<template>
	<div class="animated fadeIn mct-form">
		<mct-topo-lista :titulo="titulo" :subtitulo="`Listagem de ${titulo}`" v-show="!showModal"></mct-topo-lista>
		<div class="clearfix">
			<div style="float: right;">
				<mct-filtro-lista :columns="columns" :query="query" :filters="filtros" @getData="getData" @toogleAvancado="toogleAvancado"></mct-filtro-lista>
			</div>
		</div>
		<mct-filtro-avancado v-if="showAvancado" :columns="columns" :filters="filtros" @getData="getData"></mct-filtro-avancado>
		<slot>
			<mct-grid-lista-s-b :id="id" :columns="columns" :data="data" :query="query" :source="source" @toggleOrder="toggleOrder" :showModal="showModal" @setDescricao="setDescricao"></mct-grid-lista-s-b>
		</slot>
		<mct-rodape-lista :data="data" :query="query" @navigate="navigate" @getData="getData"></mct-rodape-lista>
	</div>
</template>

<script>
import MctTopoLista from './MctTopoLista.vue'
import MctRodapeLista from './MctRodapeLista.vue'
import MctGridListaSB from './MctGridListaSB.vue'
import MctFiltroLista from './MctFiltroLista.vue'
import MctFiltroAvancado from './MctFiltroLista2.vue'
import MctMixinsList from '../funcoes/MctMixinsList'

export default {
    name: 'MctDataViewerSB',
    mixins: [MctMixinsList],
    components: {
        'MctTopoLista': MctTopoLista,
        'MctRodapeLista': MctRodapeLista,
        'MctGridListaSB': MctGridListaSB,
        'MctFiltroLista': MctFiltroLista,
        'MctFiltroAvancado': MctFiltroAvancado
    },
    props: ['source', 'titulo', 'showModal'],
    data() {
        return {
            id: '',
            showAvancado: false
        }
    },
    watch: {
        source: function () {
            this.query.column = '';
            this.query.search_column = '';
            this.query.page = 1;
            this.query.direction = 'asc';
            this.query.per_page = 10;
            this.query.search_operator = 'equal';
            this.query.search_input = '';
            this.query.search_input2 = '';
            this.filters = {};
            this.getData();
        }
    },
    methods: {
        navigate(page) {
            this.query.page = page;
            this.getData()
        },
        toggleOrder(column) {
            if (column === this.query.column) {
                if (this.query.direction === 'desc') {
                    this.query.direction = 'asc';
                } else {
                    this.query.direction = 'desc';
                }
            } else {
                this.query.column = column;
                this.query.direction = 'asc';
            }
            this.getData()
        },
        setDescricao(data) {
            this.$emit('setDescricao', data)
        },
        toogleAvancado(data) {
            this.showAvancado = data
        }
    },
}
</script>