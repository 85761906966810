<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" :titAlerta="`ERRO: ${status}`" :msgAlerta="message" :tipoAlerta="tipoAlerta" @setData="setData">
        <div slot="formulario">
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="ent_codigo" :valor="lista" titulo="Código" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
            </div>            
            <div class="row">
                <div class="col-sm-12">
                    <mct-form-itens coluna="0" tipo="text" campo="ent_nome" :valor="lista" titulo="Nome" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <mct-form-itens coluna="0" tipo="text" campo="ent_abreviacao" :valor="lista" titulo="Abreviação" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
                <div class="col-sm-3">
                    <mct-form-itens coluna="0" tipo="text" campo="ent_cpf" :valor="lista" titulo="CPF/CNPJ" obrigatorio="true" mascara="cpf_cnpj" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
                <div class="col-sm-3">
                    <mct-form-itens coluna="0" tipo="text" campo="ent_rg" :valor="lista" titulo="RG/IE" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
            </div>
            <mct-logradouro :modo="modo" :valor="lista" :rels="{logra_codigo: 'ent_logra_codigo', logra_descricao: 'ent_logradouro', logra_bairro: 'ent_bairro', 
                        logra_cidade: 'ent_cidade', logra_estado: 'ent_estado', logra_cep: 'ent_cep', logra_numero: 'ent_logra_numero', logra_complemento: 'ent_complemento', logra_cidade_ibge: 'ent_cidade_ibge'}"></mct-logradouro>                     
            <div class="row">
                <div class="col-lg-3">
                    <mct-form-itens coluna='0' tipo="text" campo="ent_telefone" :valor="lista" titulo="Telefone" obrigatorio="false" :errors="errors" mascara="telefone" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-lg-3">
                    <mct-form-itens tipo="text" campo="ent_celular" :valor="lista" titulo="Celular" obrigatorio="false" :errors="errors" mascara="celular" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-lg-3">
                    <mct-form-itens tipo="text" campo="ent_fax" :valor="lista" titulo="Fax" obrigatorio="false" :errors="errors" mascara="telefone" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6">
                    <mct-form-itens coluna="0" tipo="text" campo="ent_email" :valor="lista" titulo="Email" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-lg-6">
                    <mct-form-itens coluna="0" tipo="text" campo="ent_url" :valor="lista" titulo="URL" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <mct-form-itens coluna="0" tipo="text" campo="ent_logo" :valor="lista" titulo="Logo" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
        </div>
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'

import MctPadrao from '../comum/MctFormPadrao.vue'
import MctFormItens from '../comum/MctFormItens.vue'
import MctLogradouro from '../comum/MctLogradouroC.vue'


export default {
  Name: 'Entidade',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      MctLogradouro,      
  },
  data () {
      return {
          lista: {},  
          errors:{},
          message: '',
          status: 0,
          tipoAlerta: '',
          ultreg: 0,
      }
  },
  methods: {    
    setData (lista) {
        this.lista = lista
        if (this.modo == 'novo'){
            
        }
    },    
    getUltReg (modulo, submodulo) {
        var vm = this
        axios.get(`menu/ultreg/`+modulo+`/`+submodulo)
        .then(function(response) {
            vm.$data.lista.menu_codigo = response.data[0].ultreg
        })
        .catch(function(response) {
            console.log(response)
        })     
    },
    setLogradouro (data) {
        if (this.modo == 'novo' || this.modo == 'editar'){
            this.lista.logra_codigo     = data.logra_codigo     
            this.lista.ent_logra_numero  = data.logra_numero     
        }
    },    
  },
  computed: {
    ...mapState('Login',['infoLogado','Entidade']),
    exercicios () {
        console.log(this.lista.exercicios)
        return this.lista.exercicios
    }
        
  }, 
  created() {      

},
  watch: {
         
  }     
}
</script>