<template>
    <div class="mct-form container-fluid ">
        <mct-topo-lista titulo="Gerar Remessa / Ler Arquivo de Retorno do Banco" subtitulo=""></mct-topo-lista>
        <div class="container-fluid position-relative">
            <!-- TABS --> 
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#remessa" role="tab">Remessa</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#retorno" role="tab">Retorno</a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="remessa" role="tabpanel">
                    <div class="row mct-form-filter">
                        <div class="row col-12">
                            <div class="col-lg-2">
                                <fieldset class="well">
                                    <legend class="well-legend">Data Emissão</legend>
                                    <div class="row">
                                        <div class="col-lg-12 mb-2">
                                            <mct-form-itens tipo="date" campo="dataemi_ini" :valor="filtro" titulo="Inicial" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <mct-form-itens tipo="date" campo="dataemi_fin" :valor="filtro" titulo="Final" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                            <div class="col-lg-2">
                                <fieldset class="well">
                                    <legend class="well-legend">Data Vencimento</legend>
                                    <div class="row">
                                        <div class="col-lg-12 mb-2">
                                            <mct-form-itens tipo="date" campo="datavenc_ini" :valor="filtro" titulo="Inicial" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <mct-form-itens tipo="date" campo="datavenc_fin" :valor="filtro" titulo="Final" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>                        
                        </div>
                        <div class="row col-12 mb-2">
                            <div class="col-12">
                                <mct-form-itens tipo="select" campo="pessoa" :valor="filtro" titulo="Pessoa"  origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo' :errors="{}" modo="editar"></mct-form-itens>
                            </div>
                        </div>
                        <div class="row col-12 mb-2">
                            <div class="col-12">
                                <mct-form-itens tipo="select" campo="conta" :valor="filtro" titulo="Conta Bancária" obrigatorio="true" 
                                    :origemFiltro="{cbnc_status: 1, cbnc_boleto: 1}"  origem="esc_conta_bancaria" origemChave="cbnc_codigo" origemRetorno='cbnc_descricao' origemRetornoSec='cbnc_numero' :errors="{}" modo="editar"></mct-form-itens>    
                            </div>
                        </div>
                        <div class="row col-12 mb-2">
                            <div class="col-12">
                                <mct-form-itens tipo="radio" campo="tiporemessa" :valor="filtro" titulo="Tipo Movimento Remessa" obrigatorio="true" 
                                    :origemData="[{value: '01', text: 'Entrada de Títulos'}, {value: '02', text: 'Solicitação de Baixa'}, {value: '03', text: 'Reenvio de Título (Novo Nosso Nº)'}]" :errors="{}" modo="editar"></mct-form-itens>    
                            </div>
                        </div>

                        <div class="row col-12 mb-2">
                            <div class="col-12">
                                <div class="btn-group btn-group-toggle">
                                    <button class="btn btn-secondary" aria-label="Left Align" @click.prevent.stop="getLancamentos()"><span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Filtrar</button>     
                                    <button class="btn btn-dark text-white" @click.prevent.stop="onProcessarArquivoRemessa"><span class="fa fa-cogs fa-lg text-white" aria-hidden="true"></span> Gerar Arquivo Remessa</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mct-grid">
                        <div class="mct-grid-scroll">         
                            <table class="table table-striped table-sm table-hover">
                                <thead>
                                    <tr>
                                        <th style="width: 35px!important;" class="text-center"></th>
                                        <th style="width: 50px" class="text-center">Enviado</th>
                                        <th style="width: 80px;" class="text-right">Número</th>
                                        <th style="width: 80px;" class="text-right">Data Emissão</th>
                                        <th style="width: 80px;" class="text-right">Cód. Cliente</th>
                                        <th class="text-left">Nome Cliente</th>
                                        <th style="width: 80px;" class="text-right">Data Vencimento</th>
                                        <th style="width: 80px;" class="text-right">Valor Doc.</th>
                                        <th style="width: 80px;" class="text-right">Valor A Pagar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row,index) in lancamentos" :key="index">
                                        <td class="text-center">
                                            <label class="custom-control custom-checkbox" v-if="row.crecp_codigo !== null">
                                                <input  class="custom-control-input" type="checkbox" :name="`ch_enviar_${index}`" v-model="row.enviar"
                                                    v-bind:true-value="1"
                                                    v-bind:false-value="0">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description align-middle"></span>
                                            </label>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge badge-success p-1 pl-2 pr-2 m-0" v-if="row.crecp_remessa && row.crecp_remessa === 1">SIM</span>
                                            <span class="badge badge-warning p-1 pl-2 pr-2 m-0" v-else-if="row.crecp_remessa && row.crecp_remessa === 2">BAIXA</span>
                                            <span class="badge badge-danger p-1 pl-2 pr-2 m-0" v-else>NÃO</span>
                                        </td>
                                        <td class="text-right">
                                            {{row.crecp_numero}}
                                        </td>
                                        <td class="text-right">
                                            {{row.crec_emissao | data}}
                                        </td>
                                        <td class="text-right">
                                            {{row.pes_codigo}}
                                        </td>
                                        <td class="text-left" style="white-space: pre-wrap; word-wrap:break-word;">{{row.pes_nome}}</td>
                                        <td class="text-right">
                                            {{row.crecp_vencimento | data}}
                                        </td>
                                        <td class="text-right">
                                            {{row.crecp_valor | moeda}}
                                        </td>
                                        <td class="text-right">
                                            
                                        </td>                                    
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div class="tab-pane" id="retorno" role="tabpanel">
                    <div class="row mct-form-filter">
                        <div class="col-4 mb-2">
                            <input type="file" v-on:change="onFileChange" class="form-control" style="border: 0!important;" accept=".txt,.rem,.ret">
                        </div>
                        <div class="row col-12 mb-2">
                            <div class="col-12">
                                <div class="btn-group btn-group-toggle">
                                    <button class="btn btn-dark text-white" @click.prevent.stop="onProcessarArquivoRetorno"><span class="fa fa-cogs fa-lg text-white" aria-hidden="true"></span> Processar Arquivo Retorno</button>
                                    <button class="btn btn-success text-white" @click.prevent.stop="onBaixarSelecionados"><span class="fa fa-download fa-lg text-white" aria-hidden="true"></span> Baixar Documentos Selecionados</button>
                                    <button class="btn btn-info" @click.prevent.stop="onImprimirArquivo"><span class="fa fa-print fa-lg" aria-hidden="true"></span> Imprimir Listagem</button>
                                </div>
                            </div>
                        </div>

                        <div class="row col-12 mb-2">
                            <div class="col-4">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text">Banco</label>
                                    </div>            
                                    <input type="text" 
                                        class="form-control"
                                        placeholder="Banco"
                                        :value="`${header.codBanco ?  header.codBanco+'-'+header.nomeBanco : ''}`"
                                        disabled>              
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text">Convênio</label>
                                    </div>            
                                    <input type="text" 
                                        class="form-control text-right"
                                        placeholder="Convênio"
                                        :value="`${headerLote.convenio || ''}`"
                                        disabled>              
                                </div>
                            </div>
                        </div>
                        <div class="row col-12 mb-2">
                            <div class="col-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text">Data Arquivo</label>
                                    </div>            
                                    <input type="text" 
                                        class="form-control text-right"
                                        placeholder="Data Arquivo"
                                        :value="`${headerLote.dataGravacao || ''}`"
                                        disabled>              
                                </div>
                            </div>
                            <div class="col-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text">Sequência</label>
                                    </div>            
                                    <input type="text" 
                                        class="form-control text-right"
                                        placeholder="Sequência"
                                        :value="`${header.numeroSequencialArquivo || ''}`"
                                        disabled>              
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text">Agên./Conta</label>
                                    </div>            
                                    <input type="text" 
                                        class="form-control text-right"
                                        placeholder="Agên./Conta"
                                        :value="`${header.agencia ? header.agencia+'-'+header.agenciaDv+'/'+header.conta+'-'+header.contaDv : ''}`"
                                        disabled>              
                                </div>
                            </div>
                            <div class="col-5">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text">Empresa</label>
                                    </div>            
                                    <input type="text" 
                                        class="form-control"
                                        placeholder="Empresa"
                                        :value="`${header.nomeEmpresa || ''}`"
                                        disabled>              
                                </div>
                            </div>
                        </div>
                        <div class="row col-12 mb-2">
                            <div class="col-2">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text">Qtd. de Títulos</label>
                                    </div>            
                                    <input type="text" 
                                        class="form-control text-right"
                                        placeholder="Qtd. de Títulos"
                                        :value="`${trailerLote.qtdTitulosCobrancaSimples || ''}`"
                                        disabled>              
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <label class="input-group-text">Valor Total dos Títulos</label>
                                    </div>            
                                    <input type="text" 
                                        class="form-control text-right"
                                        placeholder="Valor Total dos Títulos"
                                        :value="`${$options.filters.moeda(trailerLote.valorTotalTitulosCobranca) || ''}`"
                                        disabled>              
                                </div>
                            </div>                        
                        </div>  
                    </div>

                    <div class="mct-grid">
                        <div class="mct-grid-scroll">         
                            <table class="table table-striped table-sm table-hover">
                                <thead>
                                    <tr>
                                        <th style="width: 35px!important;" class="text-center"></th>
                                        <th style="width: 50px" class="text-center">Baixado</th>
                                        <th style="width: 50px" class="text-center">Conciliado</th>
                                        <th style="width: 50px;" class="text-center">Nosso Número</th>
                                        <th style="width: 50px;" class="text-center">Tipo Ocorrência</th>
                                        <th style="width: 80px;" class="text-right">Data Vencimento</th>
                                        <th style="width: 80px;" class="text-right">Valor Doc.</th>
                                        <th>Pagador</th>
                                        <th style="width: 80px;" class="text-right">Data Ocorrência</th>
                                        <th style="width: 80px;" class="text-right">Data Crédito</th>
                                        <th style="width: 80px;" class="text-right">Valor Desconto</th>
                                        <th style="width: 80px;" class="text-right">Valor Multa</th>
                                        <th style="width: 80px;" class="text-right">Valor Juros</th>
                                        <th style="width: 80px;" class="text-right">Valor Tarifa</th>
                                        <th style="width: 80px;" class="text-right">Valor Recebido</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row,index) in detalhe" :key="index">
                                        <td class="text-center">
                                            <label class="custom-control custom-checkbox" v-if="row.crecp_codigo !== null">
                                                <input  class="custom-control-input" type="checkbox" :name="`ch_baixar_${index}`" v-model="row.baixar"
                                                    v-bind:true-value="1"
                                                    v-bind:false-value="0">
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description align-middle"></span>
                                            </label>
                                            <span class="badge badge-danger p-1 pl-2 pr-2 m-0" v-else title="Documento/Nosso Número não localizado no Contas a Receber.">***</span> 
                                        </td>
                                        <td class="text-center">
                                            <span class="badge badge-success p-1 pl-2 pr-2 m-0" v-if="row.quitacao && row.quitacao.quita_data && row.quitacao.quita_data !== null">SIM</span>
                                            <span class="badge badge-danger p-1 pl-2 pr-2 m-0" v-else>NÃO</span>
                                        </td>
                                        <td class="text-center">
                                            <span class="badge badge-success p-1 pl-2 pr-2 m-0" v-if="row.conciliacao && row.conciliacao.cbnclanc_conciliado_data && row.conciliacao.cbnclanc_conciliado_data !== null">SIM</span>
                                            <span class="badge badge-danger p-1 pl-2 pr-2 m-0" v-else>NÃO</span>
                                        </td>
                                        <td>
                                            {{row.nossoNumero}}
                                        </td>
                                        <td>
                                            {{row.ocorrenciaDescricao}}
                                        </td>
                                        <td class="text-right">
                                            {{row.dataVencimento}}
                                        </td>
                                        <td class="text-right">
                                            {{row.valor | moeda}}
                                        </td>
                                        <td class="text-left" style="white-space: pre-wrap; word-wrap:break-word;">{{row.pagador.nome_documento}}</td>
                                        <td class="text-right">
                                            {{row.dataOcorrencia}}
                                        </td>
                                        <td class="text-right">
                                            {{row.dataCredito}}
                                        </td>
                                        <td class="text-right">
                                            {{row.valorDesconto | moeda}}
                                        </td>
                                        <td class="text-right">
                                            {{row.valorMulta | moeda}}
                                        </td>
                                        <td class="text-right">
                                            {{row.valorMora | moeda}}
                                        </td>
                                        <td class="text-right">
                                            {{row.valorTarifa | moeda}}
                                        </td>
                                        <td class="text-right">
                                            {{row.valorRecebido | moeda}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div> 
        </div>                  
    </div>
</template>

<script>
import Vue from 'vue'
import axios from '@/http'

import PDFView from '@/components/pdfviewer/PDFView.vue' 
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios'

import { saveAs } from 'file-saver';

import MctTopoLista from '@/components/comum/MctTopoLista.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
    name: "EscRetornoBanco",
    mixins: [MctPadraoRelatorios],
    components: {
        PDFView,
        MctTopoLista,
        MctFormItens
    }, 
	data(){
		return {
            arquivo: '',
            nomeArquivo: '',
            header: {},
            headerLote: {},
            detalhe: [],
            trailerLote: {},
            trailer: {},
            lancamentos: [],
            filtro: {
                dataemi_ini: null,
                dataemi_fin: null,
                datavenc_ini: null,
                datavenc_fin: null,
                pessoa: null,
                conta: null,
                tiporemessa: '01'
            }
		}
    },
    created () {
        //this.getLancamentos();
    },
	methods: {
        async getLancamentos () {
            const {dataemi_ini, dataemi_fin, datavenc_ini, datavenc_fin, pessoa, conta} = this.filtro; 
            await axios.get('/esccontabancaria/remessa',
                { 
                    params: { 
                        dataemi_ini,  
                        dataemi_fin,
                        datavenc_ini, 
                        datavenc_fin, 
                        pessoa,
                        conta
                    } 
                })
                .then(response => {
                    this.message = response.data.message
                    this.status = response.status
                    this.lancamentos = response.data.data
                    this.errors = {}                    
                })
                .catch(response => {
                   this.message = response.response.data.message
                    if (response.response.data.error){
                       this.errors = response.response.data.error.errorInfo[2]
                    }else{
                       this.errors = response.response.data.errors
                    }
                   this.status = response.response.status
                })

        },
		onFileChange(e) {
			let files = e.target.files || e.dataTransfer.files;
			if (!files.length)
                return;
            this.arquivo = files[0];
            this.nomeArquivo = files[0].name;
            var fileReader = new FileReader();
            fileReader.onload = e => {
                //var fileContents = document.getElementById('fileContent');
                //fileContents.innerText = fileReader.result;
                this.arquivo = fileReader.result
            }
            fileReader.readAsText(this.arquivo);
		},
		createImage(file) {
			let reader = new FileReader();
			let vm = this;
			reader.onload = (e) => {
				vm.arquivo = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		onProcessarArquivoRetorno(){
			axios.post('/retorno/arquivo',{arquivo: this.arquivo}).then(response => {
                this.header = response.data.header;
                this.headerLote = response.data.headerLote;
                this.detalhe = response.data.detalhe;
                this.trailerLote = response.data.trailerLote;
                this.trailer = response.data.trailer;            
            });
        },
        onProcessarArquivoRemessa(){
            this.$swal({
                title: 'Deseja realmente Gerar o arquivo de remessa a partir dos documentos selecionados?',
                text: "Os documentos já enviados terão os dados de envio alterados na base de dados!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim'
                }).then((result) => {
                    if (result.value) {
                        this.onArquivoRemessa()   
                    }                     
                })
        },
        async onArquivoRemessa(){
            const {conta, tiporemessa} = this.filtro;
            let parcelas = this.lancamentos.filter(item => item.enviar === 1);
            parcelas = parcelas.map(item => item.crecp_codigo);

            await axios.post('/remessa/arquivo',{conta, parcelas, tiporemessa}).then(response => {
                const {nomeArquivo, textoRemessa} = response.data;
                var blob = new Blob([textoRemessa], {type: "text/plain;charset=utf-8"});
                saveAs(blob, nomeArquivo);
                this.getLancamentos();
            });
        },
        async onImprimirArquivo(){
            //alert('Esta funcionalidade esta sendo desenvolvida.')
            //await this.getData();

            await this.onCreatePDF()
            /*CABEÇALHO*/
            this.title = `ARQUIVO DE RETORNO - ${this.header.nomeBanco} AG.: ${this.header.agencia}-${this.header.agenciaDv} C.: ${this.header.conta}-${this.header.contaDv}`;
            this.onHeaderPDF();
            this.pdf.setFont('courier') 
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)     
                    
            this.pdf.text(`ARQUIVO: ${this.nomeArquivo} - DATA: ${this.headerLote.dataGravacao}`,8,this.lin)
            this.onAddLine(this.jump)
            this.pdf.text(`TOTAL DE REGISTROS: ${this.detalhe.length}`,8,this.lin)
            this.onAddLine(this.jump)

            this.onAddLine(this.jump)
            this.pdf.setFillColor(192,192,192)
            this.pdf.rect(8,this.lin-3,this.widthPage,this.jump+1,'FD')
            this.pdf.setFont('helvetica','bold') 
            this.pdf.setFontSize(7)
            this.pdf.text('DT.EMI.',26,this.lin,{align: 'right'})
            this.pdf.text('DT.VENC.',42,this.lin,{align: 'right'})
            this.pdf.text('DOCUMENTO',64,this.lin,{align: 'right'})
            this.pdf.text('VLR ORI.',80,this.lin,{align: 'right'})
            this.pdf.text('JUROS.',90,this.lin,{align: 'right'})
            this.pdf.text('DESC.',100,this.lin,{align: 'right'})
            this.pdf.text('VLR PAGO',116,this.lin,{align: 'right'})
            this.pdf.text('TARIFA',126,this.lin,{align: 'right'})
            this.pdf.text('VLR CRED.',142,this.lin,{align: 'right'})
            this.pdf.text('DT.PGTO.',158,this.lin,{align: 'right'})
            this.pdf.text('DT.CRED.',174,this.lin,{align: 'right'})
            this.pdf.text('DIF.',184,this.lin,{align: 'right'})
            this.pdf.text('BAIXA',198,this.lin,{align: 'right'})
            this.onAddLine(this.jump+1)

            var somaValorOri = 0
            var somaJur = 0
            var somaDesc = 0
            var somaValorPago = 0
            var somaTarifa = 0
            var somaValorCred = 0
            var somaDif = 0

            var somaValorOriCli = 0
            var somaJurCli = 0
            var somaDescCli = 0
            var somaValorPagoCli = 0
            var somaTarifaCli = 0
            var somaValorCredCli = 0
            var somaDifCli = 0

            var grupo = null

            for (var index in this.detalhe) {
                this.pdf.setFont('courier') 
                //AGRUPADO PELO NOME DA PESSOA
                if (grupo !== this.detalhe[index].pagador.documento){
                    grupo = this.detalhe[index].pagador.documento
                    this.pdf.setFont('courier') 
                    this.pdf.setFontSize(7)
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.text(String('CLIENTE:'),10,this.lin)
                    this.pdf.text(String(this.detalhe[index].pagador.nome.substring(0,27)+' - '+this.detalhe[index].pagador.documento),28,this.lin)        
                    this.onAddLine(this.jump+1)
                }

                this.pdf.setFont('courier') 
                this.pdf.setFontSize(7)
                this.pdf.setFont('helvetica','normal')
                this.pdf.text(String(this.detalhe[index].dataOcorrencia),26,this.lin,{align: 'right'})
                this.pdf.text(String(this.detalhe[index].dataVencimento),42,this.lin,{align: 'right'})
                this.pdf.text(String(this.detalhe[index].receber_parcela.crecp_numero ? this.detalhe[index].receber_parcela.crecp_numero : this.detalhe[index].numeroDocumento),64,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.moeda(this.detalhe[index].valor)),80,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.moeda(this.detalhe[index].valorMulta)),90,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.moeda(this.detalhe[index].valorDesconto)),100,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.moeda(Number(this.detalhe[index].valor)-Number(this.detalhe[index].valorDesconto)+Number(this.detalhe[index].valorMulta))),116,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.moeda(this.detalhe[index].valorTarifa)),126,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.moeda(Number(this.detalhe[index].valor)-Number(this.detalhe[index].valorDesconto)+Number(this.detalhe[index].valorMulta)-Number(this.detalhe[index].valorTarifa))),142,this.lin,{align: 'right'})
                this.pdf.text(String(this.detalhe[index].dataOcorrencia),158,this.lin,{align: 'right'})
                this.pdf.text(String(this.detalhe[index].dataCredito),174,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.moeda(Number(this.detalhe[index].receber_parcela.crecp_valor) > 0 ? Number(this.detalhe[index].receber_parcela.crecp_valor)-Number(this.detalhe[index].valor) : 0)),184,this.lin,{align: 'right'})
                this.pdf.text(String(this.detalhe[index].baixar == 1 ? 'SIM' : 'NÃO'),198,this.lin,{align: 'right'})

                somaValorOri += Number(this.detalhe[index].valor);
                somaJur += Number(this.detalhe[index].valorMulta);
                somaDesc += Number(this.detalhe[index].valorDesconto);
                somaValorPago += Number(this.detalhe[index].valor)-Number(this.detalhe[index].valorDesconto)+Number(this.detalhe[index].valorMulta);
                somaTarifa += Number(this.detalhe[index].valorTarifa);
                somaValorCred += Number(this.detalhe[index].valor)-Number(this.detalhe[index].valorDesconto)+Number(this.detalhe[index].valorMulta)-Number(this.detalhe[index].valorTarifa);
                somaDif += Number(this.detalhe[index].receber_parcela.crecp_valor) > 0 ? Number(this.detalhe[index].receber_parcela.crecp_valor)-Number(this.detalhe[index].valor) : 0;

                somaValorOriCli += Number(this.detalhe[index].valor);
                somaJurCli += Number(this.detalhe[index].valorMulta);
                somaDescCli += Number(this.detalhe[index].valorDesconto);
                somaValorPagoCli += Number(this.detalhe[index].valor)-Number(this.detalhe[index].valorDesconto)+Number(this.detalhe[index].valorMulta);
                somaTarifaCli += Number(this.detalhe[index].valorTarifa);
                somaValorCredCli += Number(this.detalhe[index].valor)-Number(this.detalhe[index].valorDesconto)+Number(this.detalhe[index].valorMulta)-Number(this.detalhe[index].valorTarifa);
                somaDifCli += Number(this.detalhe[index].receber_parcela.crecp_valor) > 0 ? Number(this.detalhe[index].receber_parcela.crecp_valor)-Number(this.detalhe[index].valor) : 0;

                this.onAddRegLine()
                this.onAddLine(this.jump)

                if ((parseInt(index)+1 == this.detalhe.length || grupo !== this.detalhe[parseInt(index)+1 < this.detalhe.length ? parseInt(index)+1 : index].pagador.documento)){
                    this.onAddLine(1)
                    this.pdf.setFont('courier') 
                    this.pdf.setFontSize(7)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text(String(`TOTAL REC. DO CLIENTE:`),64,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaValorOriCli)),80,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaJurCli)),90,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaDescCli)),100,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaValorPagoCli)),116,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaTarifaCli)),126,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaValorCredCli)),142,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaDifCli)),184,this.lin,{align: 'right'})
                    this.onAddLine(this.jump+1)
                    this.pdf.line(8,this.lin-this.jump,(this.widthPage+8),this.lin-this.jump)
                    somaValorOriCli = 0;
                    somaJurCli = 0;
                    somaDescCli = 0;
                    somaValorPagoCli = 0;
                    somaTarifaCli = 0;
                    somaValorCredCli = 0;
                    somaDifCli = 0;
                }
            }
            
            // TOTAL FINAL
            this.onAddLine(1)
            this.pdf.setFont('courier') 
            this.pdf.setFontSize(7)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text(String(`TOTAL GERAL:`),64,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaValorOri)),80,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaJur)),90,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaDesc)),100,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaValorPago)),116,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaTarifa)),126,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaValorCred)),142,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaDif)),184,this.lin,{align: 'right'})
            this.onAddLine(this.jump+1)
            this.pdf.line(8,this.lin-this.jump,(this.widthPage+8),this.lin-this.jump)
            somaValorOri = 0;
            somaJur = 0;
            somaDesc = 0;
            somaValorPago = 0;
            somaTarifa = 0;
            somaValorCred = 0;
            somaDif = 0;
                                   
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            this.pdf.save(`arq-retorno-banco-${uuidv4}.pdf`)

            //const urlPDF = this.pdf.output('blob');
            //this.outputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
        },
        onBaixarSelecionados(){
            this.$swal({
                title: 'Deseja realmente baixar os documentos selecionados?',
                html: '<b style="color: red;">ATENÇÃO:</b> Os documentos já baixados e não conciliados terão suas baixas removidas, para depois serem efetuadas as novas baixas!</br>Para os documentos lançados já conciliados, remova-os da conciliação e efetue a baixa novamente.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim'
                }).then((result) => {
                    if (result.value) {
                        this.onBaixar()   
                    }                     
                })            
        },        
        async onBaixar (){
            await axios.post('/retorno/baixar',this.detalhe)
                .then(response => {
                    this.message = response.data.message
                    this.status = response.status
                    this.lista = response.data.data
                    this.errors = {}
                    this.$swal('Sucesso!',
                    'Documentos baixados com sucesso!',
                    'success');
                })
                .catch(response => {
                   this.message = response.response.data.message
                    if (response.response.data.error){
                       this.errors = response.response.data.error.errorInfo[2]
                    }else{
                       this.errors = response.response.data.errors
                    }
                   this.status = response.response.status
                })
        }
	}
}

/*
TIPOS DE MIDIA:

    Extensão de arquivo (.xls, .jpg);
    audio/* - todos arquivos de áudio;
    video/* - todos arquivos de vídeo;
    image/* - todos arquivos de imagem;
    media_type - arquivos de mídia.
*/
</script>