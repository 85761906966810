<template>
    <div>
        <div class="card-menu" v-if="infoEscola.esc_tipo !== 1">
                <div class="card-modulo" @click="open('/portal/financeiro')">
                    <i alt="Financeiro" class="fa fa-dollar fa-3x"></i>
                    <h1>
                        Financeiro
                    </h1>
                </div>
                <div class="card-modulo" @click="open('/portal/pedagogico')">
                    <i alt="Acompanhamento Pedagógico" class="fa fa-book fa-3x"></i>
                    <h1>
                        Acompanhamento Pedagógico
                    </h1>
                </div>
                <div class="card-modulo" @click="open('/portal/avisos')">
                    <i alt="Avisos" class="fa fa-bell fa-3x"></i>
                    <h1>
                        Avisos
                    </h1>
                </div>
                <div class="card-modulo" @click="open('/portal/provas')">
                    <i alt="Calendário de Provas" class="fa fa-calendar fa-3x"></i>
                    <h1>
                        Calendário de Provas
                    </h1>
                </div>
        </div>
        <div class="card-titulo d-flex justify-content-center align-items-center">
            <h1>MEUS CURSOS</h1>
        </div>

        <div class="card-container">
            <div class="cards card-curso p-3" v-for="(matricula,idxm) in matriculas" :key="idxm">
                <div>
                    <h2>{{matricula.turma.turm_identificacao}}</h2>
                    <h1 v-if="infoEscola.esc_tipo === 1">{{matricula.turma.serie.ser_descricao}}</h1>
                    <h1>{{matricula.turma.curso.cur_nome}}</h1>
                </div>
                <div class="d-flex flex-column">
                    <small>Matrícula</small>
                    <span>{{matricula.matri_matricula}}</span>
                </div>

                <div class="card-modulos" v-if="infoEscola.esc_tipo !== 1">
                    <div class="card-modulo p-3" v-for="(serie,idxs) in matricula.series" :key="idxs">
                        <div class="p-0">
                            <h2>{{serie.agendamento.serie.ser_descricao}}</h2>
                            <div class="d-flex flex-row my-3">
                                <div class="d-flex flex-column mr-3">
                                    <small>Início</small>
                                    <span>{{serie.agendamento.horari_data_inicial | data}}</span>
                                </div>
                                <div class="d-flex flex-column">
                                    <small>Fim</small>
                                    <span>{{serie.agendamento.horari_data_final | data}}</span>
                                </div>
                            </div>

                            <h1>
                                <span class="badge badge-primary" v-if="serie.agendamento.horari_situacao === 1"><i class="fa fa-clock-o" aria-hidden="true"></i> em andamento</span>
                                <span class="badge badge-danger" v-else-if="serie.agendamento.horari_situacao === 0"><i class="fa fa-ban" aria-hidden="true"></i> cancelado</span>
                                <span class="badge badge-info" v-else-if="serie.agendamento.horari_situacao === 2"><i class="fa fa-hourglass-start" aria-hidden="true"></i> aguardando</span>
                                <span class="badge badge-success p-1" v-else-if="serie.agendamento.horari_situacao === 3"><i class="fa fa-check" aria-hidden="true"></i> finalizado</span>                            
                            </h1>
                        </div>

                        <div class="card-modulo-menu">
                            <div class="p-1" @click="open(`/portal/notas/${matricula.matri_ent}/${matricula.matri_esc}/${matricula.matri_ano}/${matricula.turma.turm_codigo}/${serie.agendamento.horari_codigo}`)">
                                <i alt="Início" class="fa fa-clipboard fa-2x icon-portal"></i>
                                <h1>Notas e Faltas</h1>
                            </div>
                            <!--<div class="p-1" @click="open('/portal/boletim')">
                                <i alt="Início" class="fa fa-newspaper-o fa-2x icon-portal"></i>
                                <h1>Boletim</h1>
                            </div>-->
                            <div class="p-1" @click="open(`/portal/horarios/${matricula.matri_ent}/${matricula.matri_esc}/${matricula.matri_ano}/${matricula.turma.turm_codigo}/${serie.agendamento.horari_codigo}`)">
                                <i alt="Início" class="fa fa-clock-o fa-2x icon-portal"></i>
                                <h1>Horário</h1>
                            </div>                                
                        </div>
                    </div>
                </div>
                <div class="card-modulos" v-else>
                        <div class="card-modulo-menu">
                            <div class="p-1 card-modulo" @click="open(`/portal/notas/${matricula.matri_ent}/${matricula.matri_esc}/${matricula.matri_ano}/${matricula.turma.turm_codigo}/${matricula.turma.ser_codigo}`)">
                                <i alt="Notas e Faltas" class="fa fa-clipboard fa-2x icon-portal"></i>
                                <h1>Notas e Faltas</h1>
                            </div>
                            <div class="card-modulo p-1" @click="open(`/portal/boletim/${matricula.matri_ent}/${matricula.matri_esc}/${matricula.matri_ano}/${matricula.matri_turm_codigo}/${matricula.matri_alu_codigo}`)">
                                <i alt="Boletim" class="fa fa-newspaper-o fa-2x icon-portal"></i>
                                <h1>Boletim</h1>
                            </div>
                            <div class="card-modulo p-1" @click="open(`/portal/horarios/${matricula.matri_ent}/${matricula.matri_esc}/${matricula.matri_ano}/${matricula.turma.turm_codigo}/${matricula.turma.ser_codigo}`)">
                                <i alt="Horário" class="fa fa-clock-o fa-2x icon-portal"></i>
                                <h1>Horário</h1>
                            </div>
                            <div class="card-modulo p-1" @click="open(`/portal/provas/${matricula.matri_ent}/${matricula.matri_esc}/${matricula.matri_ano}/${matricula.turma.turm_codigo}`)">
                                <i alt="Calendário de Provas" class="fa fa-calendar fa-2x icon-portal"></i>
                                <h1>Calendário de Provas</h1>
                            </div>
                            <div class="card-modulo p-1" @click="open(`/portal/calendario/${matricula.matri_ent}/${matricula.matri_esc}/${matricula.matri_ano}/${matricula.turma.turm_codigo}`)">
                                <i alt="Calendário Escolar" class="fa fa-calendar fa-2x icon-portal"></i>
                                <h1>Calendário Escolar</h1>
                            </div>
                            <div class="card-modulo p-1" @click="open(`/portal/conteudo/${matricula.matri_ent}/${matricula.matri_esc}/${matricula.matri_ano}/${matricula.turma.turm_codigo}`)">
                                <i alt="Conteúdo Online" class="fa fa-television fa-2x icon-portal"></i>
                                <h1>Conteúdo Online</h1>
                            </div>
                            <div class="card-modulo p-1" @click="open(`/portal/acompanhamento/${matricula.matri_ent}/${matricula.matri_esc}/${matricula.matri_ano}/${matricula.turma.turm_codigo}`)">
                                <i alt="Acompanhamento Pedagógico" class="fa fa-book fa-2x icon-portal"></i>
                                <h1>Acompanhamento Pedagógico</h1>
                            </div>
                            <div class="card-modulo p-1" @click="open(`/portal/avisos/${matricula.matri_ent}/${matricula.matri_esc}/${matricula.matri_ano}/${matricula.turma.turm_codigo}`)">
                                <i alt="Avisos" class="fa fa-bell fa-2x icon-portal"></i>
                                <h1>Avisos</h1>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LayoutPortal from '@/components/layouts/LayoutPortal.vue';
import { mapState } from 'vuex'

export default {
    Name: 'PortalMatriculas',
    data() {
        return {

        }
    },
    methods: {
        open(url) {
            this.$router.push(url);
        },
    },
    created () {

    },
    mounted () {
        this.$store.dispatch("Login/LAYOUT",LayoutPortal);
    }, 
    computed: {
        ...mapState('Portal',['matriculas','infoEscola']),
    },
}
</script>

<style scoped>

    .card-container {
        display: grid;
        /* aqui eu determino é são 3 colunas de tamanhos iguas, cada coluna tem uma fração */
        grid-template-columns: repeat(3, 1fr);
        /* esssa é a altura das linhas, se vc quiser pode apaga-la caso não queira uma altura definida */
        /*grid-auto-rows: 200px;*/
        /* espaço entre um elemento interno e outro */
        grid-gap: 8px 10px;
        /* margem interna que afasta os elementos da borda do grid */
        padding: 20px 0;
        align-content: space-between;             
      
    }

    .card-curso {
        cursor: pointer;
    }

    .card-curso h1 {
        color: #333333;
        font-size: 1em;
    }

    .card-curso h2 {
        color: #05512C;
        font-size: 1.2em;
        font-weight: bold;
    }

    .card-modulos {
        display: grid;
        /* aqui eu determino é são 3 colunas de tamanhos iguas, cada coluna tem uma fração */
        grid-template-columns: repeat(1, 1fr);
        /* esssa é a altura das linhas, se vc quiser pode apaga-la caso não queira uma altura definida */
        /*grid-auto-rows: 200px;*/
        /* espaço entre um elemento interno e outro */
        grid-gap: 8px 10px;
        /* margem interna que afasta os elementos da borda do grid */
        padding: 20px 10px;          
    }

    .card-menu {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 120px;
        grid-gap: 8px 10px;
        padding: 20px 0px;
    }

    .card-menu > .card-modulo {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }

    .card-menu > .card-modulo > h1 {
        font-size: 1.2em;
    }


    .card-modulo {
        cursor: pointer;
        padding: 10px;
        background-color: #FFFFFF;
        border: 1px solid #E7E7E7;
        border-bottom: 3px solid #F9A21E;
        /*border-radius: 10px;*/
        overflow: hidden;
        position: relative;

        -webkit-box-shadow: 5px 5px 5px 0px #E7E7E7;
        -moz-box-shadow: 5px 5px 5px 0px #E7E7E7;
        box-shadow: 5px 5px 5px 0px #E7E7E7;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1);
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1);
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1);
        transition: all 200ms ease-in;
        transform: scale(1);
    }

    .card-modulo:hover {
        box-shadow: 1px 1px 1px #FFFFFF;
        z-index: 9999;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1.2);
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1.2);
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1.2);
        transition: all 200ms ease-in;
        transform: scale(1.2);
    }    

    .card-modulo h1 {
        color: #333333;
        font-size: 1em;
    }

    .card-modulo h2 {
        color: #05512C;
        font-size: 1.2em;
        font-weight: bold;
    }

    .card-modulo-menu {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        /*grid-auto-rows: 200px;*/
        grid-gap: 5px 8px;
        padding: 10px 5px;
        text-align: center;            
    }

    .card-modulo-menu > div:hover {
        background-color: #f4f4f4;
    }

    .btn {
        border-radius: 0;
    }

</style>
