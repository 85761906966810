<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <div class="row">
                <div class="col-sm-8">
                    <div class="row">
                        <div class="col-sm-3">
                            <mct-form-itens tipo="number" campo="pes_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
                        </div>    
                        <div class="col-sm-3">
                            <mct-form-itens tipo="date" campo="audit_data_cadastro" :valor="lista" titulo="Data Cad." obrigatorio="true" :errors="errors" modo="readonly"></mct-form-itens>    
                        </div>
                        <div class="col-sm-3">
                            <mct-form-itens tipo="select" campo="pes_tipo_cad" :valor="lista" titulo="Tipo Cadastro" obrigatorio="true" :errors="errors" :origemData="[ {value: 'A', text: 'ALUNO'}, {value: 'R', text: 'RESPONSÁVEL'}, {value: 'C', text: 'CREDOR'}, {value: 'F', text: 'FUNC./SERV.'}, {value: 'P', text: 'PROFESSOR'} ]" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-sm-3">
                            <mct-form-itens tipo="select" campo="pes_tipo" :valor="lista" titulo="Tipo Pessoa" obrigatorio="true" :errors="errors" :origemData="[{value: 'F', text: 'FÍSICA'}, {value: 'J', text: 'JURÍDICA'}]" :modo="modo"></mct-form-itens>    
                        </div>                        
                    </div>
                    <div class="row">
                        <div class="col-sm-5">
                            <mct-form-itens tipo="checkbox" campo="pes_situacao" :valor="lista" :titulo="lista.pes_situacao == 1 ? 'Cadastro Ativo.' : 'Cadastro Inativado.'" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4 text-right">
                    <div class="mct-foto">
                        <div class="social">
                        <img :src="img_pes_foto_img" alt="">
                        <div class="social-wrapper">
                            <div class="social-icon border border-light rounded-circle">
                                <input id="inputfile" type="file" @change="onFileChange($event,'pes_foto_img')" accept="image/*">
                                <a @click.prevent.stop="onCustomFileChange('inputfile')" title="Obter Imagem de um Arquivo" aria-label="Obter Imagem de um Arquivo">
                                    <span class="fa fa-upload" aria-hidden="true"></span>
                                </a>
                            </div>
                            <div class="social-icon border border-light rounded-circle">
                                <a @click.prevent.stop="onAbreCaptura()" title="Obter Imagem de uma Câmera de Vídeo" aria-label="Obter Imagem de uma Câmera de Vídeo" target="_blank">
                                    <span class="fa fa-video-camera" aria-hidden="true"></span>
                                </a>
                            </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>            
            <!-- TABS --> 
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#cadastro" role="tab">Cadastro</a>
                </li>
                <li class="nav-item" v-if="lista.pes_tipo_cad == 'A'">
                    <a class="nav-link" data-toggle="tab" href="#aluno" role="tab">Dados do Aluno</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#outros" role="tab">Outros Dados</a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="cadastro" role="tabpanel">    
                    <div class="row">
                        <div  class="col-9">
                            <mct-form-itens tipo="text" campo="pes_nome" :valor="lista" titulo="Nome" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                        </div>   
                        <div  class="col-3">
                            <mct-form-itens tipo="radio" campo="pes_sexo" :valor="lista" titulo="Sexo" obrigatorio="true" :origemData="[{value:'M',text:'Masculino'},{value:'F',text:'Feminino'}]" :errors="errors" :modo="modo"></mct-form-itens>  
                        </div>    
                    </div>
                    <div class="row">
                        <div  class="col-9">
                            <mct-form-itens tipo="text" campo="pes_nome_social" :valor="lista" titulo="Nome Social" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>                      
                        </div>   
                    </div>
                    <!--<mct-form-itens tipo="text" campo="pes_nome" :valor="lista" titulo="Nome" obrigatorio="true" tamanho="9" :errors="errors" :modo="modo"></mct-form-itens>                      
                    <mct-form-itens tipo="radio" campo="pes_sexo" :valor="lista" titulo="Sexo" obrigatorio="true" tamanho="2" :origemData="[{value:'M',text:'Masculino'},{value:'F',text:'Feminino'}]" :errors="errors" :modo="modo"></mct-form-itens>   -->
                    <mct-form-itens v-show="lista.pes_tipo == 'J'" tipo="text" campo="pes_fantasia" :valor="lista" titulo="Fantasia" obrigatorio="true" tamanho="12" :errors="errors" :modo="modo"></mct-form-itens>                      
                    <mct-form-itens v-show="lista.pes_tipo == 'J'" tipo="select" campo="pes_tipo_empresa" :valor="lista" titulo="Tp. Empresa" obrigatorio="false" tamanho="4" :errors="errors" :origemData="tpEmpresa" :modo="modo"></mct-form-itens>
                    <div class="row">
                        <div class="col-4">
                            <mct-form-itens tipo="text" campo="pes_cpf" :valor="lista" titulo="CPF/CNPJ" :obrigatorio="infoEscola.esc_tipo !== 1 ? true : false" mascara="cpf_cnpj" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-2">
                            <mct-form-itens tipo="text" campo="pes_rg" :valor="lista" titulo="RG/IE" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-2" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens tipo="text" campo="pes_rg_orgao" :valor="lista" titulo="Orgão Exped." obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-2" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens tipo="select" campo="pes_rg_uf" :valor="lista" titulo="UF" obrigatorio="false" origem="estado" origemChave="est_uf" origemRetorno='est_uf' :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-2" v-show="lista.pes_tipo_cad != 'A'">
                            <mct-form-itens tipo="text" campo="pes_im" :valor="lista" titulo="IM" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>                        
                    <div class="row">
                        <div class="col-4" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens coluna='0' tipo="select" campo="pes_estadocivil" :valor="lista" titulo="Est. Civil" obrigatorio="false" :errors="errors" :origemData="pesEstCivil" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-3" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens tipo="date" campo="pes_nascimento" :valor="lista" titulo="Data Nasc." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-5" v-show="lista.pes_tipo == 'F' && (lista.pes_tipo_cad == 'F' || lista.pes_tipo_cad == 'P')">
                            <mct-form-itens tipo="text" campo="pes_passep" :valor="lista" titulo="PIS/PASSEP" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-5" v-show="lista.pes_tipo == 'J' && lista.pes_tipo_cad == 'C'">
                            <mct-form-itens coluna='0' tipo="select" campo="pes_tipo_empresa_lc" :valor="lista" titulo="Tipo Empresa(L.C. 123/2006)" obrigatorio="false" :errors="errors" :origemData="pesTipoEmpresa" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-3" v-show="lista.pes_tipo == 'J' && lista.pes_tipo_cad == 'C'">
                            <mct-form-itens tipo="checkbox" campo="pes_optante_simples" :valor="lista" titulo="Optante Simples Nacional" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div> 
                    <mct-logradouro :modo="modo" :valor="lista" :rels="{logra_codigo: 'pes_logra_codigo', logra_descricao: 'pes_logradouro', logra_bairro: 'pes_bairro', 
                        logra_cidade: 'pes_cidade', logra_estado: 'pes_estado', logra_cep: 'pes_cep', logra_numero: 'pes_logra_numero', logra_complemento: 'pes_logra_complemento', logra_cidade_ibge: 'pes_cidade_ibge'}" :cepObrigatorio="false"></mct-logradouro> 
                    <div class="row">
                        <div class="col-3" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens coluna='0' tipo="text" campo="pes_residencial" :valor="lista" titulo="Tel. Residencial" obrigatorio="true" :errors="errors" mascara="celular" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-3">
                            <mct-form-itens :coluna="lista.pes_tipo == 'J' ? 0 : null" tipo="text" campo="pes_celular" :valor="lista" titulo="Celular" obrigatorio="true" :errors="errors" mascara="celular" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-3">
                            <mct-form-itens tipo="text" campo="pes_fax" :valor="lista" titulo="Fax" obrigatorio="false" :errors="errors" mascara="telefone" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-3">
                            <mct-form-itens tipo="text" campo="pes_comercial" :valor="lista" titulo="Comercial" obrigatorio="false" :errors="errors" mascara="telefone" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <mct-form-itens tipo="text" campo="pes_cel_contato1" :valor="lista" titulo="Contato 1" obrigatorio="false" :errors="errors" mascara="celular" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-3">
                            <mct-form-itens tipo="text" campo="pes_cel_contato2" :valor="lista" titulo="Contato 2" obrigatorio="false" :errors="errors" mascara="celular" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-3">
                            <mct-form-itens tipo="text" campo="pes_cel_contato3" :valor="lista" titulo="Contato 3" obrigatorio="false" :errors="errors" mascara="celular" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                </div>

                <div class="tab-pane" id="aluno" role="tabpanel" v-show="lista.pes_tipo_cad == 'A'">
                    <div class="row">
                        <div class="col-2">
                            <mct-form-itens tipo="text" campo="pes_matricula" :valor="lista" titulo="Matrícula Atual" obrigatorio="false" :errors="errors" modo="visualizar"></mct-form-itens>    
                        </div>
                        <div class="col-2">
                            <mct-form-itens tipo="date" campo="pes_transferencia" :valor="lista" titulo="Transferência" obrigatorio="false" :errors="errors" modo="visualizar"></mct-form-itens>    
                        </div>
                        <div class="col-2">
                            <mct-form-itens tipo="text" campo="pes_nis" :valor="lista" titulo="NIS" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <mct-form-itens tipo="select" campo="pes_deficiencia" :valor="lista" titulo="Deficiência" obrigatorio="false" :origemData="tpDeficiencia" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-3">
                            <mct-form-itens tipo="select" campo="pes_raca" :valor="lista" titulo="Raça" obrigatorio="false" :origemData="raca" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>                        
                        <div class="col-3">
                            <mct-form-itens tipo="select" campo="pes_cor" :valor="lista" titulo="Cor" obrigatorio="false" :origemData="cor" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-3">
                            <mct-form-itens tipo="select" campo="pes_fator_rh" :valor="lista" titulo="Fator Rh" obrigatorio="false" 
                            :origemData="[{value:'A+',text:'A+'}, {value:'A-',text:'A-'}, {value:'B+',text:'B+'}, {value:'B-',text:'B-'},
                                          {value:'AB+',text:'AB+'}, {value:'AB-',text:'AB-'}, {value:'O+',text:'O+'}, {value:'O-',text:'O-'}]"
                            :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>                                          
                    <div class="row">
                        <div class="col-3">
                            <mct-form-itens tipo="text" campo="pes_reservista" :valor="lista" titulo="Reservista" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-2">
                            <mct-form-itens tipo="text" campo="pes_reservista_serie" :valor="lista" titulo="Res. Série" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>                        
                        <div class="col-2">
                            <mct-form-itens tipo="text" campo="pes_reservista_rm" :valor="lista" titulo="Res. RM" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-2">
                            <mct-form-itens tipo="text" campo="pes_reservista_csm" :valor="lista" titulo="Res. CSM" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-3">
                            <mct-form-itens tipo="date" campo="pes_reservista_data" :valor="lista" titulo="Res. Emissão" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div> 
                    <fieldset class="well">
                        <legend class="well-legend">Registro Nascimento</legend>                    
                        <div class="row">
                            <div class="col-6">
                                <mct-form-itens tipo="text" campo="pes_registro" :valor="lista" titulo="Reg. Nascimento" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-2">
                                <mct-form-itens tipo="text" campo="pes_registro_livro" :valor="lista" titulo="Reg. Nasc. Livro" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-4">
                                <mct-form-itens tipo="text" campo="pes_registro_cartorio" :valor="lista" titulo="Reg. Cartório" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <mct-form-itens tipo="select" campo="pes_registro_cidade" :valor="lista" titulo="Reg. Cidade" obrigatorio="false" :errors="errors" origem="cidade" origemChave="cid_codigo" origemRetorno='cid_descricao' :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-4">
                                <mct-form-itens tipo="select" campo="pes_registro_distrito" :valor="lista" titulo="Reg. Distrito" obrigatorio="false" :errors="errors" origem="cidade" origemChave="cid_codigo" origemRetorno='cid_descricao' :modo="modo"></mct-form-itens>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset class="well">
                        <legend class="well-legend">Dados Cobrança</legend>                    
                        <div class="row">
                            <div class="col-4">
                                <mct-form-itens tipo="radio" campo="pes_tp_responsavel" :valor="lista" titulo="Tp. Responsável" obrigatorio="true" :errors="errors" :origemData="[{value:'1',text:'O Mesmo'}, {value:'2',text:'Pai'}, {value:'3',text:'Mãe'}, {value:'4',text:'Outro'}]" :modo="modo"></mct-form-itens>
                            </div>   
                        </div>   
                        <div class="row">
                            <div class="col-9">
                                <mct-form-itens tipo="search" campo="pes_resp_cob_codigo" :valor="lista" titulo="Resp. Cobrança" obrigatorio="false" :errors="errors" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' :modo="lista != null && lista.pes_tp_responsavel == 4 ? modo : 'visualizar'" tamanho="1"></mct-form-itens>
                            </div>
                            <div class="col-3">
                                <mct-form-itens tipo="select" campo="gpar_codigo" :valor="lista" titulo="Grau Parentesco" obrigatorio="false" :errors="errors" origem="grau_parentesco" origemChave="gpar_codigo" origemRetorno='gpar_descricao' :modo="lista != null && lista.pes_tp_responsavel == 4 ? modo : 'visualizar'"></mct-form-itens>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="tab-pane" id="outros" role="tabpanel">
                    <div class="row">
                        <div class="col-6" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens tipo="search" campo="prf_codigo" :valor="lista" titulo="Profissão" obrigatorio="false" :errors="errors" origem="profissao" origemChave="prf_codigo" origemRetorno='prf_descricao' :modo="modo" tamanho="2"></mct-form-itens>
                        </div>
                        <div class="col-6" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens tipo="select" campo="ginst_codigo" :valor="lista" titulo="Grau de Instrução" obrigatorio="false" :errors="errors" origem="grau_instrucao" origemChave="ginst_codigo" origemRetorno='ginst_descricao' :modo="modo"></mct-form-itens>
                        </div>
                    </div>  
                    <div class="row">
                        <div class="col-6">
                            <mct-form-itens tipo="email" campo="pes_email" :valor="lista" titulo="Email" :obrigatorio="infoEscola.esc_tipo !== 1 ? true : false" :errors="errors" :modo="modo" transforme="lower"></mct-form-itens>
                        </div>
                        <div class="col-6">
                            <mct-form-itens tipo="url" campo="pes_url" :valor="lista" titulo="URL" obrigatorio="false" :errors="errors" :modo="modo" transforme="lower"></mct-form-itens>
                        </div>
                    </div>                    
                    <div class="row">
                        <div class="col-6" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens tipo="select" campo="pes_nac_codigo" :valor="lista" titulo="Nacionalidade" obrigatorio="false" :errors="errors" origem="nacionalidade" origemChave="nac_codigo" origemRetorno='nac_descricao' :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-6" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens tipo="select" campo="pes_cidn_codigo" :valor="lista" titulo="Natural de" obrigatorio="false" :errors="errors" origem="cidade" origemChave="cid_codigo" origemRetorno='cid_descricao' origemRetornoSec='est_uf' :modo="modo"></mct-form-itens>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens tipo="text" campo="pes_conjuge" :valor="lista" titulo="Conjuge" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-3" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens tipo="text" campo="pes_conjuge_cpf" :valor="lista" titulo="Conjuge CPF" obrigatorio="false" :errors="errors" mascara="cpf" :modo="modo"></mct-form-itens>
                        </div>
                    </div>

                    <div class="row" v-show="lista.pes_tipo_cad != 'A'">
                        <div class="col-6" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens tipo="text" campo="pes_pai" :valor="lista" titulo="Pai" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-6" v-show="lista.pes_tipo == 'F'">
                            <mct-form-itens tipo="text" campo="pes_mae" :valor="lista" titulo="Mãe" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>

                    <div class="row" v-show="lista.pes_tipo_cad == 'A'">
                        <div class="col-6">
                            <mct-form-itens tipo="select" campo="pes_pai_cod" :valor="lista" titulo="Pai" obrigatorio="false" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-6">
                            <mct-form-itens tipo="select" campo="pes_mae_cod" :valor="lista" titulo="Mãe" obrigatorio="false" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-6">
                            <mct-form-itens tipo="select" campo="pes_responsavel_cod" :valor="lista" titulo="Responsável" obrigatorio="false" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>

                    <fieldset class="well" v-show="lista.pes_tipo_cad == 'C'">
                        <legend class="well-legend">Dados Bancários</legend>
                        <div class="row">
                            <div class="col-8">
                                <mct-form-itens tipo="search" campo="pes_cnt_banco" :valor="lista" titulo="Banco" obrigatorio="false" :errors="errors" origem="banco" origemChave="bnc_codigo" origemRetorno='bnc_descricao' :modo="modo" tamanho="1"></mct-form-itens>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-3">
                                <mct-form-itens tipo="text" campo="pes_cnt_agencia" :valor="lista" titulo="Agência" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-3">
                                <mct-form-itens tipo="text" campo="pes_cnt_conta" :valor="lista" titulo="Conta" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-4">
                                <mct-form-itens tipo="select" campo="pes_cnt_operacao" :valor="lista" titulo="Operação" obrigatorio="false" tamanho="7" :errors="errors" :origemData="cntOperacao" :modo="modo"></mct-form-itens>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="well" v-show="lista.pes_tipo_cad == 'A'">
                        <legend class="well-legend">Dados Sociais</legend>
                        <div class="row">
                            <div class="col-4">
                                <mct-form-itens tipo="text" campo="pes_cartao_sus" :valor="lista" titulo="N.° Cartão SUS" :obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-4">
                                <mct-form-itens tipo="text" campo="pes_numero_censo" :valor="lista" titulo="N.° Censo" :obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-4">
                                <mct-form-itens tipo="currency" campo="pes_renda_bruta" :valor="lista" titulo="Renda Familar Bruta" :obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>                    
                        <div class="row">
                            <div class="col-4">
                                <mct-form-itens tipo="text" campo="pes_codigo_unidade" :valor="lista" titulo="Código da Unidade" :obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-8">
                                <mct-form-itens tipo="text" campo="pes_nome_autorizacao" :valor="lista" titulo="Pessoa Autorizada a Buscar na Escola" :obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>   

                        <div class="row">
                            <div class="col-3">
                                <mct-form-itens tipo="select" campo="pes_reside_em" :valor="lista" titulo="Reside em" obrigatorio="false" 
                                :origemData="[{value:1,text:'Casa Própria'}, {value:2,text:'Arrendada'}, {value:3,text:'Alugada'}, {value:4,text:'Cedida'},
                                            {value:5,text:'Outros'}]"
                                :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-3">
                                <mct-form-itens tipo="select" campo="pes_reside_tp_construcao" :valor="lista" titulo="Residência Tp. Construção" obrigatorio="false" 
                                :origemData="[{value:1,text:'Alvenaria'}, {value:2,text:'Madeira'}, {value:3,text:'Outros'}]"
                                :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-3">
                                <mct-form-itens tipo="select" campo="pes_reside_com" :valor="lista" titulo="Reside com" obrigatorio="false" 
                                :origemData="[{value:1,text:'Pais'}, {value:2,text:'Pai'}, {value:3,text:'Mãe'}, {value:4,text:'Avós'},
                                            {value:5,text:'Tios'}, {value:6,text:'Padrasto/Madrasta'}, {value:7,text:'Irmãos'}, {value:8,text:'Outros'}]"
                                :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-3">
                                <mct-form-itens tipo="integer" campo="pes_reside_qtde" :valor="lista" titulo="Reside com quantas pessoas?" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4">
                                <mct-form-itens tipo="select" campo="pes_irmaos_estudam_unidade" :valor="lista" titulo="Possui Irmãos que Estudam nesta Unidade" obrigatorio="false" 
                                :origemData="[{value:1,text:'Sim'}, {value:2,text:'Não'}]" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-sm-8">
                                <fieldset class="well">
                                    <legend class="well-legend">Nome dos Irmãos</legend>
                                    <span class="text-danger">Selecione um ou mais Nomes</span>                    
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <multiselect v-model="irmaos" :options="listaIrmaos" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" 
                                                placeholder="Selecione os Nomes" selectedLabel="Selecionar opção" deselectLabel="Pressione Enter para remover" selectLabel="Pressione enter para selecionar"
                                                label="pes_nome" track-by="pes_nome">
                                            </multiselect>
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </fieldset>

                    <fieldset class="well" v-show="lista.pes_tipo_cad == 'P'">
                        <legend class="well-legend">Graduações / Especializações</legend>
                        <div class="row">
                            <div class="col-12">
                                <mct-form-itens tipo="textarea" campo="pes_graduacoes" :valor="lista" titulo="Graduações (Separar com ;)" linhas="3" :obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>                            
                        </div>
                    </fieldset>                

                    <fieldset class="well">
                        <legend class="well-legend">Assinatuta Digitalizada</legend>
                        <div class="col-sm-4 text-right">
                            <div class="mct-foto2">
                                <div class="social">
                                    <img :src="img_pes_assinatura_img" alt="">
                                    <div class="social-wrapper">
                                        <div class="social-icon border border-light rounded-circle">
                                            <input id="inputfile2" type="file" @change="onFileChange($event,'pes_assinatura_img')" accept="image/*">
                                            <a @click.prevent.stop="onCustomFileChange('inputfile2')" title="Obter Imagem de um Arquivo" aria-label="Obter Imagem de um Arquivo">
                                                <span class="fa fa-upload fa-2x" aria-hidden="true"></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </fieldset>
                </div>

                
            </div>
            <mct-modal id="Capture">
                <mct-capture :img.sync="img_pes_foto_img" :img2.sync="lista['pes_foto_img']"></mct-capture>
            </mct-modal>              
        </div>              
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import helper from '@/components/funcoes/ExisteImgUrl'
import Multiselect from 'vue-multiselect'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctLogradouro from '@/components/comum/MctLogradouroC.vue'
import MctCapture from '@/components/comum/MctCapture.vue'
import MctModal from '@/components/comum/MctModalSHB.vue'

import jsonTpEmpresa from '@/components/tabsInternas/tp_empresa.json'
import jsonPesEstCivil from '@/components/tabsInternas/pes_estcivil.json'
import jsonCor from '@/components/tabsInternas/cor.json'
import jsonRaca from '@/components/tabsInternas/raca.json'
import jsonTpDeficiencia from '@/components/tabsInternas/tp_deficiencia.json'

import semImg from '@/assets/images/sem_foto.png'

export default {
  Name: 'Pessoas',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctLogradouro,
      MctFormItens,
      MctCapture,
      MctModal,
      Multiselect    
  },
  data () {
      return {
          lista: {},
          raca: jsonRaca,
          cor: jsonCor,
          tpDeficiencia: jsonTpDeficiencia,
          tpEmpresa: jsonTpEmpresa,
          cntOperacao: {},
          pesEstCivil: jsonPesEstCivil,
          pesTipoEmpresa: {},
          errors:{},
          message: '',
          status: 0,
          showCapture: false,
          img_pes_foto_img: null,
          img_pes_assinatura_img: null,
          listaIrmaos: [],
      }
  },
  methods: {    
    async setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            this.lista.pes_situacao = 1    
            this.lista.pes_tipo = 'F'
            this.lista.pes_tipo_cad = 'C'  
            this.lista.pes_sexo = 'M'  
            this.lista.audit_data_cadastro = this.moment(new Date()).format('YYYY-MM-DD')
            this.lista.audit_user_cadastro = this.infoLogado['usu_usuario'].toUpperCase()       
            this.lista.audit_local_cadastro = this.IP       
        } else if (this.modo == 'editar'){
            this.lista.audit_data_atualiza = this.moment(new Date()).format('YYYY-MM-DD')
            this.lista.audit_user_atualiza = this.infoLogado['usu_usuario'].toUpperCase()       
            this.lista.audit_local_atualiza = this.IP
        }
        if(this.lista.pes_foto !== null && this.lista.pes_foto !== ''){
            const URL = `/escpessoas/image/${this.lista.pes_codigo}`;
            await axios.get(URL)
                .then(response => {                        
                    this.img_pes_foto_img = 'data:image/jpg;base64,'+response.data || semImg;
                })
                .catch(response => {
                  this.message = response.response.data.message
                  this.errors = response.response.data.errors
                  this.status = response.response.status
                })
        }
        if(this.lista.pes_assinatura !== null && this.lista.pes_assinatura !== ''){
            const URL = `/escpessoas/assinatura/${this.lista.pes_codigo}`;
            await axios.get(URL)
                .then(response => {                        
                    this.img_pes_assinatura_img = 'data:image/jpg;base64,'+response.data || semImg;
                })
                .catch(response => {
                  this.message = response.response.data.message
                  this.errors = response.response.data.errors
                  this.status = response.response.status
                })
        }
    },
    onAbreCaptura () {
        this.showCapture = true
        $('#MctModalCapture').modal('show')        
    },
    onFileChange(e, field) {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length)
            return
        this.createImage(files[0], field)
    },
    createImage(file, field) {
        let reader = new FileReader()
        let vm = this
        reader.onload = (e) => {
            vm[`img_${field}`] = e.target.result
            vm.lista[field] = e.target.result
        };
        reader.readAsDataURL(file)
    },   
    onCustomFileChange (id) {
        var link = document.getElementById(id);
        link.click();
    },
    async getIrmaos () {
        let URL = `/escpessoas/combo`
        await axios.get(`${URL}`)
            .then(response => {
                this.listaIrmaos = response.data.data                
            })
            .catch(response => {

            })
    },                 
  },
  computed: {
    ...mapState('Login',['infoLogado','IP','infoEscola'/*,'Entidade','Exercicio','Escola'*/]),

    irmaos: {
        get: function () {
            if (typeof this.lista.pes_nomes_irmaos !== 'undefined' && this.lista.pes_nomes_irmaos !== null && this.lista.pes_nomes_irmaos !== ''){
                let req = this.lista.pes_nomes_irmaos.split(',')
                let resultado = [];
                req.forEach(el =>{
                    resultado.push(...this.listaIrmaos.filter(el2 => el2.pes_nome === el))
                })
                return resultado
            }
        },
        set: function (newValue) {
            const res = newValue.map(item => item.pes_nome)
            this.lista.pes_nomes_irmaos = res.toString()
        }
    }
  },
  created() {
      this.getIrmaos()
  }     
}
</script>

<style scoped>


.mct-foto{
    position: absolute;
    right: 30px;
    height: 130px;
    width: 130px;
    background-color: #F0F3F5!important;
    border: 1px solid #C2CFD6;
    margin: 2px;
    padding: 1px;
    overflow: hidden;
}

.mct-foto .social{
    position:relative;
    height: 126px;
}

.mct-foto .social img{
    height: 100%;
}

.mct-foto .social-wrapper{
    position: absolute;
    left: 2px;
    bottom: 2px;
}

.mct-foto .social-icon{  
    display: inline-block;
    text-align: center;
    height: 24px;
    width: 24px;
    background-color: #fff!important;
}

.mct-foto .social-icon a{
    cursor: pointer;
}

#inputfile, #inputfile2 {
	display: none;
}

.mct-foto2{
    background-color: #F1F1F1;
    border: 1px dashed black;
    padding: 10px;
}

.mct-foto2 .social-icon{  
    height: 36px;
    width: 36px;
}

</style>