<template>
    <div class="container mct-form">
        <mct-topo-lista :titulo="titulo" :subtitulo="'Manutenção de '+ titulo + ' - ' + modo.toUpperCase()"></mct-topo-lista>    
        <mct-alertas :mensagem="message" :errors="errors" :status="status" :modo="modo" v-show="status !== 0"></mct-alertas>
        <div class="container-fluid position-relative">
            <mct-form>
                <div class="row">
                    <div class="col-2">
                        <mct-form-itens tipo="number" campo="atest_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
                    </div>    
                    <div class="col-2">
                        <mct-form-itens tipo="date" campo="atest_data" :valor="lista" titulo="Data Cadastro" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                    </div>
                    <div class="col-4">
                        <mct-form-itens tipo="select" campo="atest_tipo" :valor="lista" titulo="Tipo" obrigatorio="true" 
                        :origemData="[{value:1,text:'Declaração de Trabalho Voluntário'}, {value:2,text:'Declaração de Frequência'}, {value:3,text:'Declaração de Transferência'}, {value:4,text:'Declaração Funcionários'}]" :errors="errors" :modo="modo"></mct-form-itens>
                    </div>
                    <div class="col-2">
                        <mct-form-itens tipo="checkbox" campo="atest_status" :valor="lista" :titulo="lista.atest_status == 1 ? 'Cadastro Ativo.' : 'Cadastro Inativado.'" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                    </div>
                </div>
                <div class="row">
                    <div class="col-8">
                        <mct-form-itens tipo="search" campo="pes_codigo" :valor="lista" titulo="Aluno/Pessoa" obrigatorio="true" tamanho="2" origem="esc_pessoas" 
                            origemChave="pes_codigo" origemRetorno='pes_nome' :errors="errors" :modo="modo"
                        ></mct-form-itens>    
                    </div>
                    <div class="col-4" v-show="lista.atest_tipo === 2">
                        <mct-form-itens tipo="select" campo="matri_codigo" :valor="lista" titulo="Matrícula Aluno" obrigatorio="true" origem="esc_matricula_aluno" 
                            :origemFiltro="{matri_situacao: 'M', matri_alu_codigo: lista.pes_codigo}" origemChave="matri_codigo" origemRetorno='matri_matricula' :errors="errors" :modo="modo"
                        ></mct-form-itens>    
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mct-form-itens tipo="search" campo="resp_codigo" :valor="lista" titulo="Responsável" obrigatorio="true" tamanho="2" origem="esc_professores" 
                            origemChave="pes_codigo" origemRetorno='pes_nome' :errors="errors" :modo="modo" @setDataRetorno="setProfessores"
                        ></mct-form-itens>   
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mct-form-itens tipo="search" campo="uni_codigo" :valor="lista" titulo="Unidade Escolar" obrigatorio="true" tamanho="2" origem="esc_unidades" 
                            origemChave="uni_codigo" origemRetorno='uni_descricao' :errors="errors" :modo="modo"
                        ></mct-form-itens>   
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <fieldset class="well">
                            <legend class="well-legend">Modelo do Atestado/ Declaração</legend>                                
                            <div class="row">
                                <div class="col-8">
                                    <mct-form-itens tipo="select" campo="modela_codigo" :valor="lista" titulo="Modelo do Atestado/ Declaração" obrigatorio="true" 
                                        origem="esc_modelos_atestados" origemChave="modela_codigo" origemRetorno='modela_descricao' :errors="errors" :modo="modo"></mct-form-itens>
                                </div>
                                <div class="col-4">
                                    <button type="button" class="btn btn-danger text-white mr-1" style="margin-top: 19px;" aria-label="Left Align" @click.prevent.stop="verAtestado()">
                                        <span class="fa fa-file-pdf-o fa-lg text-white" aria-hidden="true"></span> Ver Atestado/ Declaração </button>
                                </div>
                            </div>
                        </fieldset>
                        <b-modal size="lg" id="texto-sel" title="Atestado/ Declaração">
                            <div ref="atestadoTexto" id="atestadoTexto" v-html="lista.atest_texto" style="max-height:400px;overflow-y: scroll;" class="ql-editor"></div>   
                            <template v-slot:modal-footer>
                                <button type="button" class="btn btn-dark btn-sm text-white" aria-label="Left Align" @click="onImprimirAtestado()">
                                    <span class="fa fa-print fa-sm text-white" aria-hidden="true"></span> Imprimir </button>
                                <button type="button" class="btn btn-danger btn-sm text-white" aria-label="Left Align" @click="$bvModal.hide('texto-sel')">
                                    <span class="fa fa-times fa-sm text-white" aria-hidden="true"></span> Fechar </button>
                            </template>
                        </b-modal>
                    </div>
                </div>

            </mct-form>
        </div>
        <mct-form-botoes @submit="submit()" :modo="modo"></mct-form-botoes>
    </div>     
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'

import moment from 'moment'
import jsPDF from 'jspdf'

import MctTopoLista from '@/components/comum/MctTopoLista.vue'
import MctForm from '@/components/comum/MctForm.vue'
import MctFormBotoes from '@/components/comum/MctFormBotoes.vue'
import MctAlertas from '@/components/comum/MctAlertas.vue' 
import MctMixinsForm from '@/components/funcoes/MctMixinsForm'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'EscContratos',
  mixins: [MctMixinsForm],  
  props: ['source','titulo','modo'],
  components: {
    MctForm,
    MctTopoLista,
    MctFormBotoes,
    MctAlertas,
    MctFormItens
  },
  data () {
      return {
        
      }
  },
  methods: {
    onImprimirAtestado () {
        printJS({printable: '<style>.ql-editor p{ line-height: 1.2rem!important;}</style><div class="ql-editor" style="overflow: hidden;">'+this.lista.atest_texto+'</div>', type: 'raw-html', scanStyles: false, css: 'https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.6/quill.core.min.css'})
    },
    async onValida() {
        let msgs = ''
        if (this.lista.atest_tipo == null || this.lista.atest_tipo == ''){
            msgs += 'O campo Tipo tem um valor inválido; </br>';
        }
        if (this.lista.atest_data == null || this.lista.atest_data == 0){
            msgs += 'Digite uma Data de Cadastro válida; </br>';
        }
        if (this.lista.pes_codigo == null || this.lista.pes_codigo == ''){
            msgs += 'Selecione um Aluno/Pessoa; </br>';
        }        
        if (msgs !== ''){
            this.isValidated = await false
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Incluir / Alterar o Registro',
                html/*text*/: msgs,
                /*footer: '<a href>Why do I have this issue?</a>'*/
                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
            })        
        }else{
            this.isValidated = await true
        }
    },      
    setData () {
        if (this.modo == 'novo'){
            this.lista.atest_status = 1 
            this.lista.atest_ent = this.Entidade
            this.lista.atest_esc = this.Escola
            this.lista.atest_ano = this.Exercicio
            this.lista.atest_data = new Date()
            this.lista.audit_data_cadastro = this.moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
            this.lista.audit_usu_cadastro = this.infoLogado['usu_usuario']         
            this.lista.audit_local_cadastro = this.IP
            this.lista.resp_codigo = this.infoLogado.pes_codigo
        }else if (this.modo == 'editar'){
            this.lista.audit_data_atualiza = this.moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
            this.lista.audit_usu_atualiza = this.infoLogado['usu_usuario']            
            this.lista.audit_local_atualiza = this.IP
        }else if (this.modo == 'cancelar'){
            //this.lista.atest_status = 0; 
            this.lista.audit_data_cancelado = this.moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
            this.lista.audit_usu_cancelado = this.infoLogado['usu_usuario']            
            this.lista.audit_local_cancelado = this.IP
        }
    },
    async verAtestado () {
        this.$bvModal.show('texto-sel')
    },
    setProfessores(data) {
        //console.log(data);
    }
  },
  computed: {
    ...mapState('Login',['infoLogado','Entidade','Exercicio','Escola','IP']),
  }, 
  created() {

  },
}
</script>

<style scoped>

    @media print {
        body * {
            visibility: hidden;
        }
        #atestadoTexto, #atestadoTexto * {
            visibility: visible;
        }
        #atestadoTexto {
            position: fixed;
            left: 0;
            top: 0;
        }
    }
</style>