<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" :titAlerta="`ERRO: ${status}`" :msgAlerta="message" :tipoAlerta="tipoAlerta" @setData="setData">
        <div slot="formulario">
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="menu_codigo" :valor="lista" titulo="Código" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-3">
                    <mct-form-itens tipo="checkbox" campo="menu_ativo" :valor="lista" titulo="Ativo" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <mct-form-itens coluna="0" tipo="select" campo="mod_codigo" :valor="lista" titulo="Módulo" obrigatorio="false" origem="modulos" origemChave="mod_codigo" origemRetorno='mod_descricao' :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="select" campo="smod_codigo" :valor="lista" titulo="Sub Módulo" obrigatorio="false"
                    origem="modulos_sub" origemChave="smod_codigo" origemRetorno='smod_descricao' origemRetornoSec='smod_mod_codigo'
                    :origemFiltro="{smod_mod_codigo: lista.mod_codigo}"
                    :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="select" campo="menu_tipo" :valor="lista" titulo="Tipo" obrigatorio="true" :errors="errors" :modo="modo" :origemData="[{value: 0, text: 'MENU PRINCIPAL'}, {value: 1, text: 'MENU LATERAL'}]"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="text" campo="menu_descricao" :valor="lista" titulo="Descrição" obrigatorio="true" :errors="errors" :modo="modo" transforme="camel"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="text" campo="menu_pagina" :valor="lista" titulo="Rota Aces." obrigatorio="false" :errors="errors" :modo="modo" transforme="lower"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <!--<mct-form-itens coluna="0" tipo="select" campo="menu_pai" :valor="lista" titulo="Menu Pai" obrigatorio="false"
                    origem="menu" origemChave="menu_codigo" origemRetorno='menu_descricao' origemRetornoSec='menu_tipo'
                    :origemFiltro="lista.smod_codigo === null ?
                    {mod_codigo: lista.mod_codigo,menu_tipo: lista.menu_tipo} :
                    {mod_codigo: lista.mod_codigo,smod_codigo: lista.smod_codigo,menu_tipo: lista.menu_tipo}"
                    :errors="errors" :modo="modo"></mct-form-itens>-->
                    <mct-form-itens coluna="0" tipo="select" campo="menu_pai" :valor="lista" titulo="Menu Pai" obrigatorio="false"
                    origem="menu" origemChave="menu_codigo" origemRetorno='menu_descricao' origemRetornoSec='menu_tipo'
                    :origemFiltro="{menu_tipo: lista.menu_tipo}"
                    :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="text" campo="menu_icon" :valor="lista" titulo="Menu Ícone" obrigatorio="false" :errors="errors" :modo="modo" transforme="lower"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="menu_nivel" :valor="lista" titulo="Nível" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="menu_ordem" :valor="lista" titulo="Ordem" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>

        </div>
    </mct-padrao>
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'

import MctPadrao from '../comum/MctFormPadrao.vue'
import MctFormItens from '../comum/MctFormItens.vue'


export default {
  Name: 'Menu',
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
          lista: {},
          errors:{},
          message: '',
          status: 0,
          tipoAlerta: '',
          ultreg: 0,
          //submodulo: {},
          //menu: {}
      }
  },
  methods: {
    setData (lista) {
        this.lista = lista
        if (this.modo == 'novo'){
            this.lista.menu_ativo = 1
        }
    },

    getUltReg (modulo, submodulo) {
        axios.get(`menu/ultreg/`+modulo+`/`+submodulo)
        .then(response => {
            this.lista.menu_codigo = response.data[0].ultreg
        })
        .catch(response => {
            console.log(response)
        })
    },
    /*getSubModulo (modulo) {
        var vm = this
        axios.get(`modulos_sub/combo/`+modulo)
        .then(function(response) {
            vm.$data.submodulo = response.data
        })
        .catch(function(response) {
            console.log(response)
        })
    },
    getMenu (modulo) {
        var vm = this
        axios.get(`menu/modulo/`+modulo)
        .then(function(response) {
            vm.$data.menu = response.data
        })
        .catch(function(response) {
            console.log(response)
        })
    },
    getMenuSubModulo (modulo, submodulo) {
        var vm = this
        axios.get(`menu/modulo/`+modulo+`/`+submodulo)
        .then(function(response) {
            vm.$data.menu = response.data
        })
        .catch(function(response) {
            console.log(response)
        })
    },*/
  },
  computed: {
    ...mapState('Login',['infoLogado']),

  },
  created() {
    //this.fetchIndexData()
  },
  watch: {
      'lista.menu_pai' () {
        if ((this.modo === 'novo' /*|| this.modo === 'editar'*/) && (this.lista.menu_tipo) && (this.lista.menu_pai)){
            axios.get(`menu/pai/${this.lista.menu_tipo}/${this.lista.menu_pai}`)
            .then(response => {
                this.lista.menu_nivel = response.data[0].nivel
                this.lista.menu_ordem = response.data[0].ordem
            })
            .catch(response => {
                console.log(response)
            })
        }
      },
    'lista.mod_codigo' () {
        if (this.modo === 'novo' && this.lista.mod_codigo){
            this.getUltReg(this.lista.mod_codigo,0)
        }
        if ((this.modo === 'novo' /*|| this.modo === 'editar'*/) && (this.lista.menu_tipo) /*&& (this.lista.menu_pai === null)*/){
            axios.get(`menu/pai/${this.lista.menu_tipo}/${this.lista.menu_pai === null ? 0 : this.lista.menu_pai}`)
            .then(response => {
              console.log(response);
                this.lista.menu_nivel = response.data[0].nivel
                this.lista.menu_ordem = response.data[0].ordem
            })
            .catch(response => {
                console.log(response)
            })
        }
        /*if (this.lista.mod_codigo){
            this.getSubModulo(this.lista.mod_codigo)
            this.getMenu(this.lista.mod_codigo)
        }*/
      },
    'lista.smod_codigo' () {
        if (this.modo === 'novo' && this.lista.smod_codigo){
            this.getUltReg(this.lista.mod_codigo,this.lista.smod_codigo)
        }
        /*if (this.lista.smod_codigo){
            this.getMenuSubModulo(this.lista.mod_codigo,this.lista.smod_codigo)
        }*/
      },
    'lista.menu_tipo' () {
        if ((this.modo === 'novo' /*|| this.modo === 'editar'*/) && (this.lista.menu_tipo) /*&& (this.lista.menu_pai)*/){
            axios.get(`menu/pai/${this.lista.menu_tipo}/${this.lista.menu_pai === null ? 0 : this.lista.menu_pai}`)
            .then(response => {
                this.lista.menu_nivel = response.data[0].nivel
                this.lista.menu_ordem = response.data[0].ordem
            })
            .catch(response => {
                console.log(response)
            })
        }
      },
  }
}
</script>
