<template>
  <!-- RELATORIOS CONTAS A RECEBER - A RECEBER -->
  <div>
    <div class="row mb-2">
      <div class="col-lg-8">
        <mct-form-itens
          tipo="select"
          campo="ccusto"
          :valor="filtro"
          titulo="Centro de Custos"
          origem="esc_centro_custo"
          origemChave="ccst_codigo"
          origemRetorno="ccst_descricao"
          origemRetornoSec="ccst_codigo"
          :errors="{}"
          modo="editar"
        ></mct-form-itens>
      </div>
      <div class="col-lg-4">
        <mct-form-itens
          tipo="select"
          campo="ano_letivo"
          :valor="filtro"
          titulo="Ano Letivo"
          :errors="{}"
          modo="editar"
        ></mct-form-itens>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-4">
        <fieldset class="well h-100">
          <legend class="well-legend">Data Base</legend>
          <div class="row">
            <div class="col-lg-12 mb-2">
              <mct-form-itens
                tipo="date"
                campo="data_base"
                :valor="filtro"
                titulo="Data Base"
                showTitulo="false"
                :errors="{}"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-lg-4">
        <fieldset class="well h-100">
          <legend class="well-legend">Data</legend>
          <div class="row">
            <div class="col-lg-12 mb-2">
              <mct-form-itens
                tipo="date"
                campo="data_ini"
                :valor="filtro"
                titulo="Inicial"
                showTitulo="false"
                :errors="{}"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <mct-form-itens
                tipo="date"
                campo="data_fin"
                :valor="filtro"
                titulo="Final"
                showTitulo="false"
                :errors="{}"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-lg-4">
        <fieldset class="well h-100">
          <legend class="well-legend">Tipo Data</legend>
          <div class="row">
            <div class="col-lg-12 mb-2">
              <mct-form-itens
                tipo="radio"
                campo="tipodata"
                :valor="filtro"
                titulo="Tipo de Data"
                showTitulo="false"
                :errors="{}"
                :origemData="[
                  { value: 'E', text: 'Emissão' },
                  { value: 'V', text: 'Vencimento' },
                  { value: 'P', text: 'Pagamento' },
                  { value: 'X', text: 'Vencimento ou Pagamento' }
                ]"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-lg-12 mb-2">
        <fieldset class="well h-100">
          <legend class="well-legend">Cliente/ Aluno</legend>
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-4">
                  <mct-form-itens
                    tipo="number"
                    campo="pessoa_ini"
                    :valor="filtro"
                    titulo="Inicial"
                    :errors="{}"
                    modo="editar"
                  ></mct-form-itens>
                </div>
                <div class="col-8">
                  <mct-form-itens
                    tipo="select"
                    campo="pessoa_ini"
                    :valor="filtro"
                    titulo=" "
                    origem="esc_pessoas"
                    origemChave="pes_codigo"
                    origemRetorno="pes_nome"
                    origemRetornoSec="pes_codigo"
                    :errors="{}"
                    modo="editar"
                  ></mct-form-itens>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="row">
                <div class="col-4">
                  <mct-form-itens
                    tipo="number"
                    campo="pessoa_fin"
                    :valor="filtro"
                    titulo="Final"
                    :errors="{}"
                    modo="editar"
                  ></mct-form-itens>
                </div>
                <div class="col-8">
                  <mct-form-itens
                    tipo="select"
                    campo="pessoa_fin"
                    :valor="filtro"
                    titulo=" "
                    origem="esc_pessoas"
                    origemChave="pes_codigo"
                    origemRetorno="pes_nome"
                    origemRetornoSec="pes_codigo"
                    :errors="{}"
                    modo="editar"
                  ></mct-form-itens>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-lg-12">
        <fieldset class="well h-100">
          <legend class="well-legend">Responsável</legend>
          <div class="row">
            <div class="col-lg-12">
              <div class="row">
                <div class="col-4">
                  <mct-form-itens
                    tipo="number"
                    campo="responsavel_ini"
                    :valor="filtro"
                    titulo="Inicial"
                    :errors="{}"
                    modo="editar"
                  ></mct-form-itens>
                </div>
                <div class="col-8">
                  <mct-form-itens
                    tipo="select"
                    campo="responsavel_ini"
                    :valor="filtro"
                    titulo=" "
                    origem="esc_pessoas"
                    origemChave="pes_codigo"
                    origemRetorno="pes_nome"
                    origemRetornoSec="pes_codigo"
                    :errors="{}"
                    modo="editar"
                  ></mct-form-itens>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="row">
                <div class="col-4">
                  <mct-form-itens
                    tipo="number"
                    campo="responsavel_fin"
                    :valor="filtro"
                    titulo="Final"
                    :errors="{}"
                    modo="editar"
                  ></mct-form-itens>
                </div>
                <div class="col-8">
                  <mct-form-itens
                    tipo="select"
                    campo="responsavel_fin"
                    :valor="filtro"
                    titulo=" "
                    origem="esc_pessoas"
                    origemChave="pes_codigo"
                    origemRetorno="pes_nome"
                    origemRetornoSec="pes_codigo"
                    :errors="{}"
                    modo="editar"
                  ></mct-form-itens>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-8">
        <div class="row mb-2">
          <div class="col-lg-12">
            <mct-form-itens
              tipo="select"
              campo="tprecebimento"
              :valor="filtro"
              titulo="Tipo Recebimento"
              :errors="{}"
              modo="editar"
              origem="esc_tp_recebimento"
              origemChave="trec_codigo"
              origemRetorno="trec_descricao"
              origemRetornoSec="trec_codigo"
            ></mct-form-itens>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col-lg-12">
            <mct-form-itens
              tipo="select"
              campo="tpquitacao"
              :valor="filtro"
              titulo="Tipo Quitação"
              :errors="{}"
              modo="editar"
              origem="esc_tp_quitacao"
              origemChave="tqui_codigo"
              origemRetorno="tqui_descricao"
              origemRetornoSec="tqui_codigo"
            ></mct-form-itens>
          </div>
        </div>
      </div>
      <div class="col-4">
        <fieldset class="well h-100">
          <legend class="well-legend">Modelo Relatório</legend>
          <div class="row">
            <div class="col-lg-12 mb-2">
              <mct-form-itens
                tipo="radio"
                campo="modelo"
                :valor="filtro"
                titulo="Modelo Relatório"
                showTitulo="false"
                :errors="{}"
                :origemData="[
                  { value: 1, text: 'Modelo 01' },
                  { value: 2, text: 'Modelo 02' }
                ]"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-lg-12">
        <mct-form-itens
          tipo="checkbox"
          campo="op01"
          :valor="filtro.opcoes"
          titulo="Somente quitadas sem cheque e cartão;"
          :errors="{}"
          modo="editar"
          showTitulo="false"
        ></mct-form-itens>
      </div>
      <div class="col-lg-12">
        <mct-form-itens
          tipo="checkbox"
          campo="op02"
          :valor="filtro.opcoes"
          titulo="Visualizar responsáveis;"
          :errors="{}"
          modo="editar"
          showTitulo="false"
        ></mct-form-itens>
      </div>
      <div class="col-lg-12">
        <mct-form-itens
          tipo="checkbox"
          campo="op03"
          :valor="filtro.opcoes"
          titulo="Visualizar dados das quitações/cheques;"
          :errors="{}"
          modo="editar"
          showTitulo="false"
        ></mct-form-itens>
      </div>
      <div class="col-lg-12">
        <mct-form-itens
          tipo="checkbox"
          campo="op04"
          :valor="filtro.opcoes"
          titulo="Visualizar resumo por tipo de quitações;"
          :errors="{}"
          modo="editar"
          showTitulo="false"
        ></mct-form-itens>
      </div>
      <div class="col-lg-12">
        <mct-form-itens
          tipo="checkbox"
          campo="op05"
          :valor="filtro.opcoes"
          titulo="Visualizar resumo por tipo de recebimento;"
          :errors="{}"
          modo="editar"
          showTitulo="false"
        ></mct-form-itens>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-sm-12 text-left">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          aria-label="Left Align"
          @click="onCReceberDiariaMod1"
        >
          <span class="fa fa-refresh fa-lg" aria-hidden="true"></span>
          Visualizar
        </button>
      </div>
    </div>
    <b-modal id="relatorio" title="Relatório Financeiro" hide-footer hide-header hide-title size="xl">
      <PDFView :url="localOutputPdf === null ? '' : localOutputPdf" />
    </b-modal>     
  </div>
</template>

<script>
import axios from "@/http";
import moment from "moment";
import PDFView from "@/components/pdfviewer/PDFView.vue";
import MctFormItens from "@/components/comum/MctFormItens.vue";
import MctPadraoRelatorios from "@/components/funcoes/MctPadraoRelatorios";

export default {
  props: ["outputPdf"],
  mixins: [MctPadraoRelatorios],
  components: {
    PDFView,
    MctFormItens: MctFormItens
  },
  data() {
    return {
      data: [],
      filtro: {},
      localOutputPdf: {}
    };
  },
  created() {
    this.localOutputPdf = this.outputPdf;
    this.filtro = {
      detalhado: 1,
      tipo: "R",
      modelo: 1,
      opcoes: {},
      tipoconta: "T",
      tipodata: "E",
      tipoordem: 3,
      tipoagrupar: 1,
      tipovisualizacao: 1,
      data_base: null,
      data_ini: null,
      data_fin: null,
      pessoa_ini: null,
      pessoa_fin: null,
      conta_ini: null,
      conta_fin: null,
      tprecebimento: null,
      tpquitacao: null,
      ccusto: null
    };
  },
  methods: {
    async getData() {
      await axios
        .get("/relatorios/financeiro", {
          params: {
            detalhado: this.filtro.detalhado,
            tipoordem: this.filtro.tipoordem,
            tipoagrupar: this.filtro.tipoagrupar,
            pessoaini: this.filtro.pessoa_ini,
            pessoafin: this.filtro.pessoa_fin,
            containi: this.filtro.conta_ini,
            contafin: this.filtro.conta_fin,
            ccusto: this.filtro.ccusto,
            tipo: this.filtro.tipo,
            tipoc: this.filtro.tipoconta,
            database: moment(this.filtro.data_base).isValid()
              ? moment(this.filtro.data_base).format("YYYY-MM-DD")
              : null,
            emiini:
              moment(this.filtro.data_ini).isValid() &&
              this.filtro.tipodata === "E"
                ? moment(this.filtro.data_ini).format("YYYY-MM-DD")
                : null,
            emifin:
              moment(this.filtro.data_fin).isValid() &&
              this.filtro.tipodata === "E"
                ? moment(this.filtro.data_fin).format("YYYY-MM-DD")
                : null,
            vencini:
              moment(this.filtro.data_ini).isValid() &&
              this.filtro.tipodata === "V" || this.filtro.tipodata === "X"
                ? moment(this.filtro.data_ini).format("YYYY-MM-DD")
                : null,
            vencfin:
              moment(this.filtro.data_fin).isValid() &&
              this.filtro.tipodata === "V" || this.filtro.tipodata === "X"
                ? moment(this.filtro.data_fin).format("YYYY-MM-DD")
                : null,
            quitini:
              moment(this.filtro.data_ini).isValid() &&
              this.filtro.tipodata === "P" || this.filtro.tipodata === "X"
                ? moment(this.filtro.data_ini).format("YYYY-MM-DD")
                : null,
            quitfin:
              moment(this.filtro.data_fin).isValid() &&
              this.filtro.tipodata === "P" || this.filtro.tipodata === "X"
                ? moment(this.filtro.data_fin).format("YYYY-MM-DD")
                : null
          },
          paramsSerializer: function(params) {
            return jQuery.param(params);
          }
        })
        .then(response => {
          this.data = response.data.data;
        })
        .catch(response => {
          console.log(response.message);
        });
    },
    async onCReceberDiariaMod1() {
      await this.getData();

      await this.onCreatePDF("L");
      /*CABEÇALHO*/
      this.title = "RESUMO DIÁRIO - CONTAS A RECEBER E RECEBIDAS";
      this.onHeaderPDF();
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"normal");
      this.pdf.setFontSize(7.5);

      if (this.filtro.ccusto) {
        this.pdf.text(`CENTRO DE CUSTO: ${this.filtro.ccusto}`, 8, this.lin);
        this.onAddLine(this.jump);
      }
      if (moment(this.filtro.data_ini).isValid()) {
        this.pdf.text(
          `PERÍODO DE ${
            this.filtro.tipodata === "E"
              ? "EMISSÃO"
              : this.filtro.tipodata === "V"
              ? "VENCIMENTO"
              : this.filtro.tipodata === "P"
              ? "PAGAMENTO"
              : this.filtro.tipodata === "X"
              ? "VENCIMENTO OU PAGAMENTO"
              : ""
          } ENTRE ${moment(this.filtro.data_ini).format(
            "DD/MM/YYYY"
          )} A ${moment(this.filtro.data_fin).format("DD/MM/YYYY")}`,
          8,
          this.lin
        );
        this.onAddLine(this.jump);
      }
      if (this.filtro.pessoa_ini) {
        this.pdf.text(
          `FORNECEDOR ${this.filtro.pessoa_ini} ATÉ ${this.filtro.pessoa_fin}`,
          8,
          this.lin
        );
        this.onAddLine(this.jump);
      }
      if (this.filtro.tprecebimento) {
        this.pdf.text(
          `TIPO DE RECEBIMENTO: ${this.filtro.tprecebimento}`,
          8,
          this.lin
        );
        this.onAddLine(this.jump);
      }
      if (this.filtro.tprecebimento) {
        this.pdf.text(
          `TIPO DE QUITAÇÃO: ${this.filtro.tpquitacao}`,
          8,
          this.lin
        );
        this.onAddLine(this.jump);
      }
      this.onAddLine(this.jump);

      this.onAddLine(this.jump);
      this.pdf.setFillColor(192, 192, 192);
      this.pdf.rect(8, this.lin - 3, this.widthPage, this.jump + 1, "FD");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("NOME", 10, this.lin);
      this.pdf.text("DOCUMENTO", 90, this.lin, { align: "right" });
      this.pdf.text("DT.EMIS.", 110, this.lin, { align: "right" });
      this.pdf.text("DT.VENC.", 130, this.lin, { align: "right" });
      this.pdf.text("VALOR DOC", 150, this.lin, { align: "right" });
      this.pdf.text("SLD.A REC.", 170, this.lin, { align: "right" });
      this.pdf.text("JUR+MU", 185, this.lin, { align: "right" });
      this.pdf.text("DESC.", 200, this.lin, { align: "right" });
      this.pdf.text("TOT. REC.", 220, this.lin, { align: "right" });
      this.pdf.text("DT. PGTO", 240, this.lin, { align: "right" });
      this.pdf.text("TIPO QUITAÇÃO", 242, this.lin);
      this.onAddLine(this.jump + 1);

      var somaJurMul = 0;
      var somaDesc = 0;
      var somaValorRecebido = 0;
      var somaValorAReceber = 0;
      var somaJurMulForn = 0;
      var somaDescForn = 0;
      var somaValorRecebidoForn = 0;
      var somaValorAReceberForn = 0;

      var grupo = 0;
      var grupoDoc = 0;

      for (var index in this.data) {
        this.pdf.setFont("courier");
        /*NOME PESSOA*/
        if (grupo !== this.data[index].pes_codigo) {
          grupo = this.data[index].pes_codigo;
          this.pdf.setFontSize(7.5);
          this.pdf.setFont('helvetica',"normal");
          this.pdf.text(
            this.data[index].pes_nome + " - " + this.data[index].pes_codigo,
            10,
            this.lin
          );
        }
        if (grupoDoc !== this.data[index].docp_codigo) {
          grupoDoc = this.data[index].docp_codigo;
        }

        this.pdf.setFontSize(7.5);
        this.pdf.setFont('helvetica',"bold");
        this.pdf.text(String(this.data[index].docp_numero), 90, this.lin, {
          align: "right"
        });
        this.pdf.setFont('helvetica',"normal");
        this.pdf.text(
          String(this.$options.filters.data(this.data[index].doc_emissao)) !==
            "null"
            ? String(this.$options.filters.data(this.data[index].doc_emissao))
            : "",
          110,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.data(this.data[index].docp_vencimento)
          ) !== "null"
            ? String(
                this.$options.filters.data(this.data[index].docp_vencimento)
              )
            : "",
          130,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(this.$options.filters.moeda(this.data[index].docp_valor)),
          150,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(this.$options.filters.moeda(this.data[index].docp_vlr_apagar)),
          170,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              Number(this.data[index].docp_vlr_juros) +
                Number(this.data[index].docp_vlr_multa)
            )
          ),
          185,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(this.data[index].docp_vlr_desconto)
          ),
          200,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(this.$options.filters.moeda(this.data[index].docp_vlr_pago)),
          220,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.data(this.data[index].docp_pagamento)
          ) !== "null"
            ? String(
                this.$options.filters.data(this.data[index].docp_pagamento)
              )
            : "",
          240,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          this.data[index].tqui_descricao !== null
            ? String(this.data[index].tqui_descricao)
            : "",
          242,
          this.lin
        );
        somaValorRecebido += Number(this.data[index].docp_vlr_pago);
        somaValorRecebidoForn += Number(this.data[index].docp_vlr_pago);

        if (
          parseInt(index) + 1 == this.data.length ||
          grupoDoc !==
            this.data[
              parseInt(index) + 1 < this.data.length
                ? parseInt(index) + 1
                : index
            ].docp_codigo
        ) {
          somaValorAReceber += Number(this.data[index].docp_vlr_apagar);
          somaJurMul +=
            Number(this.data[index].docp_vlr_juros) +
            Number(this.data[index].docp_vlr_multa);
          somaDesc += Number(this.data[index].docp_vlr_desconto);

          somaValorAReceberForn += Number(this.data[index].docp_vlr_apagar);
          somaJurMulForn +=
            Number(this.data[index].docp_vlr_juros) +
            Number(this.data[index].docp_vlr_multa);
          somaDescForn += Number(this.data[index].docp_vlr_desconto);
        }
        this.onAddRegLine();
        this.onAddLine(this.jump);

        if (
          parseInt(index) + 1 == this.data.length ||
          grupo !==
            this.data[
              parseInt(index) + 1 < this.data.length
                ? parseInt(index) + 1
                : index
            ].pes_codigo
        ) {
          this.onAddLine(1);
          this.pdf.setFont("courier");
          this.pdf.setFontSize(7.5);
          this.pdf.setFont('helvetica',"bold");
          if (this.filtro.opcoes.op02 && this.filtro.opcoes.op02 === 1) {
            if (this.data[index].resp_codigo !== null)
              this.pdf.text(
                String(
                  `RESP.: ${this.data[index].resp_codigo} - ${this.data[index].resp_nome}`
                ),
                10,
                this.lin
              );
          }
          this.pdf.text(
            String(`TOTAL DO CLIENTE (${this.data[index].pes_codigo}):`),
            130,
            this.lin,
            { align: "right" }
          );
          this.pdf.text(
            String(this.$options.filters.moeda(somaValorAReceberForn)),
            170,
            this.lin,
            { align: "right" }
          );
          this.pdf.text(
            String(this.$options.filters.moeda(somaJurMulForn)),
            185,
            this.lin,
            { align: "right" }
          );
          this.pdf.text(
            String(this.$options.filters.moeda(somaDescForn)),
            200,
            this.lin,
            { align: "right" }
          );
          this.pdf.text(
            String(this.$options.filters.moeda(somaValorRecebidoForn)),
            220,
            this.lin,
            { align: "right" }
          );
          this.pdf.text(
            "**SALDO A RECEBER R$: " +
              String(
                this.$options.filters.moeda(
                  somaValorAReceberForn - somaValorRecebidoForn
                )
              ),
            280,
            this.lin,
            { align: "right" }
          );

          this.onAddLine(this.jump + 1);
          this.pdf.line(
            8,
            this.lin - this.jump,
            this.widthPage + 8,
            this.lin - this.jump
          );
          somaJurMulForn = 0;
          somaDescForn = 0;
          somaValorRecebidoForn = 0;
          somaValorAReceberForn = 0;
        }
      }

      /** TOTAL FINAL */
      this.onAddLine(1);
      this.pdf.setFont("courier");
      this.pdf.setFontSize(7.5);
      this.pdf.setFont('helvetica',"bold");
      this.pdf.text("TOTAL GERAL:", 130, this.lin, { align: "right" });
      this.pdf.text(
        String(this.$options.filters.moeda(somaValorAReceber)),
        170,
        this.lin,
        { align: "right" }
      );
      this.pdf.text(
        String(this.$options.filters.moeda(somaJurMul)),
        185,
        this.lin,
        { align: "right" }
      );
      this.pdf.text(
        String(this.$options.filters.moeda(somaDesc)),
        200,
        this.lin,
        { align: "right" }
      );
      this.pdf.text(
        String(this.$options.filters.moeda(somaValorRecebido)),
        220,
        this.lin,
        { align: "right" }
      );
      this.pdf.text(
        "**SALDO A RECEBER R$: " +
          String(
            this.$options.filters.moeda(somaValorAReceber - somaValorRecebido)
          ),
        280,
        this.lin,
        { align: "right" }
      );
      this.onAddLine(this.jump + 1);
      this.pdf.line(
        8,
        this.lin - this.jump,
        this.widthPage + 8,
        this.lin - this.jump
      );
      somaJurMul = 0;
      somaDesc = 0;
      somaValorRecebido = 0;
      somaValorAReceber = 0;

      /* RESUMO POR TIPO DE QUITACAO */
      if (this.filtro.opcoes.op04 && this.filtro.opcoes.op04 === 1) {
        var resTpQuitacao = this.data.filter((ele, index) => {
          return ele.tqui_codigo !== null && ele.docp_vlr_pago > 0;
        });
        resTpQuitacao = resTpQuitacao.sort((objA, objB) => {
          const a = objA.tqui_codigo;
          const b = objB.tqui_codigo;
          return a < b ? -1 : a > b ? 1 : 0;
        });

        resTpQuitacao = resTpQuitacao.map(ele => {
          return {
            tqui_codigo: ele.tqui_codigo,
            tqui_descricao: ele.tqui_descricao,
            docp_vlr_pago: ele.docp_vlr_pago
          };
        });

        var resFinalTpQuitacao = [];
        resTpQuitacao.reduce(function(res, value) {
          if (!res[value.tqui_codigo]) {
            res[value.tqui_codigo] = {
              tqui_codigo: value.tqui_codigo,
              tqui_descricao: value.tqui_descricao,
              docp_vlr_pago: 0
            };
            resFinalTpQuitacao.push(res[value.tqui_codigo]);
          }
          res[value.tqui_codigo].docp_vlr_pago += Number(value.docp_vlr_pago);
          return res;
        }, {});

        this.onAddLine(this.jump);
        this.pdf.setFillColor(192, 192, 192);
        this.pdf.rect(8, this.lin - 3, this.widthPage, this.jump + 1, "FD");
        this.pdf.setFont("courier");
        this.pdf.setFont('helvetica',"bold");
        this.pdf.setFontSize(7.5);
        this.pdf.text("RESUMO GERAL POR TIPO DE QUITAÇÃO", 10, this.lin);
        this.onAddLine(this.jump + 1);
        this.pdf.setFont("courier");
        this.pdf.setFont('helvetica',"bold");
        this.pdf.setFontSize(7.5);
        this.pdf.text("CÓD.", 15, this.lin, { align: "right" });
        this.pdf.text("TP. QUITAÇÃO", 17, this.lin);
        this.pdf.text("CRÉDITO", 110, this.lin, { align: "right" });
        this.onAddLine(this.jump + 1);
        var somaResumo = 0;
        for (var index in resFinalTpQuitacao) {
          this.pdf.setFont("courier");
          this.pdf.setFontSize(7.5);
          this.pdf.setFont('helvetica',"normal");
          this.pdf.text(
            String(resFinalTpQuitacao[index].tqui_codigo),
            15,
            this.lin,
            { align: "right" }
          );
          this.pdf.text(
            String(resFinalTpQuitacao[index].tqui_descricao),
            17,
            this.lin
          );
          this.pdf.text(
            String(
              this.$options.filters.moeda(
                resFinalTpQuitacao[index].docp_vlr_pago
              )
            ),
            110,
            this.lin,
            { align: "right" }
          );
          somaResumo += Number(resFinalTpQuitacao[index].docp_vlr_pago);
          this.onAddLine(this.jump);
        }
        this.pdf.setFont("courier");
        this.pdf.setFont('helvetica',"bold");
        this.pdf.setFontSize(7.5);
        this.pdf.text("MOVIMENTO PERÍODO", 90, this.lin, { align: "right" });
        this.pdf.text(
          String(this.$options.filters.moeda(somaResumo)),
          110,
          this.lin,
          { align: "right" }
        );
        this.onAddLine(this.jump + 1);
      }

      /* RESUMO POR TIPO DE RECEBIMENTO */
      if (this.filtro.opcoes.op05 && this.filtro.opcoes.op05 === 1) {
        var resTpRecebimento = this.data.filter((ele, index) => {
          return ele.trec_codigo !== null && ele.docp_vlr_pago > 0;
        });
        resTpRecebimento = resTpRecebimento.sort((objA, objB) => {
          const a = objA.trec_codigo;
          const b = objB.trec_codigo;
          return a < b ? -1 : a > b ? 1 : 0;
        });
        console.log(resTpRecebimento);

        resTpRecebimento = resTpRecebimento.map(ele => {
          return {
            trec_codigo: ele.trec_codigo,
            trec_descricao: ele.trec_descricao,
            docp_vlr_pago: ele.docp_vlr_pago
          };
        });
        console.log(resTpRecebimento);

        var resFinalTpRecebimento = [];
        resTpRecebimento.reduce(function(res, value) {
          if (!res[value.trec_codigo]) {
            res[value.trec_codigo] = {
              trec_codigo: value.trec_codigo,
              trec_descricao: value.trec_descricao,
              docp_vlr_pago: 0
            };
            resFinalTpRecebimento.push(res[value.trec_codigo]);
          }
          res[value.trec_codigo].docp_vlr_pago += Number(value.docp_vlr_pago);
          return res;
        }, {});

        this.onAddLine(this.jump);
        this.pdf.setFillColor(192, 192, 192);
        this.pdf.rect(8, this.lin - 3, this.widthPage, this.jump + 1, "FD");
        this.pdf.setFont("courier");
        this.pdf.setFont('helvetica',"bold");
        this.pdf.setFontSize(7.5);
        this.pdf.text("RESUMO GERAL POR TIPO DE RECEBIMENTO", 10, this.lin);
        this.onAddLine(this.jump + 1);
        this.pdf.setFont("courier");
        this.pdf.setFont('helvetica',"bold");
        this.pdf.setFontSize(7.5);
        this.pdf.text("CÓD.", 15, this.lin, { align: "right" });
        this.pdf.text("TP. RECEBIMENTO", 17, this.lin);
        this.pdf.text("CRÉDITO", 110, this.lin, { align: "right" });
        this.onAddLine(this.jump + 1);
        var somaResumo = 0;
        for (var index in resFinalTpRecebimento) {
          this.pdf.setFont("courier");
          this.pdf.setFontSize(7.5);
          this.pdf.setFont('helvetica',"normal");
          this.pdf.text(
            String(resFinalTpRecebimento[index].trec_codigo),
            15,
            this.lin,
            { align: "right" }
          );
          this.pdf.text(
            String(resFinalTpRecebimento[index].trec_descricao),
            17,
            this.lin
          );
          this.pdf.text(
            String(
              this.$options.filters.moeda(
                resFinalTpRecebimento[index].docp_vlr_pago
              )
            ),
            110,
            this.lin,
            { align: "right" }
          );
          somaResumo += Number(resFinalTpRecebimento[index].docp_vlr_pago);
          this.onAddLine(this.jump);
        }
        this.pdf.setFont("courier");
        this.pdf.setFont('helvetica',"bold");
        this.pdf.setFontSize(7.5);
        this.pdf.text("MOVIMENTO PERÍODO", 90, this.lin, { align: "right" });
        this.pdf.text(
          String(this.$options.filters.moeda(somaResumo)),
          110,
          this.lin,
          { align: "right" }
        );
        this.onAddLine(this.jump + 1);
      }

      this.onFooterPDF();
      this.onInsertNumberPagesPDF();

      const uuidv4 = this.$uuid.v4();
      const urlPDF = this.pdf.output("blob");
      this.localOutputPdf = await window.URL.createObjectURL(
        new Blob([urlPDF], { type: "application/pdf:base64" })
      );
      this.$bvModal.show("relatorio");
    }
  },
  watch: {
    localOutputPdf(newValue, oldValue) {
      //this.$emit("update:outputPdf", newValue);
    }
  }
};
</script>
