<template>
    <div class="container-fluid mct-calendar">
        <ul class=" d-flex flex-wrap justify-content-center">
            <li v-for="(dia,indexDb) in onGeraDiasBranco()" :key="'bb'+indexDb">

            </li>
            <li v-for="(dia,index) in lista.horari_dias" :key="index" :class="dia.selecionado == 0 ? 'unselect' : 'select'">            
                <div class="mct-calendar-left float-left" :class="typeof dia.feriado != 'undefined' && dia.feriado === 1 ? 'mct-calendar-fer' : dia.horaridia_diasem == 0 ? 'mct-calendar-dom' : ''">
                    <a href="#" @click.prevent.stop="onSelecionarDia(index)" title="Clique para Selecionar ou Deselecionar o Dia">
                    <span class="mct-calendar-mes">{{moment(dia.horaridia_data).locale("pt-br").format('MMM').toUpperCase()}}</span>
                    <span class="mct-calendar-dia">{{moment(dia.horaridia_data).locale("pt-br").format('DD')}}</span>
                    <span class="mct-calendar-diasem">{{moment(dia.horaridia_data).locale("pt-br").format('ddd').toUpperCase()}}</span>
                    </a>
                </div>
                <a href="#" tabindex="-1" title="Ver Horários Livres" @click.prevent.stop="onModalDisciplinaHora(dia,index)">
                    <div class="mct-calendar-right float-right">
                        <div class="form-group m-0 p-0">
                            <span v-for="(disciplina,indexDisp) in dia.horarios" :key="'disp'+indexDisp" class="badge badge-primary" @click.prevent.stop="onAlterarDisciplinaHora(disciplina,indexDisp,index)">{{ disciplina.horaridiadisp_hora }} - {{getNomeDisciplina(disciplina.disp_codigo)}}</span>
                        </div>
                    </div>
                </a>
            </li>
        </ul>
        <b-modal id="incluir-disciplina" title="Agendar Aula">
            <form>
            <div class="row">
                <div class="col-sm-6">
                    <mct-form-itens coluna="0" tipo="select" campo="disp_codigo" :valor="disciplina" titulo="Selecione a Disciplina" :errors="{}" 
                        :origemData="cursoDisciplinas" origemChave="disp_codigo" origemRetorno='disp_nome'                     
                        modo="editar" showBotoes="false"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <mct-form-itens coluna="0" tipo="text" mascara="hora" campo="hora" :valor="disciplina" titulo="Horário da Aula" :errors="{}" 
                        modo="editar" showBotoes="false"></mct-form-itens>
                </div>
            </div>
            </form>
            <template v-slot:modal-footer>
                <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('incluir-disciplina')">
                    <span class="fa fa-trash-o fa-sm" aria-hidden="true"></span> Fechar </button>
                <button type="button" class="btn btn-success btn-sm" aria-label="Left Align" @click.prevent.stop="onSalvarDisciplinaHora()">
                    <span class="fa fa-check fa-sm" aria-hidden="true"></span> Salvar </button>
            </template>
        </b-modal>            
        <!--<div class="row mb-2">
            <button class="btn btn-sm btn-primary mt-2" @click.prevent.stop="$emit('setHora')"><i class="fa fa-chevron-left" aria-hidden="true"></i> Voltar</button> 
        </div>
        <div class="row mct-calendar-horarios">
            <div class="col-2 mct-calendar-horarios-hora"></div>
            <div class="col mct-calendar-horarios-mins-t"><span>{{moment(linha.horaridia_data).locale('pt-br').format('L')}}</span></div>
        </div>
        <div class="row mct-calendar-horarios" v-for="(linha,index) in horarios" :key="index">
            <div :id="'ch_'+index" class="col-2 mct-calendar-horarios-hora"><a href="#" @click.prevent.stop="onSelDisp(linha.hora,index)">{{linha.hora}}</a></div>
            <div :id="'cm15_'+index" class="col mct-calendar-horarios-mins" v-show="(!showDisciplinas && linhaSelecionada === index) || linhaSelecionada !== index" @click.prevent.stop="onSelDisp(linha.hora,index)">
            
            </div>
            <div class="mct-calendar-horarios-txt" v-for="(disp,idx) in getAulasHora(linha.hora,index)" :key="'disp_'+idx">{{disp.disp_nome}}</div>
            <div class="mct-calendar-horarios-disp" v-show="showDisciplinas && linhaSelecionada === index" >
                <span class="mb-2">Selecione a Disciplina</span>
                <mct-form-itens coluna="0" tipo="select" campo="disp_codigo" :valor="linha" :errors="{}" 
                    :origemData="lista" origemChave="disp_codigo" origemRetorno='disp_nome'                     
                    modo="editar" showBotoes="false"></mct-form-itens>
                <button class="btn btn-sm btn-primary mt-2" @click.prevent.stop="onSelHora(linha)"><i class="fa fa-check" aria-hidden="true"></i> Selecionar</button>    
            </div>
        </div>-->
    </div>    
</template>

<script>
import axios from '@/http'
import moment from 'moment'

import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
    name: 'EscAgendamentosHorarios',
    props: ['lista', 'turma'],
    components: {
        MctFormItens
    },
    data () {
        return {
            intervalo: 15,
            horarios: [],
            aulasDia: [],
            dataDias: [],
            dataFeriados: [],
            linhaSelecionada: -1,
            showDisciplinas: false,
            disciplinas: [],
            cursoDisciplinas: {},
            diaSelecionado: null,
            linhaSelecionada: null,
            showHorarios: false,
            disciplina: {
                index: null,
                disp_codigo: null,
                hora: null
            },
  
        }
    },
    methods: {
        /*Disciplina Hora */
        onModalDisciplinaHora (linha,index) {
            this.diaSelecionado = moment(linha.horaridia_data).locale("pt-br").format('YYYY-MM-DD')
            this.linhaSelecionada = index
            this.disciplina.index = null
            this.disciplina.disp_codigo = null
            this.disciplina.hora = null
            this.$bvModal.show('incluir-disciplina')
        },
        onAlterarDisciplinaHora (linha,indexDisp,index) {
            alert(indexDisp,index)
        },
        getNomeDisciplina(disp_codigo){
            var disciplina = this.cursoDisciplinas.filter(item => item.disp_codigo === disp_codigo)
            return disciplina[0].disp_nome
        },
        onGeraDiasBranco () {
            let diasem = 0
            if (typeof this.lista !== 'undefined' && typeof this.lista.horari_dias !== 'undefined' && typeof this.lista.horari_dias[0] !== 'undefined'){
                diasem = this.lista.horari_dias[0].horaridia_diasem
            }
            let ret = []
            while (diasem > 0){
                ret.push({diasem: diasem})    
                diasem--
            }
            return ret
        },
        onSelHora (linha) {
            var index = -1
            this.linha.selecionado = 1
            if (typeof this.linha.horarios.filter !== 'undefined'){
                var ret = this.linha.horarios.filter(function(el) {
                    return el.horaridiadisp_hora == linha.hora || el.horaridiadisp_hora+':00' == linha.hora
                })
            index = this.linha.horarios.indexOf(ret[0])            
            }
          
            if (index >= 0){
                this.linha.horarios[index].disp_codigo           = linha.disp_codigo
                this.linha.horarios[index].disp_nome             = linha.disp_nome
                this.linha.horarios[index].horaridiadisp_hora    = linha.hora
                this.linha.horarios[index].horaridiadisp_duracao = this.duracao
            } else {
                this.linha.horarios.push({  
                    disp_codigo           : linha.disp_codigo,
                    disp_nome             : linha.disp_nome,
                    horaridiadisp_hora    : linha.hora,
                    horaridiadisp_duracao : this.duracao
                })                
            }
            this.showDisciplinas = false
        },
        onSelDisp (hora, linha) {
            this.showDisciplinas = true
            this.linhaSelecionada = linha
        },
        geraHorario () {
            var seq = 0
            for (var i = 1; i <= 24; i++){
                this.horarios.push({
                    hora: ("00" + i).slice(-2)+':'+("00" + 0).slice(-2),
                    seq: seq
                })
                seq++
            }
        },       
        getAulasHora (hora,index) {
            if (typeof this.linha !== 'undefined' && typeof this.linha.horarios !== 'undefined' && typeof this.linha.horarios.filter !== 'undefined'){
                var ret = this.linha.horarios.filter(function(el) {
                    return el.horaridiadisp_hora == hora || el.horaridiadisp_hora == hora+':00'
                })
                $('#ch_'+index).width()
                return ret
            }else{
                return {}
            }
        },
        async getDisciplinas () {
            await axios.get(`/esccursosdisciplinas/combo?ser_codigo=${this.lista.ser_codigo}&turn_codigo=${this.turma.turn_codigo} `)
            .then(response => {
                this.cursoDisciplinas = response.data.data
            })
            .catch(response => {
                this.message = response.response.data.message
                this.errors = response.response.data.errors
                this.status = response.response.status
            })
        },
        async getFeriados() {
            let URL = '/esccalendario/feriadosentredatas?dataini='+
                moment(this.lista.horari_data_inicial).locale("pt-br").format('YYYY-MM-DD')+
                '&datafin='+
                moment(this.lista.horari_data_final).locale("pt-br").format('YYYY-MM-DD')
            await axios.get(`${URL}`)
            .then(response => {
                this.dataFeriados = response.data.data          
            })
            .catch(response => {
                this.message = response.response.data.message
                this.errors = response.response.data.errors
                this.status = response.response.status
            })
        },
        async getDias() {
            let URL = 'escagendamento/dias?cur_codigo='+this.lista.cur_codigo+'&turm_codigo='+this.lista.turm_codigo//+'&disp_codigo='+this.lista.disp_codigo     
            await axios.get(`${URL}`)
            .then(response => {
                this.dataDias = response.data.data
            })
            .catch(response => {
                this.message = response.response.data.message
                this.errors = response.response.data.errors
                this.status = response.response.status
            })
        },
        fillDataDias () {
        for (var i = 0; i < this.dataDias.length; i++){
            this.dataDias[i]
            let data = this.dataDias[i].horaridia_data
            var ret = this.lista.horari_dias.filter(function(el) {
                return moment(el.horaridia_data).locale("pt-br").format('YYYY-MM-DD') == moment(data).locale("pt-br").format('YYYY-MM-DD')
            })
            var index = this.lista.horari_dias.indexOf(ret[0])
            if (index >= 0){
                this.lista.horari_dias[index].selecionado           = 1
                this.lista.horari_dias[index].feriado               = 1
                this.lista.horari_dias[index].horarios              = this.dataDias[i].horarios            
            }
            this.lista.turm_totalselecionados = i+1  
        }       
        },
        fillFeriados () {
        for (var i = 0; i < this.dataFeriados.length; i++){
            this.dataFeriados[i]
            let data = this.dataFeriados[i].calen_dia
            var ret = this.lista.horari_dias.filter(function(el) {
                return moment(el.horaridia_data).locale("pt-br").format('YYYY-MM-DD') == moment(data).locale("pt-br").format('YYYY-MM-DD')
            })
            var index = this.lista.horari_dias.indexOf(ret[0])
            if (index >= 0){
                this.lista.horari_dias[index].feriado = 1
            }
        }       
        },        
        onGeraDias () {
        if (typeof this.lista.horari_data_inicial != 'undefined' && this.lista.horari_data_inicial != null 
            && typeof this.lista.horari_data_final != 'undefined' && this.lista.horari_data_final != null){            
                let dias = moment(this.lista.horari_data_final).diff(this.lista.horari_data_inicial,'days')
                dias++
                this.lista.turm_totaldias = dias
                this.lista.turm_totalselecionados = 0
                this.lista.horari_dias = []
                let dataBase = this.lista.horari_data_inicial
                var diasSel = 0
                var ret = []
                for (var i = 0; i < dias; i++){                
                    var data = moment(dataBase).add(i, 'days')
                    if (typeof this.lista.horari_dias !== 'undefined'){
                    ret = this.lista.horari_dias.filter(function(el) {
                        return el.horaridia_data == moment(data).locale("pt-br").format('YYYY-MM-DD');
                    })
                    }                
                    if (ret.length > 0){
                    this.lista.horari_dias.push({
                        selecionado: 1,
                        feriado: 0,
                        horaridia_seq: i+1,
                        horaridia_data: ret[0]['horaridia_data'], 
                        horarios: ret[0]['horarios'],            
                        horaridia_diasem: moment(data).locale("pt-br").format('d')
                    })
                    diasSel++
                    }else{
                    this.lista.horari_dias.push({
                        selecionado: 0,
                        feriado: 0,
                        horaridia_seq: i+1, 
                        horaridia_data: data,
                        horarios: [],  
                        horaridia_diasem: moment(data).locale("pt-br").format('d')
                    })
                    }
                }
                this.lista.turm_totalselecionados = diasSel
                this.getFeriados().then(() => this.fillFeriados())
        }        
        }, 
        onSalvarDisciplinaHora () {
            let msgs = ''
            if (this.disciplina.disp_codigo == null || this.disciplina.disp_codigo == ''){
                msgs += 'Selecione uma Disciplina; </br>';
            }
            if (this.disciplina.hora == null || this.disciplina.hora == 0){
                msgs += 'Digite uma Hora válida; </br>';
            }        
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Incluir / Alterar o Registro',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            var index = null      
            if (typeof this.lista.horari_dias[this.linhaSelecionada].horarios.filter !== 'undefined'){
                var ret = this.lista.horari_dias[this.linhaSelecionada].horarios.filter(el => el.horaridiadisp_hora == this.disciplina.hora || el.horaridiadisp_hora+':00' == this.disciplina.hora)
                index = this.lista.horari_dias[this.linhaSelecionada].horarios.indexOf(ret[0])            
            }
            
            if (index >= 0){
                this.lista.horari_dias[this.linhaSelecionada].horarios[index].disp_codigo           = this.disciplina.disp_codigo
                this.lista.horari_dias[this.linhaSelecionada].horarios[index].horaridiadisp_hora    = this.disciplina.hora
                this.lista.horari_dias[this.linhaSelecionada].horarios[index].horaridiadisp_duracao = this.duracao
            } else {
                this.lista.horari_dias[this.linhaSelecionada].horarios.push({  
                    disp_codigo           : this.disciplina.disp_codigo,
                    horaridiadisp_hora    : this.disciplina.hora,
                    horaridiadisp_duracao : this.duracao
                })                
            }
        
            this.onSelecionarDia(this.linhaSelecionada)
            this.disciplina.index = null
            this.disciplina.disp_codigo = null
            this.disciplina.hora = null
            this.$bvModal.hide('incluir-disciplina')
            }
        },
        onSelecionarDia (index){
            if (this.lista.horari_dias[index].selecionado == 0) {
                this.lista.horari_dias[index].selecionado = 1
                this.lista.turm_totalselecionados++
            } else {
                this.lista.horari_dias[index].selecionado = 0
                this.lista.turm_totalselecionados--
            }
        }                     
    },
    watch: {
        'lista.horari_data_inicial': function (newVal, oldVal){ 
            if (typeof newVal != 'undefined' && newVal != null) {
                this.onGeraDias()
                //this.fillDataDias()
            }
        },
        'lista.horari_data_final': function (newVal, oldVal){ 
            if (typeof newVal != 'undefined' && newVal != null) {
                this.onGeraDias()
                //this.fillDataDias()
            }
        },
        'lista.turm_codigo': function (newVal, oldVal){ 
            if (typeof newVal != 'undefined' && newVal != null){          
                this.getDisciplinas()
            }
        },
        'lista.ser_codigo': function (newVal, oldVal){ 
            if (typeof newVal != 'undefined' && newVal != null){        
                this.getDisciplinas()
            }
        },
    },
    created() {
        this.showDisciplinas = false
        //this.geraHorario()
    },
    
}
</script>

