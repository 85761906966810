<template>
  <div class="container p-3 m-auto">
    <div class="animated fadeIn mct-form p-3 bg-white mct-box-shadow">  
      <form-wizard @on-complete="onGravar" 
        color="#20a8d8"
        errorColor="#f27474" 
        title="Transferência de Aluno" 
        subtitle=""
        back-button-text="Voltar"
        next-button-text="Avançar"
        finish-button-text="Finalizar"
        v-show="raMatriculado === null"
        ref="wizard"
        >
        <tab-content title="Identificação da Matrícula Atual" icon="fa fa-user" :before-change="() => onBeforeTab(1)">
          <div class="form-group row">
            <label class="col-sm-12 col-lg-2 col-form-label text-lg-right text-info">Selecione a Matrícula</label>
            <div class="col-sm-12 col-lg-8" v-if="infoEscola.esc_tipo !== 1">
              <mct-form-itens tipo="select" campo="matri_codigo" :valor="lista" titulo="" :errors="errors" obrigatorio="true" 
              origemAPI="/escmatriculaaluno/matriculados" origemChave="matri_codigo" origemRetorno='pes_nome' origemRetornoSec='matri_matricula' :origemFiltro="{matri_situacao: 'M'}" @setDataRetorno="setMatricula" :modo="modo"></mct-form-itens>
            </div>
            <div class="col-sm-12 col-lg-8" v-if="infoEscola.esc_tipo === 1">
              <mct-form-itens tipo="select" campo="matri_codigo" :valor="lista" titulo="" :errors="errors" obrigatorio="true" 
              origemAPI="/escmatriculaaluno/matriculados" origemChave="matri_codigo" origemRetorno='pes_nome' origemRetornoSec='matri_matricula' @setDataRetorno="setMatricula" :modo="modo"></mct-form-itens>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-12 col-lg-2 col-form-label text-lg-right text-info">Curso</label>
            <div class="col-sm-12 col-lg-4">
              <mct-form-itens tipo="text" campo="cur_nome" :valor="matricula" titulo="" :errors="errors" obrigatorio="false" modo="readonly"></mct-form-itens>
            </div>
          </div>          
          <div class="form-group row">
            <label class="col-sm-12 col-lg-2 col-form-label text-lg-right text-info">Turma</label>
            <div class="col-sm-12 col-lg-4">
              <mct-form-itens tipo="text" campo="turm_identificacao" :valor="matricula" titulo="" :errors="errors" obrigatorio="false" modo="readonly"></mct-form-itens>
            </div>
          </div>          
          <div class="form-group row" v-if="infoEscola.esc_tipo !== 1">
            <label class="col-sm-12 col-lg-2 col-form-label text-lg-right text-info">Módulo</label>
            <div class="col-sm-12 col-lg-4">
              <mct-form-itens tipo="select" campo="matris_codigo" :valor="lista" titulo="" :errors="errors" obrigatorio="true" 
              :origemData="matricula.modulos" origemChave="matris_codigo" origemRetorno="horari_descricao" origemRetornoSec="matri_horari_codigo" 
              :origemFiltro="{horari_situacao: 1}" :modo="modo"></mct-form-itens>
            </div>
          </div>          
        </tab-content>
        <tab-content :title="infoEscola.esc_tipo === 1 ? 'Dados da Transferência' : 'Seleção da Vaga'" icon="fa fa-bookmark" :before-change="() => onBeforeTab(2)">
          
          <div class="form-group row" v-if="infoEscola.esc_tipo === 1">
            <label class="col-sm-12 col-lg-2 col-form-label text-lg-right text-info">Data da Transferência</label>
            <div class="col-sm-12 col-lg-4">
              <mct-form-itens tipo="date" campo="matri_data_transf" :valor="lista" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
          </div>
          <div class="form-group row" v-if="infoEscola.esc_tipo === 1">
            <label class="col-sm-12 col-lg-2 col-form-label text-lg-right text-info">Selecione o Tipo</label>
            <div class="col-sm-12 col-lg-8">
              <mct-form-itens tipo="radio" campo="matri_tipo_transf" :valor="lista" :origemData="[{value:'T',text:'Turma'},{value:'E',text:'Escola'}]" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
          </div>
          <div class="form-group row" v-if="infoEscola.esc_tipo !== 1">
            <label class="col-sm-12 col-lg-2 col-form-label text-lg-right text-info">Selecione o Curso</label>
            <div class="col-sm-12 col-lg-8">
              <mct-form-itens tipo="select" campo="matri_cur_codigo" :valor="lista" titulo="" :errors="errors" obrigatorio="false" 
                origem="esc_cursos" origemChave="cur_codigo" origemRetorno='cur_nome' :origemFiltro="{cur_situacao: 1}" @setDataRetorno="setCurso" modo="readonly"></mct-form-itens>
            </div>
          </div>
          <div class="form-group row" v-if="infoEscola.esc_tipo !== 1">
            <label class="col-sm-12 col-lg-2 col-form-label text-lg-right text-info">Selecione a Turma</label>
            <div class="col-lg-8">
                <mct-form-itens tipo="select" campo="matri_turm_codigo" :valor="lista" titulo="" obrigatorio="false"
                  origem="esc_turmas" :origemFiltro="{cur_codigo: lista.matri_cur_codigo, turm_situacao: 1}" origemChave="turm_codigo" origemRetorno='turm_completa' :errors="errors" :modo="modo"></mct-form-itens>
            </div>
          </div>
          <div class="form-group row" v-if="infoEscola.esc_tipo === 1 && lista.matri_tipo_transf === 'T'">
            <label class="col-sm-12 col-lg-2 col-form-label text-lg-right text-info">Selecione a Turma</label>
            <div class="col-lg-8">
                <mct-form-itens tipo="select" campo="matri_turm_codigo" :valor="lista" titulo="" obrigatorio="false"
                  origem="esc_turmas" :origemFiltro="{ser_codigo: matricula ? matricula.ser_codigo : null, turm_situacao: 1}" origemChave="turm_codigo" origemRetorno='turm_completa' :errors="errors" :modo="modo"></mct-form-itens>
            </div>
          </div>
          <fieldset class="well col-sm-12 col-lg-8 m-auto" v-if="infoEscola.esc_tipo !== 1">
            <legend class="well-legend">Selecione um ou mais Módulos</legend>                    
            <div class="mct-grid">
                <div class="mct-grid-scroll-sec">  
                    <table class="table table-sm table-striped table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" style="width: 34px;" class="text-center"></th>
                                <th scope="col" style="width: 50px;" class="text-center">Grade</th>
                                <th scope="col" class="text-left">Módulo</th>
                                <th scope="col" style="width: 160px;" class="text-right">Período</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="!agendamentos || agendamentos.length === 0" class="bg-white">
                                <td colspan="4" class="text-center"><span class="text-info">SEM REGISTRO(S)</span></td>
                            </tr>                              
                            <tr v-for="(agendamento,index) in agendamentosSerie" :key="'hr'+index">
                                <th scope="row">
                                    <label class="custom-control custom-checkbox">
                                        <input  class="custom-control-input" type="checkbox" name="ckSelecionado" v-model="agendamento.selecionado"
                                        v-bind:true-value="1"
                                        v-bind:false-value="0"
                                        >
                                        <span class="custom-control-indicator"></span>
                                        <span class="custom-control-description align-middle"></span>
                                    </label>
                                </th> 
                                <td class="text-center">{{agendamento.serie.ser_serie}}</td>
                                <td class="text-left">{{agendamento.serie.ser_descricao}}</td>
                                <td class="text-right">{{agendamento.horari_data_inicial | data}} até {{agendamento.horari_data_final | data}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>                        
          </fieldset>
        </tab-content>
        <tab-content title="Finalizar Transferência" icon="fa fa-handshake-o" :before-change="() => onBeforeTab(3)">
          <div class="form-group row">
            <label class="col-sm-12 col-lg-2 col-form-label text-lg-right text-info">Observações</label>
            <div class="col-sm-12 col-lg-8">
              <mct-form-itens tipo="textarea" campo="matri_observacao" :valor="lista" titulo="" obrigatorio="false" :errors="errors" :modo="modo" linhas="5"></mct-form-itens>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-12 col-lg-2 col-form-label text-lg-right text-info">Responsável Pela Transferência</label>
            <div class="col-sm-12 col-lg-8">
              <mct-form-itens tipo="select" campo="matri_resp_codigo" :valor="lista" titulo="" obrigatorio="false"
                origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' :errors="errors" modo="readonly"></mct-form-itens>
            </div>
          </div>
        </tab-content>
      </form-wizard>
      <div v-show="raMatriculado !== null" class="p-3 m-auto text-center">
        <h2 class="text-success p-3">Transferência Realizada com Sucesso!</h2>
        <h2 class="text-success p-3">O Registro do Aluno é o de número:</h2>
        <h1 class="text-info font-weight-bold p-3 bg-light">{{raMatriculado}}</h1>
        <br/>
        <br/>
        <button type="button" class="btn btn-success btn-lg" aria-label="Left Align" @click="onNovaTransferencia()"> Continuar Transferindo </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/http";
import { mapState, mapActions } from "vuex";

import MctFormItens from "@/components/comum/MctFormItens.vue";

export default {
  Name: "MatriculaCursos",
  props: ["source", "titulo", "modo"],
  components: {
    MctFormItens,
  },
  data() {
    return {     
      lista: {
        matri_ent: null,
        matri_esc: null,
        matri_ano: null,
        matri_codigo: null,
        matris_codigo: null,
        matri_resp_codigo: null,
        matri_cur_codigo: null,
        matri_turm_codigo: null,
        matri_observacao: null,
        matri_tipo_transf: 'T',
        matri_data_transf:  new Date()
      },
      errors: {},
      matricula: {},
      curso: {},
      agendamentos: [],
      raMatriculado: null,
      status: 0
    };
  },
  methods: {    
    setMatricula(data) {
      this.matricula = data;
    },
    setCurso(data) {
      this.curso = data;
    },
    onNovaTransferencia() {
      this.onLimpaTela();
      this.$refs.wizard.navigateToTab(0)
    },
    onLimpaTela() {
      this.lista.matri_ent = this.Entidade;
      this.lista.matri_esc = this.Escola;
      this.lista.matri_ano = this.Exercicio;
      this.lista.matri_codigo = null;
      this.lista.matris_codigo = null;
      this.lista.matri_resp_codigo = this.infoLogado.pes_codigo;
      this.lista.matri_cur_codigo = null;
      this.lista.matri_turm_codigo = null;
      this.lista.matri_observacao = null;
      this.lista.matri_tipo_transf = 'T';
      this.lista.matri_data_transf =  new Date();
      this.matricula = {};
      this.curso = {};
      this.agendamentos = {};
      this.raMatriculado = null;
    },
    async onBeforeTab(tab) {
      let msgs = ''
      if (tab === 1){
        if (this.lista.matri_codigo == null || this.lista.matri_codigo === 0){
            msgs += 'Selecione um Aluno(a), caso não localize cadastre o aluno e tente novamente; </br>';
        }
        if ((this.lista.matris_codigo == null || this.lista.matris_codigo === 0) && (this.infoEscola.esc_tipo !== 1)){
            msgs += 'Selecione o Agendamento e tente novamente; </br>';
        }
        if ((msgs === '') && (this.infoEscola.esc_tipo !== 1)){
          this.lista.matri_cur_codigo = this.matricula.cur_codigo;
        }
      } else
      if (tab === 2){
        if ((this.lista.matri_tipo_transf == null || this.lista.matri_tipo_transf === '') && (this.infoEscola.esc_tipo === 1)){
            msgs += 'Selecione o Tipo de Transferência e tente novamente; </br>';
        }
        if ((this.lista.matri_data_transf == null || this.lista.matri_data_transf === 0) && (this.infoEscola.esc_tipo === 1)){
            msgs += 'Selecione a Data da Transferência e tente novamente; </br>';
        }
        if ((this.lista.matri_cur_codigo == null || this.lista.matri_cur_codigo === 0) && (this.infoEscola.esc_tipo !== 1)){
            msgs += 'Selecione um Curso e tente novamente; </br>';
        }
        if ((this.lista.matri_turm_codigo == null || this.lista.matri_turm_codigo === 0) && ((this.infoEscola.esc_tipo !== 1) || ((this.infoEscola.esc_tipo === 1) && (this.lista.matri_tipo_transf === 'T')))){
            msgs += 'Selecione uma Turma e tente novamente; </br>';
        }
        if (this.infoEscola.esc_tipo !== 1) {
          var soma = 0
          if (this.agendamentos !== null && typeof this.agendamentos !== 'undefined'){
              for (var index in this.agendamentos) {
                  if (this.agendamentos[index].selecionado === 1){
                    soma += 1
                  }
              }            
          }
          if (soma === 0){
              msgs += 'Selecione ao menos um Módulo e tente novamente; </br>';
          }
        }
      } else
      if (tab === 3){
        /*if (this.lista.matri_observacao == null || this.lista.matri_observacao === ''){
            msgs += 'Digite uma observação e tente novamente; </br>';
        }*/
        if (this.lista.matri_resp_codigo == null || this.lista.matri_resp_codigo === 0){
            msgs += 'Responsável pela Transferência não Selecionado; </br>';
        }        
      }
      if (msgs !== ''){
        this.$swal({
          icon: 'error',
              title: 'Erro',
              html: msgs,              
              footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
          })        
        return false
      } else {
        if (tab === 3){
          const result = await this.$swal({
            title: 'Deseja realmente realizar a transferência?',
            text: "Realizar a transferência!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
          })
          if (result.value) {
            return true
          } else {
            return false  
          }
        } else {          
          return true
        }
      }
    },  
    async onGravar() {
      var dados = this.lista;
      if (this.infoEscola.esc_tipo !== 1) {
        dados.agendamentos = this.agendamentos.map(item => {
          if (item.selecionado && item.selecionado === 1) return item.horari_codigo  
        }).filter(ele => ele !== undefined && ele !== null);
      }
      let URL = '';
      if (this.infoEscola.esc_tipo === 1) {
        URL = '/escmatriculaalunocurso/transferir'
      } else {        
        URL = `escmatriculaalunoseries/transferir/${dados.matris_codigo}`
      }

      await axios
        .put(URL, dados)
        .then(response => {

          this.message = response.data.message;
          this.status = response.status;
          this.errors = {};

          this.$swal("Sucesso!", "Transferência realizada com sucesso!", "success");
          this.raMatriculado = response.data.data.matri_matricula;          
        })
        .catch(response => {
          this.message = response.response.data.message;
          if (response.response.data.error) {
            this.errors = response.response.data.error.errorInfo[2];
          } else {
            this.errors = response.response.data.errors;
          }
          this.status = response.response.status;
        });      
    }
  },
  computed: {
    ...mapState("Login", [
      "infoLogado",
      "Exercicio",
      "Escola",
      "Entidade",
      "infoEscola"
    ]),
    agendamentosSerie () {
      const horario = this.matricula && this.matricula.series && this.matricula.series.filter !== undefined ? this.matricula.series.filter((ele) => ele.matris_codigo === this.lista.matris_codigo)[0] : {};
      return this.agendamentos && this.agendamentos.filter !== undefined && horario ? this.agendamentos.filter((ele) => ele.horari_codigo !== horario.matri_horari_codigo && ele.ser_codigo === horario.horario.ser_codigo) : []
    }
  },
  created() {
    this.onLimpaTela();
  },
  watch: {
    'lista.matri_turm_codigo': async function (newVal, oldVal){
      if (newVal){
          let URL = '/escagendamento/combo?horari_situacao=1&turm_codigo=' + newVal
          await axios.get(`${URL}`)
          .then(response => {
              this.agendamentos = response.data.data                
          })
          .catch(response => {
            //
          })                       
      }
    }
  },

};
</script>

<style lang="scss" scoped>

  .wizard-tab-content {
    display: flex; // to avoid horizontal scroll when animating
    .wizard-tab-container {
      display: block;
      animation: fadeInRight 0.3s;
    }
  }

</style>