<template>
  <mct-padrao :titulo="titulo" :modo="modo" botoes="false">
    <div slot="formulario">
      <div class="row">
        <div class="col-lg-6">
          <mct-form-itens tipo="select" campo="turm_codigo" :valor="lista" titulo="Turma" obrigatorio="true" :errors="errors" 
            origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_completa' origemRetornoSec="turm_codigo" origemChaveComposta="true"
            :modo="modo" @setDataLista="setTurma"></mct-form-itens>
        </div>
        <div class="col-lg-6">
          <mct-form-itens tipo="select" campo="ser_codigo" :valor="lista" titulo="Módulo" obrigatorio="true" :errors="errors" 
            origem="esc_serie" origemChave="ser_codigo" origemRetorno='ser_descricao' origemRetornoSec="ser_codigo" origemChaveComposta="true" 
            :origemFiltro="{cur_codigo: turmaSelecionada.cur_codigo}" @setDataLista="setSerie"
            :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <mct-form-itens tipo="text" campo="horari_descricao" :valor="lista" titulo="Identificação" obrigatorio="true" :errors="errors" :modo="modo" ></mct-form-itens>
        </div>
        <div class="col-lg-2">
          <mct-form-itens tipo="date" campo="horari_data_inicial" :valor="lista" titulo="Data Início" obrigatorio="true" :errors="errors" :modo="modo" ></mct-form-itens>
        </div>
        <div class="col-lg-2">
          <mct-form-itens tipo="date" campo="horari_data_final" :valor="lista" titulo="Data Fim" obrigatorio="true" :errors="errors" :modo="modo" ></mct-form-itens>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 text-center">
          <div class="btn-group btn-group-toggle">
              <button class="btn btn-info" @click.prevent.stop="getOutrosAgendamentos"><span class="fa fa-calendar fa-lg" aria-hidden="true"></span> Ver Agendamentos da mesma Turma/Módulo</button>
              <button class="btn btn-info ml-2" @click.prevent.stop="getMatriculas" v-if="modo !== 'novo'"><span class="fa fa-address-card-o fa-lg" aria-hidden="true"></span> Ver Alunos Matrículados</button>
              <button class="btn btn-danger ml-2" @click.prevent.stop="setSituacao(0)" v-if="modo === 'editar'"><span class="fa fa-ban fa-lg" aria-hidden="true"></span> Cancelar Agendamento</button>     
              <button class="btn btn-success ml-2" @click.prevent.stop="setSituacao(3)" v-if="modo === 'editar'"><span class="fa fa-check fa-lg" aria-hidden="true"></span> Finalizar Agendamento</button>
              <button class="btn btn-primary ml-2" @click.prevent.stop="setSituacao(1)" v-if="modo !== 'novo' && modo !== 'editar' && lista.horari_situacao !== 1"><span class="fa fa-check fa-lg" aria-hidden="true"></span> Reabrir Agendamento</button>
          </div>
        </div>
        <b-modal id="outros-agendamentos" title="Agendamentos da mesma Turma/Módulo" ok-only>
          <table class="table table-sm table-striped">
            <thead class="table-info">
                <tr>
                    <th scope="col" class="text-center"></th>
                    <th scope="col" class="text-right">Início</th>
                    <th scope="col" class="text-right">Término</th>
                    <th scope="col" class="text-center">Situação</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="!outrosAgendamentos || outrosAgendamentos.length === 0" class="bg-white">
                    <td colspan="4" class="text-center"><span class="text-info">SEM REGISTRO(S)</span></td>
                </tr>
                <tr v-for="(agendamento,index) in outrosAgendamentos" :key="'outhr'+index">
                    <th scope="row" class="text-center">{{agendamento.horari_codigo}}</th> 
                    <td class="text-right">{{agendamento.horari_data_inicial | data}}</td>
                    <td class="text-right">{{agendamento.horari_data_final | data}}</td>
                    <td class="text-center">
                      <span class="badge badge-primary" v-if="agendamento.horari_situacao === 1"><i class="fa fa-clock-o" aria-hidden="true"></i> em andamento</span>
                      <span class="badge badge-danger" v-else-if="agendamento.horari_situacao === 0"><i class="fa fa-ban" aria-hidden="true"></i> cancelado</span>
                      <span class="badge badge-info" v-else-if="agendamento.horari_situacao === 2"><i class="fa fa-hourglass-start" aria-hidden="true"></i> aguardando</span>
                      <span class="badge badge-success" v-else-if="agendamento.horari_situacao === 3"><i class="fa fa-check" aria-hidden="true"></i> finalizado</span>
                    </td>
                </tr>
            </tbody>
          </table>
        </b-modal>

        <b-modal id="matriculas" title="Alunos Matrículados no Agendamento" size="lg" ok-only>
          <table class="table table-sm table-striped">
            <thead class="table-info">
                <tr>
                    <th scope="col" class="text-center">Contrato</th>                    
                    <th scope="col" class="text-left">Turma</th>
                    <th scope="col" class="text-left">Data</th>
                    <th scope="col" class="text-left">Matricula</th>
                    <th scope="col" class="text-left">Aluno</th>
                </tr>
            </thead>
            <tbody>
                <tr v-if="!lista.matriculas || lista.matriculas.length === 0" class="bg-white">
                    <td colspan="5" class="text-center"><span class="text-info">SEM REGISTRO(S)</span></td>
                </tr>
                <tr v-for="(matricula,index) in lista.matriculas" :key="'outhr'+index">
                    <td class="text-center">{{matricula.cntr_codigo}}</td>
                    <td class="text-left">{{matricula.turm_identificacao}}</td>
                    <td class="text-left">{{matricula.matri_data | data}}</td>
                    <td class="text-left">{{matricula.matri_matricula}}</td>
                    <td class="text-left">{{matricula.pes_nome}}</td>
                </tr>
            </tbody>
          </table>
        </b-modal>

      </div>
      <fieldset class="well">
        <legend class="well-legend">Seleção de Dias (Etapas), Disciplinas e Horários</legend>
        <div v-show="!showHorarios" style="max-width: 1100px; margin: 0 auto;">
          <div class="d-flex flex-wrap justify-content-center mct-calendar-status">
              <div>
                  <span>Qtd. Dias:</span>
                  <h1 class="mct-bg-red">{{lista.turm_totaldias}}</h1>
              </div>
              <div>
                  <span>Qtd. Dias Selecionados:</span>
                  <h1 class="mct-bg-green">{{lista.turm_totalselecionados}}</h1>
              </div>
              <div>
                  <span>Carga Horária Módulo(hs):</span>
                  <h1 class="mct-bg-green">{{serieSelecionada ? $options.filters.moeda(serieSelecionada.ser_ch) : ''}}</h1>
              </div>
          </div>

          <ul class="mct-calendar d-flex flex-wrap justify-content-center">
            <li v-for="(dia,indexDbl) in onGeraDiasBranco('L')" :key="'bl'+indexDbl">

            </li>
            <li v-for="(dia,index) in lista.horariosdia" :key="index" :class="dia.selecionado == 1 ? 'select' : 'unselect'">            
              <div class="mct-calendar-left float-left" :class="typeof dia.feriado != 'undefined' && dia.feriado === 1 ? 'mct-calendar-fer' : dia.horaridia_diasem == 0 ? 'mct-calendar-dom' : ''">
                <a href="#" @click.prevent.stop="onSelecionarDia(index)" title="Clique para Selecionar ou Deselecionar o Dia">
                  <span class="mct-calendar-mes">{{moment(dia.horaridia_data).locale("pt-br").format('MMM').toUpperCase()}}</span>
                  <span class="mct-calendar-dia">{{moment(dia.horaridia_data).locale("pt-br").format('DD')}}</span>
                  <span class="mct-calendar-diasem">{{moment(dia.horaridia_data).locale("pt-br").format('ddd').toUpperCase()}}</span>
                </a>
              </div>
              <a href="#" tabindex="-1" title="Adicionar Horário da Disciplina" @click.prevent.stop="onModalDisciplinaHora(dia,index)">
                <div class="mct-calendar-right float-right">
                  <div class="form-group m-0 p-0">
                    <span v-for="(disciplina,indexDisp) in dia.horariosdiadisp" :key="'disp'+indexDisp" class="badge badge-secondary" :title="disciplina.aula.turnhor_completo+' - '+disciplina.disp_nome" @click.prevent.stop="onAlterarDisciplinaHora(disciplina,indexDisp,index)"><i class="fa fa-times text-danger" aria-hidden="true" title="Excluir Registro de Aula" @click.prevent.stop="onExcluirDisciplinaHora(indexDisp,index)"></i> {{ disciplina.aula.turnhor_aula_c }} - {{disciplina.disp_nome}}</span>
                  </div>
                </div>
              </a>
            </li>
            <li v-for="(dia,indexDbr) in onGeraDiasBranco('R')" :key="'br'+indexDbr">

            </li>
          </ul>
        </div>
        <b-modal id="incluir-disciplina" title="Agendar Aula">
            <form>
              <div class="row">
                <div class="col-sm-12">
                  <mct-form-itens coluna="0" tipo="select" campo="disp_codigo" :valor="disciplina" titulo="Selecione a Disciplina" :errors="{}" 
                    :origemData="cursoDisciplinas" origemChave="disp_codigo" origemRetorno='disp_nome' origemRetornoSec='pes_nome'
                    modo="editar" showBotoes="false"></mct-form-itens>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <!--<mct-form-itens coluna="0" tipo="text" mascara="hora" campo="hora" :valor="disciplina" titulo="Horário da Aula" :errors="{}" 
                    modo="editar" showBotoes="false"></mct-form-itens>-->
                  <mct-form-itens coluna="0" tipo="select" campo="turnhor_codigo" :valor="disciplina" titulo="Aula / Horário" :errors="{}"
                    :origemData="turnoHorarios" origemChave="turnhor_codigo" origemRetorno='turnhor_aula_c' origemRetornoSec='turnhor_horario'
                    modo="editar" showBotoes="false"></mct-form-itens>
                </div>
              </div>
            </form>
            <template v-slot:modal-footer>
                <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('incluir-disciplina')">
                    <span class="fa fa-trash-o fa-sm" aria-hidden="true"></span> Fechar </button>
                <button type="button" class="btn btn-success btn-sm" aria-label="Left Align" @click.prevent.stop="onSalvarDisciplinaHora()">
                    <span class="fa fa-check fa-sm" aria-hidden="true"></span> Salvar </button>
            </template>
        </b-modal> 
        <!--<horarios v-show="showHorarios" :data="diaSelecionado" :linha="lista.horariosdia[linhaSelecionada]" :duracao="lista.turn_duracao_aula" :lista="cursoDisciplinas" @setHora="showHorarios = !showHorarios"/>-->
       
      </fieldset>

      <div style="margin-bottom: 5px; height: 30px;">
          <div class="h-divider clear"></div>
          <button type="button" class="btn btn-success" aria-label="Left Align"  @click.prevent.stop="onSubmit($event)" v-if="modo !== 'deletar' && modo !== 'visualizar' && modo !== 'readonly'">
                  <span class="fa fa-check fa-lg" aria-hidden="true"></span> Gravar Agendamento </button>
          <button type="button" class="btn btn-danger" aria-label="Left Align" @click.prevent.stop="onCancela($event)" v-if="modo === 'deletar'">
                  <span class="fa fa-ban fa-lg" aria-hidden="true"></span> Cancelar Agendamento </button>
          <button type="button" class="btn btn-dark float-right" aria-label="Left Align" @click.prevent.stop="$router.back()">
                  <span class="fa fa-times fa-lg" aria-hidden="true"></span> Fechar </button>
      </div>                 
    </div>
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import MctPadrao from '@/components/comum/MctFormPadraoSCB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctModal from '@/components/comum/MctModal.vue'

import Horarios from '@/components/modulos/siges/EscAgendamentosHorarios.vue'

export default {
  Name: 'Agendamentos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      MctModal,
      Horarios
  },
  data () {
      return {
        dataDias: [],
        dataFeriados: [],
        lista: {},
        errors:{},
        message: '',
        status: 0,
        turma: [],
        serie: [],
        turmaSelecionada: {},
        cursoDisciplinas: {},
        //turnoHorarios: [],
        diaSelecionado: null,
        linhaSelecionada: null,
        showHorarios: false,
        disciplina: {
          index: null,
          disp_codigo: null,
          turnhor_codigo: null
        },
        outrosAgendamentos: []
      }
  },
  methods: {
    setSituacao (tipo) {
      this.$swal({
            title: `Deseja realmente ${tipo === 1 ? 'reabrir' : tipo === 0 ? 'cancelar' : tipo === 3 ? 'finalizar' : ''} o agendamento?`,
            text: `${tipo === 1 ? 'Reabrir' : tipo === 0 ? 'Cancelar' : tipo === 3 ? 'Finalizar' : ''} o agendamento!`,
            html:
                '<label class="swal2-label">Digite o motivo.</label>' +
                '<input type="text" id="swal-input1" class="swal2-input">' +
                '<label class="swal2-label">Digite a data.</label>' +
                `<input type="date" id="swal-input2" class="swal2-input" disabled value="${this.moment(new Date()).format('YYYY-MM-DD')}">`,
            focusConfirm: false,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                const motivo = document.getElementById('swal-input1').value
                const data = document.getElementById('swal-input2').value

                if (motivo === '')
                {
                    this.$swal.showValidationMessage(
                        'Digite o motivo.'
                    )
                } else
                if (!moment(data).isValid())
                {
                    this.$swal.showValidationMessage(
                        'Digite a data.'
                    )
                } else {
                    return [
                        document.getElementById('swal-input1').value,
                        document.getElementById('swal-input2').value
                    ]
                }
            }

        }).then((result) => {
          if (result.value) {
            const motivo = result.value[0]
            //const data = result.value[1]

            /*this.lista.horari_situacao = tipo;
            this.lista.horari_situacao_usu = this.infoLogado.usu_nome;
            this.lista.horari_situacao_motivo = motivo;
            this.lista.horari_situacao_data = data;*/

              var dados = {
                horari_codigo: this.lista.horari_codigo,
                horari_ent: this.lista.horari_ent,
                horari_esc: this.lista.horari_esc,
                horari_ano: this.lista.horari_ano,
                horari_situacao: tipo,
                horari_situacao_usu: this.infoLogado.usu_nome,
                horari_situacao_motivo: motivo,
                horari_situacao_data: this.moment(new Date()).format('YYYY-MM-DD hh:mm:ss'),
              }

              axios.put('/escagendamento/situacao',dados)
                  .then(response => {
                    this.message = response.data.message
                    this.status = response.status
                    this.errors = {}
                    //this.getData()                      
                    this.$swal('Sucesso!',
                      `Agendamento ${tipo === 1 ? 'reaberto' : tipo === 0 ? 'cancelado' : tipo === 3 ? 'finalizado' : ''} com sucesso!`,
                      'success');
                    this.$router.back()
                  })
                  .catch(response => {
                      this.message = response.response.data.message;
                      this.errors = response.response.data.message;
                      this.status = response.response.status
                      this.$swal({
                          icon: 'error',
                          title: `Erro(s) ao tentar ${tipo === 1 ? 'reabrir' : tipo === 0 ? 'cancelar' : tipo === 3 ? 'finalizar' : ''} o agendamento`,
                          text: this.message,
                          footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                      })
                  })                
            }
        })

      /*this.$swal({
        title: 'Deseja realmente alterar a situação do agendamento?',
        text: "A Situação do agendamento não poderá ser alterada posteriormente!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
              this.lista.horari_situacao = tipo;
            }                     
        })*/
    },
    async getData() {
      let URL = ''
      if (Object.keys(this.$route.params).length > 1){
        for (var index in this.$route.params){
          if (URL === ''){
            URL += '?' +index +'='+ this.$route.params[index]
          }else{
            URL += '&' + index +'='+ this.$route.params[index]            
          }
        }
      }   
      if (typeof this.source != 'undefined'){
        if (this.modo !== 'novo'){
          URL = this.source + URL
        }else{
          URL = this.source
        }
        await axios.get(`${URL}`)
            .then(response => {
              this.lista = response.data.data
              this.columns = response.data.columns
              this.errors = {}
              if (this.modo === 'novo') {
                this.lista.horari_ent       = this.Entidade            
                this.lista.horari_esc       = this.Escola
                this.lista.horari_ano       = this.Exercicio   
                this.lista.horari_situacao  = 1                               
              }
            })
            .catch(response => {
              this.message = response.response.data.message
              this.errors = response.response.data.errors
              this.status = response.response.status
            })
      }
    },
    onSubmit (e) {
      this.$swal({
        title: 'Deseja realmente gravar o agendamento?',
        text: "Este agendamento poderá apenas ser cancelado posteriormente!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.onGravar()   
            }                     
        })
    },
    async onGravar (e) {
      
      var dados = this.lista
      await axios.post(`${this.source}`,dados)
          .then(response => {
              this.errors = {}
              
              this.$swal('Sucesso!',
              'Registro incluído com sucesso!',
              'success');  
          //this.mode = 'readonly'
          this.$router.back()                                          
          })
          .catch(response => {
              this.message = response.response.data.message
              if (response.response.data.error){
                  this.errors = response.response.data.error.errorInfo[2]              
              }else{
                  this.errors = response.response.data.errors
              }
              this.status = response.response.status
              
          })                     
    },
    onCancela (e) {
      this.$swal({
        title: 'Deseja realmente cancelar o registro?',
        text: "Cancelar Registro!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.onCancelar()   
            }                     
        })
    },
    async onCancelar() {
        let Parms = '?'
        if (Object.keys(this.$route.params).length > 1){
            for (var index in this.$route.params){
                if (Parms === '?'){
                    Parms = Parms + index +'='+ this.$route.params[index]
                }else{
                    Parms = Parms + '&' + index +'='+ this.$route.params[index]                    
                }
            }
        }else{
            Parms = '/' + this.$route.params['codigo']
        }
        await axios.delete(`${this.source}${Parms}`)
            .then(response => {
                this.message = response.data.message
                this.errors = {}                    
                this.status = response.status
                if (response.statusText == 'OK') {
                    this.$swal(
                        'Cancelado!',
                        'O registro foi cancelado com sucesso.',
                        'success'
                    )
                }
                this.$router.back()                     
            })
            .catch(response => {
                this.message = response.message
                if (response.response.data.error){
                    this.errors = response.response.data.error.errorInfo[2]              
                  }else{
                    this.errors = response.errors
                  }                     
                this.status = response.response.status
            })
    },
    setTurma (data) {
      this.turma = data
    }, 
    setSerie (data){
      this.serie = data
    },
    /* HORARIO / DIAS */
    onGeraDiasBranco (dir) {
      let diasem = 0
      if (typeof this.lista !== 'undefined' && typeof this.lista.horariosdia !== 'undefined' && typeof this.lista.horariosdia[0] !== 'undefined'){
        if (dir === 'L'){
          diasem = this.lista.horariosdia[0].horaridia_diasem
        } else {
          diasem = this.lista.horariosdia[this.lista.horariosdia.length-1].horaridia_diasem
        }
      }
      let ret = []
      if (dir === 'L'){
        while (diasem > 0){
          ret.push({diasem: diasem})    
          diasem--
        }
      } else {
        while (diasem < 6){
          ret.push({diasem: diasem})    
          diasem++
        }
      }
      return ret
    },    
    async getFeriados() {
      let URL = '/esccalendario/feriadosentredatas?dataini='+
        moment(this.lista.horari_data_inicial).locale("pt-br").format('YYYY-MM-DD')+
        '&datafin='+
        moment(this.lista.horari_data_final).locale("pt-br").format('YYYY-MM-DD')
      await axios.get(`${URL}`)
        .then(response => {
          this.dataFeriados = response.data.data          
        })
        .catch(response => {
          this.message = response.response.data.message
          this.errors = response.response.data.errors
          this.status = response.response.status
        })
    },
    async getDias() {
      let URL = 'escagendamento/dias?cur_codigo='+this.lista.cur_codigo+'&turm_codigo='+this.lista.turm_codigo//+'&disp_codigo='+this.lista.disp_codigo     
      await axios.get(`${URL}`)
        .then(response => {
          this.dataDias = response.data.data
        })
        .catch(response => {
          this.message = response.response.data.message
          this.errors = response.response.data.errors
          this.status = response.response.status
        })
    },
    fillFeriados () {
      for (var i = 0; i < this.dataFeriados.length; i++){
          this.dataFeriados[i]
          let data = this.dataFeriados[i].calen_dia
          var ret = this.lista.horariosdia.filter(function(el) {
            return moment(el.horaridia_data).locale("pt-br").format('YYYY-MM-DD') == moment(data).locale("pt-br").format('YYYY-MM-DD')
          })
          var index = this.lista.horariosdia.indexOf(ret[0])
          if (index >= 0){
            this.lista.horariosdia[index].feriado = 1
          }
      }       
    },        
    onGeraDias () {
      if (typeof this.lista.horari_data_inicial != 'undefined' && this.lista.horari_data_inicial != null 
          && typeof this.lista.horari_data_final != 'undefined' && this.lista.horari_data_final != null){
            for (let i in this.lista.horariosdia) {
              if (moment(this.lista.horariosdia[i].horaridia_data).locale("pt-br") < moment(this.lista.horari_data_inicial).locale("pt-br") || moment(this.lista.horariosdia[i].horaridia_data).locale("pt-br") > moment(this.lista.horari_data_final).locale("pt-br")){
                console.log(this.lista.horariosdia[i].horaridia_data)
                this.lista.horariosdia.splice(i,1);
              }              
            }
            
            let dias = moment(this.lista.horari_data_final).diff(this.lista.horari_data_inicial,'days')
            dias++
            this.lista.turm_totaldias = dias
            this.lista.turm_totalselecionados = 0
            let dataBase = this.lista.horari_data_inicial
            var diasSel = 0
            var ret = []
            for (var i = 0; i < dias; i++){                
                var data = moment(dataBase).add(i, 'days')
                if (typeof this.lista.horariosdia !== 'undefined'){
                  ret = this.lista.horariosdia.filter(function(el) {
                    return  moment(el.horaridia_data).locale("pt-br").format('YYYY-MM-DD') == moment(data).locale("pt-br").format('YYYY-MM-DD');
                  })
                } else {
                   this.lista.horariosdia = []  
                }                
                if (ret.length > 0){
                  var idx = this.lista.horariosdia.indexOf(ret[0])
                  if (this.lista.horariosdia[idx].horaridia_codigo > 0){
                    this.lista.horariosdia[idx].selecionado = 1
                    diasSel++
                  }
                  this.lista.horariosdia[idx].feriado = 0,
                  this.lista.horariosdia[idx].horaridia_seq = i+1,
                  this.lista.horariosdia[idx].horaridia_diasem = moment(this.lista.horariosdia[idx].horaridia_data).locale("pt-br").format('d')
                }else{
                  this.lista.horariosdia.push({
                    selecionado: 0,
                    feriado: 0,
                    horaridia_seq: i+1, 
                    horaridia_data: data,
                    horariosdiadisp: [],  
                    horaridia_diasem: moment(data).locale("pt-br").format('d')
                  })
                }
            }
            var teste = [...this.lista.horariosdia];
            this.lista.horariosdia = [...teste];
            this.lista.turm_totalselecionados = diasSel
            /* REORDENANDO */
            this.lista.horariosdia.sort(function (a, b) {
              return +(moment(a.horaridia_data).locale("pt-br").format('YYYY-MM-DD') > moment(b.horaridia_data).locale("pt-br").format('YYYY-MM-DD')) || +(moment(a.horaridia_data).locale("pt-br").format('YYYY-MM-DD') === moment(b.horaridia_data).locale("pt-br").format('YYYY-MM-DD')) - 1 ;
            });

            this.getFeriados().then(() => this.fillFeriados())
      }        
    },
    onSelecionarDia (index){
      if (this.modo === 'deletar' || this.modo === 'visualizar'){
        return
      }
      if (this.lista.horariosdia[index].selecionado == 0) {
        this.lista.horariosdia[index].selecionado = 1
        this.lista.turm_totalselecionados++
      } else {
        this.lista.horariosdia[index].selecionado = 0
        this.lista.turm_totalselecionados--
      }
    },
    /* DISCIPLINA */
    onModalDisciplinaHora (linha,index) {
      if (this.modo === 'deletar' || this.modo === 'visualizar'){
        return
      }
      this.diaSelecionado = moment(linha.horaridia_data).locale("pt-br").format('YYYY-MM-DD')
      this.linhaSelecionada = index
      this.disciplina.index = null
      this.disciplina.disp_codigo = null
      this.disciplina.turnhor_codigo = null
      this.$bvModal.show('incluir-disciplina')
    },
    onAlterarDisciplinaHora (linha,indexDisp,index) {
      //alert(indexDisp,index)
    },
    onExcluirDisciplinaHora(indexDisp,index){
      this.$swal({
        title: 'Deseja realmente excluir o registro de aula?',
        text: "Excluir Registro de Aula!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                //alert(indexDisp,index)
                this.lista.horariosdia[index].horariosdiadisp.splice(indexDisp,1)
            }                     
        })      
    },          
    async getDisciplinas () {
      if ((this.lista.ser_codigo) && (this.lista.turma.turn_codigo) ){
        await axios.get(`/esccursosdisciplinas/combo?ser_codigo=${this.lista.ser_codigo}&turn_codigo=${this.lista.turma.turn_codigo} `)
          .then(response => {
            this.cursoDisciplinas = response.data.data
          })
          .catch(response => {
            this.message = response.response.data.message
            this.errors = response.response.data.errors
            this.status = response.response.status
          })
      }
    },
    async getOutrosAgendamentos () {
      if ((this.lista.ser_codigo) && (this.lista.cur_codigo) && (this.lista.turm_codigo) ){
        await axios.get(`/escagendamento/outros?horari_codigo=${this.lista.horari_codigo || 0}&cur_codigo=${this.lista.cur_codigo}&ser_codigo=${this.lista.ser_codigo}&turm_codigo=${this.lista.turm_codigo} `)
          .then(response => {
            this.outrosAgendamentos = response.data.data
            this.$bvModal.show('outros-agendamentos')
          })
          .catch(response => {
            this.message = response.response.data.message
            this.errors = response.response.data.errors
            this.status = response.response.status
          })
      }
    },
    getMatriculas () {
      this.$bvModal.show('matriculas')
    },
    onSalvarDisciplinaHora () {
        let msgs = ''
        if (this.disciplina.disp_codigo == null || this.disciplina.disp_codigo == ''){
            msgs += 'Selecione uma Disciplina; </br>';
        }
        if (this.disciplina.turnhor_codigo == null || this.disciplina.turnhor_codigo == 0){
            msgs += 'Selecione uma Aula / Horário válida; </br>';
        }        
        if (msgs !== ''){
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Incluir / Alterar o Registro',
                html: msgs,
                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
            })        
        }else{
          var index = null      
          if (typeof this.lista.horariosdia[this.linhaSelecionada].horariosdiadisp.filter !== 'undefined'){
            var ret = this.lista.horariosdia[this.linhaSelecionada].horariosdiadisp.filter(el => 
              el.turnhor_codigo == this.disciplina.turnhor_codigo
            )
            index = this.lista.horariosdia[this.linhaSelecionada].horariosdiadisp.indexOf(ret[0])            
          }
          //console.log(index);
          const horarioSelecionado = this.turnoHorarios.filter(item => item.turnhor_codigo === this.disciplina.turnhor_codigo);
          const disciplinaSelecionado = this.cursoDisciplinas.filter(item => item.disp_codigo === this.disciplina.disp_codigo)
          if (index >= 0){
              this.lista.horariosdia[this.linhaSelecionada].horariosdiadisp[index].disp_codigo           = this.disciplina.disp_codigo
              this.lista.horariosdia[this.linhaSelecionada].horariosdiadisp[index].disp_nome             = disciplinaSelecionado[0].disp_nome              
              this.lista.horariosdia[this.linhaSelecionada].horariosdiadisp[index].turnhor_codigo        = this.disciplina.turnhor_codigo
              this.lista.horariosdia[this.linhaSelecionada].horariosdiadisp[index].aula                  = horarioSelecionado[0]
              
          } else {
              this.lista.horariosdia[this.linhaSelecionada].horariosdiadisp.push({  
                  disp_codigo           : this.disciplina.disp_codigo,
                  disp_nome             : disciplinaSelecionado[0].disp_nome,
                  turnhor_codigo        : this.disciplina.turnhor_codigo,
                  aula                  : horarioSelecionado[0]
              })                
          }
    
          this.lista.horariosdia[this.linhaSelecionada].selecionado = 1
          /* REORDENANDO *** VER DEPOIS *** */
          
          /* this.lista.horariosdia[this.linhaSelecionada].horariosdiadisp.sort(function (a, b) {
            return +(moment(a.horaridiadisp_hora_ini, "HH:mm").locale("pt-br").format('HH:mm') > moment(b.horaridiadisp_hora_ini, "HH:mm").locale("pt-br").format('HH:mm')) || +(moment(a.horaridiadisp_hora_ini, "HH:mm").locale("pt-br").format('HH:mm') === moment(b.horaridiadisp_hora_ini, "HH:mm").locale("pt-br").format('HH:mm')) - 1 ;
          });*/

          this.disciplina.index = null
          this.disciplina.disp_codigo = null
          this.disciplina.turnhor_codigo = null
          this.$bvModal.hide('incluir-disciplina')
        }
    },    
  },
  computed: {
    ...mapState('Login',['infoLogado','Entidade', 'Exercicio', 'Escola']),
    turnoHorarios () {
      return this.turmaSelecionada && this.turmaSelecionada.turno && this.turmaSelecionada.turno.horarios ? this.turmaSelecionada.turno.horarios : []
    },
    serieSelecionada () {
      if (this.serie && this.serie.filter){
        const serie = this.serie.filter(item => item.ser_codigo === this.lista.ser_codigo)
        //console.log(this.serie,(serie))
        return serie ? serie[0] : {} 
      }
      return {}
    }
  },
  mounted() {
    setTimeout(() => {
      
    }, 1000)
  },  
  created() {
    //if (this.modo !== 'novo'){
      this.getData()
    //}
    //this.setData() 
    
  },
  watch: {
    'lista.horari_data_inicial': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null) {
            this.onGeraDias()
        }
    },
    'lista.horari_data_final': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null) {
            this.onGeraDias()
        }
    },
    'turma': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
          var ret = newVal.filter(el => {
              return el.turm_codigo == this.lista.turm_codigo;
          })
          if (ret[0]){
            this.turmaSelecionada = ret[0]
            this.lista.turma = this.turmaSelecionada
            this.lista.cur_codigo = this.turmaSelecionada.cur_codigo
          }
        }
    },
    'lista.turm_codigo': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
          var ret = this.turma.filter(function(el) {
              return el.turm_codigo == newVal;
          })
          if (ret[0]){
            this.turmaSelecionada = ret[0]
            this.lista.turma = this.turmaSelecionada
            this.lista.cur_codigo = this.turmaSelecionada.cur_codigo            
          }
        }
    },
    'lista.ser_codigo': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
          this.getDisciplinas()   
        }
    },
  }     
}
</script>
