<template>
    <div v-if="!editing">
        <span class='text' @click="enableEditing">{{value}}</span>
    </div>
    <div v-else class="div-buttons">
        <input v-model="tempValue" class="input"/>
        <a href="#" @click.prevent.stop="disableEditing" title="Cancelar"><i class="fa fa-times"></i></a>
        <a href="#" @click.prevent.stop="saveEdit" title="Salvar"><i class="fa fa-check"></i></a>        
    </div>
</template>

<script>
export default {
    props: ['id','value'],
    data () {
        return {
            tempValue: null,
            editing: false    
        }
    },
    methods: {
        enableEditing: function(){
            this.tempValue = this.value;
            this.editing = true;
        },
        disableEditing: function(){
            this.tempValue = null;
            this.editing = false;
        },
        saveEdit: function(){
        // However we want to save it to the database
            //this.value = this.tempValue;
            this.$emit('update:value', this.tempValue);
            this.$emit('onSave', {id: this.id, value: this.tempValue});
            this.disableEditing();
        }        
    }
}
</script>

<style scoped>

input {
    width: 100%;
    border: 1px solid #d1d1dd;
    padding: 5px;
    border-radius: 0px;
    outline: 1px solid;
    outline-color: var(--blue);
    
}

input:target {
    border-color: red;
}

a {
    font-weight: normal;
}

.div-buttons {
    position: relative;
}

.div-buttons a:first-of-type {
    position: absolute;
    top: 5px;
    right: 25px;
}

.div-buttons a:nth-of-type(2) {
    position: absolute;
    top: 5px;
    right: 5px;
}

</style>