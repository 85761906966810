<template>
    <fieldset class="well">
        <legend class="well-legend">Sócios</legend>
        <button type="button" class="btn btn-success btn-sm mb-1" aria-label="Left Align" @click="incluirSocio()">
            <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Incluir Novo </button>                     
        <table class="table table-striped table-bordered table-sm table-hover">
            <thead>
                <tr>
                    <th style="width: 55px!important;">Ações</th>
                    <th>
                        <span>CÓDIGO</span>
                    </th>
                    <th>
                        <span>SÓCIO</span>
                    </th>
                    <th>
                        <span>CPF</span>
                    </th>
                    <th>
                        <span>PERC.(%)</span>
                    </th>
                    <th>
                        <span>REG. JUNTA COM.</span>
                    </th>
                    <th>
                        <span>DT. CADASTRO</span>
                    </th>                                
                </tr>
            </thead>
            <tbody>
                <tr v-for="(socio,index) in socios" :key="index" :class="[{editing: socio == linhaEditada},{'is-excluido': socio.status == 'E'}]" v-cloak v-on:dblclick="editSocio(socio,index)" :id="'socio_'+index"> 
                    <td class="is-icon">
                        <i class="fa fa-pencil text-white mct-fa bg-primary" aria-hidden="true" @click="editSocio(socio,index)"></i>
                        <i class="fa fa-times text-white bg-danger mct-fa" aria-hidden="true" @click="excluirSocio(index)"></i>
                    </td>
                    <td> 
                        <div class="view" style="width: 100px;">
                            {{socio.socio_codigo}}
                        </div>
                        <div class="edit" style="width: 100px;">
                            <mct-form-itens tipo="search-input" campo="socio_codigo" :valor="socio" :errors="errors" origem="pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' :origemLinha='index' @setDataRetorno="setData" :modo="modo" telaLista="PessoasLista" telaListaTitulo="Pessoas"></mct-form-itens>
                        </div>
                    </td>
                    <td>
                        <div class="view" style="min-width: 230px;">
                            {{socio.socio_nome}}
                        </div>
                        <div class="edit" style="min-width: 230px;">
                            <mct-form-itens tipo="text" campo="socio_nome" :valor="socio" :errors="errors" modo="readonly" tabindex="-1"></mct-form-itens>
                        </div>
                    </td>
                    <td>
                        <div class="view" style="width: 120px;">
                            {{socio.socio_cpf}}
                        </div>
                        <div class="edit" style="width: 120px;">
                            <mct-form-itens tipo="text" campo="socio_cpf" :valor="socio" :errors="errors" mascara="cpf" modo="readonly" tabindex="-1"></mct-form-itens>
                        </div>
                    </td>
                    <td>
                        <div class="view" style="width: 80px;">
                            {{socio.socio_percentual}}
                        </div>
                        <div class="edit" style="width: 80px;">
                            <mct-form-itens tipo="number" campo="socio_percentual" :valor="socio" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </td>
                    <td>
                        <div class="view" style="width: 130px;">
                            {{ moment(socio.socio_dt_reg_junta).format('DD/MM/YYYY') }}
                        </div>
                        <div class="edit" style="width: 130px;">
                            <mct-form-itens tipo="date" campo="socio_dt_reg_junta" :valor="socio" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </td>
                    <td>
                        <div class="view" style="width: 130px;">
                            {{ moment(socio.socio_dt_cadastro).format('DD/MM/YYYY') }}
                        </div>
                        <div class="edit" style="width: 130px;">
                            <mct-form-itens tipo="date" campo="socio_dt_cadastro" :valor="socio" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </fieldset>                                            
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import MctFormItens from '../../comum/MctFormItens.vue'
import { mapState, mapActions } from 'vuex'

export default {
  Name: 'TblSocios',  
  props: ['modo','socios'],
  components: {
      MctFormItens,
  },
  data () {
      return {
          errors:{},
          message: '',
          status: 0,
          tipoAlerta: '',
          linhaEditada: null,
          pessoa: null
      }
  },
  methods: {
    setPessoa(codigo) {
        this.pessoa = codigo
    },          
    incluirSocio () {
        if (typeof this.socios === 'undefined'){
            this.socios.push( {pes_codigo: this.pessoa, socio_codigo: '', socio_nome: '', socio_cpf: '', status: 'N'} ) 
            this.linhaEditada = this.socios[0]
            this.linhaEditada['id'] = 0 
        }else
        if (this.socios[0] && typeof this.socios[0].pes_codigo === 'undefined'){
            this.socios[0] = {pes_codigo: this.pessoa, socio_codigo: '', socio_nome: '', socio_cpf: '', status: 'N'}
            this.linhaEditada = this.socios[0]
            this.linhaEditada['id'] = 0             
            }else{
            this.socios.push( {pes_codigo: this.pessoa, socio_codigo: '', socio_nome: '', socio_cpf: '', status: 'N'} ) 
            this.linhaEditada = this.socios[this.socios.length-1]
            this.linhaEditada['id'] = this.socios.length-1 
        }
    },
    excluirSocio (linha) {
        this.linhaEditada = null   
        this.socios[linha].status = 'E'
        var element = document.getElementById("socio_"+linha)
        element.classList.add("is-excluido")
    },
    editSocio (linha,id) {
        if ((typeof this.socios[id].status != 'undefined' && this.socios[id].status != 'E') || typeof this.socios[id].status == 'undefined'){
            if (this.linhaEditada === linha){
                this.linhaEditada = null    
            }else{
                this.linhaEditada = linha
                this.linhaEditada['id'] = id 
            }
        }
    },
    setData (data) {
        if (typeof data != 'undefined' && this.linhaEditada != null){
            if (data == 404 || data == '404'){
                this.socios[this.linhaEditada.id].socio_nome = ''
                this.socios[this.linhaEditada.id].socio_cpf = ''
            }else{                
                this.socios[this.linhaEditada.id].socio_nome = data.pes_nome
                this.socios[this.linhaEditada.id].socio_cpf = data.pes_cpf
            }
        }
    }              
  },
  watch: {
      /*'socios' () {
          this.setSocios()
      }*/
  },
  computed: {
      /*compPessoa () {
          return this.pessoa
      }*/

  }     
}
</script>

<style scoped>
    [v-cloak] {
        display: none;
    }
    .edit {
        display: none;
    }
    .editing .edit {
        display: block
    }
    .editing .view {
        display: none;
    }  
      
    .is-icon{
        max-width: 65px;
    }

    .is-excluido{
        color: #C82333;
        background-color: #e39199!important; 
        text-decoration: line-through;
    }    
</style>

