<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#cadastro" role="tab">Curso</a>
                </li>
                <!--<li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#combo" role="tab">Combos</a>
                </li>-->
                <li class="nav-item" v-if="infoEscola.esc_tipo !== 1">
                    <a class="nav-link" data-toggle="tab" href="#conteudo" role="tab">Módulos</a>
                </li>
                <li class="nav-item" v-if="((infoEscola.esc_tipo !== 1) && (infoEscola.esc_administracao = 'P'))">
                    <a class="nav-link" data-toggle="tab" href="#financeiro" role="tab">Vinc. Finan./Contábil</a>
                </li>
            </ul>
            <!-- TABS -->
            <div class="tab-content">
                <div class="tab-pane active" id="cadastro" role="tabpanel">                
                    <div class="row">
                        <div class="col-sm-2">
                            <mct-form-itens coluna="0" tipo="number" campo="cur_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                        </div>    
                        <div class="col-lg-5">
                            <mct-form-itens tipo="checkbox" campo="cur_situacao" :valor="lista" :titulo="lista.cur_situacao == 1 ? 'Cadastro Ativo.' : 'Cadastro Inativado.'" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <mct-form-itens tipo="text" campo="cur_nome" :valor="lista" titulo="Curso" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <mct-form-itens tipo="text" campo="cur_habilitacao" :valor="lista" titulo="Habilitação" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-sm-3">
                            <mct-form-itens tipo="select" campo="cur_regime" :valor="lista" titulo="Regime" obrigatorio="false" :errors="errors" 
                                :origemData="[{value: 'A', text: 'ANUAL'}, {value: 'S', text: 'SEMESTRAL'}, {value: 'T', text: 'TÉCNICO'}, {value: 'P', text: 'PERIÓDICO'}, {value: 'O', text: 'OUTRO'}]" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <mct-form-itens tipo="colorpicker" campo="cur_cor" :valor="lista" titulo="Cor" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>

                    <!--<div class="row">
                        <div class="col-lg-5">
                            <mct-form-itens tipo="checkbox" campo="cur_combo" :valor="lista" titulo="Combo" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <fieldset class="well" v-if="lista.cur_combo == 1">
                        <legend class="well-legend">Combo de Cursos</legend>    
                        <mct-form-itens tipo="selectmult" campo="combos" :valor="lista" titulo="Cursos do Combo" obrigatorio="false" origem="esc_cursos" origemChave="ccmb_cur_codigo" origemRetorno='cur_nome' :errors="errors" :modo="modo"></mct-form-itens>                                                           
                    </fieldset>-->
                </div>
                <div class="tab-pane" id="conteudo" role="tabpanel" v-if="infoEscola.esc_tipo !== 1">
                    <fieldset class="well">
                        <legend class="well-legend">Módulos</legend> 
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-1 pl-0 pr-2">
                                <mct-form-itens tipo="text" campo="ser_serie" :valor="serie" titulo="Grade" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-sm-4 pl-0 pr-2">
                                <mct-form-itens tipo="text" campo="ser_descricao" :valor="serie" titulo="Descrição" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-sm-1 pl-0 pr-2">
                                <mct-form-itens tipo="text" campo="ser_ch" :valor="serie" titulo="CH" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="is-icon mt-3">
                                <i class="fa fa-check text-white mct-fa bg-success" aria-hidden="true" @click="onAdcSerie()"></i>
                            </div>

                        </div>

                        <div class="mct-grid">
                            <div class="mct-grid-scroll-sec">                        
                                <table class="table table-sm table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col" style="width: 70px;">Grade</th>
                                            <th scope="col" style="width: 100%;">Descrição</th>
                                            <th scope="col" style="width: 100%;">CH</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                
                                        <tr v-for="(serie,index) in lista.series" :key="index">
                                            <th scope="row">
                                                <div class="is-icon">
                                                    <i class="fa fa-pencil text-white mct-fa bg-primary" aria-hidden="true" @click="onEditSerie(index)"></i>
                                                    <i class="fa fa-times text-white bg-danger mct-fa" aria-hidden="true" @click="onExcSerie(index)"></i>
                                                </div>
                                            </th>
                                            <td>{{serie.ser_serie}}</td>
                                            <td>{{serie.ser_descricao}}</td>
                                            <td>{{serie.ser_ch}}</td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </fieldset>
                </div>

                <div class="tab-pane" id="financeiro" role="tabpanel" v-if="((infoEscola.esc_tipo !== 1) && (infoEscola.esc_administracao = 'P'))">
                    <fieldset class="well">
                        <legend class="well-legend">Centro de Custo</legend> 
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-8 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="ccst_codigo" :valor="lista" titulo="Centro de Custo" 
                                    origem="esc_centro_custo" origemChave="ccst_codigo" origemRetorno='ccst_descricao' 
                                    :origemFiltro="{ccst_status: 1}" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset class="well">
                        <legend class="well-legend">Plano de Contas</legend> 
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-8 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plnc_codigo" :valor="lista" titulo="Plano de Contas"
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>                            
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>              
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'Cursos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
        serie: {
            ser_codigo: null,
            ser_serie: '',
            ser_descricao: '',
            ser_ch: ''
        }
      }
  },
  methods: {
    onSelect (items, lastSelectItem) {
        this.items = items
        //lista.combos = items
        this.lastSelectItem = lastSelectItem
    },  
    setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            this.lista.cur_situacao = 1    
        }
    },
    onAdcSerie () {
        this.lista.series.push(
        {
            ser_codigo: this.serie.ser_codigo,
            ser_serie: this.serie.ser_serie,
            ser_descricao: this.serie.ser_descricao,
            ser_ch: this.serie.ser_ch
        })

        this.serie.ser_codigo = null
        this.serie.ser_serie = ''
        this.serie.ser_descricao = ''
        this.serie.ser_ch = ''
    },
    onEditSerie (index) {
        this.serie.ser_codigo = this.lista.series[index].ser_codigo
        this.serie.ser_serie = this.lista.series[index].ser_serie
        this.serie.ser_descricao = this.lista.series[index].ser_descricao
        this.serie.ser_ch = this.lista.series[index].ser_ch
        this.lista.series.splice(index,1)
    },
    onExcSerie (index) {
        this.$swal({
            title: 'Deseja realmente remover o item da lista?',
            text: "Este evento não podera ser revertido!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.lista.series.splice(index,1)                       
            }
        })        
    }
  },
  computed: {
    ...mapState('Login',['infoEscola']), 

  }, 
  created() {
  
  }     
}
</script>