<template>
    <fieldset class="well">
        <!--<legend class="well-legend">Sócios</legend>
        <button type="button" class="btn btn-success btn-sm mb-1" aria-label="Left Align" @click="incluirSocio()">
            <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Incluir Novo </button>    -->                 
        <table class="table table-striped table-bordered table-sm table-hover">
            <thead>
                <tr>
                    <!--<th style="width: 55px!important;">Ações</th>
                    <th>
                        <span></span>
                    </th>-->
                    <th>
                        <span>HORÁRIO</span>
                    </th>
                    <th>
                        <span>SEGUNDA</span>
                    </th>
                    <th>
                        <span>TERÇA</span>
                    </th>
                    <th>
                        <span>QUARTA</span>
                    </th>
                    <th>
                        <span>QUINTA</span>
                    </th>  
                    <th>
                        <span>SEXTA</span>
                    </th>
                    <th>
                        <span>SÁBADO</span>
                    </th>                              
                </tr>
            </thead>
            <tbody>
                <tr v-for="(aula,index) in aulas" :key="index" :class="[{editing: aula == linhaEditada}]" v-cloak> 
                    <!--<td class="is-icon">
                        <i class="fa fa-pencil text-white mct-fa bg-primary" aria-hidden="true" @click="editAula(socio,index)"></i>
                        <i class="fa fa-times text-white bg-danger mct-fa" aria-hidden="true" @click="excluirSocio(index)"></i>
                    </td>-->
                    <td> 
                        <div class="view" style="width: 100px;">
                            {{index+1}}
                        </div>
                    </td>
                    <td> 
                        <div class="view" style="width: 100px;">
                            <mct-form-itens tipo="checkbox" campo="turmau_segunda" :valor="aula" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </td>
                    <td>
                        <div class="view" style="min-width: 100px;">
                            <mct-form-itens tipo="checkbox" campo="turmau_terca" :valor="aula" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </td>
                    <td>
                        <div class="view" style="width: 100px;">
                            <mct-form-itens tipo="checkbox" campo="turmau_quarta" :valor="aula" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </td>
                    <td>
                        <div class="view" style="width: 100px;">
                            <mct-form-itens tipo="checkbox" campo="turmau_quinta" :valor="aula" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </td>
                    <td>
                        <div class="view" style="width: 100px;">
                            <mct-form-itens tipo="checkbox" campo="turmau_sexta" :valor="aula" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </td>
                    <td>
                        <div class="view" style="width: 100px;">
                            <mct-form-itens tipo="checkbox" campo="turmau_sabado" :valor="aula" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </fieldset>                                            
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import MctFormItens from '../../comum/MctFormItens.vue'
import { mapState, mapActions } from 'vuex'

export default {
  Name: 'TblAulas',  
  props: ['modo','aulas'],
  components: {
      MctFormItens,
  },
  data () {
      return {
          errors:{},
          message: '',
          status: 0,
          tipoAlerta: '',
          linhaEditada: null,
          pessoa: null
      }
  },
  methods: {
    /*setPessoa(codigo) {
    },          
    incluirSocio () {
       
    },
    excluirSocio (linha) {
       
    },
    editAula (linha,id) {
        
    },
    setData (data) {
        
    }  */            
  },
  watch: {
      /*'socios' () {
          this.setSocios()
      }*/
  },
  computed: {
      /*compPessoa () {
          return this.pessoa
      }*/

  }     
}
</script>

<style scoped>
    [v-cloak] {
        display: none;
    }
    .edit {
        display: none;
    }
    .editing .edit {
        display: block
    }
    .editing .view {
        display: none;
    }  
      
    .is-icon{
        max-width: 65px;
    }

    .is-excluido{
        color: #C82333;
        background-color: #e39199!important; 
        text-decoration: line-through;
    }    
</style>

