<template>
    <div class="mct-form container">
        <mct-topo-lista :titulo="titulo" :subtitulo="`Manutenção de ${titulo}`"></mct-topo-lista>    
        <mct-alertas :mensagem="message" :errors="errors" :status="status" :modo="modoComp" v-if="status !== 0"></mct-alertas>
        <div class="container-fluid">
          <mct-form>
            <mct-form-itens v-if="column.tipo !== 'logradouro'" coluna="0" v-for="(column,index) in columns" :key="index" 
              :tipo="column.tipo" 
              :campo="column.nome" 
              :valor="lista" 
              :titulo="column.titulo" 
              :obrigatorio="column.required"
              :errors="errors" 
              :origem="column.origem ? column.origem : null" 
              :origemChave="column.origem_chave ? column.origem_chave : null"
              :origemChaveComposta="column.origem_chave_composta ? column.origem_chave_composta : null" 
              :origemRetorno='column.origem_campo ? column.origem_campo : null' 
              :origemData='column.data ? column.data : null'
              :origemDataJson='column.dataJson ? column.dataJson : null'
              :origemDataCSV='column.dataCSV ? column.dataCSV : null'
              :modo="column.readonly == true ? 'readonly' : column.autoincremento && column.autoincremento == true ? 'readonly' : modoComp" 
              :tamanho="column.tamanho" 
              :visivel="column.visivel_form"
              :linhas="column.linhas" 
              :transforme="column.case" 
              :mascara="column.mascara ? column.mascara : null"
              :chave='column.chave ? column.chave : false'
              :telaLista="column.telaLista ? column.telaLista : null"
              :obs='column.obs'
              ></mct-form-itens>
            <div v-else>
              <mct-logradouro v-if="column.tipo === 'logradouro'" v-for="(column,index) in columns" :key="index"
                :modo="column.readonly == true ? 'readonly' : column.autoincremento && column.autoincremento == true ? 'readonly' : modoComp" 
                :valor="lista" :rels="column.lograRels"></mct-logradouro> 
            </div>
          </mct-form>
        </div>
        <mct-form-botoes @submit="submit" :modo="modoComp" :modal="modal"></mct-form-botoes>
    </div>
</template>

<script>
import Vue from 'vue'
import MctTopoLista from './MctTopoLista.vue'
import MctForm from './MctForm.vue'
import MctFormItens from './MctFormItens.vue'
import MctFormBotoes from './MctFormBotoes.vue'
import MctAlertas from './MctAlertas.vue' 
import MctMixinsForm from '../funcoes/MctMixinsForm'
import MctLogradouro from './MctLogradouro.vue'

export default {
  Name: 'MctFormViewer',  
  mixins: [MctMixinsForm],
  components: {
      MctForm,
      MctFormItens,
      MctTopoLista,
      MctFormBotoes,
      MctAlertas,
      MctLogradouro,
  },
  props: ['source','titulo','modo','modal'],
  data () {
    return{

    }
  },
  methods: {

  },
}
</script>

