<template>
    <div>
        <div class="modal fade" id="MctPopUp" tabindex="-1" role="dialog" aria-labelledby="MctPopUpLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
            <div class="modal-header" :class="[
                            {'modal-danger': modo === 'error'},
                            {'modal-info': modo === 'success'},
                            {'modal-danger': modo === 'deletar'}
                        ]">
                <div class="icon ">
                    <i class="fa" :class="[
                            {'fa-times': modo === 'error'},
                            {'fa-check': modo === 'success'},
                            {'fa-info-circle': modo === 'deletar'}
                        ]"></i>
                </div>

                <h5 class="modal-title" id="MctPopUpLabel">{{ titulo }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                {{ mensagem }}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm" :class="[{'btn-primary': modo === 'success'},
                                                                  {'btn-danger': modo !== 'success'}]" 
                    @click.prevent.stop="confirma($event)" v-if="modo === 'deletar'">Confirmar</button>
                <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal">Fechar</button>
            </div>
            </div>
        </div>
        </div>        
    </div>  
</template>

<script>
export default {
    props: ['titulo','mensagem','modo'],
    methods: {
        confirma (ev){
            ev.preventDefault()
            $('#MctPopUp').modal('hide')
            this.$emit('confirma')
        }
    },
    watch: {
        /*showModal: function (val) {
            $('#MctPopUp').modal('show')
        }*/
    }    
}
</script>

<style scoped>
    .modal-content{
        border-radius: 0;
    }

    .modal-dialog {    
        width: 360px;    
    }
    
    .modal-header {    
        /*background-image: linear-gradient(to bottom, #337AB7, #337AB7);*/
        padding:16px 16px;
        color:#FFF;
        position: relative;

        border: 0;
        border-radius: 0;
        padding-left: 61px;
    }  
    
    .modal-header .close{
        color: #FFF;
    }
    .modal-header.rounded {
        border-radius: 3px;
        -webkit-border-radius: 3px;
    }

    .modal-header.rounded .icon {
        border-radius: 3px 0 0 3px;
        -webkit-border-radius: 3px 0 0 3px;
    }

    .modal-header .icon {
        text-align: center;
        width: 45px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border: 1px solid #bdbdbd;
        padding-top: 15px;
    }


    .modal-header .icon:after {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        display: block;
        content: '';
        width: 10px;
        height: 10px;
        border: 1px solid #bdbdbd;
        position: absolute;
        border-left: 0;
        border-bottom: 0;
        top: 50%;
        right: -6px;
        margin-top: -3px;
        background: #fff;
    }

    .modal-header .icon i {
        font-size: 20px;
        color: #fff;
        left: 12px;
        margin-top: -10px;
        position: absolute;
        top: 50%;
    }  
    
    /*============ colors ========*/
    .modal-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
    }

    .modal-header.modal-success .icon, 
    .modal-header.modal-success .icon:after {
    border-color: #54a754;
    background: #60c060;
    }

    .modal-info {
    background-color: #d9edf7;
    border-color: #98cce6;
    color: #3a87ad;
    }

    .modal-header.modal-info .icon, 
    .modal-header.modal-info .icon:after {
    border-color: #3a8ace;
    background: #4d90fd;
    }


    .modal-header.modal-warning .icon, 
    .modal-header.modal-warning .icon:after {
    border-color: #d68000;
    background: #fc9700;
    }

    .modal-warning {
    background-color: #fcf8e3;
    border-color: #f1daab;
    color: #c09853;
    }

    .modal-danger {
    background-color: #f2dede;
    border-color: #e0b1b8;
    color: #b94a48;
    }

    .modal-header.modal-danger .icon, 
    .modal-header.modal-danger .icon:after {
    border-color: #ca452e;
    background: #da4932;
    }    
</style>


