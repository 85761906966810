<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="!Alterando ? 'editar' : 'readonly'" botoes="false">
    <div slot="formulario">
      <div class="view" style="padding-top: 5px; padding-bottom: 5px;">      
        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Professor</legend> 
          <div class="row">
            <div class="col-12">
              <mct-form-itens tipo="select" :campo="infoLogado.pes_codigo > 0 ? 'contd_prof_logado' : 'contd_prof_codigo'" :valor="lista" titulo="Professor" obrigatorio="true" :errors="errors" :origemData="professores" origemRetornoSec="value" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
          </div>  
        </fieldset>
        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Turma</legend> 
          <div class="row">
            <div class="col-4">
              <mct-form-itens tipo="select" campo="contd_turm_codigo" :valor="lista" titulo="Turma" obrigatorio="true" :errors="errors" :origemData="turmas" origemRetornoSec="value" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
            <div class="col-4" v-if="infoEscola.esc_tipo !== 1">
              <mct-form-itens tipo="select" campo="contd_ser_codigo" :valor="lista" titulo="Módulo" :errors="errors" obrigatorio="true" 
                :origemFiltro="{cur_codigo: turmaSelecionada.cur_codigo}" origemChave="ser_codigo" origemRetorno='ser_descricao' origemRetornoSec="ser_codigo" origem="esc_serie" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
            <div class="col-4">
              <mct-form-itens tipo="select" campo="contd_disp_codigo" :valor="lista" titulo="Disciplina" :errors="errors" obrigatorio="true" :origemData="disciplinas" origemRetornoSec="value" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
            <div class="col-4" v-if="infoEscola.esc_tipo === 1">
              <mct-form-itens tipo="select" campo="contd_etap_codigo" :valor="lista" titulo="Etapa" :errors="errors" obrigatorio="true" origem="esc_etapas" origemChave="etap_codigo" origemRetorno='etap_descricao'  :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
          </div>
          <div class="row" v-if="infoEscola.esc_tipo !== 1">
            <div class="col-12">
              <mct-form-itens tipo="select" campo="contd_horari_codigo" :valor="lista" titulo="Agendamento" :errors="errors" obrigatorio="true" 
                :origemFiltro="{cur_codigo: turmaSelecionada.cur_codigo, ser_codigo: lista.contd_ser_codigo, turm_codigo: lista.contd_turm_codigo}" origemChave="horari_codigo" origemRetorno='horari_descricao' origemRetornoSec="horari_codigo" origem="esc_horarios" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
          </div>
        </fieldset>

         <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Aulas/Conteúdo/Material de Apoio</legend> 
          <div class="row">
            <div class="col-4">
              <mct-form-itens tipo="datepicker" campo="contd_data" :valor="lista" titulo="Data" :errors="errors" obrigatorio="true" :modo="!Alterando ? 'editar' : 'readonly'" :legenda="moment(lista.contd_data).locale('pt-br').format('dddd')"
              :disabled-date="disabledBeforeTodayAndAfterAWeek"
              ></mct-form-itens>
            </div>
            <div class="col-2">
              <mct-form-itens tipo="number" campo="contd_aula" :valor="lista" titulo="Conteúdo da:" :errors="errors" obrigatorio="true" :modo="!Alterando ? 'editar' : 'readonly'" legenda="ª Aula"></mct-form-itens>
            </div>
            <div class="col-2">
              <mct-form-itens tipo="number" campo="contd_qtde" :valor="lista" titulo="Quant. de Aulas" :errors="errors" obrigatorio="true" modo="readonly"></mct-form-itens>
            </div>
            <div class="col-4">
              <div class="btn-group" role="group" aria-label="Ações" style="margin-top: 20px;">
                <button type="button" class="btn btn-info text-white" aria-label="Left Align" :disabled="Alterando"  @click.prevent.stop="alterar()">
                <span class="fa fa-pencil text-white" aria-hidden="true"></span> Alterar </button>
                <button type="button" class="btn btn-success text-white" aria-label="Left Align" :disabled="!Alterando"  @click.prevent.stop="confirma()">
                <span class="fa fa-check fa-lg text-white" aria-hidden="true"></span> Gravar </button>
                <button type="button" class="btn btn-dark text-white" aria-label="Left Align" :disabled="!Alterando"  @click.prevent.stop="cancelar()">
                <span class="fa fa-ban fa-lg text-white" aria-hidden="true"></span> Cancelar </button>
              </div>
            </div>
          </div>
          <div class="mct-grid" style="padding-top: 10px;">   
            <div class="row">
              <div class="col-12">
                <mct-form-itens tipo="text" campo="contd_descricao" :valor="lista" titulo="Descrição da Aula:" :errors="errors" :modo="Alterando ? 'editar' : 'readonly'"></mct-form-itens>
              </div>       
            </div>
            <div class="row">
              <div class="col-12">
                <mct-form-itens tipo="textarea" campo="contd_conteudo" :valor="lista" titulo="Conteúdo da Aula:" :errors="errors" :modo="Alterando ? 'editar' : 'readonly'" linhas="10"></mct-form-itens>
              </div>       
            </div>
            <div class="row">
              <div class="col-12">
                <mct-form-itens tipo="textarea" campo="contd_observacoes" :valor="lista" titulo="Obsevações da Aula:" :errors="errors" :modo="Alterando ? 'editar' : 'readonly'" linhas="5"></mct-form-itens>
              </div>       
            </div>
          </div>
          <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
            <legend class="well-legend">Material de Apoio</legend> 
            <button 
                v-if="Alterando"
                type="button" class="btn btn-success btn-sm mb-2 text-white" aria-label="Left Align" @click="onIncluirMaterial($event)">
                <span class="fa fa-plus fa-sm text-white" aria-hidden="true"></span> Incluir Material </button> 

            <b-modal id="material-modal" title="Incluir/ Editar Material">
                <div class="row">
                    <div class="col-12">
                        <mct-form-itens tipo="select" campo="contdm_tipo" :valor="material" titulo="Tipo Material" obrigatorio="true" :errors="errors" 
                          :origemData="[{value: 1, text: 'MATERIAL EM VÍDEO'}, {value: 2, text: 'MATERIAL EM ÁUDIO'}, {value: 3, text: 'MATERIAL EM PDF'}, {value: 4, text: 'IMAGENS'}, {value: 5, text: 'QUESTIONÁRIO'}]" :modo="modo"></mct-form-itens>    
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <mct-form-itens tipo="number" campo="contdm_valor" :valor="material" titulo="Valor" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-6">
                        <mct-form-itens tipo="select" campo="contdm_valor_tipo" :valor="material" titulo="Tipo" obrigatorio="false" :errors="errors" 
                          :origemData="[{value: 1, text: 'PERCENTUAL'}, {value: 2, text: 'NOTA'}]" :modo="modo"></mct-form-itens>    
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <mct-form-itens tipo="date" campo="contdm_data_encerramento" :valor="material" titulo="Dt. Encerramento" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-6">
                        <mct-form-itens tipo="text" campo="contdm_hora_encerramento" :valor="material" titulo="Hr. Encerramento" mascara="hora" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mct-form-itens tipo="text" campo="contdm_descricao" :valor="material" titulo="Descrição" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                </div>
                <div class="row" v-show="material.contdm_tipo === 1 || material.contdm_tipo === 2">
                    <div class="col-12">
                        <mct-form-itens tipo="text" campo="contdm_url" :valor="material" titulo="Link do Vídeo" obrigatorio="" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                </div>
                <div class="container mt-2" v-show="material.contdm_tipo === 3 || material.contdm_tipo === 4">
                    <div class="large-12 medium-12 small-12 filezone">
                        <input type="file" id="files" ref="files" v-on:change="onAdicionarArquivo()" :accept="material.contdm_tipo === 3 ? 'application/pdf' : 'image/*'"/>
                        <p>
                            Solte seu arquivo aqui <br> ou clique para pesquisar
                        </p>
                    </div>

                    <div v-if="material.contdm_arquivo" class="file-listing">
                        <img class="preview" ref="preview1"/>
                        {{ material.contdm_arquivo.name }}
                        <div class="success-container" v-if="material.contdm_arquivo.id > 0">
                            Success
                        </div>
                        <div class="remove-container" v-else>
                            <a class="remove" v-on:click="onRemoverArquivo(key)">Remover</a>
                        </div>
                    </div>
                </div>
                <template v-slot:modal-footer>
                  <div class="btn-group" role="group" aria-label="Ações">
                    <button type="button" class="btn btn-success text-white" aria-label="Left Align" @click.prevent.stop="onSalvarMaterial()">
                      <span class="fa fa-check fa-lg text-white" aria-hidden="true"></span> Gravar </button>
                    <button type="button" class="btn btn-dark text-white" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('material-modal')">
                      <span class="fa fa-ban fa-lg text-white" aria-hidden="true"></span> Cancelar </button>
                  </div>
                </template>
               
            </b-modal>
            <div class="row row-striped p-0 py-1 m-0" v-for="(item,idx) in this.lista.material" :key="idx">
              <div class="col-1" v-if="Alterando">
                <div class="btn-group" role="group" aria-label="Ações">
                  <button type="button" class="btn btn-primary text-white fa fa-pencil btn-sm" aria-label="Left Align" title="Editar Material" @click="onEditarMaterial(idx)"></button>
                  <button type="button" class="btn btn-danger text-white fa fa-times btn-sm" aria-label="Left Align" title="Excluir Material" @click="onExcluirMaterial(idx)"></button>
                </div>
              </div>
              <div class="col-3">{{item.contdm_tipo === 1 ? 'MATERIAL EM VÍDEO' : 
                                   item.contdm_tipo === 2 ? 'MATERIAL EM ÁUDIO' :
                                   item.contdm_tipo === 3 ? 'MATERIAL EM PDF' : 
                                   item.contdm_tipo === 4 ? 'IMAGENS' :
                                   item.contdm_tipo === 5 ? 'QUESTIONÁRIO' : ''}}</div>
              <div class="col-3">{{item.contdm_descricao}}</div>
              <div class="col-2">{{item.contdm_data_encerramento | data}}</div>
              <div class="col-2">{{item.contdm_hora_encerramento}}</div>
              <div class="col-1">ARQUIVO</div>
            </div>
          </fieldset>        
        </fieldset>
        <div style="padding-top: 17px;">
          <button type="button" class="btn btn-danger text-white" aria-label="Left Align" @click.prevent.stop="$router.back()">
              <span class="fa fa-times text-white" aria-hidden="true"></span> Fechar </button>
        </div>
      </div> 
    </div>              
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'
import moment from 'moment'
import {isEmpty, minBy, orderBy, size, filter, some} from "lodash"

import MctPadrao from '@/components/comum/MctFormPadraoSCB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'EscFrequenciaProfessor',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {
          contd_codigo: null,
          contd_cur_codigo: null,
          contd_ser_codigo: null,
          contd_turm_codigo: null,
          contd_prof_logado: null,
          contd_prof_codigo: null,
          contd_prof_substituto: null,
          contd_disp_codigo: null,
          contd_etap_codigo: null,
          contd_data: null,
          contd_aula: null,
          contd_qtde: null,
          contd_conteudo: null,
          contd_descricao: null,
          contd_observacoes: null,
          material: [],
          afast_data_inicio: null,
          afast_data_retorno: null
        },
        errors:{},
        message: '',
        status: 0,
        turmas:{},
        professores:[],
        disciplinas:[],
        arraySql:[],
        datasDisciplina: [],
        Alterando: false,
        material: {},
        materialSelecionado: -1,        
        files: []
      }
  },
  methods: {
    disabledBeforeTodayAndAfterAWeek(date) {
      if (this.datasDisciplina){
        const data = this.datasDisciplina.filter((ele) => {
          return ele.horaridia_data === moment(new Date(date)).format("YYYY-MM-DD") && ((moment(new Date(date)).format("YYYY-MM-DD") >= moment(new Date(this.lista.afast_data_inicio)).format("YYYY-MM-DD") && moment(new Date(date)).format("YYYY-MM-DD") <= moment(new Date(this.lista.afast_data_retorno)).format("YYYY-MM-DD")) || (!this.lista.afast_data_inicio))
        });

        if (data && data[0]){
          return null;
        }
        return date;
      }
      return null;
    },
    async setProfessores(){      
      await axios.get('escprofessordisciplina/professoresturmas')
        .then(response => {
          this.arraySql = response.data
          })
          
      this.professores = this.arraySql.professores
      if (this.infoLogado.pes_codigo > 0){
        this.professores = this.professores.filter(item => item.value === this.infoLogado.pes_codigo);
        this.lista.contd_prof_logado = this.infoLogado.pes_codigo;
        this.lista.contd_prof_codigo = this.professores[0].substituido ?? this.infoLogado.pes_codigo;
        this.lista.contd_prof_substituto = this.professores[0].substituido ? this.infoLogado.pes_codigo : null;
        this.lista.afast_data_inicio = this.professores[0].substituido ? this.professores[0].afast_data_inicio : null;
        this.lista.afast_data_retorno = this.professores[0].substituido ? this.professores[0].afast_data_retorno : null;
        this.setTurma();
      }
    },
    setTurma(){
      this.turmas = _.filter(this.arraySql.turmas,{'pes_codigo':this.lista.contd_prof_codigo})
      this.turmas = _.uniqWith(this.turmas, _.isEqual);
    },
    async setDisciplinas(){
      this.disciplinas = []
      this.disciplinas = _.filter(this.arraySql.disciplinas,{'turm_codigo':this.lista.contd_turm_codigo,'professor':this.lista.contd_prof_codigo})
      this.disciplinas = _.uniqWith(this.disciplinas, _.isEqual);
    },    
    async getAulas () {
      if (typeof this.lista.contd_data !== 'undefined' && this.lista.contd_data !== null){
        await axios.get('eschorarios/aulasdiadisp?turm_codigo='+this.lista.contd_turm_codigo+'&disp_codigo='+this.lista.contd_disp_codigo+'&horari_codigo='+this.lista.contd_horari_codigo+'&dia='+moment(this.lista.contd_data).format("e")+'&data='+moment(this.lista.contd_data).format("YYYY-MM-DD"))
          .then(response => {
            this.lista.contd_qtde = response.data.data
          })
          .catch(response => {
            this.message = response.response.data.message
            this.errors = response.response.data.errors
            this.status = response.response.status
          })
      }        
    },    
    async getDatas () {
      let URL = '';
      if (this.infoEscola.esc_tipo === 1){
        if (typeof this.lista.contd_etap_codigo !== 'undefined' && this.lista.contd_etap_codigo !== null)
          URL = '&etap_codigo='+this.lista.contd_etap_codigo
      }else{
        if (typeof this.lista.contd_horari_codigo !== 'undefined' && this.lista.contd_horari_codigo !== null)
          URL = '&horari_codigo='+this.lista.contd_horari_codigo
      }
      if (URL !== ''){
        await axios.get('eschorarios/diasdisp?turm_codigo='+this.lista.contd_turm_codigo+'&disp_codigo='+this.lista.contd_disp_codigo+URL)
          .then(response => {
            this.datasDisciplina = response.data.data
          })
          .catch(response => {
            this.message = response.response.data.message
            this.errors = response.response.data.errors
            this.status = response.response.status
          })
      }        
    },       
    async alterar(){
      if(this.validaInputs()){
        if(this.lista.contd_aula <= 0 || this.lista.contd_aula > this.lista.contd_qtde )
          {
            this.$swal({
              title:'O campo "CONTEÚDO DA AULA Nº" não pode tem o valor maior/menor que a "QUANTIDADE DE AULAS" ou igual a zero.',
              text: 'Preencha o campo com um valor válido e tente novamente.',
              icon: 'warning'});
            return
          }
          let URL = '';
          if (this.infoEscola.esc_tipo === 1) {
            URL = 'contd_turm_codigo='+this.lista.contd_turm_codigo+'&contd_prof_codigo='+this.lista.contd_prof_codigo+'&contd_disp_codigo='+
            this.lista.contd_disp_codigo+'&contd_etap_codigo='+this.lista.contd_etap_codigo+'&contd_data='+moment(this.lista.contd_data).format("YYYY-MM-DD")+'&contd_aula='+this.lista.contd_aula;
          }else{
            URL = 'contd_turm_codigo='+this.lista.contd_turm_codigo+'&contd_prof_codigo='+this.lista.contd_prof_codigo+'&contd_disp_codigo='+
            this.lista.contd_disp_codigo+'&contd_data='+moment(this.lista.contd_data).format("YYYY-MM-DD")+'&contd_aula='+this.lista.contd_aula;
          }
          
          await axios.get(`escconteudo/editar?${URL}`)
            .then(response => {
              this.Alterando = true
              if (response.data.data != null){
                this.lista.contd_conteudo     = response.data.data.contd_conteudo
                this.lista.contd_descricao    = response.data.data.contd_descricao
                this.lista.contd_observacoes  = response.data.data.contd_observacoes
                this.lista.contd_codigo       = response.data.data.contd_codigo
                this.lista.material           = response.data.data.material
              }
            })
            .catch(response => {
              this.message = response.response.data.message
              this.errors = response.response.data.errors
              this.status = response.response.status
            })
      }          
    },
    validaInputs () {
      this.errors = new Object()
      if (this.lista.contd_turm_codigo == null || this.lista.contd_turm_codigo == ''){
        this.errors.contd_turm_codigo = []
        this.errors.contd_turm_codigo.push("O campo TURMA não pode ser nulo ou vazio.") 
      }
      if (this.lista.contd_prof_codigo == null || this.lista.contd_prof_codigo == ''){
        this.errors.contd_prof_codigo = []
        this.errors.contd_prof_codigo.push("O campo PROFESSOR não pode ser nulo ou vazio.") 
      }
      if (this.lista.contd_disp_codigo == null || this.lista.contd_disp_codigo == ''){
        this.errors.contd_disp_codigo = []
        this.errors.contd_disp_codigo.push("O campo DISCIPLINA não pode ser nulo ou vazio.") 
      }
      if ((this.lista.contd_etap_codigo == null || this.lista.contd_etap_codigo == '') && (this.infoEscola.esc_tipo === 1)){
        this.errors.contd_etap_codigo = []
        this.errors.contd_etap_codigo.push("O campo ETAPA não pode ser nulo ou vazio.") 
      }
      if (this.lista.contd_data == null || this.lista.contd_data == ''){
        this.errors.contd_data = []
        this.errors.contd_data.push("O campo DATA não pode ser nulo ou vazio.") 
      }
      if (Object.keys(this.errors).length > 0) {
        this.message = "Houve um ou mais erros."
        this.status = 422
        return false
      }else{
        this.message = ""
        this.status = 0
        return true
      }        
    },
    confirma(){
      if(this.validaInputs()){
        this.$swal({
          title: 'Deseja realmente gravar os dados?',
          text: "Lembre-se que todos os dados anteriores serão apagados!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
          }).then((result) => {
            if (result.value) {
              this.gravar()
            }                     
        })
      }
    },
    async gravar(){            
      //var dados = this.lista
      //await axios.post('escconteudo/salvar',dados)
      let formData = new FormData();
      for( let i = 0; i < this.files.length; i++ ){ 
        if(this.files[i] && this.files[i].id) {
            continue;
        }
        formData.append('file'+i, this.files[i]);      
      }
      formData.append('data', JSON.stringify(this.lista));
      await axios.post('escconteudo/salvarformdata',
          formData,
          {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }
        )      
        .then(response => {
            this.message = response.data.message
            this.status = response.status
            this.errors = {}
            
            this.$swal('Sucesso!',
              'Registro incluído com sucesso!',
              'success');  
            this.Alterando = false
            this.lista.contd_codigo = null
            this.lista.contd_data = null
            this.lista.contd_qtde = null 
            this.lista.contd_conteudo = null
            this.lista.contd_descricao = null
            this.lista.contd_observacoes = null
            this.lista.material = []
            this.files = []
        })
        .catch(response => {
            this.message = response.response.data.message
            if (response.response.data.error){
                this.errors = response.response.data.error.errorInfo[2]              
            }else{
                this.errors = response.response.data.errors
            }
            this.status = response.response.status
            
        })     
    },    
    cancelar(){
      this.$swal({
        title: 'Deseja realmente cancelar a alteração?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
              this.Alterando = false
              this.lista.contd_codigo = null
              this.lista.contd_data = null
              this.lista.contd_qtde = null 
              this.lista.contd_conteudo = null
              this.lista.contd_descricao = null
              this.lista.contd_observacoes = null
              this.lista.material = []
              this.files = []              
            }                     
        })
    },
    /* MATERIAL */
    onIncluirMaterial (e) {
        this.materialSelecionado = -1
        this.material = {
            contdm_codigo: null,
            contdm_tipo: 1,
            contdm_valor: null,
            contdm_valor_tipo: null,
            contdm_data_encerramento: null,
            contdm_hora_encerramento: null,
            contdm_descricao: null,
            contdm_url: null,
            contdm_arquivo: null
        }
        this.$bvModal.show('material-modal')
    },
    onSalvarMaterial () {
      let msgs = ''
      if (this.material.contdm_tipo == null || this.material.contdm_tipo == ''){
          msgs += 'Selecione o Tipo do Material de Apoio; </br>';
      }
      if (this.material.contdm_descricao == null || this.material.contdm_descricao == ''){
          msgs += 'Digite uma Descrição para o Material de Apoio; </br>';
      }
      if ((this.material.contdm_valor > 0) && (this.material.contdm_valor_tipo == null || this.material.contdm_valor_tipo == 0)){
          msgs += 'Ao digitar um Valor é obrigatória a seleção de um Tipo de Valor; </br>';
      }
      if ((this.material.contdm_tipo === 1 || this.material.contdm_tipo === 2) && (this.material.contdm_url == null || this.material.contdm_url == '')){
          msgs += 'É obrigatória a inclusão de um Link para os Tipos de Material de Vídeo/Áudio; </br>';
      }
      if ((this.material.contdm_tipo === 3 || this.material.contdm_tipo === 4) && (this.material.contdm_arquivo == null || this.material.contdm_arquivo == '')){
          msgs += 'É obrigatória a inclusão de um arquivo para os Tipos de Material PDF/Imagens; </br>';
      }
      if (msgs !== ''){
          this.$swal({
              icon: 'error',
              confirmButtonText: 'Ok',
              title: 'Erro(s) ao Incluir o Material de Apoio',
              html: msgs,
              footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
          })        
      }else{
        if (this.materialSelecionado >= 0){
          this.lista.material.splice(this.materialSelecionado,1,this.material);          
        } else {
          this.lista.material.push(this.material);
        }
        if ((this.material.contdm_tipo === 3 || this.material.contdm_tipo === 4)){
          let index = this.lista.material.indexOf(this.material);
          this.files[index] = this.lista.material[index].contdm_arquivo;
          this.lista.material[index].contdm_arquivo = this.files[index].name;
        }
        this.materialSelecionado = -1;
        this.$bvModal.hide('material-modal');
      }
    },
    onEditarMaterial (index) {
        this.materialSelecionado = index
        this.material = {...this.lista.material[index]}
        this.$bvModal.show('material-modal')
    },
    onExcluirMaterial (index) {
        this.$swal({
            title: 'Deseja realmente remover o item da lista?',
            text: "Este evento não podera ser revertido!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.lista.material.splice(index,1)
                this.files.splice(index,1)
            }
        }) 
    },        
    /* UPLOAD */        
    onAdicionarArquivo() {
        let uploadedFiles = this.$refs.files.files;
        if (!uploadedFiles.length)
            return        

        for(var i = 0; i < uploadedFiles.length; i++) {
            this.material.contdm_arquivo = uploadedFiles[i];
        }
        this.getImagePreviews();
    },
    getImagePreviews(){
        if( this.material.contdm_arquivo ){
            if ( /\.(jpe?g|png|gif)$/i.test( this.material.contdm_arquivo.name ) ) {
                let reader = new FileReader();
                reader.addEventListener("load", function(){
                    this.$refs['preview1'].src = reader.result;
                    this.material.contdm_arquivo_arq = reader.result                    
                }.bind(this), false);
                reader.readAsDataURL( this.material.contdm_arquivo );
            }else{
                this.$nextTick(function(){
                    this.$refs['preview1'].src = '/img/generic.png';
                });
            }
        }
    },
    onRemoverArquivo( key ){
        this.material.contdm_arquivo.splice( key, 1 );
        this.getImagePreviews();
    },
  },
  computed: {
    ...mapState('Login',['infoLogado', 'infoEscola'])
  },  
  created() {
    this.setProfessores()  
  },
  watch: {
    'lista.contd_data' (){
        this.getAulas()
    },
    'lista.contd_qtde'(){
      if(this.lista.contd_qtde > 0)
      {
        this.lista.contd_aula = 1
      }else{
        this.lista.contd_aula = 0
      }
    },    
    'lista.contd_prof_codigo' (){
      this.turmas = [] 
      this.disciplinas = [] 
      this.lista.contd_turm_codigo = ''
      this.lista.contd_disp_codigo = ''
      this.setTurma()
    },
    'lista.contd_turm_codigo' (){
      if(this.lista.contd_turm_codigo!=null){
        this.setDisciplinas()
      }
    },
    'lista.contd_horari_codigo' (){
        if (this.infoEscola.esc_tipo !== 1){                  
          this.getDatas();
          this.lista.contd_qtde = 0;
          this.lista.contd_data = null;
        }
    },
    'lista.contd_etap_codigo' (){
        if (this.infoEscola.esc_tipo === 1){                  
          this.getDatas();
          this.lista.contd_qtde = 0;
          this.lista.contd_data = null;
        }
    },
    'lista.contd_disp_codigo' (){
        if (this.infoEscola.esc_tipo === 1){                  
          this.getDatas();
          this.lista.contd_qtde = 0;
          this.lista.contd_data = null;
        }
    },
  }
}
</script>

<style scoped>
  input[type="file"]{
      opacity: 0;
      width: 100%;
      height: 150px;
      position: absolute;
      cursor: pointer;
  }
  .filezone {
      outline: 2px dashed grey;
      outline-offset: -10px;
      background: #ccc;
      color: dimgray;
      padding: 10px 10px;
      min-height: 150px;
      position: relative;
      cursor: pointer;
  }
  .filezone:hover {
      background: #c0c0c0;
  }

  .filezone p {
      font-size: 1.2em;
      text-align: center;
      padding: 50px 50px 50px 50px;
  }

  div.file-listing img{
      max-width: 90%;
  }

  div.file-listing{
      margin: auto;
      padding: 10px;
      border-bottom: 1px solid #ddd;
  }

  div.file-listing img{
      height: 50px;
  }

  .row-striped:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

</style>