<template>
    <div class="funil">
        <header>
            <h1>Lista de Funis</h1>                
        </header>
        <div class="funil-itens">
            <a v-for="(funil, index) in funis" :key="index" href="#" @dblclick="$router.push(`/crm/funil/${funil.fnl_codigo}`)">
                <div>
                    <h1><mct-label-edit :id="funil.fnl_codigo" :value.sync="funil.fnl_descricao" @onSave="onAlterarFunil"/></h1>

                    <div><strong>{{funil.grupos.length}}</strong><span class="text-primary"> Grupo(s)</span></div>
                    <div><strong>{{funil && funil.grupos ? funil.grupos.reduce((soma,item) => soma + Number(item.oportunidades.length), 0) : 0}}</strong><span class="text-info"> Oportunidade(s)</span></div>
                    <div v-show="grupoGanhou(funil).length > 0"><strong>{{grupoGanhou(funil)[0] && grupoGanhou(funil)[0].oportunidades ? grupoGanhou(funil)[0].oportunidades.length : 0}}</strong><span class="text-success"> Ganhos(s)</span></div>
                    <div v-show="grupoPerdeu(funil).length > 0"><strong>{{grupoPerdeu(funil)[0] && grupoPerdeu(funil)[0].oportunidades ? grupoPerdeu(funil)[0].oportunidades.length : 0}}</strong><span class="text-danger"> Perdida(s)</span></div>
                    <span class=""><i class="fa fa-trash-o" @click.prevent.stop="onExcluirFunil(funil.fnl_codigo)" title="Excluir Funil." style="cursor: pointer;"></i></span>
                </div>
            </a>
            <a class="funil-novo" @click.prevent.stop="onNovoFunil()">
                <span class="fa fa-plus fa-lg ml-1 mr-1" aria-hidden="true"></span>  Novo Funil
            </a>
        </div>

        <b-modal id="novo-funil" title="Adicionar Funil" size="md" footer-class="">
            <div class="p-2">
                <div class="row mb-2">
                    <div class="col-lg-12 mr-2">
                        <mct-form-itens tipo="text" campo="fnl_descricao" :valor="data" titulo="Descrição" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-12 mr-2">
                        <mct-form-itens tipo="radio" campo="fnl_tipo" :valor="data" titulo="Tipo" 
                        :origemData="[{value: 1, text: 'Vendas'}, {value: 2, text: 'Cobrança'},{value: 3, text: 'Pós Venda'},{value: 4, text: 'Marketing'},{value: 0, text: 'Outros'}]"
                        obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="slot" titulo="Tags" :errors="{}" modo="novo" >
                            <multiselect class="form-control" v-model="oprtTags" :options="tags" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" 
                                placeholder="Selecione as Tags" selectedLabel="Selecionar opção" deselectLabel="Pressione Enter para remover" selectLabel="Pressione enter para selecionar"
                                label="tags_tag" track-by="tags_tag">
                            </multiselect>
                        </mct-form-itens>
                    </div>
                </div>
            </div>                
            <div slot="modal-footer">
                <button type="button" class="btn btn-success mr-1 p-2" aria-label="Left Align"  @click.prevent.stop="onIncluirFunil()">
                        <span class="fa fa-check fa-lg" aria-hidden="true"></span> Adicionar </button>
                <button type="button" class="btn btn-danger mr-1 p-2" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('novo-funil')">
                        <span class="fa fa-times fa-lg" aria-hidden="true"></span> Cancelar </button>
            </div>
        </b-modal>        
    </div>
  
</template>

<script>
import Vue from 'vue';
import axios from '@/http';
import { mapState } from 'vuex'

import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctLabelEdit from '@/components/comum/MctLabelEdit.vue'
import Multiselect from 'vue-multiselect'

export default {
    name: 'CrmFunil',
    components: {
        MctFormItens,
        MctLabelEdit,
        Multiselect
    },    
    data () {
        return {
            funis: [],
            tags: [],
            data: {
                fnl_codigo: null,
                fnl_ent: null,
                fnl_esc: null,
                fnl_ano: null,
                fnl_descricao: null,
                fnl_tipo: null,
                fnl_tags: null,
                fnl_situacao: null,
            }
        }
    },
    methods: {
        async getFunis() {
            await axios.get('/crmfunil/combo')
            .then((result) => {
                this.funis = result.data.data;                
            })
            .catch((result) => {

            })
        },
        grupoGanhou (funil) {
            return funil && funil.grupos ? funil.grupos.filter(ele => ele.fnlgrp_tipo === 2) : [];
            //return grupo && grupo[0] ? grupo[0] : [];
        },
        grupoPerdeu (funil) {
            return funil && funil.grupos ? funil.grupos.filter(ele => ele.fnlgrp_tipo === 3) : [];
            //return grupo && grupo[0] ? grupo[0] : [];
        },
        async setTags () {
            await axios.get('crmtags/combo')
                .then(response => {
                    this.tags = response.data.data 
                })
                .catch(response => {
                    //console.log(response)
                })
        },
        onLimpaFunil (){
            this.data = {
                fnl_codigo: null,
                fnl_ent: null,
                fnl_esc: null,
                fnl_ano: null,
                fnl_descricao: null,
                fnl_tipo: null,
                fnl_tags: null,
                fnl_situacao: null,
            }
        },
        onNovoFunil (){
            this.onLimpaFunil();
            this.data.fnl_ent = this.Entidade;
            this.data.fnl_esc = this.Escola;
            this.data.fnl_ano = this.Exercicio;
            this.data.fnl_tipo = 0;
            this.data.fnl_situacao = 1;
            this.$bvModal.show('novo-funil');
        },
        async onIncluirFunil() {
            let msgs = ''
            if (this.data.fnl_tipo == null /*|| this.data.fnl_tipo == 0*/){
                msgs += 'O tipo do funil deve ser selecionado; </br>';
            }
            if (this.data.fnl_descricao == null || this.data.fnl_descricao == ''){
                msgs += 'A descrição do funil deve ser preenchida; </br>';
            }
            
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Incluir o Funil',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            await axios.post('crmfunil/novo',this.data)
                .then(response => {                                        
                    this.$swal('Sucesso!',
                        'Funil incluído com sucesso!',
                        'success');
                    this.$bvModal.hide('novo-funil');
                    this.onLimpaFunil;
                    this.getFunis();
                })
                .catch(response => {
                    this.message = response.response.data.message;
                    this.errors = response.response.data.message;
                    this.status = response.response.status
                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) ao incluir o Funil.',
                        text: this.message,
                        footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                    })
                })
            }
        },
        async onAlterarFunil (data) {
            const {id, value} = data;
            const funil = this.funis.filter(el => el.fnl_codigo === id)[0];
            let msgs = ''
            if (id == null /*|| this.data.fnl_tipo == 0*/){
                msgs += 'O funil deve ser selecionado; </br>';
            }
            if (value == null || value == ''){
                msgs += 'A descrição do funil deve ser preenchida; </br>';
            }
            
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Alterar o Funil',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            await axios.put(`crmfunil/editar/${id}`,funil)
                .then(response => {
                    this.$awn.success(`Funil alterado com sucesso!`);
                })
                .catch(response => {
                    this.message = response.response.data.message;
                    this.errors = response.response.data.message;
                    this.status = response.response.status
                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) ao alterar o Funil.',
                        text: this.message,
                        footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                    })
                })
            }
        },
        async onExcluirFunil (codigo) {
            this.$swal({
                title: 'Deseja realmente excluir o funil, todos seus grupos e todas as suas oportunidades?',
                text: "Este evento não podera ser revertido!",
                focusConfirm: false,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim',
            }).then((result) => {
                if (result.value) {                    
                    axios.delete(`/crmfunil/deletar/${codigo}`)
                        .then(response => {
                            this.message = response.data.message
                            this.status = response.status
                            this.errors = {}
                            this.getFunis()
                            
                            this.$swal('Sucesso!',
                            'Funil excluído com sucesso!',
                            'success');
                        })
                        .catch(response => {
                            this.message = response.response.data.message;
                            this.errors = response.response.data.message;
                            this.status = response.response.status
                            this.$swal({
                                icon: 'error',
                                title: 'Erro(s) ao Excluir o Funil',
                                text: this.message,
                                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                            })                              
                        })                
                }                     
            }) 
        }
    },
    computed: {
        ...mapState('Login',['infoLogado','Entidade','Exercicio','Escola']),
        oprtTags: {
            get: function () {
                if (typeof this.data.fnl_tags !== 'undefined' && this.data.fnl_tags !== null && this.data.fnl_tags !== ''){
                    let req = this.data.fnl_tags.split(',')
                    let resultado = [];
                    req.forEach(el =>{
                        resultado.push(...this.tags.filter(el2 => el2.tags_tag === el))
                    })
                    return resultado
                }
            },
            set: function (newValue) {
                const res = newValue.map(item => item.tags_tag)
                this.data.fnl_tags = res.toString()
            }
        }        
    },
    created ()  {
        this.setTags();
        this.getFunis();
    },
}
</script>

<style scoped>
    .funil {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }

    .funil > header > h1 {
        font-size: 1.6rem;
        color: var(--dark);
        font-weight: 600;
        padding: 10px;
    }

    .funil-itens {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .funil-itens a:hover{
        text-decoration: none;
        
    }


    .funil-itens > a > div{
        background-color: #FFFFFF;
        width: 270px;
        height: 150px;
        margin: 10px;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: hidden;
        position: relative;
    }

    .funil-itens > a > div:hover{
        -webkit-box-shadow: 2px 2px 5px 0px rgba(194,192,194,1);
        -moz-box-shadow: 2px 2px 5px 0px rgba(194,192,194,1);
        box-shadow: 2px 2px 5px 0px rgba(194,192,194,1);
    }

    .funil-itens > a > div > h1 {
        font-size: 1.2rem;
        font-weight: 500;
        color: var(--dark);
        width: 100%;
        text-align: center;
    }

    .funil-itens > a > div > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin: 5px 0;
        padding: 0;
    }

    .funil-itens > a > div > span {
        position: absolute;
        bottom: 5px;
        right: 10px;
        color: var(--red);
    }

    .funil-itens > a > div > span:hover {
        color: var(--dark);
    }

    .funil-itens > a > div > div > strong {
        font-size: 1rem;
        font-weight: 600;
        color: var(--dark);
        min-width: 20px;
        text-align: right;
        margin-right: 5px;
        line-height: 15px;
    }

    .funil-itens > a > div > div > span {
        font-size: 0.8rem;
        font-weight: 400;
        color: var(--dark);
        line-height: 15px;
    }


    a.funil-novo {
        font-size: 1rem;
        width: 270px;
        height: 150px;
        margin: 10px;
        padding: 0;
        border-radius: 4px;
        border: 1px dashed var(--dark);
        overflow: hidden;
        color: var(--dark);
        background: var(--light);
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    a.funil-novo:hover {
        opacity: 0.8;
        background: var(--white);
    }

</style>