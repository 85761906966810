<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" botoes="false">
    <div slot="formulario">
      <div class="view" style="padding-top: 5px; padding-bottom: 5px;">      
        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Professor</legend> 
          <div class="row">
            <div class="col-12">
              <mct-form-itens tipo="select" :campo="infoLogado.pes_codigo > 0 ? 'alufreq_prof_logado' : 'alufreq_prof_codigo'" :valor="lista" titulo="Professor" obrigatorio="true" :errors="errors" :origemData="professores" origemRetornoSec="value" :modo="modo"></mct-form-itens>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mct-form-itens tipo="radio" campo="tp_lancamento" :valor="lista" titulo="Tipo Lançamento" obrigatorio="true" 
                :origemData="[{value:1,text:'Turma/Disciplina/Etapa'},{value:2,text:'Turma/Etapa'},{value:3,text:'Etapa'}]" :errors="errors" :modo="modo"></mct-form-itens>
            </div>
          </div>
          <div class="row">
            <div class="col-4" v-if="infoEscola.esc_tipo !== 1">
              <mct-form-itens tipo="select" campo="alufreq_ser_codigo" :valor="lista" titulo="Módulo" :errors="errors" obrigatorio="true" 
                :origemFiltro="{cur_codigo: turmaSelecionada.cur_codigo}" origemChave="ser_codigo" origemRetorno='ser_descricao' origemRetornoSec="ser_codigo" origem="esc_serie" :modo="modo"></mct-form-itens>
            </div>
          </div>
          <div class="row">
            <div class="col-4" v-show="lista.tp_lancamento === 1 || lista.tp_lancamento === 2">
              <mct-form-itens tipo="select" campo="alufreq_turm_codigo" :valor="lista" titulo="Turma" obrigatorio="true" :errors="errors" :origemData="turmas" :modo="modo"></mct-form-itens>
            </div>
            <div class="col-4" v-show="lista.tp_lancamento === 1">
              <mct-form-itens tipo="select" campo="alufreq_disp_codigo" :valor="lista" titulo="Disciplina" :errors="errors" obrigatorio="true" :origemData="disciplinas" :modo="modo"></mct-form-itens>
            </div>
            <div class="col-4" v-if="infoEscola.esc_tipo === 1" v-show="lista.tp_lancamento === 1 || lista.tp_lancamento === 2 || lista.tp_lancamento === 3">
              <mct-form-itens tipo="select" campo="alufreq_etap_codigo" :valor="lista" titulo="Etapa" :errors="errors" obrigatorio="true" origem="esc_etapas" origemChave="etap_codigo" origemRetorno='etap_descricao'  :modo="modo"></mct-form-itens>
            </div>
          </div>
          <div class="row" v-if="infoEscola.esc_tipo !== 1">
            <div class="col-12">
              <mct-form-itens tipo="select" campo="alufreq_horari_codigo" :valor="lista" titulo="Agendamento" :errors="errors" obrigatorio="true" 
                :origemFiltro="{cur_codigo: turmaSelecionada.cur_codigo, ser_codigo: lista.alufreq_ser_codigo, turm_codigo: lista.alufreq_turm_codigo}" origemChave="horari_codigo" origemRetorno='horari_descricao' origemRetornoSec="horari_codigo" origem="esc_horarios" :modo="modo"></mct-form-itens>
            </div>
          </div>
        </fieldset>

        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Intervalo de Datas</legend> 
          <div class="row">
            <div class="col-4">
              <mct-form-itens tipo="datepicker" campo="alufreq_data" :valor="lista" titulo="Data Inicial" :errors="errors" obrigatorio="true" :modo="modo" :legenda="moment(lista.alufreq_data).locale('pt-br').format('dddd')"
               :disabled-date="disabledBeforeTodayAndAfterAWeek"
              ></mct-form-itens>
            </div>
            <div class="col-4">
              <mct-form-itens tipo="datepicker" campo="alufreq_data2" :valor="lista" titulo="Data Final" :errors="errors" obrigatorio="true" :modo="modo" :legenda="moment(lista.alufreq_data2).locale('pt-br').format('dddd')"
               :disabled-date="disabledBeforeTodayAndAfterAWeek"
              ></mct-form-itens>
            </div>
          </div>

        </fieldset>
        <div class="btn-group" role="group" aria-label="Ações">
          <button type="button" class="btn btn-success text-white" aria-label="Left Align" @click.prevent.stop="confirma()">
            <span class="fa fa-check text-white" aria-hidden="true"></span> Executar Processo </button>
          <button type="button" class="btn btn-danger text-white" aria-label="Left Align" @click.prevent.stop="$router.back()">
            <span class="fa fa-times text-white" aria-hidden="true"></span> Fechar </button>
        </div>
      </div> 
    </div>              
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'
import moment from 'moment'
import {isEmpty, minBy, orderBy, size, filter, some} from "lodash"

import MctPadrao from '@/components/comum/MctFormPadraoSCB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'EscFrequenciaProfessorData',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {
          tp_lancamento: 1,
          alufreq_cur_codigo: null,
          alufreq_ser_codigo: null,
          alufreq_turm_codigo: null,
          alufreq_prof_logado: null,
          alufreq_prof_codigo: null,
          alufreq_prof_substituto: null,
          alufreq_disp_codigo: null,
          alufreq_etap_codigo: null,
          alufreq_horari_codigo: null,
          alufreq_data: null,
          alufreq_data2: null,
          afast_data_inicio: null,
          afast_data_retorno: null
        },
        errors:{},
        message: '',
        status: 0,
        turmas:{},
        professores:[],
        disciplinas:[],
        arraySql:[],
        datasDisciplina: [],
      }
  },
  methods: {
    disabledBeforeTodayAndAfterAWeek(date) {
      if (this.datasDisciplina){
        const data = this.datasDisciplina.filter((ele) => {
          return ele.horaridia_data === moment(new Date(date)).format("YYYY-MM-DD") && ((moment(new Date(date)).format("YYYY-MM-DD") >= moment(new Date(this.lista.afast_data_inicio)).format("YYYY-MM-DD") && moment(new Date(date)).format("YYYY-MM-DD") <= moment(new Date(this.lista.afast_data_retorno)).format("YYYY-MM-DD")) || (!this.lista.afast_data_inicio))
        });

        if (data && data[0]){
          return null;
        }
        return date;
      }
      return null;
    },
    async setProfessores(){      
      await axios.get('escprofessordisciplina/professoresturmas')
        .then(response => {
          this.arraySql = response.data
          })
          
      this.professores = this.arraySql.professores
      if (this.infoLogado.pes_codigo > 0){
        this.professores = this.professores.filter(item => item.value === this.infoLogado.pes_codigo);
        this.lista.alufreq_prof_logado = this.infoLogado.pes_codigo;
        this.lista.alufreq_prof_codigo = this.professores[0].substituido ?? this.infoLogado.pes_codigo;
        this.lista.alufreq_prof_substituto = this.professores[0].substituido ? this.infoLogado.pes_codigo : null;
        this.lista.afast_data_inicio = this.professores[0].substituido ? this.professores[0].afast_data_inicio : null;
        this.lista.afast_data_retorno = this.professores[0].substituido ? this.professores[0].afast_data_retorno : null;
        this.setTurma();
      }
    },
    setTurma(){
      this.turmas = _.filter(this.arraySql.turmas,{'pes_codigo':this.lista.alufreq_prof_codigo})
      this.turmas = _.uniqWith(this.turmas, _.isEqual);
    },
    async setDisciplinas(){
      this.disciplinas = []
      this.disciplinas = _.filter(this.arraySql.disciplinas,{'turm_codigo':this.lista.alufreq_turm_codigo,'professor':this.lista.alufreq_prof_codigo})
      this.disciplinas = _.uniqWith(this.disciplinas, _.isEqual);
    },    
    async getAulas () {
      if (typeof this.lista.alufreq_data !== 'undefined' && this.lista.alufreq_data !== null){
        await axios.get('eschorarios/aulasdiadisp?turm_codigo='+this.lista.alufreq_turm_codigo+'&disp_codigo='+this.lista.alufreq_disp_codigo+'&horari_codigo='+this.lista.alufreq_horari_codigo+'&dia='+moment(this.lista.alufreq_data).format("e")+'&data='+moment(this.lista.alufreq_data).format("YYYY-MM-DD"))
          .then(response => {
            this.lista.alufreq_qtde = response.data.data
          })
          .catch(response => {
            this.message = response.response.data.message
            this.errors = response.response.data.errors
            this.status = response.response.status
          })
      }        
    },    
    async getDatas () {
      let URL = '';
      if (this.infoEscola.esc_tipo === 1){
        if (typeof this.lista.alufreq_etap_codigo !== 'undefined' && this.lista.alufreq_etap_codigo !== null)
          URL = '&etap_codigo='+this.lista.alufreq_etap_codigo
      }else{
        if (typeof this.lista.alufreq_horari_codigo !== 'undefined' && this.lista.alufreq_horari_codigo !== null)
          URL = '&horari_codigo='+this.lista.alufreq_horari_codigo
      }
      if (URL !== ''){
        await axios.get('eschorarios/diasdisp?turm_codigo='+this.lista.alufreq_turm_codigo+'&disp_codigo='+this.lista.alufreq_disp_codigo+URL)
          .then(response => {
            this.datasDisciplina = response.data.data
          })
          .catch(response => {
            this.message = response.response.data.message
            this.errors = response.response.data.errors
            this.status = response.response.status
          })
      }        
    },       
    validaInputs () {
      this.errors = new Object()
      if ((this.lista.alufreq_turm_codigo == null || this.lista.alufreq_turm_codigo == '') && (this.lista.tp_lancamento == 1 || this.lista.tp_lancamento == 2)){
        this.errors.alufreq_turm_codigo = []
        this.errors.alufreq_turm_codigo.push("O campo TURMA não pode ser nulo ou vazio.") 
      }
      if (this.lista.alufreq_prof_codigo == null || this.lista.alufreq_prof_codigo == ''){
        this.errors.alufreq_prof_codigo = []
        this.errors.alufreq_prof_codigo.push("O campo PROFESSOR não pode ser nulo ou vazio.") 
      }
      if ((this.lista.alufreq_disp_codigo == null || this.lista.alufreq_disp_codigo == '') && (this.lista.tp_lancamento == 1)){
        this.errors.alufreq_disp_codigo = []
        this.errors.alufreq_disp_codigo.push("O campo DISCIPLINA não pode ser nulo ou vazio.") 
      }
      if ((this.lista.alufreq_etap_codigo == null || this.lista.alufreq_etap_codigo == '') && (this.infoEscola.esc_tipo === 1)){
        this.errors.alufreq_etap_codigo = []
        this.errors.alufreq_etap_codigo.push("O campo ETAPA não pode ser nulo ou vazio.") 
      }
      if (this.lista.alufreq_data == null || this.lista.alufreq_data == ''){
        this.errors.alufreq_data = []
        this.errors.alufreq_data.push("O campo DATA INICIAL não pode ser nulo ou vazio.") 
      }
      if (this.lista.alufreq_data2 == null || this.lista.alufreq_data2 == ''){
        this.errors.alufreq_data2 = []
        this.errors.alufreq_data2.push("O campo DATA FINAL não pode ser nulo ou vazio.") 
      }
      if (Object.keys(this.errors).length > 0) {
        this.message = "Houve um ou mais erros."
        this.status = 422
        return false
      }else{
        this.message = ""
        this.status = 0
        return true
      }        
    },
    confirma(){
      if(this.validaInputs()){
        this.$swal({
          title: 'Deseja realmente gravar os dados?',
          text: "Lembre-se que todos os dados anteriores serão apagados!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
          }).then((result) => {
            if (result.value) {
              this.gravar()
            }                     
        })
      }
    },
    async gravar(){      
      var dados = this.lista
      await axios.post('escalunosfrequencia/salvardata',dados)
        .then(response => {
            this.message = response.data.message
            this.status = response.status
            this.errors = {}
            
            this.$swal('Sucesso!',
              'Registro incluído com sucesso!',
              'success');  
            this.lista.alufreq_data = null
            this.lista.alufreq_data2 = null 
        })
        .catch(response => {
            this.message = response.response.data.message
            if (response.response.data.error){
                this.errors = response.response.data.error.errorInfo[2]              
            }else{
                this.errors = response.response.data.errors
            }
            this.status = response.response.status            
        })  
    },
  },
  computed: {
    ...mapState('Login',['infoLogado', 'infoEscola'])
  },  
  created() {
    this.setProfessores()  
  },
  watch: {
    'lista.tp_lancamento' () {
      if (this.lista.tp_lancamento === 2) {
        this.lista.alufreq_disp_codigo = null;
      } else if (this.lista.tp_lancamento === 3) {
        this.lista.alufreq_disp_codigo = null;
        this.lista.alufreq_prof_codigo = null;
      }
    },
    'lista.alufreq_prof_codigo' (){
      this.turmas = [] 
      this.disciplinas = [] 
      this.lista.alufreq_turm_codigo = ''
      this.lista.alufreq_disp_codigo = ''
      this.setTurma()
    },
    'lista.alufreq_turm_codigo' (){
      if(this.lista.alufreq_turm_codigo!=null){
        this.setDisciplinas()
      }
    },
    'lista.alufreq_horari_codigo' (){
        if (this.infoEscola.esc_tipo !== 1){                  
          this.getDatas();
          this.lista.alufreq_data = null;
          this.lista.alufreq_data2 = null;
        }
    },
    'lista.alufreq_etap_codigo' (){
        if (this.infoEscola.esc_tipo === 1){                  
          this.getDatas();
          this.lista.alufreq_data = null;
          this.lista.alufreq_data2 = null;
        }
    },
    'lista.alufreq_disp_codigo' (){
        if (this.infoEscola.esc_tipo === 1){                  
          this.getDatas();
          this.lista.alufreq_data = null;
          this.lista.alufreq_data2 = null;
        }
    },
  }
}
</script>