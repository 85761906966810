<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <div class="row">
                <div class="col-2">
                    <mct-form-itens tipo="number" campo="horari_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>  
            </div>  
            <div class="row">
                <div class="col-12">
                    <mct-form-itens tipo="text" campo="horari_descricao" :valor="lista" titulo="Descrição" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>  
            </div>  
            <div class="row mb-2">
                <div class="col-6">
                    <mct-form-itens tipo="select" campo="turm_codigo" :valor="lista" titulo="Turma" obrigatorio="false" 
                        origem="esc_turmas" origemChave="turm_codigo" origemRetorno='turm_identificacao' @setDataLista="setTurma" :errors="errors" :modo="modo"></mct-form-itens>                                                           
                </div>    
            </div>
            <div class="mct-grid">
                <div class="mct-grid-scroll-sec">                        
                    <table class="table table-sm table-striped">
                        <thead>
                            <tr>
                                <th class="text-center">AULA</th>    
                                <th style="width: 160px;">SEGUNDA</th>    
                                <th style="width: 160px;">TERÇA</th>    
                                <th style="width: 160px;">QUARTA</th>    
                                <th style="width: 160px;">QUINTA</th>    
                                <th style="width: 160px;">SEXTA</th>    
                                <th style="width: 160px;">SÁBADO</th>    
                            </tr>
                        </thead> 
                        <tbody>
                            <tr v-for="(linha,index) in lista.horariosdisciplinas" :key="index">
                                <td class="text-center">{{linha.horaridisp_aula}}</td>
                                <td>
                                    <div class="col-12 m-0 p-0">
                                        <mct-form-itens tipo="select" campo="horaridisp_seg_cod" :valor="linha" obrigatorio="false" 
                                            :origemData="disciplinas" origemChave="horaridisp_seg_cod" origemRetorno='disp_nome' :errors="errors" :modo="modo"></mct-form-itens>                                                              
                                    </div>
                                </td>
                                <td>
                                    <div class="col-12 m-0 p-0">
                                        <mct-form-itens tipo="select" campo="horaridisp_ter_cod" :valor="linha" obrigatorio="false" 
                                            :origemData="disciplinas" origemChave="profdis_disp_codigo" origemRetorno='disp_nome' :errors="errors" :modo="modo"></mct-form-itens>                                                              
                                    </div>
                                </td>
                                <td>
                                    <div class="col-12 m-0 p-0">
                                        <mct-form-itens tipo="select" campo="horaridisp_qua_cod" :valor="linha" obrigatorio="false" 
                                            :origemData="disciplinas" origemChave="profdis_disp_codigo" origemRetorno='disp_nome' :errors="errors" :modo="modo"></mct-form-itens>                                                              
                                    </div>
                                </td>
                                <td>
                                    <div class="col-12 m-0 p-0">
                                        <mct-form-itens tipo="select" campo="horaridisp_qui_cod" :valor="linha" obrigatorio="false" 
                                            :origemData="disciplinas" origemChave="profdis_disp_codigo" origemRetorno='disp_nome' :errors="errors" :modo="modo"></mct-form-itens>                                                              
                                    </div>
                                </td>
                                <td>
                                    <div class="col-12 m-0 p-0">
                                        <mct-form-itens tipo="select" campo="horaridisp_sex_cod" :valor="linha" obrigatorio="false" 
                                            :origemData="disciplinas" origemChave="profdis_disp_codigo" origemRetorno='disp_nome' :errors="errors" :modo="modo"></mct-form-itens>                                                              
                                    </div>
                                </td>
                                <td>
                                    <div class="col-12 m-0 p-0">
                                        <mct-form-itens tipo="select" campo="horaridisp_sab_cod" :valor="linha" obrigatorio="false" 
                                            :origemData="disciplinas" origemChave="profdis_disp_codigo" origemRetorno='disp_nome' :errors="errors" :modo="modo"></mct-form-itens>                                                              
                                    </div>
                                </td>
                            </tr>    
                        </tbody>   
                    </table>  
                </div>
            </div>
        </div>              
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'

import { mapState, mapActions } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'Horarios',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
        disciplinas: {},
        turmas: [],
        turmaSelecionada: {}
      }
  },
  methods: {
    setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            this.lista.horari_ent = this.Entidade    
            this.lista.horari_esc = this.Escola    
            this.lista.horari_ano = this.Exercicio    
            this.lista.horari_situacao = 1    
        }
    },
    setTurma (data) {
        //console.log(data);
        this.turmas = data
        if (typeof this.lista != undefined && typeof this.lista.turm_codigo != undefined){
            var ret = this.turmas.filter(el =>  {
                return el.turm_codigo == this.lista.turm_codigo;
            })
            this.turmaSelecionada = ret[0]
            this.disciplinas = this.turmaSelecionada.disciplinas;
        }
    },

       
  },
  watch: {
    'lista.turm_codigo': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
            var ret = this.turmas.filter(function(el) {
                return el.turm_codigo == newVal;
            })
            this.turmaSelecionada = ret[0]
            this.disciplinas = {}
            if ( typeof this.turmaSelecionada != undefined && typeof this.turmaSelecionada == 'object' && typeof this.turmaSelecionada.disciplinas != undefined){
                    this.disciplinas = this.turmaSelecionada.disciplinas;
            }
            if ((this.modo == 'novo') /*|| ( this.modo == 'editar' && newVal !== oldVal)*/){
                this.lista.horariosdisciplinas = []
                var qtdAulas = this.turmaSelecionada.turno.turn_aulas_diaria
                var i = 1
                while (i <= qtdAulas){
                    this.lista.horariosdisciplinas.push({
                        horaridisp_codigo: 0,
                        horari_codigo: null,
                        horari_ent: null,
                        horari_esc: null,
                        horari_ano: null,
                        horaridisp_aula: i,
                        horaridisp_seg_cod: null,
                        horaridisp_ter_cod: null,
                        horaridisp_qua_cod: null,
                        horaridisp_qui_cod: null,
                        horaridisp_sex_cod: null,
                        horaridisp_sab_cod: null,                        
                    }) 
                    i++   
                }

            }
        }
    },
  },
  computed: {
      ...mapState('Login',['infoLogado','Entidade', 'Exercicio', 'Escola']),
  }, 
  created() {
  
  }     
}
</script>