import { render, staticRenderFns } from "./EscCursosTabela.vue?vue&type=template&id=06d83b7c&"
import script from "./EscCursosTabela.vue?vue&type=script&lang=js&"
export * from "./EscCursosTabela.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports