<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="!Alterando ? 'editar' : 'readonly'" botoes="false">
    <div slot="formulario">
      <div class="view" style="padding-top: 5px; padding-bottom: 5px;">      
        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Turma</legend> 
          <div class="row">
            <div class="col-12">
              <mct-form-itens tipo="search" tamanho="2" campo="aluacom_turm_codigo" :valor="lista" titulo="Turma" :errors="errors" obrigatorio="true" origem="esc_turmas" origemChave="turm_codigo" origemRetorno='turm_completa' @setDataRetorno="setTurma" origemChaveComposta="true" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
          </div>
          <div class="row">
            <div class="col-8">
              <mct-form-itens tipo="select" campo="aluacom_acon_codigo" :valor="lista" titulo="Área de Conhecimento" :errors="errors" obrigatorio="true" origemChave="acon_codigo" origemRetorno='acon_codigo' origemRetornoSec="acon_descricao" :origemData="areaAcompanhamentoLista" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
            <div class="col-4">
              <!--<mct-form-itens tipo="select" campo="aluacom_etap_codigo" :valor="lista" titulo="Etapa" :errors="errors" obrigatorio="true" origem="esc_etapas" origemChave="etap_codigo" origemRetorno='etap_descricao' 
               :origemFiltro="{etap_crit_codigo: turmaSelecionada.crit_codigo}" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>-->
              <mct-form-itens tipo="select" campo="aluacom_etap_codigo" :valor="lista" titulo="Etapa" :errors="errors" obrigatorio="true" origem="esc_etapas" origemChave="etap_codigo" origemRetorno='etap_descricao' 
                :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
          </div>
        </fieldset>

        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Alunos/Aulas</legend> 
          <div class="row mt-2 text-center">
            <div class="col-12">
              <div class="btn-group" role="group" aria-label="Ações">
                <button type="button" class="btn btn-info text-white" aria-label="Left Align" :disabled="Alterando"  @click.prevent.stop="alterar()">
                <span class="fa fa-pencil text-white" aria-hidden="true"></span> Alterar </button>
                <button type="button" class="btn btn-success text-white" aria-label="Left Align" :disabled="!Alterando"  @click.prevent.stop="confirma()">
                <span class="fa fa-check fa-lg text-white" aria-hidden="true"></span> Gravar </button>
                <button type="button" class="btn btn-dark text-white" aria-label="Left Align" :disabled="!Alterando"  @click.prevent.stop="cancelar()">
                <span class="fa fa-ban fa-lg text-white" aria-hidden="true"></span> Cancelar </button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="mct-grid mt-2">
                <div class="mct-grid-scroll-sec">                        
                  <table class="animated fadeIn table table-striped table-hover mb-0">
                    <thead>
                      <tr>
                        <th scope="col" style="width: 60px;">R.A.</th>
                        <th scope="col" style="width: 100px;">MATRÍCULA</th>
                        <th scope="col">NOME ALUNO</th>
                      </tr>
                    </thead>
                    <tbody>                                
                      <tr v-for="(aluno,index2) in lista.alunos" :key="index2" @click="alunoSel = index2">
                        <td>{{aluno.matri_codigo}}</td>
                        <td>{{aluno.matri_matricula}}</td>
                        <td>{{aluno.aluno.pes_nome}} <small class="text-muted">[{{aluno.aluno.pes_codigo}}]</small></td>
                      </tr>
                      <tr  v-if="typeof lista.alunos === 'undefined' || lista.alunos.length === 0 || Object.keys(lista.alunos).length === 0">
                          <td colspan="12" class="text-info" style="text-align: center; font-size: 1.2rem;">SEM REGISTROS</td>
                      </tr>    
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-6" v-if="alunoSel !== null">
              <mct-form-itens tipo="textarea" campo="aluacom_texto" linhas=20 :valor="lista.alunos[alunoSel]" :titulo="`Digite o Relatório do Aluno(a): ${lista.alunos[alunoSel].aluno.pes_nome}.`" :errors="errors" modo="editar"></mct-form-itens>
            </div>
          </div>
        </fieldset>
        <div style="padding-top: 17px;">
          <button type="button" class="btn btn-danger text-white" aria-label="Left Align" @click.prevent.stop="$router.back()">
              <span class="fa fa-times text-white" aria-hidden="true"></span> Fechar </button>
        </div>
      </div> 
    </div>              
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import { uniqBy } from 'lodash'
import * as funcoes from '@/components/funcoes/Funcoes.js'

import MctPadrao from '@/components/comum/MctFormPadraoSCB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'Cursos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {
          aluacom_turm_codigo: null,
          aluacom_disp_codigo: null,
          aluacom_acon_codigo: null,
          aluacom_etap_codigo: null,
          //aluacom_texto: null,
          alunos: [],
        },
        errors:{},
        message: '',
        status: 0,
        turmaSelecionada: [],
        Alterando: false,
        alunoSel: null
      }
  },
  methods: {
    setTurma(data){
      this.turmaSelecionada = data
    },    
    async alterar(){
      if(this.validaInputs()){
        await axios.get('escalunosacompanhamento/acompanhamentos?turm_codigo='+this.lista.aluacom_turm_codigo+'&acon_codigo='+this.lista.aluacom_acon_codigo+
          '&etap_codigo='+this.lista.aluacom_etap_codigo)
          .then(response => {
            this.Alterando = true
            this.lista.alunos = response.data.data
          })
          .catch(response => {
            this.message = response.response.data.message
            this.errors = response.response.data.errors
            this.status = response.response.status
          })
      }      
    },
    validaInputs () {
      this.errors = new Object()
      if (this.lista.aluacom_turm_codigo == null || this.lista.aluacom_turm_codigo == ''){
        this.errors.aluacom_turm_codigo = []
        this.errors.aluacom_turm_codigo.push("O campo TURMA não pode ser nulo ou vazio.") 
      }
      if (this.lista.aluacom_acon_codigo == null || this.lista.aluacom_acon_codigo == ''){
        this.errors.aluacom_acon_codigo = []
        this.errors.aluacom_acon_codigo.push("O campo ÁREA DE CONHECIMENTO não pode ser nulo ou vazio.") 
      }
      if (this.lista.aluacom_etap_codigo == null || this.lista.aluacom_etap_codigo == ''){
        this.errors.aluacom_etap_codigo = []
        this.errors.aluacom_etap_codigo.push("O campo ETAPA não pode ser nulo ou vazio.") 
      }
      if (Object.keys(this.errors).length > 0) {
        this.message = "Houve um ou mais erros."
        this.status = 422
        return false
      }else{
        this.message = ""
        this.status = 0
        return true
      }        
    },
    confirma(){
      if(this.validaInputs()){
        this.$swal({
          title: 'Deseja realmente gravar os dados?',
          text: "Lembre-se que todos os dados anteriores serão apagados!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
          }).then((result) => {
            if (result.value) {
              this.gravar()
            }                     
        })
      }
    },
    async gravar(){
      
      var dados = this.lista
      await axios.post('escalunosacompanhamento/salvar',dados)
        .then(response => {
            this.message = response.data.message
            this.status = response.status
            //this.lista = response.data.data
            this.errors = {}
            
            this.$swal('Sucesso!',
              'Registro incluído com sucesso!',
              'success');  
            this.Alterando = false
            this.lista.alunos = []
            this.lista.aluacom_turm_codigo = null
            this.lista.aluacom_disp_codigo = null
            this.lista.aluacom_acon_codigo = null
            this.lista.aluacom_etap_codigo = null
            this.alunoSel = null
        })
        .catch(response => {
            this.message = response.response.data.message
            if (response.response.data.error){
                this.errors = response.response.data.error.errorInfo[2]              
            }else{
                this.errors = response.response.data.errors
            }
            this.status = response.response.status            
        })  
    },
    cancelar(){
      this.$swal({
        title: 'Deseja realmente cancelar a alteração?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
              this.Alterando = false
              this.lista.alunos = []
              this.lista.aluacom_turm_codigo = null
              this.lista.aluacom_disp_codigo = null
              this.lista.aluacom_acon_codigo = null
              this.lista.aluacom_etap_codigo = null
              this.alunoSel = null
            }                     
        })
    }
  },
  computed: {
    ...mapState('Login',['infoLogado']), 

    areaAcompanhamentoLista () {
      let ret = []
      if (this.turmaSelecionada && this.turmaSelecionada.disciplinas){
        ret = uniqBy(this.turmaSelecionada.disciplinas, 'acon_codigo')
      }
      return ret
    }

  },  
  created() {
  
  }
 
}
</script>