<template>
<!-- RELATORIOS CONTAS A PAGAR - A PAGAR -->
    <div>        
        <div class="row mb-4">
            <div class="col-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Turma</legend>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_ini" :valor="filtro" titulo="Inicial" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_ini" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_fin" :valor="filtro" titulo="Final" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_fin" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div> 
        <div class="row mb-2">
            <div class="col-12">
                <div class="row">
                    <div class="col-3">
                        <mct-form-itens tipo="number" campo="aluno" :valor="filtro" titulo="Aluno" :errors="{}" modo="editar"></mct-form-itens>
                    </div>
                    <div class="col-9">
                        <mct-form-itens tipo="select" campo="aluno" :valor="filtro" titulo=" " origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo' :errors="{}" modo="editar"></mct-form-itens>
                    </div>
                </div>
            </div>
        </div>               
        <div class="row mb-2">
            <div class="col-sm-12 text-left">
                <button type="button" class="btn btn-secondary mr-2" aria-label="Left Align" @click="onFichaIndividual()">
                <span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Visualizar</button>     
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/http'
import moment from 'moment'
  
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios'
  
export default {
    components: {
        'MctFormItens': MctFormItens,
    },
    mixins: [MctPadraoRelatorios],
    data() {
        return {
            data: [],
            outputPdf: null,
            filtro: {
                turma_ini: null,
                turma_fin: null,
                agrupa_agendamento: null,
                situacao_agendamento: 9,
                agendamento: null,
                assinatura: 0,
                etap_codigo: null,
                aluno: null,
                modelo: 1,
                tipo: 0
            }
        }
    },
    methods: {
        async getData() {
            var url = '/fichaindividual';

            await axios.get(`/relatorios/siges${url}`,{params:  {
                turmaini: this.filtro.turma_ini,
                turmafin: this.filtro.turma_fin,
                agrupa_agendamento: this.filtro.agrupa_agendamento,
                situacao_agendamento: this.filtro.situacao_agendamento,
                agendamento: this.filtro.agendamento,
                etap_codigo: this.filtro.etap_codigo,
                aluno: this.filtro.aluno,
                tipo: this.filtro.tipo
            },
            paramsSerializer: function (params) {
                return jQuery.param(params)
            }
            })
            .then(response => {
                this.data = response.data.data                    
            })
            .catch(response => {
                console.log(response.message)
            })
            
        },  
        
        async onTopoFichaIndividual(index) {
            this.pdf.setFontSize(7.5)
            this.pdf.setFont('helvetica','normal')
            
            this.pdf.rect(8,this.lin-3,this.widthPage,27)
            this.pdf.setFontSize(7.5)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Nome Aluno(a):',10,this.lin+1)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].pes_nome),32,this.lin+1)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('R.A.:',130,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].matri_codigo),152,this.lin) 
            this.onAddLine(this.jump+1)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Data Nasc.:',10,this.lin+1)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.$options.filters.data(this.data[index].pes_nascimento)),32,this.lin+1) 
            this.onAddLine(this.jump+1)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Naturalidade:',10,this.lin+1)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].cidadenatal?this.data[index].cidadenatal.cid_descricao+'-'+this.data[index].cidadenatal.estado.est_uf : ''),32,this.lin+1) 
            this.onAddLine(this.jump+1)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Nacionalidade:',10,this.lin+1)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].nacionalidade?this.data[index].nacionalidade.nac_descricao : ''),32,this.lin+1) 
            this.onAddLine(this.jump+1)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Nome do Pai:',10,this.lin+1)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].pai?this.data[index].pai.pes_nome : ''),32,this.lin+1) 
            this.onAddLine(this.jump+1)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Nome da Mãe:',10,this.lin+1)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].mae?this.data[index].mae.pes_nome : ''),32,this.lin+1) 
            this.onAddLine(this.jump+4)

            this.pdf.rect(8,this.lin-3,this.widthPage,6);
            this.pdf.setFont('helvetica','bold')
            this.pdf.setFontSize(9)
            this.pdf.text('FICHA INDIVIDUAL - ANO LETIVO '+this.data[index].matri_ano,this.widthPage/2,this.lin+1,{align: 'center'});
            this.onAddLine(this.jump+3)


            this.pdf.rect(8,this.lin-3,this.widthPage,9)
            this.pdf.setFont('helvetica','bold')
            this.pdf.setFontSize(7.5)
            this.pdf.text('Curso:',10,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].cur_nome),26,this.lin) 

            this.onAddLine(this.jump+1)

            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Série:',10,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].ser_descricao),26,this.lin) 

            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Turma:',60,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].turm_turma),73,this.lin) 

            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Identificação:',93,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].turm_identificacao),116,this.lin) 

            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Turno:',148,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].turn_descricao),163,this.lin)
        },

        async onFichaIndividual () {

            await this.getData();

            await this.onCreatePDF('P')
            /*CABEÇALHO*/
            this.title = '';
            this.semData = true;
            this.semPagina = true;
            this.onHeaderPDF();
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)    
                    
            var grupo = '';

            for (var index in this.data) {
                if (grupo !== this.data[index].cur_codigo+'|'+this.data[index].turm_codigo+'|'+this.data[index].matri_alu_codigo){
                    if (grupo !== '')
                    this.onSaltarPagina2();
                    grupo = this.data[index].cur_codigo+'|'+this.data[index].turm_codigo+'|'+this.data[index].matri_alu_codigo
                    
                    await this.onTopoFichaIndividual(index);

                    /*PARTE DO BOLETIM*/

                    this.onAddLine(this.jump+4);
                    this.pdf.rect(8,this.lin-3,40,10);
                    this.pdf.text('Componentes Curriculares',10,this.lin+3);

                    this.pdf.rect(48,(this.lin-3),this.widthPage-40,4);
                    this.pdf.text('Nível de Desempenho dos Alunos por Bimestre',this.widthPage-70,this.lin,{align: 'center'});

                    this.onAddLine(this.jump+1);

                    

                    this.pdf.rect(48,(this.lin-3),12,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('1°. BIM.',54,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('NOTA',51,this.lin+2,{align: 'center'});
                    this.pdf.text('F',57,this.lin+2,{align: 'center'});
                    
                    this.pdf.rect(60,(this.lin-3),12,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('2°. BIM.',66,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('NOTA',63,this.lin+2,{align: 'center'});
                    this.pdf.text('F',69,this.lin+2,{align: 'center'});

                    this.pdf.rect(72,(this.lin-3),10,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('MD. 1°.',77,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('SEM.',77,this.lin+2,{align: 'center'});

                    this.pdf.rect(82,(this.lin-3),10,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('REC. 1°.',87,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('SEM.',87,this.lin+2,{align: 'center'});

                    this.pdf.rect(92,(this.lin-3),10,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('MD. FIN.',97,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('1°. SEM.',97,this.lin+2,{align: 'center'});
                    
                    this.pdf.rect(102,(this.lin-3),12,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('3°. BIM.',108,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('NOTA',105,this.lin+2,{align: 'center'});
                    this.pdf.text('F',111,this.lin+2,{align: 'center'});
                    
                    this.pdf.rect(114,(this.lin-3),12,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('4°. BIM.',120,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('NOTA',117,this.lin+2,{align: 'center'});
                    this.pdf.text('F',123,this.lin+2,{align: 'center'});

                    this.pdf.rect(126,(this.lin-3),10,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('MD. 2°.',131,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('SEM.',131,this.lin+2,{align: 'center'});

                    this.pdf.rect(136,(this.lin-3),10,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('REC. 2°.',141,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('SEM.',141,this.lin+2,{align: 'center'});

                    this.pdf.rect(146,(this.lin-3),10,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('MD. FIN.',151,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('2°. SEM.',151,this.lin+2,{align: 'center'});

                    this.pdf.rect(156,(this.lin-3),10,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('PROVA',161,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('FINAL',161,this.lin+2,{align: 'center'});

                    this.pdf.rect(166,(this.lin-3),10,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('MEDIA',171,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('FINAL',171,this.lin+2,{align: 'center'});

                    this.pdf.rect(176,(this.lin-3),10,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('CARGA',181,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('HORA.',181,this.lin+2,{align: 'center'});

                    this.pdf.rect(186,(this.lin-3),17,6);
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.setFontSize(6)
                    this.pdf.text('RESUL.',194.5,this.lin-0.5,{align: 'center'});
                    this.pdf.setFontSize(5)
                    this.pdf.text('FINAL',194.5,this.lin+2,{align: 'center'});

                    this.onAddLine(this.jump+3);
                    
                    this.data[index].notas.map((item,index) => {

                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7)
                        this.pdf.rect(8,this.lin-3,40,5);
                        this.pdf.text(item.disp_nome,10,this.lin);

                        this.pdf.rect(48,(this.lin-3),12,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.media_eta_1,2)),51,this.lin,{align: 'center'});
                        this.pdf.text(String(item.faltas_eta_1),57,this.lin,{align: 'center'});
                        
                        this.pdf.rect(60,(this.lin-3),12,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.media_eta_2,2)),63,this.lin,{align: 'center'});
                        this.pdf.text(String(item.faltas_eta_2),69,this.lin,{align: 'center'});

                        this.pdf.rect(72,(this.lin-3),10,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.media_sem_1,2)),77,this.lin,{align: 'center'});

                        this.pdf.rect(82,(this.lin-3),10,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.rec_sem_1,2)),87,this.lin,{align: 'center'});

                        this.pdf.rect(92,(this.lin-3),10,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.media_fin_sem_1,2)),97,this.lin,{align: 'center'});
                        
                        this.pdf.rect(102,(this.lin-3),12,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.media_eta_3,2)),105,this.lin,{align: 'center'});
                        this.pdf.text(String(item.faltas_eta_3),111,this.lin,{align: 'center'});
                        
                        this.pdf.rect(114,(this.lin-3),12,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.media_eta_4,2)),117,this.lin,{align: 'center'});
                        this.pdf.text(String(item.faltas_eta_4),123,this.lin,{align: 'center'});

                        this.pdf.rect(126,(this.lin-3),10,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.media_sem_2,2)),131,this.lin,{align: 'center'});

                        this.pdf.rect(136,(this.lin-3),10,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.rec_sem_2,2)),141,this.lin,{align: 'center'});

                        this.pdf.rect(146,(this.lin-3),10,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.media_fin_sem_2,2)),151,this.lin,{align: 'center'});

                        this.pdf.rect(156,(this.lin-3),10,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.prova_final,2)),161,this.lin,{align: 'center'});

                        this.pdf.rect(166,(this.lin-3),10,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.media_final,2)),171,this.lin,{align: 'center'});

                        this.pdf.rect(176,(this.lin-3),10,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(this.$options.filters.moeda(item.ch,0)),181,this.lin,{align: 'center'});

                        this.pdf.rect(186,(this.lin-3),17,5);
                        this.pdf.setFont('helvetica','normal') 
                        this.pdf.setFontSize(7)
                        this.pdf.text(String(item.situacao),194.5,this.lin,{align: 'center'});

                        this.onAddLine(this.jump+2);
                    });

                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Dias Letivos:',10,this.lin+1)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.$options.filters.moeda(this.data[index].curr_dias_letivos,0)),32,this.lin+1)            

                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Carga Horária:',50,this.lin+1)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.$options.filters.moeda(this.data[index].curr_ch_anual,0)),72,this.lin+1)            

                    this.onAddLine(this.jump+2);

                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Observações:',10,this.lin+1)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(''),32,this.lin+1)            

                    this.onAddLine(this.jump+6);

                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Situação:',10,this.lin+1)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(''),32,this.lin+1)            

                    this.onAddLine(this.jump+2);

                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(this.infoEntidade.ent_cidade+'-'+this.infoEntidade.ent_estado+', '+moment().locale('pt-br').format('LL')+'.',195,this.lin+1,{align: 'right'})

                }
            }
                                               
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            const urlPDF = this.pdf.output('blob');
            this.outputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
            this.$emit('update:outputPdf', this.outputPdf);            
        },         
    }
}
</script>