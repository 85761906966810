<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="modela_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <mct-form-itens tipo="text" campo="modela_descricao" :valor="lista" titulo="Descrição" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-sm-2 overflow-auto bg-light" style="max-height: 600px">
                    <fieldset class="well bg-white">
                        <legend class="well-legend">Tags</legend>
                        <ul class="nav">
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[PAGE_BREAK]')">Quebra de Página</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_NOME]')">Nome Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_CPF]')">CPF Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_RG]')">RG Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_CERTIDAO]')">Certidão Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_CERTIDAO_CIDADE]')">Cidade Certidão Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_CERTIDAO_CARTORIO]')">Cartório Certidão Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_PAI]')">Pai do Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_MAE]')">Mãe do Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_TELEFONES]')">Telefones Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_CONTATOS]')">Telefones de Contato Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_DATA_NASCIMENTO]')">Data Nascimento Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_CEP]')">Cep Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_ENDERECO]')">Endereço Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_BAIRRO]')">Bairro Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_CIDADE]')">Cidade Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_ESTADO]')">Estado Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_ENDERECO_COMPLETO]')">Endereço Completo Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_EMAIL]')">Email Aluno</a></li>                           
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_INSCRICAO]')">Inscrição do Aluno</a></li>                           
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_INSCRICAO_DATA]')">Data de Inscrição do Aluno</a></li>                           
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE]')">Nome Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_CNPJ]')">CNPJ Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_CEP]')">Cep Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_ENDERECO]')">Endereço Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_BAIRRO]')">Bairro Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_CIDADE]')">Cidade Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_ESTADO]')">Estado Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_ENDERECO_COMPLETO]')">Endereço Completo Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ESCOLA_DIRETOR]')">Escola Diretor</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ESCOLA_DIRETOR_CPF]')">Escola Diretor CPF</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ESCOLA_DIRETOR_RG]')">Escola Diretor RG</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ESCOLA_DIRETOR_ASSINATURA]')">Escola Diretor Assinatura</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[UNIDADE_ESCOLAR]')">Unidade Escolar</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[UNIDADE_ESCOLAR_ENDERECO]')">Endereço Unidade Escolar</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[LOCAL_DATA]')">Local e Data</a></li>
                        </ul>
                    </fieldset> 
                </div>
                <div class="col-sm-10">
                    <fieldset class="well">
                        <legend class="well-legend">Texto</legend>
                        <quill-editor v-model="lista.modela_texto"
                            ref="QuillEditor"
                            :options="editorOption"
                            >
                            <div id="toolbar" slot="toolbar">
                                <button class="ql-bold">Bold</button>
                                <button class="ql-italic">Italic</button>
                                <button class="ql-underline">Underline</button>
                                <button class="ql-strike">Strike</button>
                                <button class="ql-blockquote">Blockquote</button>
                                <button class="ql-code-block">CodeBlock</button>
                                <button class="ql-header" value="1"></button>
                                <button class="ql-header" value="2"></button>
                                <button class="ql-list" value="ordered"></button>
                                <button class="ql-list" value="bullet"></button>
                                <button class="ql-script" value="sub"></button>
                                <button class="ql-script" value="super"></button>
                                <button class="ql-indent" value="-1"></button>
                                <button class="ql-indent" value="+1"></button>

                                <button class="ql-direction" value="rtl"></button>
                                <select class="ql-size">
                                    <option value="small"></option>
                                    <option selected></option>
                                    <option value="large"></option>
                                    <option value="huge"></option>
                                </select>
                                <select class="ql-header">
                                    <option selected="selected"></option>
                                    <option value="1"></option>
                                    <option value="2"></option>
                                    <option value="3"></option>
                                    <option value="4"></option>
                                    <option value="5"></option>
                                    <option value="6"></option>
                                </select>
                                <select class="ql-font">
                                    <option selected="selected"></option>
                                    <option value="serif"></option>
                                    <option value="monospace"></option>
                                </select>
                                <select class="ql-color"></select>
                                <select class="ql-background"></select>
                                <select class="ql-align"></select>
                                
                                <!--<button class="ql-clear"></button>-->
                                <button class="ql-link"></button>
                                <button class="ql-image"></button>
                                <button class="ql-video"></button>
                                
                                <!--<button class="ql-clear"></button>-->
                                
                                <!--<select id="ql-customTags" class="ql-customTags">
                                    <option selected="selected" class="ql-customTags"></option>
                                    <option value="[ALUNO_NOME]" class="ql-customTags">NOME ALUNO</option>
                                    <option value="[ALUNO_ENDERECO_COMPLETO]" class="ql-customTags">ENDEREÇO COMPLETO ALUNO</option>
                                </select>-->
                                <button id="custom-button" @click.prevent.stop="onPrint"><i class="fa fa-print fa-sm"></i></button>
                            </div>
                        </quill-editor>
                    </fieldset>
                </div>
            </div>
        </div>        
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { quillEditor } from 'vue-quill-editor';

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'ModelosContratos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      quillEditor
  },
  data () {
    return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
        editorOption: {
            preserveWhitespace: true,
            placeholder: 'Digite seu texto aqui.',
            modules: {
                toolbar: { 
                    container: '#toolbar',                   
                }
                
            }
        },
        customToolbar: [
            [{ 'placeholder': ['[GuestName]', '[HotelName]'] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
        ],
    }
  },
  methods: {
    setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            //this.lista.modela_status = 1 
            this.lista.modela_ent = this.Entidade   
            this.lista.modela_esc = this.Escola   
        }
    },
    placeholder(args) {
        const quill = this.$refs.QuillEditor.quill
        const value = args;//args[0];
        const cursorPosition = quill.getSelection().index
        quill.insertText(cursorPosition, value)
        quill.setSelection(cursorPosition + value.length)

    },
    onPrint(){
        printJS({printable: '<style>.ql-editor p{ line-height: 0.9rem!important;}</style><div class="ql-editor">'+this.lista.modela_texto+'</div>', type: 'raw-html', scanStyles: false, css: 'https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.core.min.css'})
    },
  },
  computed: {
    ...mapState('Login',['Entidade','Escola'])       
  }, 
  created() {
 
  }     
}
</script>

<style lang="css">
/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>

<style scoped>

    .ql-picker-item:before {
        content: attr(data-label);
    }

    .nav-link {
        display: block;
        background-color: #F4F4F4;
        padding: 4px;
        margin: 2px;
    }

</style>