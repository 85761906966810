<template>
    <div class="animated fadeIn mct-form">
        <mct-pop-ups :titulo="titAlerta" :mensagem="msgAlerta" :modo="tipoAlerta" @confirma="onConfirma()"></mct-pop-ups>
        <mct-topo-lista :titulo="titulo" :subtitulo="'Manutenção de '+ titulo + ' - ' + modo.toUpperCase()"></mct-topo-lista>    
        <mct-alertas :mensagem="message" :errors="errors" :status="status" :modo="modo" v-show="status !== 0"></mct-alertas>
        <mct-form>
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="transf_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
                </div>    
                <div class="col-sm-3">
                    <mct-form-itens tipo="select" campo="transf_tipo" :valor="lista" titulo="Tipo" obrigatorio="true" :errors="errors" 
                    :origemData="[ {value: 1, text: 'ENTRE CONTAS'}, {value: 2, text: 'BANCO -> CAIXA'}, {value: 3, text: 'CAIXA -> BANCO'}, {value: 4, text: 'ENTRE CAIXAS'} ]" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-sm-2">
                    <mct-form-itens tipo="date" campo="transf_data" :valor="lista" titulo="Data" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row" v-show="lista.transf_tipo == 1 || lista.transf_tipo == 2">
                <div class="col-sm-12">
                    <mct-form-itens tipo="select" campo="cbncori_codigo" :valor="lista" titulo="Conta Bancária Origem" obrigatorio="true" 
                        origem="esc_conta_bancaria" origemChave="cbnc_codigo" origemRetorno='cbnc_descricao' :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>
            <div class="row" v-show="lista.transf_tipo == 3 || lista.transf_tipo == 4">
                <div class="col-sm-12">
                    <mct-form-itens tipo="select" campo="cxaori_codigo" :valor="lista" titulo="Conta Caixa Origem" obrigatorio="true" 
                        origem="esc_caixa" origemChave="cxa_codigo" origemRetorno='cxa_descricao' :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>
            <div class="row" v-show="lista.transf_tipo == 1 || lista.transf_tipo == 3">
                <div class="col-sm-12">
                    <mct-form-itens tipo="select" campo="cbncdes_codigo" :valor="lista" titulo="Conta Bancária Destino" obrigatorio="true" 
                        origem="esc_conta_bancaria" origemChave="cbnc_codigo" origemRetorno='cbnc_descricao' :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>
            <div class="row" v-show="lista.transf_tipo == 2 || lista.transf_tipo == 4">
                <div class="col-sm-12">
                    <mct-form-itens tipo="select" campo="cxades_codigo" :valor="lista" titulo="Conta Caixa Destino" obrigatorio="true" 
                        origem="esc_caixa" origemChave="cxa_codigo" origemRetorno='cxa_descricao' :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <mct-form-itens tipo="select" campo="ccst_codigo" :valor="lista" titulo="Centro de Custo" 
                        origem="esc_centro_custo" origemChave="ccst_codigo" origemRetorno='ccst_descricao' 
                        :origemFiltro="{ccst_status: 1}" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="currency" campo="transf_valor" :valor="lista" titulo="Valor" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
                <div class="col-sm-3">
                    <mct-form-itens tipo="select" campo="tqui_codigo" :valor="lista" titulo="Tipo Documento" obrigatorio="true" origem="esc_tp_quitacao" 
                        origemChave="tqui_codigo" origemRetorno='tqui_descricao' :origemFiltro="{tqui_status: 1}" @setDataLista="setTpQuitacao" :errors="errors" :modo="modo"></mct-form-itens>
                </div>    
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="text" campo="transf_documento" :valor="lista" titulo="N.º Documento" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
            </div>            
            <div class="row">
                <div class="col-sm-12">
                    <mct-form-itens tipo="textarea" campo="transf_historico" :valor="lista" titulo="Histórico" obrigatorio="true" linhas="3" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>            
        </mct-form>
        <mct-form-botoes @submit="submit()" :modo="modo"></mct-form-botoes>
    </div>     
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'
import moment from 'moment'

import MctTopoLista from '@/components/comum/MctTopoLista.vue'
import MctForm from '@/components/comum/MctForm.vue'
import MctFormBotoes from '@/components/comum/MctFormBotoes.vue'
import MctAlertas from '@/components/comum/MctAlertas.vue' 
import MctPopUps from '@/components/comum/MctPopUps.vue'
import MctDataViewer from '@/components/comum/MctDataViewer.vue'
import MctMixinsForm from '@/components/funcoes/MctMixinsForm'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'EscTransferencias',
  mixins: [MctMixinsForm],
  props: ['source','titulo','modo'],
  components: {
    MctForm,
    MctTopoLista,
    MctFormBotoes,
    MctAlertas,
    MctPopUps,
    MctDataViewer,
    MctFormItens
  },
  data () {
    return {
        tpQuitacao: [],
        tpQuitacaoSelecionado: {}
    }
  },
  methods: {
    async onValida() {
        let msgs = ''
        let soma = 0
        if (this.lista.transf_tipo == null || this.lista.transf_tipo == ''){
            msgs += 'Selecione uma Tipo; </br>';
        }
        if (this.lista.transf_documento == null || this.lista.transf_documento == ''){
            msgs += 'O campo Número Documento tem um valor inválido; </br>';
        }
        if (this.lista.transf_data == null || this.lista.transf_data == 0){
            msgs += 'Digite uma Data válida; </br>';
        }
        if (this.lista.transf_valor == null || this.lista.transf_valor == 0){
            msgs += 'O campo Valor não pode ser nulo ou zero; </br>';
        }
        if (this.lista.transf_historico == null || this.lista.transf_historico == 0){
            msgs += 'O campo Histórico não pode ser vazio; </br>';
        }
        if (this.lista.transf_tipo == 1){
            if (this.lista.cbncori_codigo == null || this.lista.cbncori_codigo == ''){
                msgs += 'Selecione uma Conta de Origem; </br>';
            }
            if (this.lista.cbncdes_codigo == null || this.lista.cbncdes_codigo == ''){
                msgs += 'Selecione uma Conta de Destino; </br>';
            }
            if (this.lista.cbncori_codigo == this.lista.cbncdes_codigo){
                msgs += 'A Conta de Origem não pode ser igual a Conta de Destino; </br>';
            }
        } else
        if (this.lista.transf_tipo == 2){
            if (this.lista.cbncori_codigo == null || this.lista.cbncori_codigo == ''){
                msgs += 'Selecione uma Conta de Origem; </br>';
            }
            if (this.lista.cxades_codigo == null || this.lista.cxades_codigo == ''){
                msgs += 'Selecione um Caixa de Destino; </br>';
            }
        } else
        if (this.lista.transf_tipo == 3){
            if (this.lista.cxaori_codigo == null || this.lista.cxaori_codigo == ''){
                msgs += 'Selecione um Caixa de Origem; </br>';
            }
            if (this.lista.cbncdes_codigo == null || this.lista.cbncdes_codigo == ''){
                msgs += 'Selecione uma Conta de Destino; </br>';
            }
        } else
        if (this.lista.transf_tipo == 4){
            if (this.lista.cxaori_codigo == null || this.lista.cxaori_codigo == ''){
                msgs += 'Selecione um Caixa de Origem; </br>';
            }
            if (this.lista.cxades_codigo == null || this.lista.cxades_codigo == ''){
                msgs += 'Selecione um Caixa de Destino; </br>';
            }
            if (this.lista.cxaori_codigo == this.lista.cxades_codigo){
                msgs += 'O Caixa de Origem não pode ser igual o Caixa de Destino; </br>';
            }
        }
        if (this.tpQuitacaoSelecionado.tqui_descricao === 'CHEQUE'){
                msgs += 'O Tipo de Documento Cheque não pode ser usado no momento, aguarde atualizações futuras; </br>';                       
        }        
        if (msgs !== ''){
            this.isValidated = await false
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Incluir / Alterar o Registro',
                html: msgs,
                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
            })        
        }else{
            this.isValidated = await true
        }
    },
    setData () {
        if (this.modo == 'novo'){
            this.lista.transf_tipo = 1
            this.lista.transf_ent = this.Entidade
            this.lista.transf_data = new Date()
            this.lista.audit_data_cadastro = this.moment(new Date()).format('YYYY-MM-DD')
            this.lista.audit_usu_cadastro = this.infoLogado['usu_usuario'].toUpperCase()       
            this.lista.audit_local_cadastro = this.IP       
        } else if (this.modo == 'editar'){
            this.lista.audit_data_atualiza = this.moment(new Date()).format('YYYY-MM-DD')
            this.lista.audit_usu_atualiza = this.infoLogado['usu_usuario'].toUpperCase()       
            this.lista.audit_local_atualiza = this.IP
        }        
    },
    setTpQuitacao (data) {
        this.tpQuitacao = data
    },    
  },
  watch: {
    'lista.transf_tipo' : function (newVal, oldVal) {
        if (newVal == 1){
            this.lista.cxaori_codigo = null
            this.lista.cxades_codigo = null
        }else
        if (newVal == 2){
            this.lista.cxaori_codigo = null
            this.lista.cbncdes_codigo = null
        }else
        if (newVal == 3){
            this.lista.cbncori_codigo = null
            this.lista.cxades_codigo = null
        }else
        if (newVal == 3){
            this.lista.cbncori_codigo = null
            this.lista.cbncdes_codigo = null
        }
    },
    'lista.tqui_codigo' : function (newVal, oldVal) {
        let ret = this.tpQuitacao.filter(el => {
            return el.tqui_codigo == newVal;
        })
        if (typeof ret[0] !== 'undefined'){
            this.tpQuitacaoSelecionado = ret[0]
        }else{
            this.tpQuitacaoSelecionado = ''
        }
    }
  },
  computed: {
    ...mapState('Login',['infoLogado','IP','Entidade','Exercicio','Escola'])
  }, 
  created() {
  
  }     
}
</script>