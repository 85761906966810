<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" :titAlerta="`ERRO: ${status}`" :msgAlerta="message" :tipoAlerta="tipoAlerta" @setData="setData" @submit="submit" @onAntesGravar="onAntesGravar">
        <div slot="formulario" class="formulario">
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="usu_codigo" :valor="lista" titulo="Código" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-3" style="color: red!important; font-weight: bold;">
                    <mct-form-itens tipo="checkbox" campo="usu_cancelado" :valor="lista" titulo="Cancelado" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <!--<div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="select" campo="per_codigo" :valor="lista" titulo="Perfil" obrigatorio="false" origem="perfil" origemChave="per_codigo" origemRetorno='per_descricao' :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>-->
            <div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="text" campo="usu_nome" :valor="lista" titulo="Nome" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="email" campo="usu_email" :valor="lista" titulo="E-mail" obrigatorio="true" :errors="errors" :modo="modo" transforme="lower"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="text" campo="usu_usuario" :valor="lista" titulo="Usuário" obrigatorio="false" :errors="errors" :modo="modo" transforme="lower"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="select" campo="usu_funcao" :valor="lista" titulo="Função" obrigatorio="false" :origemData="funcao" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row" v-if="this.modo == 'novo' || this.infoLogado.usu_funcao === 1">
                <div class="col-sm-3">
                    <mct-form-itens coluna="0" tipo="password" campo="usu_senha" :valor="lista" titulo="Senha" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-3">
                    <mct-form-itens coluna="0" tipo="password" campo="usu_confirma_senha" :valor="lista" titulo="Confirma Senha" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="search" campo="pes_codigo" :valor="lista" titulo="Pessoas" obrigatorio="false" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' tamanho="2" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <mct-form-itens coluna="0" tipo="checkbox" campo="usu_nunca_expira" :valor="lista" titulo="Usuário Nunca Expira" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-3">
                    <mct-form-itens coluna="0" tipo="checkbox" campo="usu_mudar_senha" :valor="lista" titulo="Alterar Senha no Próximo Login" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <fieldset class="well">
                  <legend class="well-legend">Perfis de Usuário</legend>
                  <span class="text-danger">Selecione um ou mais Perfis</span>
                  <div class="row">
                    <div class="col-sm-12">
                      <multiselect v-model="lista.perfis" :options="listaPerfis" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
                        placeholder="Selecione os Perfis" selectedLabel="Selecionar opção" deselectLabel="Pressione Enter para remover" selectLabel="Pressione enter para selecionar"
                        label="per_descricao" track-by="per_codigo">
                      </multiselect>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="mct-foto">
                <div class="social">
                <!--<img src="../../../../static/img/avatars/5.jpg" alt="">-->
                <img :src="img" alt="">
                <div class="social-wrapper">
                    <div class="social-icon border border-light rounded-circle">
                        <input id="inputfile" type="file" @change="onFileChange" accept="image/*">
                        <a @click.prevent.stop="onCustomFileChange()" title="Obter Imagem de um Arquivo" aria-label="Obter Imagem de um Arquivo">
                            <span class="fa fa-upload" aria-hidden="true"></span>
                        </a>
                    </div>
                    <div class="social-icon border border-light rounded-circle">
                        <a @click.prevent.stop="onAbreCaptura()" title="Obter Imagem de uma Câmera de Vídeo" aria-label="Obter Imagem de uma Câmera de Vídeo" target="_blank">
                            <span class="fa fa-video-camera" aria-hidden="true"></span>
                        </a>
                    </div>
                    </div>
                </div>
            </div>
            <mct-modal id="Capture">
                <mct-capture :img.sync="img" :img2.sync="lista['usu_foto_img']"></mct-capture>
            </mct-modal>
        </div>
    </mct-padrao>
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'

import MctPadrao from '../comum/MctFormPadrao.vue'
import MctFormItens from '../comum/MctFormItens.vue'
import Multiselect from 'vue-multiselect'

import MctCapture from '@/components/comum/MctCapture.vue'
import MctModal from '@/components/comum/MctModalSHB.vue'

export default {
  Name: 'Usuarios',
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      Multiselect,
      MctCapture,
      MctModal
  },
  data () {
      return {
          lista: {},
          listaSec: {"usu_confirma_senha": ''},
          listaPerfis: [],
          errors:{},
          message: '',
          status: 0,
          tipoAlerta: '',
          ultreg: 0,
          submodulo: {},
          funcao: [{"value": "1", "text": "ADMINISTRADOR" }, {"value": "2", "text": "GERENTE"}, {"value": "4", "text": "FUNCIONÁRIO"}, {"value": "3", "text": "FINANCEIRO"}],
          showCapture: false,
          img: null,          
      }
  },
  methods: {
    async setData (lista) {
        this.lista = lista
        if (this.modo == 'novo'){
            this.lista.usu_cancelado = 0
            this.lista.usu_nunca_expira = 1
            this.lista.usu_mudar_senha = 0
            this.lista.usu_tipo = 0
            this.lista.ent_codigo = this.Entidade
            this.lista.audit_data_cadastro = this.moment(new Date()).format('YYYY-MM-DD')
            this.lista.audit_user_cadastro = this.infoLogado['usu_usuario'].toUpperCase()       
            this.lista.audit_local_cadastro = this.IP       
        } else if (this.modo == 'editar'){
            this.lista.audit_data_atualiza = this.moment(new Date()).format('YYYY-MM-DD')
            this.lista.audit_user_atualiza = this.infoLogado['usu_usuario'].toUpperCase()       
            this.lista.audit_local_atualiza = this.IP
        }
        if(this.lista.usu_foto !== null && this.lista.usu_foto !== ''){
            const URL = `/usuarios/image/${this.lista.usu_codigo}`;
            await axios.get(URL)
                .then(response => {                        
                    this.img = 'data:image/jpg;base64,'+response.data || semImg;
                })
                .catch(response => {
                  this.message = response.response.data.message
                  this.errors = response.response.data.errors
                  this.status = response.response.status
                })                        
        }
    }, 
    getUltReg (modulo, submodulo) {
        var vm = this
        axios.get(`menu/ultreg/`+modulo+`/`+submodulo)
        .then(function(response) {
            vm.$data.lista.menu_codigo = response.data[0].ultreg
        })
        .catch(function(response) {
            console.log(response)
        })
    },
    submit () {
        //alert('Submit')
    },
    onAntesGravar (valor) {
        alert('Antes Gravar')
        valor = true
        return valor
    },
    async getPerfis () {
        let URL = `/perfil/combo`
        await axios.get(`${URL}`)
            .then(response => {
                this.listaPerfis = response.data.data
            })
            .catch(response => {

            })
    },
    onAbreCaptura () {
        this.showCapture = true
        $('#MctModalCapture').modal('show')        
    },
    onFileChange(e) {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length)
            return
        this.createImage(files[0])
    },
    createImage(file) {
        let reader = new FileReader()
        reader.onload = (e) => {
            this.img = e.target.result
            this.lista['usu_foto_img'] = e.target.result
        };
        reader.readAsDataURL(file)
    },   
    onCustomFileChange () {
        var link = document.getElementById('inputfile');
        link.click();
    }          
  },
  computed: {
    ...mapState('Login',['infoLogado','Entidade','IP']),
  },
  created() {
    this.getPerfis();
  },
  watch: {

  }
}
</script>

<style scoped>
    .formulario{
        position: relative;
    }

    .mct-foto{
        position: absolute;
        top: 10px;
        right: 10px;
    }

    #inputfile {
	    display: none;
    }
</style>