<template>
    <!--<span :title="isLoading"></span>-->
    <div id="test" class="mct-overlay" v-if="isLoading">
        <div class="mct-info">
            <img src="/src/assets/images/Mercato_W224_branco.png"/>
            <p class="loading-message">Aguarde!</p>
            <div class="sk-spinner sk-spinner-wave"><div class="sk-rect1"></div><div class="sk-rect2"></div><div class="sk-rect3"></div><div class="sk-rect4"></div><div class="sk-rect5"></div></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MctLoading',
    props: ['isLoading'],
    data () {
        return {
        pleaseWaitInstance: null
        }
    },
    mounted () {
        this.updatePleaseWait()
    },
    beforeUpdate () {
        this.updatePleaseWait()
    },
    methods: {
        updatePleaseWait () {
        /*if (this.isLoading && this.pleaseWaitInstance == null) {
            this.pleaseWaitInstance = pleaseWait({
            logo: '/src/assets/images/Mercato_W224_branco.png',
            backgroundColor: '#379A7B',
            loadingHtml: '<p class="loading-message">Aguarde!</p><div class="sk-spinner sk-spinner-wave"><div class="sk-rect1"></div><div class="sk-rect2"></div><div class="sk-rect3"></div><div class="sk-rect4"></div><div class="sk-rect5"></div></div>'
            })
        }
        if (!this.isLoading && this.pleaseWaitInstance != null) {
            this.pleaseWaitInstance.finish()
        }*/
        }
    }

}
</script>
<style scoped>
    .mct-overlay {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: rgba(3, 3, 3, 0.5);
        z-index: 1000;
        /*background-image: url(https://i.stack.imgur.com/BNGOI.gif);*/
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100px;
    }

    .mct-info {
        position: absolute;
        height: 220px;
        width: 350px;
        background-color: #379A7B;
        border-radius: 5px; 
        color: #FFF;
        overflow: hidden;
        padding: 10px;
        align-items: center;
        text-align: center;
        font-weight: bold;
        justify-content: space-around;
        top:50%;
        left:50%;              
        margin-top:-110px;
        margin-left:-175px;
        border: 2px solid #047a55;
    }

    .mct-info img{
        max-height: 90px;
    }

    .mct-info p{
        font-size: 18px;
        font-family: 'Glegoo', Helvetica, Arial, Verdana, sans-serif;
        padding: 5px;
    }

</style>
