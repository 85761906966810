<template>
<!-- RELATORIOS CONTAS A PAGAR - A PAGAR -->
    <div>
        
        <div class="row mb-4">
            <div class="col-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Turma</legend>
                    <div class="row">                                                        
                        <div class="col-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_ini" :valor="filtro" titulo="Inicial" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_ini" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_fin" :valor="filtro" titulo="Final" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_fin" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>            
        </div>
        <div class="row mb-4">
            <div class="col-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Aluno</legend>
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="aluno" :valor="filtro" titulo="Aluno" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="aluno" :valor="filtro" titulo=" " origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>            
        </div>
        <div class="row mb-4">
            <div class="col-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Modelo</legend>
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <mct-form-itens tipo="radio" campo="modelo" :valor="filtro" titulo="Modelo" showTitulo="false" :errors="{}" 
                            :origemData="[{value:1,text:'Modelo 01'}, {value:2,text:'Modelo 02'}]" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>            
        </div>
        <div class="row mb-2">
            <div class="col-sm-12 text-left">
                <button type="button" class="btn btn-secondary mr-2" aria-label="Left Align" @click="onBoletim()">
                <span class="fa fa-refresh fa" aria-hidden="true"></span> Visualizar</button>     
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/http'
  
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios'

export default {
    components: {
        'MctFormItens': MctFormItens,
    },
    mixins: [MctPadraoRelatorios],
    data() {
        return {
            data: [],
            outputPdf: null,
            filtro: {
                turma_ini: null,
                turma_fin: null,
                agrupa_agendamento: null,
                situacao_agendamento: 9,
                agendamento: null,
                assinatura: 0,
                etap_codigo: null,
                aluno: null,
                modelo: 1,
            }
        }
    },
    methods: {
        async getData() {
            var url = '/boletim';

            await axios.get(`/relatorios/siges${url}`,{params:  {
                turmaini: this.filtro.turma_ini,
                turmafin: this.filtro.turma_fin,
                agrupa_agendamento: this.filtro.agrupa_agendamento,
                situacao_agendamento: this.filtro.situacao_agendamento,
                agendamento: this.filtro.agendamento,
                etap_codigo: this.filtro.etap_codigo,
                aluno: this.filtro.aluno,
                tipo: this.filtro.tipo
            },
            paramsSerializer: function (params) {
                return jQuery.param(params)
            }
            })
            .then(response => {
                this.data = response.data.data                    
            })
            .catch(response => {
                console.log(response.message)
            })
            
        },     

        async onTopoBoletim(index) {
            this.pdf.setFontSize(7.5)
            this.pdf.setFont('helvetica','normal')
            
            this.pdf.rect(8,this.lin-3,130,10)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Nome Aluno(a):',10,this.lin+1)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].pes_nome),32,this.lin+1) 

            this.pdf.rect(138,this.lin-3,this.widthPage-130,10)         
            this.pdf.setFontSize(12)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text(String('Boletim Escolar Ano: '+this.data[index].matri_ano),140,this.lin+3)

            this.onAddLine(this.jump+2)

            this.pdf.setFontSize(7.5)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('R.A.:',10,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].matri_codigo),32,this.lin) 
            
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Mãe:',43,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].pes_mae ?? ''),50,this.lin) 

            this.onAddLine(this.jump+2)

            this.pdf.rect(8,this.lin-3,this.widthPage,5)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Curso:',10,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].cur_nome),26,this.lin) 

            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Ano Letivo:',130,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].matri_ano),147,this.lin) 

            this.onAddLine(this.jump+2)

            this.pdf.rect(8,this.lin-3,this.widthPage,5)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Série:',10,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].ser_descricao),26,this.lin) 

            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Turma:',60,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].turm_turma),73,this.lin) 

            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Identificação:',93,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].turm_identificacao),116,this.lin) 

            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Turno:',148,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].turn_descricao),163,this.lin)
        },

        async onBoletim () {

            await this.getData();

            await this.onCreatePDF('P')
            /*CABEÇALHO*/
            this.title = '';
            this.semData = true;
            this.semPagina = true;
            this.onHeaderPDF();
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)    
                    
            var grupo = '';

            for (var index in this.data) {
                if (grupo !== this.data[index].cur_codigo+'|'+this.data[index].turm_codigo+'|'+this.data[index].matri_alu_codigo){
                    if (grupo !== '')
                    this.onSaltarPagina2();
                    grupo = this.data[index].cur_codigo+'|'+this.data[index].turm_codigo+'|'+this.data[index].matri_alu_codigo
                    
                    await this.onTopoBoletim(index);

                    this.onAddLine(this.jump+4);
                    this.pdf.rect(8,this.lin-3,40,10);
                    this.pdf.text('Componentes Curriculares',10,this.lin+3);

                    this.pdf.rect(48,(this.lin-3),this.widthPage-40,4);
                    this.pdf.text('Nível de Desempenho dos Alunos por Bimestre',this.widthPage-70,this.lin,{align: 'center'});

                    this.onAddLine(this.jump+1);

                    if (this.filtro.modelo === 1 ){
                        for (var i=0;i<=3;i++){
                            this.pdf.rect((48+(28*i)),(this.lin-3),28,6);
                            this.pdf.setFont('helvetica','bold') 
                            this.pdf.setFontSize(7.5)
                            this.pdf.text((i+1)+'°. BIM.',62+(28*i),this.lin,{align: 'center'});
                            this.pdf.setFontSize(6)
                            this.pdf.text('NOTA',55+(28*i),this.lin+2,{align: 'center'});
                            this.pdf.text('F',69+(28*i),this.lin+2,{align: 'center'});
                        }
    
                        this.pdf.rect(160,(this.lin-3),17,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('MEDIA',168.5,this.lin,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('FINAL',168.5,this.lin+2,{align: 'center'});                    
    
                        this.pdf.rect(177,(this.lin-3),this.widthPage-169,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('RESULTADO',177+((this.widthPage-169)/2),this.lin,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('FINAL',177+((this.widthPage-169)/2),this.lin+2,{align: 'center'});
                        
                        this.onAddLine(this.jump+3);
                        
                        this.data[index].notas.map((item,index) => {
    
                            this.pdf.setFont('helvetica','bold') 
                            this.pdf.setFontSize(7)
                            this.pdf.rect(8,this.lin-3,40,5);
                            this.pdf.text(item.disp_nome,10,this.lin);
    
                            for (var i=0;i<=3;i++){
                                this.pdf.rect((48+(28*i)),(this.lin-3),28,5);
                                this.pdf.setFont('helvetica','normal') 
                                this.pdf.setFontSize(7)
                                this.pdf.text(String(this.$options.filters.moeda(item['media_eta_'+(i+1)],2)),55+(28*i),this.lin,{align: 'center'});
                                this.pdf.text(String(item['faltas_eta_'+(i+1)]),69+(28*i),this.lin,{align: 'center'});
                            }
    
                            this.pdf.rect(160,(this.lin-3),17,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.media_final,2)),168.5,this.lin,{align: 'center'});
    
                            this.pdf.rect(177,(this.lin-3),this.widthPage-169,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(item.situacao,177+((this.widthPage-169)/2),this.lin,{align: 'center'});
    
                            this.onAddLine(this.jump+2);
                        })
                    } else if (this.filtro.modelo === 2 ){

                        this.pdf.rect(48,(this.lin-3),15,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('1°. BIM.',55.5,this.lin-0.5,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('NOTA',51.75,this.lin+2,{align: 'center'});
                        this.pdf.text('F',59.25,this.lin+2,{align: 'center'});
                        
                        this.pdf.rect(63,(this.lin-3),15,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('2°. BIM.',70.5,this.lin-0.5,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('NOTA',66.75,this.lin+2,{align: 'center'});
                        this.pdf.text('F',74.25,this.lin+2,{align: 'center'});

                        this.pdf.rect(78,(this.lin-3),12,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('MD. 1°.',84,this.lin-0.5,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('SEM.',84,this.lin+2,{align: 'center'});

                        this.pdf.rect(90,(this.lin-3),12,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('REC. 1°.',96,this.lin-0.5,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('SEM.',96,this.lin+2,{align: 'center'});

                        this.pdf.rect(102,(this.lin-3),12,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('MD. FIN.',108,this.lin-0.5,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('1°. SEM.',108,this.lin+2,{align: 'center'});
                        
                        this.pdf.rect(114,(this.lin-3),15,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('3°. BIM.',121.5,this.lin-0.5,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('NOTA',117.75,this.lin+2,{align: 'center'});
                        this.pdf.text('F',125.25,this.lin+2,{align: 'center'});
                        
                        this.pdf.rect(129,(this.lin-3),15,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('4°. BIM.',136.5,this.lin-0.5,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('NOTA',132.75,this.lin+2,{align: 'center'});
                        this.pdf.text('F',140.25,this.lin+2,{align: 'center'});

                        this.pdf.rect(144,(this.lin-3),12,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('MD. 2°.',150,this.lin-0.5,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('SEM.',150,this.lin+2,{align: 'center'});

                        this.pdf.rect(156,(this.lin-3),12,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('REC. 2°.',162,this.lin-0.5,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('SEM.',162,this.lin+2,{align: 'center'});

                        this.pdf.rect(168,(this.lin-3),12,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('MD. FIN.',174,this.lin-0.5,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('2°. SEM.',174,this.lin+2,{align: 'center'});

                        this.pdf.rect(180,(this.lin-3),12,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('PROVA',186,this.lin-0.5,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('FINAL',186,this.lin+2,{align: 'center'});

                        this.pdf.rect(192,(this.lin-3),11,6);
                        this.pdf.setFont('helvetica','bold') 
                        this.pdf.setFontSize(7.5)
                        this.pdf.text('MEDIA',197.5,this.lin-0.5,{align: 'center'});
                        this.pdf.setFontSize(6)
                        this.pdf.text('FINAL',197.5,this.lin+2,{align: 'center'});

                        this.onAddLine(this.jump+3);
                        
                        this.data[index].notas.map((item,index) => {
    
                            this.pdf.setFont('helvetica','bold') 
                            this.pdf.setFontSize(7)
                            this.pdf.rect(8,this.lin-3,40,5);
                            this.pdf.text(item.disp_nome,10,this.lin);

                            this.pdf.rect(48,(this.lin-3),15,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.media_eta_1,2)),51.75,this.lin,{align: 'center'});
                            this.pdf.text(String(item.faltas_eta_1),59.25,this.lin,{align: 'center'});
                            
                            this.pdf.rect(63,(this.lin-3),15,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.media_eta_2,2)),66.75,this.lin,{align: 'center'});
                            this.pdf.text(String(item.faltas_eta_2),74.25,this.lin,{align: 'center'});

                            this.pdf.rect(78,(this.lin-3),12,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.media_sem_1,2)),84,this.lin,{align: 'center'});

                            this.pdf.rect(90,(this.lin-3),12,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.rec_sem_1,2)),96,this.lin,{align: 'center'});

                            this.pdf.rect(102,(this.lin-3),12,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.media_fin_sem_1,2)),108,this.lin,{align: 'center'});
                            
                            this.pdf.rect(114,(this.lin-3),15,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.media_eta_3,2)),117.75,this.lin,{align: 'center'});
                            this.pdf.text(String(item.faltas_eta_3),125.25,this.lin,{align: 'center'});
                            
                            this.pdf.rect(129,(this.lin-3),15,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.media_eta_4,2)),132.75,this.lin,{align: 'center'});
                            this.pdf.text(String(item.faltas_eta_4),140.25,this.lin,{align: 'center'});

                            this.pdf.rect(144,(this.lin-3),12,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.media_sem_2,2)),150,this.lin,{align: 'center'});

                            this.pdf.rect(156,(this.lin-3),12,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.rec_sem_2,2)),162,this.lin,{align: 'center'});

                            this.pdf.rect(168,(this.lin-3),12,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.media_fin_sem_2,2)),174,this.lin,{align: 'center'});

                            this.pdf.rect(180,(this.lin-3),12,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.prova_final,2)),186,this.lin,{align: 'center'});

                            this.pdf.rect(192,(this.lin-3),11,5);
                            this.pdf.setFont('helvetica','normal') 
                            this.pdf.setFontSize(7)
                            this.pdf.text(String(this.$options.filters.moeda(item.media_final,2)),197.5,this.lin,{align: 'center'});

                            this.onAddLine(this.jump+2);
                        });

                    }

                }
            }
                                               
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            const urlPDF = this.pdf.output('blob');
            this.outputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
            this.$emit('update:outputPdf', this.outputPdf);
        },
    }

}
</script>