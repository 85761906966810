<template>
  <div class="mct-form">
      <mct-topo-lista titulo="Relatórios" subtitulo="Módulo Contabil" v-show="!showModal"></mct-topo-lista>
      <div class="row">
          <div class="col-4">
              <ul class="list-group list-group-flush h-100">                
                  <li class="list-group-item px-0">
                      <a class="btn collapsed" data-toggle="collapse" href="#collapseExample3" role="button" aria-expanded="true" aria-controls="collapseExample3">
                      Contábil<span class="mr-3"></span>
                      </a>
                      <div class="collapse" id="collapseExample3">
                      <div class="card card-body p-2 mb-0">
                          <button class="btn btn-secondary" @click="onRelatorio(1)">Movimento Diário - Contábil</button>
                      </div>
                      </div>
                  </li>                
              </ul>                
          </div>

          <div class="col-8">
              <div class="list-group p-2 h-100">
                  <pnl-contabil-mov-diario v-if="relatorio === 1" :outputPdf.sync="outputPdf"/>
              </div>
          </div>
      </div>

      <div class="row">
          <div class="col-12 p-4">
              <PDFView :url="outputPdf === null ? '' : outputPdf"/>
          </div>
      </div>    
  </div>
</template>
<script>
  import Vue from 'vue'
  import axios from '@/http'
  import moment from 'moment'
  
  import PDFView from '@/components/pdfviewer/PDFView.vue' 

  import MctTopoLista from '@/components/comum/MctTopoLista.vue'
  import MctFormItens from '@/components/comum/MctFormItens.vue'
  
  import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios'

  /**PAINEIS*/
  import pnlContabilMovDiario from "./panels/pnlContabilMovDiario.vue";

  export default {
    name: 'RelEscContabil',
    mixins: [MctPadraoRelatorios],
    components: {
        'PDFView': PDFView,
        'MctTopoLista': MctTopoLista,
        'MctFormItens': MctFormItens,

        pnlContabilMovDiario
    },    
    props: ['source', 'titulo', 'showModal'],
    data() {
      return {
        relatorio: 0,
        outputPdf: null,
      }
    }, 
    watch: {
        relatorio: function ( newVal, odlVal) {
            this.outputPdf = null
        }
    },   
    methods: {        
        onImprimirPDF() {
            printJS({printable: this.outputPdf, type: 'pdf'})
        },
        onRelatorio (tipo) {
            switch (tipo) {
                case 3:
                    this.relatorio = tipo
                    break;            
                default:
                    this.relatorio = tipo
                    break;
            }
        },             
    },
  }
</script>

<style scoped>

.list-group {
    border: 1px solid #c8ced3;
    border-top: 4px solid #60C060;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.btn {
  box-shadow: none !important;
  outline: 0;
}

.list-group-item a {
    font-size: 14px;    
}

.list-group-item a:link,
.list-group-item a:visited {
  color: #222;
}

.list-group-item a:hover,
.list-group-item a:focus {
  color: #5DA287;
  font-weight: 500;
}

.list-group-item span {
  border: solid #222;
  border-width: 0 1px 1px 0;
  display: inline;
  cursor: pointer;
  padding: 3px;
  position: absolute;
  right: 0;
  margin-top: 10px;
}

.list-group-item a.btn.collapsed span {
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  transition: .3s transform ease-in-out;
}

.list-group-item a.btn span {
  transform: rotate(-140deg);
  -webkit-transform: rotate(-140deg);
  transition: .3s transform ease-in-out;
}

.card {
    border: 0;
}

</style>