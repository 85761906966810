<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <div class="row">
                <div class="col-sm-3">
                    <mct-form-itens coluna="0" tipo="number" campo="plnc_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
                <div class="col-sm-3">
                    <mct-form-itens coluna="0" tipo="text" campo="plnc_numero" :valor="lista" titulo="Número" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
                <div class="col-lg-3">
                    <mct-form-itens tipo="checkbox" campo="plnc_escrituracao" :valor="lista" titulo="Escrituração" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-lg-4">
                    <mct-form-itens tipo="checkbox" campo="plnc_status" :valor="lista" :titulo="lista.plnc_status == 1 ? 'Cadastro Ativo.' : 'Cadastro Inativado.'" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <mct-form-itens tipo="select" campo="plnc_tipo_saldo" :valor="lista" titulo="Tipo de Saldo" 
                    :origemData="[{value: 'C', text: 'CREDORA'},{value: 'D', text: 'DEVEDORA'},{value: 'A', text: 'AMBOS'}]"
                    obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-lg-4">
                    <mct-form-itens tipo="select" campo="plnc_tipo_conta" :valor="lista" titulo="Tipo de Conta" 
                    :origemData="[{value: 'A', text: 'ANALÍTICA'},{value: 'S', text: 'SINTÉTICA'}]"
                    obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-lg-3">
                    <mct-form-itens tipo="select" campo="plnc_grau" :valor="lista" titulo="Tipo de Conta" 
                        :origemData="[{value: 1, text: 'GRAU 1'},{value: 2, text: 'GRAU 2'},{value: 3, text: 'GRAU 3'},{value: 4, text: 'GRAU 4'},{value: 5, text: 'GRAU 5'}]" 
                        obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-10">
                    <mct-form-itens coluna="0" tipo="textarea" campo="plnc_descricao" :valor="lista" titulo="Descrição" obrigatorio="true" linhas="3" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
            </div>
            <div class="row">
                <div class="col-sm-10">
                    <mct-form-itens tipo="select" campo="plncpai_codigo" :valor="lista" titulo="Conta Pai" obrigatorio="false" origem="esc_plncontas" 
                        origemChave="plnc_codigo" origemRetorno='plnc_descricao' :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>
        </div>              
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'EscPnlContas',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0        
      }
  },
  methods: {
    setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            this.lista.plnc_status = 1 
            this.lista.plnc_escrituracao = 0 
            this.lista.plnc_tipo_saldo = 'A' 
            this.lista.plnc_tipo_conta = 'A' 
            this.lista.plnc_grau = 1 
        }
    },        
  },
  computed: {
        ...mapState('Login',['Entidade','Exercicio','Escola'])       
  }, 
  created() {
  
  }     
}
</script>