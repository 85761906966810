<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <div class="row">
        <div class="col-sm-2">
          <mct-form-itens tipo="number" campo="turn_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <mct-form-itens tipo="radio" campo="turn_tipo" :valor="lista" titulo="Tipo" obrigatorio="true" :errors="errors"
            :origemData="[{value: 0, text: 'DIURNO'}, {value: 1, text: 'MATUTINO'}, {value: 2, text: 'VESPERTINO'},{value: 3, text: 'NOTURNO'},{value: 4, text: 'OUTRO'}]" :modo="modo" tamanho="12"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <mct-form-itens tipo="text" campo="turn_descricao" :valor="lista" titulo="Turno" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2">
          <mct-form-itens tipo="text" campo="turn_turno" :valor="lista" titulo="Abreviatura"
            obs="O valor do campo SIGLA é o que será apresentado nas busca onde o tamanho do campo não possa ultrapassar 5 caracteres." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-lg-2">
          <mct-form-itens tipo="text" campo="turn_abreviatura" :valor="lista" titulo="Sigla" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-lg-2">
          <mct-form-itens tipo="number" campo="turn_aulas_diaria" :valor="lista" titulo="Aulas Diárias" :errors="errors" :modo="modo" ></mct-form-itens>
        </div>
        <div class="col-lg-2">
          <mct-form-itens tipo="number" campo="turn_duracao_aula" :valor="lista" titulo="Duração das Aulas" :errors="errors" :modo="modo" ></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <fieldset class="well">
            <legend class="well-legend">Horário(s) da(s) Aula(s)</legend>
            <table class="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr>
                  <th style="width: 40px;">Ação</th>
                  <th>
                    <span>Aula</span>
                  </th>
                  <th>
                    <span>Início</span>
                  </th>
                  <th>
                    <span>Fim</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(aula,index) in lista.horarios" :key="index" :class="[{editing: aula == linhaEditada}]" v-cloak v-on:dblclick="editAulaTurno(aula,index)">
                  <td>
                    <i class="fa fa-pencil text-white mct-fa bg-primary mr-1" aria-hidden="true" title="Editar Registro" @click="editAulaTurno(aula,index)"></i>
                  </td>
                  <td>
                    <div class="view" readonly style="width: 40px;">
                      {{aula.turnhor_aula}}
                    </div>
                    <div class="edit" style="width: 40px;">
                      {{aula.turnhor_aula}}
                    </div>
                  </td>
                  <td>
                    <div class="view" style="width: 120px;">
                      {{aula.turnhor_hora_ini}}
                    </div>
                    <div class="edit" style="width: 120px;">
                      <mct-form-itens tipo="text" mascara="hora" campo="turnhor_hora_ini" :valor="aula" :errors="errors" obrigatorio="true" :modo="modo"></mct-form-itens>
                    </div>
                  </td>
                  <td>
                    <div class="view" style="width:120px;">
                      {{aula.turnhor_hora_fin}}
                    </div>
                    <div class="edit" style="width:120px;">
                      <mct-form-itens tipo="text" mascara="hora" campo="turnhor_hora_fin" :valor="aula" :errors="errors" obrigatorio="true" :modo="modo"></mct-form-itens>
                    </div>
                  </td>
                </tr>
                <tr v-if="typeof lista !== 'undefined' && typeof lista.horarios !== 'undefined' && lista.horarios.length == 0">
                  <td colspan="4" class="text-info" style="text-align: center; font-size: 1rem; line-height: 1.2rem;">
                    O FILTRO NÃO RETORNOU REGISTROS DE AULAS, VERIFIQUE SE NO TURNO O VALOR DO CAMPO "AULAS DIÁRIA" ESTÁ MAIOR QUE 0 (ZERO).
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>
      </div>
    </div>
  </mct-padrao>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'Turnos',
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
        linhaEditada: null,
      }
  },
  methods: {
    setData (lista, errors, lastCode) {
        this.lista = lista
        if (this.modo === 'novo'){
            this.lista.turn_ent = this.Entidade
            this.lista.turn_esc = this.Escola
            this.lista.turn_ano = this.Exercicio
        }
        this.errors = errors
    },
    editAulaTurno(linha,id) {
      if (this.linhaEditada === linha){
        this.linhaEditada = null
      }else{
        this.linhaEditada = linha
        this.linhaEditada['id'] = id
      }
    },
  },
   computed: {
    ...mapState('Login',['infoLogado','Entidade', 'Exercicio', 'Escola']),
  },
  created() {

  },
  watch: {
      'lista.turn_aulas_diaria': function (newVal, oldVal){
        if (typeof newVal != 'undefined' && newVal != null) {
            for (let i = this.lista.horarios.length; i < Number(newVal); i++) {
                this.lista.horarios.push({turn_codigo: this.lista.turn_codigo, turn_ent: this.Entidade, turn_esc: this.Escola, turn_ano: this.Exercicio, turnhor_hora_ini:'', turnhor_hora_fin:'', turnhor_aula: i+1})
            }

            //SE FOR MAIOR
            for (let i = 0; i < Number(this.lista.horarios.length); i++) {
              if (i+1 > newVal)
                this.lista.horarios.splice(i,1);
            }
        }
      }
  },
}
</script>

<style scoped>
    [v-cloak] {
        display: none;
    }
    .edit {
        display: none;
    }
    .editing .edit {
        display: block
    }
    .editing .view {
        display: none;
    }

    .is-icon{
        max-width: 65px;
    }

    .is-excluido{
        color: #C82333;
        background-color: #e39199!important;
        text-decoration: line-through;
    }
</style>
