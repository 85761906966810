<template>
    <mct-lista-padrao ref="childComponent" :source="source" :titulo="titulo" :showModal="showModal" @setData="setData">
        <mct-grid-lista id="cpag_codigo" :columns="colunas" :data="data" :query="query" :source="source" @toggleOrder="toggleOrder" :showModal="showModal" @setDescricao="setDescricao">        
        </mct-grid-lista>
    </mct-lista-padrao>      
</template>
<script>

import Vue from 'vue'
import MctListaPadrao from '@/components/comum/MctDataViewer.vue'
import MctGridLista from '@/components/comum/MctGridLista.vue'

export default {
    name: 'EscPagarLista',
    components: {
        MctListaPadrao,
        MctGridLista
    },
    props: ['source', 'titulo', 'showModal'],
    data (){
        return {
            data: {},
            columns: {},
            query: {},
            colunas: [
                {nome: 'cpag_numero', titulo: 'NÚMERO', classe: '1', tamanhoCol: '70px', filtro: true},
                {nome: 'cpag_cancelado', titulo: 'CANC.', tipo:'checkbox', classe: '1', tamanhoCol: '40px'},
                {nome: 'pes_nome', titulo: 'NOME / RAZÃO SOCIAL', classe: '4',filtro: true, subtab: 'pessoa'},
                {nome: 'cpag_emissao', titulo: 'EMISSÃO', tipo: 'date', classe: '', tamanhoCol: '100px'},
                {nome: 'cpag_valor', titulo: 'VALOR', tipo: 'currency', classe: '', tamanhoCol: '100px'},
            ]
        }
    },
    methods: {
        toggleOrder(ev, column) {
            ev.preventDefault()
            this.$refs.childComponent.toggleOrder(column);
        },
        setDescricao (ev, data ){
            ev.preventDefault()
            this.$emit('setDescricao', data)
        },
        setData (data, columns, query){
            this.data = data
            this.columns = columns
            this.query = query
        },
        redirect (ev, link) {
            ev.preventDefault();
            
            this.$router.push(link)
        },        
        getIndexes (row) {
            var indexes = ''
            if (typeof this.id === 'object'){
               for (var index in this.id) {               
                    if (indexes === ''){
                        indexes = row[this.id[0]]
                    } else {    
                        indexes = indexes + '/' + row[this.id[index]]
                    }
               }
            } else {
                indexes = row[this.id]   
            }
            return indexes
        },
        onFilter (campo){
            //console.log(campo) 
            this.$emit('getData')               
        }

    }
}
</script>