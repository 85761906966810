<template>
    <div class="form-group form-group-sm mb-0" v-show="visivel != 'false' && visivel != false" :style="tipo == 'textarea' || tipo == 'image' ? 'max-height: none!important;' : ''">

        <label v-if="titulo && (typeof showTitulo === 'undefined' || (typeof showTitulo !== 'undefined' && showTitulo === true) )" :for="campo" class="mct-label mb-0" :style="[coluna > 0 ? `min-width: ${coluna}px; max-width: ${coluna}px;` : '', tipo == 'textarea' || tipo == 'image' ? 'max-height: none!important;' : '']">{{ titulo }}
            <small class="mct-obg" v-if="obrigatorio == 'true' || obrigatorio == true"> * </small>
            <i style="font-size: 14px;" class="fa fa-info-circle text-info" aria-hidden="true" v-if="typeof obs != 'undefined' && obs !== '' && obs !== null"                 
                data-toggle="tooltip" data-placement="right" :title="obs"></i>
        </label>        
        
        <div :id="'ig_'+campo" class="input-group mt-0">
            <!-- TEXT / NUMBER / DATE -->
            <input v-if="tipo in {'text':1, 'number':1, 'password':1, 'email':1, 'url':1} && !mascara" :type="tipo == 'colorpicker' ? 'text' : tipo" :id="campo" :placeholder="subtitulo != null ? subtitulo : titulo" 
                class="form-control"  
                v-model="valor[campo]"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"     
                :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"
                :tabindex="tabindex"
                :style="tipo === 'number' ? 'text-align: right' : typeof alinhamentoTexto != 'undefined' ? `text-align: ${alinhamentoTexto} ` : ''"
                @input="forceUppercase($event, valor, `${campo}`, `${typeof transforme !== 'undefined' ? transforme : 'upper'}`,tipo)"
                @keyup.enter="onKeyUp()"
                @blur="onChange($event)"                
                > 
            <!-- DATETIME-PICKER -->
            <b-form-input 
                v-else-if="tipo == 'date' && !mascara"
                :placeholder="subtitulo != null ? subtitulo : titulo"
                v-model="valor[campo]" 
                :name="campo"
                type="date"
                :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                @change="onChange($event)"
            ></b-form-input>

            <!-- DATETIME-PICKER -->
            <datepicker v-else-if="tipo == 'datepicker' && !mascara"
                :placeholder="subtitulo != null ? subtitulo : titulo"
                v-model="valor[campo]" 
                :name="campo"
                :input-attr="{required: obrigatorio == 'true' || obrigatorio == true ? true : false, id: campo, tabindex: tabindex}"
                :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                lang='pt-br'
                type="date"
                :input-class="['form-control', obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"
                :class="[{'is-invalid': errors[campo]}]"
                value-type="YYYY-MM-DD" format="DD/MM/YYYY"
                v-bind="$attrs"
            ></datepicker>

           <!-- CURRENCY -->
            <currency-input
                v-else-if="tipo in {'currency':1}" 
                :name="campo" 
                :placeholder="subtitulo != null ? subtitulo : titulo"  
                class="form-control"  
                v-model="valueNumber"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"     
                :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"
                :tabindex="tabindex"
                style="text-align: right"                
                autoDecimalDigits
                :precision="!isNaN(qtdDigitos) && Number(qtdDigitos) > 0 ? Number(qtdDigitos) : 2"
                :currency="showCurrencySymbol === false ? null : 'BRL'"
            />
                
            <!-- HORA EXTRA -->
            <input v-else-if="tipo in {'horaextra':1}" type="text" :id="campo" :placeholder="subtitulo != null ? subtitulo : titulo"  
                class="form-control"  
                v-model="horaextra" 
                v-hora="hora3casas"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'border-red' : 'border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"     
                :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"
                v-mask="mask"
                :tabindex="tabindex"
                @input="unformatHoraExtra($event, horaextra)"
                >

            <!-- TEXT / NUMBER / DATE -->
            <input v-else-if="tipo in {'text':1, 'number':1, 'date':1}  && mascara" :type="tipo" :id="campo" :placeholder="subtitulo != null ? subtitulo : titulo"  
                class="form-control"  
                v-model="valor[campo]" 
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"     
                :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"
                v-mask="mask == null ? '' : mask"
                :tabindex="tabindex"
                @input="forceUppercase($event, valor, `${campo}`, `${typeof transforme !== 'undefined' ? transforme : 'upper'}`,tipo)"
                > 
            
            <!-- INTEGER -->   
            <input v-else-if="tipo in {'integer':1}" type="text" :id="campo" :placeholder="subtitulo != null ? subtitulo : titulo"  
                class="form-control"  
                v-model="valor[campo]" 
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"     
                :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"
                :tabindex="tabindex"
                oninput="this.value = this.value.replace(/[^0-9.]/g, ''); this.value = this.value.replace(/(\..*)\./g, '$1');"
                >  
            
            <!-- COLORPICKER -->
            <b-form-input v-else-if="tipo in {'colorpicker':1}" 
                type="color" 
                :id="campo" 
                :placeholder="subtitulo != null ? subtitulo : titulo"  
                class="form-control"  
                v-model="valor[campo]" 
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"     
                :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"
                :tabindex="tabindex"
                >   
            </b-form-input>                      

            <!-- TEXTAREA -->
            <textarea v-else-if="tipo == 'textarea'" :id="campo" :rows="linhas"
                class="form-control" 
                v-model="valor[campo]" 
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"     
                :readonly="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"        
                :tabindex="tabindex"
                :style="tipo == 'textarea' ? 'max-height: none!important;' : ''"
                @input="forceUppercase($event, valor, `${campo}`, `${typeof transforme !== 'undefined' ? transforme : 'upper'}`,tipo)"
                @keyup.enter="onKeyUp()"
                @keydown.enter="onKeyDown()"
                ></textarea>

            <!-- SELECT -->
            <v-select v-else-if="tipo == 'select' && dataCombo" :id="'smdt_'+campo+Math.random()" class="form-control m-0 p-0" :options="dataCombo" v-model="valor[campo]" 
                :reduce="opcao => opcao[origemChave ? origemChave : 'value']" :value="origemChave ? origemChave : 'value'" :label="origemRetornoObj ? origemChave : origemRetorno ? origemRetorno : 'text'"                
                placeholder="Selecione um Item"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"
                :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || modo === 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"  
                :tabindex="tabindex >= 0 ? tabindex : null"
                @input="onSelected"
                append-to-body
                >
                <span slot="no-options">Desculpe, não há itens correspondentes.</span>
                <template v-slot:selected-option="option">
                    {{ origemRetornoObj ? option[origemRetornoObj][origemRetorno ? origemRetorno : 'text'] : option[origemRetorno ? origemRetorno : 'text'] }}&nbsp;<small v-if="typeof origemRetornoSec != 'undefined' && origemRetornoSec !== null" class="text-muted" style="font-size: 13px !important;">[{{ option[origemRetornoSec] }}]</small>
                </template>
                <template v-slot:option="option">
                    {{ origemRetornoObj ? option[origemRetornoObj][origemRetorno ? origemRetorno : 'text'] : option[origemRetorno ? origemRetorno : 'text'] }}&nbsp;<small v-if="typeof origemRetornoSec != 'undefined' && origemRetornoSec !== null" class="text-success" style="font-size: 13px !important;">[{{ option[origemRetornoSec] }}]</small>
                </template>
            </v-select>

            <select v-else-if="tipo == 'select2' && dataCombo" class="form-control" :id="campo" :placeholder="titulo"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"     
                v-model="valor[campo]"
                :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"  
                :tabindex="tabindex"
                >
                <option value="" v-if="obrigatorio !== 'true' && obrigatorio !== true"></option>
                <option v-for="(registro,index) in dataCombo" :key="index" :value="origemChave ? registro[origemChave] : registro.value ? registro.value : index">{{ origemRetorno ? registro[origemRetorno] : registro.text }} <small v-if="typeof origemRetornoSec != 'undefined' && origemRetornoSec !== null" class="text-muted">[{{ registro[origemChave] }}]</small> </option>
            </select>

            <!-- SELECT - BUSCA BASE DADOS-->
            <v-select v-else-if="tipo == 'select' && !dataCombo" :id="'sm_'+campo" class="form-control m-0 p-0" :options="opcoes" v-model="valorSelect" 
                :reduce="opcao => opcao[origemChave]" :value="origemChave" label="mct_origem_retorno"                
                placeholder="Selecione um Item"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"
                :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || modo === 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"  
                :tabindex="tabindex >= 0 ? tabindex : null"
                @input="onSelected"
                >
                <span slot="no-options">Desculpe, não há itens correspondentes.</span>
                <template v-slot:selected-option="option">
                    {{ origemRetornoObj ? option[origemRetornoObj][origemRetorno] : option[origemRetorno] }}&nbsp;<small v-if="typeof origemRetornoSec != 'undefined' && origemRetornoSec !== null" class="text-muted ml-1" style="font-size: 13px !important;">[{{ option[origemRetornoSec] }}]</small>
                </template>
                <template v-slot:option="option">
                    {{ origemRetornoObj ? option[origemRetornoObj][origemRetorno] : option[origemRetorno] }}&nbsp;<small v-if="typeof origemRetornoSec != 'undefined' && origemRetornoSec !== null" class="text-success" style="font-size: 13px !important;">[{{ option[origemRetornoSec] }}]</small>
                </template>
            </v-select>
            <v-select v-else-if="tipo == 'select2' && !dataCombo" :id="'sm_'+campo" class="form-control m-0 p-0" :options="opcoes" v-model="valor" 
                :reduce="opcao => opcao[origemChave]" :value="origemChave" label="mct_origem_retorno"                
                placeholder="Selecione um Item"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"
                :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || modo === 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"  
                :tabindex="tabindex >= 0 ? tabindex : null"
                @input="onSelected"
                >
                <span slot="no-options">Desculpe, não há itens correspondentes.</span>
                <template v-slot:selected-option="option">
                    {{ origemRetornoObj ? option[origemRetornoObj][origemRetorno] : option[origemRetorno] }}&nbsp;<small v-if="typeof origemRetornoSec != 'undefined' && origemRetornoSec !== null" class="text-muted ml-1" style="font-size: 13px !important;">[{{ option[origemRetornoSec] }}]</small>
                </template>
                <template v-slot:option="option">
                    {{ origemRetornoObj ? option[origemRetornoObj][origemRetorno] : option[origemRetorno] }}&nbsp;<small v-if="typeof origemRetornoSec != 'undefined' && origemRetornoSec !== null" class="text-success" style="font-size: 13px !important;">[{{ option[origemRetornoSec] }}]</small>
                </template>
            </v-select>
            <!--<select v-if="tipo == 'select' && !dataCombo" class="selectpicker show-tick form-control" :id="'sm_'+campo" :placeholder="titulo" title="Selecione um Item"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"        
                v-model="valor[campo]"
                :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || modo === 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true"  
                :tabindex="tabindex"
                data-live-search="true" :ref="'sm_'+campo" data-style="mct-select" data-size="5" data-container="body"
                @click.prevent.stop="onReflesh()"
                @change="changeSelect"
                :data-show-subtext="typeof origemRetornoSec != 'undefined' && origemRetornoSec != null ? true : false"                             
                >
                <option value="" v-if="obrigatorio !== 'true' && obrigatorio !== true"></option>
                <option v-for="(registro,index) in opcoes" :key="index" :value="registro[origemChave]"
                    :data-subtext="typeof origemRetornoSec != 'undefined' && origemRetornoSec != null ? registro[origemRetornoSec] : ''">
                    <span v-if="origemRetorno.split(',') != -1"><span v-for="(reg,i) in origemRetorno.split(',')" :key="i"> {{ registro[reg] }} </span></span>
                    <span v-else> {{ registro[origemRetorno] }} </span>
                </option>
            </select>-->

            <!-- MULTI-SELECT - BUSCA BASE DADOS-->        
            <multiselect v-else-if="(tipo == 'selectmult') && !dataCombo" class="form-control" :id="campo"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"
                :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || modo === 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true" 
                :tabindex="Number(tabindex) ? Number(tabindex) : null"
                :multiple="tipo == 'selectmult'"
                :close-on-select="tipo == 'selectmult' ? false : true"
                :clear-on-select="tipo == 'selectmult' ? false : true"
                v-model="valor[campo]"
                :options="opcoes"
                :value="origemChave"
                :label="origemRetorno"
                :track-by="origemChave"
                :return="origemChave"

                placeholder="Adicionar um novo Item"
                select-label="Clique ou Enter para selecionar!"
                deselect-label="Clique ou Enter para remover."
                selected-label='Selecionado'
                v-bind="$attrs"
                >
            </multiselect>

            <!-- AUTOCOMPLETE -->
            <vue-bootstrap-typeahead
                v-else-if="tipo === 'autocomplete' && origemData"
                v-model="valor[campo]"
                :data="origemData"
                :serializer="item => item[campo]"
                @hit="setDataRetorno($event)"
                class="m-0 p-0"
                style="margin-top: 0px;"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"
                :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || modo === 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true" 
                :tabindex="Number(tabindex) ? Number(tabindex) : null"
                v-bind="$attrs"                
            />

            <!-- RADIO -->
            <div class="form-control" v-else-if="tipo === 'radio' && dataCombo"
                :class="[tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}, typeof titulo !== 'undefined' && titulo !== '' ? 'mct-radio' : '', (obrigatorio == 'true' || obrigatorio == true) &&  typeof titulo !== 'undefined' && titulo !== '' ? 'mct-border-red' : typeof titulo !== 'undefined' && titulo !== '' ? 'mct-border-seq' : '']"
                style="border: 0!important;"
                :required="obrigatorio == 'true' || obrigatorio == true"
                :tabindex="tabindex"
                >
                <div class="form-inline">
                    <label class="custom-control custom-radio align-middle" v-for="(registro,index) in dataCombo" :key="index">
                        <input :id="campo" :name="campo" type="radio" class="custom-control-input" :value="registro.value" v-model="valor[campo]" 
                        :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || modo === 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')">
                        <span class="custom-control-indicator"></span>
                        <span class="custom-control-description align-middle">{{ registro.text }}</span>
                    </label>        
                </div>
            </div>

            <!-- RADIOBUT -->        
            <div class="input-group input-group-sm h-100" v-else-if="tipo === 'radiobut' && dataCombo"
                :class="[tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': errors[campo]}]"
                style="border: 0!important;"            
                :required="obrigatorio == 'true' || obrigatorio == true"
                :tabindex="tabindex"
                >
                <label class="custom-control custom-radio align-middle" v-for="(registro,index) in dataCombo" :key="index">
                    <input :id="campo" :name="campo" type="radio" class="custom-control-input" :value="registro.value" v-model="valor[campo]" 
                    :disabled="modo === 'deletar' || modo === 'visualizar' || modo == 'readonly' || modo === 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description align-middle">{{ registro.text }}</span>
                </label>        
            </div>
            
            <!-- CHECKBOX -->
            <label v-else-if="tipo == 'checkbox'" class="custom-control custom-checkbox">
                <input  class="custom-control-input" type="checkbox" :name="campo" v-model="valor[campo]"
                v-bind:true-value="1"
                v-bind:false-value="0"
                :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                 @change="onChange($event)">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description align-middle">{{subtitulo?subtitulo:titulo}}</span>
            </label> 

            <!-- SWITCH -->
            <b-form-checkbox v-else-if="tipo == 'switch'"  
                value="1"
                unchecked-value="0"
                :name="campo" 
                v-model="valor[campo]" 
                switch
                :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                @change="onChange($event)"
                size="lg"
                :class="valor[campo] == 1 ? 'text-success' : 'text-danger'">
                {{valor[campo] == 1 ? 'Ativo' : 'Inativo'}}
            </b-form-checkbox>

            <!-- IMAGE -->
            <div class="form-control col-4" v-else-if="tipo in {'image':1}" :placeholder="titulo"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', {'is-invalid': errors[campo]}]"        
                :tabindex="tabindex"
                style="height: auto!important;display: inline;"
                >
                <div :id="`img_${campo}`" class="mct-img">
                    <img :src="img">
                </div>
                <div class="col-8">
                    <input class="mct-btn-img" type="file" @change="onFileChange" accept="image/*">
                </div>
            </div>
            
            <slot></slot>
            <!-- BUSCA MODAL -->
            <input type="text" class="form-control" :id="campo" :placeholder="titulo"
                :class="[obrigatorio == 'true' || obrigatorio == true ? 'mct-border-red' : 'mct-border-seq', tamanho > 0 ? `col-${tamanho}` : 'col-auto', {'is-invalid': typeof errors != 'undefined' && errors[campo]}]" 
                :style="typeof alinhamentoTexto != 'undefined' ? `text-align: ${alinhamentoTexto} ` : ''"
                v-model="valor[campo]"
                :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')"
                :required="obrigatorio == 'true' || obrigatorio == true" 
                v-if="tipo == 'search' || tipo == 'search-input'"
                @keyup.enter="getDescricao(valor[campo],origem,origemRetorno)"
                @blur="getDescricao(valor[campo],origem,origemRetorno)"
                :tabindex="tabindex"
            >
            <div class="input-group-append" v-if="tipo == 'search' || tipo == 'search-input'">
                <button class="btn btn-secondary input-group-text" type="button" id="btnPesquisa" 
                    @click="evtModal($event,2)" :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly' || modo == 'cancelar' || (typeof chave != 'undefined' && (chave == true || chave == 'true') && modo == 'editar')" 
                    tabindex="-1" alt="Abrir listagem."><i class="fa fa-search" aria-hidden="true"></i></button>
            </div> 
            

            <div class="input-group-append form-control col-auto"  v-if="tipo == 'search'" :id="`dsc${campo}`" tabindex="-1" readonly>
               {{ retornoDescricao }}
            </div> 

            <!-- BOTAO OPEN CADASTRO -->
            <!--&& (abrirCadastro == true || abrirCadastro == 'true')-->
            <div class="input-group-append" v-if="(tipo == 'select' || tipo == 'select2' || tipo == 'search') && !dataCombo && (modo == 'novo' || modo == 'editar') && ((typeof showBotoes !== 'undefined' && showBotoes === true))" @click="evtModal($event,1)">
                <a class="btn btn-sm btn-success input-group-text" id="btnAbrirCadastro" href="#" title="Adicionar Novo Cadastro."
                    tabindex="-1"
                ><i class="fa fa-plus" aria-hidden="true"></i></a>
            </div>

            <!-- REFRESH LISTAGEM -->
            <div class="input-group-append" v-if="(tipo == 'select' || tipo == 'select2') && !dataCombo && (modo == 'novo' || modo == 'editar') && (typeof showBotoes === 'undefined' || (typeof showBotoes !== 'undefined' && showBotoes === true))" @click.prevent.stop="getLista()">
                <a class="btn btn-sm btn-info input-group-text" id="btnRefreshLista" href="#" title="Atualizar Listagem."
                    tabindex="-1"
                ><i class="fa fa-refresh" aria-hidden="true"></i></a>
            </div>

            <!-- ICONE STATUS -->     
            <div class="input-group-append" v-if="tipo == 'text' && (mascara == 'cpf_cnpj' || mascara == 'cpf' || mascara == 'cnpj') && typeof valor[campo] != 'undefined' && valor[campo] != null && valor[campo] != 'null' && valor[campo] != ''">
               <i class="btn input-group-text fa fa-anim" :class="valCC(valor[campo]) ? 'fa-check text-success' : 'fa-times text-danger'" aria-hidden="true"></i>
            </div> 

            <!-- BOTAO OPEN URL / EMAIL -->
            <div class="input-group-append" v-if="(tipo == 'url' || tipo == 'email')">
                <a class="btn btn-light input-group-text" id="btnPesquisa" tabindex="-1" :href="tipo == 'url' ? valor[campo] : 'mailto:'+valor[campo]" target="_blank" title="Abrir endereço de Link em uma nova janela."><i class="fa" :class="tipo == 'url' ? 'fa-edge' : 'fa-envelope'" aria-hidden="true"></i></a>
            </div>

            <!--ICONES TIPOS DE CAMPOS E MASCARAS-->
            <div class="input-group-append" v-if="(tipo == 'currency') /*|| (tipo == 'date')*/ || (mascara == 'telefone') || (mascara == 'celular')">
                <span class="input-group-text" id="basic-addon2">
                    <i class="fa" :class="tipo == 'currency' ? 'fa-usd' : tipo == 'date' ? 'fa-calendar' : mascara == 'telefone' ? 'fa-phone' : mascara == 'celular' ? 'fa-mobile' : mascara == 'cep' ? 'fa-map-marker' : ''" aria-hidden="true"></i>
                </span>
            </div>

             <!-- BOTAO BUSCA CEP -->
            <div class="input-group-append" v-if="mascara == 'cep'">
                <a class="btn btn-light input-group-text" id="btnBuscaCep" tabindex="-1" href="#" title="Buscar Logradouro pelo Cep" @click.prevent.stop="onCep()"><i class="fa fa-map-marker" aria-hidden="true"></i></a>
            </div> 

            <!-- LEGENDA -->
            <slot name="legenda">
                
            </slot> 
            <div class="input-group-append" v-if="typeof legenda != 'undefined' && legenda != ''">
                <span class="input-group-text" style="font-size: 11px">{{legenda}}</span>
            </div>

            <!-- COLOR PICKER -->
            <!--<div class="input-group-append" v-if="tipo == 'colorpicker'">
                <span class="input-group-text"><i class="d-block" :style="'background-color:'+valor[campo]+'; width: 15px;'">&nbsp;</i></span>
            </div>-->
 

            <!-- OBRIGATORIO -->
            <div class="invalid-feedback" v-for="(item,idx) in errors[campo]" :key="idx">
                {{item}} 
            </div> 
        </div>    

        <!-- MODAL -->
        <slot name="modalcad">
        </slot>
        <mct-modal v-if="(tipo == 'search' || tipo == 'search-input') && this.telaLista" :id="modalId" :titulo="this.titulo ? 'Pesquisar: ' + this.titulo : this.telaListaTitulo ? 'Pesquisar: ' + this.telaListaTitulo : '' " size="lg">
            <component :is="loadC(this.telaLista)" :source="`${ this.origem.replace(/_/g, '')}/lista`" :titulo="this.titulo ? this.titulo : this.telaListaTitulo ? this.telaListaTitulo : '' " showModal="true" @setDescricao="setDescricao"></component>
        </mct-modal>
        <mct-modal v-else-if="tipo == 'search' || tipo == 'search-input'" :id="modalId" :titulo="this.titulo ? 'Pesquisar: ' + this.titulo : this.telaListaTitulo ? 'Pesquisar: ' + this.telaListaTitulo : '' " size="lg">
            <component :is="'MctDataViewer'" :source="`${ this.origem.replace(/_/g, '')}/lista`" :titulo="this.titulo ? this.titulo : this.telaListaTitulo ? this.telaListaTitulo : '' " showModal="true" @setDescricao="setDescricao"></component>
        </mct-modal>
        <mct-modal v-if="abrirCadastro == true" :id="`cad_${modalId}`" :titulo="'Cadastro: ' + this.titulo" hide-header hide-footer size="xl">                
            <component :is="loadC('comum/MctFormViewer')" :source="`${ this.origem.replace(/_/g, '')}/novo`" :titulo="this.titulo ? this.titulo : this.telaListaTitulo ? this.telaListaTitulo : '' " modo="novo" :modal="`modal_cad_${modalId}`"></component>
        </mct-modal>
    </div>   
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import http from '@/http2'

import moment from 'moment'
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/pt-br';

import MctModal from './MctModal.vue'
import MctDataViewer from './MctDataViewer.vue'
import MctFileUpload from './MctFileUpload.vue'

import { mapState } from 'vuex'
import helper from '../funcoes/ExisteImgUrl'
import ValidaCpfCnpj from '../funcoes/ValidaCpfCnpj.js'
import Multiselect from 'vue-multiselect'
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";

/*** COMPONENTES DE BUSCA ***/

import semImg from '@/assets/images/sem_foto.png'

const importJSONCSV = (filename) =>
    import("@/components/tabsInternas/"+filename);


export default {
    name: 'MctFormItens',
    props: ['coluna','linhas','tipo','campo','transforme','alinhamentoTexto','valor','valorTrue','valorFalse','titulo','subtitulo','tamanho','obrigatorio','modo','mascara',
            'origemAPI','origem','origemChave','origemRetorno', 'origemRetornoSec', 'origemRetornoObj', 'origemData','origemDataJson','origemDataCSV','origemLinha','origemChaveComposta', 'origemFiltro', 'gravaRetorno',
            'errors','tabindex','telaLista','telaListaTitulo','visivel','chave', 'abrirCadastro', 'legenda', 'obs', 'showBotoes', 'showTitulo', 'valorInteiro', 'qtdDigitos', 'showCurrencySymbol'],
    components: {
        MctModal,
        MctDataViewer,
        MctFileUpload,
        Multiselect,
        Datepicker,
        VueBootstrapTypeahead
        /*** COMPONENTES DE BUSCA ***/
    },
    data () {
        const valueType = {
            value2date: value => {
                const date = moment(value, "YYYY-MM-DD").toDate();
                return value ? moment(new Date(date), "YYYY-MM-DD").toDate() : null;
            },
            date2value: date => {
                return date ? moment(date).format("YYYY-MM-DD") : null;
            }
        };
        return {
            valueType,
            retornoDescricao: '',
            modalId: '1',
            opcoes: [],
            dataCombo: null,
            img: null,
            horaextra: {
                type: Number,
                default: null
            },
            hora3casas: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: '',
                /*prefix: 'R$ ',
                suffix: ' #',*/
                precision: 2,
                masked: false /* doesn't work with directive */
            },
            momentFormat: {
                 // Date to String
                stringify: (date) => {
                    console.log('date',date)
                    console.log('new date',date ? moment(date).format('DD/MM/YYYY') : null)


                    return date ? moment(date).format('DD/MM/YYYY') : null
                },
                // String to Date
                parse: (value) => {
                    console.log('value',value);
                    console.log('new value',value ? moment(value,'DD/MM/YYYY').toDate():null);
                    //return value ? moment(value, 'MM/DD/YYYY').toDate() : null
                    //const date = moment(value, "YYYY-MM-DD").toDate();
                     return value ? moment(value,'DD/MM/YYYY').toDate() : null

                     //const date = moment(value, "YYYY-MM-DD").toDate();
                     //return value ? moment(new Date(date), "YYYY-MM-DD").toDate() : null;
                    
                }
            }
        }
    },
    computed: {
        valueNumber: {
            // getter
            get: function () {
                return Number(this.valor[this.campo]);
            },
            // setter
            set: function (newValue) {
                this.valor[this.campo] = newValue
            }
        },       
        origemRetornoSplit () {
          return this.origemRetorno.split(',').filter((item,idx) => idx > 0);
        },        
        valorSelect: {
            get () {
                //console.log('get ',this.valor[this.campo])
                return /*parseInt(this.valor[this.campo]) ? parseInt(this.valor[this.campo])  : this.valor[this.campo]*/this.valor[this.campo]
                //return this.valor[this.campo]
            },
            set (val) {
                //console.log('set ',this.valor[this.campo], val)
                this.valor[this.campo] = val
            }
        },       
        mask () {
            if (this.mascara == 'cpf_cnpj'){
                return ['###.###.###-##', '##.###.###/####-##'] 
            } else
            if (this.mascara == 'cpf'){
                return ['###.###.###-##'] 
            } else
            if (this.mascara == 'cnpj'){
                return ['##.###.###/####-##'] 
            } else
            if (this.mascara == 'telefone'){
                return ['(##) ####-####'] 
            } else
            if (this.mascara == 'celular'){
                return ['(##) ####-####', '(##) #####-####'] 
            } else
            if (this.mascara == 'cep'){
                return ['#####-###'] 
            } else 
            if (this.mascara == 'hora'){
                return ['##:##'] 
            } else     
            if (this.mascara == 'horaextra'){
                return ['###.##', '##.##', '#.##','#.#'] 
            } else         
            if (this.mascara == 'receita'){
                if (this.Exercicio <= 2017){
                    return ['####.##.##.##'] 
                }else{
                    return ['#.#.#.#.##.#.#.##.##.##'] 
                }
            } else            
            if (this.mascara == 'despesa'){
                if (this.Exercicio <= 2017){
                    return ['#.#.##.##.##.##'] 
                }else{
                    return ['#.#.##.##.##.##'] 
                }
            } else            
            if (typeof this.mascara != "undefined" && typeof this.mascara != "null"){
                return this.mascara 
            } else {
                return null
            }
        },
        ...mapState('Login',['Entidade','Exercicio','Competencia','EntidadeOrc','baseURL'])
    },
    methods: {
        loadC (component) {
            return Vue.component('modal_cad', require("@/components/"+component+".vue").default);
            //return Vue.component('modal_cad', () => import("@/components/comum/"+component+".vue"));
        },         
        unformatHoraExtra($event, horaextra) {
            let vValor = ''
            vValor = horaextra.replace('.','')
            vValor = vValor.replace(':','.')
            this.valor[this.campo] = Number(vValor)
        },
        forceUppercase(e, o, prop, c, t) {
            if ((c !== 'camel') && (c !== 'none')){
                if (t === 'text' || t === 'textarea' || t === 'url' || t === 'email'){
                    const start = e.target.selectionStart
                    if ((c == 'undefined') || (c == '') || (c == 'upper')){ 
                        e.target.value = e.target.value.toUpperCase()
                        }else
                    if (c == 'lower'){ 
                        e.target.value = e.target.value.toLowerCase()
                        }            
                    this.$set(o, prop, e.target.value)
                    if (t === 'text' || t === 'textarea' || t === 'url'){
                        e.target.setSelectionRange(start, start)
                    }
                }
            }            
        },
        async getLista () {
            var url = this.origem
            let Parms = '?' 
            if (this.origem != '' && typeof this.origem != 'undefined' && typeof this.origem != 'null' && this.origem != null){
                if (this.origem.indexOf("/") != -1){
                    url = this.origem.replace(/_/g, '')
                }else{
                    url = this.origem.replace(/_/g, '')+'/combo'                    
                }
                if (this.origemFiltro != '' && typeof this.origemFiltro != 'undefined' && typeof this.origemFiltro != 'null' && this.origemFiltro != null){
                    if (typeof this.origemFiltro == 'object'){
                        for (var index in this.origemFiltro) {
                            if (Parms != '?') { Parms += '&'}
                            Parms += index + '='
                            //Parms += typeof this.valor[this.origemFiltro[index]] != 'undefined' ? this.valor[this.origemFiltro[index]] : this.origemFiltro[index] 
                            Parms += this.origemFiltro[index] in this.valor ? this.valor[this.origemFiltro[index]] : this.origemFiltro[index] !== null && typeof this.origemFiltro[index] !== 'undefined' ? this.origemFiltro[index] : 0
                        }
                    }else{
                        Parms += this.origemFiltro
                    }
                }
                url += Parms 
                await axios.get(url)
                    .then(response => {
                        this.opcoes = response.data.data 
                        this.opcoes.map(x => {
                            return x.mct_origem_retorno = x[this.origemRetornoSec] ? x[this.origemRetorno] + ' ' + x[this.origemRetornoSec] : x[this.origemRetorno];
                        });                       
                        this.setDataLista(this.opcoes) 
                    })
                    .catch(response => {
                        //console.log(response)
                    })
            }
        },
        async getListaAPI () {
            var url = this.origemAPI
            await axios.get(url)
                .then(response => {
                    this.opcoes = response.data.data 
                    this.opcoes.map(x => {
                        return x.mct_origem_retorno = x[this.origemRetornoSec] ? x[this.origemRetorno] + ' ' + x[this.origemRetornoSec] : x[this.origemRetorno];
                    });                       
                    this.setDataLista(this.opcoes) 
                })
                .catch(response => {
                    //console.log(response)
                })
        },        
        async getDescricao (busca, origem, retorno) {
            let Parms = '?'
            let filtros = ''
            if ((busca != '' && typeof busca != 'undefined' && typeof busca != 'null' && busca != null) &&
                (origem != '' && typeof origem != 'undefined' && typeof origem != 'null' && origem != null)){     

                if (this.origemChaveComposta === true || this.origemChaveComposta === 'true'){
                    Parms = Parms + this.origemChave +'='+ busca
                }else{
                    Parms = '/' + busca
                }

                if (this.origemFiltro != '' && typeof this.origemFiltro != 'undefined' && typeof this.origemFiltro != 'null' && this.origemFiltro != null){
                    for (var index in this.origemFiltro) {
                        if (Parms != '?') { Parms += '&'}
                        Parms += index +'='+ this.origemFiltro[index] in this.valor ? this.valor[this.origemFiltro[index]] : this.origemFiltro[index] !== null ? this.origemFiltro[index] : 0
                    }
                }

                var url = origem.replace(/_/g, '')+'/editar'+Parms 
                //console.log(url)

                await axios.get(url)
                .then(response => {
                    this.retornoDescricao = response.data.data[retorno].toUpperCase()
                    this.setDescricao(response.data.data)
                })
                .catch(response => {
                    if (typeof response.response.status !== 'undefined' && response.response.status == 404) {
                        this.retornoDescricao = 'REGISTRO NÃO LOCALIZADO'
                        this.setDataRetorno(response.response.status)
                    }
                })
            }
        }, 
        setDescricao (retorno) {
            this.valor[this.campo] = retorno[this.origemChave]             
            //$(`#dsc${this.campo}`).html(retorno[this.origemRetorno])
            if (this.tipo != 'search-input'){
                if (typeof retorno[this.origemRetorno] === 'string'){
                    this.retornoDescricao = retorno[this.origemRetorno].toUpperCase()
                }else{
                    this.retornoDescricao = retorno[this.origemRetorno]
                }
                if (typeof this.gravaRetorno !== 'undefined' && this.gravaRetorno){
                    this.valor[this.origemRetorno] = retorno[this.origemRetorno]
                }
                if (this.origemFiltro != '' && typeof this.origemFiltro != 'undefined' && typeof this.origemFiltro != 'null' && this.origemFiltro != null){
                    for (var index in this.origemFiltro) {
                        this.valor[this.origemFiltro[index]] = retorno[index]
                    }
                }                
            }
            this.setDataRetorno(retorno)
            if (typeof this.origemData != 'undefined' && typeof this.origemData != 'null' && this.origemData != null){
                for (var index in retorno) {
                        this.origemData[index] = retorno[index]
                    }
            }
            if (this.abrirCadastro == true){
                this.$bvModal.hide('modal_cad_'+this.modalId)
            } else {
                this.$bvModal.hide('modal_'+this.modalId)
            }

        },                   
        evtModal (ev, tipo) {
            if (tipo === 2){
                this.$bvModal.show('modal_'+this.modalId)
            } else {
                this.$bvModal.show('modal_cad_'+this.modalId)
            }
            //$('#MctModal'+this.modalId).modal('show')
        },
        setDataRetorno (data) {
            this.$emit('setDataRetorno',data)
        },
        setDataLista (data) {
            this.$emit('setDataLista',data)            
        },
        onCep (data) {
            this.$emit('onCep')            
        },
        csvJSON(csv){
            var lines=csv.split("\n")
            var result = []
            var headers=lines[0].split(";")
            for(var i=1;i<lines.length;i++){
                var obj = {}
                var currentline=lines[i].split(";")
                for(var j=0;j<headers.length;j++){
                    obj[headers[j]] = currentline[j]
                }
                result.push(obj)
            }
            return result
        },        
        onFileChange(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length)
                return
            this.createImage(files[0])
        },
        createImage(file) {
            let reader = new FileReader()
            let vm = this
            reader.onload = (e) => {
                vm.img = e.target.result
                vm.valor[vm.campo+'_img'] = e.target.result
            };
            reader.readAsDataURL(file)
        },
        async upload(){
            await axios.post('/upload',{image: this.img}).then(response => {
            
            })
        },
        valCC (valor){
            return ValidaCpfCnpj.valida_cpf_cnpj(valor)
        },
        abrirPopup(data){
            this.$store.dispatch('Popup/ABRIR_POPUP', [data, true]);
        },
        onKeyUp() {
            this.$emit('onKeyUp')    
        },
        onKeyDown() {
            this.$emit('onKeyDown')    
        },
        onChange(e) {
            if (this.tipo == 'colorpicker'){
                this.valor[this.campo] = e.target.value
            }
            this.$emit('onChange')
        },
        onReflesh() {
            this.getLista()
            if(this.tipo == 'select' || this.tipo == 'select2'){
                //
            }            
        },
        onSelected (val) {
            var ret = this.opcoes.filter(res => res[this.origemChave] === val)
            this.$emit('setDataRetorno',ret[0])
        },
        changeSelect(){
            //console.log('teste')             
        },
        loadModal(component, source, titulo) {
            this.$modal.open({
                id: source,
                component: import('@/components/modal/BasicModal.vue'),
                options: {
                    show: true,
                    destroy: true,
                },
                props: {
                    component: component,
                    source: `${source}/novo`, 
                    titulo: titulo, 
                    modo: 'novo'
                },
            })
        },
    },
    async created () {
        if (((this.tipo == 'select') || (this.tipo == 'select2') || (this.tipo == 'selectmult')) && (this.origemAPI)){
            await this.getListaAPI()  
        }else 
        if (((this.tipo == 'select') || (this.tipo == 'select2') || (this.tipo == 'selectmult')) && (!this.origemData)){
            await this.getLista() 
        }else 
        if (this.tipo == 'search'){
            this.getDescricao (this.valor[this.campo],this.origem,this.origemRetorno) 
            this.modalId = Math.floor((Math.random() * 99999999) + 1)
        }else 
        if (this.tipo == 'search-input'){
            this.getDescricao (this.valor[this.campo],this.origem,this.origemRetorno) 
            this.modalId = Math.floor((Math.random() * 99999999) + 1)
        }
        
        if((this.origemDataJson !== null) && (typeof this.origemDataJson !== 'undefined')){
            const dataJSON = await importJSONCSV(this.origemDataJson);
            this.dataCombo = dataJSON.default;
        }else{
            this.dataCombo = this.origemData
        }

        if((this.origemDataCSV !== null) && (typeof this.origemDataCSV !== 'undefined')){
            const dataCSV = await importJSONCSV(this.origemDataCSV);
            var csv = dataCSV.default;
            var csvJson = this.csvJSON(csv)
            this.dataCombo = csvJson
        }           

        if(this.tipo == 'image'){
            let caminho = this.$route.path
            caminho = caminho.substr(0, caminho.indexOf("/",1))
                        
            const URL = `/download${caminho}/${this.valor[this.campo]}`;
            await axios.get(URL)
                .then(response => {                        
                    this.img = 'data:image/jpg;base64,'+response.data || semImg;
                })
                .catch(response => {
                  this.message = response.response.data.message
                  this.errors = response.response.data.errors
                  this.status = response.response.status
                })  
            /*let vm = this
            let caminho = this.$route.path
            caminho = caminho.substr(0, caminho.indexOf("/",1))
            caminho = process.env.VUE_APP_BASE_URL+'storage/arquivos'+caminho+'/'+this.valor[this.campo]
            this.img = helper.image(caminho, {
                success : function () {
                    //alert(this.width); 
                    },
                failure : function () {
                    vm.img = semImg;

                },
                //target : "#img_bnc_logo"
            });*/ 
        } 
        
        if(this.tipo == 'horaextra'){
            this.horaextra = this.valor[this.campo]
        }
    },
    mounted () {
        //$(this.$refs['sm_'+this.campo]).selectpicker()
        if(this.tipo == 'select'){
            //$(this.$refs['sm_'+this.campo]).selectpicker('refresh')
            //$(this.$refs['sm_'+this.campo]).val(this.valor[this.campo]).change();                       
            //const $selectpicker = $(this.$el).find('.selectpicker')
            //$selectpicker.selectpicker().on('changed.bs.select', (e, clickedIndex, isSelected, previousValue) => this.setDataRetorno(this.opcoes[clickedIndex-1]))            
        }


        if (this.tipo == 'colorpicker'){
            $('#ig_'+this.campo).colorpicker();            
        }
        $('[data-toggle="tooltip"]').tooltip();            
    },
    updated () {
        //$.fn.selectpicker.Constructor.BootstrapVersion = '4';
        // if(this.tipo == 'select'){
        //     var vm = this
        //     $(this.$refs['sm_'+this.campo]).selectpicker('refresh')
        //     $(this.$refs['sm_'+this.campo]).val(this.valor[this.campo]).change();    
        //     $(this.$refs['sm_'+this.campo]).on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        //         if (typeof clickedIndex !== 'undefined'){
        //             if (typeof vm.gravaRetorno !== 'undefined' && vm.gravaRetorno){
        //                 //console.log(clickedIndex,this.obrigatorio)
        //                 if (clickedIndex === 1 && (this.obrigatorio !== 'true' && this.obrigatorio !== true)){
        //                     vm.valor[vm.origemRetorno] = null    
        //                 }else{
        //                     vm.valor[vm.origemRetorno] = vm.opcoes[clickedIndex - (this.obrigatorio !== 'true' && this.obrigatorio !== true ? 2 : 1)][vm.origemRetorno]                            
        //                 }
        //             }
        //             if (clickedIndex === 1 && (this.obrigatorio !== 'true' && this.obrigatorio !== true)){
        //                 //vm.setDataRetorno(vm.opcoes[clickedIndex - (this.obrigatorio !== 'true' && this.obrigatorio !== true ? 2 : 1)])
        //             }else{
        //                 vm.setDataRetorno(vm.opcoes[clickedIndex - (this.obrigatorio !== 'true' && this.obrigatorio !== true ? 2 : 1)])
        //             }
        //         }/*else{
        //             if (typeof vm.gravaRetorno !== 'undefined' && vm.gravaRetorno){
        //                 vm.valor[vm.origemRetorno] = null
        //             }                    
        //         }*/
        //     });
        // }
	},
    watch: {
        'opcoes' () {
            if(this.tipo == 'select'){
                //$(this.$refs['sm_'+this.campo]).selectpicker('refresh')
               // $(this.$refs['sm_'+this.campo]).val(this.valor[this.campo]).change();
            }
        },
        'valor' () {                
            if (this.tipo == 'search'){
                this.getDescricao (this.valor[this.campo],this.origem,this.origemRetorno) 
                this.modalId = Math.floor((Math.random() * 99999999) + 1)
            }else 
            if (this.tipo == 'search-input'){
                this.getDescricao (this.valor[this.campo],this.origem,this.origemRetorno) 
                this.modalId = Math.floor((Math.random() * 99999999) + 1)
            }
        } ,
        'horaextra' () {
            return this.valor[this.campo]
        },
        'origemData' () {
            if((this.origemDataJson === null) || (typeof this.origemDataJson === 'undefined')){
                this.dataCombo = this.origemData
            }
        },
        origemFiltro: {
            handler: function (val, oldVal) {
                var changed = Object.keys(val).some( function( prop ) {
                    return val[prop] !== oldVal[prop];
                })
                if (changed){
                    this.getLista()
                }
            },
            deep: true
        }                       
    }          
}
</script>

<style scoped>
    .mct-btn-img{
        margin-top: 10px;
        margin-left: -5px;
    }

    /* Esconde o input */
    input[type='file'] {
        background-color: #343A40;
        color: white;
    }   

    .fa-anim {
        /*margin-left: 5px;*/
        animation: bounceIn 2s;
    }

    @keyframes bounceIn {
        0% {
            transform: scale(0.1);
            opacity: 0;
        }
        60% {
            transform: scale(1.2);
            opacity: 1;
        }
        100% {
            transform: scale(1);
        }
    }

</style>

