<template>
    <div class="mct-form container-fluid">
        <div class="row m-0 my-1 mct-barra-sub">
            <button type="button" class="btn btn-success" aria-label="Left Align" @click="incluirNovo($event,'R')">
                <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Contas à Receber </button>
            <button type="button" class="btn btn-danger ml-1" aria-label="Left Align" @click="incluirNovo($event,'P')">
                <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Contas à Pagar </button>
            <button type="button" class="btn btn-info ml-1" aria-label="Left Align" @click="loadModal('modulos/financeiro/EscLancamentos','esclancfinanceiro','Lançamentos Financeiros')">
                <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Lançamentos Financeiros </button>

            <button type="button" class="btn btn-secondary ml-1" aria-label="Left Align" @click="incluirNovo($event,'T')">
                <span class="fa fa-exchange fa-lg" aria-hidden="true"></span> Transferências </button>
            <button type="button" class="btn btn-secondary ml-1" aria-label="Left Align" @click="$router.push({path: '/esccentrocusto'})">
                <span class="fa fa-university fa-lg" aria-hidden="true"></span> Centro de Custo </button>
            <button type="button" class="btn btn-secondary ml-1" aria-label="Left Align" @click="$router.push({path: '/escplncontas'})">
                <span class="fa fa-list fa-lg" aria-hidden="true"></span> Plano de Contas </button>
            <button type="button" class="btn btn-secondary ml-1" aria-label="Left Align" @click="$router.push({path: '/esccondpag'})">
                <span class="fa fa-credit-card fa-lg" aria-hidden="true"></span> Cond. Pagto </button>
        </div>        
        <mct-topo-lista :titulo="titulo" :subtitulo="`Listagem de ${titulo}`" v-show="!showModal"></mct-topo-lista>
        <div class="container-fluid">
            <div class="row m-0 my-1">
                <div class="btn-group btn-group-sm btn-group-toggle">
                    <label class="btn" :class="filtro.tipo == 'A' ? 'btn-dark' : 'btn-secondary'" style="width: 110px;">
                        <input type="radio" name="options" autocomplete="off" id="option3" value="A" v-model="filtro.tipo"><i class="fa fa-th-large" aria-hidden="true"></i> Todas
                    </label>
                    <label class="btn" :class="filtro.tipo == 'R' ? 'btn-dark' : 'btn-secondary'" style="width: 110px;">
                        <input type="radio" name="options" autocomplete="off" id="option1" value="R" v-model="filtro.tipo"><i class="fa fa-usd text-success" aria-hidden="true"></i> Receber
                    </label>
                    <label class="btn" :class="filtro.tipo == 'P' ? 'btn-dark' : 'btn-secondary'" style="width: 110px;">
                        <input type="radio" name="options" autocomplete="off" id="option2" value="P" v-model="filtro.tipo"><i class="fa fa-usd text-danger" aria-hidden="true"></i> Pagar
                    </label>
                    <label class="btn" :class="filtro.tipo == 'L' ? 'btn-dark' : 'btn-secondary'" style="width: 130px;">
                        <input type="radio" name="options" autocomplete="off" id="option2" value="L" v-model="filtro.tipo"><i class="fa fa-usd text-danger" aria-hidden="true"></i> Lanç. Financeiro
                    </label>
                    <label class="btn" :class="filtro.tipo == 'T' ? 'btn-dark' : 'btn-secondary'" style="width: 130px;">
                        <input type="radio" name="options" autocomplete="off" id="option2" value="T" v-model="filtro.tipo"><i class="fa fa-usd text-danger" aria-hidden="true"></i> Transf. Financeira
                    </label>
                </div>
                <div class="btn-group btn-group-sm btn-group-toggle ml-2">
                    <label class="btn" :class="filtro.tipoconta == 'T' ? 'btn-dark' : 'btn-secondary'" style="width: 110px;">
                        <input type="radio" name="options" autocomplete="off" id="option1" value="T" v-model="filtro.tipoconta"> Todas
                    </label>
                    <label class="btn" :class="filtro.tipoconta == 'Q' ? 'btn-dark' : 'btn-secondary'" style="width: 110px;">
                        <input type="radio" name="options" autocomplete="off" id="option1" value="Q" v-model="filtro.tipoconta"> Quitadas
                    </label>
                    <label class="btn" :class="filtro.tipoconta == 'P' ? 'btn-dark' : 'btn-secondary'" style="width: 110px;">
                        <input type="radio" name="options" autocomplete="off" id="option2" value="P" v-model="filtro.tipoconta"> Parc. Quitadas
                    </label>
                    <label class="btn" :class="filtro.tipoconta == 'A' ? 'btn-dark' : 'btn-secondary'" style="width: 110px;">
                        <input type="radio" name="options" autocomplete="off" id="option3" value="A" v-model="filtro.tipoconta"> A Vencer
                    </label>
                    <label class="btn" :class="filtro.tipoconta == 'V' ? 'btn-dark' : 'btn-secondary'" style="width: 110px;">
                        <input type="radio" name="options" autocomplete="off" id="option3" value="V" v-model="filtro.tipoconta"> Vencidas 
                    </label>
                </div>               
            </div>
            <div class="row">
                <div class="col-lg-2">
                    <fieldset class="well">
                        <legend class="well-legend">Data Emissão</legend>
                        <div class="row">
                            <div class="col-lg-12 mb-2">
                                <mct-form-itens tipo="date" campo="dataemi_ini" :valor="filtro" titulo="Inicial" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <mct-form-itens tipo="date" campo="dataemi_fin" :valor="filtro" titulo="Final" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-lg-2">
                    <fieldset class="well">
                        <legend class="well-legend">Data Vencimento</legend>
                        <div class="row">
                            <div class="col-lg-12 mb-2">
                                <mct-form-itens tipo="date" campo="datavenc_ini" :valor="filtro" titulo="Inicial" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <mct-form-itens tipo="date" campo="datavenc_fin" :valor="filtro" titulo="Final" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                            </div>
                        </div>
                    </fieldset>
                </div>
                <div class="col-lg-2">
                    <fieldset class="well">
                        <legend class="well-legend">Data Quitação</legend>
                        <div class="row">
                            <div class="col-lg-12 mb-2">
                                <mct-form-itens tipo="date" campo="dataquita_ini" :valor="filtro" titulo="Inicial" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <mct-form-itens tipo="date" campo="dataquita_fin" :valor="filtro" titulo="Final" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                            </div>
                        </div>
                    </fieldset>
                </div>
                
            </div>
            <div class="row mb-2" v-if="filtro.tipo != 'L'">
                <div class="col-lg-6">
                    <mct-form-itens tipo="select" campo="pessoa" :valor="filtro" titulo="Pessoa" origemAPI="escpessoas/combo2" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_fantasia' :errors="{}" modo="editar"></mct-form-itens>
                </div>
            </div>
            <div class="row mb-2">
                <div class="col-sm-12 text-left">
                    <button type="button" class="btn btn-secondary" aria-label="Left Align" @click="getData()">
                    <span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Filtrar</button>     
                </div>
            </div>  
            <div class="mct-grid">
                <div class="mct-grid-scroll">         
                    <table class="table table-striped table-bordered table-sm table-hover m-0 p-0">
                        <thead>                       
                            <tr>
                                <th class="mct-acoes col-xs-2">Ações</th>
                                <th @click="toggleOrder($event,'doc_tipo_conta')" style="width: 50px;" class="text-center">
                                    <span>
                                        <span>TIPO</span>
                                        <span class="dv-table-column" v-if="'doc_tipo_conta' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>
                                <th @click="toggleOrder($event,'docp_numero')" style="width: 100px;" class="text-right">
                                    <span>
                                        <span>NÚMERO</span>
                                        <span class="dv-table-column" v-if="'docp_numero' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'doc_emissao')" style="width: 95px;" class="text-right">
                                    <span>
                                        <span>EMISSÃO</span>
                                        <span class="dv-table-column" v-if="'doc_emissao' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'docp_vencimento')" style="width: 95px;" class="text-right">
                                    <span>
                                        <span>VENCIMENTO</span>
                                        <span class="dv-table-column" v-if="'docp_vencimento' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'pes_nome')" class="text-left">
                                    <span>
                                        <span>PESSOA / HISTÓRICO <small class="text-muted">CÓDIGO</small><br/><small class="text-muted text-info"> [RESPONSÁVEL / FANTASIA]</small></span>
                                        <span class="dv-table-column" v-if="'pes_nome' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'docp_valor')" style="width: 100px;" class="text-right">
                                    <span>
                                        <span>VALOR</span>
                                        <span class="dv-table-column" v-if="'docp_valor' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'docp_vlr_pago')" style="width: 100px;" class="text-right">
                                    <span>
                                        <span>VLR. PAGO/LANÇ.</span>
                                        <span class="dv-table-column" v-if="'docp_vlr_pago' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'docp_pagamento')" style="width: 95px;" class="text-right">
                                    <span>
                                        <span>ÚLT. PGTO/LANÇ.</span>
                                        <span class="dv-table-column" v-if="'docp_pagamento' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'docp_dias_atraso')" style="width: 50px;" class="text-right">
                                    <span>
                                        <span>ATRASO</span>
                                        <span class="dv-table-column" v-if="'docp_dias_atraso' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'docp_vlr_multa')" style="width: 60px;" class="text-right">
                                    <span>
                                        <span>MULTA</span>
                                        <span class="dv-table-column" v-if="'docp_vlr_multa' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'docp_vlr_juros')" style="width: 60px;" class="text-right">
                                    <span>
                                        <span>JUROS</span>
                                        <span class="dv-table-column" v-if="'docp_vlr_juros' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'docp_vlr_apagar')" style="width: 100px;" class="text-right">
                                    <span>
                                        <span>SALDO A PAGAR</span>
                                        <span class="dv-table-column" v-if="'docp_vlr_apagar' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th style="width: 34px;"></th>

                            </tr>

                            <!--<tr>
                                <th class="mct-acoes col-xs-2"></th>
                                <th v-for="(column, index) in columns" :key="index">
                                    <mct-filtro-campo :column="column" :source="source"/>
                                </th>                        
                            </tr>-->
                        </thead>
                        <tbody>
                        <tr v-for="(row,index) in data.data" :key="index" :style="row.tp_lancamento == 1 && index & 1 ? 'border-left: 8px solid #F86C6B!important; background-color: #FEF2F4!important': row.tp_lancamento == 1 ? 'border-left: 8px solid #F86C6B!important; background-color: #FCDAE1!important' : 'border-left: 8px solid #379A7B!important;'">
                            <td>
                                <div class="btn-group">
                                    <i class="fa fa-pencil text-white mct-fa mr-1 bg-primary" aria-hidden="true" title="Editar" @click.prevent.stop="row.doc_tipo_conta !== 'L' ? redirect($event, row.doc_tipo_conta, row.doc_codigo, '/editar') : onEditarLancamento('modulos/financeiro/EscLancamentos','esclancfinanceiro','Editar Lançamentos Financeiros', row.doc_codigo)"></i>
                                    <i class="fa fa-check text-white mct-fa mr-1" aria-hidden="true" title="Quitar" @click.prevent.stop="row.docp_vlr_apagar == 0 ? '' : onQuitar(row)" :class="row.docp_vlr_apagar == 0 ? 'bg-secondary' : 'bg-success'"></i>
                                    <i class="fa fa-times text-white bg-danger mct-fa mr-1" aria-hidden="true" title="Excluir" v-if="row.doc_tipo_conta === 'L' || row.doc_tipo_conta === 'T'" @click.prevent.stop="row.doc_tipo_conta === 'L' ? onExcluirLancamento(row) : redirect($event, row.doc_tipo_conta, row.doc_codigo, '/deletar') "></i>
                                </div>
                            </td>
                            <td class="text-center">
                                <span :class="row.doc_tipo_conta === 'R' ? 'badge-success' : row.doc_tipo_conta === 'P' ? 'badge-danger' : 'badge-info'" class="badge p-1 pl-2 pr-2 m-0">{{row.doc_tipo_conta}}</span>
                            </td>
                            <td class="text-right">{{row.docp_numero}}</td>
                            <td class="text-right">{{$options.filters.data(row.doc_emissao)}}</td>
                            <td class="text-right">{{$options.filters.data(row.docp_vencimento)}}</td>
                            <td class="text-left" style="white-space: pre-wrap; word-wrap:break-word;">{{row.pes_nome}} <small class="text-muted">{{row.pes_codigo}}</small>
                                <div v-if="row.pes_fantasia !== null && row.pes_fantasia !== ''">
                                    <small class="text-muted text-info"> [ Fantasia: {{row.pes_fantasia}} ]</small>
                                </div>
                                <div v-if="row.resp_nome !== null && row.resp_nome !== ''">
                                    <small class="text-muted text-info"> [ Resp. Cobrança: {{row.resp_nome}} ]</small>
                                </div>
                            </td>
                            <td class="text-right">{{$options.filters.moeda(row.docp_valor)}}</td>
                            <td class="text-right">{{$options.filters.moeda(row.docp_vlr_pago)}}</td>
                            <td class="text-right">{{$options.filters.data(row.docp_pagamento)}}</td>
                            <td class="text-center">{{row.docp_dias_atraso}}</td>
                            <td class="text-right">{{$options.filters.moeda(row.docp_vlr_multa)}}</td>
                            <td class="text-right">{{$options.filters.moeda(row.docp_vlr_juros)}}</td>
                            <td class="text-right">{{$options.filters.moeda(row.docp_vlr_apagar)}}</td>
                            <td>
                                <div class="btn-group">
                                    <img :src="row.tpdoc_icone !== null && row.tpdoc_icone !== '' ? `assets/images/financeiro/${row.tpdoc_icone}.png` : `assets/images/financeiro/outros.png`" :title="row.tpdoc_descricao" style="max-height: 24px;">
                                    <img  v-if="row.docp_vlr_apagar == 0" src="assets/images/financeiro/pago.png" :title="`${row.tpdoc_descricao} - Pago`" style="max-height: 24px;position: absolute;left: 3px;">
                                </div>
                            </td>                        
                        </tr>
                        </tbody>
                    </table>
                </div>         
            </div>        
            <mct-rodape-lista :data="data" :query="query" @navigate="navigate" @getData="getData"></mct-rodape-lista> 
        </div>
    </div>
</template>
<script>
  import Vue from 'vue'
  import axios from '@/http'
  import moment from 'moment'

  import MctTopoLista from '@/components/comum/MctTopoLista.vue'
  import MctRodapeLista from '@/components/comum/MctRodapeLista.vue'
  import MctGridLista from '@/components/comum/MctGridLista.vue'
  import MctFiltroLista from '@/components/comum/MctFiltroLista.vue'
  import MctMixinsList from '@/components/funcoes/MctMixinsList'
  import MctFormItens from '@/components/comum/MctFormItens.vue'
  import MctFiltroCampo from '@/components/comum/MctFiltroCampo.vue'
  import {verificaPermissao} from '@/components/funcoes/Funcoes.js'
  
  export default {
    name: 'EscFinanceiroLista',
    mixins: [MctMixinsList],
    components: {
        'MctTopoLista': MctTopoLista,
        'MctRodapeLista': MctRodapeLista,
        'MctGridLista' : MctGridLista,
        'MctFiltroLista': MctFiltroLista,
        'MctFormItens': MctFormItens,
        'MctFiltroCampo': MctFiltroCampo,        
    },        
    props: ['source', 'titulo', 'showModal'],
    data() {
      return {
        id: '',
        showAvancado: false, 
        abreQuitacao: false,
        parcSelecionada: {},
        nomeModal: 'modulos/financeiro/EscQuitacaoSimples',
        filtro: {
            tipo: 'A',
            tipoconta: 'T',
            dataemi_ini: null,
            dataemi_fin: null,
            datavenc_ini: null,
            datavenc_fin: null,
            dataquita_ini: null,
            dataquita_fin: null,
            pessoa: null,
        }               
      }
    },
    watch: {
        /*source: function () {
          this.query.column = ''
          this.query.search_column = ''
          this.query.page = 1
          this.query.direction = 'asc'
          this.query.per_page = 30
          this.query.search_operator = 'equal'
          this.query.search_input = ''
          this.query.search_input2 = ''
          this.filters = {}
          this.getData()      
        }*/
    },    
    methods: {
        getBaseUrl () {
            return process.env.VUE_APP_BASE_URL
        },
        loadModal(component, source, titulo) {
            this.$modal.open({
                id: source,
                component: import('@/components/modal/BasicModal.vue'),
                options: {
                    show: true,
                    destroy: true,
                },
                props: {
                    component: component,
                    source: `${source}/novo`, 
                    titulo: titulo, 
                    modo: 'novo'
                },
            })
        }, 
        
        onEditarLancamento(component, source, titulo, linha) {
            this.$modal.open({
                id: source,
                component: import('@/components/modal/BasicModal.vue'),
                options: {
                    show: true,
                    destroy: true,
                },
                props: {
                    component: component,
                    source: `${source}/editar`, 
                    linha: linha,
                    titulo: titulo, 
                    modo: 'novo'
                },
            })
        },
        incluirNovo(ev,tpc) {
            if (tpc === 'R'){
                this.$router.push({path: '/escreceber/novo'})
            } else if (tpc === 'P'){
                this.$router.push({path: '/escpagar/novo'})
            } else if (tpc === 'T'){
                this.$router.push({path: '/esctransferencias/novo'})
            }            
        },
        async getData() {
            //console.log('passou')
            if (typeof this.source !== "undefined" && typeof this.source !== "null" && this.source !== 'undefined/lista'){
            axios.get(this.source,{params:  {
                    pessoa: this.filtro.pessoa,
                    /*responsavel: this.filtro.responsavel,*/
                    tipo: this.filtro.tipo,
                    tipoc: this.filtro.tipoconta,
                    emiini: moment(this.filtro.dataemi_ini).isValid() ? moment(this.filtro.dataemi_ini).format('YYYY-MM-DD') : null,
                    emifin: moment(this.filtro.dataemi_fin).isValid() ? moment(this.filtro.dataemi_fin).format('YYYY-MM-DD') : null,
                    vencini: moment(this.filtro.datavenc_ini).isValid() ? moment(this.filtro.datavenc_ini).format('YYYY-MM-DD') : null,
                    vencfin: moment(this.filtro.datavenc_fin).isValid() ? moment(this.filtro.datavenc_fin).format('YYYY-MM-DD') : null,
                    quitini: moment(this.filtro.dataquita_ini).isValid() ? moment(this.filtro.dataquita_ini).format('YYYY-MM-DD') : null,
                    quitfin: moment(this.filtro.dataquita_fin).isValid() ? moment(this.filtro.dataquita_fin).format('YYYY-MM-DD') : null,

                    column: this.query.column,
                    direction: this.query.direction,
                    page: this.query.page,
                    per_page: this.query.per_page,
                    search_column: this.query.search_column,
                    search_operator: this.query.search_operator,
                    search_input: this.query.search_input
                },
                paramsSerializer: function (params) {
                    return jQuery.param(params)
                }
                })
                .then(response => {
                    this.data = response.data.data                    
                })
                .catch(response => {
                    console.log(response.message)
                })
            }
        },        
      navigate (page) {
            this.query.page = page
            this.getData()
      },
      toggleOrder(column) {
        if(column === this.query.column) {
          if(this.query.direction === 'desc') {
            this.query.direction = 'asc'
          } else {
            this.query.direction = 'desc'
          }
        } else {
          this.query.column = column
          this.query.direction = 'asc'
        }
        this.getData()
      },      
      setDescricao (data ){
          this.$emit('setDescricao', data)
      },
      toogleAvancado (data){
        this.showAvancado = data
      },
      redirect (ev, tpc, cod, tp) {
        
        if (tpc === 'T' ){
            this.$router.push('/esctransferencias/'+cod+tp)
        } else
        if (tpc === 'R' ){
            this.$router.push('/escreceber/'+cod+tp)
        } else {
            this.$router.push('/escpagar/'+cod+tp)
        }         
      },
      onQuitar (row) {
          if (!verificaPermissao(`/${row.doc_tipo_conta === 'R' ? 'escreceber' : row.doc_tipo_conta === 'P' ? 'escpagar' : 'escfinanceiro'}/editar`)){
              return
          }
          /*if (row.docp_vlr_apagar > 0){
                this.parcSelecionada = row
                this.abreQuitacao = true
          }*/
        if (row.doc_tipo_conta !== 'L'){
            this.$modal.open({
                id: 'escquitacaosimples',
                component: import('@/components/modal/BasicModal.vue'),
                options: {
                    show: true,
                    destroy: true,
                },
                props: { 
                    component: 'modulos/financeiro/EscQuitacaoSimples',
                    parcsel: row, 
                    titulo: 'Quitar Conta'
                },
            })
        }  
      },
      onExcluirLancamento(row){
        this.$swal({
            title: 'Deseja realmente excuir o lançamento?',
            text: "Este evento não podera ser revertido!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {   
                const URL = `/esclancamentos/deletar/${row.doc_codigo}`             
                axios.delete(URL)
                    .then(response => {
                        this.errors = {}                    
                        this.status = response.status
                        if (response.statusText == 'OK') {
                            this.$swal(
                                'Deletado!',
                                'O Lançamento foi deletado com sucesso.',
                                'success'
                            )
                        }
                    })
                    .catch(response => {
                        this.message = response.message
                        if (response.response.data.error){
                            this.errors = response.response.data.error.errorInfo[2]              
                        }else{
                            this.errors = response.errors
                        }                     
                        this.status = response.response.status
                    })                                    
            }
        })
      }        
    },
    watch : {
        'filtro.tipo' : function(newVal,oldVal) {
            if (newVal !== oldVal){
                //this.getData()
            }
        },
        'filtro.tipoconta' : function(newVal,oldVal) {
            if (newVal !== oldVal){
                //this.getData()
            }
        }        
    },
    sockets: {
        mctatufinanceiro: function (data) {
            this.getData()
            //this.$awn.info("o Financeiro foi atualizado.") 
        },
    },
    computed: {
        cModal () {
            return () => import(`@/components/${this.nomeModal}.vue`)
        }
    }
  }
</script>

<style scoped>

    .mct-acoes{
        width: 95px;
    }

</style>