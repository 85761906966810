<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <div class="row">
        <div class="col-sm-2">
          <mct-form-itens tipo="number" campo="esc_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
        </div>
        <div class="col-10">
          <mct-form-itens tipo="radio" campo="esc_tipo" titulo="Tipo Escola" :valor="lista" obrigatorio="false" :errors="errors" 
            :origemData="[{value:1,text:'Ensino Infantil/Fundamental/Médio'}, {value:2,text:'Graduação/Pós-Graduação'},{value:3,text:'Técnico/Curso de Idiomas/Cursos Livres'}, {value:4,text:'Pré-Vestibulares'}, {value:5,text:'Educação de Jovens e Adultos (EJA)'}]" 
            :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-1">
          <mct-form-itens tipo="checkbox" campo="esc_bloqueio" :valor="lista" subtitulo="Bloqueada" :errors="errors" @setDataRetorno="setBloqueio" :modo="modo"></mct-form-itens>                      
        </div> 
        <div class="col-sm-2" v-if="lista.esc_bloqueio == 1 && lista.esc_user_bloqueio">
          <mct-form-itens tipo="text" campo="esc_user_bloqueio" :valor="lista" titulo="Usuário" :errors="errors" modo="readonly"></mct-form-itens>                      
        </div> 
        <div class="col-sm-2" v-if="lista.esc_bloqueio == 1 && lista.esc_user_bloqueio">
          <mct-form-itens tipo="text" campo="esc_data_bloqueio" :valor="lista" titulo="Data" :errors="errors" modo="readonly"></mct-form-itens>                      
        </div> 
        <div class="col-sm-2" v-if="lista.esc_bloqueio == 1 && lista.esc_user_bloqueio">
          <mct-form-itens tipo="text" campo="esc_hora_bloqueio" :valor="lista" titulo="Hora" :errors="errors" modo="readonly"></mct-form-itens>                      
        </div> 
      </div>
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#basico" role="tab">Básico</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#informacoes" role="tab">Outras Informações</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#configuracoes" role="tab">Configurações/ Logo</a>
        </li>
      </ul>
      <!-- TAB BÁSICO -->
      <div class="tab-content">
        <div class="tab-pane active" id="basico" role="tabpanel">         
          <div class="row">
            <div class="col-sm-6">
              <mct-form-itens tipo="text" campo="esc_nome" :valor="lista" titulo="Nome" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
            <div class="col-sm-6">
              <mct-form-itens tipo="text" campo="esc_abreviatura" :valor="lista" titulo="Abreviatura" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <mct-form-itens tipo="text" campo="esc_cnpj" :valor="lista" titulo="C.N.P.J." obrigatorio="true" mascara="cpf_cnpj" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
            <div class="col-4">
              <mct-form-itens tipo="text" campo="esc_im" :valor="lista" titulo="I.M." obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
            <div class="col-4">
              <mct-form-itens tipo="text" campo="esc_inep" :valor="lista" titulo="INEP" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
          </div>
          <div class="row">
            <div  class="col-3" style="padding-left: 0px;padding-right: 0px;">
              <div class="col-12">
                <mct-form-itens tipo="text" campo="esc_telefone" :valor="lista" titulo="Telefone" obrigatorio="true" mascara="celular" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
              <div class="col-12">
                <mct-form-itens tipo="text" campo="esc_fax" :valor="lista" titulo="Fax" obrigatorio="false" mascara="celular" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
            </div>
          
            <div class="col-4">
              <fieldset class="well">
              <legend class="well-legend">Administração</legend>                    
                <div class="row">
                  <div class="col-12">
                    <mct-form-itens tipo="radio" campo="esc_administracao" :valor="lista" obrigatorio="false" :errors="errors" :origemData="[{value:'E',text:'Estadual'}, {value:'F',text:'Federal'},{value:'M',text:'Municipal'}, {value:'P',text:'Particular'}]" :modo="modo"></mct-form-itens>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col-3">
              <fieldset class="well">
              <legend class="well-legend">Convênio</legend>                    
                <div class="row">
                  <div class="col-12">
                    <mct-form-itens tipo="radio" campo="esc_convenio" :valor="lista" obrigatorio="false" :errors="errors" :origemData="[{value:'E',text:'Estado'}, {value:'M',text:'Município'}, {value:'N',text:'Nenhum'}]" :modo="modo"></mct-form-itens>
                  </div>
                </div>
              </fieldset>
            </div>
            <div class="col-2">
              <fieldset class="well">
              <legend class="well-legend">Zona</legend>                    
                <div class="row">
                  <div class="col-12">
                    <mct-form-itens tipo="radio" campo="esc_zona" :valor="lista" obrigatorio="false" :errors="errors" :origemData="[{value:'U',text:'Urbana'}, {value:'R',text:'Rural'}]" :modo="modo"></mct-form-itens>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mct-form-itens tipo="email" campo="esc_email" :valor="lista" titulo="Email" obrigatorio="true" transforme="lower" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
            <div class="col-6">
              <mct-form-itens tipo="url" campo="esc_url" :valor="lista" titulo="URL" obrigatorio="false" transforme="lower" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
          </div>
          <fieldset class="well">
            <legend class="well-legend">Logradouro</legend>     
            <mct-logradouro :modo="modo" :valor="lista" :rels="{logra_codigo: 'esc_logra_codigo', logra_descricao: 'esc_logradouro', logra_bairro: 'esc_bairro', 
                        logra_cidade: 'esc_cidade', logra_estado: 'esc_estado', logra_cep: 'esc_cep', logra_numero: 'esc_numero', logra_complemento: 'esc_complemento', logra_cidade_ibge: 'esc_cidade_ibge'}"></mct-logradouro> 
          </fieldset>
          <fieldset class="well">
            <legend class="well-legend">Diretor(a)/Secretário(a)</legend>                    
            <div class="row">
              <div class="col-6">
                <mct-form-itens tipo="search" campo="esc_cod_diretor" :valor="lista" titulo="Diretor" obrigatorio="true" :errors="errors" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' @setDataRetorno="setDiretor" :modo="modo" tamanho="2"></mct-form-itens>
              </div>                    
              <div class="col-3">
                <mct-form-itens tipo="text" campo="cpf" :valor="diretor" titulo="C.P.F.:" obrigatorio="false" :errors="errors" modo="readonly"></mct-form-itens>
              </div>     
              <div class="col-3">
                <mct-form-itens tipo="text" campo="rg" :valor="diretor" titulo="R.G.:" obrigatorio="false" :errors="errors" modo="readonly"></mct-form-itens>
              </div>                
            </div>
            <div class="row">
              <div class="col-6">
                <mct-form-itens tipo="search" campo="esc_cod_secretario" :valor="lista" titulo="Secretario(a)" obrigatorio="false" :errors="errors" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' :modo="modo" tamanho="2"></mct-form-itens>
              </div>  
            </div>
          </fieldset>
          
        </div>
        <!-- TAB OUTRAS INFORMAÇÕES -->
        <div class="tab-pane" id="informacoes" role="tabpanel">
          <div class="row">
            <div class="col-6">
              <fieldset class="well">
                <legend class="well-legend">Localização de Funcionamento</legend>    
                <div class="row">
                  <div class="col-12">
                    <mct-form-itens tipo="radio" campo="esc_localizacao" :valor="lista" obrigatorio="false" :errors="errors" 
                      :origemData="[{value:'1',text:'Prédio Escolar'}, {value:'2',text:'Templo/Igreja'}, {value:'3',text:'Salas de Empresa'},
                                    {value:'4',text:'Casa do Professor'}, {value:'5',text:'Salas em Outra Escola'}, {value:'6',text:'Galpão/Rancho/Barracão'},{value:'7',text:'Outras Instalações'}]" :modo="modo"></mct-form-itens>
                  </div>
                </div>                                             
              </fieldset>
            </div>
            <div class="col-3">
              <fieldset class="well">
                <legend class="well-legend">Forma Ocupação Prédio</legend>    
                <div class="row">
                  <div class="col-12">
                    <mct-form-itens tipo="radio" campo="esc_predio_ocupacao" :valor="lista" obrigatorio="false" :errors="errors" :origemData="[{value:'1',text:'Próprio'}, {value:'2',text:'Alugado'}, {value:'3',text:'Cedido'}]" :modo="modo"></mct-form-itens>
                  </div>
                </div>                                             
              </fieldset>
            </div>
            <div class="col-3">
              <fieldset class="well">
                <legend class="well-legend">Entidade Proprietária do Imóvel</legend>    
                <div class="row">
                  <div class="col-12">
                    <mct-form-itens tipo="radio" campo="esc_predio_proprietario" :valor="lista" obrigatorio="false" :errors="errors" :origemData="[{value:'F',text:'Federal'}, {value:'M',text:'Municipio'}, {value:'E',text:'Estadual'}, {value:'P',text:'Particular'}]" :modo="modo"></mct-form-itens>
                  </div>
                </div>                                             
              </fieldset>
            </div>
          </div>
          <fieldset class="well">
              <legend class="well-legend">Dados do Imóvel</legend> 
              <div class="row">
                <div class="col-2">
                  <mct-form-itens tipo="number" campo="esc_terreno_area" :valor="lista" titulo="Área Terreno" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-3">
                  <mct-form-itens tipo="currency" campo="esc_terreno_valor" :valor="lista" titulo="Valor Terreno" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-3">
                  <mct-form-itens tipo="number" campo="esc_area_contruida" :valor="lista" titulo="Área Construída" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-2">
                  <mct-form-itens tipo="number" campo="esc_numero_pavimentos" :valor="lista" titulo="Nº Pavimentos" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-2">
                  <mct-form-itens tipo="checkbox" campo="esc_murado" :valor="lista" subtitulo="Murado?" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
              </div>
          </fieldset>
          <fieldset class="well">
              <legend class="well-legend">Outros Dados</legend> 
              <div class="row">
                <div class="col-3">
                  <mct-form-itens tipo="text" campo="esc_autorizacao" :valor="lista" titulo="Autorização" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-2">
                  <mct-form-itens tipo="text" campo="esc_autorizacao_orgao" :valor="lista" titulo="Orgão" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-3">
                  <mct-form-itens tipo="date" campo="esc_criacao" :valor="lista" titulo="Data Criação" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <mct-form-itens tipo="text" campo="esc_reconhecimento" :valor="lista" titulo="Reconhecimento/Credenciamento" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-2">
                  <mct-form-itens tipo="text" campo="esc_reconhecimento_orgao" :valor="lista" titulo="Orgão" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-3">
                  <mct-form-itens tipo="date" campo="esc_data_reconhecimento" :valor="lista" titulo="Data" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
              </div>
          </fieldset>
          <fieldset class="well">
              <legend class="well-legend">Dados do Polo Educacional</legend> 
              <div class="row">
                <div class="col-4">
                  <mct-form-itens tipo="text" campo="esc_diario_oficial" :valor="lista" titulo="Diário Oficial" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-4">
                  <mct-form-itens tipo="currency" campo="esc_valor_matricula" :valor="lista" titulo="Valor Matrícula" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-4">
                  <mct-form-itens tipo="currency" campo="esc_valor_atestado" :valor="lista" titulo="Valor Atestado" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <mct-form-itens tipo="currency" campo="esc_valor_certificado" :valor="lista" titulo="Valor Certificado" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-4">
                  <mct-form-itens tipo="select" campo="esc_tp_valor_certificado" :valor="lista" titulo="Tipo" :origemData="[{value:1,text:'Início'}, {value:2,text:'Final'}]" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-4">
                  <mct-form-itens tipo="select" campo="esc_tp_parc_valor_certificado" :valor="lista" titulo="Tipo Pagamento" :origemData="[{value:1,text:'À Vista'}, {value:2,text:'Parcelado'}]" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
              </div>
          </fieldset>
        </div>    

        <!-- TAB CONFIGURAÇÕES -->            
        <div class="tab-pane" id="configuracoes" role="tabpanel">
          <div class="row">
            <div class="col-6">
              <fieldset class="well">
                <legend class="well-legend">Arredondamento das Notas</legend>    
                <div class="row">
                  <div class="col-12">
                    <mct-form-itens tipo="radio" campo="esc_notas" :valor="lista" obrigatorio="false" :errors="errors" 
                      :origemData="[{value: 0,text:'Sem Arredondamento'}, {value: 1,text:'0.25 A 0.25'}, {value: 2,text:'0.50 A 0.50 (POR 0.25)'},
                                    {value: 3,text:'0.50 A 0.50 (POR 0.50)'}, {value: 4,text:'Somatória das Notas'}]" :modo="modo"></mct-form-itens>
                  </div>
                </div>                                             
              </fieldset>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <mct-form-itens tipo="checkbox" campo="esc_mostratransf" subtitulo="Não Mostrar Alunos Transferidos" :valor="lista"  obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
          </div>
        </div>
      </div>
    </div>              
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctLogradouro from '@/components/comum/MctLogradouroC.vue'

export default {
  Name: 'Cursos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctLogradouro,
      MctFormItens,
  },
  data () {
      return {
        lista: {},
        errors:{},
        diretor:{'rg':'','cpf':''},
        message: '',
        status: 0,
      }
  },
  methods: {
    onSelect (items, lastSelectItem) {
        this.items = items
        this.lastSelectItem = lastSelectItem
    },  
    setData (lista, errors) {
        this.lista = lista
        this.errors = errors        
    },
    setDiretor(data){
      this.diretor.cpf = data.pes_cpf
      this.diretor.rg = data.pes_rg
    },
    setBloqueio(data){
      this.lista.esc_user_bloqueio = infoLogado.usu_nome
    }

  },
   computed: {
    ...mapState('Login',['infoLogado']), 
  },  
  created() {
  
  }     
}
</script>