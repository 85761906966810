<template>
  <mct-padrao titulo="Lançamentos Financeiros" modo="editar" botoes="false">
    <div slot="formulario">        
        <div class="row p-0 m-0 mb-2 mct-row-itens">
            <div class="col-sm-4">
                <mct-form-itens tipo="radio" campo="lcto_tipo" :valor="lancamento" titulo="Tipo Lançamento" :origemData="[{value:'1',text:'DÉBITO'}, {value:'2',text:'CRÉDITO'}]" obrigatorio="true" :errors="errors" :modo="trava ? 'readonly' : 'editar'"></mct-form-itens>
            </div> 
            <div class="col-sm-3">
                <mct-form-itens tipo="date" campo="lcto_data" :valor="lancamento" titulo="Data Lançamento" obrigatorio="true" :errors="errors" modo="editar"></mct-form-itens>    
            </div>
            <div class="col-sm-4">
                <mct-form-itens tipo="currency" campo="lcto_valor" :valor="lancamento" titulo="Valor" obrigatorio="true" :errors="errors" modo="editar"></mct-form-itens>    
            </div>
        </div>
        <div class="row p-0 m-0 mb-2 mct-row-itens">
            <div class="col-sm-4">
                <mct-form-itens tipo="radio" campo="tp_lancamento" :valor="lancamento" titulo="Lançar em" :origemData="[{value: 'CAIXA', text: 'CAIXA'}, {value: 'BANCO', text: 'BANCO'}]" obrigatorio="true" :errors="errors" modo="editar"></mct-form-itens>    
            </div>
            <div class="col-sm-4" v-show="lancamento.tp_lancamento == 'BANCO'">
                <mct-form-itens tipo="select" campo="cbnc_codigo" :valor="lancamento" titulo="Conta Bancária" 
                    origem="esc_conta_bancaria" origemChave="cbnc_codigo" origemRetorno='cbnc_descricao' 
                    :origemFiltro="{cbnc_status: 1, cbnc_ent: this.Entidade}" obrigatorio="true" :errors="errors" modo="editar"></mct-form-itens>    
            </div>
            <div class="col-sm-4" v-show="lancamento.tp_lancamento == 'CAIXA'">
                <mct-form-itens tipo="select" campo="cxa_codigo" :valor="lancamento" titulo="Conta Caixa" 
                    origem="esc_caixa" origemChave="cxa_codigo" origemRetorno='cxa_descricao' 
                    :origemFiltro="{cxa_status: 1, cxa_ent: this.Entidade}" obrigatorio="true" :errors="errors" modo="editar"></mct-form-itens>    
            </div>
            <div class="col-sm-4">
                <mct-form-itens tipo="select" campo="tqui_codigo" :valor="lancamento" titulo="Tipo Documento" 
                    origem="esc_tp_quitacao" origemChave="tqui_codigo" origemRetorno='tqui_descricao' 
                    :origemFiltro="{tqui_status: 1}" @setDataLista="setTpQuitacao" obrigatorio="true" :errors="errors" :modo="trava ? 'readonly' : 'editar'"></mct-form-itens>    
            </div>

        </div>
        <div class="row p-0 m-0 mb-2 mct-row-itens">
            <div class="col-sm-6">
                <mct-form-itens tipo="select" campo="ccst_codigo" :valor="lancamento" titulo="Centro Custo" 
                    origem="esc_centro_custo" origemChave="ccst_codigo" origemRetorno='ccst_descricao' 
                    obrigatorio="true" :errors="errors" modo="editar"></mct-form-itens>    
            </div>
            <div class="col-sm-6">
                <mct-form-itens tipo="select" campo="lcto_cpart_codigo" :valor="lancamento" titulo="Plano de Contas (Contra Partida)" 
                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" obrigatorio="true" :errors="errors" modo="editar"></mct-form-itens>    
            </div>
        </div>

        <div class="row p-0 m-0 mb-2 mct-row-itens">
            <div class="col-sm-12">
                <mct-form-itens tipo="textarea" campo="lcto_historico" :valor="lancamento" titulo="Histórico" obrigatorio="true" :errors="errors" modo="editar"></mct-form-itens>    
            </div>                    
        </div>

        <div class="row p-0 m-0 mb-2 mct-row-itens" v-if="lancamento.tqui_descricao === 'CHEQUE'">
            <fieldset class="well col-12">
                <legend class="well-legend">Dados do Cheque</legend>
                <div class="row col-12 p-0 m-0 mct-row-itens">
                    <div class="col-sm-2">
                        <mct-form-itens tipo="text" campo="lcto_ch_numero" :valor="lancamento" titulo="Número Ch." obrigatorio="true" :errors="errors" :modo="trava ? 'readonly' : 'editar'"></mct-form-itens>    
                    </div>                    
                    <div class="col-sm-2">
                        <mct-form-itens tipo="date" campo="lcto_ch_vencimento" :valor="lancamento" titulo="Vcto Cheque" obrigatorio="true" :errors="errors" :modo="trava ? 'readonly' : 'editar'"></mct-form-itens>    
                    </div>     
                    <div class="col-sm-4">
                        <mct-form-itens tipo="select" campo="lcto_ch_banco" :valor="lancamento" titulo="Banco" 
                            origem="banco" origemChave="bnc_codigo" origemRetorno='bnc_descricao' obrigatorio="true" :errors="errors" :modo="lancamento.lcto_tipo == '2' && !trava ? 'editar' : 'readonly'"></mct-form-itens>  
                    </div>                    
                    <div class="col-sm-2">
                        <mct-form-itens tipo="text" campo="lcto_ch_agencia" :valor="lancamento" titulo="Agên." obrigatorio="true" :errors="errors" :modo="lancamento.lcto_tipo == '2' && !trava ? 'editar' : 'readonly'"></mct-form-itens>    
                    </div>                    
                    <div class="col-sm-2">
                        <mct-form-itens tipo="text" campo="lcto_ch_conta" :valor="lancamento" titulo="Conta Corrente" obrigatorio="true" :errors="errors" :modo="lancamento.lcto_tipo == '2' && !trava ? 'editar' : 'readonly'"></mct-form-itens>    
                    </div>                    
                    <div class="col-sm-12">
                        <mct-form-itens tipo="select" campo="lcto_ch_pes_codigo" :valor="lancamento" titulo="Pessoa" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' obrigatorio="true" :errors="errors" :modo="trava ? 'readonly' : 'editar'"></mct-form-itens>    
                    </div>
                </div>
            </fieldset>
        </div>

        <div>
            <div class="h-divider clear"></div>
            <div class="d-flex flex-row justify-content-between">
                <button type="button" class="btn btn-success" aria-label="Left Align"  @click.prevent.stop="onSubmit($event)">
                        <span class="fa fa-check fa-lg" aria-hidden="true"></span> Gravar Lançamento </button>
                <button type="button" class="btn btn-dark" aria-label="Left Align" @click.prevent.stop="$modal.close('esclancfinanceiro',true)">
                        <span class="fa fa-times fa-lg" aria-hidden="true"></span> Fechar </button>
            </div>     
        </div>     
    </div>
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import MctPadrao from '@/components/comum/MctFormPadraoSCB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctModal from '@/components/comum/MctModal.vue'

export default {
  Name: 'Lancamentos',  
  props: ['titulo'],
  components: {
      MctPadrao,
      MctFormItens,
      MctModal,
  },
  data () {
      return {        
        lista: {},               
        errors:{},
        message: '',
        status: 0,
        lancamento: {
            lcto_codigo: null,
            tp_lancamento: 'CAIXA',
            lcto_tipo: 2/*1-DEB, 2-CRED */,
            lcto_data: this.moment(new Date()).format('YYYY-MM-DD'),
            lcto_valor: 0.00,
            ccst_codigo: null,
            cbnc_codigo: null,
            cbnc_ent: null,
            cxa_codigo: null,
            cxa_ent: null,
            lcto_cpart_codigo: null,
            tqui_codigo: null,
            tqui_descricao: null,
            lcto_historico: null,            
            lcto_ch_numero: null,
            lcto_ch_vencimento: null,
            lcto_ch_banco: null,
            lcto_ch_agencia: null,
            lcto_ch_conta: null,
            lcto_ch_pes_codigo: null,            
        },
        tpQuitacao: [],
        trava: false
      }
  },
  methods: {         
    async onGetDados (){
        if (this.$attrs.linha && this.$attrs.linha !== null){
            let URL = `esclancamentos/editar/${this.$attrs.linha}`
            await axios.get(`${URL}`)
                .then(response => {
                    this.lancamento = response.data.data

                    if (this.lancamento.cxa_codigo !== null){
                        this.lancamento.tp_lancamento = 'CAIXA'
                    } else if (this.lancamento.cbnc_codigo !== null) {
                        this.lancamento.tp_lancamento = 'BANCO'
                    }

                    if (this.lancamento.chqrec_codigo !== null){
                        this.lancamento.lcto_ch_numero = this.lancamento.chequesrecebidos.chqrec_numero
                        this.lancamento.lcto_ch_vencimento = this.lancamento.chequesrecebidos.chqrec_predatado
                        this.lancamento.lcto_ch_banco = this.lancamento.chequesrecebidos.chqrec_banco
                        this.lancamento.lcto_ch_agencia = this.lancamento.chequesrecebidos.chqrec_agencia
                        this.lancamento.lcto_ch_conta = this.lancamento.chequesrecebidos.chqrec_conta
                        this.lancamento.lcto_ch_pes_codigo = this.lancamento.chequesrecebidos.chqrec_pes_codigo
                    }else if (this.lancamento.chqemi_codigo !== null){
                        /*this.lancamento.lcto_ch_numero = this.lancamento.chequesrecebidos.chqrec_numero
                        this.lancamento.lcto_ch_vencimento = this.lancamento.chequesrecebidos.chqrec_predatado
                        this.lancamento.lcto_ch_banco = this.lancamento.chequesrecebidos.chqrec_banco
                        this.lancamento.lcto_ch_agencia = this.lancamento.chequesrecebidos.chqrec_agencia
                        this.lancamento.lcto_ch_conta = this.lancamento.chequesrecebidos.chqrec_conta
                        this.lancamento.lcto_ch_pes_codigo = this.lancamento.chequesrecebidos.chqrec_pes_codigo*/
                    }

                    if (this.lancamento.tpquitacao.tqui_descricao === 'CHEQUE'){
                        this.$swal({
                            icon: 'warning',
                            title: 'Alguns dados não podem ser alterados.',
                            text: 'Lançamentos em cheque somente e permitido a exclusão ou cancelamento do lançamento, não sendo possivel a alteração do tipo de lançamento e nem os dados do cheque.',
                            footer: ''
                        })
                        this.trava = true
                    }

                    this.message = response.data.message
                    this.status = response.status
                    this.errors = {}
                    
                })
                .catch(response => {
                    this.message = response.response.data.message
                    if (response.response.data.error){
                        this.errors = response.response.data.error.errorInfo[2]              
                    }else{
                        this.errors = response.response.data.errors
                    }
                    this.status = response.response.status
                    
                })
        }
    },
    onSubmit (e) {
      this.$swal({
        title: 'Deseja realmente gravar o lançamento financeiro?',
        text: "Este lançamento poderá apenas ser cancelado posteriormente!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.onGravar()   
            }                     
        })
    },
    async onGravar (e) {
        let msgs = ''
        
        if (this.lancamento.lcto_tipo == null || this.lancamento.lcto_tipo == ''){
            msgs += 'Selecione o Tipo de Lançamento; </br>';
        }
        if (this.lancamento.lcto_data == null || this.lancamento.lcto_data == 0){
            msgs += 'Digite uma Data válida; </br>';
        }
        if (this.lancamento.lcto_valor == null || this.lancamento.lcto_valor == 0){
            msgs += 'Digite um Valor válido; </br>';
        } 
        if (this.lancamento.tp_lancamento == null || this.lancamento.tp_lancamento == ''){
            msgs += 'Selecione se o Lançamento e no Caixa ou Banco; </br>';
        }
        if ((this.lancamento.cbnc_codigo == null || this.lancamento.cbnc_codigo == 0) && (this.lancamento.tp_lancamento == 'BANCO')){
            msgs += 'Selecione uma Conta Bancária; </br>';
        }
        if ((this.lancamento.cxa_codigo == null || this.lancamento.cxa_codigo == 0) && (this.lancamento.tp_lancamento == 'CAIXA')){
            msgs += 'Selecione um Caixa; </br>';
        }
        if (this.lancamento.tqui_codigo == null || this.lancamento.tqui_codigo == 0){
            msgs += 'Selecione o Tipo de Documento; </br>';
        }        
        if (this.lancamento.ccst_codigo == null || this.lancamento.ccst_codigo == 0){
            msgs += 'Selecione um Centro de Custo; </br>';
        }
        if (this.lancamento.lcto_cpart_codigo == null || this.lancamento.lcto_cpart_codigo == 0){
            msgs += 'Selecione um Plano de Contas (Contra Partida); </br>';
        }
        if (this.lancamento.lcto_historico == null || this.lancamento.lcto_historico == 0){
            msgs += 'Selecione um Histórico; </br>';
        }
        if (this.lancamento.tqui_descricao === 'CHEQUE'){
            if (this.lancamento.lcto_ch_numero == null || this.lancamento.lcto_ch_numero == ''){
                msgs += 'Digite o Número do Cheque; </br>';
            }            
            if (this.lancamento.lcto_ch_vencimento == null || this.lancamento.lcto_ch_vencimento == 0){
                msgs += 'Digite a Data de Vencimento do Cheque; </br>';
            }            
            if ((this.lancamento.lcto_ch_banco == null || this.lancamento.lcto_ch_banco == 0) && (this.lancamento.lcto_tipo !== '1')){
                msgs += 'Selecione o Banco do Cheque; </br>';
            }            
            if ((this.lancamento.lcto_ch_agencia == null || this.lancamento.lcto_ch_agencia == '') && (this.lancamento.lcto_tipo !== '1')){
                msgs += 'Digite a Agência do Cheque; </br>';
            }            
            if ((this.lancamento.lcto_ch_conta == null || this.lancamento.lcto_ch_conta == '') && (this.lancamento.lcto_tipo !== '1')){
                msgs += 'Digite a Conta Corrente do Cheque; </br>';
            }            
            if ((this.lancamento.lcto_ch_pes_codigo == null || this.lancamento.lcto_ch_pes_codigo == 0) && (this.lancamento.lcto_tipo !== '1')){
                msgs += 'Digite a Pessoa do Cheque; </br>';
            }            
        }
        
        if (msgs !== ''){
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Incluir o Lançamento',
                html: msgs,
                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
            })        
        }else{
            
            let URL = '/escfinanceiro/lancamento/novo'
            var dados = this.lancamento
            await axios.post(`${URL}`,dados)
                .then(response => {
                    this.message = response.data.message
                    this.status = response.status
                    this.errors = {}
                    
                    this.$swal('Sucesso!',
                    'Registro incluído com sucesso!',
                    'success');
                    this.onLimpar()
                })
                .catch(response => {
                    this.message = response.response.data.message
                    if (response.response.data.error){
                        this.errors = response.response.data.error.errorInfo[2]              
                    }else{
                        this.errors = response.response.data.errors
                    }
                    this.status = response.response.status
                    
                })
        }

    },
    /* lancamentos */
    setTpQuitacao (data) {
        this.tpQuitacao = data
    },
    onLimpar () {
        this.lancamento.lcto_codigo = null
        this.lancamento.tp_lancamento = 'CAIXA'
        this.lancamento.lcto_tipo = 2/*1-DEB 2-CRED */
        this.lancamento.lcto_data = this.moment(new Date()).format('YYYY-MM-DD')
        this.lancamento.lcto_valor = 0.00
        this.lancamento.ccst_codigo = null
        this.lancamento.cbnc_codigo = null
        this.lancamento.cbnc_ent = this.Entidade
        this.lancamento.cxa_codigo = null
        this.lancamento.cxa_ent = this.Entidade
        this.lancamento.lcto_cpart_codigo = null
        this.lancamento.tqui_codigo = null
        this.lancamento.tqui_descricao = null
        this.lancamento.lcto_historico = null            
        this.lancamento.lcto_ch_numero = null
        this.lancamento.lcto_ch_vencimento = null
        this.lancamento.lcto_ch_banco = null
        this.lancamento.lcto_ch_agencia = null
        this.lancamento.lcto_ch_conta = null
        this.lancamento.lcto_ch_pes_codigo = null            
    }
  },
  computed: {
    ...mapState('Login',['infoLogado','Entidade', 'Exercicio', 'Escola']),
  },
  watch: {
    'lancamento.cbnc_codigo' : function (newVal, oldVal) {
        /*BUSCANDO O ULTIMO NUMERO DO CHEQUE*/
        if (this.lancamento.tqui_descricao === 'CHEQUE' && this.lancamento.lcto_tipo == '1'){
            URL = '/esccontabancaria/ultcheque?cbnc_codigo='+newVal
            axios.get(`${URL}`)
                .then(response => {
                    this.lancamento.lcto_ch_numero = response.data.data
                    if (this.lancamento.lcto_ch_vencimento == null || this.lancamento.lcto_ch_vencimento == 0){
                        this.lancamento.lcto_ch_vencimento = new Date()
                    }
                    this.errors = {}
                })
                .catch(response => {
                    this.message = response.response.data.message
                    this.errors = response.response.data.errors
                    this.status = response.response.status
                })
        }
    },
    'lancamento.tqui_codigo' : function (newVal, oldVal) {
        let ret = this.tpQuitacao.filter(el => {
            return el.tqui_codigo == newVal;
        })
        if (typeof ret[0] !== 'undefined'){
            this.lancamento.tqui_descricao = ret[0].tqui_descricao
        }else{
            this.lancamento.tqui_descricao = ''
        }
    }
  },
  mounted() {
    setTimeout(() => {
      
    }, 1000)
    this.lancamento.cbnc_ent    = this.Entidade 
    this.lancamento.cxa_ent     = this.Entidade
    this.onGetDados()
    
  },  
  updated() {

  },     
}
</script>
