<template>
  <div class="mct-form">
      <mct-topo-lista titulo="Relatórios" v-show="!showModal"></mct-topo-lista>

      <div class="row">
        <div class="col-2">
            <ul class="list-group h-100 bg-white list-group-relatorios" id="list-tab" role="tablist">
                <span v-for="(item,index) in relatoriosPedagogicos.children" :key="index">
                    <li class="bg-success text-white p-2">{{item.name}}</li>
                    <li class="list-group-item" v-for="(children,idxc) in item.children" :key="idxc" :class="relatorio === children.relatorio ? 'active' : ''">
                        <a class="btn" :href="`#card${children.relatorio}`" aria-expanded="true" :aria-controls="`card${children.relatorio}`" @click="relatorio = children.relatorio">
                        {{children.name}}
                        </a>                            
                    </li>
                </span>    

            </ul>
        </div>
        <div class="col-3">
            <div class="bg-white mr-2 h-100 p-2 rounded">
                <pnl-ficha-individual :outputPdf.sync="outputPdf" v-if="relatorio == 7"/>
                <pnl-alunos-sus :outputPdf.sync="outputPdf" v-if="relatorio == 9"/>
                <pnl-professores-graduacao :outputPdf.sync="outputPdf" v-if="relatorio == 10"/>
                <pnl-alunos-turma :outputPdf.sync="outputPdf" v-if="relatorio == 1"/>
                <pnl-lista-presenca-turma :outputPdf.sync="outputPdf" v-if="relatorio == 2"/>
                <pnl-ficha-turma :outputPdf.sync="outputPdf" v-if="relatorio == 3"/>
                <pnl-agendamentos :outputPdf.sync="outputPdf" v-if="relatorio == 4"/>
                <pnl-diario-classe :outputPdf.sync="outputPdf" v-if="relatorio == 5"/>
                <pnl-acompanhamento-aluno :outputPdf.sync="outputPdf" v-if="relatorio == 8"/>
                <pnl-boletim :outputPdf.sync="outputPdf" v-if="relatorio == 6"/>
            </div>          
        </div>

        <div class="col-7 bg-dark rounded">
            <PDFView :url="outputPdf === null ? '' : outputPdf"/>
        </div>
      </div>      
  </div>
</template>
<script>

  import { mapState } from 'vuex'
  
  import PDFView from '@/components/pdfviewer/PDFView.vue' 

  import MctTopoLista from '@/components/comum/MctTopoLista.vue'

  /**PAINEIS*/
  import pnlAlunosTurma from "./panels/pnlAlunosTurma.vue";
  import pnlListaPresencaTurma from "./panels/pnlListaPresencaTurma.vue";
  import pnlFichaTurma from "./panels/pnlFichaTurma.vue";
  import pnlAgendamentos from "./panels/pnlAgendamentos.vue";
  import pnlDiarioClasse from "./panels/pnlDiarioClasse.vue";
  import pnlBoletim from "./panels/pnlBoletim.vue";
  import pnlFichaIndividual from "./panels/pnlFichaIndividual.vue";
  import pnlAcompanhamentoAluno from "./panels/pnlAcompanhamentoAluno.vue";
  import pnlAlunosSus from "./panels/pnlAlunosSUS.vue";
  import pnlProfessoresGraduacao from "./panels/pnlProfessoresGraduacao.vue";

  export default {
    name: 'RelEscPedagogico',
    components: {
        PDFView,
        MctTopoLista,
        pnlAlunosTurma,
        pnlListaPresencaTurma,
        pnlFichaTurma,
        pnlAgendamentos,
        pnlDiarioClasse,
        pnlBoletim,
        pnlFichaIndividual,
        pnlAcompanhamentoAluno,
        pnlAlunosSus,
        pnlProfessoresGraduacao
    },    
    props: ['source', 'titulo', 'showModal'],
    data() {
      return {
        relatorio: 0,
        outputPdf: null,       
      }
    },   
    computed: {
        ...mapState('Login',['infoEntidade','infoMenuSideBar']),
        relatoriosPedagogicos () {
            try {
                const relatorios = this.infoMenuSideBar.filter(item => item.id == 626)
                const pedagogico = relatorios[0].children.filter(item => item.id == 628)
                pedagogico[0].children.forEach((element,index,arr) => {
                    const regex = /[\/](\w*)[\/](\d*)[\/]?(\d*)?/
                    const array = regex.exec(element.url)
                    arr[index].group = array[2]

                    element.children.forEach((elementC,indexC,arrC) => {
                        const regex = /[\/](\w*)[\/](\d*)[\/]?(\d*)?/
                        const array = regex.exec(elementC.url)
                        arrC[indexC].group = array[2]
                        arrC[indexC].relatorio = array[3]
                    });
                });    
                console.log(pedagogico[0])
                return pedagogico[0]
            }
            catch (error){
                return []
            }
        }
    },
    methods: {        
      onImprimirPDF() {
        printJS({printable: this.outputPdf, type: 'pdf'})
      },
    }
  }
</script>

<style scoped>

.list-group {
    border: 0;
    border-top: 4px solid #60C060;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.btn {
  box-shadow: none !important;
  outline: 0;
}

.list-group-item {
   border: 0;
}

.list-group-item a {
    font-size: 14px;    
}

.list-group-item a:link,
.list-group-item a:visited {
  color: #222;
}

.list-group-item a:hover,
.list-group-item a:focus {
  color: #5DA287;
  font-weight: 500;
}

.list-group-item span {
  border: solid #222;
  border-width: 0 1px 1px 0;
  display: inline;
  cursor: pointer;
  padding: 3px;
  position: absolute;
  right: 0;
  margin-top: 10px;
}

.list-group-item a.btn.collapsed span {
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  transition: .3s transform ease-in-out;
}

.list-group-item a.btn span {
  transform: rotate(-140deg);
  -webkit-transform: rotate(-140deg);
  transition: .3s transform ease-in-out;
}

.card {
    border: 0;
}

.list-group-item.active {
    background-color: #292834!important;
    border: 0 !important;
}

.list-group-item.active a{
    color: white!important;
}


</style>