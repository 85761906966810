<template>
<!-- RELATORIOS CONTAS A PAGAR - A PAGAR -->
    <div>
        <div class="row mb-2">
            <div class="col-lg-4">
                <fieldset class="well h-100">
                    <legend class="well-legend">Data Base</legend>
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <mct-form-itens tipo="date" campo="data_base" :valor="filtro" titulo="Data Base" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div> 
            <div class="col-lg-4">
                <fieldset class="well h-100">
                    <legend class="well-legend">Relatório</legend>
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <mct-form-itens tipo="radio" campo="detalhado" :valor="filtro" titulo="Tipo de Rel." showTitulo="false" :errors="{}" 
                            :origemData="[{value:0,text:'Resumido'}, {value:1,text:'Detalhado'}]" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>                            

        </div>
        <div class="row mb-2">
            <div class="col-lg-4">
                <fieldset class="well h-100">
                    <legend class="well-legend">Data</legend>
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <mct-form-itens tipo="date" campo="data_ini" :valor="filtro" titulo="Inicial" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <mct-form-itens tipo="date" campo="data_fin" :valor="filtro" titulo="Final" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-lg-4">
                <fieldset class="well h-100">
                    <legend class="well-legend">Tipo Data</legend>
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <mct-form-itens tipo="radio" campo="tipodata" :valor="filtro" titulo="Tipo de Data" showTitulo="false" :errors="{}" 
                            :origemData="[{value:'E',text:'Emissão'}, {value:'V',text:'Vencimento'}]" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-lg-4">
                <fieldset class="well h-100">
                    <legend class="well-legend">Tipo Relatório</legend>
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <mct-form-itens tipo="radio" campo="tipoconta" :valor="filtro" titulo="Tipo de Rel." showTitulo="false" :errors="{}" 
                            :origemData="[{value:'V',text:'Vencidas'}, {value:'A',text:'A Vencer'},{value:'TA',text:'Todas'}]" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>                            
        </div>
        <div class="row mb-4">
            <div class="col-lg-8">
                <fieldset class="well h-100">
                    <legend class="well-legend">Fornecedor/ Pessoa</legend>
                    <div class="row">                                                        
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-2">
                                    <mct-form-itens tipo="number" campo="pessoa_ini" :valor="filtro" titulo="Inicial" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-10">
                                    <mct-form-itens tipo="select" campo="pessoa_ini" :valor="filtro" titulo=" " origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-2">
                                    <mct-form-itens tipo="number" campo="pessoa_fin" :valor="filtro" titulo="Final" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-10">
                                    <mct-form-itens tipo="select" campo="pessoa_fin" :valor="filtro" titulo=" " origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-lg-4">
                <fieldset class="well h-100">
                    <legend class="well-legend">Agrupar Por</legend>
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <mct-form-itens tipo="radio" campo="tipoagrupar" :valor="filtro" titulo="Agrupar Por" showTitulo="false" :errors="{}" 
                            :origemData="[{value:1,text:'Fornecedor'}, {value:2,text:'Data Vencimento'}]" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>                            
        </div>
        <div class="row mb-2">
            <div class="col-sm-12 text-left">
                <button type="button" class="btn btn-secondary mr-2" aria-label="Left Align" @click="onCPagarAPagar">
                <span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Visualizar</button>     
            </div>
        </div>
        <b-modal id="relatorio" title="Relatório Financeiro" hide-footer hide-header hide-title size="xl">
            <PDFView :url="localOutputPdf === null ? '' : localOutputPdf" />
        </b-modal>        
    </div>
</template>

<script>
import axios from '@/http';
import moment from 'moment';
import PDFView from "@/components/pdfviewer/PDFView.vue";
import MctFormItens from "@/components/comum/MctFormItens.vue";
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios';

export default {
    props: ['outputPdf'],
    mixins: [MctPadraoRelatorios],
    components: {
        PDFView,
        MctFormItens: MctFormItens
    },
    data() {
        return {
        data: [],
        filtro: {},
        localOutputPdf: {}
        };
    },
    created() {
        this.localOutputPdf = this.outputPdf;
        this.filtro = {
        detalhado: 0,
        tipo: 'P',
        modelo: 1,
        opcoes: {},
        tipoconta: 'TA',
        tipodata: 'E',
        tipoordem: 3,
        tipoagrupar: 1,
        tipovisualizacao: 1,
        data_base: null,
        data_ini: null,
        data_fin: null,
        pessoa_ini: null,
        pessoa_fin: null,
        conta_ini: null,
        conta_fin: null,
        tprecebimento: null,
        tpquitacao: null,
        ccusto: null
        }
    },
    methods: {
        async getData() {
            await axios.get('/relatorios/financeiro',{params:  {
                detalhado: this.filtro.detalhado,
                tipoordem: this.filtro.tipoordem,
                tipoagrupar: this.filtro.tipoagrupar,
                pessoaini: this.filtro.pessoa_ini,
                pessoafin: this.filtro.pessoa_fin,
                containi: this.filtro.conta_ini,
                contafin: this.filtro.conta_fin,
                ccusto: this.filtro.ccusto,
                tipo: this.filtro.tipo,
                tipoc: this.filtro.tipoconta,
                database: moment(this.filtro.data_base).isValid() ? moment(this.filtro.data_base).format('YYYY-MM-DD') : null,
                emiini: moment(this.filtro.data_ini).isValid() && this.filtro.tipodata === 'E' ? moment(this.filtro.data_ini).format('YYYY-MM-DD') : null,
                emifin: moment(this.filtro.data_fin).isValid() && this.filtro.tipodata === 'E' ? moment(this.filtro.data_fin).format('YYYY-MM-DD') : null,
                vencini: moment(this.filtro.data_ini).isValid() && this.filtro.tipodata === 'V' ? moment(this.filtro.data_ini).format('YYYY-MM-DD') : null,
                vencfin: moment(this.filtro.data_fin).isValid()  && this.filtro.tipodata === 'V'? moment(this.filtro.data_fin).format('YYYY-MM-DD') : null,
                quitini: moment(this.filtro.data_ini).isValid() && this.filtro.tipodata === 'P' ? moment(this.filtro.data_ini).format('YYYY-MM-DD') : null,
                quitfin: moment(this.filtro.data_fin).isValid() && this.filtro.tipodata === 'P' ? moment(this.filtro.data_fin).format('YYYY-MM-DD') : null              
            },
            paramsSerializer: function (params) {
                return jQuery.param(params)
            }
            })
            .then(response => {
                this.data = response.data.data                    
            })
            .catch(response => {
                console.log(response.message)
            })            
        },        
        async onCPagarAPagar () {

            await this.getData();

            this.onCreatePDF()
            /*CABEÇALHO*/
            this.title = 'RELATÓRIO DE CONTAS A PAGAR';
            if (this.filtro.tipoconta === 'TA'){
                this.title += ' - VENCIDAS E A VENCER';
            } else if (this.filtro.tipoconta === 'V'){
                this.title += ' - VENCIDAS';
            } else if (this.filtro.tipoconta === 'A'){
                this.title += ' - A VENCER';
            }
            this.onHeaderPDF();
            this.pdf.setFont('courier') 
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)     
                    
            if(moment(this.filtro.data_base).isValid()){
                this.pdf.text(`DATA BASE: ${moment(this.filtro.data_base).format('DD/MM/YYYY')}`,8,this.lin)
                this.onAddLine(this.jump)
            }
            if(moment(this.filtro.data_ini).isValid()){
                this.pdf.text(`PERÍODO DE ${this.filtro.tipodata === 'E' ? 'EMISSÃO' : this.filtro.tipodata === 'V' ? 'VENCIMENTO' : '' } ENTRE ${moment(this.filtro.data_ini).format('DD/MM/YYYY')} A ${moment(this.filtro.data_fin).format('DD/MM/YYYY')}`,8,this.lin)
                this.onAddLine(this.jump)
            }
            if(this.filtro.pessoa_ini){
                this.pdf.text(`FORNECEDOR ${this.filtro.pessoa_ini} ATÉ ${this.filtro.pessoa_fin}`,8,this.lin)
                this.onAddLine(this.jump)
            }

            this.onAddLine(this.jump)
            this.pdf.setFillColor(192,192,192)
            this.pdf.rect(8,this.lin-3,195,this.jump+1,'FD')
            this.pdf.setFont('helvetica','bold') 
            if (this.filtro.tipoagrupar === 2){
                this.pdf.text('DT.VENC.',25,this.lin,{align: 'right'})
                this.pdf.text("NOME", 28, this.lin);
                this.pdf.text("DOCUMENTO", 98, this.lin, { align: "right" });
                this.pdf.text('VALOR',118,this.lin,{align: 'right'})
                this.pdf.text('VALOR VENC.',138,this.lin,{align: 'right'})
                this.pdf.text('DT.EMIS.',158,this.lin,{align: 'right'})
                this.pdf.text('ATRS',168,this.lin,{align: 'right'})
                this.pdf.text('ULTIMO PGTO',188,this.lin,{align: 'right'})
                //this.pdf.text('COND. PGTO',141,this.lin)
                //this.pdf.text('NOTA FISCAL',181,this.lin)
            } else if (this.filtro.tipoagrupar !== 2) 
            {
                this.pdf.text('DT.EMIS.',25,this.lin,{align: 'right'})
                this.pdf.text('DOCUMENTO',45,this.lin,{align: 'right'})
                this.pdf.text('VALOR',65,this.lin,{align: 'right'})
                this.pdf.text('VALOR VENC.',85,this.lin,{align: 'right'})
                this.pdf.text('DT.VENC.',105,this.lin,{align: 'right'})
                this.pdf.text('ATRS',120,this.lin,{align: 'right'})
                this.pdf.text('ULTIMO PGTO',140,this.lin,{align: 'right'})
                this.pdf.text('COND. PGTO',141,this.lin)
                this.pdf.text('NOTA FISCAL',181,this.lin)
            }

            this.onAddLine(this.jump+1)

            var somaValor = 0
            var somaVencido = 0
            var somaValorForn = 0
            var somaVencidoForn = 0

            var grupo = 0

            for (var index in this.data) {
                this.pdf.setFont('courier') 
                /*NOME PESSOA*/
                if (this.filtro.tipoagrupar === 1 && grupo !== this.data[index].pes_codigo)
                {
                    grupo = this.data[index].pes_codigo
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(8,this.lin-3,195,this.jump+1,'FD')
                    this.pdf.setFont('courier') 
                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.text('FORNECEDOR:',30,this.lin,{align: 'right'})
                    this.pdf.text(this.data[index].pes_nome+' - '+this.data[index].pes_codigo,32,this.lin)        
                    this.onAddLine(this.jump+1)
                }/*AGRUPADO PELA DATA VENCIMENTO*/ 
                else if (this.filtro.tipoagrupar === 2 && grupo !== this.data[index].docp_vencimento) 
                {
                    grupo = this.data[index].docp_vencimento;
                    this.pdf.setFontSize(7);
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text(String(this.$options.filters.data(this.data[index].docp_vencimento) ) !== "null" ? String(this.$options.filters.data(this.data[index].docp_vencimento)): "",26,this.lin,{ align: "right" });
                    this.pdf.setFont('helvetica',"normal");
                }

                this.pdf.setFont('helvetica','normal')
                this.pdf.setFontSize(7.5)
                if (this.filtro.tipoagrupar === 1){
                    this.pdf.text(String(this.$options.filters.data(this.data[index].doc_emissao)) !== 'null' ? String(this.$options.filters.data(this.data[index].doc_emissao)) : '',25,this.lin,{align: 'right'})
                    this.pdf.text(this.data[index].docp_numero,45,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(this.data[index].docp_valor)),65,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(this.data[index].docp_vlr_apagar)),85,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.data(this.data[index].docp_vencimento)) !== 'null' ? String(this.$options.filters.data(this.data[index].docp_vencimento)) : '',105,this.lin,{align: 'right'})
                    this.pdf.text(this.data[index].docp_dias_atraso > 0 ? String(this.data[index].docp_dias_atraso) : '',120,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.data(this.data[index].docp_pagamento)) !== 'null' ? String(this.$options.filters.data(this.data[index].docp_pagamento)) : '',140,this.lin,{align: 'right'})
                    this.pdf.text(String(this.data[index].cpg_nome),141,this.lin)
                    this.pdf.text(this.data[index].docp_notafiscal !== null ? String(this.data[index].docp_notafiscal) : '',181,this.lin)
                } else if (this.filtro.tipoagrupar === 2){
                    this.pdf.text(String(this.data[index].pes_nome).substring(0,31),28,this.lin)
                    this.pdf.text(this.data[index].docp_numero,98,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(this.data[index].docp_valor)),118,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(this.data[index].docp_vlr_apagar)),138,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.data(this.data[index].doc_emissao)) !== 'null' ? String(this.$options.filters.data(this.data[index].doc_emissao)) : '',158,this.lin,{align: 'right'})
                    this.pdf.text(this.data[index].docp_dias_atraso > 0 ? String(this.data[index].docp_dias_atraso) : '',168,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.data(this.data[index].docp_pagamento)) !== 'null' ? String(this.$options.filters.data(this.data[index].docp_pagamento)) : '',188,this.lin,{align: 'right'})
                }
                somaValor += Number(this.data[index].docp_valor)
                somaVencido += Number(this.data[index].docp_vlr_apagar)
                somaValorForn += Number(this.data[index].docp_valor)
                somaVencidoForn += Number(this.data[index].docp_vlr_apagar)
                this.onAddRegLine()
                this.onAddLine(this.jump)

                if (this.filtro.tipoagrupar === 1 && (parseInt(index)+1 == this.data.length || grupo !== this.data[parseInt(index)+1 < this.data.length ? parseInt(index)+1 : index].pes_codigo)){
                    this.onAddLine(1)
                    this.pdf.setFont('courier') 
                    this.pdf.setFontSize(7.5)
                    this.pdf.line(8,this.lin-this.jump,203,this.lin-this.jump)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('TOTAL DO FORNECEDOR:',45,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaValorForn)),65,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaVencidoForn)),85,this.lin,{align: 'right'})
                    this.onAddLine(this.jump+1)
                    somaValorForn = 0;
                    somaVencidoForn = 0;
                } else if (this.filtro.tipoagrupar === 2 && (parseInt(index)+1 == this.data.length || grupo !== this.data[parseInt(index)+1 < this.data.length ? parseInt(index)+1 : index].docp_vencimento)){
                    this.onAddLine(1)
                    this.pdf.setFont('courier') 
                    this.pdf.setFontSize(7.5)
                    this.pdf.line(8,this.lin-this.jump,203,this.lin-this.jump)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text(String(`TOTAL DA DATA (${String(this.$options.filters.data(this.data[index].docp_vencimento)) !== "null"? String(this.$options.filters.data(this.data[index].docp_vencimento)): ""}):`),98,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaValorForn)),118,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaVencidoForn)),138,this.lin,{align: 'right'})
                    this.onAddLine(this.jump+1)
                    somaValorForn = 0;
                    somaVencidoForn = 0;
                }
            }
            
            /** TOTAL FINAL */
            this.onAddLine(1)
            this.pdf.setFont('courier') 
            this.pdf.setFontSize(7.5)
            this.pdf.line(8,this.lin-this.jump,203,this.lin-this.jump)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('TOTAL GERAL:',45,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaValor)),65,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaVencido)),85,this.lin,{align: 'right'})
            this.onAddLine(this.jump+1)
            somaValor = 0;
            somaVencido = 0;
                                   
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            //this.pdf.output('datauristring');
            const urlPDF = this.pdf.output('blob');
            this.localOutputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
            this.$bvModal.show("relatorio");

            //printJS({printable: this.outputPdf, type: 'pdf'})
            //this.pdf.save(`contas-a-pagar-${uuidv4}.pdf`)
        },
    },
    watch: {
        localOutputPdf(newValue, oldValue) {
            //this.$emit("update:outputPdf", newValue);
        }
    },

}
</script>