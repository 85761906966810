<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <div class="row titulo">
                <div class="col-12">
                    <h4>Dados Aluno</h4>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="" :valor="lista" titulo="R.A." obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-6">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Nome" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-3">
                    <mct-form-itens tipo="date" campo="pes_data_cadastro" :valor="lista" titulo="Nascimento" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>

                <div class="col-sm-2">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="CPF" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-2">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="RG-Orgão Exp./UF" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-1">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-1">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-6">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="E-mail" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>

                <div class="col-sm-3">
                    <mct-form-itens coluna="0" tipo="number" campo="" :valor="lista" titulo="CEP" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-6">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Logradouro" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-3">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Número" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>

                <div class="col-sm-5">
                    <mct-form-itens coluna="0" tipo="number" campo="" :valor="lista" titulo="Bairro" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-5">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Cidade" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-2">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Estado" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>

                <div class="col-sm-2">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Responsável Financeiro" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>

            <div class="row titulo">
                <div class="col-sm-12">
                    <h4>Dados Responsável Financeiro</h4>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-8">
                    <mct-form-itens coluna="0" tipo="number" campo="" :valor="lista" titulo="Nome" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-4">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Nascimento" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>

                <div class="col-sm-2">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="CPF" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-2">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="RG-Orgão Exp./UF" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-1">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-1">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-6">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="E-mail" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>

                <div class="col-sm-5">
                    <mct-form-itens coluna="0" tipo="number" campo="" :valor="lista" titulo="Bairro" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-5">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Cidade" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-2">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Estado" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>

                <div class="col-sm-6">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Pai" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-6">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Mãe" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>

                <div class="col-sm-3">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Telefone" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-3">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Celular" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-4">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Cidade Natal" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-2">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Estado" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>

            <div class="row titulo">
                <div class="col-sm-12">
                    <h4>Dados do Pai</h4>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-8">
                    <mct-form-itens coluna="0" tipo="number" campo="" :valor="lista" titulo="Nome" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-4">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Nascimento" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>

                <div class="col-sm-2">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="CPF" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-2">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="RG-Orgão Exp./UF" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-1">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-1">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-6">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="E-mail" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>

                <div class="col-sm-5">
                    <mct-form-itens coluna="0" tipo="number" campo="" :valor="lista" titulo="Bairro" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-5">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Cidade" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-2">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Estado" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>

                <div class="col-sm-6">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Pai" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-6">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Mãe" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>

                <div class="col-sm-3">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Telefone" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-3">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Celular" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-4">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Cidade Natal" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
                <div class="col-sm-2">
                    <mct-form-itens tipo="text" campo="pes_data_cadastro" :valor="lista" titulo="Estado" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
        </div>
    </mct-padrao>
</template>

<script>
    import MctPadrao from '@/components/comum/MctFormPadrao.vue'
    import MctFormItens from '@/components/comum/MctFormItens.vue'

    export default {
        Name: 'DadosPessoais',
        props: ['source','titulo','modo'],
        components: {
            MctPadrao,
            MctFormItens,
        },
        data () {
            return {
                lista: {},
                errors:{},
                message: '',
                status: 0,
            }
        },
        methods: {
            setData(lista, colunms, mode, errors) {
                this.lista = lista
                this.errors = errors
            }
        },
    }
</script>

<style scoped>
    .titulo {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .titulo  h4 {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        text-align: left;
        color: #666;
    }
</style>
