<template>
    <div>
        <div class="row m-0 mb-2 p-2 mct-barra-sub">
        <button type="button" class="btn btn-success" aria-label="Left Align" @click="$router.push({path: '/escextrato/banco'})">
            <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Conta Bancária </button>
        <button type="button" class="btn btn-danger ml-1" aria-label="Left Align" @click="$router.push({path: '/escextrato/caixa'})">
            <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Caixa </button>
        </div>

        <router-view />
    </div>
</template>

<script>
export default {
    name: 'EscExtrato',
    data () {
        return {
        }
    }
}
</script>