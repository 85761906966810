<template>
    <mct-padrao titulo="Alterar Senha Usuário" @submit="submit()" :titAlerta="titAlerta" :msgAlerta="message" :tipoAlerta="tipoAlerta" modo="alterar">
        <div slot="formulario">
            <div class="input-group input-group-sm">
                <label class="col-sm-2 input-group-addon bg-light">Usuário:</label>     
                <span class="input-group-addon col-md-2 bg-info text-white border-seq p-2 text-center" style="font-size: 1.4rem!important;"
                    id="txtUsuario">{{ infoLogado.usu_usuario }}</span> 
            </div>  
            <div class="input-group input-group-sm">
                <label class="col-sm-2 input-group-addon bg-light">Senha Atual:<small class="mct-obg"> * </small></label>        
                <input type="password" id="txtSenha" placeholder="Senha" class="form-control col-md-2 border-red"  
                    v-model="usu_senha" required
                    >
                <div class="invalid-feedback">* Digite a Senha atual do Usuário.</div>    
            </div>  

            <div class="input-group input-group-sm">
                <label class="col-sm-2 input-group-addon bg-light">Nova Senha:<small class="mct-obg"> * </small></label>        
                <input type="password" id="txtNovaSenha" placeholder="Nova Senha" class="form-control col-md-2 border-red"  
                    v-model="usu_nova_senha" 
                    >
            </div>
            <div class="input-group input-group-sm">
                <label class="col-sm-2 input-group-addon bg-light">Confirme a Senha:<small class="mct-obg"> * </small></label>        
                <input type="password" id="txtConfNovaSenha" placeholder="Confirme a Nova Senha" class="form-control col-md-2 border-red"  
                    v-model="usu_conf_nova_senha" 
                    >
            </div>
        </div>
    </mct-padrao>      
</template>

<script>
import axios from '@/http'
import MctPadrao from '../comum/MctFormPadraoSCB.vue'
import { mapState, mapActions } from 'vuex'

export default {
  Name: 'AlterarSenha',  
  components: {
      MctPadrao
  },
  data () {
      return {
          usu_senha: '',
          usu_nova_senha: '',
          usu_conf_nova_senha: '',
          message: '',
          status: '',
          tipoAlerta: '',
          titAlerta: ''
      }
  },
  methods: {
      submit () {
          //alert('clicou')
          this.alterarSenha()
      },
      alterarSenha () {
         let msg = '' 
        //event.preventDefault()  
        if (this.usu_senha === ''){
            msg += 'Digite a Senha atual do Usuário. </br>'
        }                      
        if (this.usu_nova_senha === '' || this.usu_conf_nova_senha === ''){
            msg += 'Digite a Nova Senha do Usuário. </br>'
        }                      
        if (this.usu_nova_senha !== this.usu_conf_nova_senha){
            msg += 'Nova Senha não confirmada. </br>'
        } 
        if (msg !== ''){
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao alterar a senha',
                html: msg,                
                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
            })
        }
        this.$swal({
            title: 'Deseja realmente alterar sua senha de usuário?',
            text: "Este evento não podera ser revertido!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                axios.post(`auth/alterarsenha`,
                {
                    usu_codigo: this.infoLogado.usu_codigo,
                    usu_usuario: this.infoLogado.usu_usuario,
                    usu_senha: this.usu_senha,
                    usu_nova_senha: this.usu_nova_senha
                })
                .then(response => {
                    this.$swal('Sucesso!',
                        'Senha alterada com sucesso!',
                        'success');
                    this.$router.back() 
                })
                .catch(response => {
                    this.$swal('Erro!',
                                'Houve um erro ao tentar alterar a senha: </br> '+ response.response.data.message,
                                'error');                 
                    /*vm.$data.message = response.response.data.message
                    vm.$data.errors = response.response.data.errors
                    */
                })
            }
        })      
      }
  },
  computed: {
    ...mapState('Login',['infoLogado'])
  },  
}
</script>


<style scoped>

  .input-group {
    margin-top: 4px;
    margin-bottom: 4px;
  }

</style>