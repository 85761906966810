<template>
<!-- RELATORIOS CONTAS A PAGAR - A PAGAR -->
    <div>        
        <div class="row mb-4">
            <div class="col-lg-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Turma</legend>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_ini" :valor="filtro" titulo="Inicial" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_ini" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_fin" :valor="filtro" titulo="Final" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_fin" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-lg-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Etapa</legend>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="select" campo="etap_codigo" :valor="filtro" titulo="Etapa" origem="esc_etapas" origemChave="etap_codigo" origemRetorno='etap_descricao' :errors="{}" modo="editar" obrigatorio="true"></mct-form-itens>
                        </div>                       
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-12 text-left">
                <button type="button" class="btn btn-secondary mr-2" aria-label="Left Align" @click="onAcompanhamentoAluno()">
                <span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Visualizar</button>     
            </div>
        </div>        
    </div>
</template>

<script>
import axios from '@/http'

import { mapState } from 'vuex'

import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios'
import moment from 'moment'

export default {    
    components: {
        'MctFormItens': MctFormItens,
    },
    mixins: [MctPadraoRelatorios],
    data() {
        return {
            data: [],
            outputPdf: null,
            filtro: {
                turma_ini: null,
                turma_fin: null,
            }
        }
    },
    computed: {
        ...mapState('Login',['infoEscola','infoEntidade'])
    },
    methods : {
        async getData() {
            var url = '/acompanhamentoaluno';

            await axios.get(`/relatorios/siges${url}`,{params:  {
                turmaini: this.filtro.turma_ini,
                turmafin: this.filtro.turma_fin,
                etap_codigo: this.filtro.etap_codigo,
            },
            paramsSerializer: function (params) {
                return jQuery.param(params)
            }
            })
            .then(response => {
                this.data = response.data.data                    
            })
            .catch(response => {
                console.log(response.message)
            })
            
        },
        async onAcompanhamentoAluno () {

            await this.getData();

            await this.onCreatePDF()
            
            /*CABEÇALHO*/
            this.semData = true;
            this.semPagina = true;
            this.semRodape = true;
            this.title = 'RELATÓRIO DESCRITIVO DE DESENVOLVIMENTO DA CRIANÇA DA EDUCAÇÃO INFANTIL';
            this.onHeaderPDF();
            this.pdf.setFont('courier') 
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)     
                
            this.onAddLine(this.jump)
            
            var grupo = 0
            

            for (var index in this.data) {
                /*TURMA*/
                if (grupo !== this.data[index].pes_codigo){
                    if (grupo !== 0) this.onSaltarPagina2();
                    grupo = this.data[index].pes_codigo
                }
                
                this.pdf.setFontSize(8.5)     
                this.pdf.setFont('helvetica','normal')

                this.onAddLine(this.jump+2)
                this.pdf.setFont('helvetica','bold')
                this.pdf.text('UNIDADE:',10,this.lin)
                this.pdf.setFont('helvetica','normal')
                this.pdf.text(String(this.data[index].uni_descricao),47,this.lin)                       
                this.onAddLine(this.jump+1)                

                this.pdf.setFont('helvetica','bold')
                this.pdf.text('CRIANÇA:',10,this.lin)
                this.pdf.setFont('helvetica','normal')
                this.pdf.text(String(this.data[index].pes_nome),47,this.lin)                       
                this.onAddLine(this.jump+1)                

                this.pdf.setFont('helvetica','bold')
                this.pdf.text('DATA DE NASCIMENTO:',10,this.lin)
                this.pdf.setFont('helvetica','normal')
                this.pdf.text(String(this.$options.filters.data(this.data[index].pes_nascimento)),47,this.lin)                       

                this.pdf.setFont('helvetica','bold')
                this.pdf.text('IDADE:',67,this.lin)
                this.pdf.setFont('helvetica','normal')
                this.pdf.text(String(moment().diff(this.data[index].pes_nascimento, 'years')),79,this.lin)                       

                this.pdf.setFont('helvetica','bold')
                this.pdf.text('TURMA:',97,this.lin)
                this.pdf.setFont('helvetica','normal')
                this.pdf.text(String(this.data[index].turm_identificacao),109,this.lin)                       
                this.onAddLine(this.jump+1)                

                this.pdf.setFont('helvetica','bold')
                this.pdf.text('TURNO:',10,this.lin)
                this.pdf.setFont('helvetica','normal')
                this.pdf.text(String(this.data[index].turn_descricao),47,this.lin)                       

                // this.pdf.setFont('helvetica','bold')
                // this.pdf.text('PESO INICIAL:',62,this.lin)
                // this.pdf.setFont('helvetica','normal')
                // this.pdf.text(String('__________'),82,this.lin)                       

                // this.pdf.setFont('helvetica','bold')
                // this.pdf.text('PESO FINAL:',102,this.lin)
                // this.pdf.setFont('helvetica','normal')
                // this.pdf.text(String('__________'),120,this.lin)                       
                // this.onAddLine(this.jump+1)                

                // this.pdf.setFont('helvetica','bold')
                // this.pdf.text('ALTURA INICIAL:',10,this.lin)
                // this.pdf.setFont('helvetica','normal')
                // this.pdf.text(String('__________'),42,this.lin)                       

                // this.pdf.setFont('helvetica','bold')
                // this.pdf.text('ALTURA FINAL:',62,this.lin)
                // this.pdf.setFont('helvetica','normal')
                // this.pdf.text(String('__________'),84,this.lin)                       
                this.onAddLine(this.jump+1)
                
                this.pdf.setFont('helvetica','bold')
                this.pdf.text('PROFESSORA:',10,this.lin)
                this.pdf.setFont('helvetica','normal')
                this.pdf.text(String(this.data[index].prof_nome),47,this.lin)
                this.onAddLine(this.jump+2)
                
                this.pdf.line(8,this.lin,(this.widthPage+8),this.lin);
                this.onAddLine(this.jump+2)

                this.pdf.setFont('helvetica','bold')
                var splitText = this.pdf.splitTextToSize('Relatório descritivos – O relatório descritivo é um instrumento avaliativo que considera cada área do conhecimento de forma sintética, mas rica em detalhes do processo vivido pela criança em interação com outras, tendo como mediador dessas ações a Professora. Para elaborar o relatório descritivo a professora deverá seguir alguns princípios:', 180);
                this.pdf.text(10,this.lin,splitText,{align: 'justify', maxWidth: 190});
                this.onAddLine(this.jump+10)
                
                this.pdf.setFont('helvetica','normal')
                this.pdf.text('De acordo com a Proposta Pedagógica da Escola Cáritas Diocesana de Rondonópolis:',10,this.lin)
                this.onAddLine(this.jump+4)

                this.pdf.setFont('helvetica','italic')
                var splitText2 = this.pdf.splitTextToSize('A avaliação é uma prática em que crianças e Professoras interagem constantemente, quando a Professora, atenta às dificuldades apresentadas pelas crianças,  media suas produções, levanta questionamentos de forma a provocar a necessidade de pensar sobre a atividade, numa atitude de cooperação, visando a superação das suas dificuldades e potencialidades. Ao avaliar a criança a professora deve estabelecer critérios claros que deverá respeitar o tempo que a criança necessita para alcançar determinados conhecimentos, os limites e as potencialidades. (Proposta Pedagógica da Escola Cáritas 2019, PAG. 95-96)', 160);
                this.pdf.text(30,this.lin,splitText2,{align: 'justify', maxWidth: 170});
                this.onAddLine(this.jump+25)

                let textoSemPonto = this.data[index].aluacom_texto;
                if (textoSemPonto[textoSemPonto.length-1] !== '.') {
                    textoSemPonto += '.';
                }
    
                var splitText3 = this.pdf.splitTextToSize(textoSemPonto, 175);
                var qtdLinhasTexto = splitText3.length;
                var qtdFolhas = 0;

                while (qtdLinhasTexto > 0) {

                    const novoTexto = splitText3.slice(qtdFolhas*45,(qtdFolhas*45)+45);
                    this.pdf.setFontSize(8.5)     
                    this.pdf.setFont('helvetica','italic')
                    this.pdf.text(10,this.lin,novoTexto,{align: 'justify', maxWidth: 185});

                    // for (let index = (qtdFolhas*28); index < ((qtdFolhas*28)+28); index++) {
                    //     this.pdf.setFontSize(8.5)     
                    //     this.pdf.setFont('helvetica','italic')
                    //     //this.pdf.text(10,this.lin,String(splitText3[index]),{align: 'justify', maxWidth: 190});
                    //     this.pdf.text(String(splitText3[index]),10,this.lin,{align: 'justify', maxWidth: 190})

                    //     //this.pdf.text(String(this.data[index].prof_nome),47,this.lin)
                    //     this.onAddLine(this.jump+2)
                    //     console.log('linha: '+this.lin+' - '+index+'/'+qtdFolhas+28)                        
                    //     console.log(String(splitText3[index]));                        
                    // }
                    qtdLinhasTexto -= 45;
                    qtdFolhas++;
                    if (qtdLinhasTexto > 0) {
                        this.onSaltarPagina2();
                    }
                }

                if (qtdLinhasTexto > -17) {
                    this.onSaltarPagina2();
                }
                this.onAddLine(this.jump+10)
                this.pdf.setFontSize(8.5)     
                this.pdf.setFont('helvetica','bold')
                this.pdf.text(String(this.infoEntidade.ent_cidade+', '+this.$options.filters.data(new Date())+'.'),200,220,{align: 'right'})
                //this.onAddLine(this.jump+2)

                this.pdf.setFont('helvetica','bold')
                this.pdf.text(String('________________________________'),60,240,{align: 'center'})
                
                this.pdf.setFont('helvetica','bold')
                this.pdf.text(String('________________________________'),140,240,{align: 'center'})

                this.pdf.setFont('helvetica','bold')
                this.pdf.text(String('Professora Responsável'),60,245,{align: 'center'})
                this.pdf.setFont('helvetica','bold')
                this.pdf.text(String('Coordenadora'),140,245,{align: 'center'})
                //this.onAddLine(this.jump+2)

                this.pdf.setFont('helvetica','bold')
                this.pdf.text(String('________________________________'),60,260,{align: 'center'})
                
                this.pdf.setFont('helvetica','bold')
                this.pdf.text(String('________________________________'),140,260,{align: 'center'})

                this.pdf.setFont('helvetica','bold')
                this.pdf.text(String('Assistente Social'),60,265,{align: 'center'})

                this.pdf.setFont('helvetica','bold')
                this.pdf.text(String('Psicóloga'),140,265,{align: 'center'})
                
            }
                        
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            const urlPDF = this.pdf.output('blob');
            this.outputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
            this.$emit('update:outputPdf', this.outputPdf);
        },
    },
}
</script>