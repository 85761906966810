<template>
    <div>
        <div class="row">
            <div class="col-lg-2" v-show="rels['logra_codigo'] !== null">
                <mct-form-itens coluna="0" tipo="search-input" :campo="`${rels['logra_codigo']}`" :valor="valor" titulo="Cód. Logra." :obrigatorio="obrigatorio" :errors="errors" origem="logradouro" origemChave="logra_codigo" origemRetorno="logra_descricao" :modo="modo" tamanho="12" @setDataRetorno="setLogradouro"></mct-form-itens>
            </div>
            <div :class="rels['logra_codigo'] !== null ? 'col-lg-2' : 'col-lg-3'">
                <mct-form-itens coluna="0" tipo="text" :campo="`${rels['logra_cep']}`" :valor="valor" titulo="Cep" :obrigatorio="cepObrigatorio" :errors="errors" mascara="cep" :modo="modo" tabindex="-1" @onCep="onBuscaCep"></mct-form-itens>
            </div>
            <div :class="rels['logra_codigo'] !== null ? 'col-lg-6' : 'col-lg-7'">
                <mct-form-itens coluna="0" tipo="text" :campo="`${rels['logra_descricao']}`" :valor="valor" titulo="Logradouro"  obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
            </div>
            <div class="col-lg-2">
                <mct-form-itens tipo="text" :campo="`${rels['logra_numero']}`" :valor="valor" titulo="Número" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
            </div>                        
        </div>

        <div class="row">
            <div class="col-lg-5">
                <mct-form-itens coluna="0" tipo="text" :campo="`${rels['logra_bairro']}`" :valor="valor" titulo="Bairro" obrigatorio="false" :errors="errors" :modo="modo" tabindex="-1"></mct-form-itens>
            </div>
            <div class="col-lg-5">
                <mct-form-itens tipo="text" :campo="`${rels['logra_cidade']}`" :valor="valor" titulo="Cidade" obrigatorio="false" :errors="errors" :modo="modo" tabindex="-1"></mct-form-itens>
            </div>
            <div class="col-lg-2">
                <mct-form-itens tipo="select" :campo="`${rels['logra_estado']}`" :valor="valor" titulo="Estado" obrigatorio="false" origem="estado" origemChave="est_uf" origemRetorno='est_uf' :errors="errors" :modo="modo" tabindex="-1"></mct-form-itens>
            </div>
        </div> 

        <div class="row">            
            <div class="col-lg-12" v-if="rels['logra_complemento'] != ''">
                <mct-form-itens tipo="text" :campo="`${rels['logra_complemento']}`" :valor="valor" titulo="Complemento" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from '@/http'

import MctFormItens from './MctFormItens.vue'


export default {
    name: 'MctLogradouro',
    props: ['modo','rels','valor', 'obrigatorio', 'cepObrigatorio'],
    components: {
        MctFormItens
    },
    data () {
        return {
            errors: {}
        }
    },
    methods: {
        setLogradouro(data) {
            //console.log(data)
            this.valor[this.rels['logra_descricao']]    = data.logra_descricao
            this.valor[this.rels['logra_bairro']]       = data.logra_bairro
            this.valor[this.rels['logra_cidade']]       = data.logra_cidade
            this.valor[this.rels['logra_estado']]       = data.logra_estado
            this.valor[this.rels['logra_cep']]          = data.logra_cep
            this.valor[this.rels['logra_cidade_ibge']]  = data.logra_cidade_ibge
        },
        onBuscaCep() {
            this.$viaCep.buscarCep(this.valor[this.rels['logra_cep']]).then((obj) => {
                //console.log(obj)
                if (obj.erro){
                    this.$swal({
                        icon: 'error',
                        title: 'Houve um erro ao buscar o Cep',
                        text: 'O Cep digitado e inválido ou esta no formato errado',
                        footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                    })  
                } else {
                    this.valor[this.rels['logra_descricao']]    = obj.logradouro.toUpperCase()
                    this.valor[this.rels['logra_bairro']]       = obj.bairro.toUpperCase()
                    this.valor[this.rels['logra_cidade']]       = obj.localidade.toUpperCase()
                    this.valor[this.rels['logra_estado']]       = obj.uf.toUpperCase()
                    this.valor[this.rels['logra_cep']]          = obj.cep
                    this.valor[this.rels['logra_cidade_ibge']]  = obj.ibge
                }
            });            
        } 
    },    
    created() {

    } 
}
</script>
