<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
                            
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="cpg_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>  
                <div class="col-sm-3">
                    <mct-form-itens tipo="select" campo="cpg_tipo" :valor="lista" titulo="Tipo" obrigatorio="true" :errors="errors" 
                        :origemData="[{value: 'P', text: 'PAGAR'}, {value: 'R', text: 'RECEBER'}, {value: 'A', text: 'AMBOS'}]" :modo="modo"></mct-form-itens>    
                </div>

                <div class="col-sm-4">
                    <mct-form-itens tipo="checkbox" campo="cpg_status" :valor="lista" :titulo="lista.cpg_status == 1 ? 'Cadastro Ativo.' : 'Cadastro Inativado.'" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-9">
                    <mct-form-itens tipo="text" campo="cpg_nome" :valor="lista" titulo="Nome" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <mct-form-itens tipo="number" campo="cpg_nparcelas" :valor="lista" titulo="N.º Parcelas" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-sm-3">
                    <mct-form-itens tipo="number" campo="cpg_dias1" :valor="lista" titulo="Dias 1º Parcela" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-sm-3">
                    <mct-form-itens tipo="number" campo="cpg_dias2" :valor="lista" titulo="Dias Parc. Seguintes" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <mct-form-itens tipo="number" campo="cpg_descontomax" :valor="lista" titulo="Desconto Máximo" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-sm-3">
                    <mct-form-itens tipo="number" campo="cpg_reducaomax" :valor="lista" titulo="Redução Máxima" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
                <div class="col-sm-3">
                    <mct-form-itens tipo="number" campo="cpg_reducaoacima" :valor="lista" titulo="Redução Acima" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>

        </div>              
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'CondPag',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0
      }
  },
  methods: {
    setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            this.lista.cpg_status = 1
            this.lista.cpg_tipo = 'A'
        }
    }                       


  },
  computed: {

  }, 
  created() {
  
  }     
}
</script>