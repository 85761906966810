<template>
    <div>        
        <div class="mct-form">
            <mct-topo-lista :titulo="titulo" :subtitulo="`Contas Bancárias`"></mct-topo-lista>        
            <div class="bg-light p-2">
                <div class="row">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="select" campo="conta" :valor="filtro" titulo="Conta Bancária"  origem="esc_conta_bancaria" origemChave="cbnc_codigo" origemRetorno='cbnc_descricao' origemRetornoSec='cbnc_numero' @setDataLista="setContas" :errors="{}" modo="editar"></mct-form-itens>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-3">
                        <mct-form-itens tipo="radio" campo="tipo" :valor="filtro" titulo="Tipo" :errors="{}" :origemData="[{value:'3',text:'Todos'}, {value:'1',text:'Conciliados'}, {value:'0',text:'Pendentes'}]" modo="editar"></mct-form-itens>
                    </div>
                    <div class="col-lg-3">
                        <mct-form-itens tipo="date" campo="data_ini" :valor="filtro" titulo="Data Inicial" :errors="{}" modo="editar"></mct-form-itens>
                    </div>
                    <div class="col-lg-3">
                        <mct-form-itens tipo="date" campo="data_fin" :valor="filtro" titulo="Data Final" :errors="{}" modo="editar"></mct-form-itens>
                    </div>
                </div>                
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <mct-form-itens tipo="date" campo="data_conciliacao" :valor="filtro" titulo="Data para Conciliação" :errors="{}" modo="editar"></mct-form-itens>
                </div>
                <div class="col-lg-9 mct-row-itens">
                    <div style="width: 180px;" class="text-center" :class="saldos.cbnclanc_saldo_anterior < 0 ? 'bg-danger' : 'bg-success'">
                        <i class="fa fa-usd" aria-hidden="true"></i>
                        <div>
                            <h1>Sld Bancário Anterior</h1>
                            {{saldos.cbnclanc_saldo_anterior | moeda}}
                        </div>
                    </div>
                    <div style="width: 180px;" class="text-center" :class="saldos.cbnclanc_saldo < 0 ? 'bg-danger' : 'bg-success'">
                        <i class="fa fa-usd" aria-hidden="true"></i>
                        <div>
                            <h1>Saldo Bancário</h1>
                            {{saldos.cbnclanc_saldo | moeda}}
                        </div>
                    </div>
                    <div style="width: 180px;" class="text-center" :class="saldos.cbnclanc_saldo_ant_conciliado < 0 ? 'bg-danger' : 'bg-success'">
                        <i class="fa fa-usd" aria-hidden="true"></i>
                        <div>
                            <h1>Tot. Conciliado Ant.</h1>
                            {{saldos.cbnclanc_saldo_ant_conciliado | moeda}}
                        </div>
                    </div>
                    <div style="width: 180px;" class="text-center" :class="saldos.cbnclanc_saldo_conciliado < 0 ? 'bg-danger' : 'bg-success'">
                        <i class="fa fa-usd" aria-hidden="true"></i>
                        <div>
                            <h1>Total Conciliado</h1>
                            {{saldos.cbnclanc_saldo_conciliado | moeda}}
                        </div>
                    </div>
                </div>
            </div> 

            <div class="row mt-2 mb-2">
                <div class="col-12 text-left">
                    <button type="button" class="btn btn-secondary" aria-label="Left Align" @click="onFiltro()">
                    <span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Filtrar</button>     
                    <button type="button" class="btn btn-success" aria-label="Left Align" @click="onSubmit()">
                    <span class="fa fa-check fa-lg" aria-hidden="true"></span> Salvar</button>     
                    <button type="button" class="btn btn-dark" aria-label="Left Align" @click="onImprimir()">
                    <span class="fa fa-print fa-lg" aria-hidden="true"></span> Imprimir Conciliação</button>     

                    <button type="button" class="btn btn-secondary ml-2" aria-label="Left Align" @click="onConciliaTodos(true)">
                    <span class="fa fa-check-square-o fa-lg" aria-hidden="true"></span> Conciliar Todos</button>     
                    <button type="button" class="btn btn-secondary" aria-label="Left Align" @click="onConciliaTodos(false)">
                    <span class="fa fa-square-o fa-lg" aria-hidden="true"></span> Desconciliar Todos</button>
                </div>
            </div>  
            <div class="mct-grid">
                <div class="mct-grid-scroll">         
                    <table class="table table-striped table-bordered table-sm table-hover">
                        <thead>                       
                            <tr>
                                <th @click="toggleOrder($event,'cbnclanc_conciliado')" style="width: 80px;" class="text-right">
                                    <span>
                                        <span>CONCILIADO</span>
                                        <span class="dv-table-column" v-if="'cbnclanc_conciliado' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>
                                <th @click="toggleOrder($event,'cbnclanc_conciliado_data')" style="width: 80px;" class="text-right">
                                    <span>
                                        <span>DT CONCILIADO</span>
                                        <span class="dv-table-column" v-if="'cbnclanc_conciliado_data' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>
                                <th @click="toggleOrder($event,'cbnclanc_data')" style="width: 80px;" class="text-right">
                                    <span>
                                        <span>DT LANÇAMENTO</span>
                                        <span class="dv-table-column" v-if="'cbnclanc_data' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>
                                <th @click="toggleOrder($event,'cbnclanc_valor')" style="width: 120px;" class="text-right">
                                    <span>
                                        <span>VALOR</span>
                                        <span class="dv-table-column" v-if="'cbnclanc_valor' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'cbnclanc_historico')" class="text-left">
                                    <span>
                                        <span>HISTÓRICO</span>
                                        <span class="dv-table-column" v-if="'cbnclanc_historico' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'cbnclanc_tipo')" style="width: 50px;" class="text-center">
                                    <span>
                                        <span></span>
                                        <span class="dv-table-column" v-if="'cbnclanc_tipo' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>
                            </tr>

                        </thead>
                        <tbody>
                        <tr v-for="(row,index) in $data.data" :key="index" :style="row.ext_debito > 0 && index & 1 ? 'background-color: #FEF2F4!important': row.ext_debito > 0 ? 'background-color: #FCDAE1!important' : ''">
                            <td class="text-center"><mct-form-itens tipo="checkbox" campo="cbnclanc_conciliado" :valor="row" titulo="" showTitulo=false :errors="{}" modo="editar" @onChange="onConcilia(index)"></mct-form-itens>                            
                            </td>
                            <td class="text-right">{{$options.filters.data(row.cbnclanc_conciliado_data)}}</td>
                            <td class="text-right">{{$options.filters.data(row.cbnclanc_data)}}</td>
                            <td class="text-right font-weight-bold" :class="row.cbnclanc_tipo === 2 ? 'text-success' : 'text-danger'">{{$options.filters.moeda(row.cbnclanc_valor)}}</td>
                            <td class="text-left">{{row.cbnclanc_historico}} <small class="text-muted">{{row.cbnclanc_tipo_conta}}</small><small class="text-muted text-info"> [{{row.cbnclanc_documento}}]</small> </td>
                            <td class="text-center">
                                <span :class="row.cbnclanc_tipo === 2 ? 'badge-success' : 'badge-danger'" class="badge p-1 pl-2 pr-2 m-0">{{row.cbnclanc_tipo === 2 ? 'CR' : 'DB'}}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>         
            </div>        
            <!--<mct-rodape-lista :data="$data" :query="query" @navigate="navigate" @getData="getData"></mct-rodape-lista> -->
        </div>
    </div> 
</template>

<script>
  import Vue from 'vue'
  import axios from '@/http'
  import moment from 'moment'
  //import jsPDF from 'jspdf'  
  import MctTopoLista from '@/components/comum/MctTopoLista.vue'
  import MctRodapeLista from '@/components/comum/MctRodapeLista.vue'
  import MctGridLista from '@/components/comum/MctGridLista.vue'
  import MctFiltroLista from '@/components/comum/MctFiltroLista.vue'
  import MctMixinsList from '@/components/funcoes/MctMixinsList'
  import MctFormItens from '@/components/comum/MctFormItens.vue'
  import MctFiltroCampo from '@/components/comum/MctFiltroCampo.vue'
  
  import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios'

  export default {
    name: 'EscConciliacao',
    mixins: [MctMixinsList, MctPadraoRelatorios],
    components: {
        'MctTopoLista': MctTopoLista,
        'MctRodapeLista': MctRodapeLista,
        'MctGridLista' : MctGridLista,
        'MctFiltroLista': MctFiltroLista,
        'MctFormItens': MctFormItens,
        'MctFiltroCampo': MctFiltroCampo,        
    },        
    props: ['source', 'titulo', 'showModal'],
    data() {
      return {
        //pdf: null,
        filtro: {
            data_ini: null,
            data_fin: null,
            conta: null,
            data_conciliacao: null,
            tipo: 3
        },
        contas: [],
        contaSelecionada: {},
        saldos: {}               
      }
    },
    watch: {
        source: function () {
          this.query.column = ''
          this.query.search_column = ''
          this.query.page = 1
          this.query.direction = 'asc'
          this.query.per_page = 10
          this.query.search_operator = 'equal'
          this.query.search_input = ''
          this.query.search_input2 = ''
          this.filters = {}
          this.getData()      
        }
    },    
    methods: {
        onConcilia(index){
            if (this.data[index].cbnclanc_conciliado === 1){
                if (this.filtro.data_conciliacao === null || typeof this.filtro.data_conciliacao === 'undefined' || this.filtro.data_conciliacao === ''){
                    //this.data[index].cbnclanc_conciliado = 0
                    this.$swal({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Selecione a data de conciliação, antes de conciliar os lançamentos.',
                        footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                    })                    
                    return
                }
                this.data[index].cbnclanc_conciliado_data = this.filtro.data_conciliacao
            } else {
                this.data[index].cbnclanc_conciliado_data = null
            }
            //console.log(index)
        },
        onConciliaTodos(marcar){
            if (this.filtro.data_conciliacao === null || typeof this.filtro.data_conciliacao === 'undefined' || this.filtro.data_conciliacao === ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Selecione a data de conciliação, antes de conciliar os lançamentos.',
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })                    
                return
            }

            this.data.forEach((item,index) => { 
                this.data[index].cbnclanc_conciliado_data = marcar ? this.filtro.data_conciliacao : null
                this.data[index].cbnclanc_conciliado = marcar ? 1 : 0                 
            });                
        },
        onFiltro() {
            if (this.filtro.data_conciliacao === null || typeof this.filtro.data_conciliacao === 'undefined' || this.filtro.data_conciliacao === ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Selecione a data de conciliação, antes de conciliar os lançamentos.',
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })
                return
            }
            this.getData()
        },
        async getData() {           
            if (typeof this.source !== "undefined" && typeof this.source !== "null" && this.source !== 'undefined/lista'){
            await axios.get(this.source,{params:  {
                    conta: this.filtro.conta,                    
                    tipo: this.filtro.tipo,                    
                    dataini: moment(this.filtro.data_ini).isValid() ? moment(this.filtro.data_ini).format('YYYY-MM-DD') : null,
                    datafin: moment(this.filtro.data_fin).isValid() ? moment(this.filtro.data_fin).format('YYYY-MM-DD') : null,

                    column: this.query.column,
                    direction: this.query.direction,
                    page: this.query.page,
                    per_page: this.query.per_page,
                    search_column: this.query.search_column,
                    search_operator: this.query.search_operator,
                    search_input: this.query.search_input
                },
                paramsSerializer: function (params) {
                    return jQuery.param(params)
                }
                })
                .then(response => {
                    this.data   = response.data.data
                    this.saldos = response.data.saldos
                })
                .catch(response => {
                    console.log(response.message)
                })
            }
        },  
        
        onSubmit (e) {
            this.$swal({
                title: 'Deseja realmente gravar a conciliação?',
                //text: "Este agendamento poderá apenas ser cancelado posteriormente!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'NÃ£o',
                confirmButtonText: 'Sim'
                }).then((result) => {
                    if (result.value) {
                        this.onGravar()   
                    }                     
                })
        },
        async onGravar (e) {
            
            let URL = '/escconciliacao/editar'
            var dados = this.data
            await axios.put(`${URL}`,dados)
                .then(response => {
                    //this.message = response.data.message
                    //this.status = response.status
                    //this.lista = response.data.data
                    this.errors = {}
                    
                    this.$swal('Sucesso!',
                    'Registro incluí­do com sucesso!',
                    'success');  
                this.getData()                                         
                })
                .catch(response => {
                    this.message = response.response.data.message
                    if (response.response.data.error){
                        this.errors = response.response.data.error.errorInfo[2]              
                    }else{
                        this.errors = response.response.data.errors
                    }
                    this.status = response.response.status
                    
                })                     
        },
       navigate (page) {
            this.query.page = page
            this.getData()
        },
        toggleOrder(column) {
            if(column === this.query.column) {
            if(this.query.direction === 'desc') {
                this.query.direction = 'asc'
            } else {
                this.query.direction = 'desc'
            }
            } else {
            this.query.column = column
            this.query.direction = 'asc'
            }
            this.getData()
        },      
        setDescricao (data ){
            this.$emit('setDescricao', data)
        },
        toogleAvancado (data){
            this.showAvancado = data
        },
        getDataUri(url, callback){
            let image = new Image()

            image.onload = () => {
                let canvas = document.createElement('canvas');
                canvas.width = this.naturalWidth
                canvas.height = this.naturalHeight
                canvas.getContext('2d').drawImage(this, 0, 0)
                callback(canvas.toDataUrl('image/png').replace(/^data:image\/(png|jpg);base64,/, ''))
                callback(canvas.toDataURL('image/png'))
            }

            image.src = url
        },
        setContas (data) {
            this.contas = data
        },        
        async onImprimir () {
            this.onCreatePDF()
            /*CABEÇALHO*/
            this.title = 'CONCILIAÇÃO BANCÁRIA';
            this.onHeaderPDF();
            this.pdf.setFont('courier') 
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)   
                
            if(this.filtro.conta > 0){
                this.pdf.text(`AGÊNCIA: ${this.contaSelecionada.age_codigo} CONTA BANCÁRIA: ${this.contaSelecionada.cbnc_numero} DESCRIÇÃO: ${this.contaSelecionada.cbnc_descricao}`,8,this.lin)
                this.onAddLine(this.jump)
            }
            if(moment(this.filtro.data_ini).isValid()){
                this.pdf.text(`PERÍODO DE ${moment(this.filtro.data_ini).format('DD/MM/YYYY')} A ${moment(this.filtro.data_fin).format('DD/MM/YYYY')}`,8,this.lin)
                this.onAddLine(this.jump)
            }
            this.pdf.text(`TIPO: ${this.filtro.tipo === '1' ? 'CONCILIADOS' : this.filtro.tipo === '0' ? 'NÃO CONCILIADOS' : 'TODOS LANÇAMENTOS' }`,8,this.lin)
            this.onAddLine(this.jump)
            this.pdf.text(moment(this.filtro.data_conciliacao).isValid() ? `DATA DE CONCILIAÇÃO: ${moment(this.filtro.data_conciliacao).format('DD/MM/YYYY')}` : '',8,this.lin)
            this.onAddLine(this.jump)

            this.onAddLine(this.jump)
            this.pdf.setFillColor(192,192,192)
            this.pdf.rect(8,this.lin-3,195,this.jump+1,'FD')
            this.pdf.setFont('helvetica','bold') 
            this.pdf.text('CONCILIADO',16.5,this.lin,{align: 'center'})
            this.pdf.text('DATA',45,this.lin,{align: 'right'})
            this.pdf.text('EMISSÃO',65,this.lin,{align: 'right'})
            this.pdf.text('HISTÓRICO',67,this.lin)
            this.pdf.text('VALOR',200,this.lin,{align: 'right'})
            this.onAddLine(this.jump)

            var somaDebitos = 0
            var somaCreditos = 0
            var somaConciliado = 0
            var saldoTotal = 0

            for (var index in this.data) {
                this.pdf.setFont('courier') 
                this.pdf.setFont('helvetica','normal') 
                this.pdf.setFontSize(7.5)   
                this.pdf.text(String(this.data[index].cbnclanc_conciliado === 1 ? 'SIM' : 'NÃO'),16.5,this.lin,{align: 'center'})
                this.pdf.text(String(this.$options.filters.data(this.data[index].cbnclanc_conciliado_data)) !== 'null' ? String(this.$options.filters.data(this.data[index].cbnclanc_conciliado_data)) : '',45,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.data(this.data[index].cbnclanc_data)) !== 'null' ? String(this.$options.filters.data(this.data[index].cbnclanc_data)) : '',65,this.lin,{align: 'right'})
                this.pdf.text(this.data[index].cbnclanc_historico.substring(0,65),67,this.lin)
                this.pdf.text(String(this.$options.filters.moeda(this.data[index].cbnclanc_valor)),200,this.lin,{align: 'right'})
                somaDebitos     += this.data[index].cbnclanc_tipo === 1 ? Number(this.data[index].cbnclanc_valor) : 0
                somaCreditos    += this.data[index].cbnclanc_tipo === 2 ? Number(this.data[index].cbnclanc_valor) : 0
                saldoTotal      += this.data[index].cbnclanc_tipo === 1 ? Number(this.data[index].cbnclanc_valor)*-1 : Number(this.data[index].cbnclanc_valor)
                somaConciliado  += this.data[index].cbnclanc_conciliado === 1 ? this.data[index].cbnclanc_tipo === 1 ? Number(this.data[index].cbnclanc_valor)*-1 : Number(this.data[index].cbnclanc_valor) : 0
                this.onAddRegLine()
                this.onAddLine(this.jump)
                if (this.qtdLin > this.itensPage || this.lin >= (94*this.jump)){
                    this.onFooterPDF()
                    this.page++   
                    this.pdf.addPage()
                    this.onHeaderPDF();  
                    this.lin = 39
                    this.qtdLin = 1 
                }
            } 
            /* FINAL RELATORIO */
            this.pdf.setFont('times')
            this.pdf.setFont('helvetica','bold')
            this.pdf.setFontSize(7.5)
            this.pdf.line(8,this.lin,203,this.lin)
            this.onAddLine(this.jump)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('TOTAL DE DÉBITOS(-) R$',175,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaDebitos)),200,this.lin,{align: 'right'})
            this.onAddLine(this.jump)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('TOTAL DE CRÉDITOS(+) R$',175,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaCreditos)),200,this.lin,{align: 'right'})
            this.onAddLine(this.jump)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('SALDO DA CONTA(=) R$',175,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(saldoTotal)),200,this.lin,{align: 'right'})
            this.onAddLine(this.jump)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('TOTAL CONCILIADO(=) R$',175,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaConciliado)),200,this.lin,{align: 'right'})
            this.onAddLine(this.jump)

            this.pdf.line(8,this.lin,203,this.lin)
            this.onAddLine(this.jump)
            this.pdf.text(`QTD. REGISTROS: ${this.data.length}`,200,this.lin,{align: 'right'})

            this.onFooterPDF()

            const pages = this.pdf.internal.getNumberOfPages();
            this.pdf.setFont('times')
            this.pdf.setFont('helvetica','bold')
            this.pdf.setFontSize(7.5)
            for (let j = 1; j < pages + 1 ; j++) {
                this.pdf.setPage(j);
                this.pdf.text(`PÁGINA ${j} DE ${pages}`,200,8*this.jump,{align: 'right'})
            }

            const uuidv4 = this.$uuid.v4();
            this.pdf.save(`conciliacao-bancaria-${uuidv4}.pdf`)
        }
    },
    created () {

    },
    watch : {
       'filtro.conta': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
            var ret = this.contas.filter(function(el) {
                return el.cbnc_codigo == newVal;
            })
            this.contaSelecionada = ret[0]
        }
    },    
    },
    computed: {

    }
  }
</script>

<style scoped>
    
    .mct-row-itens{
        display: flex;
        flex-direction: row;
        align-items: flex-start;  
        padding: 5px;
    }
    .mct-row-itens div{
        font-size: 1.4rem;
        font-weight: 600;
        margin-right: 5px;
    }

    .mct-row-itens div h1{
        font-size: 0.8rem;
        font-weight: 400;
    }

     .mct-row-itens div i {
        height: 100%;
        float: left;
        font-size: 32px;
        /* margin-top: 10px; */
        padding: 15px;
        background-color: rgba(0, 0, 0, 0.3);

     }

</style>