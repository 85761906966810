<template>
  <div v-if="modals.length">
    <div class="modalsync-backdrop" v-if="currModal && currModal.options.backdrop"></div>
    <transition name="fade" @after-enter="$emit('after-enter')" @after-leave="$emit('after-leave')">
      <div class="modalsync" id="modalsync" tabindex="-1" role="dialog">
        <div class="modalsync-dialog">
          <div class="modalsync-content">           
            <div class="modalsync-body">
                <component v-for="{id, component, props, options} of modals" :is="component" :key="id" ref="modal" v-bind="props" v-show="options.show" @click.native="e => handleBackdrop(e, id, options.backdrop)"></component>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Vue from 'vue'

import { addClass, removeClass } from './utils'

const isPromise = promise =>
  Object.prototype.toString.call(promise) === '[object Promise]' ||
  promise instanceof Promise

const DEFAULT_OPTIONS = {
  show: true,
  backdrop: true,
  destroy: false,
}

const NON_TRANSITION_ERR =
  'this modal item is not a Vue component, you should use `transition` component and emit `after-leave` event'

export default {
  data() {
    return {
      modals: [],
      currModal: null,
    }
  },
  computed: {
    currModalId() {
      return this.currModal && this.currModal.id
    },
  },
  watch: {
    currModal: modal =>
      (modal ? addClass : removeClass)(document.body, 'modalsync-open'),
  },
  beforeCreate() {
    Object.defineProperty(Vue.prototype, '$modal', {
      value: this,
      writable: process.env.NODE_ENV === 'development',
    })
  },
  methods: {
    close(modalId, destroy) {
      modalId = modalId || this.currModalId

      let modal

      if (!modalId || !(modal = this.getModal(modalId)))
        return Promise.resolve()

      const { options } = modal

      options.show = false

      const modalItem = this.getModalItem(modalId)

      if (!modalItem) return Promise.reject(new TypeError(NON_TRANSITION_ERR))

      const callback = resolve => {
        options.destroy || destroy
          ? this.removeModal(modalId)
          : this.resetCurrModal(modalId)
        resolve()
      }

      return new Promise(
        resolve =>
          getComputedStyle(modalItem.$el).display === 'none'
            ? callback(resolve)
            : modalItem.$once('after-leave', () => callback(resolve)),
      )
    },
    closeAll(destroy = true, immediate) {
      let promise = Promise.resolve()

      destroy && immediate
        ? (this.modals = [])
        : this.modals.forEach(modal => {
            promise = promise.then(() => this.close(modal.id, destroy))
          })

      return promise
    },
    open(modal) {
      modal.id = modal.id || Date.now()
      return isPromise(modal.component)
        ? modal.component.then(component =>
            this.resolve(
              Object.assign(modal, {
                component: component.default || component,
              }),
            ),
          )
        : this.resolve(modal)
    },
    resolve(modal) {
      const { id, component, props, options } = modal

      const m = this.getModal(id)

      if (m) {
        component && (m.component = component)
        modal = m
      } else if (!component) {
        return Promise.reject(
          new ReferenceError('no component passed on initialization'),
        )
      }

      modal.props = { ...props }

      const opts = { ...DEFAULT_OPTIONS, ...options }

      if (!opts.show) {
        modal.options = opts
        return Promise.resolve()
      }

      const promise = this.currModalId === id ? Promise.resolve() : this.close()

      return promise.then(() => {
        modal.options = opts
        m || this.modals.push(modal)
        this.currModal = modal

        return new Promise((resolve, reject) =>
          this.$nextTick(() => {
            const modalItem = this.getModalItem(id)
            modalItem
              ? modalItem.$once('after-enter', () => resolve(modal))
              : reject(new TypeError(NON_TRANSITION_ERR))
          }),
        )
      })
    },
    getModal(modalId) {
      return this.modals.find(m => m.id === modalId)
    },
    getModalIndex(modalId) {
      return this.modals.findIndex(m => m.id === modalId)
    },
    getModalRef(modalId) {
      return this.$refs.modal[this.getModalIndex(modalId)]
    },
    getModalItem(modalId) {
      const modalRef = this.getModalRef(modalId)
      return modalRef && modalRef.$children[0]
    },
    resetCurrModal(modalId) {
      modalId === this.currModalId && (this.currModal = null)
    },
    removeModal(modalId) {
      const modalIndex = this.getModalIndex(modalId)
      modalIndex + 1 && this.modals.splice(modalIndex, 1)
      this.resetCurrModal(modalId)
    },
    handleBackdrop(e, id, backdrop) {
      if (e.target !== e.currentTarget || backdrop === 'static') return
      this.close(id)
    },
  },
}
</script>

<style scoped>

.modalsync-open {
  overflow: hidden;
}
.modalsync-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
  opacity: 0.5;
  filter: alpha(opacity=50);
}



.modalsync {
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
.modalsync-open .modalsync {
  overflow-x: hidden;
  overflow-y: auto;
}
.modalsync-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}
.modalsync-content {
  position: relative;
  background-color: #fff;
  border: 1px solid #999;
  border: 1px solid rgba(0,0,0,0.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0,0,0,0.5);
  background-clip: padding-box;
  outline: 0;
}
.modalsync-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
.modalsync-header:before,
.modalsync-header:after {
  content: " ";
  display: table;
}
.modalsync-header:after {
  clear: both;
}
.modalsync-header .close {
  margin-top: -2px;
}
.modalsync-title {
  margin: 0;
  line-height: 1.428571428571429;
}
.modalsync-body {
  position: relative;
  padding: 15px;
}
.modalsync-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}
.modalsync-footer:before,
.modalsync-footer:after {
  content: " ";
  display: table;
}
.modalsync-footer:after {
  clear: both;
}
.modalsync-footer .btn + .btn {
  margin-left: 5px;
  margin-bottom: 0;
}
.modalsync-footer .btn-group .btn + .btn {
  margin-left: -1px;
}
.modalsync-footer .btn-block + .btn-block {
  margin-left: 0;
}
.modalsync-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}
@media (min-width: 768px) {
  .modalsync-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modalsync-content {
    box-shadow: 0 5px 15px rgba(0,0,0,0.5);
  }
}
.modalsync-sm {
  width: 300px;
}
@media (min-width: 992px) {
  .modalsync-lg {
    width: 900px;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}


</style>

