<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="ctab_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
                <div class="col-lg-2">
                    <mct-form-itens tipo="checkbox" campo="ctab_status" :valor="lista" :titulo="lista.ctab_status == 1 ? 'Cadastro Ativo.' : 'Cadastro Inativado.'" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-9">
                    <div class="row">
                        <div class="col-sm-12">
                            <mct-form-itens coluna="0" tipo="text" campo="ctab_descricao" :valor="lista" titulo="Descrição" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                        </div>    
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <mct-form-itens tipo="select" campo="cur_codigo" :valor="lista" titulo="Selecione o Curso" obrigatorio="true" 
                                origem="esc_cursos" origemChave="cur_codigo" origemRetorno='cur_nome' origemRetornoSec='cur_codigo' :errors="errors" :modo="modo"></mct-form-itens>                                                           
                        </div>
                    </div>
                    <!--<div class="row">
                        <div class="col-sm-12">
                            <mct-form-itens tipo="select" campo="cbnc_codigo" :valor="lista" titulo="Conta Bancária" obrigatorio="true" 
                                origem="esc_conta_bancaria" origemChave="cbnc_codigo" origemRetorno='cbnc_descricao' :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>-->
                    <!--<div class="row">
                        <div class="col-sm-8">
                            <mct-form-itens tipo="select" campo="cpg_codigo" :valor="lista" titulo="Condição de Pagamento" obrigatorio="true" 
                                origem="esc_condpag" origemChave="cpg_codigo" origemRetorno='cpg_nome' @setDataLista="setCondPgto" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-sm-4">
                            <mct-form-itens tipo="number" campo="ctab_parcelas" :valor="lista" titulo="Qtd. Parcelas" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>-->
                    <div class="row">
                        <div class="col-sm-4">
                            <mct-form-itens tipo="currency" campo="ctab_vlr_avista" :valor="lista" titulo="Vlr. Curso à Vista" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-sm-4">
                            <mct-form-itens tipo="currency" campo="ctab_vlr_parcelado" :valor="lista" titulo="Vlr. Curso Parcelado" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-sm-4">
                            <mct-form-itens tipo="currency" campo="ctab_vlr_matricula" :valor="lista" titulo="Valor Matrícula" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>                    
                    <!--<div class="row">
                        <div class="col-sm-4">
                            <mct-form-itens tipo="date" campo="ctab_venc_matricula" :valor="lista" titulo="Venc. Matrícula" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-sm-4">
                            <mct-form-itens tipo="number" campo="ctab_parcelas_matri" :valor="lista" titulo="Qtd. Parc. Matrí." obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <mct-form-itens tipo="number" campo="ctab_dia_vencimento" :valor="lista" titulo="Dia Venc. Parcela" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-sm-6">
                            <mct-form-itens tipo="currency" campo="ctab_vlr_parcela" :valor="lista" titulo="Vlr. Parcela" obrigatorio="false" :errors="errors" modo="readonly"></mct-form-itens>    
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <mct-form-itens tipo="number" campo="ctab_juros" :valor="lista" titulo="Juros (%)" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-sm-6">
                            <mct-form-itens tipo="number" campo="ctab_multa" :valor="lista" titulo="Multa (%)" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>-->
                </div>
                <div class="col-sm-3">
                    <fieldset class="well">
                        <legend class="well-legend">Descontos de Pontualidade</legend>                    
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-4 pl-0 pr-2">
                                <mct-form-itens tipo="text" campo="ctdesc_dia" :valor="desconto" titulo="Até o Dia" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-sm-6 pl-0 pr-2">
                                <mct-form-itens tipo="text" campo="ctdesc_desconto" :valor="desconto" titulo="Desconto (%)" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="is-icon mt-3">
                                <i class="fa fa-check text-white mct-fa bg-success" aria-hidden="true" @click="onAdcDesconto()"></i>
                            </div>
                        </div>
                        <div class="mct-grid">
                            <div class="mct-grid-scroll-sec">                        
                                <table class="table table-sm table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col" class="text-center" style="width: 70px;">Até o Dia</th>
                                            <th scope="col" class="text-right" style="width: 100%;">Desconto (%)</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                
                                        <tr v-for="(desconto,index) in lista.descontos" :key="index">
                                            <th scope="row">
                                                <div class="is-icon">
                                                    <i class="fa fa-pencil text-white mct-fa bg-primary" aria-hidden="true" @click="onEditDesconto(index)"></i>
                                                    <i class="fa fa-times text-white bg-danger mct-fa" aria-hidden="true" @click="onExcDesconto(index)"></i>
                                                </div>
                                            </th>
                                            <td class="text-center">{{desconto.ctdesc_dia}}</td>
                                            <td class="text-right">{{desconto.ctdesc_desconto}}</td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </div>
                        </div>                        
                    </fieldset>
                </div>
                <!--<div class="col-sm-3">
                    <fieldset class="well">
                        <legend class="well-legend">Vencimento Parcelas</legend>                    
                        <div class="mct-grid">
                            <div class="mct-grid-scroll-sec">                        
                                <table class="table table-sm table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width: 120px;">Tipo</th>
                                            <th scope="col" style="width: 50px;" class="text-center">Parcela</th>
                                            <th scope="col" style="width: 100%;" class="text-right">Valor Parcela</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                
                                        <tr v-for="(parcela,index) in lista.parcelas" :key="index">
                                            <td>{{parcela.ctvenc_tipo}}</td>
                                            <td class="text-center">{{parcela.ctvenc_parcela}}</td>
                                            <td v-if="parcela.ctvenc_tipo=='MATRICULA'" class="text-center">R$ {{lista.ctab_vlr_matricula | moeda}}</td>
                                            <td v-else-if="parcela.ctvenc_tipo=='PARCELA'" class="text-center">R$ {{lista.ctab_vlr_parcela | moeda}}</td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </div>
                        </div>                        
                    </fieldset>
                </div>-->
            </div>

        </div>              
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import moment from 'moment'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'EscCursosTabela',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
        desconto: {
            ctdesc_codigo: null,
            ctdesc_dia: '',
            ctdesc_desconto: ''
        },
        condPgto: {},
        condPgtoSelecionado: {},
      }
  },
  methods: {
    setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            this.lista.ctab_status = 1 
            this.lista.ctab_ent = this.Entidade
            this.lista.ctab_esc = this.Escola
            this.lista.ctab_ano = this.Exercicio
            this.lista.ctab_juros = 1
            this.lista.ctab_multa = 2
        }
    },
    setCondPgto (data) {
        this.condPgto = data
    },
    onAdcDesconto () {
        this.lista.descontos.push(
        {
            ctdesc_codigo: this.desconto.ctdesc_codigo,
            ctdesc_dia: this.desconto.ctdesc_dia,
            ctdesc_desconto: this.desconto.ctdesc_desconto,
        })

        this.desconto.ctdesc_codigo = null
        this.desconto.ctdesc_dia = ''
        this.desconto.ctdesc_desconto = ''
    },
    onEditDesconto (index) {
        this.desconto.ctdesc_codigo = this.lista.descontos[index].ctdesc_codigo
        this.desconto.ctdesc_dia = this.lista.descontos[index].ctdesc_dia
        this.desconto.ctdesc_desconto = this.lista.descontos[index].ctdesc_desconto
        this.lista.descontos.splice(index,1)
    },
    onExcDesconto (index) {
        this.$swal({
            title: 'Deseja realmente remover o item da lista?',
            text: "Este evento não podera ser revertido!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
        }).then((result) => {
            this.lista.descontos.splice(index,1)                       
        })   
        
    },
    onParcelas () {
        if (this.modo == 'novo'){
            if (this.lista.ctab_parcelas > 0){
                this.lista.ctab_vlr_parcela = Number(this.lista.ctab_vlr_parcelado) / Number(this.lista.ctab_parcelas); 
            }else if (this.lista.ctab_parcelas > 0){
                this.lista.ctab_vlr_parcela = 0;
            }



            this.lista.parcelas = []
            var diaVencimento = this.lista.ctab_dia_vencimento
            var mesAtual = typeof this.lista.ctab_venc_matricula !== 'undefined' && this.lista.ctab_venc_matricula !== null & this.lista.ctab_venc_matricula !== '' ? moment(this.lista.ctab_venc_matricula).locale('pt-br').get('month') : moment().locale('pt-br').get('month')
            var anoAtual = typeof this.lista.ctab_venc_matricula !== 'undefined' && this.lista.ctab_venc_matricula !== null & this.lista.ctab_venc_matricula !== '' ? moment(this.lista.ctab_venc_matricula).locale('pt-br').get('year') : moment().locale('pt-br').get('year')
            var dataVencimento = typeof this.lista.ctab_venc_matricula !== 'undefined' && this.lista.ctab_venc_matricula !== null & this.lista.ctab_venc_matricula !== '' ? moment(this.lista.ctab_venc_matricula).locale('pt-br').format('YYYY-MM-DD') : moment().locale('pt-br').format('YYYY-MM-DD');            
            var data = typeof this.lista.ctab_venc_matricula !== 'undefined' && this.lista.ctab_venc_matricula !== null & this.lista.ctab_venc_matricula !== '' ? moment(this.lista.ctab_venc_matricula) : moment().locale('pt-br');
            var parcAtual = 0
            /* MATRÍCULAS */
            for (var i = 0; i < Number(this.lista.ctab_parcelas_matri); i++) {
                if (i > 0){
                    dataVencimento = moment().set({'date': diaVencimento, 'month': mesAtual, 'year': anoAtual}).locale('pt-br').format('YYYY-MM-DD')
                }
                parcAtual++
                mesAtual++
                if (mesAtual > 12){
                    mesAtual = 1
                    anoAtual++
                }
                this.lista.parcelas.push(
                    {
                        ctvenc_codigo: null,
                        ctvenc_parcela: parcAtual,
                        ctvenc_data: dataVencimento,
                        ctvenc_tipo: 'MATRICULA',
                        ctab_vlr_parcela: this.lista.ctab_vlr_parcela
                    })            
            }

            /* PARCELAS */
            for (var i = 0; i < Number(this.lista.ctab_parcelas); i++) {
                if (parcAtual > 0){
                    dataVencimento = moment().set({'date': diaVencimento, 'month': mesAtual, 'year': anoAtual}).locale('pt-br').format('YYYY-MM-DD')
                }
                parcAtual++
                mesAtual++
                if (mesAtual > 12){
                    mesAtual = 1
                    anoAtual++
                }
                this.lista.parcelas.push(
                    {
                        ctvenc_codigo: null,
                        ctvenc_parcela: parcAtual,
                        ctvenc_data: dataVencimento,
                        ctvenc_tipo: 'PARCELA',
                        ctab_vlr_parcela: this.lista.ctab_vlr_parcela
                    })            
            }
        }
    }     
  },
  computed: {
        ...mapState('Login',['Entidade','Exercicio','Escola'])       
  }, 
  watch: {
      'lista.cpg_codigo': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
            var ret = this.condPgto.filter(function(el) {
                return el.cpg_codigo == newVal;
            })
            this.condPgtoSelecionado = ret[0]
            this.lista.ctab_parcelas = this.condPgtoSelecionado.cpg_nparcelas            
        }
      },
      'lista.ctab_parcelas': function (newVal, oldVal){
        this.onParcelas()
      },
      'lista.ctab_parcelas_matri': function (newVal, oldVal){ 
        this.onParcelas()
      },
      'lista.ctab_vlr_avista': function (newVal, oldVal){ 
        this.onParcelas()
      },
      'lista.ctab_vlr_parcelado': function (newVal, oldVal){ 
        this.onParcelas()
      },
      'lista.ctab_dia_vencimento': function (newVal, oldVal){ 
        this.onParcelas()
      },  
  }     
}
</script>
