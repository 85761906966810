<template>
    <div class="mct-form">
        <mct-topo-lista titulo="Relatórios" subtitulo="Módulo Financeiro" v-show="!showModal"></mct-topo-lista>

        <div class="row">
            <div class="col-3">
                <ul class="list-group h-100 bg-white list-group-relatorios" id="list-tab" role="tablist">
                    <span v-for="(item,index) in relatoriosFinanceiro.children" :key="index">
                        <li class="bg-success text-white p-2">{{item.name}}</li>
                        <li class="list-group-item" v-for="(children,idxc) in item.children" :key="idxc" :class="relatorio === children.relatorio ? 'active' : ''">
                            <a class="btn" :href="`#card${children.relatorio}`" aria-expanded="true" :aria-controls="`card${children.relatorio}`" @click="relatorio = children.relatorio">
                            {{children.name}}
                            </a>                            
                        </li>
                    </span>    

                </ul>
            </div>     
            <div class="col-6">
                <div class="bg-white mr-2 h-100 p-2 rounded">
                    <pnl-pagar-a-pagar v-if="relatorio == 1" :outputPdf.sync="outputPdf"/>
                    <pnl-pagar-pagas v-if="relatorio == 2" :outputPdf.sync="outputPdf"/>                    
                    <pnl-receber-a-receber v-if="relatorio == 3" :outputPdf.sync="outputPdf"/>
                    <pnl-receber-recebidas v-if="relatorio == 4" :outputPdf.sync="outputPdf"/>
                    <pnl-receber-res-diario v-if="relatorio == 5" :outputPdf.sync="outputPdf"/>
                    <pnl-cheques-recebidos v-if="relatorio == 6" :outputPdf.sync="outputPdf"/>
                    <pnl-recebidos-cartao v-if="relatorio == 7" :outputPdf.sync="outputPdf"/>
                    <pnl-finan-mov-diario v-if="relatorio == 10" :outputPdf.sync="outputPdf"/>
                </div>
            </div>

        </div> 
        <!--<b-modal id="relatorio" title="Relatório Financeiro">
            <PDFView :url="outputPdf === null ? '' : outputPdf"/>
        </b-modal>-->

    </div>
</template>
<script>

  import { mapState } from 'vuex'

  import PDFView from '@/components/pdfviewer/PDFView.vue' 

  import MctTopoLista from '@/components/comum/MctTopoLista.vue'
  
  /**PAINEIS*/
  import pnlPagarAPagar from "./panels/pnlPagarAPagar.vue";
  import pnlPagarPagas from "./panels/pnlPagarPagas.vue";
  import pnlReceberAReceber from "./panels/pnlReceberAReceber.vue";
  import pnlReceberRecebidas from "./panels/pnlReceberRecebidas.vue";
  import pnlReceberResDiario from "./panels/pnlReceberResDiario.vue";
  import pnlChequesRecebidos from "./panels/pnlChequesRecebidos.vue";
  import pnlRecebidosCartao from "./panels/pnlRecebidosCartao.vue";
  import pnlFinanMovDiario from "./panels/pnlFinanMovDiario.vue";

  export default {
    name: 'EscFinanceiroLista',
    components: {
        PDFView,
        MctTopoLista,
        pnlPagarAPagar,
        pnlPagarPagas,
        pnlReceberAReceber,
        pnlReceberRecebidas,
        pnlReceberResDiario,
        pnlChequesRecebidos,
        pnlRecebidosCartao,
        pnlFinanMovDiario
    },    
    props: ['source', 'titulo', 'showModal'],
    data() {
      return {
        relatorio: 0,
        outputPdf: null,
      }
    },
    computed: {
        ...mapState('Login',['infoEntidade','infoMenuSideBar']),
         relatoriosFinanceiro () {
            try {
                const relatorios = this.infoMenuSideBar.filter(item => item.id == 626)
                const financeiro = relatorios[0].children.filter(item => item.id == 627)
                financeiro[0].children.forEach((element,index,arr) => {
                    const regex = /[\/](\w*)[\/](\d*)[\/]?(\d*)?/
                    const array = regex.exec(element.url)
                    arr[index].group = array[2]

                    element.children.forEach((elementC,indexC,arrC) => {
                        const regex = /[\/](\w*)[\/](\d*)[\/]?(\d*)?/
                        const array = regex.exec(elementC.url)
                        arrC[indexC].group = array[2]
                        arrC[indexC].relatorio = array[3]
                    });
                });    
                //console.log(financeiro[0])
                return financeiro[0]
            }
            catch (error){
                return []
            }
        }
    },     
    methods: {        
        onImprimirPDF() {
            printJS({printable: this.outputPdf, type: 'pdf'})
        }        
    },    
  }
</script>

<style scoped>

.list-group {
    border: 0;
    border-top: 4px solid #60C060;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.btn {
  box-shadow: none !important;
  outline: 0;
}

.list-group-item {
   border: 0;
}

.list-group-item a {
    font-size: 14px;    
}

.list-group-item a:link,
.list-group-item a:visited {
  color: #222;
}

.list-group-item a:hover,
.list-group-item a:focus {
  color: #5DA287;
  font-weight: 500;
}

.list-group-item span {
  border: solid #222;
  border-width: 0 1px 1px 0;
  display: inline;
  cursor: pointer;
  padding: 3px;
  position: absolute;
  right: 0;
  margin-top: 10px;
}

.list-group-item a.btn.collapsed span {
  transform: rotate(40deg);
  -webkit-transform: rotate(40deg);
  transition: .3s transform ease-in-out;
}

.list-group-item a.btn span {
  transform: rotate(-140deg);
  -webkit-transform: rotate(-140deg);
  transition: .3s transform ease-in-out;
}

.card {
    border: 0;
}

.list-group-item.active {
    background-color: #292834!important;
    border: 0 !important;
}

.list-group-item.active a{
    color: white!important;
}


</style>