import { render, staticRenderFns } from "./EscNotasAlunos.vue?vue&type=template&id=dc14bca0&"
import script from "./EscNotasAlunos.vue?vue&type=script&lang=js&"
export * from "./EscNotasAlunos.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports