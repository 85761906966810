<template>
<!-- RELATORIOS CONTAS A PAGAR - A PAGAR -->
    <div>        
        <div class="row mb-4">
            <div class="col-lg-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Turma</legend>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_ini" :valor="filtro" titulo="Inicial" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_ini" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_fin" :valor="filtro" titulo="Final" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_fin" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <mct-form-itens tipo="checkbox" campo="assinatura" :valor="filtro" titulo="Incluir linha para assinatura?" :errors="{}" modo="editar"></mct-form-itens>
            </div>
        </div>        
        <div class="row" v-show="infoEscola.esc_tipo !== 1">
            <div class="col-lg-12">
                <mct-form-itens tipo="checkbox" campo="agrupa_agendamento" :valor="filtro" titulo="Agrupar por Agendamento" :errors="{}" modo="editar"></mct-form-itens>
            </div>
        </div>        
        <div class="row" v-show="filtro.agrupa_agendamento === 1">
            <div class="col-2">
                <mct-form-itens tipo="number" campo="agendamento" :valor="filtro" titulo="Agendamento" :errors="{}" modo="editar"></mct-form-itens>
            </div>
            <div class="col-10">
                <mct-form-itens tipo="select" campo="agendamento" :valor="filtro" titulo=" " origem="esc_horarios" origemChave="horari_codigo" origemRetorno='horari_descricao' origemRetornoSec='horari_codigo' 
                :origemFiltro="filtro.turma_ini === filtro.turma_fin && filtro.turma_ini > 0 ? {turm_codigo: filtro.turma_ini } : {}"
                :errors="{}" modo="editar"></mct-form-itens>
            </div>
        </div>
        <div class="row mb-4" v-show="filtro.agrupa_agendamento === 1">
            <div class="col-12">
                <mct-form-itens tipo="radio" campo="situacao_agendamento" :valor="filtro" titulo="Situação" 
                :origemData="[{value: 9, text: 'Todos'},{value: 0, text: 'Cancelado'},{value: 1, text: 'Em Andamento'},{value: 2, text: 'Aguardando'},{value: 3, text: 'Finalizado'}]" :errors="{}" modo="editar"></mct-form-itens>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-12 text-left">
                <button type="button" class="btn btn-secondary mr-2" aria-label="Left Align" @click="onAlunosTurma()">
                <span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Visualizar</button>     
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/http'

import { mapState } from 'vuex'

import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios'

export default {    
    components: {
        'MctFormItens': MctFormItens,
    },
    mixins: [MctPadraoRelatorios],
    data() {
        return {
            data: [],
            outputPdf: null,
            filtro: {
                turma_ini: null,
                turma_fin: null,
                agrupa_agendamento: null,
                situacao_agendamento: 9,
                agendamento: null,
                assinatura: 0,
                etap_codigo: null,
                aluno: null,
                modelo: 1,
                tipo: 0
            }
        }
    },
    computed: {
        ...mapState('Login',['infoEscola'])
    },
    methods : {
        async getData() {
            var url = '/alunosturma';

            await axios.get(`/relatorios/siges${url}`,{params:  {
                turmaini: this.filtro.turma_ini,
                turmafin: this.filtro.turma_fin,
                agrupa_agendamento: this.filtro.agrupa_agendamento,
                situacao_agendamento: this.filtro.situacao_agendamento,
                agendamento: this.filtro.agendamento,
                etap_codigo: this.filtro.etap_codigo,
                aluno: this.filtro.aluno,
                tipo: this.filtro.tipo
            },
            paramsSerializer: function (params) {
                return jQuery.param(params)
            }
            })
            .then(response => {
                this.data = response.data.data                    
            })
            .catch(response => {
                console.log(response.message)
            })
            
        },
        async onAlunosTurma () {

            await this.getData();

            await this.onCreatePDF()
            
            /*CABEÇALHO*/
            if (this.filtro.assinatura === 1){
                this.semData = true;
            }
            this.title = 'ALUNOS POR TURMA';
            this.onHeaderPDF();
            this.pdf.setFont('courier') 
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)     
                    
            if(this.filtro.turma_ini){
                this.pdf.text(`TURMA ${this.filtro.turma_ini} ATÉ ${this.filtro.turma_fin}`,8,this.lin)
                this.onAddLine(this.jump)
            }

            this.onAddLine(this.jump)
            this.pdf.setFillColor(192,192,192)
            this.pdf.rect(8,this.lin-3,195,this.jump+1,'FD')
            this.pdf.setFont('helvetica','bold') 
            this.pdf.text('R.A.',30,this.lin,{align: 'right'})
            this.pdf.text('ALUNO',32,this.lin)
            this.onAddLine(this.jump+1)

            var grupo = 0
            var agendamento = 0
            

            for (var index in this.data) {
                this.pdf.setFont('courier') 
                /*TURMA*/
                if (grupo !== this.data[index].turm_codigo){
                    if (this.filtro.assinatura === 1 && this.filtro.agrupa_agendamento !== 1 && grupo > 0){

                        this.onAddLine(this.jump+2)
                        this.pdf.text('DATA: _____/_____/_________',32,this.lin)

                        this.onAddLine(this.jump+2)
                        this.pdf.text('ASS.:',32,this.lin)
                        this.pdf.line(100,this.lin,(this.widthPage+8),this.lin);

                        this.onAddLine(this.jump+2)
                        this.pdf.text('OBSERVAÇÕES:',32,this.lin)
                        this.onAddLine(this.jump+2)
                        this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);
                        this.onAddLine(this.jump+2)
                        this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);
                        this.onAddLine(this.jump+2)
                        this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);
                        this.onAddLine(this.jump+2)
                        this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);

                        this.onSaltarPagina2();
                    }
                    grupo = this.data[index].turm_codigo
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(8,this.lin-3,195,this.jump+1,'FD')
                    this.pdf.setFont('courier') 
                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.text('TURMA:',30,this.lin,{align: 'right'})
                    this.pdf.text(this.data[index].turm_identificacao+' - '+this.data[index].turm_codigo,32,this.lin)        
                    if (this.filtro.assinatura === 1 && this.filtro.agrupa_agendamento !== 1){
                        this.onAddLine(this.jump+4)
                    } else {
                        this.onAddLine(this.jump+1)
                    }
                }

                if ((this.filtro.agrupa_agendamento === 1) && (agendamento !== this.data[index].matri_horari_codigo)){
                    if (this.filtro.assinatura === 1 && agendamento > 0){
                        this.onAddLine(this.jump+2)
                        this.pdf.text('DATA: _____/_____/_________',32,this.lin)

                        this.onAddLine(this.jump+2)
                        this.pdf.text('ASS.:',32,this.lin)
                        this.pdf.line(100,this.lin,(this.widthPage+8),this.lin);

                        this.onAddLine(this.jump+2)
                        this.pdf.text('OBSERVAÇÕES:',32,this.lin)
                        this.onAddLine(this.jump+2)
                        this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);
                        this.onAddLine(this.jump+2)
                        this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);
                        this.onAddLine(this.jump+2)
                        this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);
                        this.onAddLine(this.jump+2)
                        this.pdf.line(34,this.lin,(this.widthPage+8),this.lin); 

                        this.onSaltarPagina2();
                    }
                    agendamento = this.data[index].matri_horari_codigo
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(8,this.lin-3,195,(this.jump+1)*3,'FD')
                    this.pdf.setFont('courier') 
                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.text('AGENDAMENTO:',30,this.lin,{align: 'right'})
                    this.pdf.text(this.data[index].horari_descricao+' - '+this.data[index].matri_horari_codigo,32,this.lin)        
                    this.pdf.text('SITUAÇÃO:',160,this.lin,{align: 'right'})
                    this.pdf.text(this.data[index].horari_situacao,162,this.lin)

                    this.onAddLine(this.jump+1)
                    this.pdf.text('MÓDULO:',30,this.lin,{align: 'right'})
                    this.pdf.text(this.data[index].ser_descricao+' - '+this.data[index].ser_codigo,32,this.lin)        
                    this.onAddLine(this.jump+1)
                    this.pdf.text('INÍCIO:',30,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.data(this.data[index].horari_data_inicial)),32,this.lin)        
                    this.pdf.text('FIM:',60,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.data(this.data[index].horari_data_final)),62,this.lin)        
                    if (this.filtro.assinatura === 1){
                        this.onAddLine(this.jump+4)
                    } else {
                        this.onAddLine(this.jump+1)
                    }
                }

                this.pdf.setFont('helvetica','normal')
                this.pdf.setFontSize(7.5)
                this.pdf.text(String(this.data[index].matri_codigo),30,this.lin,{align: 'right'})
                this.pdf.text(this.data[index].pes_nome,32,this.lin)
                if (this.filtro.assinatura === 1){
                    this.pdf.line(100,this.lin,(this.widthPage+8),this.lin);
                }
                this.onAddRegLine()
                if (this.filtro.assinatura === 1){
                    this.onAddLine(this.jump+3)
                } else {
                    this.onAddLine(this.jump+1)
                }
            }
               
            if (this.filtro.agrupa_agendamento === 1){
                if (this.filtro.assinatura === 1 && agendamento > 0){
                    this.onAddLine(this.jump+2)
                    this.pdf.text('DATA: _____/_____/_________',32,this.lin)

                    this.onAddLine(this.jump+2)
                    this.pdf.text('ASS.:',32,this.lin)
                    this.pdf.line(100,this.lin,(this.widthPage+8),this.lin);

                    this.onAddLine(this.jump+2)
                    this.pdf.text('OBSERVAÇÕES:',32,this.lin)
                    this.onAddLine(this.jump+2)
                    this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);
                    this.onAddLine(this.jump+2)
                    this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);
                    this.onAddLine(this.jump+2)
                    this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);
                    this.onAddLine(this.jump+2)
                    this.pdf.line(34,this.lin,(this.widthPage+8),this.lin); 
                                        
                }  
            } else if (this.filtro.assinatura === 1 && this.filtro.agrupa_agendamento !== 1 && grupo > 0){

                this.onAddLine(this.jump+2)
                this.pdf.text('DATA: _____/_____/_________',32,this.lin)

                this.onAddLine(this.jump+2)
                this.pdf.text('ASS.:',32,this.lin)
                this.pdf.line(100,this.lin,(this.widthPage+8),this.lin);

                this.onAddLine(this.jump+2)
                this.pdf.text('OBSERVAÇÕES:',32,this.lin)
                this.onAddLine(this.jump+2)
                this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);
                this.onAddLine(this.jump+2)
                this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);
                this.onAddLine(this.jump+2)
                this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);
                this.onAddLine(this.jump+2)
                this.pdf.line(34,this.lin,(this.widthPage+8),this.lin);

            }            
                        
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            const urlPDF = this.pdf.output('blob');
            this.outputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
            this.$emit('update:outputPdf', this.outputPdf);
        },
    },
}
</script>