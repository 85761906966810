<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <mct-alertas :mensagem="message" :errors="errors" :status="status" :modo="modo" v-show="status !== 0"></mct-alertas>
      <div class="row">
        <div class="col-2">
          <mct-form-itens tipo="number" campo="matri_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" visivel="false" modo="readonly"></mct-form-itens>                      
        </div>    
      </div>        
      <div class="view" v-if="tela1">        
        <div class="row">
          <div class="col-2">
            <fieldset class="well">
              <legend class="well-legend">Tipo</legend>
                  <mct-form-itens tipo="radio" campo="matri_tipo" :valor="lista" obrigatorio="false" :errors="errors" :origemData="[{value:'A',text:'ABERTA'},{value:'F',text:'FECHADA'}]" :modo="modo"></mct-form-itens>
            </fieldset>
          </div>
        </div>
        <fieldset class="well">
          <legend class="well-legend">Aluno</legend>
          <div class="row">
            <div class="col-12">
              <mct-form-itens tipo="search" campo="matri_alu_codigo" :valor="lista" titulo="Aluno" :errors="errors" obrigatorio="true" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' @setDataRetorno="setAluno" :modo="modo" tamanho="2"></mct-form-itens>
            </div>
          </div>
        </fieldset>
      
        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Turma</legend> 
          <div class="row">
            <div class="col-8">
              <mct-form-itens tipo="search" campo="matri_turm_codigo" :valor="lista" titulo="Turma" :errors="errors" obrigatorio="true" origem="esc_turmas" origemChave="turm_codigo" origemRetorno='turm_completa' @setDataRetorno="setTurma" origemChaveComposta="true" tamanho="2" :modo="modo"></mct-form-itens>
            </div>
          </div>
          <div class="row">
            <div class="col-1 mr-0 pr-0">
              <mct-form-itens tipo="text" campo="curso_codigo" :valor="turma" titulo="Curso" :errors="errors"  modo="readonly"></mct-form-itens>
            </div>
            <div class="col-5">
              <mct-form-itens tipo="text" campo="curso_descricao" :valor="turma" titulo=" " :errors="errors" modo="readonly"></mct-form-itens>
            </div>
          </div>
          <div class="row">
            <div class="col-1 mr-0 pr-0">
              <mct-form-itens tipo="text" campo="serie_codigo" :valor="turma" titulo="Série" :errors="errors" modo="readonly"></mct-form-itens>
            </div>
            <div class="col-5">
              <mct-form-itens tipo="text" campo="serie_descricao" :valor="turma" titulo=" " :errors="errors" modo="readonly"></mct-form-itens>
            </div>
          </div>
          <div class="row">
            <div class="col-1 mr-0 pr-0">
              <mct-form-itens tipo="text" campo="turno_codigo" :valor="turma" titulo="Turno" :errors="errors" modo="readonly"></mct-form-itens>
            </div>
            <div class="col-5">
              <mct-form-itens tipo="text" campo="turno_descricao" :valor="turma" titulo=" " :errors="errors" modo="readonly"></mct-form-itens>
            </div>
          </div>
        </fieldset>
      </div> 
      <div class="view" v-if="tela2">        
        <div class="row">
          <div class="col-8">
            <mct-form-itens tipo="search" campo="matri_alu_codigo" :valor="lista" titulo="Aluno" :errors="errors" obrigatorio="true" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' modo="readonly" tamanho="2"></mct-form-itens>
          </div>
        </div>
        <div class="row">
          <div class="col-8">
            <mct-form-itens tipo="search" campo="matri_turm_codigo" :valor="lista" titulo="Turma" :errors="errors" obrigatorio="true" origem="esc_turmas" origemChave="turm_codigo" origemRetorno='turm_completa' @setDataRetorno="setTurma" origemChaveComposta="true" tamanho="2" :modo="modo"></mct-form-itens>
          </div>
          <div class="col-2">
            <mct-form-itens tipo="select" campo="matri_semestre" :valor="lista" titulo="Regime" :origemData="[{value:'ANUAL',text:'ANUAL'},{value:'1 SEMESTRE',text:'1º SEMESTRE'},{value:'2 SEMESTRE',text:'2º SEMESTRE'}]" :errors="errors" modo="readonly"></mct-form-itens>    
          </div>
        </div>
      </div>
      <div class="view" v-if="tela3">
        <fieldset class="well" >
          <legend class="well-legend">Tipo Matrícula</legend> 
          <div class="row">
            <div class="col-12">
              <mct-form-itens tipo="radio" campo="matri_tipo_matricula" :valor="lista" :origemData="[{value:'C',text:'Continuidade'},{value:'I',text:'Ingressante'},{value:'T',text:'Transferido'}]" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
          </div>
        </fieldset>
        <fieldset class="well" >
          <legend class="well-legend">Observações</legend> 
          <div class="row">
            <div class="col-12">
              <mct-form-itens tipo="textarea" campo="matri_observacao" :valor="lista" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
          </div>
        </fieldset>
      </div>
     
      
      <div class="view" v-if="tela4">
        <fieldset class="well">
          <legend class="well-legend">Disciplina</legend> 
          <div class="row" style="padding-bottom: 10px;">
            <div class="col-5" @keyup.enter="onTab()">
              <mct-form-itens tipo="search" campo="matridisp_disp_codigo" :valor="disciplina" titulo="Disciplina" :errors="errors" origem="esc_disciplina" origemChave="disp_codigo" origemRetorno='disp_nome' @setDataRetorno="setDisciplina" :modo="modo" tamanho="2"></mct-form-itens>
            </div>
            <div class="col-5" @keyup.enter="onAdcDisciplina()">
              <mct-form-itens tipo="text" campo="matridisp_observacao" :valor="disciplina" titulo="Observação" :errors="errors" :modo="modo"></mct-form-itens>
            </div>
            <div class="is-icon mt-3">
              <i class="fa fa-check text-white mct-fa bg-success" aria-hidden="true"  @click.prevent.stop="onAdcDisciplina()"></i>
            </div>
          </div>
          <div class="mct-grid">
            <div class="mct-grid-scroll-sec">                        
              <table class="table table-sm table-striped table-bordered">
                <thead>
                  <tr>
                    <th scope="col" style="width: 10%;">AÇÃO</th>
                    <th scope="col" style="width: 10%;">DISCIPLINA</th>
                    <th scope="col" style="width: 40%;">NOME DISCIPLINA</th>
                    <th scope="col" style="width: 40%;">OBSERVAÇÃO</th>
                  </tr>
                </thead>
                <tbody>                                
                  <tr v-for="(disciplina,index) in lista.disciplinas" :key="index">
                    <th scope="row">
                      <div class="is-icon">
                        <i class="fa fa-pencil text-white mct-fa bg-primary" aria-hidden="true"  @click.prevent.stop="onEditDisciplina(index)"></i>
                        <i class="fa fa-times text-white bg-danger mct-fa" aria-hidden="true"  @click.prevent.stop="onExcDisciplina(index)"></i>
                      </div>
                    </th>
                    <td>{{disciplina.matridisp_disp_codigo}}</td>
                    <td>{{disciplina.nome_disciplina}}</td>
                    <td>{{disciplina.matridisp_observacao}}</td>
                  </tr>    
                </tbody>
              </table>
            </div>
          </div>
        </fieldset>
      </div>
       <fieldset class="well" v-if="tela5">
        <legend class="well-legend">Funcionário Responsável pela Matrícula</legend> 
        <div class="row">
          <div class="col-8">
            <mct-form-itens tipo="search" tamanho="2" campo="matri_resp_codigo" :valor="lista" titulo="Código" :errors="errors" obrigatorio="true" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' @setDataRetorno="setFuncionario" modo="readonly" ></mct-form-itens>
          </div>
        </div>
      </fieldset>

      <div class="btn-group" role="group" slot="botoes">
        <button  v-if="btnvolta" type="button" class="btn btn-dark text-white" aria-label="Left Align"  @click.prevent.stop="voltar()">
          <span class="fa fa-arrow-left text-white" aria-hidden="true"></span> Voltar </button>
        <button  v-if="btnavanca" type="button" class="btn btn-info text-white" aria-label="Left Align"   @click.prevent.stop="avancar()">
        <span class="fa fa-arrow-right text-white" aria-hidden="true"></span> Avançar </button>
        <button  v-if="btngrava" type="button" class="btn btn-success text-white" aria-label="Left Align"  @click.prevent.stop="gravar()">
        <span class="fa fa-check text-white" aria-hidden="true"></span> Gravar </button>
        <button  v-if="btncancela" type="button" class="btn btn-danger text-white" aria-label="Left Align" @click.prevent.stop="$router.back()">
        <span class="fa fa-times text-white" aria-hidden="true"></span> Fechar </button>
      </div>
    </div> 
  </mct-padrao>      
</template>

<script>

import axios from '@/http'
import { mapState, mapActions } from 'vuex'

import TblTurmasAulas from './TblTurmasAulas.vue'

import MctPadrao from '@/components/comum/MctFormPadraoSB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctModal from '@/components/comum/MctModalSHB.vue'
import MctPopUps from '@/components/comum/MctPopUps.vue'
import MctAlertas from "@/components/comum/MctAlertas.vue"

export default {
  Name: 'Cursos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      MctModal,
      TblTurmasAulas,
      MctAlertas,
      MctPopUps, 
  },
  data () {
      return {
        lista:{},
        errors:{},
        message: '',
        tipoAlerta: '',
        msgAlerta: '',
        titAlerta: '',
        tela1: false,
        tela2: false,
        tela3: false,
        tela4: false,
        tela5: false,
        btnavanca:false,
        btnvolta:false,
        btngrava:false,
        btncancela:false,
        turma:{'curso_codigo':'','curso_descricao':'','serie_codigo':'','serie_descricao':'','turno_codigo':'','turno_descricao':'','turma_descricao':''},
        aluno:{},
        disciplina: {
            matri_codigo: '',
            matridisp_observacao: ''
        },
        status: 0,
      }
  },
  methods: {
    
    setData (lista, errors, lastCode) {
      this.lista = lista
      this.errors = errors 
      this.lista.matri_resp_codigo = this.infoLogado.pes_codigo
      if(this.modo=='novo'){
        this.lista.matri_semestre = 'ANUAL'
        this.tela1=true      
        this.btnavanca=true
        this.btncancela=true
        this.lista.matri_tipo = 'F'
        this.lista.matri_tipo_matricula = 'C'
        this.lista.dscmatridisp_disp_codigo=''
        this.disciplina.dscmatridisp_disp_codigo=''
        if(this.disciplina.matridisp_disp_codigo==null)
        {
          this.lista.disp_nome=''
          this.lista.nome_disciplina=''
        }
      }
      if(this.lista.matri_tipo=='F'){
        this.lista.disciplinas=[]
      }
    },
    onTab(){
       matridisp_observacao.focus();
    },
    setAluno(data){
      this.aluno = data
    },
    setTurma(data){
      if(data.cursoturma)
      {
        this.turma.curso_codigo = data.cursoturma.cur_codigo
        this.turma.curso_descricao = data.cursoturma.cur_nome
        this.turma.serie_codigo = data.cursoturma.ser_codigo
        this.turma.serie_descricao = data.cursoturma.ser_descricao
        this.turma.turno_codigo = data.turnoturma.turn_codigo
        this.turma.turno_descricao = data.turnoturma.turn_descricao
        //this.turma.turma_descricao = 'Curso: '+data.cursoturma.cur_nome+' - '+data.turnoturma.turn_descricao+' - '+data.cursoturma.ser_descricao
      }
    },
    setDisciplina(data){
      this.lista.nome_disciplina = data.disp_nome
    },
    setFuncionario(data){
      this.lista.pessoa = data
    },
    limpaTela(){
        this.lista.matri_resp_codigo = this.infoLogado.pes_codigo
        this.lista.matri_semestre = 'ANUAL'
        this.lista.matri_tipo = 'F'
        this.lista.matri_alu_codigo = ''
        this.lista.matri_observacao = ''
        this.lista.matri_turm_codigo = ''
        this.turma.turma_descricao = ''
        this.turma.curso_codigo = ''
        this.turma.curso_descricao = ''
        this.turma.serie_codigo = ''
        this.turma.serie_descricao = ''
        this.turma.turno_codigo = ''
        this.turma.turno_descricao = ''
        this.lista.matri_tipo_matricula = 'C'
        this.lista.dscmatridisp_disp_codigo=''
        this.disciplina.dscmatridisp_disp_codigo=''
        if(this.disciplina.matridisp_disp_codigo==null)
        {
          this.lista.disp_nome=''
          this.lista.nome_disciplina=''
        }

      if(this.lista.matri_tipo=='F'){
        this.lista.disciplinas=[]
      }
      this.voltar()
    },
    avancar(){
      if(this.validaInputs()){
        this.tela1=false
        this.tela2=true
        this.btnavanca=false
        this.btnvolta=true
        this.btngrava=true
      
        if(this.lista.matri_tipo == 'A')
        {
          this.tela3 = false
          this.tela4 = true
          this.tela5 = true
        }
        else if(this.lista.matri_tipo == 'F')
        {
          this.tela3 = true
          this.tela4 = false
          this.tela5 = true
        }
      }  
    },
    voltar(){
      this.tela1=true
      this.tela2=false
      this.btnavanca=true
      this.btnvolta=false
      this.btngrava=false
      this.tela3 = false
      this.tela4 = false
      this.tela5 = false
    },
    onAdcDisciplina () {
      this.lista.disciplinas.push(
      {
        matridisp_disp_codigo: this.disciplina.matridisp_disp_codigo,
        matridisp_observacao: this.disciplina.matridisp_observacao,
        nome_disciplina: this.lista.nome_disciplina,
        matridisp_modo: 'DEPENDÊNCIA'
      })
      //this.disciplina={}
      this.disciplina.matridisp_disp_codigo = ''
      this.disciplina.matridisp_observacao = ''
      this.lista.disp_nome=''
      this.lista.nome_disciplina=''
      this.disciplina.disp_nome=''
    },
    onEditDisciplina (index) {
      this.disciplina.matridisp_disp_codigo = this.lista.disciplinas[index].matridisp_disp_codigo
      this.disciplina.matridisp_observacao = this.lista.disciplinas[index].matridisp_observacao
      this.disciplina.nome_disciplina = this.lista.disciplinas[index].nome_disciplina
      this.lista.disciplinas.splice(index,1)
    },
    onExcDisciplina (index) {
      this.$swal({
        title: 'Deseja realmente remover o item da lista?',
        text: "Este evento não podera ser revertido!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim'
      }).then((result) => {
        this.lista.disciplinas.splice(index,1)                       
      })   
    },
    validaInputs () {
      this.errors = new Object()
      if (this.lista.matri_alu_codigo == null || this.lista.matri_alu_codigo == ''){
        this.errors.matri_alu_codigo = []
        this.errors.matri_alu_codigo.push("O campo ALUNO não pode ser nulo ou vazio.") 
      }
      if (this.lista.matri_turm_codigo == null || this.lista.matri_turm_codigo == ''){
        this.errors.matri_turm_codigo = []
        this.errors.matri_turm_codigo.push("O campo TURMA não pode ser nulo ou vazio.") 
      }
      if (Object.keys(this.errors).length > 0) {
        this.message = "Houve um ou mais erros."
        this.status = 422
        return false
      }else{
        this.message = ""
        this.status = 0
        return true
      }        
    },
    async gravar(){
      if(this.validaInputs()){
        
        if(this.lista.matri_tipo=='F')
        {
          this.lista.disciplinas=[]
        }else
        if(this.lista.matri_tipo=='A')
        {
          this.lista.matri_tipo_matricula=''
          this.lista.matri_observacao=''
        }
        
        var dados = this.lista
        await axios.post(`${this.source}`,dados)
          .then(response => {
          this.message = response.data.message
          this.status = response.status
          this.errors = {}
           
          this.$swal('Sucesso!',
                      'Registro incluído com sucesso!',
                      'success'); 
        })
        .catch(response => {
          this.message = response.response.data.message
          if (response.response.data.error){
            this.errors = response.response.data.error.errorInfo[2]  
          }else{
            this.errors = response.response.data.errors
            this.$swal('Matrícula já Existente!',
                        this.errors.matricula[0] +'<br>'+ this.errors.aluno[0] +'<br>'+ this.errors.escola[0] +'<br>'+ this.errors.turma[0] +'<br>'+'A Matrícula não pode ser efetuada.',
                        'error')
          }
          this.status = response.response.status
          
        })
        this.limpaTela()
      }
      
    }
  },
   computed: {
    ...mapState('Login',['infoLogado','Exercicio', 'Escola', 'Entidade','infoLogado']), 
  },  
  created() {
  
  }     
}
</script>