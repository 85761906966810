<template>
    <div class="animated fadeIn mct-form">
        <mct-topo-lista :titulo="titulo" :subtitulo="'Manutenção de '+ titulo + ' - ' + modo.toUpperCase()"></mct-topo-lista>    
        <mct-alertas :mensagem="message" :errors="errors" :status="status" :modo="modo" v-show="status !== 0"></mct-alertas>
        <mct-form>
          <div class="row">
            <div class="col-sm-2" style="padding-left: 20px;">
              <mct-form-itens tipo="number" campo="profturn_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
            </div>    
          </div>      
          <div class="row">
            <div class="col-lg-12">
              <fieldset class="well" style="padding-top: 0px;margin-top: 5px;padding-bottom: 5px;margin-bottom: 0px;">
                <legend class="well-legend">Professor / Turno</legend>
                <div class="row">
                  <div class="col-lg-10">
                    <mct-form-itens tipo="select" campo="profturn_prof_codigo" :valor="lista" titulo="Professor" obrigatorio="true" :errors="errors" 
                    :origemFiltro="{pes_situacao: 1, pes_tipo_cad: 'P'}" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo' :modo="modo"></mct-form-itens>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-10">
                    <mct-form-itens tipo="select" campo="turn_codigo" :valor="lista" titulo="Turno" obrigatorio="true" :errors="errors" 
                    origem="esc_turno" origemChave="turn_codigo" origemRetorno='turn_descricao' origemChaveComposta="true" :modo="modo" @setDataRetorno="setTurno"></mct-form-itens>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-2">
                    <mct-form-itens tipo="number" campo="profturn_aulas_periodo" :valor="lista" titulo="Aulas no Período" :errors="errors" :modo="modo"></mct-form-itens>
                  </div>
                  <div class="col-lg-2">
                    <mct-form-itens tipo="number" campo="profturn_hora_atividade" :valor="lista" titulo="Hora Atividade" :errors="errors" :modo="modo"></mct-form-itens>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <fieldset class="well col-lg-8" >
            <legend class="well-legend">Disponibilidade para Aulas:</legend>
            <div class="mct-grid">
              <div class="mct-grid-scroll-sec">
                <table class="table table-striped table-bordered table-sm table-hover">
                  <thead>
                    <tr>
                      <th>
                        <span>AULA</span>
                      </th>
                      <th>
                        <span>SEGUNDA</span>
                      </th>
                      <th>
                        <span>TERÇA</span>
                      </th>
                      <th>
                        <span>QUARTA</span>
                      </th>
                      <th>
                        <span>QUINTA</span>
                      </th>  
                      <th>
                        <span>SEXTA</span>
                      </th>
                      <th>
                        <span>SÁBADO</span>
                      </th>                              
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(aula,index) in lista.disponibilidade" :key="index" :class="[{editing: aula == linhaEditada}]" v-cloak style="text-align: right;"> 
                      <td> 
                        <div class="view" style="width: 35px;">
                          {{index+1}}
                        </div>
                      </td>
                      <td> 
                        <div class="view" style="width: 10px; padding-left: 40px;">
                          <mct-form-itens tipo="checkbox" campo="turndispo_segunda" :valor="aula" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                      </td>
                      <td>
                        <div class="view" style="width: 10px; padding-left: 30px;">
                          <mct-form-itens tipo="checkbox" campo="turndispo_terca" :valor="aula" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                      </td>
                      <td>
                        <div class="view" style="width: 10px; padding-left: 30px;">
                          <mct-form-itens tipo="checkbox" campo="turndispo_quarta" :valor="aula" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                      </td>
                      <td>
                        <div class="view" style="width: 10px; padding-left: 30px;">
                          <mct-form-itens tipo="checkbox" campo="turndispo_quinta" :valor="aula" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                      </td>
                      <td>
                        <div class="view" style="width: 10px; padding-left: 30px;">
                          <mct-form-itens tipo="checkbox" campo="turndispo_sexta" :valor="aula" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                      </td>
                      <td>
                        <div class="view" style="width: 10px; padding-left: 40px;">
                          <mct-form-itens tipo="checkbox" campo="turndispo_sabado" :valor="aula" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </fieldset> 
        </mct-form>
        <mct-form-botoes @submit="submit()" :modo="modo"></mct-form-botoes>
    </div>       
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import axios from '@/http'

import MctTopoLista from '@/components/comum/MctTopoLista.vue'
import MctForm from '@/components/comum/MctForm.vue'
import MctFormBotoes from '@/components/comum/MctFormBotoes.vue'
import MctAlertas from '@/components/comum/MctAlertas.vue' 
import MctMixinsForm from '@/components/funcoes/MctMixinsForm'

import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'ProfessorTurnos',
  mixins: [MctMixinsForm],  
  props: ['source','titulo','modo'],
  components: {
        MctForm,
        MctTopoLista,
        MctFormBotoes,
        MctAlertas,
        MctFormItens
  },
  data () {
      return {
        dataturno: null,
        linhaEditada: null,
      }
  },
  methods: { 
    async onValida() {
        let msgs = ''
        let soma = 0       
        if (this.lista.disponibilidade !== null && this.lista.disponibilidade !== 'undefined'){
            let soma = 0
            for (var index in this.lista.disponibilidade) {
              soma += parseInt(this.lista.disponibilidade[index].turndispo_segunda) 
              soma += parseInt(this.lista.disponibilidade[index].turndispo_terca) 
              soma += parseInt(this.lista.disponibilidade[index].turndispo_quarta) 
              soma += parseInt(this.lista.disponibilidade[index].turndispo_quinta) 
              soma += parseInt(this.lista.disponibilidade[index].turndispo_sexta) 
              soma += parseInt(this.lista.disponibilidade[index].turndispo_sabado) 
            }            
            if (parseInt(soma) !== parseInt(this.lista.profturn_aulas_periodo)){
              msgs += 'O Total de Aulas selecionadas '+parseInt(soma)+', não pode ser diferente do valor de Aulas no Período '+parseInt(this.lista.profturn_aulas_periodo)+'; </br>';
            }
        }                    
        if (msgs !== ''){
          this.isValidated = await false
          this.$swal({
            icon: 'error',
            title: 'Erro(s) ao Incluir / Alterar o Registro',
            html: msgs,
            footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
          })        
        }else{
          this.isValidated = await true
        }
    },   
    setData () {
      if(this.modo === 'novo'){              
        this.lista.curr_ano = this.Exercicio
        this.lista.curr_ent = this.Entidade
        this.lista.curr_esc = this.Escola
      }
    },
    setTurno(data){ 
      this.dataturno = data
    },
  },
   computed: {
    ...mapState('Login',['infoLogado']), 
  },
  created() {
  
  },
  watch: {    
    'lista.turn_codigo' (newVal, oldVal){
      if (newVal !== oldVal){
        if(this.dataturno && this.dataturno.turn_aulas_diaria && (this.dataturno.turn_codigo != oldVal)){
            this.lista.disponibilidade = []
            for (let i = 0; i < this.dataturno.turn_aulas_diaria; i++) {
              this.lista.disponibilidade.push({turndispo_codigo: '', turndispo_segunda: 1, turndispo_terca: 1, turndispo_quarta: 1, turndispo_quinta: 1, turndispo_sexta: 1, turndispo_sabado: 1, turndispo_aula:(i+1), status:'N'})
            }
          }
      }
    }
  }     
}
</script>

