<template>
    <div>
        <div class="p-card d-flex flex-row flex-wrap justify-content-between w-100">
            <div class="d-flex flex-row flex-wrap justify-content-between w-50 position-relative">
                <div class="cards cards-22 flex-fill align-self-stretch">
                    <h1>Recebidas (no dia)</h1>
                    <h2 class="text-success">{{lista.rec_nodia | moeda}}</h2>
                    <span class="text-secondary">{{lista.perrec_nodia | moeda}}%</span>
                </div>
                <div class="cards cards-22 flex-fill align-self-stretch">
                    <h1>À Receber (no dia)</h1>
                    <h2 class="text-info">{{lista.arec_nodia | moeda}}</h2>
                    <span class="text-secondary"></span>
                </div>
                <div class="cards cards-22 flex-fill align-self-stretch">
                    <h1>No Último Mês</h1>
                    <h2 class="text-success">{{lista.rec_mesant | moeda}}</h2>
                    <span class="text-secondary"></span>
                </div>
                <div class="cards cards-22 flex-fill align-self-stretch">
                    <h1>No Mês</h1>
                    <h2 class="text-success">{{lista.rec_nomes | moeda}}</h2>
                    <span class="text-secondary">{{lista.perrec_nomes | moeda}}%</span>
                </div>
                
                <div class="cards circle">  
                    <div class="chart-wrapper">
                        <chart-circular :data="[lista.arec_nomes,lista.rec_nomes]" :labels="['Receber no Mês', 'Recebidas no Mês']" :colors="['#FF3C1A', '#ff674d']"/>
                    </div>
                </div>
            </div>

            <div class="d-flex flex-row flex-wrap justify-content-between w-50 position-relative">
                <div class="cards cards-22 flex-fill align-self-stretch">
                    <h1>Pagas (no dia)</h1>
                    <h2 class="text-danger">{{lista.pag_nodia | moeda}}</h2>
                    <span class="text-secondary">{{lista.perpag_nodia | moeda}}%</span>
                </div>
                <div class="cards cards-22 flex-fill align-self-stretch">
                    <h1>À Pagar (no dia)</h1>
                    <h2 class="text-primary">{{lista.apag_nodia | moeda}}</h2>            
                    <span class="text-secondary"></span>
                </div>                
                <div class="cards cards-22 flex-fill align-self-stretch">
                    <h1>No Mês</h1>
                    <h2 class="text-danger">{{lista.pag_nomes | moeda}}</h2>
                    <span class="text-secondary">{{lista.perpag_nomes | moeda}}%</span>
                </div>
                <div class="cards cards-22 flex-fill align-self-stretch">
                    <h1>No Último Mês</h1>
                    <h2 class="text-danger">{{lista.pag_mesant | moeda}}</h2>
                    <span class="text-secondary"></span>
                </div>
                <div class="cards circle">  
                    <div class="chart-wrapper">
                        <chart-circular :data="[lista.apag_nomes,lista.pag_nomes]" :labels="['Pagar no Mês', 'Pagas no Mês']" :colors="['#1FC178', '#1aff98']"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-card d-flex flex-row flex-wrap justify-content-between w-100">
            <div class="cards cards-2 flex-fill align-self-stretch">
                <h1>Recebidas e Pagas por Centro de Custo</h1>
                <div class="chart-wrapper">
                    <chart-barras :width="300" :height="300"/>
                </div>
            </div>        
            <div class="cards cards-2 flex-fill align-self-stretch">
                <h1>Recebidas e Pagas no Mês por Dia</h1>
                <div class="chart-wrapper">
                    <chart-linhas :width="300" :height="300"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/http'

import ChartCircular from './charts/Circular.vue'
import ChartLinhas from './charts/Linhas.vue'
import ChartBarras from './charts/Barras.vue'

export default {
    name: 'EscModFinanceiro',
    components: {
        ChartCircular,
        ChartLinhas,
        ChartBarras,
    },
    data() {
        return {
            lista: {}
        }
    },
    methods: {
        async getData() {
            let URL = '/dashboard/financeiro'
            URL += '?database=' + this.moment(new Date()).format('YYYY-MM-DD')
            await axios.get(`${URL}`)
                .then(response => {
                    this.lista = response.data.data
                    //console.log(this.lista)
                    //this.errors = {}
                })
                .catch(response => {
                    //this.message = response.response.data.message
                    //this.errors = response.response.data.errors
                    //this.status = response.response.status
                })
      
        },
    },
    sockets: {
        mctatufinanceiro: function (data) {
            this.getData()
            this.$awn.info("O Financeiro foi atualizado.") 
        },
    },
    created () {
        this.getData()
    }    
}
</script>

<style scoped>

.p-card{
    position: relative;
}

.cards{
    position: relative;
    color: #404040;
    background-color: #FFFFFF;
    margin: 4px;
    display: inline-block;
    overflow: hidden;
}

.cards-4{
    height: 150px;
    width: 22%;
}

.cards-22{
    height: 150px;
    width: 48%;
}

.cards-2{
    min-height: 250px;
    width: 49%;
}


.cards h1 {
    width: 100%;
    font-size: .7rem;
    padding: 5px 2px;
    text-align: center;
    border-bottom: 1px solid #F0F3F5; 
}

.cards h2{
    position: absolute;
    width: 100%;    
    font-size: 1.6rem;
    text-align: center;
    font-weight: 400;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);    
}

.cards span{
    position: absolute;
    width: 100%;    
    font-size: 1.3rem;
    text-align: center;
    font-weight: 300;
    left: 50%;
    top: 75%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);    
}

.cards-center{
    width: 8%;
    background: transparent;
}

.circle{
    z-index: 2;
    position: absolute;
    height: 240px;
    width: 240px;
    background-color: #F0F3F5;
    border-radius: 50%;
    /*border: 5px solid #F0F3F5;*/
    padding: 5px;
    display: inline-block;  
    margin: 0;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    overflow: hidden;
}

.circle > .chart-wrapper{
    margin-top: -85px;
}


</style>


