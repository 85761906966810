<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" :titAlerta="`ERRO: ${status}`" :msgAlerta="message" :tipoAlerta="tipoAlerta" @setData="setData">
        <div slot="formulario">
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="per_codigo" :valor="lista" titulo="Código" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="text" campo="per_descricao" :valor="lista" titulo="Descrição" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="select" campo="per_tipo" :valor="lista" titulo="Tipo (Painel / DashBoard)" obrigatorio="true" :errors="errors" :modo="modo"
                      :origemData="[{value: 0, text: 'NENHUM'},
                                    {value: 1, text: 'ADMINISTRADOR'},
                                    {value: 2, text: 'FINANCEIRO'},
                                    {value: 3, text: 'VENDAS'},
                                    {value: 4, text: 'SECRETÁRIA'}
                      ]"></mct-form-itens>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-6">
                <fieldset class="well h-100">
                    <legend class="well-legend">Permissões Módulo Financeiro</legend>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_exc_cnts_pagar" :valor="lista" titulo="Permitir Excluir Contas a Pagar, Parcelas e seus Pagamentos." showTitulo="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_exc_cnts_receber" :valor="lista" titulo="Permitir Excluir Contas a Receber, Parcelas e seus Recebimentos."  showTitulo="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_exc_contrato" :valor="lista" titulo="Permitir Excluir Contrato, Contas a Receber, Parcelas e seus Recebimentos." showTitulo="false"  :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_exc_parc_cnts_pagar" :valor="lista" titulo="Permitir Excluir Parcelas do Contas a Pagar com Pagamentos." showTitulo="false"  :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_exc_parc_cnts_receber" :valor="lista" titulo="Permitir Excluir Parcelas do Contas a Receber com Recebimentos." showTitulo="false"  :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_exc_lanc_financeiro" :valor="lista" titulo="Permitir Excluir Lançamentos Financeiros." showTitulo="false"  :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_canc_cnts_pagar" :valor="lista" titulo="Permitir Cancelar Contas a Pagar, Parcelas e seus Pagamentos." showTitulo="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_canc_cnts_receber" :valor="lista" titulo="Permitir Cancelar Contas a Receber, Parcelas e seus Recebimentos."  showTitulo="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_canc_contrato" :valor="lista" titulo="Permitir Cancelar Contrato, Contas a Receber, Parcelas e seus Recebimentos." showTitulo="false"  :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_canc_parc_cnts_pagar" :valor="lista" titulo="Permitir Cancelar Parcelas do Contas a Pagar com Pagamentos." showTitulo="false"  :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_canc_parc_cnts_receber" :valor="lista" titulo="Permitir Cancelar Parcelas do Contas a Receber com Recebimentos." showTitulo="false"  :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_canc_lanc_financeiro" :valor="lista" titulo="Permitir Cancelar Lançamentos Financeiros." showTitulo="false"  :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_est_parc_cnts_pagar" :valor="lista" titulo="Permitir Estornar Pagamentos de Parcelas do Contas a Pagar." showTitulo="false"  :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_est_parc_cnts_receber" :valor="lista" titulo="Permitir Estornar Recebimentos de Parcelas do Contas a Receber." showTitulo="false"  :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="checkbox" campo="per_siges_est_lanc_financeiro" :valor="lista" titulo="Permitir Estornar Lançamentos Financeiros." showTitulo="false"  :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
                </div>
            </div>

        </div>
    </mct-padrao>
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'

import MctPadrao from '../comum/MctFormPadrao.vue'
import MctFormItens from '../comum/MctFormItens.vue'


export default {
  Name: 'Perfil',
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
          lista: {},
          errors:{},
          message: '',
          status: 0,
          tipoAlerta: '',
          ultreg: 0,
      }
  },
  methods: {
    setData (lista) {
        this.lista = lista
        if (this.modo == 'novo'){

            this.lista.ent_codigo = this.Entidade
        }
    },

    getUltReg (modulo, submodulo) {
        var vm = this
        axios.get(`menu/ultreg/`+modulo+`/`+submodulo)
        .then(function(response) {
            vm.$data.lista.menu_codigo = response.data[0].ultreg
        })
        .catch(function(response) {
            console.log(response)
        })
    }
  },
  computed: {
    ...mapState('Login',['infoLogado','Entidade']),

  },
  created() {

},
  watch: {

  }
}
</script>
