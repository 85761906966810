<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="cnv_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
                <div class="col-lg-2">
                    <mct-form-itens tipo="checkbox" campo="cnv_ativo" :valor="lista" :titulo="lista.cnv_ativo == 1 ? 'Cadastro Ativo.' : 'Cadastro Inativado.'" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <mct-form-itens tipo="text" campo="cnv_nome" :valor="lista" titulo="Nome" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>

            <div class="row">
                <div class="col-sm-8">
                    <fieldset class="well">
                        <legend class="well-legend">Outros Dados / Logradouro</legend>                    
                        <div class="row">
                            <div class="col-sm-4">
                                <mct-form-itens tipo="text" campo="cnv_telefone" :valor="lista" titulo="Telefone/Celular" obrigatorio="false" mascara="telefone" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-sm-4">
                                <mct-form-itens tipo="text" campo="cnv_telefone2" :valor="lista" titulo="Telefone/Celular" obrigatorio="false" mascara="telefone" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                        </div>
                        <mct-logradouro :modo="modo" :valor="lista" :rels="{logra_codigo: 'cnv_logra_codigo', logra_numero: 'cnv_logra_numero', logra_complemento: 'cnv_logra_complemento'}"></mct-logradouro> 
                    </fieldset>
                </div>
            </div>            
            <div class="row">
                <div class="col-sm-8">
                    <fieldset class="well">
                        <legend class="well-legend">Desconto</legend>                    
                        <div class="row">
                            <div class="col-sm-4">
                                <mct-form-itens tipo="select" campo="cnv_tipo_desconto" :valor="lista" titulo="Tipo de Desconto" 
                                    :origemData="[{value: 1, text: 'GERAL'}, {value: 2, text: 'POR CURSO'}]" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                           <div class="col-sm-4">
                                <mct-form-itens tipo="number" campo="cnv_desconto_geral" :valor="lista" titulo="Desconto" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div> 
            <div class="row">
                <div class="col-sm-8">
                    <fieldset class="well">
                        <legend class="well-legend">Observações</legend>                    
                        <div class="row">
                            <div class="col-sm-12">
                                <mct-form-itens tipo="textarea" campo="cnv_observacoes" :valor="lista" titulo="" obrigatorio="false" linhas="3" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>             


        </div>              
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctLogradouro from '@/components/comum/MctLogradouro.vue'

export default {
  Name: 'EscConvenios',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      MctLogradouro
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
        /*desconto: {
            ctdesc_codigo: null,
            ctdesc_dia: '',
            ctdesc_desconto: ''
        }*/
      }
  },
  methods: {
    setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            this.lista.cnv_ativo = 1
        }
    },
    /*onAdcDesconto () {
        this.lista.descontos.push(
        {
            ctdesc_codigo: this.desconto.ctdesc_codigo,
            ctdesc_dia: this.desconto.ctdesc_dia,
            ctdesc_desconto: this.desconto.ctdesc_desconto,
        })

        this.desconto.ctdesc_codigo = null
        this.desconto.ctdesc_dia = ''
        this.desconto.ctdesc_desconto = ''
    },
    onEditDesconto (index) {
        this.desconto.ctdesc_codigo = this.lista.descontos[index].ctdesc_codigo
        this.desconto.ctdesc_dia = this.lista.descontos[index].ctdesc_dia
        this.desconto.ctdesc_desconto = this.lista.descontos[index].ctdesc_desconto
        this.lista.descontos.splice(index,1)
    },
    onExcDesconto (index) {
        this.$swal({
            title: 'Deseja realmente remover o item da lista?',
            text: "Este evento não podera ser revertido!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
        }).then((result) => {
            this.lista.modulos.splice(index,1)                       
        })   
        
    },
    onParcelas () {
        var qtdParcelas = Number(this.lista.ctab_parcelas)+Number(this.lista.ctab_parcelas_matri)

        for (var i = 0; i < qtdParcelas; i++) {
            console.log(i);
            var diaVencimento = this.lista.ctab_dia_vencimento
            var dataVencimento = new Date(this.lista.ctab_venc_matricula)
            console.log(dataVencimento)
            this.lista.parcelas.push(
                {
                    ctvenc_parcela: i,
                })            
        }
    }*/     
  },
  computed: {
        ...mapState('Login',['Entidade','Exercicio','Escola'])       
  }, 
  created() {
  
  }     
}
</script>