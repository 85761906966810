<template>
  <div>
    <mct-topo-lista icone="fa-flag" titulo="País" subtitulo="Cadastro de País"></mct-topo-lista>        
    <div class="container">
      <div class="alert alert-success" role="alert" v-show="sucesso">
        <strong>Sucesso</strong> {{ mensagem }}
      </div>
      <form>
        <div class="form-group row">
          <div class="col-sm-2">
            <label for="pais_codigo">Código</label>
            <input type="text" class="form-control" id="pais_codigo" placeholder="Código" v-model="data.pais_codigo">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-4">
            <label for="pais_descricao">Nome País</label>
            <input type="text" class="form-control" id="pais_descricao" placeholder="País" v-model="data.pais_descricao">
          </div>
        </div>
        <div class="form-group row">
          <div class="col-sm-1">
            <button type="button" class="btn btn-success btn-sm" aria-label="Left Align" @click.prevent.stop="salvar()">
                    <span class="fa fa-floppy-o fa-lg" aria-hidden="true"></span> Gravar </button>
          </div>
          <div class="col-sm-1">
            <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click.prevent.stop="excluir()">
                    <span class="fa fa-trash-o fa-lg" aria-hidden="true"></span> Excluir </button>
          </div>
          <div class="col-sm-1">
            <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click.prevent.stop="$router.back()">
                    <span class="fa fa-trash-o fa-lg" aria-hidden="true"></span> Voltar </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import axios from '@/http'
import MctTopoLista from '../comum/MctTopoLista.vue'
export default {
  components: {
    MctTopoLista    
  },
  data () {
    return {
      data: {},
      mensagem: '',
      sucesso: false
    }
  },
  methods: {
    busca (){
      var vm = this
      axios.get(`/pais/edit/`+vm.$route.params.codigo)
      .then(function(response) {
          Vue.set(vm.$data, 'data', response.data)
      })
      .catch(function(response) {
          console.log(response)
      })  
    },    
    salvar () {
      var vm = this
      axios.post('/pais/edit/'+vm.$route.params.codigo, vm.data)
      .then(function(response) {
          Vue.set(vm.$data, 'sucesso', response.data.sucesso)
          Vue.set(vm.$data, 'mensagem', response.data.mensagem)
          console.log(response)
      })
      .catch(function(response) {
          console.log(response)
      }) 
    },
  },
  created (){
      this.busca()
  },  
}
</script>

