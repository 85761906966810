<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="horaridia_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>  
            </div>
            <div class="row mb-2">
                <div class="col-sm-6">
                    <mct-form-itens tipo="select" campo="horari_codigo" :valor="lista" titulo="Horário" obrigatorio="true" 
                        origem="esc_horarios" origemChave="horari_codigo" origemRetorno='turm_completa' :errors="errors" :modo="modo"></mct-form-itens>                                                           
                </div>    
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <mct-form-itens coluna="0" tipo="date" campo="horaridia_data" :valor="lista" titulo="Data" obrigatorio="true" :errors="errors" :modo="modo" :legenda="moment(lista.horaridia_data).locale('pt-br').format('dddd').toUpperCase()">
                    </mct-form-itens> 
                </div>  
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <mct-form-itens tipo="textarea" campo="horaridia_observacoes" :valor="lista" titulo="Observações" obrigatorio="false" linhas="2" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>            
            <table class="table table-responsive table-striped table-bordered table-sm table-hover mt-2">
                <thead>
                    <tr>
                        <th class="text-center">AULA</th>    
                        <th style="width: 180px;">DISCIPLINA/MÓDULO</th>    
                    </tr>
                </thead> 
                <tbody>
                    <tr v-for="(linha,index) in lista.horariosdiadisp" :key="index">
                        <td class="text-center">{{linha.horaridiadisp_aula}}</td>
                        <td>
                            <div class="col-sm-12 m-0 p-0" v-if="(modo == 'editar' || modo == 'novo') && (typeof disciplinas !== 'undefined')">
                                <mct-form-itens tipo="select" campo="disp_codigo" :valor="linha" obrigatorio="false" 
                                    :origemData="disciplinas" origemChave="profdis_disp_codigo" origemRetorno='disp_nome' :errors="errors" :modo="modo"></mct-form-itens>                                                              
                            </div>
                            <span v-else>{{linha.disp_nome}}</span>                                
                        </td>                        
                    </tr>    
                </tbody>   
            </table>  
        </div>              
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'

import moment from 'moment'

import { mapState, mapActions } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'HorariosDia',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
        disciplinas: {}
      }
  },
  methods: {
    setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            this.lista.horari_ent = this.Entidade    
            this.lista.horari_esc = this.Escola    
            this.lista.horari_ano = this.Exercicio    
        }
    },
    async getTurma (val)
    {
        var URL = '/eschorarios/turma'
        URL += '?horari_codigo='+val
        URL += '&horari_ent='+this.Entidade
        URL += '&horari_esc='+this.Escola
        URL += '&horari_ano='+this.Exercicio
        
        await axios.get(`${URL}`)
            .then(response => {
                this.disciplinas = response.data.data.turma.disciplinas
                var turno = response.data.data.turma.turno
                if ((this.modo == 'novo') /*|| ( this.modo == 'editar' && newVal !== oldVal)*/){
                    this.lista.horariosdiadisp = []
                    var qtdAulas = turno.turn_aulas_diaria
                    var i = 1
                    while (i <= qtdAulas){
                        this.lista.horariosdiadisp.push({
                            horaridiadisp_codigo: 0,
                            horaridia_codigo: null,
                            horaridiadisp_aula: i,
                            disp_codigo: null,
                            disp_nome: null,
                            //horaridiadisp_hora: null,
                            horaridiadisp_duracao: null
                        }) 
                        i++   
                    }

                }

                this.errors = {}                
            })
            .catch(response => {
                this.message = response.response.data.message
                this.errors = response.response.data.errors
                this.status = response.response.status
            })
    },
  },
  watch: {
    'lista.horari_codigo': function (newVal, oldVal){
        if (typeof newVal != 'undefined' && newVal != null){
            this.getTurma(newVal)
        }
    },
  },
  computed: {
      ...mapState('Login',['infoLogado','Entidade', 'Exercicio', 'Escola'])
  }, 
  created() {
  
  }     
}
</script>