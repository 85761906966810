<template>
<!-- RELATORIOS CONTAS A PAGAR - A PAGAR -->
    <div>
        <div class="row mb-4">
            <div class="col-lg-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Agendamento</legend>
                    <div class="row">
                        <div class="col-lg-12">
                            <mct-form-itens tipo="select" campo="agendamento" :valor="filtro" titulo="Agendamento" :errors="{}"
                            origemChave="horari_codigo" origemRetorno='horari_descricao' origemRetornoSec="horari_codigo" origem="esc_horarios" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>        
        <div class="row mb-2">
            <div class="col-sm-12 text-left">
                <button type="button" class="btn btn-secondary mr-2" aria-label="Left Align" @click="onAgendamentos()">
                <span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Visualizar</button>     
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/http'

import moment from 'moment';
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios'

export default {   
    components: {
        'MctFormItens': MctFormItens,
    },
    mixins: [MctPadraoRelatorios],
    data() {
        return {
            data: [],
            outputPdf: null,
            filtro: {
                turma_ini: null,
                turma_fin: null,
                agrupa_agendamento: null,
                situacao_agendamento: 9,
                agendamento: null,
                assinatura: 0,
                etap_codigo: null,
                aluno: null,
                modelo: 1,
                tipo: 0
            }
        }
    },
    methods: {
        async getData() {
            var url = '/agendamentos';

            await axios.get(`/relatorios/siges${url}`,{params:  {
                turmaini: this.filtro.turma_ini,
                turmafin: this.filtro.turma_fin,
                agrupa_agendamento: this.filtro.agrupa_agendamento,
                situacao_agendamento: this.filtro.situacao_agendamento,
                agendamento: this.filtro.agendamento,
                etap_codigo: this.filtro.etap_codigo,
                aluno: this.filtro.aluno,
                tipo: this.filtro.tipo
            },
            paramsSerializer: function (params) {
                return jQuery.param(params)
            }
            })
            .then(response => {
                this.data = response.data.data                    
            })
            .catch(response => {
                console.log(response.message)
            })
            
        },        
        async onAgendamentos () {

            await this.getData();

            await this.onCreatePDF('P')
            /*CABEÇALHO*/
            this.title = '';
            this.onHeaderPDF();
            this.pdf.setFont('helvetica') 
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)     
                    
            var grupo = '';
            var grupo2 = '';
            var grupo3 = '';

            for (var index in this.data) {
                if (grupo !== this.data[index].horari_codigo){
                    if (grupo !== '')
                       this.onSaltarPagina2();
                    grupo = this.data[index].horari_codigo
                    grupo2 = '';
                    grupo3 = '';

                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','normal')
                    
                    //this.pdf.setLineWidth(1);
                    this.pdf.rect(8,this.lin-3,this.widthPage,5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Agendamento:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].horari_descricao),32,this.lin)        
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Início:',80,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.$options.filters.data(this.data[index].horari_data_inicial)),90,this.lin)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Fim:',110,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.$options.filters.data(this.data[index].horari_data_final)),122,this.lin)
                    this.onAddLine(this.jump+2)
                }

                if (grupo2 !== this.data[index].cur_codigo){
                    if (grupo2 !== '')
                        this.onAddLine(this.jump+2)
                    grupo2 = this.data[index].cur_codigo
                    grupo3 = '';
                    this.pdf.rect(8,this.lin-3,this.widthPage,5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Curso:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(this.data[index].cur_nome,22,this.lin)
                    this.onAddLine(this.jump+2)
                }

                if (grupo3 !== this.data[index].ser_codigo){
                    if (grupo3 !== '')
                    this.onAddLine(this.jump+2)
                    grupo3 = this.data[index].ser_codigo

                    this.pdf.rect(8,this.lin-3,this.widthPage,5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Módulo:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(this.data[index].ser_descricao,22,this.lin)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Carga Horária:',84,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(this.data[index].ser_ch+' hrs(s)',104,this.lin)
                    this.onAddLine(this.jump+2)

                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(8,this.lin-3,195,5,'FD')
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.setFontSize(6)
                    this.pdf.text('DATA',30,this.lin,{align: 'right'})
                    this.pdf.text('HORÁRIO',32,this.lin)
                    this.pdf.text('DISCIPLINA',64,this.lin)
                    this.pdf.text('DIA',146,this.lin)
                    this.onAddLine(this.jump+2)
                }

                this.pdf.rect(8,this.lin-3,this.widthPage,5)
                this.pdf.setFont('helvetica','normal')
                this.pdf.setFontSize(6)
                this.pdf.text(String(this.$options.filters.data(this.data[index].horaridia_data)),30,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.horaminstr(this.data[index].turnhor_hora_ini)+' AS '+this.$options.filters.horaminstr(this.data[index].turnhor_hora_fin)),32,this.lin)
                this.pdf.text(this.data[index].disp_nome,64,this.lin)
                this.pdf.text(String(moment(this.data[index].horaridia_data).locale("pt-br").format('dddd')).toUpperCase(),146,this.lin)
                this.onAddLine(this.jump+2)
            }
                                               
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            const urlPDF = this.pdf.output('blob');
            this.outputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
            this.$emit('update:outputPdf', this.outputPdf);
        },         
    }

}
</script>