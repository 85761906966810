<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <mct-alertas :mensagem="message" :errors="errors" :status="status" :modo="modo" v-show="status !== 0"></mct-alertas>
      <div class="row">
        <div class="col-sm-2">
          <mct-form-itens tipo="number" campo="matri_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" visivel="false" modo="readonly"></mct-form-itens>                      
        </div>    
      </div>        
      <div class="view" style="padding-top: 5px; padding-bottom: 5px;">     
        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Turma</legend> 
          <div class="row">
            <div class="col-lg-12">
              <mct-form-itens tipo="search" campo="matri_turm_codigo" :valor="lista" titulo="Turma" :errors="errors" obrigatorio="true" origem="esc_turmas" origemChave="turm_codigo" origemRetorno='turm_completa' @setDataRetorno="setTurma" origemChaveComposta="true" tamanho="2" :modo="modo"></mct-form-itens>
            </div>
          </div>
        </fieldset>
      </div>  
      <div class="view">
        <fieldset class="well">
          <legend class="well-legend">Alunos a serem Matriculados</legend> 
          <div class="row mb-2">
            <div class="col-lg-12">
              <mct-form-itens tipo="select" campo="alu_codigo" :valor="$data" titulo="Selecione o Aluno" origem="esc_pessoas_e_turmas" origemChave="pes_codigo" origemRetorno="pes_nome" origemRetornoSec="pes_codigo" 
                :origemFiltro="{pes_situacao: 1, pes_tipo_cad: 'A'}" @setDataRetorno="setAluno" :errors="errors" :modo="modo"></mct-form-itens>
                
              <button type="button" class="btn btn-success btn-sm mt-1" aria-label="Left Align" @click.prevent.stop="incluirAluno()">
                <span class="fa fa-plus fa-lg text-white" aria-hidden="true"></span> Incluir </button>
            </div>
          </div>
          <div class="mct-grid">
            <div class="mct-grid-scroll-sec">                        
              <table class="table table-striped table-bordered table-sm table-hover">
                <thead>
                  <tr>
                    <th>Ações</th>
                    <th>
                      <span>CÓDIGO</span>
                    </th>
                    <th>
                      <span>NOME ALUNO</span>
                    </th>
                    <th>
                      <span>ÚLTIMA TURMA</span>
                    </th>  
                    <th>
                      <span>SITUAÇÃO ATUAL</span>
                    </th>                              
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(aluno,index) in alunos" :key="index" v-on:dblclick="editAluno(index)"> 
                    <td>
                      <div class="btn-group">
                        <i class="fa fa-pencil text-white mct-fa bg-primary mr-1" aria-hidden="true" @click="editAluno(index)"></i>
                        <i class="fa fa-times text-white mct-fa bg-danger mr-1" aria-hidden="true" @click="excluirAluno(index)"></i>
                      </div>
                    </td>
                    <td> 
                      <div style="width: 80px;">
                        {{aluno.pes_codigo}}
                      </div>
                    </td>
                    <td>
                      <div style="width: 300px;">
                        {{aluno.pes_nome}}
                      </div>
                    </td>
                    <td>{{aluno.turm_identificacao}}</td>
                    <td>{{aluno.pes_situacao}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </fieldset>
      </div>
       <fieldset class="well">
        <legend class="well-legend">Funcionário Responsável pela Matrícula</legend> 
        <div class="row">
          <div class="col-lg-12">
            <mct-form-itens tipo="search" tamanho="2" campo="matri_resp_codigo" :valor="lista" titulo="Código" :errors="errors" obrigatorio="true" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' @setDataRetorno="setFuncionario" modo="readonly" ></mct-form-itens>
          </div>
        </div>
      </fieldset>
      <div class="mt-2 btn-group" role="group" aria-label="Ações">
          <button type="button" class="btn btn-success text-white" aria-label="Left Align"  @click.prevent.stop="gravar()">
              <i class="fa fa-check fa-lg text-white" aria-hidden="true"></i> Gravar </button>
          <button type="button" class="btn btn-dark" aria-label="Left Align" @click.prevent.stop="$router.back()">
              <i class="fa fa-chevron-left fa-lg text-white" aria-hidden="true"></i> Fechar </button>
      </div>      
    </div> 
  </mct-padrao>      
</template>

<script>

import axios from '@/http'
import { mapState } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadraoSB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctAlertas from "@/components/comum/MctAlertas.vue"

export default {
  Name: 'Cursos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      MctAlertas
  },
  data () {
      return {
        lista:{},
        errors:{},
        message: '',
        tipoAlerta: '',
        msgAlerta: '',
        titAlerta: '',
        turma:{'curso_codigo':'','curso_descricao':'','serie_codigo':'','serie_descricao':'','turno_codigo':'','turno_descricao':'','turma_descricao':''},
        alu_codigo: null,
        alunos:[],
        alunoSelecionado:{},
        status: 0,
      }
  },
  methods: {
    
    setData (lista, errors, lastCode) {
      this.lista = lista
      this.errors = errors 
      this.lista.matri_resp_codigo = this.infoLogado.pes_codigo  
      if(this.modo =='novo')
      {
        this.lista.matri_semestre = 'ANUAL'
        this.lista.matri_tipo = 'F'
        this.lista.matri_tipo_matricula = 'I'
      }
    },
    setTurma(data){
      if(data.cursoturma)
      {
        this.turma.curso_codigo = data.cursoturma.cur_codigo
        this.turma.curso_descricao = data.cursoturma.cur_nome
        this.turma.serie_codigo = data.cursoturma.ser_codigo
        this.turma.serie_descricao = data.cursoturma.ser_descricao
        this.turma.turno_codigo = data.turnoturma.turn_codigo
        this.turma.turno_descricao = data.turnoturma.turn_descricao
        //this.turma.turma_descricao = 'Curso: '+data.cursoturma.cur_nome+' - '+data.turnoturma.turn_descricao+' - '+data.cursoturma.ser_descricao
      }
    },
    setAluno(data){
      this.alunoSelecionado = data;
    },
    incluirAluno() {
      this.alunos.push( {...this.alunoSelecionado, status: 'N'} ) 
    },
    excluirAluno(index) {
      this.alu_codigo = this.alunos[index].pes_codigo  
      this.alunos.splice(index,1)
    },
    editAluno(index) {
      console.log(index)
      this.alu_codigo = this.alunos[index].pes_codigo  
      this.alunos.splice(index,1)  
    },    
    setFuncionario(data){
      this.lista.pessoa = data
    },

    validaInputs () {
      this.errors = new Object()
      if (this.lista.matri_turm_codigo == null || this.lista.matri_turm_codigo == ''){
        this.errors.matri_turm_codigo = []
        this.errors.matri_turm_codigo.push("O campo TURMA não pode ser nulo ou vazio.") 
      }
      if (!this.alunos){
        this.errors.alunos = []
        this.errors.alunos.push("Insira ao menos 1 (um) Aluno.") 
      }
      if (Object.keys(this.errors).length > 0) {
        this.message = "Houve um ou mais erros."
        this.status = 422
        return false
      }else{
        this.message = ""
        this.status = 0
        return true
      }        
    },
    async gravar(){
      if(this.validaInputs()){
        
        
        var dados = this.lista
        dados.alunos = this.alunos
        await axios.post('/escmatriculacoletiva/salvar',dados)
          .then(response => {
          this.message = response.data.message
          this.status = response.status
          this.errors = {}

          // limpa os dados
          this.lista.alunos = [];
          this.lista.matri_turm_codigo = null;
           
          this.$swal('Sucesso!',
                      'Registro incluído com sucesso!',
                      'success'); 
        })
        .catch(response => {
          this.message = response.response.data.message
          if (response.response.data.error){
            this.errors = response.response.data.error.errorInfo[2]  
          }
          this.status = response.response.status
          
        })
      }
      
    }
  },
   computed: {
    ...mapState('Login',['infoLogado','Exercicio', 'Escola', 'Entidade','infoLogado']), 
  },  
  created() {
  
  }     
}
</script>
