<template>
    <div>    
        <div class="card-titulo d-flex justify-content-between align-items-center">
            <button type="button" class="btn btn-sm" aria-label="Left Align"  @click.prevent.stop="$router.go(-1)">
                <span class="fa fa-chevron-left fa-lg" aria-hidden="true"></span> Voltar </button>
            <h1>NOTAS E FALTAS</h1>
            <i></i>
        </div>

        <div class="cards card-horario">
            <div>
                <h2>{{matricula.turma.turm_identificacao}}</h2>
                <h1 v-if="infoEscola.esc_tipo === 1">{{matricula.turma.serie.ser_descricao}}</h1>
                <h1>{{matricula.turma.curso.cur_nome}}</h1>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column">
                        <small>Matrícula</small>
                        <span>{{matricula.matri_matricula}}</span>
                    </div>
                    <template v-if="infoEscola.esc_tipo !== 1">
                        <div class="d-flex flex-column ml-3 mr-3">
                            <small>Início</small>
                            <span>{{serie.agendamento.horari_data_inicial | data}}</span>
                        </div>
                        <div class="d-flex flex-column">
                            <small>Fim</small>
                            <span>{{serie.agendamento.horari_data_final | data}}</span>
                        </div>
                    </template>
                </div>
                <h1 v-if="infoEscola.esc_tipo !== 1">
                    <span class="badge badge-primary" v-if="serie.agendamento.horari_situacao === 1"><i class="fa fa-clock-o" aria-hidden="true"></i> em andamento</span>
                    <span class="badge badge-danger" v-else-if="serie.agendamento.horari_situacao === 0"><i class="fa fa-ban" aria-hidden="true"></i> cancelado</span>
                    <span class="badge badge-info" v-else-if="serie.agendamento.horari_situacao === 2"><i class="fa fa-hourglass-start" aria-hidden="true"></i> aguardando</span>
                    <span class="badge badge-success p-1" v-else-if="serie.agendamento.horari_situacao === 3"><i class="fa fa-check" aria-hidden="true"></i> finalizado</span>                            
                </h1>
            </div>
            <div class="d-flex flex-row justify-content-between" v-if="infoEscola.esc_tipo === 1">
                <div class="d-flex flex-column">
                    <small>Etapa</small>
                    <b-form-select v-model="etapa" :options="etapas"></b-form-select>
                </div>
            </div>
        </div>

        <ul class="nav nav-tabs mt-2" id="myTab" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" id="disciplina-tab" data-toggle="tab" href="#disciplina" role="tab" aria-controls="disciplina" aria-selected="true">Por Disciplina</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="data-tab" data-toggle="tab" href="#data" role="tab" aria-controls="data" aria-selected="false">Por Data</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="disciplina" role="tabpanel" aria-labelledby="disciplina-tab">
                <div class="row grid-titulo">
                    <div class="col-5">
                        <span>Disciplina</span>
                    </div>
                    <div class="col-1 text-center">
                        <span>Ava. 1</span>
                    </div>
                    <div class="col-1 text-center">
                        <span>Ava. 2</span>
                    </div>
                    <div class="col-1 text-center">
                        <span>Ava. 3</span>
                    </div>
                    <div class="col-1 text-center">
                        <span>Ava. 4</span>
                    </div>
                    <div class="col-1 text-center">
                        <span>Qtd. Aulas</span>
                    </div>
                    <div class="col-1 text-center">
                        <span>Faltas</span>
                    </div>
                    <div class="col-1 text-center">
                        <span>Média</span>
                    </div>
                </div>

                <div class="row grid-dados" v-for="(item, idxd) in porDisciplina" :key="idxd">
                    <div class="row col-12 p-0 m-0">
                        <div class="col-5 d-flex flex-column justify-content-start align-items-start">
                            <span>
                                <span class="badge badge-primary">{{item.disp_disciplina}}</span>
                                {{item.disp_nome}}
                            </span> 
                            <small>
                                {{item.pes_nome}}
                            </small>
                        </div>
                        <div class="col-1 text-center">
                            {{item.ava1}}
                        </div>
                        <div class="col-1 text-center">
                            {{item.ava2}}
                        </div>
                        <div class="col-1 text-center">
                            {{item.ava3}}
                        </div>
                        <div class="col-1 text-center">
                            {{item.ava4}}
                        </div>
                        <div class="col-1 text-center">
                            {{item.aula_total}}
                        </div>
                        <div class="col-1 text-center">
                            {{Number(item.aula_total) - Number(item.presencas)}}
                        </div>
                        <div class="col-1 text-center">
                            {{item.media}}
                        </div>
                    </div>                    
                </div>                
            </div>
                
            <div class="tab-pane fade" id="data" role="tabpanel" aria-labelledby="data-tab">
                <div class="row grid-titulo">
                    <div class="col-2">
                        <span>Data</span>
                    </div>
                    <div class="col-6">
                        <span>Disciplina</span>
                    </div>
                    <div class="col-1 text-center">
                        <span>Aula</span>
                    </div>
                    <div class="col-2">
                        <span>Duração</span>
                    </div>
                    <div class="col-1">
                        <span>Situação</span>
                    </div>
                </div>

                <div class="row grid-dados" v-for="(item, idxd) in porData" :key="idxd">
                    <div class="row col-12 p-0 m-0">
                        <div class="col-2">
                            {{item.horaridia_data | data}}
                        </div>
                        <div class="col-6 d-flex flex-column justify-content-start align-items-start">
                            <span>
                                <span class="badge badge-primary">{{item.disp_disciplina}}</span>
                                {{item.disp_nome}}
                            </span> 
                            <small>
                                {{item.pes_nome}}
                            </small>
                        </div>
                        <div class="col-1 text-center">
                            {{item.turnhor_aula}}
                        </div>
                        <div class="col-2">
                            {{item.turnhor_hora_ini | horaminstr}} às {{item.turnhor_hora_fin | horaminstr}}
                        </div>
                        <div class="col-1">
                            <span class="badge badge-success p-1" v-if="item.situacao === 1"><i class="fa fa-hand-paper-o" aria-hidden="true"></i> PRESENTE</span>                            
                            <span class="badge badge-danger" v-else><i class="fa fa-thumbs-o-down" aria-hidden="true"></i> AUSENTE</span>
                        </div>                
                    </div>    

                    <div class="card-avaliacao row col-12 p-1 px-2 m-0 d-flex flex-row justify-content-start align-items-center" v-if="item.notas.length > 0"> 
                        <div v-for="(nota, idxn) in item.notas" :key="idxn" class="d-flex flex-column">
                            <span>AVALIAÇÃO {{nota.notalu_nota_numero}}</span>
                            <small>Nota</small>
                            <h1>
                                {{nota.notalu_nota}}
                            </h1>
                            <span>
                                {{
                                    nota.notalu_tipo === 0 ? 'AVALIAÇÃO NORMAL' : 
                                    nota.notalu_tipo === 1 ? 'AVALIAÇÃO RECUPERAÇÃO' : 
                                    nota.notalu_tipo === 2 ? 'AVALIAÇÃO PROVÃO/EXTRA' : 
                                    nota.notalu_tipo === 3 ? 'AVALIAÇÃO SIMULADA' : 
                                    nota.notalu_tipo === 4 ? 'AVALIAÇÃO FEIRA DE CIÊNCIAS' : ''
                                }}
                            </span>
                        </div>    
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/http'
import { mapState } from 'vuex'

export default {
    Name: 'PortalNotasFaltas',
    components: {

    },
    data () {
        return {
            porData: [],
            porDisciplina: [],
            etapa: 41,
            etapas: [
                {value: 41, text: '1º BIMESTRE'},
                {value: 42, text: '2º BIMESTRE'},
                {value: 43, text: '3º BIMESTRE'},
                {value: 44, text: '4º BIMESTRE'},
            ]
        }
    },
    methods: {
        async getFrequencias () {
            await axios.get('/frequencia',
            {
                params: {
                    entidade: this.$route.params.entidade,
                    escola: this.$route.params.escola,
                    anoletivo: this.$route.params.ano_letivo,
                    turma: this.$route.params.turma,
                    horario: this.$route.params.horario,
                    etapa: this.etapa
                }
            })
            .then(response => {
                this.porData = response.data.data;
                this.porDisciplina = response.data.disciplina;
            
            })
        }

    },
    mounted () {
        this.getFrequencias();    
    },
    computed: {
        ...mapState('Portal',['matriculas','infoEscola']),
        matricula () {
            return this.matriculas.filter(item => item.turma.turm_codigo == this.$route.params.turma)[0];
        },
        serie () {
            const matricula = this.matriculas.filter(item => item.turma.turm_codigo == this.$route.params.turma); 
            return matricula[0].series.filter(item => item.matri_horari_codigo == this.$route.params.horario)[0];
        },

    },
    watch: {
        etapa (){
            this.getFrequencias();
        },
    }
}
</script>
