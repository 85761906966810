<template>
    <div class="mct-modulos d-flex justify-content-center align-items-center">
        <div class="row">
            <div class="col-3 col" @click="open('/notas')">
                <i alt="Início" class="fa fa-clipboard fa-2x icon-portal"></i>
                <h1>Notas e Faltas</h1>
            </div>
            <div class="col-3 col" @click="open('/boletim')">
                <i alt="Início" class="fa fa-newspaper-o fa-2x icon-portal"></i>
                <h1>Boletim</h1>
            </div>
            <div class="col-3 col" @click="open('/horario')">
                <i alt="Início" class="fa fa-clock-o fa-2x icon-portal"></i>
                <h1>Horário</h1>
            </div>
            <div class="col-3 col" @click="open('/financeiro')">
                <i alt="Início" class="fa fa-dollar fa-2x icon-portal"></i>
                <h1>
                    Financeiro
                </h1>
            </div>
            <div class="col-3 col" @click="open('/notas')">
                <i alt="Início" class="fa fa-book fa-2x icon-portal"></i>
                <h1>
                    Acompanhamento Pedagógico
                </h1>
            </div>
            <div class="col-3 col" @click="open('/notas')">
                <i alt="Início" class="fa fa-bell fa-2x icon-portal"></i>
                <h1>
                    Avisos
                </h1>
            </div>
            <div class="col-3 col" @click="open('/notas')">
                <i alt="Início" class="fa fa-calendar fa-2x icon-portal"></i>
                <h1>
                    Calendário de Provas
                </h1>
            </div>
            <div class="col-3 col" @click="open('/dadospessoais/novo')">
                <i alt="Início" class="fa fa-id-card fa-2x icon-portal"></i>
                <h1>
                    Dados Pessoais
                </h1>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    Name: 'MenuPortal',
    methods: {
        open(url) {
            this.$router.push(url);
        }
    },
}

</script>

<style scoped>
    @import url('https://fonts.googleapis.com/css?family=PT+Sans');

    /*.mct-modulos * {
        font-family: 'PT Sans', Helvetica, Arial, Verdana, sans-serif;
    }*/

    h1 {
        color: #FFFFFF;
        margin-top: 20px;
    }

    .icon-portal {
        font-size: 75px;
        margin-top: 30px;
        color: #FFFFFF;

    }

    .mct-modulos {
        text-align: center;
        cursor: pointer;
        margin-top: 20px;
    }

    .mct-modulos h1 {
        color: #ffffff;
        font-size: 1.2em;
        font-weight: bold;
    }

    .mct-modulos p {
        width: 100%;
        color: #ffffff;
        font-size: 0.7em;
        font-weight: normal;
        position: absolute;
        bottom: 0;
    }

    .mct-modulos img {
        max-height: 100px;
    }

    .mct-modulos .col {
        min-width: 200px;
        max-width: 200px;
        height: 200px;
        background-color: #009A90;
        /*margin: 5px;*/
        padding: 2px;
        border-bottom: 3px solid #F9A21E;
        border-left: 1px solid #ffffff;
        border-right: 1px solid #ffffff;
        overflow: hidden;

        position: relative;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1);
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1);
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1);
        transition: all 200ms ease-in;
        transform: scale(1);
    }

    .mct-modulos .col:hover {
        box-shadow: 1px 1px 1px #FFFFFF;
        z-index: 9999;
        -webkit-transition: all 200ms ease-in;
        -webkit-transform: scale(1.2);
        -ms-transition: all 200ms ease-in;
        -ms-transform: scale(1.2);
        -moz-transition: all 200ms ease-in;
        -moz-transform: scale(1.2);
        transition: all 200ms ease-in;
        transform: scale(1.2);
    }

    .row {
        margin: 0 auto;
        text-align: center;
    }

</style>
