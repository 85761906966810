<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#cadastro" role="tab">Conta Bancária</a>
                </li>
                <li class="nav-item" v-if="lista.cbnc_boleto == 1">
                    <a class="nav-link" data-toggle="tab" href="#boleto" role="tab">Config. Boleto</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#financeiro" role="tab">Vinc. Finan./Contábil</a>
                </li>
            </ul>
            <!-- TABS -->
            <div class="tab-content">
                <div class="tab-pane active" id="cadastro" role="tabpanel">                
                    <div class="row">
                        <div class="col-sm-2">
                            <mct-form-itens coluna="0" tipo="number" campo="cbnc_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                        </div>    
                        <div class="col-lg-2">
                            <mct-form-itens tipo="checkbox" campo="cbnc_status" :valor="lista" :titulo="lista.cbnc_status == 1 ? 'Cadastro Ativo.' : 'Cadastro Inativado.'" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-lg-2">
                            <mct-form-itens tipo="checkbox" campo="cbnc_boleto" :valor="lista" titulo="Emite Boleto" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <!--<mct-form-itens tipo="select" campo="age_codigo" :valor="lista" titulo="Agência Bancária" obrigatorio="true" origem="agencia_bancaria" origemChave="age_codigo" origemRetorno='age_codigo' origemRetornoSec="age_descricao" @setDataLista="setAgencia" :errors="errors" :modo="modo"></mct-form-itens>-->
                            <mct-form-itens tipo="text" campo="age_codigo" :valor="lista" titulo="Agência Bancária" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-sm-4">
                            <mct-form-itens tipo="select" campo="bnc_codigo" :valor="lista" titulo="Banco" obrigatorio="true" origem="banco" origemChave="bnc_codigo" origemRetorno='bnc_descricao' origemRetornoSec="bnc_febraban" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <mct-form-itens tipo="text" campo="cbnc_numero" :valor="lista" titulo="Número" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-sm-4">
                            <mct-form-itens tipo="text" campo="cbnc_descricao" :valor="lista" titulo="Descrição" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-2">
                            <mct-form-itens tipo="date" campo="cbnc_abertura" :valor="lista" titulo="Data Abertura" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-sm-2">
                            <mct-form-itens tipo="date" campo="cbnc_encerramento" :valor="lista" titulo="Data Encerramento" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="boleto" role="tabpanel">
                    <fieldset class="well">
                        <legend class="well-legend">Config. Boleto Bancário</legend>    
                        <div class="row">
                            <div class="col-sm-2">
                                <mct-form-itens coluna="0" tipo="text" campo="cbnc_bol_convenio" :valor="lista" titulo="Convênio" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>                      
                            </div>    
                            <div class="col-sm-2">
                                <mct-form-itens tipo="number" campo="cbnc_bol_carteira" :valor="lista" titulo="Carteira" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>                      
                            </div>    
                            <div class="col-lg-1">
                                <mct-form-itens tipo="number" campo="cbnc_bol_tpmoeda" :valor="lista" titulo="Moeda" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                            <div class="col-lg-2">
                                <mct-form-itens tipo="number" campo="cbnc_bol_numposto" :valor="lista" titulo="N.º Posto" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-2">
                                <mct-form-itens coluna="0" tipo="number" campo="cbnc_bol_seqremessa" :valor="lista" titulo="Sequência Remessa" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>                      
                            </div>    
                            <div class="col-sm-2">
                                <mct-form-itens coluna="0" tipo="number" campo="cbnc_bol_nossonumini" :valor="lista" titulo="Nosso Nº Inicial" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>                      
                            </div>    
                            <div class="col-sm-2">
                                <mct-form-itens tipo="number" campo="cbnc_bol_nossonumfin" :valor="lista" titulo="Nosso Nº Final" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>                      
                            </div>    
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <mct-form-itens coluna="0" tipo="number" campo="cbnc_bol_cedente" :valor="lista" titulo="Descrição Cedente" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>                      
                            </div>    
                            <div class="col-sm-3">
                                <mct-form-itens tipo="text" campo="cbnc_bol_cedentecpf" :valor="lista" titulo="CPF/CNPJ Cedente" obrigatorio="false" mascara="cpf_cnpj" :errors="errors" :modo="modo"></mct-form-itens>                      
                            </div>    
                        </div>
                        <div class="row">
                            <div class="col-sm-4">
                                <mct-form-itens tipo="number" campo="cbnc_bol_multa" :valor="lista" titulo="Multa (%)" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>                      
                            </div>    
                            <div class="col-sm-3">
                                <mct-form-itens tipo="number" campo="cbnc_bol_juros" :valor="lista" titulo="Juros (%)" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>                      
                            </div>    
                            <div class="col-sm-3">
                                <mct-form-itens tipo="select" campo="cbnc_bol_tp_juros" :valor="lista" titulo="Tipo de Juros" obrigatorio="false" :origemData="[{value: 1, text: 'DIÁRIO'}, {value: 2, text: 'MENSAL'}]" :errors="errors" :modo="modo"></mct-form-itens>                      
                            </div>    
                        </div>
                    </fieldset>
                </div>                
                <div class="tab-pane" id="financeiro" role="tabpanel">
                    <fieldset class="well">
                        <legend class="well-legend">Plano de Contas</legend> 
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-8 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plnc_codigo" :valor="lista" titulo="Plano de Contas" 
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>                        
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-8 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plnc_jur_codigo" :valor="lista" titulo="Plano de Contas (Juros)" 
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>                        
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-8 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plnc_mul_codigo" :valor="lista" titulo="Plano de Contas (Multa)" 
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>                        
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-8 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plnc_desc_codigo" :valor="lista" titulo="Plano de Contas (Desconto)" 
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>                        
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-8 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plnc_tar_codigo" :valor="lista" titulo="Plano de Contas (Tarifas)" 
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>                        
                    </fieldset>
                </div>
            </div>
        </div>              
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'ContaBancaria',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
        agenciaLista: {}
      }
  },
  methods: {
    setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            this.lista.cbnc_status = 1 
            this.lista.cbnc_ent = this.Entidade   
        }
    },
    setAgencia (data) {
        //console.log(data)
        this.agenciaLista = data
    }
  },
  computed: {
        ...mapState('Login',['Entidade'])       
  }, 
  created() {
  
  },
  watch: {
    /*'lista.age_codigo' () {
        if (typeof this.agenciaLista.filter !== 'undefined'){
            var ret = this.agenciaLista.filter(el => {
                return el.age_codigo == this.lista.age_codigo
            })
            if (this.modo === 'novo' || this.modo === 'editar'){
                this.lista.bnc_codigo = ret[0].bnc_codigo
            }
        }         
    }*/
  }     
}
</script>