<template>
 <!-- RELATORIOS CONTAS A PAGAR - PAGAS -->
    <div>
        <div class="row mb-2">
            <div class="col-lg-12">
                <mct-form-itens tipo="select" campo="ccusto" :valor="filtro" titulo="Centro de Custos"  origem="esc_centro_custo" origemChave="ccst_codigo" origemRetorno='ccst_descricao' origemRetornoSec='ccst_codigo' :errors="{}" modo="editar" @setDataRetorno="setCCusto"></mct-form-itens>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-lg-4">
                <fieldset class="well h-100">
                    <legend class="well-legend">Data</legend>
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <mct-form-itens tipo="date" campo="data_ini" :valor="filtro" titulo="Inicial" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <mct-form-itens tipo="date" campo="data_fin" :valor="filtro" titulo="Final" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-lg-4">
                <fieldset class="well h-100">
                    <legend class="well-legend">Tipo Data</legend>
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <mct-form-itens tipo="radio" campo="tipodata" :valor="filtro" titulo="Tipo de Data" showTitulo="false" :errors="{}" 
                            :origemData="[{value:'E',text:'Emissão'}, {value:'V',text:'Vencimento'},{value:'P',text:'Pagamento'}]" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-lg-4">
                <fieldset class="well h-100">
                    <legend class="well-legend">Tipo Relatório</legend>
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <mct-form-itens tipo="radio" campo="tipoconta" :valor="filtro" titulo="Tipo de Rel." showTitulo="false" :errors="{}" 
                            :origemData="[{value:'Q',text:'Total'}, {value:'P',text:'Parcial'},{value:'TR',text:'Todas'}]" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>                            
        </div>
        <div class="row mb-4">
            <div class="col-8">
                <div class="row">
                    <div class="col-lg-12 mb-2">
                        <fieldset class="well h-100">
                            <legend class="well-legend">Fornecedor/ Pessoa</legend>
                            <div class="row">                                                        
                                <div class="col-lg-12">
                                    <div class="row">
                                    <div class="col-4">
                                            <mct-form-itens tipo="number" campo="pessoa_ini" :valor="filtro" titulo="Inicial" :errors="{}" modo="editar"></mct-form-itens>
                                    </div>
                                    <div class="col-8">
                                            <mct-form-itens tipo="select" campo="pessoa_ini" :valor="filtro" titulo=" " origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                    </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                    <div class="col-4">
                                            <mct-form-itens tipo="number" campo="pessoa_fin" :valor="filtro" titulo="Final" :errors="{}" modo="editar"></mct-form-itens>
                                    </div>
                                    <div class="col-8">
                                            <mct-form-itens tipo="select" campo="pessoa_fin" :valor="filtro" titulo=" " origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div class="col-lg-12">
                        <fieldset class="well h-100">
                            <legend class="well-legend">Conta Bancária</legend>
                            <div class="row">                                                        
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-4">
                                            <mct-form-itens tipo="number" campo="conta_ini" :valor="filtro" titulo="Inicial" :errors="{}" modo="editar"></mct-form-itens>
                                        </div>
                                        <div class="col-8">
                                            <mct-form-itens tipo="select" campo="conta_ini" :valor="filtro" titulo="Inicial" origem="esc_conta_bancaria" origemChave="cbnc_codigo" origemRetorno='cbnc_descricao' origemRetornoSec='cbnc_numero' :errors="{}" modo="editar"></mct-form-itens>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-4">
                                            <mct-form-itens tipo="number" campo="conta_fin" :valor="filtro" titulo="Final" :errors="{}" modo="editar"></mct-form-itens>
                                        </div>
                                        <div class="col-8">
                                            <mct-form-itens tipo="select" campo="conta_fin" :valor="filtro" titulo="Final" origem="esc_conta_bancaria" origemChave="cbnc_codigo" origemRetorno='cbnc_descricao' origemRetornoSec='cbnc_numero' :errors="{}" modo="editar"></mct-form-itens>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                </div>
            </div>
            <div class="col-4">
                <div class="row h-100">
                    <div class="col-lg-12">
                        <fieldset class="well h-100">
                            <legend class="well-legend">Ordenar Por</legend>
                            <div class="row">
                                <div class="col-lg-12 mb-2">
                                    <mct-form-itens tipo="radio" campo="tipoordem" :valor="filtro" titulo="Ordenar Por" showTitulo="false" :errors="{}" 
                                    :origemData="[{value:1,text:'Nota Fiscal'}, {value:2,text:'Doc. Número'},{value:3,text:'Fornecedor'},{value:4,text:'Data Pagamento'},{value:5,text:'Data Vencimento'}]" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-12 text-left">
                <button type="button" class="btn btn-secondary mr-2" aria-label="Left Align" @click="onCPagarPagas">
                <span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Visualizar</button>     
            </div>
        </div>
        <b-modal id="relatorio" title="Relatório Financeiro" hide-footer hide-header hide-title size="xl">
            <PDFView :url="localOutputPdf === null ? '' : localOutputPdf" />
        </b-modal>         
    </div>
</template>

<script>
import axios from '@/http';
import moment from 'moment';
import PDFView from "@/components/pdfviewer/PDFView.vue";
import MctFormItens from "@/components/comum/MctFormItens.vue";
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios';

export default {
    props: ['outputPdf'],
    mixins: [MctPadraoRelatorios],
    components: {
        PDFView,
        MctFormItens: MctFormItens
    },
    data() {
        return {
            data: [],
            filtro: {},
            localOutputPdf: {}
        };
    },
    created() {
        this.localOutputPdf = this.outputPdf;
        this.filtro = {
            detalhado: 1,
            tipo: 'P',
            modelo: 1,
            opcoes: {},
            tipoconta: 'TR',
            tipodata: 'E',
            tipoordem: 3,
            tipoagrupar: 1,
            tipovisualizacao: 1,
            data_base: null,
            data_ini: null,
            data_fin: null,
            pessoa_ini: null,
            pessoa_fin: null,
            conta_ini: null,
            conta_fin: null,
            tprecebimento: null,
            tpquitacao: null,
            ccusto: null,
            ccusto_descricao: null,
        }
    },
    methods: {
        async getData() {
            await axios.get('/relatorios/financeiro',{params:  {
                detalhado: this.filtro.detalhado,
                tipoordem: this.filtro.tipoordem,
                tipoagrupar: this.filtro.tipoagrupar,
                pessoaini: this.filtro.pessoa_ini,
                pessoafin: this.filtro.pessoa_fin,
                containi: this.filtro.conta_ini,
                contafin: this.filtro.conta_fin,
                ccusto: this.filtro.ccusto,
                tipo: this.filtro.tipo,
                tipoc: this.filtro.tipoconta,
                database: moment(this.filtro.data_base).isValid() ? moment(this.filtro.data_base).format('YYYY-MM-DD') : null,
                emiini: moment(this.filtro.data_ini).isValid() && this.filtro.tipodata === 'E' ? moment(this.filtro.data_ini).format('YYYY-MM-DD') : null,
                emifin: moment(this.filtro.data_fin).isValid() && this.filtro.tipodata === 'E' ? moment(this.filtro.data_fin).format('YYYY-MM-DD') : null,
                vencini: moment(this.filtro.data_ini).isValid() && this.filtro.tipodata === 'V' ? moment(this.filtro.data_ini).format('YYYY-MM-DD') : null,
                vencfin: moment(this.filtro.data_fin).isValid()  && this.filtro.tipodata === 'V'? moment(this.filtro.data_fin).format('YYYY-MM-DD') : null,
                quitini: moment(this.filtro.data_ini).isValid() && this.filtro.tipodata === 'P' ? moment(this.filtro.data_ini).format('YYYY-MM-DD') : null,
                quitfin: moment(this.filtro.data_fin).isValid() && this.filtro.tipodata === 'P' ? moment(this.filtro.data_fin).format('YYYY-MM-DD') : null              
            },
            paramsSerializer: function (params) {
                return jQuery.param(params)
            }
            })
            .then(response => {
                this.data = response.data.data                    
            })
            .catch(response => {
                console.log(response.message)
            })            
        },
        setCCusto (data) {
            this.filtro.ccusto_descricao = data.ccst_descricao;
        },       
        async onCPagarPagas () {

            await this.getData();

            this.onCreatePDF()
            /*CABEÇALHO*/
            this.title = 'RELATÓRIO DE CONTAS A PAGAR';
            if (this.filtro.tipoconta === 'TR'){
                this.title += ' - QUITADAS PARCIAL E TOTAL';
            } else if (this.filtro.tipoconta === 'Q'){
                this.title += ' - QUITADAS TOTAL';
            } else if (this.filtro.tipoconta === 'P'){
                this.title += ' - QUITADAS PARCIAL';
            }
            this.onHeaderPDF();
            this.pdf.setFont('courier') 
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)     
                    
            if(this.filtro.ccusto){
                this.pdf.text(`CENTRO DE CUSTO: ${this.filtro.ccusto} - ${this.filtro.ccusto_descricao}`,8,this.lin)
                this.onAddLine(this.jump)
            }
            if(moment(this.filtro.data_ini).isValid()){
                this.pdf.text(`PERÍODO DE ${this.filtro.tipodata === 'E' ? 'EMISSÃO' : this.filtro.tipodata === 'V' ? 'VENCIMENTO' : this.filtro.tipodata === 'P' ? 'PAGAMENTO' : '' } ENTRE ${moment(this.filtro.data_ini).format('DD/MM/YYYY')} A ${moment(this.filtro.data_fin).format('DD/MM/YYYY')}`,8,this.lin)
                this.onAddLine(this.jump)
            }
            if(this.filtro.pessoa_ini){
                this.pdf.text(`FORNECEDOR ${this.filtro.pessoa_ini} ATÉ ${this.filtro.pessoa_fin}`,8,this.lin)
                this.onAddLine(this.jump)
            }
            if(this.filtro.conta_ini){
                this.pdf.text(`CONTA BANCÁRIA ${this.filtro.conta_ini} ATÉ ${this.filtro.conta_fin}`,8,this.lin)
                this.onAddLine(this.jump)
            }
            this.pdf.text(`ORDENADO POR ${this.filtro.tipoordem === 1 ? 'NOTA FISCAL' : this.filtro.tipoordem === 2 ? 'DOC. NÚMERO' : this.filtro.tipoordem === 3 ? 'FORNECEDOR' : this.filtro.tipoordem === 4 ? 'DATA PAGAMENTO' : this.filtro.tipoordem === 5 ? 'DATA VENCIMENTO' : ''}`,8,this.lin)
            this.onAddLine(this.jump)

            this.onAddLine(this.jump)
            this.pdf.setFillColor(192,192,192)
            this.pdf.rect(8,this.lin-3,195,this.jump+1,'FD')
            this.pdf.setFont('helvetica','bold') 
            this.pdf.text('DT.EMIS.',25,this.lin,{align: 'right'})
            this.pdf.text('NOTA FISCAL',45,this.lin,{align: 'right'})
            this.pdf.text('VALOR',65,this.lin,{align: 'right'})
            this.pdf.text('VLR PAGO',85,this.lin,{align: 'right'})
            this.pdf.text('VLR ABERTO',105,this.lin,{align: 'right'})
            this.pdf.text('DT. VENC.',125,this.lin,{align: 'right'})
            this.pdf.text('DT. PGTO',145,this.lin,{align: 'right'})
            this.pdf.text('COND. PGTO',146,this.lin)
            this.pdf.text('DOC. N°.',187,this.lin)
            this.onAddLine(this.jump+1)

            var somaValor = 0
            var somaValorPago = 0
            var somaValorAPagar = 0
            var somaValorForn = 0
            var somaValorPagoForn = 0
            var somaValorAPagarForn = 0

            var grupo = 0

            for (var index in this.data) {
                this.pdf.setFont('courier') 
                /*NOME PESSOA*/
                if (grupo !== this.data[index].pes_codigo){
                    grupo = this.data[index].pes_codigo
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(8,this.lin-3,195,this.jump+1,'FD')
                    this.pdf.setFont('courier') 
                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','bold') 
                    this.pdf.text('FORNECEDOR:',30,this.lin,{align: 'right'})
                    this.pdf.text(this.data[index].pes_nome+' - '+this.data[index].pes_codigo,32,this.lin)        
                    this.onAddLine(this.jump+1)
                }

                this.pdf.setFont('helvetica','normal')
                this.pdf.setFontSize(7.5)
                this.pdf.text(String(this.$options.filters.data(this.data[index].doc_emissao)) !== 'null' ? String(this.$options.filters.data(this.data[index].doc_emissao)) : '',25,this.lin,{align: 'right'})
                this.pdf.text(this.data[index].docp_notafiscal !== null ? String(this.data[index].docp_notafiscal) : '',45,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.moeda(this.data[index].docp_valor)),65,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.moeda(this.data[index].docp_vlr_pago)),85,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.moeda(this.data[index].docp_vlr_apagar)),105,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.data(this.data[index].docp_vencimento)) !== 'null' ? String(this.$options.filters.data(this.data[index].docp_vencimento)) : '',125,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.data(this.data[index].docp_pagamento)) !== 'null' ? String(this.$options.filters.data(this.data[index].docp_pagamento)) : '',145,this.lin,{align: 'right'})
                this.pdf.text(String(this.data[index].cpg_nome),146,this.lin)
                this.pdf.text(this.data[index].docp_numero,187,this.lin)
                somaValor += Number(this.data[index].docp_valor)
                somaValorPago += Number(this.data[index].docp_vlr_pago)
                somaValorAPagar += Number(this.data[index].docp_vlr_apagar)
                somaValorForn += Number(this.data[index].docp_valor)
                somaValorPagoForn += Number(this.data[index].docp_vlr_pago)
                somaValorAPagarForn += Number(this.data[index].docp_vlr_apagar)
                this.onAddRegLine()
                this.onAddLine(this.jump)

                if (parseInt(index)+1 == this.data.length || grupo !== this.data[parseInt(index)+1 < this.data.length ? parseInt(index)+1 : index].pes_codigo){
                    this.onAddLine(1)
                    this.pdf.setFont('courier') 
                    this.pdf.setFontSize(7.5)
                    this.pdf.line(8,this.lin-this.jump,203,this.lin-this.jump)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('TOTAL DO FORNECEDOR:',45,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaValorForn)),65,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaValorPagoForn)),85,this.lin,{align: 'right'})
                    this.pdf.text(String(this.$options.filters.moeda(somaValorAPagarForn)),105,this.lin,{align: 'right'})
                    this.onAddLine(this.jump+1)
                    somaValorPagoForn = 0;
                    somaValorAPagarForn = 0;
                }
            }
            
            /** TOTAL FINAL */
            this.onAddLine(1)
            this.pdf.setFont('courier') 
            this.pdf.setFontSize(7.5)
            this.pdf.line(8,this.lin-this.jump,203,this.lin-this.jump)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('TOTAL GERAL:',45,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaValor)),65,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaValorPago)),85,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaValorAPagar)),105,this.lin,{align: 'right'})
            this.onAddLine(this.jump+1)
            somaValorPago = 0;
            somaValorAPagar = 0;
                                   
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            const urlPDF = this.pdf.output('blob');
            this.localOutputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
            this.$bvModal.show("relatorio");

        },
    },
    watch: {
        localOutputPdf(newValue, oldValue) {
            //this.$emit("update:outputPdf", newValue);
        }
    },
}
</script>