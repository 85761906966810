<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <div class="row">
        <div class="col-sm-2" style="padding-left: 20px;">
          <mct-form-itens tipo="number" campo="profturm_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
        </div>    
      </div>
      <fieldset class="well" style="padding-top: 0px;margin-top: 5px;padding-bottom: 5px;margin-bottom: 0px;">
        <legend class="well-legend">Matriz Curricular</legend>
        <div class="row">
          <div class="col-lg-10">
            <mct-form-itens tipo="search" tamanho="2" campo="curr_codigo" :valor="lista" titulo="Código" obrigatorio="true" :errors="errors" origem="esc_curriculo" origemChave="curr_codigo" origemRetorno='curr_completo' origemChaveComposta="true" :modo="modo=='novo'?modo:'visualizar'" @setDataRetorno="setCurriculo"></mct-form-itens>
          </div>
        </div>
      </fieldset>
      <fieldset class="well" style="padding-top: 0px;margin-top: 5px;padding-bottom: 5px;margin-bottom: 0px;">
        <legend class="well-legend">Turma</legend>
        <div class="row">
          <div class="col-lg-10">
            <mct-form-itens tipo="select" campo="profturm_turm_codigo" :valor="lista" titulo="Turma" obrigatorio="true" :errors="errors" :origemData="turmasLista" origemChave="turm_codigo" origemRetorno='turm_completa' :modo="modo=='novo'?modo:'visualizar'"></mct-form-itens>
          </div>
        </div>
      </fieldset>
      <fieldset class="well">    
        <legend class="well-legend">Disciplinas / Professor</legend>  
        <div class="mct-grid">
            <div class="mct-grid-scroll-sec" style="min-height: 300px;">
              <table class="table table-striped table-bordered table-sm table-hover" style="width: 99%;">
                <thead>
                  <tr>
                    <th>Ação</th>
                    <th>
                      <span>DISCIPLINA</span>
                    </th>
                    <th>
                      <span>NOME DISCIPLINA</span>
                    </th>
                    <th>
                      <span>CÓD./NOME PROFESSOR</span>
                    </th>
                    <th>
                      <span>CÓD./NOME PROF. AUXILIAR</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(disciplina,index) in lista.disciplinas" :key="index" :class="[{editing: disciplina == linhaEditada}]" v-cloak v-on:dblclick="editDisciplina(disciplina,index)"> 
                    <td>
                      <i class="fa fa-pencil text-white mct-fa bg-primary mr-1" aria-hidden="true" @click="editDisciplina(disciplina,index)"></i>
                    </td>
                    <td> 
                        {{disciplina.disp_disciplina}}
                    </td>
                    <td>
                        {{disciplina.disp_nome}}
                    </td>
                    <td>
                      <div class="view" style="width:250px;">
                        {{disciplina.pes_nome !== null ? disciplina.pes_nome +' ['+ disciplina.profdis_prof_codigo +']' : ''}}
                      </div>
                      <div class="edit row">
                        <div class="col-lg-12">
                          <mct-form-itens tipo="select2" campo="profdis_prof_codigo" :valor="disciplina" origem="esc_pessoas" origemChave="pes_codigo" origemRetorno="pes_nome" origemRetornoSec="pes_codigo" 
                            :origemFiltro="{pes_situacao: 1, pes_tipo_cad: 'P'}"  obrigatorio="true" showBotoes="false" @setDataRetorno="setProfessor" :errors="errors" :modo="modo"></mct-form-itens>
                            
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="view" style="width:150px;">
                        {{disciplina.aux_nome !== null ? disciplina.aux_nome +' ['+ disciplina.profdis_aux_codigo +']' : ''}}
                      </div>
                      <div class="edit row">
                        <div class="col-lg-12">
                          <mct-form-itens tipo="select" campo="profdis_aux_codigo" :valor="disciplina" origemAPI="/escprofessores/combo" origemChave="pes_codigo" origemRetorno="pes_nome" origemRetornoSec="pes_codigo" 
                            :origemFiltro="{pes_situacao: 1}"  obrigatorio="true" showBotoes="false" @setDataRetorno="setAuxiliar" :errors="errors" :modo="modo"></mct-form-itens>
                            
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
        </div>
      </fieldset>
    </div>              
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctModal from '@/components/comum/MctModalSHB.vue'

export default {
  Name: 'ProfessorTurma',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      MctModal,
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
        curriculoLista: [],
        turmasLista: [],
        professorLista: [],
        auxiliarLista: [],
        linhaEditada: null,
      }
  },
  methods: {
    setData (lista, errors) {
      this.lista = lista
      this.errors = errors
    },
    setCurriculo(data){
      this.curriculoLista = data
      if (this.curriculoLista.curr_codigo){
        this.turmasLista = this.curriculoLista.turmas
        if (this.modo === 'novo'){
          this.lista.disciplinas = this.curriculoLista.disciplinas
          this.lista.disciplinas.forEach((element,index) => {
            this.lista.disciplinas[index].profturm_codigo = null
            this.lista.disciplinas[index].profturm_ent = null
            this.lista.disciplinas[index].profturm_esc = null
            this.lista.disciplinas[index].profturm_ano = null
            this.lista.disciplinas[index].profdis_disp_codigo = this.lista.disciplinas[index].disp_codigo
            this.lista.disciplinas[index].profdis_prof_codigo = null
            this.lista.disciplinas[index].profdis_aux_codigo = null
            this.lista.disciplinas[index].pes_nome = null
            this.lista.disciplinas[index].status = 'N'
          })
        }
      } else {
        this.turmasLista = []
        if (this.modo === 'novo'){
          this.lista.disciplinas = []
        }
      }      
    },    
    setProfessor(data,linhaEditada){
      this.professorLista = data
      let index = this.linhaEditada ? this.linhaEditada.id : -1
      if (typeof data != 'undefined' && this.linhaEditada != null){
        if (data == 404 || data == '404'){
          this.lista.disciplinas[index].profdis_prof_codigo = ''
          this.lista.disciplinas[index].pes_nome = ''
        }else{                
          this.lista.disciplinas[index].profdis_prof_codigo = this.professorLista.pes_codigo
          this.lista.disciplinas[index].pes_nome = this.professorLista.pes_nome
        }
      }
    },
    setAuxiliar(data,linhaEditada){
      this.auxiliarLista = data
      let index = this.linhaEditada ? this.linhaEditada.id : -1
      if (typeof data != 'undefined' && this.linhaEditada != null){
        if (data == 404 || data == '404'){
          this.lista.disciplinas[index].profdis_aux_codigo = ''
          this.lista.disciplinas[index].aux_nome = ''
        }else{                
          this.lista.disciplinas[index].profdis_aux_codigo = this.auxiliarLista.pes_codigo
          this.lista.disciplinas[index].aux_nome = this.auxiliarLista.pes_nome
        }
      }
    },
    editDisciplina(linha,id) {
      if (this.linhaEditada === linha){
        this.linhaEditada = null    
      }else{
        this.linhaEditada = linha
        this.linhaEditada['id'] = id 
      }
    },
  },
   computed: {
    ...mapState('Login',['infoLogado']), 
  }
}
</script>

<style scoped>
    [v-cloak] {
        display: none;
    }
    .edit {
        display: none;
    }
    .editing .edit {
        display: block
    }
    .editing .view {
        display: none;
    }  
      
    .is-icon{
        max-width: 65px;
    }

    .is-excluido{
        color: #C82333;
        background-color: #e39199!important; 
        text-decoration: line-through;
    }    
</style>