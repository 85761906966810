<template>
  <!-- RELATORIOS CONTAS A RECEBER - A RECEBER -->
  <div>
    <div class="row mb-2">
      <div class="col-lg-8">
        <mct-form-itens
          tipo="select"
          campo="ccusto"
          :valor="filtro"
          titulo="Centro de Custos"
          origem="esc_centro_custo"
          origemChave="ccst_codigo"
          origemRetorno="ccst_descricao"
          origemRetornoSec="ccst_codigo"
          :errors="{}"
          modo="editar"
        ></mct-form-itens>
      </div>
      <div class="col-lg-4">
        <mct-form-itens
          tipo="select"
          campo="ano_letivo"
          :valor="filtro"
          titulo="Ano Letivo"
          :errors="{}"
          modo="editar"
        ></mct-form-itens>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-4">
        <fieldset class="well h-100">
          <legend class="well-legend">Data</legend>
          <div class="row">
            <div class="col-lg-12 mb-2">
              <mct-form-itens
                tipo="date"
                campo="data_ini"
                :valor="filtro"
                titulo="Inicial"
                showTitulo="false"
                :errors="{}"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <mct-form-itens
                tipo="date"
                campo="data_fin"
                :valor="filtro"
                titulo="Final"
                showTitulo="false"
                :errors="{}"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-lg-4">
        <fieldset class="well h-100">
          <legend class="well-legend">Tipo Data</legend>
          <div class="row">
            <div class="col-lg-12 mb-2">
              <mct-form-itens
                tipo="radio"
                campo="tipodata"
                :valor="filtro"
                titulo="Tipo de Data"
                showTitulo="false"
                :errors="{}"
                :origemData="[
                  { value: 'E', text: 'Emissão' },
                  { value: 'V', text: 'Vencimento' },
                  { value: 'P', text: 'Pagamento' },
                  { value: 'DC', text: 'Data do Crédito' }
                ]"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-lg-4">
        <fieldset class="well h-100">
          <legend class="well-legend">Agrupar Por</legend>
          <div class="row">
            <div class="col-lg-12 mb-2">
              <mct-form-itens
                tipo="radio"
                campo="tipoagrupar"
                :valor="filtro"
                titulo="Tipo de Rel."
                showTitulo="false"
                :errors="{}"
                :origemData="[
                  { value: 1, text: 'Cliente' },
                  { value: 2, text: 'Apenas Cartão' },
                  { value: 3, text: 'Cartão / Clientes' }
                ]"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-12">
        <div class="row">
          <div class="col-lg-12 mb-2">
            <fieldset class="well h-100">
              <legend class="well-legend">Cliente/ Aluno</legend>
              <div class="row">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-4">
                      <mct-form-itens
                        tipo="number"
                        campo="pessoa_ini"
                        :valor="filtro"
                        titulo="Inicial"
                        :errors="{}"
                        modo="editar"
                      ></mct-form-itens>
                    </div>
                    <div class="col-8">
                      <mct-form-itens
                        tipo="select"
                        campo="pessoa_ini"
                        :valor="filtro"
                        titulo=" "
                        origem="esc_pessoas"
                        origemChave="pes_codigo"
                        origemRetorno="pes_nome"
                        origemRetornoSec="pes_codigo"
                        :errors="{}"
                        modo="editar"
                      ></mct-form-itens>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-4">
                      <mct-form-itens
                        tipo="number"
                        campo="pessoa_fin"
                        :valor="filtro"
                        titulo="Final"
                        :errors="{}"
                        modo="editar"
                      ></mct-form-itens>
                    </div>
                    <div class="col-8">
                      <mct-form-itens
                        tipo="select"
                        campo="pessoa_fin"
                        :valor="filtro"
                        titulo=" "
                        origem="esc_pessoas"
                        origemChave="pes_codigo"
                        origemRetorno="pes_nome"
                        origemRetornoSec="pes_codigo"
                        :errors="{}"
                        modo="editar"
                      ></mct-form-itens>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-8">
        <mct-form-itens
          tipo="select"
          campo="tprecebimento"
          :valor="filtro"
          titulo="Tipo Recebimento"
          :errors="{}"
          modo="editar"
          origem="esc_centro_custo"
          origemChave="ccst_codigo"
          origemRetorno="ccst_descricao"
          origemRetornoSec="ccst_codigo"
        ></mct-form-itens>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12 text-left">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          aria-label="Left Align"
          @click="onRecebidosCartao"
        >
          <span class="fa fa-refresh fa-lg" aria-hidden="true"></span>
          Visualizar
        </button>
      </div>
    </div>
    <b-modal id="relatorio" title="Relatório Financeiro" hide-footer hide-header hide-title size="xl">
      <PDFView :url="localOutputPdf === null ? '' : localOutputPdf" />
    </b-modal>    
  </div>
</template>

<script>
import axios from '@/http';
import moment from 'moment';
import PDFView from "@/components/pdfviewer/PDFView.vue";
import MctFormItens from "@/components/comum/MctFormItens.vue";
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios';

export default {
  props: ['outputPdf'],
  mixins: [MctPadraoRelatorios],
  components: {
    PDFView,
    MctFormItens: MctFormItens
  },
  data() {
    return {
      data: [],
      filtro: {},
      localOutputPdf: {}
    };
  },
  created() {
    this.localOutputPdf = this.outputPdf;
    this.filtro = {
      detalhado: 1,
      tipo: 'CA',
      modelo: 1,
      opcoes: {},
      tipoconta: 'T',
      tipodata: 'E',
      tipoordem: 3,
      tipoagrupar: 1,
      tipovisualizacao: 1,
      data_base: null,
      data_ini: null,
      data_fin: null,
      pessoa_ini: null,
      pessoa_fin: null,
      conta_ini: null,
      conta_fin: null,
      tprecebimento: null,
      tpquitacao: null,
      ccusto: null
    }
  },
  methods: {
    async getData() {
      await axios.get('/relatorios/financeiro',{params:  {
          detalhado: this.filtro.detalhado,
          tipoordem: this.filtro.tipoordem,
          tipoagrupar: this.filtro.tipoagrupar,
          pessoaini: this.filtro.pessoa_ini,
          pessoafin: this.filtro.pessoa_fin,
          containi: this.filtro.conta_ini,
          contafin: this.filtro.conta_fin,
          ccusto: this.filtro.ccusto,
          tipo: this.filtro.tipo,
          tipoc: this.filtro.tipoconta,
          database: moment(this.filtro.data_base).isValid() ? moment(this.filtro.data_base).format('YYYY-MM-DD') : null,
          emiini: moment(this.filtro.data_ini).isValid() && this.filtro.tipodata === 'E' ? moment(this.filtro.data_ini).format('YYYY-MM-DD') : null,
          emifin: moment(this.filtro.data_fin).isValid() && this.filtro.tipodata === 'E' ? moment(this.filtro.data_fin).format('YYYY-MM-DD') : null,
          vencini: moment(this.filtro.data_ini).isValid() && this.filtro.tipodata === 'V' ? moment(this.filtro.data_ini).format('YYYY-MM-DD') : null,
          vencfin: moment(this.filtro.data_fin).isValid()  && this.filtro.tipodata === 'V'? moment(this.filtro.data_fin).format('YYYY-MM-DD') : null,
          quitini: moment(this.filtro.data_ini).isValid() && this.filtro.tipodata === 'P' ? moment(this.filtro.data_ini).format('YYYY-MM-DD') : null,
          quitfin: moment(this.filtro.data_fin).isValid() && this.filtro.tipodata === 'P' ? moment(this.filtro.data_fin).format('YYYY-MM-DD') : null              
      },
      paramsSerializer: function (params) {
          return jQuery.param(params)
      }
      })
      .then(response => {
          this.data = response.data.data                    
      })
      .catch(response => {
          console.log(response.message)
      })            
    },        
    async onRecebidosCartao () {

      await this.getData();

      await this.onCreatePDF()
      /*CABEÇALHO*/
      this.title = 'RECEBIDOS COM CARTÃO DÉBITO / CRÉDITO';
      this.onHeaderPDF();
      this.pdf.setFont('courier') 
      this.pdf.setFont('helvetica','normal') 
      this.pdf.setFontSize(7.5)     
              
      if(this.filtro.ccusto){
          this.pdf.text(`CENTRO DE CUSTO: ${this.filtro.ccusto}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if(moment(this.filtro.data_ini).isValid()){
          this.pdf.text(`PERÍODO DE ${this.filtro.tipodata === 'E' ? 'EMISSÃO' : this.filtro.tipodata === 'V' ? 'VENCIMENTO' : this.filtro.tipodata === 'P' ? 'PAGAMENTO' : '' } ENTRE ${moment(this.filtro.data_ini).format('DD/MM/YYYY')} A ${moment(this.filtro.data_fin).format('DD/MM/YYYY')}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if(this.filtro.pessoa_ini){
          this.pdf.text(`CLIENTE ${this.filtro.pessoa_ini} ATÉ ${this.filtro.pessoa_fin}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if(this.filtro.cheque_ini){
          this.pdf.text(`Nº CHEQUE ${this.filtro.cheque_ini} ATÉ ${this.filtro.cheque_fin}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if(this.filtro.tprecebimento){
          this.pdf.text(`TIPO DE RECEBIMENTO: ${this.filtro.tprecebimento}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      this.onAddLine(this.jump)

      this.onAddLine(this.jump)
      this.pdf.setFillColor(192,192,192)
      this.pdf.rect(8,this.lin-3,this.widthPage,this.jump+1,'FD')
      this.pdf.setFont('helvetica','bold') 
      this.pdf.setFontSize(7)

      this.pdf.text('DT.EMI.',28,this.lin,{align: 'right'})
      this.pdf.text('DT.VENC',46,this.lin,{align: 'right'})
      this.pdf.text('DOCUMENTO',66,this.lin,{align: 'right'})
      this.pdf.text('TP. RECEBIMENTO',68,this.lin)
      this.pdf.text('VALOR DOC.',127,this.lin,{align: 'right'})
      this.pdf.text('JUR+MUL',145,this.lin,{align: 'right'})
      this.pdf.text('VLR APLIC.',163,this.lin,{align: 'right'})
      this.pdf.text('VLR PARC.',181,this.lin,{align: 'right'})
      this.pdf.text('DT CRÉDITO',199,this.lin,{align: 'right'})
      this.onAddLine(this.jump+1)

      
      var somaAplic = 0
      var somaCartao = 0

      var somaAplicCli = 0
      var somaCartaoCli = 0

      var grupo = null

      for (var index in this.data) {
          this.pdf.setFont('courier') 
          //AGRUPADO PELO NOME DA PESSOA
          if (grupo !== this.data[index].pes_codigo){
              grupo = this.data[index].pes_codigo
              this.pdf.setFontSize(7)
              this.pdf.setFont('helvetica','normal') 
              this.pdf.text(this.data[index].pes_nome.substring(0,27)+' - '+this.data[index].pes_codigo,10,this.lin)
              this.onAddLine(this.jump+1)
          }

          this.pdf.setFontSize(7)
          this.pdf.setFont('helvetica','normal')
          this.pdf.text(String(this.$options.filters.data(this.data[index].doc_emissao)) !== 'null' ? String(this.$options.filters.data(this.data[index].doc_emissao)) : '',28,this.lin,{align: 'right'})
          this.pdf.text(String(this.$options.filters.data(this.data[index].docp_vencimento)) !== 'null' ? String(this.$options.filters.data(this.data[index].docp_vencimento)) : '',46,this.lin,{align: 'right'})
          this.pdf.text(String(this.data[index].docp_numero),66,this.lin,{align: 'right'})
          this.pdf.text(String(this.data[index].trec_descricao),68,this.lin)
          this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].docp_valor))),127,this.lin,{align: 'right'})
          this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].docp_vlr_juros) + Number(this.data[index].docp_vlr_multa))),145,this.lin,{align: 'right'})
          this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].docp_vlr_pago))),163,this.lin,{align: 'right'})
          this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].docp_vlr_pago))),181,this.lin,{align: 'right'})
          this.pdf.text(String(this.$options.filters.data(this.data[index].lanc_data_credito)) !== 'null' ? String(this.$options.filters.data(this.data[index].lanc_data_credito)) : '',199,this.lin,{align: 'right'})

          somaAplic += Number(this.data[index].docp_vlr_pago)
          somaCartao += Number(this.data[index].docp_vlr_pago)
          
          somaAplicCli += Number(this.data[index].docp_vlr_pago)
          somaCartaoCli += Number(this.data[index].docp_vlr_pago)

          this.onAddRegLine()
          this.onAddLine(this.jump)

          if (parseInt(index)+1 == this.data.length || grupo !== this.data[parseInt(index)+1 < this.data.length ? parseInt(index)+1 : index].pes_codigo){
              this.onAddLine(1)
              this.pdf.setFont('courier') 
              this.pdf.setFontSize(7)
              this.pdf.setFont('helvetica','bold')
              this.pdf.text(String(`TOTAL DO CLIENTE (${this.data[index].pes_codigo}):`),127,this.lin,{align: 'right'})
              this.pdf.text(String(this.$options.filters.moeda(somaAplicCli)),163,this.lin,{align: 'right'})
              this.pdf.text(String(this.$options.filters.moeda(somaCartaoCli)),181,this.lin,{align: 'right'})
              this.onAddLine(this.jump+1)
              this.pdf.line(8,this.lin-this.jump,(this.widthPage+8),this.lin-this.jump)
              somaAplicCli = 0
              somaCartaoCli = 0
          }
      }
      
      // TOTAL FINAL
      this.onAddLine(1)
      this.pdf.setFont('courier') 
      this.pdf.setFontSize(7)
      this.pdf.setFont('helvetica','bold')
      this.pdf.text('TOTAL GERAL:',127,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(somaAplic)),163,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(somaCartao)),181,this.lin,{align: 'right'})
      this.onAddLine(this.jump+1)
      this.pdf.line(8,this.lin-this.jump,(this.widthPage+8),this.lin-this.jump)
      somaAplic = 0
      somaCartao = 0
      
                              
      this.onFooterPDF()
      this.onInsertNumberPagesPDF()

      const uuidv4 = this.$uuid.v4();
      const urlPDF = this.pdf.output('blob');
      this.localOutputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
      this.$bvModal.show("relatorio");
    },
  },  
  watch: {
    localOutputPdf(newValue, oldValue) {
      //this.$emit("update:outputPdf", newValue);
    }
  }
};
</script>
