<template>
  <div class="mct-form">
    <mct-topo-lista :titulo="titulo" :subtitulo="`Listagem de ${titulo}`" v-show="!showModal"></mct-topo-lista>    

    <div class="clearfix">
      <div style="float: left;" class="form-inline">
        <div style="padding: 4px 0px;" v-show="!showModal">
            <button type="button" class="btn btn-success" aria-label="Left Align" @click="incluirNovo($event)">
              <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Novo Registro </button>
        </div>                
            <button type="button" class="btn btn-light" aria-label="Left Align" @click="getData()">
              <span class="fa fa-refresh fa-lg" aria-hidden="true"></span></button>                          
      </div>    
    </div>
    <slot>
        <div class="mct-grid">
            <div class="mct-grid-scroll">         
                <table class="table table-striped table-bordered table-sm table-hover">
                    <thead>
                        <tr>
                            <th :style="showModal ? 'width: 35px!important;' : 'width: 75px!important;'"></th>
                            <th style="width: 50px;" class="text-center">Cód. Hor.</th>
                            <th>Curso</th>
                            <th style="width: 80px;" class="text-right">Data Início</th>
                            <th style="width: 80px;" class="text-right">Data Fim</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row,index) in data.data" :key="index"  @click.prevent.stop="setDescricao($event,row)">
                        <td>
                          <div class="btn-group pt-2">
                            <i class="fa fa-pencil text-white mct-fa bg-primary mr-1" aria-hidden="true" title="Editar Registro" v-show="!showModal" @click="redirect($event,`${$route.fullPath.replace('/lista','')}/${row.turm_codigo}/${row.turm_ent}/${row.turm_ano}/${row.turm_esc}/editar`)"></i>
                            <i class="fa fa-times text-white bg-danger mct-fa mr-1" aria-hidden="true" title="Excluir Registro" v-show="!showModal" @click="redirect($event,`${$route.fullPath.replace('/lista','')}/${row.turm_codigo}/${row.turm_ent}/${row.turm_ano}/${row.turm_esc}/deletar`)"></i>
                            <i class="fa fa-search text-white bg-secondary mct-fa mr-1" aria-hidden="true" title="Visualizar Registro" v-show="!showModal" @click="redirect($event,`${$route.fullPath.replace('/lista','')}/${row.turm_codigo}/${row.turm_ent}/${row.turm_ano}/${row.turm_esc}/visualizar`)"></i>
                            <i class="fa fa-hand-pointer-o text-white bg-info mct-fa mr-1" aria-hidden="true" v-show="showModal" @click.prevent.stop="setDescricao($event,row)"></i>
                          </div>
                        </td>
                        <td><span class="mct-grid-span-02 d-block text-center pt-2">{{row.turm_codigo}}</span></td>
                        <td>
                          <div class="row">
                            <div class="col-6">
                              <h1 class="mct-grid-h1">{{row.cur_codigo +' - '+ row.cur_nome}}</h1>
                              <span class="mct-grid-span-01 d-block text-primary">{{row.ser_descricao +' | '+'CH: '}}{{'15' | horamin}}hs</span>
                              <span class="badge badge-primary"><i class="fa fa-clock-o" aria-hidden="true"></i> em andamento</span>
                            </div>
                            <div class="col-1">
                              <span class="mct-grid-span-00 mct-text-dark-light d-block text-center">ANO</span>
                              <span class="mct-grid-span-02 d-block text-center">{{row.ser_serie}}</span>                              
                            </div>
                            <div class="col-2">
                              <span class="mct-grid-span-00 mct-text-dark-light d-block text-center">TURMA</span>
                              <span class="mct-grid-span-02 d-block text-center">{{row.turm_identificacao}}</span>
                            </div>
                            <div class="col-1">
                              <span class="mct-grid-span-00 mct-text-dark-light d-block text-center">TURNO</span>
                              <span class="mct-grid-span-02 fa-stack text-center">
                                <i class="fa fa-sun-o text-warning" :class="row.turn_tipo == 0 ? 'fa-daytime' : ''" v-if="row.turn_tipo == 0 || row.turn_tipo == 1 || row.turn_tipo == 2"></i>
                                <i class="fa fa-moon-o text-primary" v-if="row.turn_tipo == 0 || row.turn_tipo == 3"></i> 
                                <i class="fa fa-clock-o text-info" v-if="row.turn_tipo == 4"></i> 
                                {{row.turn_abreviatura}}
                              </span>
                            </div>
                            <div class="col-2">
                              <span class="mct-grid-span-00 mct-text-dark-light d-block text-center">ETAPAS</span>
                              <span class="mct-grid-span-02 d-block text-center">{{row.horari_qtddias}}</span>
                            </div>
                            
                          </div>
                        </td>
                        <td class="text-right"><span class="mct-grid-span-02 d-block pt-2"><i class="fa fa-calendar mct-text-dark-light" aria-hidden="true" v-if="row.turm_data_criacao != '' && row.turm_data_criacao != null"></i> {{row.turm_data_criacao | data}}</span></td>
                        <td class="text-right"><span class="mct-grid-span-02 d-block pt-2"><i class="fa fa-calendar mct-text-dark-light" aria-hidden="true" v-if="row.turm_data_fechamento != '' && row.turm_data_fechamento != null"></i> {{row.turm_data_fechamento | data}}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </slot>
    <mct-rodape-lista :data="data" :query="query" @navigate="navigate" @getData="getData"></mct-rodape-lista>        
  </div>
</template>
<script>
  import Vue from 'vue'

  import MctTopoLista from '@/components/comum/MctTopoLista.vue'
  import MctRodapeLista from '@/components/comum/MctRodapeLista.vue'
  import MctGridLista from '@/components/comum/MctGridLista.vue'
  import MctFiltroLista from '@/components/comum/MctFiltroLista.vue'
  import MctFiltroAvancado from '@/components/comum/MctFiltroLista2.vue'
  import MctMixinsList from '@/components/funcoes/MctMixinsList'

  export default {
    name: 'EscAgendamentoListaT',
    mixins: [MctMixinsList],
    components: {
        'MctTopoLista': MctTopoLista,
        'MctRodapeLista': MctRodapeLista,
        'MctGridLista' : MctGridLista,
        'MctFiltroLista': MctFiltroLista,
    },        
    props: ['source', 'titulo', 'showModal'],
    data() {
      return {
        id: '',
      }
    },
    watch: {
        source: function () {
          this.query.column = ''
          this.query.search_column = ''
          this.query.page = 1
          this.query.direction = 'asc'
          this.query.per_page = 10
          this.query.search_operator = 'equal'
          this.query.search_input = ''
          this.query.search_input2 = ''
          this.filters = {}
          this.getData()      
        }
    },    
    methods: {
      navigate (page) {
            this.query.page = page
            this.getData()
      },
      toggleOrder(column) {
        if(column === this.query.column) {
          if(this.query.direction === 'desc') {
            this.query.direction = 'asc'
          } else {
            this.query.direction = 'desc'
          }
        } else {
          this.query.column = column
          this.query.direction = 'asc'
        }
        this.getData()
      },      
      setDescricao (data ){
          this.$emit('setDescricao', data)
      },
      toogleAvancado (data){
        this.showAvancado = data
      },
      redirect (ev, link) {
          ev.preventDefault();
          
          this.$router.push(link)
      },  
    },
  }
</script>

<style scoped>
  .mct-grid-h1{
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 0.8rem; 
  }
  .mct-grid-span-01{
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.6rem; 
  }
  .mct-grid-span-00{
    font-size: 0.6rem;
    font-weight: 400;
  }
  .mct-grid-span-02{
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.8rem;
  }
  .fa-daytime{
    margin-right: -10px;
  }

</style>
