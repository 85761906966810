<template>
    <div>
        <div class="row">
            <div class="col-lg-10">
                <mct-form-itens coluna="0" tipo="search" :campo="`${rels['logra_codigo']}`" :valor="valor" titulo="Logradouro" :obrigatorio="obrigatorio" :errors="errors" origem="logradouro" origemChave="logra_codigo" origemRetorno="logra_descricao" :origemData="logradouro" :modo="modo" tamanho="1"></mct-form-itens>
            </div>
            <div class="col-lg-2">
                <mct-form-itens tipo="text" :campo="`${rels['logra_numero']}`" :valor="valor" titulo="Número" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
            </div>                        
        </div>

        <div class="row">
            <div class="col-lg-5">
                <mct-form-itens coluna="0" tipo="text" campo="logra_bairro" :valor="logradouro" titulo="Bairro" obrigatorio="false" :errors="errors" modo="readonly" tabindex="-1"></mct-form-itens>
            </div>
            <div class="col-lg-5">
                <mct-form-itens tipo="text" campo="logra_cidade" :valor="logradouro" titulo="Cidade" obrigatorio="false" :errors="errors" modo="readonly" tabindex="-1"></mct-form-itens>
            </div>
            <div class="col-lg-2">
                <mct-form-itens tipo="select" campo="logra_estado" :valor="logradouro" titulo="Estado" obrigatorio="false" origem="estado" origemChave="est_uf" origemRetorno='est_uf' :errors="errors" modo="readonly" tabindex="-1"></mct-form-itens>
            </div>
        </div> 

        <div class="row">
            <div class="col-lg-3">
                <mct-form-itens coluna="0" tipo="text" campo="logra_cep" :valor="logradouro" titulo="Cep" obrigatorio="false" :errors="errors" mascara="cep" modo="readonly" tabindex="-1"></mct-form-itens>
            </div>
            <div class="col-lg-9" v-if="rels['logra_complemento'] != ''">
                <mct-form-itens tipo="text" :campo="`${rels['logra_complemento']}`" :valor="valor" titulo="Complemento" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from '@/http'
import MctFormItens from './MctFormItens.vue'


export default {
    name: 'MctLogradouro',
    props: ['modo','rels','valor', 'obrigatorio'],
    components: {
        MctFormItens
    },
    data () {
        return {
            errors: {}, 
            logradouro: {
                logra_bairro: '',
                logra_cidade: '',
                logra_estado: '',
                logra_cep: ''
            }       
        }
    },
    methods: {
        /*setLogradouro (){
            this.$emit('setLogradouro', this.logradouro)
        }*/
    },    
    created() {

    }/*,
    watch: {
        'logradouro.logra_codigo' () {
            console.log('l')
            this.$emit('setLogradouro', this.logradouro, this.rels)
        },
        'logradouro.logra_numero' () {
            console.log('n')
            this.$emit('setLogradouro', this.logradouro, this.rels)
        },
        'logradouro.logra_complemento' () {
            console.log('c')
            this.$emit('setLogradouro', this.logradouro, this.rels)
        }            
    }*/ 
}
</script>
