<template>
    <div class="funil">
        <header>
            <mct-form-itens tipo="select" campo="fnl_codigo" :valor="$data" :errors="{}" 
                origem="crm_funil" origemChave="fnl_codigo" origemRetorno='fnl_descricao' modo="novo" style="width: 300px; margin-right: 13px;" showBotoes=false></mct-form-itens>
            <div class="row m-0">
                <button type="button" class="btn btn-success ml-2 mr-2 p-1" aria-label="Left Align"  @click.prevent.stop="onNovaOportunidade()">
                    <span class="fa fa-plus fa-lg ml-1 mr-1" aria-hidden="true"></span> Nova Opotunidade</button>
                
                <div class="btn-group" v-if="funil.fnl_tipo !== 0">
                    <button type="button" class="btn btn-info ml-2 mr-2 p-1 dropdown-toggle" aria-label="Left Align" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="fa fa-dollar fa-lg ml-1 mr-1" aria-hidden="true"></span> Metas</button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#" @click.prevent.stop="onListaMeta()">Ver Metas</a>
                        <a class="dropdown-item" href="#" @click.prevent.stop="onNovaMeta()">Nova Meta</a>
                    </div>
                </div>                
                <div class="btn-group">
                    <button type="button" class="btn btn-primary ml-2 mr-2 p-1 dropdown-toggle" aria-label="Left Align" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="fa fa-tags fa-lg ml-1 mr-1" aria-hidden="true"></span> Tags</button>
                    <div class="dropdown-menu">
                        <a class="dropdown-item" href="#" @click.prevent.stop="$router.push('/crmtags/lista')">Ver Tags</a>
                        <a class="dropdown-item" href="#" @click.prevent.stop="onNovaTag()">Nova Tag</a>
                    </div>
                </div>                
                <button type="button" class="btn ml-2 p-1 bg-light" aria-label="Left Align" title="Modo Lista" @click.prevent.stop="onNovaOportunidade()">
                    <span class="fa fa-list fa-lg" aria-hidden="true"></span></button>
                <button type="button" class="btn ml-1 p-1 bg-light" aria-label="Left Align" title="Modo Kaban" @click.prevent.stop="onNovaOportunidade()">
                    <span class="fa fa-th-large fa-lg" aria-hidden="true"></span></button>
                <button type="button" class="btn ml-1 p-1 bg-light" aria-label="Left Align" title="Atualizar dados" @click.prevent.stop="setFunil()">
                    <span class="fa fa-refresh fa-lg" aria-hidden="true"></span></button>
            </div>
        </header>
        <main>
            <draggable v-model="funil.grupos" :data-funil-codigo="funil.fnl_codigo" :animation="200" ghost-class="ghost-card" group="grupos" class="funil-grupos" draggable=".grupos" @end="onEndFunil">                                
                <section class="grupos cursor-move"
                v-for="(grupo, idxGrupo) in funil.grupos"
                :key="grupo.fnlgrp_codigo"
                >
                    <header class="grupo-titulo" :style="grupo.fnlgrp_cor ? `border-bottom: 4px solid ${grupo.fnlgrp_cor}` : 'border-bottom: 4px solid var(--blue)'">
                        <h1><mct-label-edit :id="grupo.fnlgrp_codigo" :value.sync="grupo.fnlgrp_descricao" @onSave="onAlterarGrupo" title="Clique para editar o título do grupo."/></h1>
                        <div>
                            <span class="text-primary" title="Quantidade de Oportunidades.">{{grupo.oportunidades.length}}</span>
                            <span class="text-danger"><i class="fa fa-trash-o" @click.prevent.stop="onExcluirGrupo(grupo.fnlgrp_codigo)" title="Excluir Grupo." style="cursor: pointer;"></i></span>
                        </div>
                    </header>
                    <draggable v-model="grupo.oportunidades" :data-grupo-index="idxGrupo" :data-grupo-codigo="grupo.fnlgrp_codigo" :animation="200" ghost-class="ghost-card" group="oportunidades" class="grupo-oportunidades" draggable=".grupo-oportunidade" @end="onEndGrupo">
                        
                        <section class="grupo-oportunidade cursor-move"
                            v-for="(oportunidade,idxOptr) in grupo.oportunidades"
                            :key="oportunidade.oprt_codigo"
                            :task="oportunidade"
                            @dblclick="onEditarOportunidade(idxGrupo,idxOptr)"
                        >
                            <h1><b>#{{oportunidade.oprt_codigo}}</b> {{oportunidade.oprt_titulo}}</h1>
                            <span>{{oportunidade.contato.cont_nome}}</span>
                            <i class="grupo-oportunidade-interesse fa fa-2x" 
                                :class="oportunidade.oprt_nivel_interesse === 0 ? 'fa-thermometer-empty text-primary' : 
                                        oportunidade.oprt_nivel_interesse === 1 ? 'fa-thermometer-quarter text-primary' : 
                                        oportunidade.oprt_nivel_interesse === 2 ? 'fa-thermometer-half text-warning' : 
                                        oportunidade.oprt_nivel_interesse === 3 ? 'fa-thermometer-three-quarters text-danger' : 
                                        oportunidade.oprt_nivel_interesse === 4 ? 'fa-thermometer-full text-danger' : ''" 
                                :title="oportunidade.oprt_nivel_interesse === 0 ? 'Gelo' : 
                                        oportunidade.oprt_nivel_interesse === 1 ? 'Frio' : 
                                        oportunidade.oprt_nivel_interesse === 2 ? 'Morno' : 
                                        oportunidade.oprt_nivel_interesse === 3 ? 'Quente' : 
                                        oportunidade.oprt_nivel_interesse === 4 ? 'Fogo' : ''" 

                                aria-hidden="true"></i>
                            <div class="grupo-oportunidade-tarefas" v-if="oportunidade.tarefas.length > 0">                            
                                <div class="graph">
                                    <svg>
                                        <circle cx="30" cy="30" r="25" />
                                    </svg>
                                    <svg class="cor" :style="`stroke-dashoffset: calc(159.37 - (${Number(159.37/oportunidade.tarefas.length) * Number(tarefasConcluidas(oportunidade.tarefas))}));`">
                                        <circle cx="30" cy="30" r="25" />
                                    </svg>
                                    <span class="counter">{{tarefasConcluidas(oportunidade.tarefas)}}</span><span>/{{oportunidade.tarefas.length}}</span>
                                </div>
                            </div>
                            <ul v-if="oportunidade.tarefas.length > 0">
                                <li v-for="tarefa in oportunidade.tarefas" :key="tarefa.trf_codigo">

                                    <label class="custom-control custom-checkbox">
                                        <input  class="custom-control-input" type="checkbox" name="trf_situacao" v-model="tarefa.trf_situacao"
                                        v-bind:true-value="1"
                                        v-bind:false-value="0"
                                        disabled
                                        />
                                        <!--<input  class="custom-control-input" type="checkbox" name="trf_situacao" v-model="tarefa.trf_situacao"
                                        v-bind:true-value="1"
                                        v-bind:false-value="0"
                                        @change="onAlteraSituacaoTarefa(tarefa.trf_codigo,$event.target.checked ? 1 : 0)"
                                        />-->
                                        <span class="custom-control-indicator"></span>
                                        <span class="custom-control-description ml-4" :style="tarefa.trf_situacao === 2 ? 'text-decoration: line-through;' : ''">{{tarefa.trf_descricao}}</span>
                                    </label>                                    
                                </li>
                            </ul>
                            <div class="grupo-oportunidade-tags">
                                <span v-for="(tag,index) in getTags(oportunidade.oprt_tags)" :key="index">{{tag}}</span>
                            </div>
                            <footer>
                                <div class="d-flex flex-row justify-content-start align-items-center">
                                    <img style="height:28px; width:28px;" class="avatar avatar-user bg-white mr-1" :src="oportunidade.usuario.usu_foto_url" :alt="oportunidade.usuario.usu_nome" :title="oportunidade.usuario.usu_nome"/>
                                    <div v-for="(participante,prtIndex) in oportunidade.participantes" :key="prtIndex">                                    
                                        <img style="height:28px; width:28px;" class="avatar avatar-user bg-white mr-1" :src="participante.usuario.usu_foto_url" :alt="participante.usuario.usu_nome" :title="participante.usuario.usu_nome"/>
                                    </div>
                                </div>
                                <!--<span class="grupo-nova-tarefa"><i v-if="grupo.fnlgrp_tipo != 2 && grupo.fnlgrp_tipo != 3" class="fa fa-plus" title="Adicionar Nova Tarefa" @click="onNovaTarefa(grupo.fnlgrp_codigo, oportunidade.oprt_codigo)"></i></span>-->
                                <span class="grupo-dias"
                                    :class="Number(moment(oportunidade.oprt_expec_termino).locale('pt-br').diff(new Date,'days')+1) >= 0 ? 'text-success' : 'text-danger'">
                                    <i class="fa fa-clock-o"></i> {{moment(oportunidade.oprt_expec_termino).locale('pt-br').diff(new Date,'days')+1}} d
                                </span>
                            </footer>
                        </section>

                    </draggable>
                    <footer class="grupo-rodape">

                    </footer>            
                </section>
            </draggable>
            <section class="grupo-novo">
                <button type="button" class="btn" @click.prevent.stop="onNovoGrupo()"><span class="fa fa-plus fa-lg ml-1 mr-1" aria-hidden="true"></span>  Novo Grupo</button>
            </section>

        </main>
        <b-modal id="nova-tarefa" :title="tarefa.trf_codigo > 0 ? 'Editar Tarefa' : 'Nova Tarefa'" size="lg" footer-class="">
            <div class="p-2">
                <div class="row mb-2">
                    <div class="col-lg-8">
                        <mct-form-itens tipo="text" campo="trf_descricao" :valor="tarefa" titulo="Tarefa" obrigatorio="true" :errors="{}" modo="novo" ></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-8">
                        <mct-form-itens tipo="select" campo="tptrf_codigo" :valor="tarefa" titulo="Tipo" obrigatorio="true" :errors="{}" 
                            origem="crm_tp_tarefas" origemChave="tptrf_codigo" origemRetorno='tptrf_descricao' modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-8">
                        <mct-form-itens tipo="selectmult" campo="participantes" :valor="tarefa" titulo="Usuários Participantes" obrigatorio="true" :errors="{}" modo="novo"
                            :origemAPI="`/crmoportunidades/participantes/combo?oprt_codigo=${tarefa.oprt_codigo}`" origemChave="usu_codigo" origemRetorno='usu_nome'></mct-form-itens>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-lg-2 mr-2">
                        <mct-form-itens tipo="date" campo="trf_data" :valor="tarefa" titulo="Data" :errors="{}" modo="novo" ></mct-form-itens>
                    </div>
                    <div class="col-lg-2 mr-2">
                        <mct-form-itens tipo="text" mascara="hora" campo="trf_inicio" :valor="tarefa" titulo="Hora de Inicio" :errors="{}" modo="novo" ></mct-form-itens>
                    </div>
                    <div class="col-lg-2">
                        <mct-form-itens tipo="text" mascara="hora" campo="trf_fim" :valor="tarefa" titulo="Hora de Término" :errors="{}" modo="novo" ></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-8">
                        <mct-form-itens tipo="textarea" linhas="3" campo="trf_anotacoes" :valor="tarefa" titulo="Anotações" :errors="{}" modo="novo" ></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-8">
                        <mct-form-itens tipo="radio" campo="trf_situacao" :valor="tarefa" titulo="Situação" obrigatorio="true" :errors="{}" 
                            :origemData="[{value: 0, text: 'Aberta'}, {value: 1, text: 'Concluída'},{value: 2, text: 'Cancelada'}]"
                            modo="novo"></mct-form-itens>
                    </div>
                </div>
            </div>
            <div slot="modal-footer">
                <button type="button" class="btn btn-success mr-1 p-2" aria-label="Left Align"  @click.prevent.stop="tarefa.trf_codigo > 0 ? onAlteraTarefa(tarefa) : onIncluirTarefa()">
                        <span class="fa fa-check fa-lg" aria-hidden="true"></span> {{tarefa.trf_codigo > 0 ? 'Salvar' : 'Incluir' }} Tarefa </button>
                <!--<button type="button" class="btn btn-danger mr-1" aria-label="Left Align" @click.prevent.stop="submit($event)" v-if="modo === 'deletar'">
                        <span class="fa fa-trash-o fa-lg" aria-hidden="true"></span> Excluir </button>-->
                <button type="button" class="btn btn-danger mr-1 p-2" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('nova-tarefa')">
                        <span class="fa fa-times fa-lg" aria-hidden="true"></span> Cancelar </button>
            </div>
        </b-modal>

        <b-modal id="nova-oportunidade" title="Nova Oportunidade" size="lg" footer-class="">
            <div class="p-2">
                <div class="row mb-2">
                    <div class="col-lg-8 pr-4">
                        <mct-form-itens tipo="text" campo="oprt_titulo" :valor="oportunidade" titulo="Título" obrigatorio="true" :errors="{}" modo="novo" ></mct-form-itens>
                    </div>
                    <div class="col-lg-4">
                        <mct-form-itens tipo="select" campo="oprt_nivel_interesse" :valor="oportunidade" titulo="Nível de Interesse" obrigatorio="true" :errors="{}" 
                            :origemData="[{value: 0, text: 'Gelo'}, {value: 1, text: 'Frio'}, {value: 2, text: 'Morno'}, {value: 3, text: 'Quente'}, {value: 4, text: 'Fogo'}]" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-6 pr-4">
                        <mct-form-itens tipo="autocomplete" campo="cont_nome" :valor="oportunidade" titulo="Pessoa" obrigatorio="true" :errors="{}" 
                            :origemData="contatos" tamanho="12" modo="novo"
                            placeholder="Digite o Nome do Contato" @setDataRetorno="setContatoSelecionado"></mct-form-itens>
                    </div>
                    <div class="col-lg-3 pr-4">
                        <mct-form-itens tipo="text" mascara="celular" campo="cont_telefone" :valor="oportunidade" titulo="Telefone" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                    <div class="col-lg-3 pr-4">
                        <mct-form-itens tipo="text" mascara="celular" campo="cont_celular" :valor="oportunidade" titulo="Celular" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-4 pr-4">
                        <mct-form-itens tipo="radio" campo="cont_tipo" :valor="oportunidade" titulo="Tipo Pessoa" obrigatorio="true" :errors="{}" 
                            :origemData="[{value: 'F', text: 'Física'}, {value: 'J', text: 'Jurídica'}]" modo="novo"></mct-form-itens>
                    </div>
                    <div class="col-lg-4 pr-4">
                        <mct-form-itens tipo="text" campo="cont_empresa" :valor="oportunidade" titulo="Empresa" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                    <div class="col-lg-4">
                        <mct-form-itens tipo="text" campo="cont_segmento" :valor="oportunidade" titulo="Segmento" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-6 pr-4">
                        <mct-form-itens tipo="text" campo="cont_cargo" :valor="oportunidade" titulo="Cargo" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                    <div class="col-lg-6">
                        <mct-form-itens tipo="email" campo="cont_email" :valor="oportunidade" transforme="camel" titulo="Email" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <mct-logradouro modo="novo" :valor="oportunidade" :rels="{logra_codigo: null, logra_descricao: 'cont_logradouro', logra_bairro: 'cont_bairro', 
                    logra_cidade: 'cont_cidade', logra_estado: 'cont_estado', logra_cep: 'cont_cep', logra_numero: 'cont_logra_numero', logra_complemento: 'cont_logra_complemento'}"></mct-logradouro> 

                <div class="row mb-2 mt-2">
                    <div class="col-lg-3 pr-4">
                        <mct-form-itens tipo="currency" campo="oprt_vlr_estimado" :valor="oportunidade" titulo="Valor Estimado" :errors="{}" modo="novo" ></mct-form-itens>
                    </div>
                    <div class="col-lg-3 pr-4">
                        <mct-form-itens tipo="date" campo="oprt_expec_termino" :valor="oportunidade" titulo="Expectativa Término" :errors="{}" modo="novo" ></mct-form-itens>
                    </div>
                    <div class="col-lg-6">
                        <mct-form-itens tipo="select" campo="fnlgrp_codigo_atual" :valor="oportunidade" titulo="Iniciar na Etapa" obrigatorio="true" :errors="{}" 
                        origem="crm_funil_grupos" origemChave="fnlgrp_codigo" origemRetorno='fnlgrp_descricao' :origemFiltro="{fnl_codigo: funil.fnl_codigo}" modo="novo"></mct-form-itens>
                    </div>

                </div>
                <div class="row mb-2">
                    <div class="col-lg-4 pr-4">
                        <mct-form-itens tipo="select" campo="usu_codigo" :valor="oportunidade" titulo="Usuário Proprietário" obrigatorio="true" :errors="{}" 
                            origem="usuarios" origemChave="usu_codigo" origemRetorno='usu_nome' modo="novo"></mct-form-itens>
                    </div>
                    <div class="col-lg-8">
                        <mct-form-itens tipo="selectmult" campo="participantes" :valor="oportunidade" titulo="Usuários Participantes" obrigatorio="true" :errors="{}" modo="novo"
                            origemAPI="/crmfunil/participantes/combo" origemChave="usu_codigo" origemRetorno='usu_nome'></mct-form-itens>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class="col-lg-6 pr-4">
                        <mct-form-itens tipo="textarea" linhas="2" campo="oprt_observacoes" :valor="oportunidade" titulo="Anotações" :errors="{}" modo="novo" ></mct-form-itens>
                    </div>
                    <div class="col-lg-6">
                        <mct-form-itens tipo="slot" titulo="Tags" :errors="{}" modo="novo" >
                            <multiselect class="form-control" v-model="oprtTags" :options="tags" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" 
                                placeholder="Selecione as Tags" selectedLabel="Selecionar opção" deselectLabel="Pressione Enter para remover" selectLabel="Pressione enter para selecionar"
                                label="tags_tag" track-by="tags_tag">
                            </multiselect>
                        </mct-form-itens>
                    </div>
                </div>
            </div>
            <div slot="modal-footer">
                <button type="button" class="btn btn-success mr-1 p-2" aria-label="Left Align"  @click.prevent.stop="onIncluirOportunidade()">
                        <span class="fa fa-check fa-lg" aria-hidden="true"></span> Incluir Oportunidade </button>
                <button type="button" class="btn btn-danger mr-1 p-2" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('nova-oportunidade')">
                        <span class="fa fa-times fa-lg" aria-hidden="true"></span> Cancelar </button>
            </div>
        </b-modal>

        <b-modal id="nova-meta" title="Configurar Metas" size="lg" footer-class="">
            <div class="p-2">
                <div class="row mb-2">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="radio" campo="mts_tipo" :valor="meta" titulo="Tipo" obrigatorio="true" :errors="{}" 
                            :origemData="[{value: 1, text: 'Valor'}, {value: 2, text: 'Quantidade'}]"
                            modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="radio" campo="mts_periodo" :valor="meta" titulo="Período" obrigatorio="true" :errors="{}" 
                            :origemData="[{value: 1, text: 'Semanal'}, {value: 2, text: 'Quinzenal'}, {value: 3, text: 'Mensal'}, {value: 4, text: 'Bimestral'}, {value: 5, text: 'Semestral'}, {value: 6, text: 'Anual'}]"
                            modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-4">
                        <mct-form-itens tipo="currency" campo="mts_valor" :valor="meta" titulo="Valor" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <mct-form-itens tipo="selectmult" campo="participantes" :valor="meta" titulo="Participantes" obrigatorio="true" :errors="{}" modo="novo"
                        origemAPI="/crmfunil/participantes/combo" origemChave="usu_codigo" origemRetorno='usu_nome'></mct-form-itens>
                    </div>
                </div>
            </div>
            <div slot="modal-footer">
                <button type="button" class="btn btn-success mr-1 p-2" aria-label="Left Align"  @click.prevent.stop="meta.mts_codigo > 0 ? onAlterarMeta() : onIncluirMeta()">
                        <span class="fa fa-check fa-lg" aria-hidden="true"></span> {{meta.mts_codigo > 0 ? 'Alterar' : 'Incluir'}} Meta </button>
                <button type="button" class="btn btn-danger mr-1 p-2" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('nova-meta')">
                        <span class="fa fa-times fa-lg" aria-hidden="true"></span> Cancelar </button>
            </div>
        </b-modal>

        <b-modal id="lista-meta" title="Listagem de Metas" size="lg" footer-class="">
            <div class="mct-grid">
                <div class="mct-grid-scroll-sec">
                    <table class="table table-sm table-striped table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" style="width: 34px;">
                                </th>
                                <th scope="col" style="width: 80px;">Tipo</th>
                                <th scope="col" style="width: 80px;">Período</th>
                                <th scope="col" style="width: 100px" class="text-right">Valor</th>
                                <th scope="col" style="width: 100px;" class="text-right">Valor Realizado</th>
                                <th scope="col" class="text-left">Participantes</th>
                            </tr>
                        </thead>
                        <tbody>                                
                            <tr v-for="(meta,idxMeta) in metas" :key="idxMeta">
                                <th scope="row">
                                    <div class="is-icon">
                                        <i class="fa fa-pencil text-white mct-fa bg-primary p-1" aria-hidden="true" title="Editar Meta" @click="onEditarMeta(meta)"></i>
                                        <i class="fa fa fa-times text-white bg-danger mct-fa p-1" aria-hidden="true" title="Excluir Meta" @click="onExcluirMeta(meta.mts_codigo)"></i>
                                        <!--<i class="fa fa fa-ban text-white bg-danger mct-fa p-1" aria-hidden="true" title="Cancelar Meta" @click="onCancelarMeta(idxMeta)"></i>-->
                                    </div>
                                </th>
                                <td>{{meta.mts_tipo === 1 ? 'Valor' : meta.mts_tipo === 2 ? 'Quantidade' : ''}}</td>
                                <td>{{
                                    meta.mts_periodo === 1 ? 'Semanal' : 
                                    meta.mts_periodo === 2 ? 'Quinzenal' : 
                                    meta.mts_periodo === 3 ? 'Mensal' : 
                                    meta.mts_periodo === 4 ? 'Bimestral' :
                                    meta.mts_periodo === 5 ? 'Semestral' :
                                    meta.mts_periodo === 6 ? 'Anual' : ''}}</td>
                                <td class="text-right">{{ meta.mts_valor | moeda }}</td>
                                <td class="text-right"></td>
                                <td class="text-left">
                                    <div class="d-flex flex-row justify-content-start align-items-center">
                                        <div v-for="(participante,mtsPrtIndex) in meta.participantes" :key="mtsPrtIndex">                                    
                                            <img style="height:28px; width:28px;" class="avatar avatar-user bg-white mr-1" :src="participante.usuario.usu_foto_url" :alt="participante.usuario.usu_nome" :title="participante.usuario.usu_nome"/>
                                        </div>
                                    </div>

                                </td>
                            </tr>    
                        </tbody>
                    </table>
                </div>
            </div>
            <div slot="modal-footer">
                <button type="button" class="btn btn-danger mr-1 p-2" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('lista-meta')">
                        <span class="fa fa-times fa-lg" aria-hidden="true"></span> Fechar </button>
            </div>
        </b-modal>

        <b-modal id="nova-tag" title="Incluir Tags" size="md" footer-class="">
            <div class="p-2">
                <div class="row mb-2">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="select" campo="tags_tipo" :valor="tag" titulo="Tipo" obrigatorio="true" :errors="{}" 
                            :origemData="[{value: 1, text: 'Curso'}, {value: 2, text: 'Mídia'}, {value: 3, text: 'Localidade'}, {value: 4, text: 'Nome'}, {value: 5, text: 'Profissão'}, {value: 6, text: 'Faixa Etária'}, {value: 7, text: 'Classe Social'}, {value: 9, text: 'Outros'}]"
                            modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="text" campo="tags_tag" :valor="tag" titulo="Tag" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-4">
                        <mct-form-itens tipo="colorpicker" campo="tags_cor" :valor="tag" titulo="Cor" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
            </div>
            <div slot="modal-footer">
                <button type="button" class="btn btn-success mr-1 p-2" aria-label="Left Align"  @click.prevent.stop="onIncluirTag()">
                        <span class="fa fa-check fa-lg" aria-hidden="true"></span> Incluir Tag </button>
                <button type="button" class="btn btn-danger mr-1 p-2" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('nova-tag')">
                        <span class="fa fa-times fa-lg" aria-hidden="true"></span> Cancelar </button>
            </div>
        </b-modal>

        <b-modal id="novo-grupo" title="Novo Grupo" size="lg" footer-class="">
            <div class="p-2">
                <div class="row mb-2">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="radio" campo="fnlgrp_tipo" :valor="grupo" titulo="Tipo" obrigatorio="true" :errors="{}" 
                            :origemData="[{value: 1, text: 'Grupo'}, {value: 2, text: 'Ganho'}, {value: 3, text: 'Perda'}]"
                            modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="text" campo="fnlgrp_descricao" transforme="camel" :valor="grupo" titulo="Grupo" obrigatorio="true" :errors="{}" modo="novo" ></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-2 mr-2">
                        <mct-form-itens tipo="number" campo="fnlgrp_sequencia" :valor="grupo" titulo="Sequência" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2"> 
                    <label class="radio-color">
                        <input type="radio" name="color" value="#e83e8c" v-model="grupo.fnlgrp_cor">
                        <div class="layer"></div>
                        <div class="button"><span class="pink" style="background: #e83e8c;"></span></div>
                    </label>
                    <label class="radio-color">
                        <input type="radio" name="color" value="#f86c6b" v-model="grupo.fnlgrp_cor">
                        <div class="layer"></div>
                        <div class="button"><span class="red" style="background: #f86c6b;"></span></div>
                    </label>
                    <label class="radio-color">
                        <input type="radio" name="color" value="#4dbd74" v-model="grupo.fnlgrp_cor">
                        <div class="layer"></div>
                        <div class="button"><span class="green" style="background: #4dbd74;"></span></div>
                    </label>
                    <label class="radio-color">
                        <input type="radio" name="color" value="#17a2b8" v-model="grupo.fnlgrp_cor">
                        <div class="layer"></div>
                        <div class="button"><span class="teal" style="background: #17a2b8;"></span></div>
                    </label>        
                    <label class="radio-color">
                        <input type="radio" name="color" value="#20a8d8" v-model="grupo.fnlgrp_cor">
                        <div class="layer"></div>
                        <div class="button"><span class="blue" style="background: #20a8d8;"></span></div>
                    </label>
                    <label class="radio-color">
                        <input type="radio" name="color" value="#63c2de" v-model="grupo.fnlgrp_cor">
                        <div class="layer"></div>
                        <div class="button"><span class="cyan" style="background: #63c2de;"></span></div>
                    </label>
                    <label class="radio-color">
                        <input type="radio" name="color" value="#f8cb00" v-model="grupo.fnlgrp_cor">
                        <div class="layer"></div>
                        <div class="button"><span class="yellow" style="background: #f8cb00;"></span></div>
                    </label>
                    <label class="radio-color">
                        <input type="radio" name="color" value="#ffc107" v-model="grupo.fnlgrp_cor">
                        <div class="layer"></div>
                        <div class="button"><span class="orange" style="background: #ffc107;"></span></div>
                    </label>
                    <label class="radio-color">
                        <input type="radio" name="color" value="#f0f3f5" v-model="grupo.fnlgrp_cor">
                        <div class="layer"></div>
                        <div class="button"><span class="light" style="background: #f0f3f5;"></span></div>
                    </label>
                    <label class="radio-color">
                        <input type="radio" name="color" value="#c8ced3" v-model="grupo.fnlgrp_cor">
                        <div class="layer"></div>
                        <div class="button"><span class="secondary" style="background: #c8ced3;"></span></div>
                    </label>
                    <label class="radio-color">
                        <input type="radio" name="color" value="#6610f2" v-model="grupo.fnlgrp_cor">
                        <div class="layer"></div>
                        <div class="button"><span class="indigo" style="background: #6610f2;"></span></div>
                    </label>
                    <label class="radio-color">
                        <input type="radio" name="color" value="#6f42c1" v-model="grupo.fnlgrp_cor">
                        <div class="layer"></div>
                        <div class="button"><span class="purple" style="background: #6f42c1;"></span></div>
                    </label>

                </div>
            </div>
            <div slot="modal-footer">
                <button type="button" class="btn btn-success mr-1 p-2" aria-label="Left Align"  @click.prevent.stop="onIncluirGrupo()">
                        <span class="fa fa-check fa-lg" aria-hidden="true"></span> Incluir Grupo </button>
                <button type="button" class="btn btn-danger mr-1 p-2" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('novo-grupo')">
                        <span class="fa fa-times fa-lg" aria-hidden="true"></span> Cancelar </button>
            </div>
        </b-modal>

        <b-modal id="editar-oportunidade" :title="`#${oportunidadeSelecionada.oprt_codigo} - ${oportunidadeSelecionada.oprt_titulo}`" 
            size="lg" footer-class="" hide-footer>
            <div class="p-2">

                <div class="row mb-2 mt-2">
                    <div class="col-lg-12">
                        <h5 class="m-0">{{oportunidadeSelecionada && oportunidadeSelecionada.contato ? oportunidadeSelecionada.contato.cont_nome +' - '+ oportunidadeSelecionada.contato.cont_celular : ''}}</h5>
                        <small class="m-0"><i>{{oportunidadeSelecionada && oportunidadeSelecionada.contato ? oportunidadeSelecionada.contato.cont_email : ''}}</i></small>
                    </div>
                </div>

                <div class="row mb-2 mt-2">
                    <div class="col-lg-3 pr-4">
                        <mct-form-itens tipo="currency" campo="oprt_vlr_estimado" :valor="oportunidadeSelecionada" titulo="Valor Estimado" :errors="{}" :modo="modo"
                        ></mct-form-itens>
                    </div>
                    <div class="col-lg-3 pr-4">
                        <mct-form-itens tipo="date" campo="oprt_expec_termino" :valor="oportunidadeSelecionada" titulo="Expectativa Término" :errors="{}" :modo="modo"
                        ></mct-form-itens>
                    </div>
                    <div class="col-lg-3 pr-4">
                        <mct-form-itens tipo="select" campo="usu_codigo" :valor="oportunidadeSelecionada" titulo="Usuário Proprietário" obrigatorio="true" :errors="{}" 
                            origem="usuarios" origemChave="usu_codigo" origemRetorno="usu_nome" origemRetornoSec="usu_codigo" :modo="modo"></mct-form-itens>
                    </div>
                    <div class="col-lg-3">
                        <mct-form-itens tipo="select" campo="oprt_nivel_interesse" :valor="oportunidadeSelecionada" titulo="Nível de Interesse" obrigatorio="true" :errors="{}" 
                            :origemData="[{value: 0, text: 'Gelo'}, {value: 1, text: 'Frio'}, {value: 2, text: 'Morno'}, {value: 3, text: 'Quente'}, {value: 4, text: 'Fogo'}]" :modo="modo"></mct-form-itens>
                    </div>

                </div>
                <div class="row mb-2">
                    <div class="col-lg-6 pr-4">
                        <mct-form-itens tipo="selectmult" campo="participantes" :valor="oportunidadeSelecionada" titulo="Usuários Participantes" obrigatorio="true" :errors="{}" :modo="modo"
                            origemAPI="/crmfunil/participantes/combo" origemChave="usu_codigo" origemRetorno='usu_nome'></mct-form-itens>
                    </div>
                    <div class="col-lg-6">
                        <mct-form-itens tipo="slot" titulo="Tags" :errors="{}" modo="novo" >
                            <multiselect class="form-control" v-model="oprtTagsSelecionada" :options="tags" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" 
                                placeholder="Selecione as Tags" selectedLabel="Selecionar opção" deselectLabel="Pressione Enter para remover" selectLabel="Pressione enter para selecionar"
                                label="tags_tag" track-by="tags_tag">
                            </multiselect>
                        </mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="textarea" campo="oprt_observacoes" linhas="3" transforme="camel" :valor="oportunidadeSelecionada" titulo="Observações" obrigatorio="false" :errors="{}" :modo="modo"                        
                        ></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2" v-if="modo !== 'readonly'">
                    <button type="button" class="btn btn-sm btn-success mr-1 p-1" aria-label="Left Align"  @click.prevent.stop="onAlterarOportunidade()">
                        <span class="fa fa-check fa-lg" aria-hidden="true"></span> Salvar Alterações </button>
                </div>
                <hr style="color: var(--secondary); height: 1px; background-color:var(--secondary);" class="col-lg-12 mt-3 mb-3"/>
                <div class="row mb-2 oportunidade-mensagens-icons">
                    <a href="#" @click.prevent.stop="onMensagemWhatsApp()" class="mr-2" title="Enviar Mensagem por WhatsApp"><i class="fa fa-whatsapp text-success"></i></a>
                    <a href="#" @click.prevent.stop="onMensagemSMS()" class="mr-2" title="Enviar Mensagem por SMS"><i class="fa fa-envelope-o text-primary"></i></a>
                    <a href="#" @click.prevent.stop="onMensagemEmail()" class="mr-2" title="Enviar Mensagem por Email"><i class="fa fa-envelope text-secondary"></i></a>
                    <!--<a href="#" @click.prevent.stop="" class="mr-2" title="Adicionar Lembrete"><i class="fa fa-bell-o text-danger"></i></a>-->
                </div>                
                <hr style="color: var(--secondary); height: 1px; background-color:var(--secondary);" class="col-lg-12 mt-3 mb-3"/>
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <h5>Tarefas</h5>
                    <button v-if="modo !== 'readonly'" type="button" class="btn btn-sm btn-success p-1" aria-label="Left Align"  @click.prevent.stop="onNovaTarefa(oportunidadeSelecionada.fnlgrp_codigo_atual, oportunidadeSelecionada.oprt_codigo)">
                        <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Nova Tarefa</button>
                </div>
                <div class="progress mt-2 mb-2">
                    <div class="progress-bar bg-success" role="progressbar" 
                    :style="`width: ${oportunidadeSelecionada.tarefas && oportunidadeSelecionada.tarefas.length ? 
                    (oportunidadeSelecionada.tarefas.filter(ele => ele.trf_situacao === 1).length/oportunidadeSelecionada.tarefas.length) * 100 : 0}%`" 
                    :aria-valuenow="oportunidadeSelecionada.tarefas && oportunidadeSelecionada.tarefas.length ? 
                    oportunidadeSelecionada.tarefas.filter(ele => ele.trf_situacao === 1).length : 0" 
                    aria-valuemin="0" 
                    :aria-valuemax="oportunidadeSelecionada.tarefas && oportunidadeSelecionada.tarefas.length ? oportunidadeSelecionada.tarefas.length : 0"></div>
                </div>
                <div class="grupo-oportunidade-tarefas" v-if="oportunidadeSelecionada.tarefas && oportunidadeSelecionada.tarefas.length > 0">
                    <ul v-if="oportunidadeSelecionada.tarefas && oportunidadeSelecionada.tarefas.length > 0">
                        <li v-for="tarefa in oportunidadeSelecionada.tarefas" :key="tarefa.trf_codigo">
                            <div class="d-flex flex-row justify-content-start align-items-center w-100">
                                <a v-if="modo !== 'readonly' && tarefa.trf_situacao !== 2" href="#" @click.prevent.stop="onExcluirTarefa(tarefa.trf_codigo)" class="mr-2" title="Excluir Tarefa"><i class="fa fa-trash-o text-danger"></i></a>
                                <a v-if="modo !== 'readonly' && tarefa.trf_situacao !== 2" href="#" @click.prevent.stop="onEditarTarefa(tarefa)" class="mr-2" title="Editar Tarefa"><i class="fa fa-pencil text-primary"></i></a>
                                <a v-if="modo !== 'readonly' && tarefa.trf_situacao !== 2" href="#" @click.prevent.stop="onAlteraSituacaoTarefa(tarefa.trf_codigo,2)" class="mr-2" title="Cancelar Tarefa"><i class="fa fa-ban text-danger"></i></a>
                                <label class="custom-control custom-checkbox">
                                    <input  class="custom-control-input" type="checkbox" name="trf_situacao" v-model="tarefa.trf_situacao"
                                    v-bind:true-value="1"
                                    v-bind:false-value="0"
                                    @change="onAlteraSituacaoTarefa(tarefa.trf_codigo,$event.target.checked ? 1 : 0)"
                                    :disabled="tarefa.trf_situacao === 2 || 
                                        (oportunidadeSelecionada.usu_codigo !== infoLogado.usu_codigo && (!tarefa.participantes || !tarefa.participantes.filter(item => item.usu_codigo === infoLogado.usu_codigo)[0]))"
                                    />
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description ml-4">{{tarefa.trf_descricao}}</span>
                                </label>
                                <h5 v-if="tarefa.trf_situacao === 2"><span class="badge badge-danger p-1 ml-2"><i class="fa fa-ban" aria-hidden="true"></i> cancelado</span></h5>
                            </div>

                        </li>
                    </ul>
                </div>
                <hr style="color: var(--secondary); height: 1px; background-color:var(--secondary);" class="col-lg-12 mt-3 mb-3"/>
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <h5>Cursos</h5>
                    <button v-if="modo !== 'readonly'" type="button" class="btn btn-sm btn-success p-1" aria-label="Left Align"  @click.prevent.stop="onNovoCurso(oportunidadeSelecionada.oprt_codigo)">
                        <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Novo Curso</button>
                </div>
                <div class="grupo-oportunidade-arquivos">
                    <ul v-if="oportunidadeSelecionada.produtos && oportunidadeSelecionada.produtos.length > 0">
                        <li v-for="produto in oportunidadeSelecionada.produtos" :key="produto.oprtprod_codigo">
                            <div class="d-flex flex-row justify-content-between align-items-center w-100">
                                <div>
                                    <a v-if="modo !== 'readonly'" href="#" @click.prevent.stop="onExcluirCurso(produto.oprtprod_codigo)" class="mr-2" title="Excluir Curso"><i class="fa fa-trash-o text-danger"></i></a>
                                    <span>{{produto.curso.cur_nome}}</span>                                    
                                </div> 
                                <span>R$ {{(produto.oprtprod_valor*produto.oprtprod_qtd) | moeda}}</span>
                            </div>
                            <!--<div class="d-flex flex-row justify-content-end align-items-center">
                                <img style="height:24px; width:24px;" class="avatar avatar-user bg-white" src="https://avatars1.githubusercontent.com/u/575412?s=460&u=f082ee13ae01464c3226062edf6e1689dcfba083&v=4" alt="usuário" :title="arquivo.usuario.usu_nome"/>
                            </div>-->
                        </li>
                    </ul>
                </div>
                <hr style="color: var(--secondary); height: 1px; background-color:var(--secondary);" class="col-lg-12 mt-3 mb-3"/>
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <h5>Anexos</h5>
                </div>
                <div class="grupo-oportunidade-arquivos">
                    <ul v-if="oportunidadeSelecionada.arquivos && oportunidadeSelecionada.arquivos.length > 0">
                        <li v-for="arquivo in oportunidadeSelecionada.arquivos" :key="arquivo.oprtarq_codigo">
                            <div class="d-flex flex-row justify-content-start align-items-center">
                                <a v-if="modo !== 'readonly'" href="#" @click.prevent.stop="onExcluirArquivo(arquivo.oprtarq_codigo)" class="mr-2" title="Excluir Arquivo"><i class="fa fa-trash-o text-danger"></i></a>
                                <span class="">{{arquivo.oprtarq_arquivo}}</span>
                                <small>{{ arquivo.oprtarq_data | datahora }}</small>
                            </div>
                            <div class="d-flex flex-row justify-content-end align-items-center">
                                <img style="height:24px; width:24px;" class="avatar avatar-user bg-white" :src="arquivo.usuario.usu_foto_url" :alt="arquivo.usuario.usu_nome" :title="arquivo.usuario.usu_nome"/>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="container" v-if="modo !== 'readonly'">
                    <div class="large-12 medium-12 small-12 filezone">
                        <input type="file" id="files" ref="files" multiple v-on:change="handleFiles()"/>
                        <p>
                            Solte seus arquivos aqui <br> ou clique para pesquisar
                        </p>
                    </div>

                    <div v-for="(file, key) in files" class="file-listing" :key="key">
                        <img class="preview" v-bind:ref="'preview'+parseInt(key)"/>
                        {{ file.name }}
                        <div class="success-container" v-if="file.id > 0">
                            Success
                        </div>
                        <div class="remove-container" v-else>
                            <a class="remove" v-on:click="removeFile(key)">Remover</a>
                        </div>
                    </div>

                    <a class="submit-button" v-on:click="submitFiles()" v-show="files.length > 0">Enviar</a>
                </div>
                <hr style="color: var(--secondary); height: 1px; background-color:var(--secondary);" class="col-lg-12 mt-3 mb-3"/>
                <div class="d-flex flex-row justify-content-between align-items-center">
                    <h5>Mensagens</h5>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="textarea" campo="oprtmsg_mensagem" linhas="3" transforme="camel" :valor="$data" titulo="" obrigatorio="false" :errors="{}" modo="editar"
                        @onKeyDown="onIncluirMensagem(0)"
                        ></mct-form-itens>
                    </div>
                </div>
                <div v-for="mensagem in oportunidadeSelecionada.mensagens" :key="mensagem.oprtmsg_codigo">
                    <div class="oportunidade-mensagens" 
                        @mouseover.prevent="onShow(mensagem.oprtmsg_codigo)">
                        <img style="height:24px; width:24px;" class="avatar avatar-user bg-white" :src="mensagem.usuario.usu_foto_url" :alt="mensagem.usuario.usu_nome" :title="mensagem.usuario.usu_nome"/>
                        <span>{{mensagem.usuario.usu_nome}}</span>
                        <small>{{ mensagem.oprtmsg_data | datahora }}</small>
                        <p>{{mensagem.oprtmsg_mensagem}}</p>
                        <div>
                            <a href="#" title="Responder" @click.prevent.stop="respostaMensagem = mensagem.oprtmsg_codigo"><i class="fa fa-reply"></i></a>
                            <!--<div>
                                <span>Visualizado por:</span>
                                <img style="height:20px; width:20px;" class="avatar avatar-user bg-white" src="https://avatars1.githubusercontent.com/u/575412?s=460&u=f082ee13ae01464c3226062edf6e1689dcfba083&v=4" alt="usuário" :title="oportunidadeSelecionada.usuario.usu_nome"/>
                            </div>-->
                        </div>
                    </div>
                    <div style="width: calc(100% - 30px); margin-left: 30px;" class="mt-2" v-show="respostaMensagem === mensagem.oprtmsg_codigo">
                        <mct-form-itens tipo="textarea" campo="oprtmsg_resposta" linhas="3" transforme="camel" :valor="$data" titulo="" obrigatorio="false" :errors="{}" modo="editar"
                        @onKeyDown="onIncluirMensagem(mensagem.oprtmsg_codigo)"
                        ></mct-form-itens>
                    </div>
                    <div style="width: calc(100% - 30px)!important; margin-left: 30px!important;" class="mt-2 oportunidade-mensagens" v-for="resposta in mensagem.respostas" :key="resposta.oprtmsg_codigo"
                        @mouseover.prevent="onShow(resposta.oprtmsg_codigo)">
                        <img style="height:24px; width:24px;" class="avatar avatar-user bg-white" :src="resposta.usuario.usu_foto_url" :alt="resposta.usuario.usu_nome" :title="resposta.usuario.usu_nome"/>
                        <span>{{resposta.usuario.usu_nome}}</span>
                        <small>{{ resposta.oprtmsg_data | datahora }}</small>
                        <p>{{resposta.oprtmsg_mensagem}}</p>
                    </div>
                </div>                    
            </div>
        </b-modal>
        <b-modal id="msg-whatsapp" title="Enviar mensagem por WhatsApp" size="md" footer-class="">
            <div class="p-2">
                <div class="row mb-2">
                    <div class="col-lg-2 mr-2">
                        <mct-form-itens tipo="text" campo="ddi" :valor="mensagem_whatsapp" titulo="DDI" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                    <div class="col-lg-4">
                        <mct-form-itens tipo="text" campo="telefone" mascara="celular" :valor="mensagem_whatsapp" titulo="Telefone" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="textarea" linhas="4" campo="mensagem" transforme="camel" :valor="mensagem_whatsapp" titulo="Mensagem (máx. 300 caracteres)" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <small><i>Para quebra de linha na mensagem do WhatsApp insira este código: %0D</i></small>
                <div class="alert alert-info mt-2 mb-2" role="alert">
                    <p class="text-justify p-1"><strong>ALERTA: </strong>Ao clicar em enviar sua mensagem será incluída na lista de tarefas como concluída e logo após você será direcionado para o <strong>WhatsApp Web/Desktop</strong>, tenha certeza que o número de telefone contenha <strong>DDI</strong> e <strong>DDD</strong>.</p>
                </div>
                <div class="alert alert-danger" role="alert">
                    <p class="text-justify p-1"><strong>ATENÇÃO: </strong>Respeite a política de envio de mensagem do WhatsApp, pois ao desrespeitar tais políticas seu número poderá ser banido do aplicativo.</p>
                    <p class="text-justify p-1"><strong>Evite mensagens longas.</strong></p>
                </div>
            </div>                
            <div slot="modal-footer">
                <button type="button" class="btn btn-success mr-1 p-2" aria-label="Left Align"  @click.prevent.stop="onEnviarWhatsApp()">
                        <span class="fa fa-check fa-lg" aria-hidden="true"></span> Enviar </button>
                <button type="button" class="btn btn-danger mr-1 p-2" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('msg-whatsapp')">
                        <span class="fa fa-times fa-lg" aria-hidden="true"></span> Cancelar </button>
            </div>
        </b-modal>
        <b-modal id="msg-sms" title="Enviar mensagem por SMS" size="md" footer-class="">
            <div class="p-2">
                <div class="row mb-2">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="text" campo="nome" :valor="mensagem_sms" titulo="Nome" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-4">
                        <mct-form-itens tipo="text" campo="telefone" mascara="celular" :valor="mensagem_sms" titulo="Telefone" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="textarea" linhas="4" campo="mensagem" transforme="camel" :valor="mensagem_sms" titulo="Mensagem (máx. 160 caracteres)" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="alert alert-info mt-2 mb-2" role="alert">
                    <p class="text-justify p-1"><strong>ALERTA: </strong>Ao clicar em enviar sua mensagem será incluída na lista de tarefas como concluída e logo após será enviada a mensagem <strong>SMS</strong>.</p>
                </div>
            </div>                
            <div slot="modal-footer">
                <button type="button" class="btn btn-success mr-1 p-2" aria-label="Left Align"  @click.prevent.stop="onEnviarSMS()">
                        <span class="fa fa-check fa-lg" aria-hidden="true"></span> Enviar </button>
                <button type="button" class="btn btn-danger mr-1 p-2" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('msg-sms')">
                        <span class="fa fa-times fa-lg" aria-hidden="true"></span> Cancelar </button>
            </div>
        </b-modal>        
        <b-modal id="msg-email" title="Enviar mensagem por Email" size="md" footer-class="">
            <div class="p-2">
                <div class="row mb-2">
                    <div class="col-lg-12 mr-2">
                        <mct-form-itens tipo="text" campo="nome" :valor="mensagem_email" titulo="Nome do Contato" transforme="camel" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-12 mr-2">
                        <mct-form-itens tipo="email" campo="email" :valor="mensagem_email" titulo="Email" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-12 mr-2">
                        <mct-form-itens tipo="text" campo="assunto" :valor="mensagem_email" titulo="Assunto do Email" transforme="camel" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <mct-form-itens tipo="textarea" linhas="4" campo="mensagem" transforme="camel" :valor="mensagem_email" titulo="Mensagem (máx. 300 caracteres)" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="alert alert-info mt-2 mb-2" role="alert">
                    <p class="text-justify p-1"><strong>ALERTA: </strong>Ao clicar em enviar sua mensagem será incluída na lista de tarefas como concluída e logo após será enviado o email, tenha certeza que o <strong>EMAIL</strong> esteja digitado corretamente.</p>
                </div>
            </div>                
            <div slot="modal-footer">
                <button type="button" class="btn btn-success mr-1 p-2" aria-label="Left Align"  @click.prevent.stop="onEnviarEmail()">
                        <span class="fa fa-check fa-lg" aria-hidden="true"></span> Enviar </button>
                <button type="button" class="btn btn-danger mr-1 p-2" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('msg-email')">
                        <span class="fa fa-times fa-lg" aria-hidden="true"></span> Cancelar </button>
            </div>
        </b-modal>
        <b-modal id="novo-curso" title="Adicionar Curso(s)" size="md" footer-class="">
            <div class="p-2">
                <div class="row mb-2">
                    <div class="col-lg-12 mr-2">
                        <mct-form-itens tipo="select" campo="cur_codigo" :valor="produtos" titulo="Curso" 
                        origem="esc_cursos_tabela" origemChave="cur_codigo" origemRetorno='cur_nome' origemRetornoObj="curso" origemRetornoSec='ctab_vlr_avista'
                        obrigatorio="true" :errors="{}" modo="novo" @setDataRetorno="getCurso"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-12 mr-2">
                        <mct-form-itens tipo="number" campo="oprtprod_qtd" :valor="produtos" titulo="Qtde" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
                <div class="row mb-2">
                    <div class="col-lg-12 mr-2">
                        <mct-form-itens tipo="currency" campo="oprtprod_valor" :valor="produtos" titulo="Valor" obrigatorio="true" :errors="{}" modo="novo"></mct-form-itens>
                    </div>
                </div>
            </div>                
            <div slot="modal-footer">
                <button type="button" class="btn btn-success mr-1 p-2" aria-label="Left Align"  @click.prevent.stop="onIncluirCurso()">
                        <span class="fa fa-check fa-lg" aria-hidden="true"></span> Adicionar </button>
                <button type="button" class="btn btn-danger mr-1 p-2" aria-label="Left Align" @click.prevent.stop="$bvModal.hide('novo-curso')">
                        <span class="fa fa-times fa-lg" aria-hidden="true"></span> Cancelar </button>
            </div>
        </b-modal>

    </div>
</template>

<script>
import Vue from 'vue';
import { mapState } from 'vuex'
import axios from '@/http';
import moment from 'moment-timezone';
import draggable from "vuedraggable";
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import Multiselect from 'vue-multiselect'

import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctLogradouro from '@/components/comum/MctLogradouroC.vue'
import MctLabelEdit from '@/components/comum/MctLabelEdit.vue'

export default {
    name: "FunilKaban",
    components: {
        draggable,
        MctFormItens,
        MctLogradouro,
        MctLabelEdit,
        VueBootstrapTypeahead,
        Multiselect
    },
    data() {
        return {
            modo: 'novo',
            fnl_codigo: null,
            contatos: [],
            contatoSelecionado: {},
            funil: {},
            metas: [],
            meta: {
                fnl_codigo: null,
                mts_tipo: null,
                mts_periodo: null,
                mts_valor: null,
                participantes: []
            },
            grupo: {
                fnlgrp_codigo: null,
                fnl_codigo: null,
                fnlgrp_descricao: null,
                fnlgrp_tipo: null,
                fnlgrp_sequencia: null,
                fnlgrp_cor: null
            },
            oportunidade: {
                oprt_ent: null,
                oprt_esc: null,
                oprt_ano: null,
                fnl_codigo: null,
                cont_codigo: null,
                oprt_titulo: null,
                oprt_tipo: null,
                oprt_observacoes: null,
                oprt_inicio: null,
                oprt_expec_termino: null,
                oprt_vlr_estimado: null,
                oprt_tags: null,
                oprt_nivel_interesse: null,
                usu_codigo: null,
                fnlgrp_codigo_atual: null,
                oprt_situacao: null,

                cont_tipo: null,
                cont_cpf: null,
                cont_nome: null,
                cont_telefone: null,
                cont_celular: null,
                cont_segmento: null,
                cont_cargo: null,
                cont_email: null,
                cont_empresa: null,
                cont_logra_numero: null,
                cont_logra_complemento: null,
                cont_cep: null,
                cont_logradouro: null,
                cont_bairro: null,
                cont_cidade: null,
                cont_estado: null
            },
            oportunidadeSelecionada: {},
            tarefa: {
                fnlgrp_codigo: null,
                oprt_codigo: null,
                trf_descricao: null,
                tptrf_codigo: null,
                trf_anotacoes: null,
                trf_data: null,
                trf_inicio: null,
                trf_fim: null,
                trf_anotacoes: null,
                trf_situacao: 0
            },
            produtos: {
                oprtprod_codigo: null,
                oprt_codigo: null,
                cur_codigo: null,
                oprtprod_valor: null,
                oprtprod_qtd: null,
            },
            tags: [],
            tag :{
                tags_codigo: null,
                tags_tag: null,
                tags_cor: null,
                tags_tipo: null
            },
            oprtmsg_mensagem: '',
            oprtmsg_resposta: '',
            mensagem_email: {
                email: '',
                nome: '',
                assunto: '',
                mensagem: '',
            },
            mensagem_sms: {
                telefone: '',
                nome: '',
                mensagem: '',
            },
            mensagem_whatsapp: {
                ddi: '+55',
                telefone: '',
                mensagem: '',
            },
            respostaMensagem: 0,
            files: []
        };
    },
    methods: {
        onShow(item){
            //console.log('show: ',item);
        },
        async setMetas () {
            await axios.get(`/crmmetas/lista/${this.fnl_codigo}`)
                .then(response => {
                    this.metas = response.data.data 
                })
                .catch(response => {
                    //console.log(response)
                })
        },
        async setTags () {
            await axios.get('crmtags/combo')
                .then(response => {
                    this.tags = response.data.data 
                })
                .catch(response => {
                    //console.log(response)
                })
        },
        async setContatosPessoas () {
            await axios.get('crmcontatos/pessoas')
                .then(response => {
                    this.contatos = response.data 
                })
                .catch(response => {
                    //console.log(response)
                })
        },
        setContatoSelecionado (data) {
            this.contatoSelecionado = data;

            this.oportunidade = {...this.oportunidade, ...this.contatoSelecionado, 
                cont_codigo: this.contatoSelecionado.tipo === 'C' ? this.contatoSelecionado.cont_codigo : null}
        }, 
        async setFunil () {
            await axios.get(`crmfunil/editar/${this.fnl_codigo}`)
                .then(response => {
                    this.funil = response.data.data 
                })
                .catch(response => {
                    //console.log(response)
                })
        },
        async saveFunil () {
            await axios.put(`crmfunil/atualizar/${this.funil.fnl_codigo}`,this.funil)
                .then(response => {
                    //this.funil = response.data.data
                    //console.log(response);
                })
                .catch(response => {
                    //console.log(response)
                })
        },
        getTags(tags){
            return tags ? tags.split(',') : null;
        },
        onEndFunil (evt) {
            const newArray = this.funil.grupos.map((item, idx) => {
                return { ...item, fnlgrp_sequencia: idx };
            })

            this.funil.grupos = newArray;

            this.saveFunil();
        },
        onEndGrupo (evt) {
            const grupoFromIndex = evt.from.getAttribute('data-grupo-index');
            const grupoToIndex =evt.to.getAttribute('data-grupo-index');
            const grupoToCodigo =evt.to.getAttribute('data-grupo-codigo');
            const newIndex = evt.newIndex;

            this.funil.grupos[grupoToIndex].oportunidades[newIndex].fnlgrp_codigo_atual = Number(grupoToCodigo);

            const newArray = this.funil.grupos[grupoToIndex].oportunidades.map((item, idx) => {
                return { ...item, oprt_sequencia: idx };
            })

            this.funil.grupos[grupoToIndex].oportunidades = newArray;

            this.saveFunil();
        },
        onNovoGrupo () {
            this.onLimpaGrupo();
            this.grupo.fnlgrp_codigo = null;
            this.grupo.fnl_codigo = this.funil.fnl_codigo;
            this.grupo.fnlgrp_descricao = null;
            this.grupo.fnlgrp_tipo = 1;
            this.grupo.fnlgrp_sequencia = this.funil && this.funil.grupos ? this.funil.grupos.length : 0;
            this.grupo.fnlgrp_cor = null;            
            this.$bvModal.show('novo-grupo')
        },
        async onIncluirGrupo() {
            let msgs = ''
            if (this.grupo.fnlgrp_tipo == null || this.grupo.fnlgrp_tipo == 0){
                msgs += 'O tipo do grupo deve ser selecionado; </br>';
            }
            if (this.grupo.fnlgrp_descricao == null || this.grupo.fnlgrp_descricao == ''){
                msgs += 'A descrição do grupo deve ser preenchida; </br>';
            }
            if (this.grupo.fnlgrp_sequencia == null || this.grupo.fnlgrp_sequencia == ''){
                msgs += 'A sequência do grupo deve ser preenchida; </br>';
            }
            if (this.grupo.fnlgrp_cor == null || this.grupo.fnlgrp_cor == ''){
                msgs += 'A cor do grupo deve ser selecionada; </br>';
            }
            
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Incluir o Grupo',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            await axios.post('crmfunilgrupos/novo',this.grupo)
                .then(response => {
                    
                    this.$swal('Sucesso!',
                        'Grupo incluído com sucesso!',
                        'success');
                    this.$bvModal.hide('novo-grupo');
                    this.onLimpaGrupo;
                })
                .catch(response => {
                    this.message = response.response.data.message;
                    this.errors = response.response.data.message;
                    this.status = response.response.status
                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) ao incluir o grupo.',
                        text: this.message,
                        footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                    })
                })
            }
        }, 
        async onExcluirGrupo (codigo) {
            this.$swal({
                title: 'Deseja realmente excluir o grupo e todas as suas oportunidades?',
                text: "Este evento não podera ser revertido!",
                focusConfirm: false,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim',
            }).then((result) => {
                if (result.value) {                    
                    axios.delete(`/crmfunilgrupos/deletar/${codigo}`)
                        .then(response => {
                            this.message = response.data.message
                            this.status = response.status
                            this.errors = {}
                            this.getData()
                            
                            this.$swal('Sucesso!',
                            'Grupo excluído com sucesso!',
                            'success');
                        })
                        .catch(response => {
                            this.message = response.response.data.message;
                            this.errors = response.response.data.message;
                            this.status = response.response.status
                            this.$swal({
                                icon: 'error',
                                title: 'Erro(s) ao Excluir o Grupo',
                                text: this.message,
                                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                            })                              
                        })                
                }                     
            })            
        },
        onLimpaGrupo () {
            this.grupo = {
                fnlgrp_codigo: null,
                fnl_codigo: null,
                fnlgrp_descricao: null,
                fnlgrp_tipo: null,
                fnlgrp_sequencia: 1,
                fnlgrp_cor: null
            }
        },
        async onAlterarGrupo ( data ) {            
            const {id, value} = data;
            const grupo  = this.funil.grupos.filter(el => el.fnlgrp_codigo === id)[0];
            let msgs = '';
            if (id == null || id == 0){
                msgs += 'O grupo deve ser selecionado; </br>';
            }
            if (value == null || value == ''){
                msgs += 'A descrição do grupo deve ser preenchida; </br>';
            }
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Alterar o Grupo',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            await axios.put(`crmfunilgrupos/editar/${id}`,grupo)
                .then(response => {
                    this.$awn.success(`Grupo incluído com sucesso!`);
                })
                .catch(response => {
                    this.message = response.response.data.message;
                    this.errors = response.response.data.message;
                    this.status = response.response.status
                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) ao alterar o grupo.',
                        text: this.message,
                        footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                    })
                })
            }
        },
        onNovaOportunidade () {
            this.onLimpaOportunidade();
            this.oportunidade.oprt_ent = this.funil.fnl_ent;
            this.oportunidade.oprt_esc = this.funil.fnl_esc;
            this.oportunidade.oprt_ano = this.funil.fnl_ano;
            this.oportunidade.fnl_codigo = this.funil.fnl_codigo;
            this.oportunidade.usu_codigo = this.infoLogado.usu_codigo;
            this.oportunidade.oprt_inicio = new Date;
            this.oportunidade.oprt_tipo = 1;
            this.oportunidade.oprt_situacao = 1; 
            this.oportunidade.oprt_nivel_interesse = 0;           
            this.$bvModal.show('nova-oportunidade')
        },
        onLimpaOportunidade () {
            this.oportunidade =  {
                oprt_ent: null,
                oprt_esc: null,
                oprt_ano: null,
                fnl_codigo: null,
                cont_codigo: null,
                oprt_titulo: null,
                oprt_tipo: null,
                oprt_observacoes: null,
                oprt_inicio: null,
                oprt_expec_termino: null,
                oprt_vlr_estimado: null,
                oprt_tags: null,
                usu_codigo: null,
                fnlgrp_codigo_atual: null,
                oprt_situacao: null,
                oprt_nivel_interesse: null,

                cont_tipo: null,
                cont_cpf: null,
                cont_nome: null,
                cont_telefone: null,
                cont_celular: null,
                cont_segmento: null,
                cont_cargo: null,
                cont_email: null,
                cont_empresa: null,
                cont_logra_numero: null,
                cont_logra_complemento: null,
                cont_cep: null,
                cont_logradouro: null,
                cont_bairro: null,
                cont_cidade: null,
                cont_estado: null                 
            }
        },     
        async onIncluirOportunidade() {
            let msgs = ''
            if (this.oportunidade.oprt_titulo == null || this.oportunidade.oprt_titulo == ''){
                msgs += 'O título da oportunidade deve ser preenchido; </br>';
            }
            if (this.oportunidade.cont_nome == null || this.oportunidade.cont_nome == ''){
                msgs += 'Selecione uma pessoa ou digite o Nome e os dados da mesma; </br>';
            }
            if (this.oportunidade.cont_celular == null || this.oportunidade.cont_celular == ''){
                msgs += 'O Número do Telefone Celular deve ser preenchido; </br>';
            }
            /*if (this.oportunidade.cont_email == null || this.oportunidade.cont_email == ''){
                msgs += 'O Email deve ser preenchido; </br>';
            }*/
            if (this.oportunidade.fnlgrp_codigo_atual == null){
                msgs += 'Selecione a etapa que a oportunidade ira iniciar; </br>';
            }
            
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Incluir a oportunidade',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            await axios.post('crmoportunidades/novo',this.oportunidade)
                .then(response => {
                    
                    this.$swal('Sucesso!',
                        'Oportunidade incluída com sucesso!',
                        'success');
                    this.$bvModal.hide('nova-oportunidade');
                    this.onLimpaOportunidade;
                })
                .catch(response => {
                    this.message = response.response.data.message;
                    this.errors = response.response.data.message;
                    this.status = response.response.status
                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) ao incluir a oportunidade.',
                        text: this.message,
                        footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                    })
                })
            }
        }, 
        onEditarOportunidade(idxGrupo,idxOptr){
            this.oportunidadeSelecionada = this.funil.grupos[idxGrupo].oportunidades[idxOptr];
            if (this.funil.grupos[idxGrupo].fnlgrp_tipo === 2 || 
            this.funil.grupos[idxGrupo].fnlgrp_tipo ===  3 || 
            this.funil.grupos[idxGrupo].oportunidades[idxOptr].usu_codigo !== this.infoLogado.usu_codigo){
                this.modo = 'readonly';
            } else {
                this.modo = 'editar';
            }
            this.$bvModal.show('editar-oportunidade');
        },
        async onAlterarOportunidade() {
            let msgs = ''
            if (this.oportunidadeSelecionada.oprt_titulo == null || this.oportunidadeSelecionada.oprt_titulo == ''){
                msgs += 'O título da oportunidade deve ser preenchido; </br>';
            }
            if (this.oportunidadeSelecionada.cont_codigo == null || this.oportunidadeSelecionada.cont_codigo == 0){
                msgs += 'Selecione uma pessoa ou digite o Nome e os dados da mesma; </br>';
            }
            if (this.oportunidadeSelecionada.fnlgrp_codigo_atual == null){
                msgs += 'Selecione a etapa que a oportunidade ira iniciar; </br>';
            }
            
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Alterar a oportunidade',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            await axios.put(`crmoportunidades/editar/${this.oportunidadeSelecionada.oprt_codigo}`,this.oportunidadeSelecionada)
                .then(response => {
                    
                    this.$swal('Sucesso!',
                        'Oportunidade alterada com sucesso!',
                        'success');
                    //this.$bvModal.hide('nova-oportunidade');
                    //this.onLimpaOportunidade;
                })
                .catch(response => {
                    this.message = response.response.data.message;
                    this.errors = response.response.data.message;
                    this.status = response.response.status
                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) ao alterar a oportunidade.',
                        text: this.message,
                        footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                    })
                })
            }
        },        
        
        onLimpaTarefa () {
            this.tarefa =  {
                oprt_codigo: null,
                fnlgrp_codigo: null,
                trf_descricao: null,
                tptrf_codigo: null,
                trf_anotacoes: null,
                trf_data: null,
                trf_inicio: null,
                trf_fim: null,
                trf_anotacoes: null,
                trf_situacao: 0                    
            }
        },
        onNovaTarefa (fnlgrp_codigo, oprt_codigo) {
            this.onLimpaTarefa();
            this.tarefa.oprt_codigo = oprt_codigo;
            this.tarefa.fnlgrp_codigo = fnlgrp_codigo;
            this.$bvModal.show('nova-tarefa')
        },
        onEditarTarefa (tarefa) {
            this.onLimpaTarefa();
            this.tarefa = tarefa;
            this.$bvModal.show('nova-tarefa')
        },
        async onAlteraTarefa(tarefa) {
            await axios.put(`crmtarefas/editar/${tarefa.trf_codigo}`,tarefa)
            .then(response => {
                this.$swal('Sucesso!',
                    'Tarefa alterada com sucesso!',
                    'success');
                this.$bvModal.hide('nova-tarefa');
                this.onLimpaTarefa;
            })
            .catch(response => {
                this.message = response.response.data.message;
                this.errors = response.response.data.message;
                this.status = response.response.status
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao alterar a tarefa.',
                    text: this.message,
                    footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                })                    
            })
        },
        async onExcluirTarefa(codigo) {
            this.$swal({
                title: 'Deseja realmente excluir a tarefa?',
                text: "Este evento não podera ser revertido!",
                focusConfirm: false,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim',
            }).then(async (result) => {
                if (result.value) {             
                    await axios.delete(`crmtarefas/deletar/${codigo}`)
                    .then(response => {
                        this.$swal('Sucesso!',
                            'Tarefa excluida com sucesso!',
                            'success');
                    })
                    .catch(response => {
                        this.message = response.response.data.message;
                        this.errors = response.response.data.message;
                        this.status = response.response.status
                        this.$swal({
                            icon: 'error',
                            title: 'Erro(s) ao excluir a tarefa.',
                            text: this.message,
                            footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                        })                    
                    })
                }
            });
        },
        async onAlteraSituacaoTarefa(codigo, situacao) {
            this.$swal({
                title: `Deseja realmente alterar a situação da tarefa para ${situacao === 0 ? 'não concluída' : situacao === 1 ? 'concluída' : situacao === 2 ? 'cancelada' : ''}?`,
                text: "Este evento podera ser revertido futuramente!",
                focusConfirm: false,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim',
            }).then(async (result) => {
                if (result.value) {                    
                    await axios.put(`crmfunil/tarefa/atualizar/${codigo}`,{
                        trf_situacao: situacao
                    })
                    .then(response => {
                        this.$swal('Sucesso!',
                            'Situação da tarefa alterada com sucesso!',
                            'success');
                    })
                    .catch(response => {
                        this.message = response.response.data.message;
                        this.errors = response.response.data.message;
                        this.status = response.response.status
                        this.$swal({
                            icon: 'error',
                            title: 'Erro(s) ao incluir a tarefa.',
                            text: this.message,
                            footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                        })                    
                    })
                }                     
            })
        },
        async onIncluirTarefa() {
            let msgs = ''
            if (this.tarefa.trf_descricao == null || this.tarefa.trf_descricao == ''){
                msgs += 'A descrição da tarefa deve ser preenchida; </br>';
            }
            if (this.tarefa.tptrf_codigo == null || this.tarefa.tptrf_codigo == 0){
                msgs += 'Selecione o tipo da tarefa; </br>';
            }
            if (this.tarefa.trf_situacao == null){
                msgs += 'Selecione a situação da tarefa; </br>';
            }
            
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Incluir a Tarefa',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            await axios.post('crmtarefas/novo',this.tarefa)
                .then(response => {
                    const grpIdx = this.funil.grupos.findIndex(ele => {
                        return ele.fnlgrp_codigo === this.tarefa.fnlgrp_codigo;
                    })

                    const optrIdx = this.funil.grupos[grpIdx].oportunidades.findIndex(ele => {
                        return ele.optr_codigo === this.tarefa.optr_codigo;
                    })

                    this.funil.grupos[grpIdx].oportunidades[optrIdx].tarefas.push(response.data.data);

                    this.$swal('Sucesso!',
                        'Tarefa incluída com sucesso!',
                        'success');
                    this.$bvModal.hide('nova-tarefa');
                    this.onLimpaTarefa;
                })
                .catch(response => {
                    this.message = response.response.data.message;
                    this.errors = response.response.data.message;
                    this.status = response.response.status
                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) ao incluir a tarefa.',
                        text: this.message,
                        footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                    })
                })
            }
        },
        tarefasConcluidas (tarefa) {
            if (tarefa.filter){
                const concluidas = tarefa.filter(ele => ele.trf_situacao === 1).length;
                return concluidas;
            } else{
                return 0;
            }
        },
        getCurso(data){
            this.produtos.oprtprod_valor = data.ctab_vlr_avista;
        },
        onLimpaCurso () {
            this.produtos =  {
                oprt_codigo: null,
                oprtprod_codigo: null,
                cur_codigo: null,
                oprtprod_valor: null,
                oprtprod_qtd: null,
            }
        },
        onNovoCurso (oprt_codigo) {
            this.onLimpaCurso();
            this.produtos.oprt_codigo = oprt_codigo;
            this.produtos.oprtprod_qtd = 1;
            this.$bvModal.show('novo-curso')
        },
        async onAlteraCurso() {
            await axios.put(`crmoportunidadesprodutos/editar/${codigo}`,this.produtos)
            .then(response => {
                    this.$swal('Sucesso!',
                        'Curso editado com sucesso!',
                        'success');
                    this.$bvModal.hide('novo-curso');
                    this.onLimpaCurso;
            })
            .catch(response => {
                this.message = response.response.data.message;
                this.errors = response.response.data.message;
                this.status = response.response.status
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao incluir o Curso.',
                    text: this.message,
                    footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                })                    
            })
        },
        async onIncluirCurso() {
            let msgs = ''
            if (this.produtos.cur_codigo == null || this.produtos.cur_codigo == 0){
                msgs += 'O Curso deve ser selecionado; </br>';
            }
            if (this.produtos.oprtprod_qtd == null || this.produtos.oprtprod_qtd == 0){
                msgs += 'A Quantidade não poder ser nula ou zero; </br>';
            }
            if (this.produtos.oprtprod_valor == null || this.produtos.oprtprod_valor == 0){
                msgs += 'A Valor não poder ser nulo ou zero; </br>';
            }
            
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Incluir o Curso',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            await axios.post('crmoportunidadesprodutos/novo',this.produtos)
                .then(response => {

                    this.$swal('Sucesso!',
                        'Curso incluído com sucesso!',
                        'success');
                    this.$bvModal.hide('novo-curso');
                    this.onLimpaCurso;
                })
                .catch(response => {
                    this.message = response.response.data.message;
                    this.errors = response.response.data.message;
                    this.status = response.response.status
                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) ao incluir o Curso.',
                        text: this.message,
                        footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                    })
                })
            }
        },  
        async onExcluirCurso (codigo) {
            this.$swal({
                title: 'Deseja realmente excluir o Curso da lista?',
                text: "Este evento não podera ser revertido!",
                focusConfirm: false,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim',
            }).then((result) => {
                if (result.value) {                    
                    axios.delete(`/crmoportunidadesprodutos/deletar/${codigo}`)
                        .then(response => {
                            this.message = response.data.message
                            this.status = response.status
                            this.errors = {}
                            
                            this.$swal('Sucesso!',
                            'Curso excluído com sucesso!',
                            'success');
                        })
                        .catch(response => {
                            this.message = response.response.data.message;
                            this.errors = response.response.data.message;
                            this.status = response.response.status
                            this.$swal({
                                icon: 'error',
                                title: 'Erro(s) ao Excluir o Curso',
                                text: this.message,
                                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                            })                              
                        })                
                }                     
            })            
        },              
        onLimpaMeta () {
            this.meta = {
                fnl_codigo: null,
                mts_tipo: null,
                mts_periodo: null,
                mts_valor: null,
                participantes: []
            }
        },
        onListaMeta () {
            this.setMetas().then((response) => 
                this.$bvModal.show('lista-meta')
            );
        },
        onNovaMeta () {
            this.onLimpaMeta();
            this.meta.fnl_codigo = this.funil.fnl_codigo;
            this.$bvModal.show('nova-meta')
        },
        onEditarMeta (meta) {
            this.onLimpaMeta();
            this.meta = {...meta};
            this.$bvModal.show('nova-meta')
        },
        async onAlterarMeta() {
            let msgs = ''
            if (this.meta.mts_tipo == null || this.meta.mts_tipo == 0){
                msgs += 'Selecione o tipo da meta; </br>';
            }
            if (this.meta.mts_periodo == null || this.meta.mts_periodo == 0){
                msgs += 'Selecione o período de vigência da meta; </br>';
            }
            if (this.meta.mts_valor == null || this.meta.mts_valor == 0){
                msgs += 'Digite um valor para a meta; </br>';
            }
            if (this.meta.participantes == []){
                msgs += 'Selecione o(s) participante(s) da meta; </br>';
            }
            
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Alterar a Meta',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            await axios.put(`crmmetas/editar/${this.meta.mts_codigo}`,this.meta)
                .then(response => {
                    this.$swal('Sucesso!',
                        'Meta alterada com sucesso!',
                        'success');
                    this.$bvModal.hide('nova-meta');
                    this.onLimpaMeta;
                    this.setMetas();
                })
                .catch(response => {
                    this.message = response.response.data.message;
                    this.errors = response.response.data.message;
                    this.status = response.response.status
                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) ao alterar a meta.',
                        text: this.message,
                        footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                    })
                })
            }
        },
        async onIncluirMeta() {
            let msgs = ''
            if (this.meta.mts_tipo == null || this.meta.mts_tipo == 0){
                msgs += 'Selecione o tipo da meta; </br>';
            }
            if (this.meta.mts_periodo == null || this.meta.mts_periodo == 0){
                msgs += 'Selecione o período de vigência da meta; </br>';
            }
            if (this.meta.mts_valor == null || this.meta.mts_valor == 0){
                msgs += 'Digite um valor para a meta; </br>';
            }
            if (this.meta.participantes == []){
                msgs += 'Selecione o(s) participante(s) da meta; </br>';
            }
            
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Incluir a Meta',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            await axios.post('crmmetas/novo',this.meta)
                .then(response => {
                    this.$swal('Sucesso!',
                        'Meta incluída com sucesso!',
                        'success');
                    this.$bvModal.hide('nova-meta');
                    this.onLimpaMeta;
                })
                .catch(response => {
                    this.message = response.response.data.message;
                    this.errors = response.response.data.message;
                    this.status = response.response.status
                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) ao incluir a meta.',
                        text: this.message,
                        footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                    })
                })
            }
        },
        async onExcluirMeta (codigo) {
            this.$swal({
                title: 'Deseja realmente excluir a Meta?',
                text: "Este evento não podera ser revertido!",
                focusConfirm: false,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim',
            }).then((result) => {
                if (result.value) {
                    axios.delete(`/crmmetas/deletar/${codigo}`)
                        .then(response => {
                            this.message = response.data.message
                            this.status = response.status
                            this.errors = {}
                            
                            this.$swal('Sucesso!',
                            'Meta excluída com sucesso!',
                            'success');
                            this.setMetas();
                        })
                        .catch(response => {
                            this.message = response.response.data.message;
                            this.errors = response.response.data.message;
                            this.status = response.response.status
                            this.$swal({
                                icon: 'error',
                                title: 'Erro(s) ao Excluir a Meta',
                                text: this.message,
                                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                            })
                        })
                }
            })
        },
        onLimpaTag () {
            this.tag = {
                tags_codigo: null,
                tags_tag: null,
                tags_cor: null,
                tags_tipo: null
            }
        },
        onNovaTag () {
            this.onLimpaTag();
            this.$bvModal.show('nova-tag')
        },
        async onIncluirTag() {
            let msgs = ''
            if (this.tag.tags_tag == null || this.tag.tags_tag == ''){
                msgs += 'Digite uma descrição para a tag; </br>';
            }
            if (this.tag.tags_tipo == null || this.tag.tags_tipo == 0){
                msgs += 'Selecione o tipo da tag; </br>';
            }
            if (this.tag.tags_cor == null || this.tag.tags_cor == ''){
                msgs += 'Selecione uma cor para a tag; </br>';
            }
            
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Incluir a Tag',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            await axios.post('crmtags/novo',this.tag)
                .then(response => {
                    this.$swal('Sucesso!',
                        'Tag incluída com sucesso!',
                        'success');
                    this.$bvModal.hide('nova-tag');
                    this.onLimpaTag;
                    this.setTags();
                })
                .catch(response => {
                    this.message = response.response.data.message;
                    this.errors = response.response.data.message;
                    this.status = response.response.status
                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) ao incluir a tag.',
                        text: this.message,
                        footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                    })
                })
            }
        },

        async onIncluirMensagem(resposta) {
            let msgs = ''
            if ((resposta === 0) && (this.oprtmsg_mensagem == null || this.oprtmsg_mensagem == '')){
                msgs += 'O campo mensagem deve ser preenchido; </br>';
            }

            if ((resposta > 0) && (this.oprtmsg_resposta == null || this.oprtmsg_resposta == '')){
                msgs += 'O campo mensagem de resposta deve ser preenchida; </br>';
            }
            
            if (msgs !== ''){
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Incluir a mensagem',
                    html: msgs,
                    footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                })        
            }else{
            await axios.post('crmoportunidadesmensagens/novo',{
                oprt_codigo: this.oportunidadeSelecionada.oprt_codigo,
                oprtmsg_mensagem: resposta > 0 ? this.oprtmsg_resposta : this.oprtmsg_mensagem,
                usu_codigo: this.infoLogado.usu_codigo,
                oprtmsg_data: moment(new Date).locale("pt-br").tz('America/Cuiaba').format(),
                oprtmsg_situacao: 0,
                oprtmsg_origem: resposta > 0 ? resposta : null
                })
                .then(response => {                    
                    this.$awn.success(`Mensagem incluída com sucesso!`);
                    this.oprtmsg_mensagem = '';
                    this.oprtmsg_resposta = '';
                    this.respostaMensagem = 0;
                })
                .catch(response => {
                    this.message = response.response.data.message;
                    this.errors = response.response.data.message;
                    this.status = response.response.status
                    this.$swal({
                        icon: 'error',
                        title: 'Erro(s) ao incluir a mensagem.',
                        text: this.message,
                        footer: '<small class="text-danger"><strong>Corrija o(s) erro(s) relacionados acima e tente novamente.</strong></small>'
                    })
                })
            }
        },
        
        /* UPLOAD */        
        handleFiles() {
            let uploadedFiles = this.$refs.files.files;

            for(var i = 0; i < uploadedFiles.length; i++) {
                this.files.push(uploadedFiles[i]);
            }
            this.getImagePreviews();
        },
        getImagePreviews(){
            for( let i = 0; i < this.files.length; i++ ){
                if ( /\.(jpe?g|png|gif)$/i.test( this.files[i].name ) ) {
                    let reader = new FileReader();
                    reader.addEventListener("load", function(){
                        this.$refs['preview'+parseInt( i )][0].src = reader.result;
                    }.bind(this), false);
                    reader.readAsDataURL( this.files[i] );
                }else{
                    this.$nextTick(function(){
                        this.$refs['preview'+parseInt( i )][0].src = '/img/generic.png';
                    });
                }
            }
        },
        removeFile( key ){
            this.files.splice( key, 1 );
            this.getImagePreviews();
        },
        async submitFiles() {
            for( let i = 0; i < this.files.length; i++ ){
                if(this.files[i].id) {
                    continue;
                }
                let formData = new FormData();
                formData.append('file', this.files[i]);

                await axios.post(`crmoportunidades/upload/${this.oportunidadeSelecionada.oprt_codigo}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(data => {
                    this.$awn.success(`Upload do Arquivo "${data.data.oprtarq_arquivo}", realizado com sucesso!`);
                    //this.files[i].id = data['data']['id'];
                    //this.files.splice(i, 1, this.files[i]);
                    this.files.splice(i, 1);
                    //console.log('success');
                }).catch(data => {
                    //console.log('error');
                    this.$awn.alert(`Erro ao realizar Upload do Arquivo ${i+1}.`);
                });
            }
        },
        async onExcluirArquivo(arquivo) {
            await axios.delete(`crmoportunidades/deletar/arquivo/${arquivo}`
            ).then(data => {
                this.$awn.success(`Arquivo deletado com sucesso!`);
            }).catch(data => {
                this.$awn.alert(`Erro ao deletar o Arquivo ${i+1}.`);
            });
        },

        /* MENSAGENS */
        onLimpaMensagemWhatsApp(){
            this.mensagem_whatsapp = {
                ddi: '+55',
                telefone: '',
                mensagem: '',
            };
        },
        onMensagemWhatsApp(){
            this.onLimpaMensagemWhatsApp();
            this.mensagem_whatsapp.telefone = this.oportunidadeSelecionada.contato.cont_celular;
            this.$bvModal.show('msg-whatsapp');
        },
        async onEnviarWhatsApp() {            
            if (this.mensagem_whatsapp){
                /* INCLUINDO A TAREFA */
                this.onLimpaTarefa();
                this.tarefa.oprt_codigo = this.oportunidadeSelecionada.oprt_codigo;
                this.tarefa.fnlgrp_codigo = this.oportunidadeSelecionada.fnlgrp_codigo_atual;
                this.tarefa.trf_descricao = 'ENVIO MSG. WHATSAPP';
                this.tarefa.tptrf_codigo = 4;
                this.tarefa.trf_anotacoes = this.mensagem_whatsapp.mensagem;
                this.tarefa.trf_data = moment(new Date).locale("pt-br").tz('America/Cuiaba').format('DD/MM/YYYY');
                this.tarefa.trf_inicio = moment(new Date).locale("pt-br").tz('America/Cuiaba').format('HH:mm');
                this.tarefa.trf_fim = moment(new Date).locale("pt-br").tz('America/Cuiaba').format('HH:mm');
                this.tarefa.trf_situacao = 1;
                
                await this.onIncluirTarefa().then(res => {
                    this.onLimpaTarefa();
                    const numero = this.mensagem_whatsapp.ddi + this.mensagem_whatsapp.telefone.replace(/\D/g,'');
                    const mensagem = this.mensagem_whatsapp.mensagem;
                    window.open(`https://api.whatsapp.com/send?phone=${numero}&text=${mensagem}`, "_blank");
                    this.$bvModal.hide('msg-whatsapp');
                });
            }
        },
        onLimpaMensagemSMS(){
            this.mensagem_sms = {
                nome: '',
                telefone: '',
                mensagem: '',
            };
        },
        onMensagemSMS(){
            this.onLimpaMensagemSMS();
            this.mensagem_sms.telefone = this.oportunidadeSelecionada.contato.cont_celular;
            this.mensagem_sms.nome = this.oportunidadeSelecionada.contato.cont_nome;
            this.$bvModal.show('msg-sms');
        },
        async onEnviarSMS(){
            if (this.mensagem_sms){
                /* INCLUINDO A TAREFA */
                this.onLimpaTarefa();
                this.tarefa.oprt_codigo = this.oportunidadeSelecionada.oprt_codigo;
                this.tarefa.fnlgrp_codigo = this.oportunidadeSelecionada.fnlgrp_codigo_atual;
                this.tarefa.trf_descricao = 'ENVIO MSG. SMS';
                this.tarefa.tptrf_codigo = 3;
                this.tarefa.trf_anotacoes = this.mensagem_whatsapp.mensagem;
                this.tarefa.trf_data = moment(new Date).locale("pt-br").tz('America/Cuiaba').format('DD/MM/YYYY');
                this.tarefa.trf_inicio = moment(new Date).locale("pt-br").tz('America/Cuiaba').format('HH:mm');
                this.tarefa.trf_fim = moment(new Date).locale("pt-br").tz('America/Cuiaba').format('HH:mm');
                this.tarefa.trf_situacao = 1;
                
                await this.onIncluirTarefa().then(res => {
                    this.onLimpaTarefa();
                    const numero = this.mensagem_whatsapp.ddi + this.mensagem_whatsapp.telefone.replace(/\D/g,'');
                    const mensagem = this.mensagem_whatsapp.mensagem;

                    this.$awn.success(`SMS enviado com sucesso!`);
                    this.$bvModal.hide('msg-sms');
                });
            }            
        },
        onLimpaMensagemEmail(){
            this.mensagem_email = {
                nome: '',
                email: '',
                assunto: '',
                mensagem: '',
            };
        },
        onMensagemEmail(){
            this.onLimpaMensagemEmail();
            this.mensagem_email.email = this.oportunidadeSelecionada.contato.cont_email;
            this.mensagem_email.nome = this.oportunidadeSelecionada.contato.cont_nome;
            this.mensagem_email.Assunto = 'Contato por Email';
            this.$bvModal.show('msg-email');
        },
        async onEnviarEmail(){
            if (this.oportunidadeSelecionada && this.oportunidadeSelecionada.contato){
                /* INCLUINDO A TAREFA */
                this.onLimpaTarefa();
                this.tarefa.oprt_codigo = this.oportunidadeSelecionada.oprt_codigo;
                this.tarefa.fnlgrp_codigo = this.oportunidadeSelecionada.fnlgrp_codigo_atual;
                this.tarefa.trf_descricao = 'ENVIO EMAIL';
                this.tarefa.tptrf_codigo = 5;
                this.tarefa.trf_anotacoes = this.mensagem_email.mensagem;
                this.tarefa.trf_data = moment(new Date).locale("pt-br").tz('America/Cuiaba').format('DD/MM/YYYY');
                this.tarefa.trf_inicio = moment(new Date).locale("pt-br").tz('America/Cuiaba').format('HH:mm');
                this.tarefa.trf_fim = moment(new Date).locale("pt-br").tz('America/Cuiaba').format('HH:mm');
                this.tarefa.trf_situacao = 1;
                
                await this.onIncluirTarefa().then(async res => {
                    this.onLimpaTarefa();

                    await axios.post(`crmoportunidades/email/${this.oportunidadeSelecionada.oprt_codigo}`,
                    {
                        nome: this.mensagem_email.nome,
                        email: this.mensagem_email.email,
                        assunto: this.mensagem_email.assunto,
                        mensagem: this.mensagem_email.mensagem
                    }
                    ).then(data => {
                        this.$awn.success(`Email enviado com sucesso!`);
                    }).catch(data => {
                        this.$awn.alert(`Erro ao enviar email.`);
                    });

                    this.$bvModal.hide('msg-email');
                });                                
            }
        },
        
    },
    created() {
        //console.log(this.$route.params.codigo);
        this.fnl_codigo = this.$route.params.codigo ? Number(this.$route.params.codigo) : 1;
        this.setContatosPessoas();
        this.setFunil();
        this.setTags();        
    },
    watch: {
        fnl_codigo  () {
            this.setFunil();
        }
    },
    computed: {
        ...mapState('Login',['infoLogado','Entidade','Exercicio','Escola']),
        oprtTags: {
            get: function () {
                if (typeof this.oportunidade.oprt_tags !== 'undefined' && this.oportunidade.oprt_tags !== null && this.oportunidade.oprt_tags !== ''){
                    let req = this.oportunidade.oprt_tags.split(',')
                    let resultado = [];
                    req.forEach(el =>{
                        resultado.push(...this.tags.filter(el2 => el2.tags_tag === el))
                    })
                    return resultado
                }
            },
            set: function (newValue) {
                const res = newValue.map(item => item.tags_tag)
                this.oportunidade.oprt_tags = res.toString()
            }
        },        
        oprtTagsSelecionada: {
            get: function () {
                if (typeof this.oportunidadeSelecionada.oprt_tags !== 'undefined' && this.oportunidadeSelecionada.oprt_tags !== null && this.oportunidadeSelecionada.oprt_tags !== ''){
                    let req = this.oportunidadeSelecionada.oprt_tags.split(',')
                    let resultado = [];
                    req.forEach(el =>{
                        resultado.push(...this.tags.filter(el2 => el2.tags_tag === el))
                    })
                    return resultado
                }
            },
            set: function (newValue) {
                const res = newValue.map(item => item.tags_tag)
                this.oportunidadeSelecionada.oprt_tags = res.toString()
            }
        }        
    },
    filters: {
        stringify(value) {
            return JSON.stringify(value, null, 2)
        }
    },
    sockets: {
        mctatufunil(data) {
            if (data.funil == this.funil.fnl_codigo){
                this.setFunil()
            }
        },
        async mctatuoportunidade(data) {
            const res = this.funil.grupos.map(ele => ele.oportunidades.filter(ele2 => ele2.oprt_codigo == data.oportunidade)).filter(ele => ele.length > 0);
            if (res.length > 0){
                await this.setFunil();
                const res2 = this.funil.grupos.map(ele => ele.oportunidades.filter(ele2 => ele2.oprt_codigo == data.oportunidade)).filter(ele => ele.length > 0);
                this.oportunidadeSelecionada = res2[0][0];
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    *{
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    .funil{
        width: 100%;
        min-height: calc(100vh - 160px);
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        overflow: hidden;           
    }

    .funil > header {
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .funil > header > span {
        min-width: 310px;
        font-size: 1.6rem;
        font-weight: 500;
        color: var(--gray-dark);        
    }

    .funil > main{
        flex: 1;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
        overflow-y: auto;           
    }

    .funil-grupos{
        /*flex: 1;*/
        //height: 100%;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        justify-content: flex-start;
    }

    .grupos {
        min-width: 300px;
        margin-right: 20px;
        margin-bottom: 10px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        -webkit-box-shadow: 2px 2px 5px 0px rgba(194,192,194,1);
        -moz-box-shadow: 2px 2px 5px 0px rgba(194,192,194,1);
        box-shadow: 2px 2px 5px 0px rgba(194,192,194,1);
    }

    .grupo-novo {
        min-width: 300px;
        max-height: 200px;
        margin-right: 20px;
        margin-bottom: 10px;
        border-radius: 8px;
        overflow: hidden;
    }

    .grupo-novo .btn {
        width: 100%;
        height: 100%;
        border: 1px dashed var(--dark);
        border-radius: 8px;
        color: var(--dark);
        background: var(--light);
        font-size: 1rem;
    }

    .grupo-novo .btn:hover {
        opacity: 0.8;
        background: var(--white);
    }

    .grupos .grupo-titulo{
        //height: 60px;
        width: 100%;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        background-color: var(--white);
    }

    .grupos .grupo-titulo > div{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 5px;
        margin: 0;
    }

    .grupos .grupo-titulo > div > span {
        font-size: 1rem;
        font-weight: 500;
        text-align: center;
    }

    .grupos .grupo-titulo h1{
        font-size: 1.1rem;
        font-weight: 500;
        color: var(--gray-dark);
    }

    .grupos .grupo-oportunidades {
        height: 100%;
        width: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: var(--white);
        overflow-y: auto;
    }

    .grupos .grupo-oportunidade {
        width: 100%;
        padding: 10px;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: var(--light);
        border-radius: 4px;
        border-left: 5px solid var(--gray);
        -webkit-box-shadow: 2px 2px 5px 0px rgba(194,192,194,1);
        -moz-box-shadow: 2px 2px 5px 0px rgba(194,192,194,1);
        box-shadow: 2px 2px 5px 0px rgba(194,192,194,1);
    }

    .grupos .grupo-oportunidade > h1 {
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 5px;
    }

    .grupos .grupo-oportunidade > span {
        font-size: 0.9rem;
        font-weight: 500;
        color: var(--gray);
    }

    .grupos .grupo-oportunidade .grupo-oportunidade-tarefas {
        margin: 0;
        padding: 0;
        position: absolute;
        border-radius: 50%;
        top: 40px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .grupos .grupo-oportunidade .grupo-oportunidade-tags {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;        
    }

    .grupos .grupo-oportunidade .grupo-oportunidade-tags span {
        background: var(--blue);
        color: var(--white);
        border-radius: 20px;
        font-size: 0.6rem;
        font-weight: normal;        
        padding: 2px 10px ;
        margin: 4px;
    }

    .grupos .grupo-oportunidade > ul {
        font-size: 0.9rem;
        list-style: none;
        margin: 20px 10px;
    }

    .grupos .grupo-oportunidade > ul li {
        font-size: 0.8rem;
        line-height: 1.5rem;
        display: flex;
        flex: row;
        align-items: center;
        justify-content: flex-start;
    }

    .grupos .grupo-oportunidade > footer {
        width: 100%;
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    /*.grupo-usuario {
        font-size: 0.9rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: var(--gray-dark);
        font-weight: 600;
    }

    .grupo-usuario div{
        width: 24px;
        height: 24px;
        overflow: hidden;
        border-radius: 50%;
        margin-top: -1px;
        margin-right: 5px;
        background-color: var(--white);
    }

    .grupo-usuario div img{
        width: auto;
        height: 100%;
        //margin-left: -50%;
    }*/

    .grupo-dias {
        //color: var(--red)!important;
    }

    .grupo-dias i {
        margin-right: 5px;
        font-size: 1.2rem;
    }

    .grupo-nova-tarefa {
        cursor: pointer;
        color: var(--green)!important;
    }

    .grupo-nova-tarefa i {
        font-size: 1.4rem;
    }

    .grupos .grupo-rodape{
        height: 100px;
        width: 100%;
        background-color: var(--white);
    }

    .dropdown-item {
        min-height: 35px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
    }

    .cursor-move {
        cursor: move;
    }

    .graph {
        text-align: center;
        width: 60px;
        height: 60px;
        line-height: 30px;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 0.9rem;
        font-weight: 500;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .graph svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        fill: transparent;
        stroke-width: 8px;
        stroke: transparent;
    }

    .graph svg.cor {
        stroke: var(--green);
        stroke-dasharray: 159.37;
        transform: rotate(-90deg);
        transform-origin: center;
    }

    $colors: (
        indigo: #6610f2,
        purple: #6f42c1,
        blue: #20a8d8,
        cyan: #17a2b8,
        pink: #e83e8c,
        red: #f86c6b,
        orange: #f8cb00,
        yellow: #ffc107,
        green: #4dbd74,
        teal: #20c997,
        light: #f0f3f5,
        secondary: #c8ced3,
    );

    .radio-color input[type="radio"] {
        display: none;
        &:checked + div + div {
            span { 
                transform: scale(1.25);
            }
            @each $name, $value in $colors {
                [href*='#{"" + $name}'] {
                    border: 2px solid darken($value, 25%);
                }
            } // !@each
        } // !&:checked + label
    }

    .radio-color .button {
        display: inline-block;
        position: relative;
        width: 50px;
        height: 50px;
        margin: 10px;
        cursor: pointer;
        &:hover {
            span { 
                transform: scale(1.25); 
            }
        }
    }

    .radio-color .button span {
        display: block;
        position: absolute;
        width: 50px;
        height: 50px;
        padding: 0;
        //top: 50%;
        //left: 50%;
        /*-webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);*/
        border-radius: 100%;
        background: #eeeeee;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
        transition: ease .3s;
    }

    .radio-color .button span:hover {
        padding: 10px;
    }   

    #editar-oportunidade .grupo-oportunidade-tarefas ul{
        list-style: none;
    }

    #editar-oportunidade  h5 {
        font-size: 0.9rem;
        margin: 10px 0;
    }

    .grupo-oportunidade-interesse {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .oportunidade-mensagens{
        margin: 0 auto; 
        background: var(--light);
        font-size: 1em;
        line-height: 1.6em;  
        border-radius: 4px;
        width: 100%;
        height: auto;
        color: var(--dark);
        padding: 10px;
        position: relative;
        margin-top: 20px;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .oportunidade-mensagens:after{ /*Triangulo*/
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        /*Faz seta "apontar para baixo. Definir o valor como 'top' fará ela "apontar para cima" */
        /*Aqui entra a cor da "aba" do balão */
        border-bottom: 10px solid var(--light);
        top: -8px; /*localização. Experimente alterar para 'bottom'*/
        left: 0px;
    }

    .oportunidade-mensagens-icons {
        padding: 5px 0;
        font-size: 20px;
    }

    .oportunidade-mensagens-icons i {
        margin-right: 10px;
    }

    .oportunidade-mensagens > img {
        position: absolute;
    }
    .oportunidade-mensagens > span {
        font-size: 0.7rem;
        font-weight: 600;
        margin-left: 30px;
        line-height: 14px;
    }

    .oportunidade-mensagens > small {
        font-size: 0.6rem;
        color: var(--gray);
        margin-left: 30px;
        line-height: 14px;
    }

    .oportunidade-mensagens > p {
        font-size: 0.8rem;
        color: var(--dark);
        margin-left: 30px;
        margin-top: 10px;
        line-height: 14px;
        text-align: justify;
    }

    .oportunidade-mensagens > div {
        margin-left: 30px;
        margin-top: 10px;
        width: calc(100% - 30px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .oportunidade-mensagens > div > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
    }

    .oportunidade-mensagens > div > div span {
        font-size: 0.6rem;
        color: var(--gray);
    }

    .oportunidade-mensagens > div > div img {
        margin-left: 2px;
    }

    .avatar-user {
        border-radius: 50%!important;
        border: 1px solid white;
        object-fit: cover;
    }

    .avatar {
        display: inline-block;
        overflow: hidden;
        line-height: 1;
        vertical-align: middle;
        border-radius: 6px;
    }

    /* FILES UPLOAD */

    .grupo-oportunidade-arquivos ul {
        padding: 5px 0px;
        list-style: none;
    }

    .grupo-oportunidade-arquivos ul li{
        background: var(--light);
        padding: 5px;
        margin-top: 5px;
        border-radius: 4px;
        color: var(--dark);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .grupo-oportunidade-arquivos ul li small{
        font-size: 0.6rem;
        color: var(--gray);
        margin-left: 30px;
    }

    input[type="file"]{
        opacity: 0;
        width: 100%;
        height: 150px;
        position: absolute;
        cursor: pointer;
    }
    .filezone {
        outline: 2px dashed grey;
        outline-offset: -10px;
        background: #ccc;
        color: dimgray;
        padding: 10px 10px;
        min-height: 150px;
        position: relative;
        cursor: pointer;
    }
    .filezone:hover {
        background: #c0c0c0;
    }

    .filezone p {
        font-size: 1.2em;
        text-align: center;
        padding: 50px 50px 50px 50px;
    }

    div.file-listing img{
        max-width: 90%;
    }

    div.file-listing{
        margin: auto;
        padding: 10px;
        border-bottom: 1px solid #ddd;
    }

    div.file-listing img{
        height: 50px;
    }

    div.success-container{
        text-align: center;
        color: green;
    }

    div.remove-container{
        text-align: center;
    }

    div.remove-container a{
        color: red;
        cursor: pointer;
    }

    a.submit-button{
        display: block;
        margin: auto;
        text-align: center;
        width: 200px;
        padding: 10px;
        text-transform: uppercase;
        background-color: #CCC;
        color: white;
        font-weight: bold;
        margin-top: 20px;
        cursor: pointer;
    }    

</style>