<template>
<!-- RELATORIOS CONTAS A PAGAR - A PAGAR -->
    <div>
        
        <div class="row mb-4">
            <div class="col-lg-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Turma</legend>
                    <div class="row">                                                        
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_ini" :valor="filtro" titulo="Inicial" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_ini" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_fin" :valor="filtro" titulo="Final" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_fin" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="row mb-4" v-show="infoEscola.esc_tipo !== 1">
            <div class="col-lg-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Agendamento</legend>
                    <div class="row">
                        <div class="col-lg-12">
                            <mct-form-itens tipo="select" campo="agendamento" :valor="filtro" titulo="Agendamento" :errors="{}"
                            origemChave="horari_codigo" origemRetorno='horari_descricao' origemRetornoSec="horari_codigo" origem="esc_horarios" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="row mb-4" v-show="infoEscola.esc_tipo === 1">
            <div class="col-lg-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Etapa</legend>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="select" campo="etap_codigo" :valor="filtro" titulo="Etapa" origem="esc_etapas" origemChave="etap_codigo" origemRetorno='etap_descricao' :errors="{}" modo="editar" obrigatorio="true"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>        
        <div class="row mb-4">
            <div class="col-lg-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Aluno</legend>
                    <div class="row">
                        <div class="col-lg-12">
                            <mct-form-itens tipo="select" campo="aluno" :valor="filtro" titulo="Aluno" :errors="{}"
                            origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec="pes_codigo" origem="esc_pessoas" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>        
        <div class="row mb-4" v-show="infoEscola.esc_tipo !== 1">
            <div class="col-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Agrupar por</legend>
                    <div class="row">
                        <div class="col-lg-12 mb-2">
                            <mct-form-itens tipo="radio" campo="tipo" :valor="filtro" titulo="Modelo" showTitulo="false" :errors="{}" 
                            :origemData="[{value:0,text: 'Agendamento/Turma'}, {value:1,text:'Aluno/Data'}]" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-12 text-left">
                <button type="button" class="btn btn-secondary mr-2" aria-label="Left Align" @click="onListaPresenca()">
                <span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Visualizar</button>     
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/http'

import { mapState } from 'vuex'

import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios'

export default {   
    components: {
        'MctFormItens': MctFormItens,
    },
    mixins: [MctPadraoRelatorios],
    data() {
        return {
            data: [],
            outputPdf: null,
            filtro: {
                turma_ini: null,
                turma_fin: null,
                agrupa_agendamento: null,
                situacao_agendamento: 9,
                agendamento: null,
                assinatura: 0,
                etap_codigo: null,
                aluno: null,
                modelo: 1,
                tipo: 0
            }
        }
    },
    computed: {
        ...mapState('Login',['infoEscola'])
    },
    created () {
        if (this.infoEscola.esc_tipo === 1) {
            this.filtro.tipo = 1;
        }
    },
    methods: {
        async getData() {
            var url = '/listapresenca';

            await axios.get(`/relatorios/siges${url}`,{params:  {
                turmaini: this.filtro.turma_ini,
                turmafin: this.filtro.turma_fin,
                agrupa_agendamento: this.filtro.agrupa_agendamento,
                situacao_agendamento: this.filtro.situacao_agendamento,
                agendamento: this.filtro.agendamento,
                etap_codigo: this.filtro.etap_codigo,
                aluno: this.filtro.aluno,
                tipo: this.filtro.tipo
            },
            paramsSerializer: function (params) {
                return jQuery.param(params)
            }
            })
            .then(response => {
                this.data = response.data.data                    
            })
            .catch(response => {
                console.log(response.message)
            })
            
        },

        async onListaPresenca() {
            if (this.infoEscola.esc_tipo !== 1){
                if (this.filtro.tipo == 0)
                    await this.onListaPresencaTurma();
                else 
                await this.onListaPresencaAluno();
            } else {
                await this.onListaPresencaAlunoSerie();
            }
        },

        async onListaPresencaTurma () {

            await this.getData();

            await this.onCreatePDF('L')
            /*CABEÇALHO*/
            this.title = '';
            this.onHeaderPDF();
            this.pdf.setFont('helvetica') 
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)     
                    
            var grupo = '';

            for (var index in this.data) {
                if (grupo !== this.data[index].turm_codigo+'|'+this.data[index].disp_codigo){
                    if (grupo !== '')
                       this.onSaltarPagina2();
                    grupo = this.data[index].turm_codigo+'|'+this.data[index].disp_codigo
                    //this.pdf.rect(8,this.lin-3,195,this.jump+1,'FD')
                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','normal')
                    
                    //this.pdf.setLineWidth(1);
                    this.pdf.rect(8,this.lin-3,this.widthPage,5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Agendamento:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].horari_descricao),32,this.lin)        
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Início:',80,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.$options.filters.data(this.data[index].horari_data_inicial)),90,this.lin)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Fim:',110,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.$options.filters.data(this.data[index].horari_data_final)),122,this.lin)
                    this.onAddLine(this.jump+2)

                    this.pdf.rect(8,this.lin-3,this.widthPage,5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Professor(a):',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].pes_nome),32,this.lin)        

                    this.pdf.rect(108,this.lin-3,0,5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Curso:',110,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(this.data[index].cur_nome,122,this.lin)
                    this.onAddLine(this.jump+2)

                    this.pdf.rect(8,this.lin-3,this.widthPage,5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Disciplina:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].disp_nome),32,this.lin)        
                    
                    this.pdf.rect(108,this.lin-3,0,5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Módulo:',110,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(this.data[index].ser_descricao,122,this.lin)

                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Turma:',180,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].turm_codigo),192,this.lin)


                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Identificação:',202,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].turm_identificacao),220,this.lin)
                    
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Turno:',250,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(this.data[index].turn_descricao,262,this.lin)

                    this.onAddLine(this.jump+4)
                    this.pdf.rect(8,this.lin-3,this.widthPage,12)
                    this.pdf.text('Registro Aluno',10,this.lin+4);
                    this.pdf.rect(28,(this.lin-3),0,12)
                    this.pdf.text('Nº.',30,this.lin+4);
                    this.pdf.rect(35,(this.lin-3),0,12)
                    this.pdf.text('Aluno',37,this.lin+4);
                    this.pdf.rect(100,(this.lin-3),0,12)                    
                    for (let idx = 1; idx < 46; idx++) {
                        this.pdf.rect((100+(3*idx)),(this.lin-3),0,12)                        
                    }

                    const horariosdiadisciplinas = this.data[index].horariosdiadisciplinas.filter(el => el.disp_codigo = this.data[index].disp_codigo);
                    for (let idx = 0; idx < horariosdiadisciplinas.length; idx++) {
                        this.pdf.setFontSize(6)
                        this.pdf.setFont('helvetica','normal')
                        this.pdf.text(String(this.$options.filters.data(horariosdiadisciplinas[idx].horaridia_data)),(102.5+(3*idx)),this.lin+8,null,90)
                    }

                    this.pdf.text('Avaliações',255,this.lin+4,{align: 'center'});
                    this.pdf.rect(275,(this.lin-3),0,12)
                    this.pdf.text('Total',279,this.lin+2,{align: 'center'});
                    this.pdf.text('Faltas',279,this.lin+5,{align: 'center'});
                    this.onAddLine(this.jump+9)

                    for (var index2 in this.data[index].matriculas) {
                        this.pdf.setFontSize(6)
                        this.pdf.setFont('helvetica','normal')
                        if(index2 % 2 === 0){
                            this.pdf.setFillColor(232,232,232);
                        }else{
                            this.pdf.setFillColor(255,255,255);
                        }
                        this.pdf.rect(8,this.lin-3,this.widthPage,5,'FD')
                        this.pdf.text(String(this.data[index].matriculas[index2].matri_codigo),10,this.lin)
                        this.pdf.rect(28,(this.lin-3),0,5)
                        this.pdf.text(String(this.data[index].matriculas[index2].matri_numero),31.5,this.lin,{align: 'center'})
                        this.pdf.rect(35,(this.lin-3),0,5)
                        this.pdf.text(String(this.data[index].matriculas[index2].pes_nome),37,this.lin)
                        this.pdf.rect(100,(this.lin-3),0,5)
                        
                        for (let idx = 1; idx < 46; idx++) {
                            this.pdf.rect((100+(3*idx)),(this.lin-3),0,5)                        
                        }
                        /** PRESENCAS AQUI */
                        for (let idx = 0; idx < horariosdiadisciplinas.length; idx++) {                            
                            const frequencias = this.data[index].frequencias.filter(ele => 
                                ele.alufreq_alu_codigo === this.data[index].matriculas[index2].matri_alu_codigo &&
                                ele.alufreq_data === horariosdiadisciplinas[idx].horaridia_data &&
                                //ele.alufreq_aula === horariosdiadisciplinas[idx].horaridiadisp_aula &&
                                ele.alufreq_disp_codigo === horariosdiadisciplinas[idx].disp_codigo
                                );
                            if (frequencias && frequencias[0]){
                                this.pdf.setFontSize(10)
                                this.pdf.setFont('helvetica','bold')
                                this.pdf.text(String('*'),(101+(3*idx)),(this.lin+1),null,5)
                            } else {
                                this.pdf.setFontSize(8)
                                this.pdf.setFont('helvetica','bold')
                                this.pdf.text(String('F'),(101+(3*idx)),(this.lin+1),null,5)
                            }
                        }
                        /** FIM PRESENCAS */

                        this.pdf.rect(275,(this.lin-3),0,5)
                        this.onAddLine(this.jump+2)                        
                    }  
                
                }
            }
                                               
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            const urlPDF = this.pdf.output('blob');
            this.outputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
            this.$emit('update:outputPdf', this.outputPdf);
        },

        async onListaPresencaAluno () {

            await this.getData();

            await this.onCreatePDF('P')
            /*CABEÇALHO*/
            this.title = '';
            this.onHeaderPDF();
            this.pdf.setFont('helvetica') 
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)     
                    
            var grupo = '';
            var grupo2 = '';
            var grupo3 = '';
            var totalAulas = 0;
            var totalPresencas = 0;

            var coluna = 0;

            for (var index in this.data) {
                if (grupo !== this.data[index].alu_codigo){
                    if (grupo !== ''){
                        this.onAddLine(this.jump+2)
                        this.pdf.rect(8,this.lin-3,this.widthPage,10)
                        this.pdf.setFont('helvetica','bold')
                        this.pdf.text('Total de Aulas:',10,this.lin)
                        this.pdf.setFont('helvetica','normal')
                        this.pdf.text(String(totalAulas),40,this.lin)        
                        this.onAddLine(this.jump)
                        this.pdf.setFont('helvetica','bold')
                        this.pdf.text('Total de Presenças:',10,this.lin)
                        this.pdf.setFont('helvetica','normal')
                        this.pdf.text(String(totalPresencas),40,this.lin)        
                        this.onAddLine(this.jump)
                        this.pdf.setFont('helvetica','bold')
                        this.pdf.text('Total de Ausências:',10,this.lin)
                        this.pdf.setFont('helvetica','normal')
                        this.pdf.text(String(totalAulas-totalPresencas),40,this.lin)        
                        totalAulas = 0;
                        totalPresencas = 0;
       
                        this.onSaltarPagina2();
                    }
                    grupo = this.data[index].alu_codigo
                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','normal')
                    
                    this.pdf.rect(8,this.lin-3,this.widthPage,7)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Código Aluno:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].alu_codigo),32,this.lin)        

                    this.onAddLine(this.jump)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Aluno:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].alu_nome),32,this.lin)
                    this.onAddLine(this.jump)
                    grupo2 = '';
                }
                if (grupo2 !== this.data[index].horari_codigo){
                    grupo2 = this.data[index].horari_codigo
                    this.onAddLine(this.jump)
                    this.pdf.rect(8,this.lin-3,this.widthPage,7)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Agendamento:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].horari_codigo+' - CURSO: '+ this.data[index].cur_nome),32,this.lin)        
                    this.onAddLine(this.jump)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Módulo:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].ser_descricao+' - TURMA: '+this.data[index].turm_identificacao+' - TURNO: '+this.data[index].turn_descricao),32,this.lin)        
                    this.onAddLine(this.jump+2)
                    grupo3 = '';
                }
                if (grupo3 !== this.data[index].disp_codigo){
                    grupo3 = this.data[index].disp_codigo
                    if (grupo3 !== '') {
                        this.onAddLine(this.jump+1)
                    }
                    this.pdf.rect(8,this.lin-3,this.widthPage,7)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Disciplina:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].disp_codigo+' - '+ this.data[index].disp_nome),32,this.lin)                       
                    this.onAddLine(this.jump)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Professor:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].pes_codigo+' - '+ this.data[index].pes_nome),32,this.lin)   
                    this.onAddLine(this.jump+2)                    
                    coluna = 0;
                }
                this.pdf.rect(8+(19.5*coluna),this.lin-3,19.5,13)
                this.pdf.setFont('helvetica','bold')
                this.pdf.text(String(this.$options.filters.data(this.data[index].horaridia_data)),((19.5*(coluna+1))-2),this.lin,{align: 'center'})

                this.pdf.setFont('helvetica','normal')
                this.pdf.text(String(this.$options.filters.horaminstr(this.data[index].turnhor_hora_ini)),((19.5*(coluna+1))-2),this.lin+3,{align: 'center'})        

                this.pdf.setFont('helvetica','normal')
                this.pdf.text(String('AULA '+this.data[index].turnhor_aula),((19.5*(coluna+1))-2),this.lin+6,{align: 'center'})        
                totalAulas++;
                if (this.data[index].alufreq_codigo > 0){
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('PRESENTE',((19.5*(coluna+1))-2),this.lin+9,{align: 'center'})
                    totalPresencas++;
                } else{
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text('AUSENTE',((19.5*(coluna+1))-2),this.lin+9,{align: 'center'})
                }
                coluna++;
                //console.log(this.data.length, Number(index), Number(index)+1)

                if ((coluna >= 10) || ((grupo !== this.data[Number(index)+1 >= this.data.length-1 ? Number(index) : Number(index)+1].alu_codigo) 
                                   || (grupo2 !== this.data[Number(index)+1 >= this.data.length-1 ? Number(index) : Number(index)+1].horari_codigo) 
                                   || (grupo3 !== this.data[Number(index)+1 >= this.data.length-1 ? Number(index) : Number(index)+1].disp_codigo))
                                   || (Number(index)+1 === this.data.length-1)) {
                    coluna = 0;
                    this.onAddLine(this.jump+10)
                }

                if (Number(index) === this.data.length-1){
                    this.onAddLine(this.jump+12)
                    this.pdf.rect(8,this.lin-3,this.widthPage,10)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Total de Aulas:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(totalAulas),40,this.lin)        
                    this.onAddLine(this.jump)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Total de Presenças:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(totalPresencas),40,this.lin)        
                    this.onAddLine(this.jump)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Total de Ausências:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(totalAulas-totalPresencas),40,this.lin)        
                    totalAulas = 0;
                    totalPresencas = 0;
                }
            }
                                               
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            const urlPDF = this.pdf.output('blob');
            this.outputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
            this.$emit('update:outputPdf', this.outputPdf);
        }, 

        async onListaPresencaAlunoSerie () {

            await this.getData();

            await this.onCreatePDF('P')
            /*CABEÇALHO*/
            this.title = '';
            this.onHeaderPDF();
            this.pdf.setFont('helvetica') 
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)     
                    
            var grupo = '';
            var grupo2 = '';
            var grupo3 = '';
            var grupo4 = '';
            var totalAulas = 0;
            var totalPresencas = 0;

            var coluna = 0;

            for (var index in this.data) {
                if (grupo !== this.data[index].alu_codigo){
                    if (grupo !== ''){
                        this.onAddLine(this.jump+2)
                        this.pdf.rect(8,this.lin-3,this.widthPage,10)
                        this.pdf.setFont('helvetica','bold')
                        this.pdf.text('Total de Aulas:',10,this.lin)
                        this.pdf.setFont('helvetica','normal')
                        this.pdf.text(String(totalAulas),40,this.lin)        
                        this.onAddLine(this.jump)
                        this.pdf.setFont('helvetica','bold')
                        this.pdf.text('Total de Presenças:',10,this.lin)
                        this.pdf.setFont('helvetica','normal')
                        this.pdf.text(String(totalPresencas),40,this.lin)        
                        this.onAddLine(this.jump)
                        this.pdf.setFont('helvetica','bold')
                        this.pdf.text('Total de Ausências:',10,this.lin)
                        this.pdf.setFont('helvetica','normal')
                        this.pdf.text(String(totalAulas-totalPresencas),40,this.lin)        
                        totalAulas = 0;
                        totalPresencas = 0;
       
                        this.onSaltarPagina2();
                    }
                    grupo = this.data[index].alu_codigo
                    this.pdf.setFontSize(7.5)
                    this.pdf.setFont('helvetica','normal')
                    
                    this.pdf.rect(8,this.lin-3,this.widthPage,7)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Código Aluno:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].alu_codigo),32,this.lin)        

                    this.onAddLine(this.jump)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Aluno:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].alu_nome),32,this.lin)
                    this.onAddLine(this.jump)
                    grupo2 = '';
                }
                if (grupo2 !== this.data[index].turm_codigo){
                    grupo2 = this.data[index].turm_codigo
                    this.onAddLine(this.jump)
                    this.pdf.rect(8,this.lin-3,this.widthPage,7)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Curso:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].cur_nome),32,this.lin)        
                    this.onAddLine(this.jump)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Turma:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].ser_descricao+' - IDENTIFICAÇÃO: '+this.data[index].turm_identificacao+' - TURNO: '+this.data[index].turn_descricao),32,this.lin)        
                    this.onAddLine(this.jump+2)
                    grupo3 = '';
                }
                if (grupo3 !== this.data[index].disp_codigo){
                    grupo3 = this.data[index].disp_codigo
                    this.pdf.rect(8,this.lin-3,this.widthPage,5)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('Disciplina:',10,this.lin)
                    this.pdf.setFont('helvetica','normal')
                    this.pdf.text(String(this.data[index].disp_codigo+' - '+ this.data[index].disp_nome),32,this.lin)        
                    this.onAddLine(this.jump+2)
                    grupo4 = '';
                }

                this.data[index].etapas.forEach(etapa => {

                    if (grupo4 !== etapa.etap_codigo){
                        grupo4 = etapa.etap_codigo
                        this.pdf.rect(8,this.lin-3,this.widthPage,5)
                        this.pdf.setFont('helvetica','bold')
                        this.pdf.text('Etapa:',10,this.lin)
                        this.pdf.setFont('helvetica','normal')
                        this.pdf.text(String(etapa.etap_descricao),32,this.lin)        
                        this.onAddLine(this.jump+2)
                        coluna = 0;
                    }

                    etapa.diasAula.forEach((aula, indexAulas) => {

                        
                        this.pdf.rect(8+(19.5*coluna),this.lin-3,19.5,10)
                        this.pdf.setFont('helvetica','bold')
                        this.pdf.text(String(this.$options.filters.data(aula.horaridia_data)),((19.5*(coluna+1))-2),this.lin,{align: 'center'})
        
                        this.pdf.setFont('helvetica','normal')
                        this.pdf.text(String('AULA '+ aula.aula),((19.5*(coluna+1))-2),this.lin+3,{align: 'center'})        
                        totalAulas++;

                        const EsteveNaAula = etapa.frequencias.filter(frequencia => {
                            return frequencia.alufreq_data === aula.horaridia_data && 
                            frequencia.alufreq_aula === aula.aula
                        })
                        //console.log(EsteveNaAula);
                        
                        if (EsteveNaAula && EsteveNaAula.length > 0){
                            this.pdf.setFont('helvetica','bold')
                            this.pdf.text('PRESENTE',((19.5*(coluna+1))-2),this.lin+6,{align: 'center'})
                            totalPresencas++;
                        } else{
                            this.pdf.setFont('helvetica','normal')
                            this.pdf.text('AUSENTE',((19.5*(coluna+1))-2),this.lin+6,{align: 'center'})
                        }
                         
                        coluna++;
                        if ((coluna >= 10) || (Number(indexAulas)+1 >= etapa.diasAula.length)) {
                            coluna = 0;
                            this.onAddLine(this.jump+7)
                        }                        
    
                    });                    

                    if (Number(index) === this.data.length-1){
                        this.onAddLine(this.jump+12)
                        this.pdf.rect(8,this.lin-3,this.widthPage,10)
                        this.pdf.setFont('helvetica','bold')
                        this.pdf.text('Total de Aulas:',10,this.lin)
                        this.pdf.setFont('helvetica','normal')
                        this.pdf.text(String(totalAulas),40,this.lin)        
                        this.onAddLine(this.jump)
                        this.pdf.setFont('helvetica','bold')
                        this.pdf.text('Total de Presenças:',10,this.lin)
                        this.pdf.setFont('helvetica','normal')
                        this.pdf.text(String(totalPresencas),40,this.lin)        
                        this.onAddLine(this.jump)
                        this.pdf.setFont('helvetica','bold')
                        this.pdf.text('Total de Ausências:',10,this.lin)
                        this.pdf.setFont('helvetica','normal')
                        this.pdf.text(String(totalAulas-totalPresencas),40,this.lin)        
                        totalAulas = 0;
                        totalPresencas = 0;
                    }

                
                });
            }
                                               
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            const urlPDF = this.pdf.output('blob');
            this.outputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
            this.$emit('update:outputPdf', this.outputPdf);
        },        
    }
}
</script>