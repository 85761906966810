<script>
import { Doughnut } from 'vue-chartjs'

export default {
  name: 'GraficoCircularFinanceiro',  
  props: ['data', 'labels', 'colors'],
  extends: Doughnut,
  data () {
      return {
          
      }
  },
  watch:{
      data () {
        this.renderChart({          
            labels: this.labels,
            datasets: [
                {
                backgroundColor: this.colors,
                data: this.data,
                borderWidth: 1
                }
            ]
            }, {
                legend: { display: false }, 
                responsive: true, 
                maintainAspectRatio: false,
                /*tooltips: {
                    callbacks: {
                        label: tooltipItem => `${tooltipItem.yLabel}: ${tooltipItem.xLabel}`, 
                        title: () => null,
                    }
                },*/
                weight: 1 ,
                animation: {
                    animateRotate: true,
                    animateScale: true
                },           
                })
        }
  }
}
</script>
