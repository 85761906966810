<template>
  <mct-padrao :titulo="titulo" :modo="modo" botoes="false">
    <div slot="formulario">
        <div class="row">
            <div class="col-sm-12 text-center">
                <div class="btn-group btn-group-toggle">
                    <label class="btn" :class="filtro.tipo == 'R' ? 'btn-success' : 'btn-light'" style="width: 150px;">
                        <input type="radio" name="options" autocomplete="off" id="option1" value="R" v-model="filtro.tipo"> Contas à Receber
                    </label>
                    <label class="btn" :class="filtro.tipo == 'P' ? 'btn-danger' : 'btn-light'" style="width: 150px;">
                        <input type="radio" name="options" autocomplete="off" id="option2" value="P" v-model="filtro.tipo"> Contas à Pagar
                    </label>
                    <label class="btn" :class="filtro.tipo == 'A' ? 'btn-info' : 'btn-light'" style="width: 150px;">
                        <input type="radio" name="options" autocomplete="off" id="option3" value="A" v-model="filtro.tipo"> Ambas
                    </label>
                </div>   
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <div class="row" v-if="filtro.tipo == 'R' || filtro.tipo == 'A'">
                    <div class="col-sm-12">
                        <mct-form-itens tipo="search" campo="resp_codigo" :valor="filtro" titulo="Responsável" obrigatorio="false" tamanho="2" origem="esc_pessoas" 
                            origemChave="pes_codigo" origemRetorno='pes_nome' :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <mct-form-itens tipo="search" campo="pes_codigo" :valor="filtro" titulo="Pessoa (Cliente / Fornecedor)" obrigatorio="false" tamanho="2" origem="esc_pessoas" 
                            origemChave="pes_codigo" origemRetorno='pes_nome' :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                </div>
                <div class="row pt-2">
                    <div class="col-sm-12 text-right">
                        <button type="button" class="btn btn-info" aria-label="Left Align"  @click.prevent.stop="getData()">
                            <span class="fa fa-filter fa-lg" aria-hidden="true"></span> Filtrar </button>
                    </div>
                </div>
            </div>

            <div class="col-sm-3">
                <fieldset class="well">
                    <legend class="well-legend">Data Emissão</legend>
                    <div class="row">
                        <div class="col-sm-12">
                            <mct-form-itens tipo="date" campo="emi_inicial" :valor="filtro" titulo="Inicial" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <mct-form-itens tipo="date" campo="emi_final" :valor="filtro" titulo="Final" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>
                </fieldset>
            </div>
            <div class="col-sm-3">
                <fieldset class="well">
                    <legend class="well-legend">Data Vencimento</legend>
                    <div class="row">
                        <div class="col-sm-12">
                            <mct-form-itens tipo="date" campo="venc_inicial" :valor="filtro" titulo="Inicial" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <mct-form-itens tipo="date" campo="venc_final" :valor="filtro" titulo="Final" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>
                </fieldset>
            </div>            
        </div>

        <ul id="myTab" class="nav nav-tabs" role="tablist">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="tab" href="#parcela" role="tab">Parcelas</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" data-toggle="tab" href="#quitacoes" role="tab">Quitações</a>
            </li>
        </ul>
        <!-- TABS -->
        <div class="tab-content">
            <div class="tab-pane active" id="parcela" role="tabpanel">                
                <div class="mct-grid">
                    <div class="mct-grid-scroll-sec">                        
                        <table class="table table-sm table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 50px;" class="text-center">Seleção</th>
                                    <th scope="col" style="width: 80px;" class="text-right">Número</th>
                                    <th scope="col" class="text-left">Pessoa</th>
                                    <th scope="col" style="width: 100px;" class="text-right">Valor Doc.</th>
                                    <th scope="col" style="width: 80px;" class="text-right">Dt. Emissão</th>
                                    <th scope="col" style="width: 80px;" class="text-right">Vencimento</th>
                                    <th scope="col" style="width: 80px;" class="text-right">Vlr. Multa</th>
                                    <th scope="col" style="width: 80px;" class="text-right">Vlr. Juros</th>
                                    <th scope="col" style="width: 100px;" class="text-right">Valor a Pagar</th>
                                </tr>
                            </thead>
                            <tbody>                                
                                <tr v-for="(linha,index) in lista" :key="index" :style="linha.doc_tipo == 2 ? 'border-left: 8px solid #379A7B!important;': 'border-left: 8px solid #F86C6B!important;'">
                                    <th class="text-center">
                                        <mct-form-itens tipo="checkbox" campo="doc_selecionado" :valor="linha" :errors="errors" :modo="modo"></mct-form-itens>
                                    </th>
                                    <td class="text-right">{{linha.docp_numero}}</td>
                                    <td class="text-left">{{linha.pes_nome}}</td>
                                    <td class="text-right">{{linha.docp_valor | moeda}}</td>
                                    <td class="text-right">{{linha.doc_emissao | data}}</td>
                                    <td class="text-right">{{linha.docp_vencimento | data}}</td>
                                    <td class="text-right">{{linha.docp_vlr_multa | moeda}}</td>
                                    <td class="text-right">{{linha.docp_vlr_juros | moeda}}</td>
                                    <td class="text-right">{{linha.docp_vlr_apagar | moeda}}</td>
                                </tr>
                                <tr  v-if="typeof lista === 'undefined' || lista.length === 0 || Object.keys(lista).length === 0">
                                    <td colspan="9" class="text-info" style="text-align: center; font-size: 1.2rem;">O FILTRO NÃO RETORNOU REGISTROS</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>                                    
            </div>
            <div class="tab-pane" id="quitacoes" role="tabpanel">
                <div class="row p-0 m-0 mb-2 mct-row-itens">
                    <div class="col-sm-2 pl-0 pr-2">
                        <mct-form-itens tipo="select" campo="docp_codigo" :valor="quitacao" titulo="Parcela" 
                            :origemData="parcelasSelecionadas" origemChave="docp_codigo" origemRetorno='docp_numero' 
                            obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-sm-2 pl-0 pr-2">
                        <mct-form-itens tipo="currency" campo="docp_valor" :valor="quitacao" titulo="Valor Parc." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-sm-2 pl-0 pr-2">
                        <mct-form-itens tipo="date" campo="doc_data" :valor="quitacao" titulo="Data Quitação" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-sm-1 pl-0 pr-2">
                        <mct-form-itens tipo="currency" campo="doc_vlr_multa" :valor="quitacao" titulo="Valor Multa" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-sm-1 pl-0 pr-2">
                        <mct-form-itens tipo="currency" campo="doc_vlr_juros" :valor="quitacao" titulo="Valor Juros" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-sm-1 pl-0 pr-2">
                        <mct-form-itens tipo="currency" campo="doc_vlr_desconto" :valor="quitacao" titulo="Valor Desc." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-sm-1 pl-0 pr-2">
                        <mct-form-itens tipo="currency" campo="doc_vlr_tarifas" :valor="quitacao" titulo="Valor Acrés." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-sm-2 pl-0 pr-2">
                        <mct-form-itens tipo="currency" campo="doc_valor" :valor="quitacao" titulo="Valor Pago" obrigatorio="true" :errors="errors" modo="readonly"></mct-form-itens>    
                    </div>
                    <div class="col-sm-3 pl-0 pr-2">
                        <mct-form-itens tipo="select" campo="tqui_codigo" :valor="quitacao" titulo="Tipo Quitação" 
                            origem="esc_tp_quitacao" origemChave="tqui_codigo" origemRetorno='tqui_descricao' 
                            :origemFiltro="{tqui_status: 1}" @setDataLista="setTpQuitacao" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-sm-2 pl-0 pr-2">
                        <mct-form-itens tipo="select" campo="ccst_codigo" :valor="quitacao" titulo="Centro Custo" 
                            :origemData="ccustoSelecionada" origemChave="ccst_codigo" origemRetorno='ccst_descricao' 
                            obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-sm-2 pl-0 pr-2">
                        <mct-form-itens tipo="select" campo="tp_lancamento" :valor="quitacao" titulo="Tipo Lançamento" :origemData="[{value: 'CAIXA', text: 'CAIXA'}, {value: 'BANCO', text: 'BANCO'}]" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-sm-3 pl-0 pr-2" v-if="quitacao.tp_lancamento == 'BANCO'">
                        <mct-form-itens tipo="select" campo="cbnc_codigo" :valor="quitacao" titulo="Conta Bancária" 
                            origem="esc_conta_bancaria" origemChave="cbnc_codigo" origemRetorno='cbnc_descricao' 
                            :origemFiltro="{cbnc_status: 1, cbnc_ent: this.Entidade}" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                    <div class="col-sm-3 pl-0 pr-2" v-else>
                        <mct-form-itens tipo="select" campo="cbnc_codigo" :valor="quitacao" titulo="Conta Caixa" 
                            origem="esc_caixa" origemChave="cxa_codigo" origemRetorno='cxa_descricao' 
                            :origemFiltro="{cxa_status: 1, cxa_ent: this.Entidade}" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                     <div class="mt-4">
                        <button type="button" class="btn bg-success text-white" aria-label="Left Align" @click.prevent.stop="onAdcQuitacao()">
                            <span class="fa fa-check text-white" aria-hidden="true"></span> Incluir Quitação </button>
                    </div>
                    <div class="row col-12 p-0 m-0 mt-2 mct-row-itens" v-if="tpQuitacaoSelecionada.tqui_descricao === 'CHEQUE'">
                        <div class="col-sm-2 pl-0 pr-2">
                            <mct-form-itens tipo="text" campo="docp_ch_numero" :valor="quitacao" titulo="Número Ch." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>                    
                        <div class="col-sm-2 pl-0 pr-2">
                            <mct-form-itens tipo="date" campo="docp_ch_vencimento" :valor="quitacao" titulo="Vcto Cheque" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>     
                        <div class="col-sm-3 pl-0 pr-2">
                            <mct-form-itens tipo="select" campo="docp_ch_banco" :valor="quitacao" titulo="Banco" 
                                origem="banco" origemChave="bnc_codigo" origemRetorno='bnc_descricao' obrigatorio="true" :errors="errors" :modo="parcelaSelecionada.doc_tipo_conta !== 'P' ? modo : 'readonly'"></mct-form-itens>  
                        </div>                    
                        <div class="col-sm-1 pl-0 pr-2">
                            <mct-form-itens tipo="text" campo="docp_ch_agencia" :valor="quitacao" titulo="Agência" obrigatorio="true" :errors="errors" :modo="parcelaSelecionada.doc_tipo_conta !== 'P' ? modo : 'readonly'"></mct-form-itens>    
                        </div>                    
                        <div class="col-sm-2 pl-0 pr-2">
                            <mct-form-itens tipo="text" campo="docp_ch_conta" :valor="quitacao" titulo="Conta Corrente" obrigatorio="true" :errors="errors" :modo="parcelaSelecionada.doc_tipo_conta !== 'P' ? modo : 'readonly'"></mct-form-itens>    
                        </div>                    
                
                        <div class="col-sm-2 pl-0 pr-2">
                            <mct-form-itens tipo="currency" campo="docp_ch_valor" :valor="quitacao" titulo="Valor Cheque" obrigatorio="true" :errors="errors" :modo="parcelaSelecionada.doc_tipo_conta !== 'P' ? modo : 'readonly'"></mct-form-itens>    
                        </div>
                    </div>
                   
                </div>
                                    
                <div class="mct-grid">
                    <div class="mct-grid-scroll-sec">                        
                        <table class="table table-sm table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" v-if="modo == 'editar' || modo == 'novo'" style="width: 30px;"></th>
                                    <th scope="col" style="width: 60px;" class="text-center">Parcela</th>
                                    <th scope="col" style="width: 80px;" class="text-right">Número</th>
                                    <th scope="col" style="width: 100px;" class="text-right">Valor</th>
                                    <th scope="col" style="width: 100px;" class="text-right">Tarifas</th>
                                    <th scope="col" style="width: 80px;" class="text-right">Multa</th>
                                    <th scope="col" style="width: 80px;" class="text-right">Juros</th>
                                    <th scope="col" style="width: 80px;" class="text-right">Desconto</th>
                                    <th scope="col" style="width: 100px;" class="text-right">Valor Pago</th>
                                    <th scope="col" style="width: 80px;" class="text-right">Dt. Pgto</th>
                                    <th scope="col" style="width: 60px;" class="text-left">Tp. Quitação</th>
                                    <th scope="col" style="width: 60px;" class="text-left">Tp. Lançamento</th>
                                </tr>
                            </thead>
                            <tbody>                                
                                <tr v-for="(linha,index) in quitacoes" :key="index" :style="linha.doc_tipo == 2 ? 'border-left: 8px solid #379A7B!important;': 'border-left: 8px solid #F86C6B!important;'">
                                    <th scope="row" v-if="modo == 'editar' || modo == 'novo'">
                                        <div class="is-icon">
                                            <i class="fa fa fa-times text-white bg-danger mct-fa" aria-hidden="true" title="Remover quitacao" @click="onCancQuitacao(index)"></i>
                                        </div>
                                    </th>
                                    <td class="text-center">{{linha.docp_parcela}}</td>
                                    <td class="text-right">{{linha.docp_numero}}</td>
                                    <td class="text-right">{{linha.docp_valor | moeda}}</td>
                                    <td class="text-right">{{linha.doc_vlr_tarifas | moeda}}</td>
                                    <td class="text-right">{{linha.doc_vlr_multa | moeda}}</td>
                                    <td class="text-right">{{linha.doc_vlr_juros | moeda}}</td>
                                    <td class="text-right">{{linha.doc_vlr_desconto | moeda}}</td>
                                    <td class="text-right">{{linha.doc_valor | moeda}}</td>
                                    <td class="text-right">{{linha.doc_data | data}}</td>
                                    <td class="text-left">{{linha.tqui_descricao}}</td>
                                    <td class="text-left">
                                        <i class="fa fa-money text-success" aria-hidden="true" v-if="linha.tp_lancamento == 'CAIXA'"></i>
                                        <i class="fa fa-university text-info" aria-hidden="true" v-if="linha.tp_lancamento == 'BANCO'"></i> {{linha.tp_lancamento}}</td>
                                </tr>
                                <tr  v-if="typeof quitacoes === 'undefined' || quitacoes.length === 0 ">
                                    <td colspan="12" class="text-info" style="text-align: center; font-size: 1.2rem;">NÃO EXISTE QUITAÇÕES LANÇADAS</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="row p-0 m-0 mt-2 mct-row-itens">
                    <div style="width: 180px" class="bg-danger m-auto text-center aling-middle">
                        <i class="fa fa-usd" aria-hidden="true"></i>
                        <div>
                            <h1>Total Parc. Sel.</h1>
                            {{somaParcelasSel | moeda}}
                        </div>
                    </div>
                    <div style="width: 180px;" class="bg-primary m-auto text-center">
                        <i class="fa fa-usd" aria-hidden="true"></i>
                        <div>
                            <h1>Multa+Juros+Acrésc.</h1>
                            {{somaMJASel | moeda}}
                        </div>
                    </div>
                    <div style="width: 180px;" class="bg-info m-auto text-center">
                        <i class="fa fa-usd" aria-hidden="true"></i>
                        <div>
                            <h1>Desconto</h1>
                            {{somaDescontosSel | moeda}}
                        </div>
                    </div>
                    <div style="width: 180px;" class="bg-success m-auto text-center">
                        <i class="fa fa-usd" aria-hidden="true"></i>
                        <div>
                            <h1>Total Pago</h1>
                            {{somaPagoSel | moeda}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="btn-group mt-2" role="group" aria-label="Ações">
            <button type="button" class="btn btn-success text-white" aria-label="Left Align" @click.prevent.stop="onSubmit($event)">
                    <span class="fa fa-check text-white" aria-hidden="true"></span> Gravar Quitações </button>
            <button type="button" class="btn btn-dark text-white" aria-label="Left Align" @click.prevent.stop="$router.back()">
                    <span class="fa fa-times text-white" aria-hidden="true"></span> Fechar </button>
        </div>     
    </div>
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import MctPadrao from '@/components/comum/MctFormPadraoSCB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctModal from '@/components/comum/MctModal.vue'

export default {
  Name: 'Quitacao',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      MctModal,
  },
  data () {
      return {
        filtro: {
            tipo: 'A',
            pes_codigo: null,
            resp_codigo: null,
            emi_inicial: null,
            emi_final: null,
            venc_inicial: null,
            venc_final: null,
            },  
        lista: {},               
        errors:{},
        message: '',
        status: 0,
        quitacao: {
            doc_tipo_conta: null,
            docp_codigo: null,
            docp_parcela: null,
            docp_numero: null,
            docp_valor: 0.00,
            doc_codigo: null,
            lanc_codigo: null,
            doc_tipo: 2,/*1-DEB, 2-CRED */
            doc_data: this.moment(new Date()).format('YYYY-MM-DD'),
            doc_data_credito: this.moment(new Date()).format('YYYY-MM-DD'),
            cbnc_codigo: null,
            cbnc_ent: null,
            doc_vlr_multa: 0.00,
            doc_vlr_juros: 0.00,
            doc_vlr_desconto: 0.00,
            doc_vlr_tarifas: 0.00,
            doc_valor: 0.00,
            ccst_codigo: null,
            doc_estorno: 0,
            tp_lancamento: null,
            tqui_codigo: null,
            tqui_descricao: null,
            docp_ch_numero: null,
            docp_ch_vencimento: null,
            docp_ch_banco: null,
            docp_ch_agencia: null,
            docp_ch_conta: null,
            docp_ch_valor: null,
            pes_codigo: null,
            
        },
        quitacoes: [],
        tpQuitacao: [],
        tpQuitacaoSelecionada: {},
        ccustoSelecionada: {},
        parcelaSelecionada: {} 

      }
  },
  methods: {    
    async getData() {
        let URL = '/escfinanceiro/lista'
        URL += '?tipo=' + this.filtro.tipo

        if (this.filtro.pes_codigo != null && this.filtro.pes_codigo > 0){
            URL += '&pessoa=' + this.filtro.pes_codigo
        }
        if (this.filtro.resp_codigo != null && this.filtro.resp_codigo > 0){
            URL += '&responsavel=' + this.filtro.resp_codigo
        }
        if (this.filtro.emi_inicial != null && this.filtro.emi_inicial > 0){
            URL += '&emiini=' + this.moment(this.filtro.emi_inicial).format('DD/MM/YYYY')
        }
        if (this.filtro.emi_final != null && this.filtro.emi_final > 0){
            URL += '&emifin=' + this.moment(this.filtro.emi_final).format('DD/MM/YYYY')
        }
        if (this.filtro.venc_inicial != null && this.filtro.venc_inicial > 0){
            URL += '&vencini=' + this.moment(this.filtro.venc_inicial).format('DD/MM/YYYY')
        }
        if (this.filtro.venc_final != null && this.filtro.venc_final > 0){
            URL += '&vencfin=' + this.moment(this.filtro.venc_final).format('DD/MM/YYYY')
        }

        await axios.get(`${URL}`)
            .then(response => {
                this.lista = response.data.data
                this.quitacoes = []
                this.onLimpa()
                $('#myTab a[href="#parcela"]').tab('show')
                this.errors = {}
            })
            .catch(response => {
                this.message = response.response.data.message
                this.errors = response.response.data.errors
                this.status = response.response.status
            })
      
    },
    onSubmit (e) {
      this.$swal({
        title: 'Deseja realmente gravar o agendamento?',
        text: "Este agendamento poderá apenas ser cancelado posteriormente!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.onGravar()   
            }                     
        })
    },
    async onGravar (e) {
      
      let URL = '/escquitacoes/novo'
      var dados = this.quitacoes
      await axios.post(`${URL}`,dados)
          .then(response => {
              this.message = response.data.message
              this.status = response.status
              this.lista = response.data.data
              this.errors = {}
              
              this.$swal('Sucesso!',
              'Registro incluído com sucesso!',
              'success');  
          //this.mode = 'readonly'
          //this.$router.back() 
          this.getData()                                         
          })
          .catch(response => {
              this.message = response.response.data.message
              if (response.response.data.error){
                  this.errors = response.response.data.error.errorInfo[2]              
              }else{
                  this.errors = response.response.data.errors
              }
              this.status = response.response.status
              
          })                     
    },
    /* QUITACOES */
    setTpQuitacao (data) {
        this.tpQuitacao = data
    },
    onAdcQuitacao () {
        let msgs = ''
        
        if (this.quitacao.docp_codigo == null || this.quitacao.docp_codigo == ''){
            msgs += 'Selecione uma Parcela; </br>';
        }
        if (this.quitacao.doc_data == null || this.quitacao.doc_data == 0){
            msgs += 'Digite uma Data válida; </br>';
        }
        if (this.quitacao.tqui_codigo == null || this.quitacao.tqui_codigo == 0){
            msgs += 'Selecione um Tipo de Quitação; </br>';
        }        
        if (this.quitacao.ccst_codigo == null || this.quitacao.ccst_codigo == 0){
            msgs += 'Selecione um Centro de Custo; </br>';
        }
        if (this.quitacao.doc_tipo == null || this.quitacao.doc_tipo == 0){
            msgs += 'Selecione um Tipo de Lançamento; </br>';
        }
        if (this.quitacao.cbnc_codigo == null || this.quitacao.cbnc_codigo == 0){
            msgs += 'Selecione uma Conta Caixa / Bancária; </br>';
        }
        if (this.quitacao.doc_valor == null || this.quitacao.doc_valor == 0){
            msgs += 'Digite um Valor Pago válido; </br>';
        } 
        if (this.tpQuitacaoSelecionada.tqui_descricao === 'CHEQUE'){
            if (this.quitacao.docp_ch_numero == null || this.quitacao.docp_ch_numero == ''){
                msgs += 'Digite o Número do Cheque; </br>';
            }            
            if (this.quitacao.docp_ch_vencimento == null || this.quitacao.docp_ch_vencimento == 0){
                msgs += 'Digite a Data de Vencimento do Cheque; </br>';
            }            
            if ((this.quitacao.docp_ch_banco == null || this.quitacao.docp_ch_banco == 0) && (this.parcelaSelecionada.doc_tipo_conta !== 'P')){
                msgs += 'Selecione o Banco do Cheque; </br>';
            }            
            if ((this.quitacao.docp_ch_agencia == null || this.quitacao.docp_ch_agencia == '') && (this.parcelaSelecionada.doc_tipo_conta !== 'P')){
                msgs += 'Digite a Agência do Cheque; </br>';
            }            
            if ((this.quitacao.docp_ch_conta == null || this.quitacao.docp_ch_conta == '') && (this.parcelaSelecionada.doc_tipo_conta !== 'P')){
                msgs += 'Digite a Conta Corrente do Cheque; </br>';
            }            
            if ((this.quitacao.docp_ch_valor == null || this.quitacao.docp_ch_valor == 0) && (this.parcelaSelecionada.doc_tipo_conta !== 'P')){
                msgs += 'Digite um Valor válido para o Cheque; </br>';
            }            
        }
        

        if (msgs !== ''){
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Incluir o Item',
                html: msgs,
                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
            })        
        }else{
            this.quitacao.pes_codigo        = this.parcelaSelecionada.pes_codigo
            this.quitacao.docp_parcela      = this.parcelaSelecionada.docp_parcela
            this.quitacao.docp_numero       = this.parcelaSelecionada.docp_numero
            this.quitacao.doc_tipo_conta    = this.parcelaSelecionada.doc_tipo_conta
            this.quitacao.doc_tipo          = this.parcelaSelecionada.doc_tipo_conta === 'P' ? 1 : 2;
            //this.quitacao.docp_valor      = this.parcelaSelecionada.docp_valor,
            this.quitacao.tqui_descricao    = this.tpQuitacaoSelecionada.tqui_descricao
            
            var totalPGParc = this.quitacoes.reduce( (acc, item) => { return acc += this.quitacao.docp_codigo === item.docp_codigo ? Number(item.doc_valor) : 0; }, 0);
            //console.log(totalPGParc)
            if (totalPGParc >= this.quitacao.docp_valor){
                this.$swal({
                    title: 'A Soma de quitações lançada para este documento ultrapassa o valor devido, deseja incluir a quitação assim mesmo?',
                    text: "Recomendamos que os valores sejam incluídos como Juros / Multa / Acréscimo.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: 'Não',
                    confirmButtonText: 'Sim'
                    }).then((result) => {
                        if (result.value) {
                            this. onSalvarQuitacao()   
                        }                     
                    })
            } else {
                 this. onSalvarQuitacao()    
            }            
        }
    },    
    onSalvarQuitacao () {
        this.quitacoes.push(
            {
                doc_tipo_conta: this.quitacao.doc_tipo_conta,
                docp_codigo: this.quitacao.docp_codigo,
                docp_parcela: this.quitacao.docp_parcela,
                docp_numero: this.quitacao.docp_numero,
                docp_valor: this.quitacao.docp_valor,
                pes_codigo: this.quitacao.pes_codigo,
                crec_codigo: this.quitacao.crec_codigo,
                lanc_codigo: this.quitacao.lanc_codigo,
                doc_tipo: this.quitacao.doc_tipo,
                doc_data: this.quitacao.doc_data,
                doc_data_credito: this.quitacao.doc_data_credito,
                cbnc_codigo: this.quitacao.cbnc_codigo,
                cbnc_ent: this.Entidade,
                doc_vlr_multa: this.quitacao.doc_vlr_multa,
                doc_vlr_juros: this.quitacao.doc_vlr_juros,
                doc_vlr_desconto: this.quitacao.doc_vlr_desconto,
                doc_vlr_tarifas: this.quitacao.doc_vlr_tarifas,
                doc_valor: this.quitacao.doc_valor,
                cpagp_codigo: this.quitacao.cpagp_codigo,
                ccst_codigo: this.quitacao.ccst_codigo,
                doc_estorno: this.quitacao.doc_estorno,
                tp_lancamento: this.quitacao.tp_lancamento,
                tqui_codigo: this.quitacao.tqui_codigo,
                tqui_descricao: this.quitacao.tqui_descricao,
                docp_ch_numero: this.quitacao.docp_ch_numero,
                docp_ch_vencimento: this.quitacao.docp_ch_vencimento,
                docp_ch_banco: this.quitacao.docp_ch_banco,
                docp_ch_agencia: this.quitacao.docp_ch_agencia,
                docp_ch_conta: this.quitacao.docp_ch_conta,
                docp_ch_valor: this.quitacao.docp_ch_valor,                
            })

            this.onLimpa()                      
    },
    onCancQuitacao (index) {
        this.$swal({
        title: 'Deseja realmente remover a quitação?',
        text: "Remover quitação!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.quitacoes.splice(index,1);   
            }                     
        })
    },
    onLimpa () {        
        this.tpQuitacaoSelecionada = {}
        this.ccustoSelecionada = {}
        this.parcelaSelecionada = {}
        
        this.quitacao.doc_tipo_conta = null
        this.quitacao.docp_codigo = null
        this.quitacao.docp_parcela = null
        this.quitacao.docp_numero = null
        this.quitacao.docp_valor = null
        this.quitacao.pes_codigo = null
        this.quitacao.crec_codigo = null
        this.quitacao.lanc_codigo = null
        this.quitacao.doc_tipo = 2
        this.quitacao.doc_data = new Date()
        this.quitacao.doc_data_credito = new Date()
        this.quitacao.cbnc_codigo = null
        this.quitacao.cbnc_ent = this.Entidade
        this.quitacao.doc_vlr_multa = null
        this.quitacao.doc_vlr_juros = null
        this.quitacao.doc_vlr_desconto = null
        this.quitacao.doc_vlr_tarifas = null
        this.quitacao.doc_valor = null
        this.quitacao.ccst_codigo = null
        this.quitacao.doc_estorno = null
        this.quitacao.tp_lancamento = null
        this.quitacao.tqui_codigo = null
        this.quitacao.tqui_descricao= null
        this.quitacao.docp_ch_numero = null
        this.quitacao.docp_ch_vencimento = null
        this.quitacao.docp_ch_banco = null
        this.quitacao.docp_ch_agencia = null
        this.quitacao.docp_ch_conta = null
        this.quitacao.docp_ch_valor = null
    }
  },
  computed: {
    ...mapState('Login',['infoLogado','Entidade', 'Exercicio', 'Escola']),
    
    parcelasSelecionadas () {
        if (typeof this.lista.filter !== 'undefined'){        
            return this.lista.filter(el => {
                return el.doc_selecionado == 1;
            })
        }else{
            return {}
        }
    },
    somaParcelasSel () {
        if (typeof this.lista.reduce !== 'undefined'){
            return this.lista.reduce( (acc, item) => { return acc += item.doc_selecionado == 1 ? Number(item.docp_valor) : 0.00; }, 0);        
        }else{
            return 0.00
        }
    },
    somaMJASel () {
        return this.quitacoes.reduce( (acc, item) => { return acc += Number(item.doc_vlr_multa) + Number(item.doc_vlr_juros) + Number(item.doc_vlr_tarifas); }, 0);        
    },
    somaDescontosSel () {
        return this.quitacoes.reduce( (acc, item) => { return acc += Number(item.doc_vlr_desconto); }, 0);        
    },
    somaPagoSel () {
        return this.quitacoes.reduce( (acc, item) => { return acc += Number(item.doc_valor); }, 0);        
    }    
  },
  watch: {
    'quitacao.cbnc_codigo' : function (newVal, oldVal) {
        /*BUSCANDO O ULTIMO NUMERO DO CHEQUE*/
        if (this.tpQuitacaoSelecionada.tqui_descricao === 'CHEQUE' && this.parcelaSelecionada.doc_tipo_conta === 'P'){
            URL = '/esccontabancaria/ultcheque?cbnc_codigo='+newVal
            axios.get(`${URL}`)
                .then(response => {
                    this.quitacao.docp_ch_numero = response.data.data
                    if (this.quitacao.docp_ch_vencimento == null || this.quitacao.docp_ch_vencimento == 0){
                        this.quitacao.docp_ch_vencimento = new Date()
                    }
                    this.errors = {}
                })
                .catch(response => {
                    this.message = response.response.data.message
                    this.errors = response.response.data.errors
                    this.status = response.response.status
                })
        }
    },
    'quitacao.docp_codigo' : function (newVal, oldVal) {
        let ret = this.parcelasSelecionadas.filter(el => {
            return el.docp_codigo == this.quitacao.docp_codigo;
        })
        if (typeof ret[0] !== 'undefined'){
            this.quitacao.docp_valor    = ret[0].docp_valor
            this.ccustoSelecionada      = ret[0].ccustos
            this.parcelaSelecionada     = ret[0]
        }else{
            this.quitacao.docp_valor    = null
            this.ccustoSelecionada      = {}
            this.parcelaSelecionada     = {}
        }
    },
    'quitacao.tqui_codigo' : function (newVal, oldVal) {
        let ret = this.tpQuitacao.filter(el => {
            return el.tqui_codigo == newVal;
        })
        if (typeof ret[0] !== 'undefined'){
            this.tpQuitacaoSelecionada = ret[0]
        }else{
            this.tpQuitacaoSelecionada = {}
        }
    },
    'quitacao.docp_valor' : function (newVal, oldVal) {
        this.quitacao.doc_valor =  Number(this.quitacao.docp_valor) + Number(this.quitacao.doc_vlr_multa) + Number(this.quitacao.doc_vlr_juros) + Number(this.quitacao.doc_vlr_tarifas) - Number(this.quitacao.doc_vlr_desconto)
    },
    'quitacao.doc_vlr_multa' : function (newVal, oldVal) {
        this.quitacao.doc_valor =  Number(this.quitacao.docp_valor) + Number(this.quitacao.doc_vlr_multa) + Number(this.quitacao.doc_vlr_juros) + Number(this.quitacao.doc_vlr_tarifas) - Number(this.quitacao.doc_vlr_desconto)
    },
    'quitacao.doc_vlr_juros' : function (newVal, oldVal) {
        this.quitacao.doc_valor =  Number(this.quitacao.docp_valor) + Number(this.quitacao.doc_vlr_multa) + Number(this.quitacao.doc_vlr_juros) + Number(this.quitacao.doc_vlr_tarifas) - Number(this.quitacao.doc_vlr_desconto)
    },
    'quitacao.doc_vlr_tarifas' : function (newVal, oldVal) {
        this.quitacao.doc_valor =  Number(this.quitacao.docp_valor) + Number(this.quitacao.doc_vlr_multa) + Number(this.quitacao.doc_vlr_juros) + Number(this.quitacao.doc_vlr_tarifas) - Number(this.quitacao.doc_vlr_desconto)
    },
    'quitacao.doc_vlr_desconto' : function (newVal, oldVal) {
        this.quitacao.doc_valor =  Number(this.quitacao.docp_valor) + Number(this.quitacao.doc_vlr_multa) + Number(this.quitacao.doc_vlr_juros) + Number(this.quitacao.doc_vlr_tarifas) - Number(this.quitacao.doc_vlr_desconto)
    },      
  },
  mounted() {
    setTimeout(() => {
      
    }, 1000)
  },  
  created() {
      /*this.getData()*/
  },     
}
</script>

<style scoped>
    
    .mct-row-itens div{
        font-size: 1.4rem;
        font-weight: 600;
    }

    .mct-row-itens div h1{
        font-size: 0.8rem;
        font-weight: 400;
    }

     .mct-row-itens div i {
        height: 100%;
        float: left;
        font-size: 32px;
        /* margin-top: 10px; */
        padding: 15px;
        background-color: rgba(0, 0, 0, 0.3);

     }

</style>
