<template>
  <!-- RELATORIOS CONTAS A RECEBER - A RECEBER -->
  <div>
    <div class="row mb-2">
      <div class="col-lg-8">
        <mct-form-itens
          tipo="select"
          campo="ccusto"
          :valor="filtro"
          titulo="Centro de Custos"
          origem="esc_centro_custo"
          origemChave="ccst_codigo"
          origemRetorno="ccst_descricao"
          origemRetornoSec="ccst_codigo"
          :errors="{}"
          modo="editar"
        ></mct-form-itens>
      </div>
      <div class="col-lg-4">
        <mct-form-itens
          tipo="select"
          campo="ano_letivo"
          :valor="filtro"
          titulo="Ano Letivo"
          :errors="{}"
          modo="editar"
        ></mct-form-itens>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-4">
        <fieldset class="well h-100">
          <legend class="well-legend">Data</legend>
          <div class="row">
            <div class="col-lg-12 mb-2">
              <mct-form-itens
                tipo="date"
                campo="data_ini"
                :valor="filtro"
                titulo="Inicial"
                showTitulo="false"
                :errors="{}"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <mct-form-itens
                tipo="date"
                campo="data_fin"
                :valor="filtro"
                titulo="Final"
                showTitulo="false"
                :errors="{}"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-lg-4">
        <fieldset class="well h-100">
          <legend class="well-legend">Tipo</legend>
          <div class="row">
            <div class="col-lg-12 mb-2">
              <mct-form-itens
                tipo="radio"
                campo="modelo"
                :valor="filtro"
                titulo="Tipo"
                showTitulo="false"
                :errors="{}"
                :origemData="[
                  { value: 1, text: 'Resumido' },
                  { value: 2, text: 'Detalhado' },
                ]"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-lg-4">
        <fieldset class="well h-100">
          <legend class="well-legend">Tipo Lançamentos</legend>
          <div class="row">
            <div class="col-lg-12 mb-2">
              <mct-form-itens
                tipo="radio"
                campo="tipo"
                :valor="filtro"
                titulo="Tipo de Rel."
                showTitulo="false"
                :errors="{}"
                :origemData="[
                  { value: 0, text: 'Ambos' },
                  { value: 1, text: 'Débito' },
                  { value: 2, text: 'Crédito' }
                ]"
                modo="editar"
              ></mct-form-itens>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-4">
        <div class="row">
          <div class="col-lg-12 h-100">
            <fieldset class="well h-100">
              <legend class="well-legend">Tipo Conta</legend>
              <div class="row">
                <div class="col-lg-12">
                  <mct-form-itens
                    tipo="radio"
                    campo="tipoc"
                    :valor="filtro"
                    titulo="Tipo Conta"
                    showTitulo="false"
                    :errors="{}"
                    :origemData="[
                      { value: 1, text: 'Caixa' },
                      { value: 2, text: 'Banco' },
                    ]"
                    modo="editar"
                  ></mct-form-itens>                
                </div>
              </div>
            </fieldset>
          </div>
        </div>                
      </div>
      <div class="col-8">
        <div class="row">
          <div class="col-lg-12 h-100">
            <fieldset class="well h-100">
              <legend class="well-legend">Conta</legend>
              <div class="row">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-4">
                      <mct-form-itens
                        tipo="number"
                        campo="conta_cod"
                        :valor="filtro"
                        titulo=""
                        :errors="{}"
                        modo="editar"
                      ></mct-form-itens>
                    </div>
                    <div class="col-8">
                      <mct-form-itens
                        tipo="select"
                        campo="conta_cod"
                        :valor="filtro"
                        titulo=""
                        :origem="filtro.tipoc == 1 ? 'esc_caixa' : 'esc_conta_bancaria'"
                        :origemChave="filtro.tipoc == 1 ? 'cxa_codigo' : 'cbnc_codigo'"
                        :origemRetorno="filtro.tipoc == 1 ? 'cxa_descricao' : 'cbnc_descricao'"
                        :origemRetornoSec="filtro.tipoc == 1 ? 'cxa_codigo' : 'cbnc_codigo'"
                        :errors="{}"
                        modo="editar"
                      ></mct-form-itens>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-8">
        <mct-form-itens
          tipo="select"
          campo="tpquitacao"
          :valor="filtro"
          titulo="Tipo de Quitação"
          :errors="{}"
          modo="editar"
          origem="esc_tp_quitacao"
          origemChave="tqui_codigo"
          origemRetorno="tqui_descricao"
          origemRetornoSec="tqui_codigo"
        ></mct-form-itens>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-8">
        <mct-form-itens
          tipo="select"
          campo="tprecebimento"
          :valor="filtro"
          titulo="Tipo Recebimento"
          :errors="{}"
          modo="editar"
          origem="esc_centro_custo"
          origemChave="ccst_codigo"
          origemRetorno="ccst_descricao"
          origemRetornoSec="ccst_codigo"
        ></mct-form-itens>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-lg-8">
        <mct-form-itens
          tipo="text"
          campo="historico"
          :valor="filtro"
          titulo="Complemento"
          :errors="{}"
          modo="editar"
        ></mct-form-itens>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-12 text-left">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          aria-label="Left Align"
          @click="filtro.modelo === 1 ? onMovDiarioResumido() : onMovDiarioDetalhado()"
        >
          <span class="fa fa-refresh fa-lg" aria-hidden="true"></span>
          Visualizar
        </button>
      </div>
    </div>
    <b-modal id="relatorio" title="Relatório Financeiro" hide-footer hide-header hide-title size="xl">
      <PDFView :url="localOutputPdf === null ? '' : localOutputPdf" />
    </b-modal>     
  </div>
</template>

<script>
import axios from '@/http';
import moment from 'moment';
import PDFView from "@/components/pdfviewer/PDFView.vue";
import MctFormItens from "@/components/comum/MctFormItens.vue";
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios';

export default {
  props: ['outputPdf'],
  mixins: [MctPadraoRelatorios],
  components: {
    PDFView,
    MctFormItens: MctFormItens
  },
  data() {
    return {
      data: [],
      filtro: {},
      localOutputPdf: {}
    };
  },
  created() {
    this.localOutputPdf = this.outputPdf;
    this.filtro = {
      ano_letivo : null,
      ccusto: null,
      modelo: 1,
      tipoc: 1,
      tipo: 0,
      data_ini: null,
      data_fin: null,
      conta_cod: null,
      caixa_cod: null,
      tprecebimento: null,
      tpquitacao: null,
      historico: ''
    },
    sldAntQuitacao = []
  },
  methods: {
    async getData() {
      await axios.get('/relatorios/financeiro/movdiario',{params:  {
          ccusto: this.filtro.ccusto,
          anoletivo: this.filtro.ano_letivo,
          modelo: this.filtro.modelo,
          conta: this.filtro.conta_cod > 0 ? this.filtro.conta_cod : this.filtro.caixa_cod > 0 ? this.filtro.caixa_cod : null,
          tipo: this.filtro.tipo,
          tipoc: this.filtro.conta_cod > 0 ? this.filtro.tipoc : this.filtro.caixa_cod > 0 ? this.filtro.tipoc : null,
          dataini: moment(this.filtro.data_ini).isValid() ? moment(this.filtro.data_ini).format('YYYY-MM-DD') : null,
          datafin: moment(this.filtro.data_fin).isValid() ? moment(this.filtro.data_fin).format('YYYY-MM-DD') : null,
          tprecebimento: this.filtro.tprecebimento,
          tpquitacao: this.filtro.tpquitacao,
          historico: this.filtro.historico,
      },
      paramsSerializer: function (params) {
          return jQuery.param(params)
      }
      })
      .then(response => {
          this.data = response.data.data                    
          this.sldAntQuitacao = response.data.sldAntQuitacao                    
      })
      .catch(response => {
          console.log(response.message)
      })            
    },        
    async onMovDiarioResumido () {

      await this.getData();

      await this.onCreatePDF()
      /*CABEÇALHO*/
      this.title = 'MOVIMENTO DIÁRIO - FINANCEIRO (RESUMIDO)';
      this.onHeaderPDF();
      this.pdf.setFont('courier') 
      this.pdf.setFont('helvetica','normal') 
      this.pdf.setFontSize(7)     
              
      if(this.filtro.ccusto){
          this.pdf.text(`CENTRO DE CUSTO: ${this.filtro.ccusto}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if(moment(this.filtro.data_ini).isValid()){
          this.pdf.text(`PERÍODO DE ${this.filtro.tipodata === 'E' ? 'EMISSÃO' : this.filtro.tipodata === 'V' ? 'VENCIMENTO' : this.filtro.tipodata === 'P' ? 'PAGAMENTO' : '' } ENTRE ${moment(this.filtro.data_ini).format('DD/MM/YYYY')} A ${moment(this.filtro.data_fin).format('DD/MM/YYYY')}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if(this.filtro.tipo){
          this.pdf.text(`${this.filtro.tipo === 1 ? 'APENAS DÉBITO' : this.filtro.tipo === 2 ? 'APENAS CRÉDITO' : ''}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if((this.filtro.tipoc === 1) && (this.filtro.caixa_cod > 0)){
          this.pdf.text(`CAIXA CÓD.: ${this.filtro.caixa_cod}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if((this.filtro.tipoc === 2) && (this.filtro.conta_cod > 0)){
          this.pdf.text(`CONTA BANC. CÓD.: ${this.filtro.conta_cod}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if(this.filtro.tprecebimento){
          this.pdf.text(`TIPO DE RECEBIMENTO: ${this.filtro.tprecebimento}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if(this.filtro.tpquitacao){
          this.pdf.text(`TIPO DE QUITAÇÃO: ${this.filtro.tpquitacao}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      this.onAddLine(this.jump)

      //this.onAddLine(this.jump)
      this.pdf.setFillColor(192,192,192)
      this.pdf.rect(8,this.lin-3,this.widthPage,this.jump+1,'FD')
      this.pdf.setFont('helvetica','bold') 
      this.pdf.setFontSize(6)

      this.pdf.text('USUÁRIO',10,this.lin)
      this.pdf.text('HISTÓRICO LANÇAMENTO',26,this.lin)
      this.pdf.text('CRÉDITO',112,this.lin,{align: 'right'})
      this.pdf.text('DÉBITO',130,this.lin,{align: 'right'})
      this.pdf.text('DESC.',142,this.lin,{align: 'right'})
      this.pdf.text('JUROS.',152,this.lin,{align: 'right'})
      this.pdf.text('LIQUIDO',172,this.lin,{align: 'right'})
      this.pdf.text('SALDO',190,this.lin,{align: 'right'})
      this.pdf.text('TP',199,this.lin,{align: 'right'})
      this.onAddLine(this.jump+1)
      
      var somaCredito = 0
      var somaDebito = 0
      var somaDescontos = 0
      var somaMulJur = 0
      var somaTarifas = 0

      var grupoData = null

      this.pdf.setFont('courier') 
      this.pdf.setFontSize(6)
      this.pdf.setFont('helvetica','italic')
      this.pdf.text(String(`SALDO ANTERIOR`),172,this.lin,{align: 'right'})
      this.pdf.setFont('helvetica','bold')      
      this.pdf.text(String(this.$options.filters.moeda(Number(this.data[0].saldo_anterior))),192,this.lin,{align: 'right'})
      this.onAddLine(this.jump+1)

      for (var index in this.data) {
          this.pdf.setFont('courier') 
          if (grupoData !== this.data[index].data){
              grupoData = this.data[index].data
              this.pdf.setFontSize(6)
              this.pdf.setFont('helvetica','bold') 
              this.pdf.text(String('DATA: '+ this.$options.filters.data(this.data[index].data)),10,this.lin)
              this.onAddLine(this.jump+1)
          }

          this.pdf.setFontSize(6)
          this.pdf.setFont('helvetica','italic')
          this.pdf.text(String(this.data[index].usuario),10,this.lin)
          this.pdf.setFont('helvetica','normal')
          this.pdf.text(String(this.data[index].historico.substring(0,55)),26,this.lin)
          this.pdf.text(String(this.data[index].dc === 'CR' ? this.$options.filters.moeda(Number(this.data[index].credito) - Number(this.data[index].muljur) + Number(this.data[index].desconto)) : ''),112,this.lin,{align: 'right'})
          this.pdf.text(String(this.data[index].dc === 'DB' ? this.$options.filters.moeda(Number(this.data[index].debito) - Number(this.data[index].muljur) + Number(this.data[index].desconto)) : ''),130,this.lin,{align: 'right'})
          this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].desconto))),142,this.lin,{align: 'right'})
          this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].muljur))),152,this.lin,{align: 'right'})
          this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].credito) + Number(this.data[index].debito ))),172,this.lin,{align: 'right'})
          this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].saldo))),190,this.lin,{align: 'right'})
          this.pdf.text(String(this.data[index].tipo),199,this.lin,{align: 'right'})

          somaCredito += this.data[index].dc === 'CR' ? Number(this.data[index].credito) - Number(this.data[index].muljur) + Number(this.data[index].desconto) : 0;
          somaDebito += this.data[index].dc === 'DB' ? Number(this.data[index].debito) - Number(this.data[index].muljur) + Number(this.data[index].desconto) : 0;
          somaDescontos += Number(this.data[index].desconto);
          somaMulJur += Number(this.data[index].muljur);
          somaTarifas += 0/*Number(this.data[index].desconto)*/;
          
          this.onAddRegLine()
          this.onAddLine(this.jump)

          if (parseInt(index)+1 == this.data.length || grupoData !== this.data[parseInt(index)+1 < this.data.length ? parseInt(index)+1 : index].data){
              this.onAddLine(1)
              this.pdf.setFont('courier') 
              this.pdf.setFontSize(6)
              this.pdf.setFont('helvetica','italic')
              this.pdf.text(String(`SALDO DO DIA`),172,this.lin,{align: 'right'})
              this.pdf.setFont('helvetica','bold')      
              this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].saldo_dia))),192,this.lin,{align: 'right'})
              this.onAddLine(this.jump+1)
              this.pdf.setFont('helvetica','italic')
              this.pdf.text(String(`SALDO ATÉ O DIA`),172,this.lin,{align: 'right'})
              this.pdf.setFont('helvetica','bold')      
              this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].saldo))),192,this.lin,{align: 'right'})
              this.onAddLine(this.jump+1)
              //this.pdf.line(8,this.lin-this.jump,(this.widthPage+8),this.lin-this.jump)
          }
      }
      
      // TOTAL FINAL
      this.pdf.line(8,this.lin-this.jump,(this.widthPage+8),this.lin-this.jump)
      this.onAddLine(1)
      this.pdf.setFont('courier') 
      this.pdf.setFontSize(6)
      this.pdf.setFont('helvetica','normal')
      this.pdf.text('SALDO INICIAL',33,this.lin,{align: 'right'})
      this.pdf.text('CRÉDITOS',56,this.lin,{align: 'right'})
      this.pdf.text('DÉBITOS',79,this.lin,{align: 'right'})
      this.pdf.text('DESCONTOS',102,this.lin,{align: 'right'})
      this.pdf.text('JUROS',125,this.lin,{align: 'right'})
      this.pdf.text('TARIFAS',148,this.lin,{align: 'right'})
      this.pdf.text('MOV. DO PERÍODO',171,this.lin,{align: 'right'})
      this.pdf.text('SALDO ATUAL',194,this.lin,{align: 'right'})
      this.onAddLine(this.jump+1)
      this.pdf.setFont('helvetica','bold')
      this.pdf.text(String(this.$options.filters.moeda(this.data[this.data.length-1].saldo_anterior)),33,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(somaCredito)),56,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(somaDebito)),79,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(somaDescontos)),102,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(somaMulJur)),125,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(somaTarifas)),148,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(somaCredito-somaDebito)),171,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(this.data[this.data.length-1].saldo)),194,this.lin,{align: 'right'})
      this.onAddLine(this.jump+1)
      somaCredito = 0
      somaDebito = 0      
      somaDescontos = 0
      somaMulJur = 0
      somaTarifas = 0
          
/* RESUMOS */

      /* RESUMO POR TIPO DE RECEBIMENTO */
      var resTpRecebimento = this.data.filter((ele, index) => {
        return ele.trec_codigo !== null && (ele.credito > 0 || ele.debito > 0);
      });
      resTpRecebimento = resTpRecebimento.sort((objA, objB) => {
        const a = objA.trec_codigo;
        const b = objB.trec_codigo;
        return a < b ? -1 : a > b ? 1 : 0;
      });
      console.log(resTpRecebimento);

      resTpRecebimento = resTpRecebimento.map(ele => {
        return {
          trec_codigo: ele.trec_codigo,
          trec_descricao: ele.trec_descricao,
          credito: ele.credito,
          debito: ele.debito
        };
      });
      //console.log(resTpRecebimento);

      var resFinalTpRecebimento = [];
      resTpRecebimento.reduce(function(res, value) {
        if (!res[value.trec_codigo]) {
          res[value.trec_codigo] = {
            trec_codigo: value.trec_codigo,
            trec_descricao: value.trec_descricao,
            credito: 0,
            debito: 0
          };
          resFinalTpRecebimento.push(res[value.trec_codigo]);
        }
        res[value.trec_codigo].credito += Number(value.credito);
        res[value.trec_codigo].debito += Number(value.debito);
        return res;
      }, {});

      this.onAddLine(this.jump);
      this.pdf.setFillColor(192, 192, 192);
      this.pdf.rect(8, this.lin - 3, this.widthPage, this.jump + 1, "FD");
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("RESUMO GERAL POR TIPO DE RECEBIMENTO", 10, this.lin);
      this.onAddLine(this.jump + 1);
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("CÓD.", 15, this.lin, { align: "right" });
      this.pdf.text("TP. RECEBIMENTO", 17, this.lin);
      this.pdf.text("CRÉDITO", 110, this.lin, { align: "right" });
      this.pdf.text("DÉBITO", 130, this.lin, { align: "right" });
      this.pdf.text("SALDO", 160, this.lin, { align: "right" });
      this.onAddLine(this.jump + 1);
      var somaResumo = 0;
      for (var index in resFinalTpRecebimento) {
        this.pdf.setFont("courier");
        this.pdf.setFontSize(7.5);
        this.pdf.setFont('helvetica',"normal");
        this.pdf.text(
          String(resFinalTpRecebimento[index].trec_codigo),
          15,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(resFinalTpRecebimento[index].trec_descricao),
          17,
          this.lin
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              resFinalTpRecebimento[index].credito
            )
          ),
          110,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              resFinalTpRecebimento[index].debito
            )
          ),
          130,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              resFinalTpRecebimento[index].credito - resFinalTpRecebimento[index].debito
            )
          ),
          160,
          this.lin,
          { align: "right" }
        );

        somaResumo += Number(resFinalTpRecebimento[index].credito - resFinalTpRecebimento[index].debito);
        this.onAddLine(this.jump);
      }
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("MOVIMENTO PERÍODO", 130, this.lin, { align: "right" });
      this.pdf.text(
        String(this.$options.filters.moeda(somaResumo)),
        160,
        this.lin,
        { align: "right" }
      );
      this.onAddLine(this.jump + 1);


      /* RESUMO POR TIPO DE QUITACAO */      
      this.onAddLine(this.jump);
      this.pdf.setFillColor(192, 192, 192);
      this.pdf.rect(8, this.lin - 3, this.widthPage, this.jump + 1, "FD");
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("RESUMO GERAL POR TIPO DE QUITAÇÃO", 10, this.lin);
      this.onAddLine(this.jump + 1);
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("CÓD.", 15, this.lin, { align: "right" });
      this.pdf.text("TP. QUITAÇÃO", 17, this.lin);
      this.pdf.text("SLD.ANT.", 90, this.lin, { align: "right" });
      this.pdf.text("CRÉDITO", 110, this.lin, { align: "right" });
      this.pdf.text("DÉBITO", 130, this.lin, { align: "right" });
      this.pdf.text("SALDO PER.", 160, this.lin, { align: "right" });
      this.pdf.text("SALDO ATUAL", 190, this.lin, { align: "right" });
      this.onAddLine(this.jump + 1);
      var somaSaldo = 0;
      var somaSaldoAtual = 0;

      for (var index in this.sldAntQuitacao) {
        this.pdf.setFont("courier");
        this.pdf.setFontSize(7.5);
        this.pdf.setFont('helvetica',"normal");
        this.pdf.text(
          String(this.sldAntQuitacao[index].tqui_codigo),
          15,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(this.sldAntQuitacao[index].tqui_descricao),
          17,
          this.lin
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              this.sldAntQuitacao[index].saldo_anterior
            )
          ),
          90,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              this.sldAntQuitacao[index].credito
            )
          ),
          110,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              this.sldAntQuitacao[index].debito
            )
          ),
          130,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              Number(this.sldAntQuitacao[index].credito) - Number(this.sldAntQuitacao[index].debito)
            )
          ),
          160,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              Number(this.sldAntQuitacao[index].saldo_anterior) + (Number(this.sldAntQuitacao[index].credito) - Number(this.sldAntQuitacao[index].debito))
            )
          ),
          190,
          this.lin,
          { align: "right" }
        );
        somaSaldo += Number(this.sldAntQuitacao[index].credito - this.sldAntQuitacao[index].debito);
        somaSaldoAtual += Number(this.sldAntQuitacao[index].saldo_anterior) + (Number(this.sldAntQuitacao[index].credito) - Number(this.sldAntQuitacao[index].debito));
        this.onAddLine(this.jump);
      }
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("MOVIMENTO PERÍODO", 130, this.lin, { align: "right" });
      this.pdf.text(
        String(this.$options.filters.moeda(somaSaldo)),
        160,
        this.lin,
        { align: "right" }
      );
      this.pdf.text(
        String(this.$options.filters.moeda(somaSaldoAtual)),
        190,
        this.lin,
        { align: "right" }
      );
      this.onAddLine(this.jump + 1);

      /* FIM RESUMOS */

      this.onFooterPDF()
      this.onInsertNumberPagesPDF()

      const uuidv4 = this.$uuid.v4();
      const urlPDF = this.pdf.output('blob');
      this.localOutputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
      this.$bvModal.show("relatorio");
    },

    
    async onMovDiarioDetalhado () {

      await this.getData();

      await this.onCreatePDF()
      /*CABEÇALHO*/
      this.title = 'MOVIMENTO DIÁRIO - FINANCEIRO (DETALHADO)';
      this.onHeaderPDF();
      this.pdf.setFont('courier') 
      this.pdf.setFont('helvetica','normal') 
      this.pdf.setFontSize(7)     
              
      if(this.filtro.ccusto){
          this.pdf.text(`CENTRO DE CUSTO: ${this.filtro.ccusto}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if(moment(this.filtro.data_ini).isValid()){
          this.pdf.text(`PERÍODO DE ${this.filtro.tipodata === 'E' ? 'EMISSÃO' : this.filtro.tipodata === 'V' ? 'VENCIMENTO' : this.filtro.tipodata === 'P' ? 'PAGAMENTO' : '' } ENTRE ${moment(this.filtro.data_ini).format('DD/MM/YYYY')} A ${moment(this.filtro.data_fin).format('DD/MM/YYYY')}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if(this.filtro.tipo){
          this.pdf.text(`${this.filtro.tipo === 1 ? 'APENAS DÉBITO' : this.filtro.tipo === 2 ? 'APENAS CRÉDITO' : ''}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if((this.filtro.tipoc === 1) && (this.filtro.caixa_cod > 0)){
          this.pdf.text(`CAIXA CÓD.: ${this.filtro.caixa_cod}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if((this.filtro.tipoc === 2) && (this.filtro.conta_cod > 0)){
          this.pdf.text(`CONTA BANC. CÓD.: ${this.filtro.conta_cod}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if(this.filtro.tprecebimento){
          this.pdf.text(`TIPO DE RECEBIMENTO: ${this.filtro.tprecebimento}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      if(this.filtro.tpquitacao){
          this.pdf.text(`TIPO DE QUITAÇÃO: ${this.filtro.tpquitacao}`,8,this.lin)
          this.onAddLine(this.jump)
      }
      this.onAddLine(this.jump)

      //this.onAddLine(this.jump)
      this.pdf.setFillColor(192,192,192)
      this.pdf.rect(8,this.lin-3,this.widthPage,this.jump+1,'FD')
      this.pdf.setFont('helvetica','bold') 
      this.pdf.setFontSize(6)

      this.pdf.text('USUÁRIO',10,this.lin)
      this.pdf.text('HISTÓRICO LANÇAMENTO',26,this.lin)
      this.pdf.text('TP. QUITAÇÃO',112,this.lin)
      this.pdf.text('CRÉDITO',152,this.lin,{align: 'right'})
      this.pdf.text('DÉBITO',172,this.lin,{align: 'right'})
      this.pdf.text('SALDO',192,this.lin,{align: 'right'})
      this.pdf.text('D/C',199,this.lin,{align: 'right'})
      this.onAddLine(this.jump+1)
      
      var somaCredito = 0
      var somaDebito = 0

      var grupoData = null
      var grupoConta = null

      this.pdf.setFont('courier') 
      this.pdf.setFontSize(6)
      this.pdf.setFont('helvetica','italic')
      this.pdf.text(String(`SALDO ANTERIOR`),172,this.lin,{align: 'right'})
      this.pdf.setFont('helvetica','bold')      
      this.pdf.text(String(this.$options.filters.moeda(Number(this.data[0].saldo_anterior))),192,this.lin,{align: 'right'})
      this.onAddLine(this.jump+1)

      for (var index in this.data) {
          this.pdf.setFont('courier') 
          if (grupoData !== this.data[index].data){
              grupoData = this.data[index].data
              this.pdf.setFontSize(6)
              this.pdf.setFont('helvetica','bold') 
              this.pdf.text(String('DATA: '+ this.$options.filters.data(this.data[index].data)),10,this.lin)
              this.onAddLine(this.jump+1)
              grupoConta = null;
          }
          if (grupoConta !== this.data[index].conta){
              grupoConta = this.data[index].conta
              this.pdf.setFontSize(6)
              this.pdf.setFont('helvetica','bold') 
              this.pdf.text(String(this.data[index].conta_codigo),25,this.lin,{align: 'right'})
              this.pdf.text(String(this.data[index].conta),26,this.lin)
              this.onAddLine(this.jump+1)
          }

          this.pdf.setFontSize(6)
          this.pdf.setFont('helvetica','italic')
          this.pdf.text(String(this.data[index].usuario),10,this.lin)
          this.pdf.setFont('helvetica','normal')
          this.pdf.text(String(this.data[index].historico.substring(0,65)),26,this.lin)
          this.pdf.text(String(this.data[index].tqui_descricao).substring(0,16),112,this.lin)
          this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].credito))),152,this.lin,{align: 'right'})
          this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].debito))),172,this.lin,{align: 'right'})
          this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].saldo))),192,this.lin,{align: 'right'})
          this.pdf.text(String(this.data[index].dc),199,this.lin,{align: 'right'})

          somaCredito += Number(this.data[index].credito)
          somaDebito += Number(this.data[index].debito)
          
          this.onAddRegLine()
          this.onAddLine(this.jump)

          if (parseInt(index)+1 == this.data.length || grupoData !== this.data[parseInt(index)+1 < this.data.length ? parseInt(index)+1 : index].data){
              this.onAddLine(1)
              this.pdf.setFont('courier') 
              this.pdf.setFontSize(6)
              this.pdf.setFont('helvetica','italic')
              this.pdf.text(String(`SALDO DO DIA`),172,this.lin,{align: 'right'})
              this.pdf.setFont('helvetica','bold')      
              this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].saldo_dia))),192,this.lin,{align: 'right'})
              this.onAddLine(this.jump+1)
              this.pdf.setFont('helvetica','italic')
              this.pdf.text(String(`SALDO ATÉ O DIA`),172,this.lin,{align: 'right'})
              this.pdf.setFont('helvetica','bold')      
              this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].saldo))),192,this.lin,{align: 'right'})
              this.onAddLine(this.jump+1)
              //this.pdf.line(8,this.lin-this.jump,(this.widthPage+8),this.lin-this.jump)
          }
      }
      
      // TOTAL FINAL
      this.pdf.line(8,this.lin-this.jump,(this.widthPage+8),this.lin-this.jump)
      this.onAddLine(1)
      this.pdf.setFont('courier') 
      this.pdf.setFontSize(6)
      this.pdf.setFont('helvetica','normal')
      this.pdf.text('SALDO INICIAL',48,this.lin,{align: 'right'})
      this.pdf.text('CRÉDITOS',86,this.lin,{align: 'right'})
      this.pdf.text('DÉBITOS',124,this.lin,{align: 'right'})
      this.pdf.text('MOV. DO PERÍODO',162,this.lin,{align: 'right'})
      this.pdf.text('SALDO ATUAL',199,this.lin,{align: 'right'})
      this.onAddLine(this.jump+1)
      this.pdf.setFont('helvetica','bold')
      this.pdf.text(String(this.$options.filters.moeda(this.data[this.data.length-1].saldo_anterior)),48,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(somaCredito)),86,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(somaDebito)),124,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(somaCredito-somaDebito)),162,this.lin,{align: 'right'})
      this.pdf.text(String(this.$options.filters.moeda(this.data[this.data.length-1].saldo)),199,this.lin,{align: 'right'})
      this.onAddLine(this.jump+1)
      somaCredito = 0
      somaDebito = 0  
            
      /* RESUMOS */

      /* RESUMO POR TIPO DE RECEBIMENTO */
      var resTpRecebimento = this.data.filter((ele, index) => {
        return ele.trec_codigo !== null && (ele.credito > 0 || ele.debito > 0);
      });
      resTpRecebimento = resTpRecebimento.sort((objA, objB) => {
        const a = objA.trec_codigo;
        const b = objB.trec_codigo;
        return a < b ? -1 : a > b ? 1 : 0;
      });
      console.log(resTpRecebimento);

      resTpRecebimento = resTpRecebimento.map(ele => {
        return {
          trec_codigo: ele.trec_codigo,
          trec_descricao: ele.trec_descricao,
          credito: ele.credito,
          debito: ele.debito
        };
      });
      //console.log(resTpRecebimento);

      var resFinalTpRecebimento = [];
      resTpRecebimento.reduce(function(res, value) {
        if (!res[value.trec_codigo]) {
          res[value.trec_codigo] = {
            trec_codigo: value.trec_codigo,
            trec_descricao: value.trec_descricao,
            credito: 0,
            debito: 0
          };
          resFinalTpRecebimento.push(res[value.trec_codigo]);
        }
        res[value.trec_codigo].credito += Number(value.credito);
        res[value.trec_codigo].debito += Number(value.debito);
        return res;
      }, {});

      this.onAddLine(this.jump);
      this.pdf.setFillColor(192, 192, 192);
      this.pdf.rect(8, this.lin - 3, this.widthPage, this.jump + 1, "FD");
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("RESUMO GERAL POR TIPO DE RECEBIMENTO", 10, this.lin);
      this.onAddLine(this.jump + 1);
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("CÓD.", 15, this.lin, { align: "right" });
      this.pdf.text("TP. RECEBIMENTO", 17, this.lin);
      this.pdf.text("CRÉDITO", 110, this.lin, { align: "right" });
      this.pdf.text("DÉBITO", 130, this.lin, { align: "right" });
      this.pdf.text("SALDO", 160, this.lin, { align: "right" });
      this.onAddLine(this.jump + 1);
      var somaResumo = 0;
      for (var index in resFinalTpRecebimento) {
        this.pdf.setFont("courier");
        this.pdf.setFontSize(7.5);
        this.pdf.setFont('helvetica',"normal");
        this.pdf.text(
          String(resFinalTpRecebimento[index].trec_codigo),
          15,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(resFinalTpRecebimento[index].trec_descricao),
          17,
          this.lin
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              resFinalTpRecebimento[index].credito
            )
          ),
          110,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              resFinalTpRecebimento[index].debito
            )
          ),
          130,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              resFinalTpRecebimento[index].credito - resFinalTpRecebimento[index].debito
            )
          ),
          160,
          this.lin,
          { align: "right" }
        );

        somaResumo += Number(resFinalTpRecebimento[index].credito - resFinalTpRecebimento[index].debito);
        this.onAddLine(this.jump);
      }
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("MOVIMENTO PERÍODO", 130, this.lin, { align: "right" });
      this.pdf.text(
        String(this.$options.filters.moeda(somaResumo)),
        160,
        this.lin,
        { align: "right" }
      );
      this.onAddLine(this.jump + 1);


      /* RESUMO POR TIPO DE QUITACAO */      
      this.onAddLine(this.jump);
      this.pdf.setFillColor(192, 192, 192);
      this.pdf.rect(8, this.lin - 3, this.widthPage, this.jump + 1, "FD");
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("RESUMO GERAL POR TIPO DE QUITAÇÃO", 10, this.lin);
      this.onAddLine(this.jump + 1);
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("CÓD.", 15, this.lin, { align: "right" });
      this.pdf.text("TP. QUITAÇÃO", 17, this.lin);
      this.pdf.text("SLD.ANT.", 90, this.lin, { align: "right" });
      this.pdf.text("CRÉDITO", 110, this.lin, { align: "right" });
      this.pdf.text("DÉBITO", 130, this.lin, { align: "right" });
      this.pdf.text("SALDO PER.", 160, this.lin, { align: "right" });
      this.pdf.text("SALDO ATUAL", 190, this.lin, { align: "right" });
      this.onAddLine(this.jump + 1);
      var somaSaldo = 0;
      var somaSaldoAtual = 0;

      for (var index in this.sldAntQuitacao) {
        this.pdf.setFont("courier");
        this.pdf.setFontSize(7.5);
        this.pdf.setFont('helvetica',"normal");
        this.pdf.text(
          String(this.sldAntQuitacao[index].tqui_codigo),
          15,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(this.sldAntQuitacao[index].tqui_descricao),
          17,
          this.lin
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              this.sldAntQuitacao[index].saldo_anterior
            )
          ),
          90,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              this.sldAntQuitacao[index].credito
            )
          ),
          110,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              this.sldAntQuitacao[index].debito
            )
          ),
          130,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              Number(this.sldAntQuitacao[index].credito) - Number(this.sldAntQuitacao[index].debito)
            )
          ),
          160,
          this.lin,
          { align: "right" }
        );
        this.pdf.text(
          String(
            this.$options.filters.moeda(
              Number(this.sldAntQuitacao[index].saldo_anterior) + (Number(this.sldAntQuitacao[index].credito) - Number(this.sldAntQuitacao[index].debito))
            )
          ),
          190,
          this.lin,
          { align: "right" }
        );
        somaSaldo += Number(this.sldAntQuitacao[index].credito - this.sldAntQuitacao[index].debito);
        somaSaldoAtual += Number(this.sldAntQuitacao[index].saldo_anterior) + (Number(this.sldAntQuitacao[index].credito) - Number(this.sldAntQuitacao[index].debito));
        this.onAddLine(this.jump);
      }
      this.pdf.setFont("courier");
      this.pdf.setFont('helvetica',"bold");
      this.pdf.setFontSize(7.5);
      this.pdf.text("MOVIMENTO PERÍODO", 130, this.lin, { align: "right" });
      this.pdf.text(
        String(this.$options.filters.moeda(somaSaldo)),
        160,
        this.lin,
        { align: "right" }
      );
      this.pdf.text(
        String(this.$options.filters.moeda(somaSaldoAtual)),
        190,
        this.lin,
        { align: "right" }
      );
      this.onAddLine(this.jump + 1);

      /* FIM RESUMOS */
                              
      this.onFooterPDF()
      this.onInsertNumberPagesPDF()

      const uuidv4 = this.$uuid.v4();
      const urlPDF = this.pdf.output('blob');
      this.localOutputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
      this.$bvModal.show("relatorio");
    },
  },  
  watch: {
    localOutputPdf(newValue, oldValue) {
      //this.$emit("update:outputPdf", newValue);
    }
  }
};
</script>
