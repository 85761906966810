<template>
  <mct-padrao :titulo="titulo" :modo="modo" botoes="false">
    <div slot="formulario">
      <div class="row">
        <div class="col-lg-12">
          <mct-form-itens coluna="0" tipo="select" campo="cur_codigo" :valor="lista" titulo="Curso" obrigatorio="true" :errors="errors" 
            origem="esc_cursos" origemChave="cur_codigo" origemRetorno='cur_nome' origemChaveComposta="true" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <mct-form-itens coluna="0" tipo="select" campo="ser_codigo" :valor="lista" titulo="Módulo" obrigatorio="true" :errors="errors" 
            origem="esc_serie" origemChave="ser_codigo" origemRetorno='ser_descricao' origemChaveComposta="true" 
            :origemFiltro="{cur_codigo: lista.cur_codigo}"
            :modo="modo"></mct-form-itens>
        </div>
        <div class="col-lg-6">
          <mct-form-itens coluna="0" tipo="select" campo="turn_codigo" :valor="lista" titulo="Turno" obrigatorio="true" :errors="errors" 
            origem="esc_turno" origemChave="turn_codigo" origemRetorno='turn_descricao' origemChaveComposta="true" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <!--<div class="row">
        <div class="col-lg-12">
          <mct-form-itens coluna="0" tipo="select" campo="disp_codigo" :valor="lista" titulo="Disciplina" obrigatorio="true" :errors="errors" 
            origem="esccursosdisciplinas" origemChave="disp_codigo" origemRetorno='disp_nome'
            :origemFiltro="{ser_codigo: lista.ser_codigo, turn_codigo: lista.turn_codigo}" 
            origemChaveComposta="true" :modo="modo"></mct-form-itens>
        </div>
      </div>-->
      <div class="row">
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="select" campo="turm_existente" :valor="lista" titulo="Seleção de Turma" obrigatorio="true" :errors="errors" 
            :origemData="[{value: 0, text: 'NOVA'},{value: 1, text: 'EXISTENTE'}]" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-lg-2" v-show="lista.turm_existente == 1">
          <mct-form-itens coluna="0" tipo="select" campo="turm_codigo" :valor="lista" titulo="Turma" obrigatorio="true" :errors="errors" 
            origem="esc_turmas" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemChaveComposta="true" 
            :origemFiltro="{/*ser_codigo: lista.ser_codigo, */turn_codigo: lista.turn_codigo}"
            :modo="modo" @setDataLista="setTurma"></mct-form-itens>
        </div>
        <div class="col-lg-2" v-show="lista.turm_existente == 0">
          <mct-form-itens coluna="0" tipo="number" campo="turm_sequencia" :valor="lista" titulo="Turma Identificação" obrigatorio="true" :errors="errors" :modo="lista.turm_existente == 0 ? modo : 'readonly'"></mct-form-itens>    
        </div>
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="date" campo="horari_data_inicial" :valor="lista" titulo="Data Início" :errors="errors" :modo="modo" ></mct-form-itens>
        </div>
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="date" campo="horari_data_final" :valor="lista" titulo="Data Fim" :errors="errors" :modo="modo" ></mct-form-itens>
        </div>
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="number" campo="turm_numero_alunos" :valor="lista" titulo="Nº de Alunos" :errors="errors" :modo="lista.turm_existente == 0 ? modo : 'readonly'" ></mct-form-itens>
        </div>
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="number" campo="turm_maximo_alunos" :valor="lista" titulo="Nº Máximo de Alunos" :errors="errors" :modo="lista.turm_existente == 0 ? modo : 'readonly'" ></mct-form-itens>
        </div>
      </div>
      <div class="row">
          <div class="col-lg-6">
              <mct-form-itens coluna="0" tipo="textarea" campo="horaridia_observacoes" :valor="lista" titulo="Observações" obrigatorio="true" :errors="errors" :modo="lista.turm_existente == 0 ? modo : 'readonly'"></mct-form-itens>    
          </div>
      </div>

      <fieldset class="well">
        <legend class="well-legend">Seleção de Dias (Etapas)</legend>
        <div v-show="!showHorarios">
          <div class="d-flex flex-wrap justify-content-center mct-calendar-status">
              <div>
                  <span>Qtd. Dias:</span>
                  <h1 class="mct-bg-red">{{lista.turm_totaldias}}</h1>
              </div>
              <div>
                  <span>Qtd. Dias Selecionados:</span>
                  <h1 class="mct-bg-green">{{lista.turm_totalselecionados}}</h1>
              </div>
              <div>
                  <span>Carga Horária(hs):</span>
                  <h1 class="mct-bg-green">{{lista.turm_totalhoras}}</h1>
              </div>
          </div>

          <ul class="mct-calendar d-flex flex-wrap justify-content-center">
            <li v-for="(dia,index) in onGeraDiasBranco()" :key="'bb'+index">

            </li>
            <li v-for="(dia,index) in lista.horari_dias" :key="index" :class="dia.selecionado == 0 ? 'unselect' : 'select'">            
              <div class="mct-calendar-left float-left" :class="typeof dia.feriado != 'undefined' && dia.feriado === 1 ? 'mct-calendar-fer' : dia.horaridia_diasem == 0 ? 'mct-calendar-dom' : ''">
                <a href="#" @click.prevent.stop="onSelectCal(index)" title="Clique para Selecionar ou Deselecionar o Dia">
                  <span class="mct-calendar-mes">{{moment(dia.horaridia_data).locale("pt-br").format('MMM').toUpperCase()}}</span>
                  <span class="mct-calendar-dia">{{moment(dia.horaridia_data).locale("pt-br").format('DD')}}</span>
                  <span class="mct-calendar-diasem">{{moment(dia.horaridia_data).locale("pt-br").format('ddd').toUpperCase()}}</span>
                </a>
              </div>
                <a href="#" tabindex="-1" title="Ver Horários Livres" @click.prevent.stop="verHorarios(dia,index)">
              <div class="mct-calendar-right float-right">
                  <div class="form-group m-0 p-0">
                    <label class="m-0 p-0" for="cal-hora">Horários Sel.:</label>  
                    <input class="form-control m-0 p-0" type="text" name="cal-hora" v-mask="'##:##'" v-model="dia.horaridiadisp_hora" :disabled="dia.selecionado == 0" :title="dia.selecionado == 1 ? 'Clique para Alterar a Hora' : ''">
                  </div>
              </div>
                </a>
            </li>
          </ul>
        </div> 
        <horarios v-show="showHorarios" :data="diaSelecionado" :turma="lista.turm_codigo" :curso="lista.cur_codigo" :linha="lista.horari_dias[linhaSelecionada]" :duracao="lista.turn_duracao_aula" :lista="lista" @setHora="showHorarios = !showHorarios"/>
       
      </fieldset>

      <div style="margin-bottom: 5px;">
          <div class="h-divider clear"></div>
          <button type="button" class="btn btn-success" aria-label="Left Align"  @click.prevent.stop="onSubmit($event)" v-if="modo !== 'deletar' && modo !== 'visualizar' && modo !== 'readonly'">
                  <span class="fa fa-check fa-lg" aria-hidden="true"></span> Gravar Agendamento </button>
          <button type="button" class="btn btn-danger" aria-label="Left Align" @click.prevent.stop="onCancelar($event)" v-if="modo === 'deletar'">
                  <span class="fa fa-trash-o fa-lg" aria-hidden="true"></span> Cancelar Agendamento </button>
          <button type="button" class="btn btn-dark float-right" aria-label="Left Align" @click.prevent.stop="$router.back()">
                  <span class="fa fa-times fa-lg" aria-hidden="true"></span> Fechar </button>
      </div>                 
    </div>
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import MctPadrao from '@/components/comum/MctFormPadraoSCB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctModal from '@/components/comum/MctModal.vue'

import Horarios from '@/components/modulos/siges/EscAgendamentosHorarios.vue'


export default {
  Name: 'Agendamentos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      MctModal,
      Horarios
  },
  data () {
      return {
        data: {},
        dataDias: [],
        dataFeriados: [],
        lista: {
            horari_ent: null,            
            horari_esc: null,
            horari_ano: null,
            cur_codigo: null,
            ser_codigo: null,
            turm_existente: 0,
            turm_codigo: null,
            turm_sequencia: null,            
            horari_data_inicial: null,
            horari_data_final: null,
            turm_numero_alunos: null,
            turm_maximo_alunos: null,
            turn_codigo: null,
            turn_duracao_aula: null,
            crit_codigo: null,
            turm_totaldias: 0,
            turm_totalselecionados: 0,
            turm_totalhoras: 0.00,
            horari_dias: [],
            turm_status: 0,
            horaridia_observacoes: ''
        },
        errors:{},
        message: '',
        status: 0,
        turma: [],
        turmaSelecionada: {},
        diaSelecionado: null,
        linhaSelecionada: null,
        showHorarios: false
      }
  },
  methods: {
    verHorarios (linha,index) {
      this.diaSelecionado = moment(linha.horaridia_data).locale("pt-br").format('YYYY-MM-DD')
      this.linhaSelecionada = index
      //$('#MctModalHorarios').modal('show')
      this.showHorarios = true
    },
    onGeraDiasBranco () {
      let diasem = 0
      if (typeof this.lista !== 'undefined' && typeof this.lista.horari_dias !== 'undefined' && typeof this.lista.horari_dias[0] !== 'undefined'){
        diasem = this.lista.horari_dias[0].horaridia_diasem
      }
      let ret = []
      while (diasem > 0){
        ret.push({diasem: diasem})    
        diasem--
      }
      return ret
    }, 
    async getData() {
      let URL = ''
      if (Object.keys(this.$route.params).length > 1){
        for (var index in this.$route.params){
          if (URL === ''){
            URL += '?' +index +'='+ this.$route.params[index]
          }else{
            URL += '&' + index +'='+ this.$route.params[index]            
          }
        }
      }    
      if (typeof this.source != 'undefined'){
        if (this.modo !== 'novo'){
          URL = this.source + URL
        }else{
          URL = this.source
        }
        await axios.get(`${URL}`)
            .then(response => {
              this.data = response.data.data
              this.columns = response.data.columns
              this.errors = {}

              this.fillData()
            })
            .catch(response => {
              this.message = response.response.data.message
              this.errors = response.response.data.errors
              this.status = response.response.status
            })
      }
    },
    setData () {
      this.lista.horari_ent = this.Entidade            
      this.lista.horari_esc = this.Escola
      this.lista.horari_ano = this.Exercicio                  
    },
    fillData () {
      if (Object.keys(this.lista).length > 1){
        for (var index in this.lista){
          this.lista[index] = this.data[index]
        }
      this.lista.turm_existente = 1;  
      }
    },
    async getFeriados() {
      let URL = '/esccalendario/feriadosentredatas?dataini='+
        moment(this.lista.horari_data_inicial).locale("pt-br").format('YYYY-MM-DD')+
        '&datafin='+
        moment(this.lista.horari_data_final).locale("pt-br").format('YYYY-MM-DD')
      await axios.get(`${URL}`)
        .then(response => {
          this.dataFeriados = response.data.data          
        })
        .catch(response => {
          this.message = response.response.data.message
          this.errors = response.response.data.errors
          this.status = response.response.status
        })
    },
    async getDias() {
      let URL = 'escagendamento/dias?cur_codigo='+this.lista.cur_codigo+'&turm_codigo='+this.lista.turm_codigo//+'&disp_codigo='+this.lista.disp_codigo     
      await axios.get(`${URL}`)
        .then(response => {
          this.dataDias = response.data.data
        })
        .catch(response => {
          this.message = response.response.data.message
          this.errors = response.response.data.errors
          this.status = response.response.status
        })
    },
    fillDataDias () {
      //console.log('entrou')
      for (var i = 0; i < this.dataDias.length; i++){
          this.dataDias[i]
          let data = this.dataDias[i].horaridia_data
          var ret = this.lista.horari_dias.filter(function(el) {
            return moment(el.horaridia_data).locale("pt-br").format('YYYY-MM-DD') == moment(data).locale("pt-br").format('YYYY-MM-DD')
          })
          //console.log(ret)
          var index = this.lista.horari_dias.indexOf(ret[0])
          if (index >= 0){
            this.lista.horari_dias[index].selecionado           = 1
            this.lista.horari_dias[index].feriado               = 1
            //console.log(this.dataDias[i].horarios)
            this.lista.horari_dias[index].horarios              = this.dataDias[i].horarios            
            //this.lista.horari_dias[index].horaridiadisp_hora    = this.dataDias[i].horaridiadisp_hora
            //this.lista.horari_dias[index].horaridiadisp_duracao = this.dataDias[i].horaridiadisp_duracao
          }
        this.lista.turm_totalselecionados = i+1  
      }       
    },
    fillFeriados () {
      for (var i = 0; i < this.dataFeriados.length; i++){
          this.dataFeriados[i]
          let data = this.dataFeriados[i].calen_dia
          var ret = this.lista.horari_dias.filter(function(el) {
            return moment(el.horaridia_data).locale("pt-br").format('YYYY-MM-DD') == moment(data).locale("pt-br").format('YYYY-MM-DD')
          })
          var index = this.lista.horari_dias.indexOf(ret[0])
          if (index >= 0){
            this.lista.horari_dias[index].feriado = 1
          }
      }       
    },        
    onGeraDias () {
      if (typeof this.lista.horari_data_inicial != 'undefined' && this.lista.horari_data_inicial != null 
          && typeof this.lista.horari_data_final != 'undefined' && this.lista.horari_data_final != null){            
            let dias = moment(this.lista.horari_data_final).diff(this.lista.horari_data_inicial,'days')
            dias++
            this.lista.turm_totaldias = dias
            this.lista.turm_totalselecionados = 0
            this.lista.horari_dias = []
            let dataBase = this.lista.horari_data_inicial
            var diasSel = 0
            var ret = []
            for (var i = 0; i < dias; i++){                
                var data = moment(dataBase).add(i, 'days')
                if (typeof this.data.horari_dias !== 'undefined'){
                  ret = this.data.horari_dias.filter(function(el) {
                    return el.horaridia_data == moment(data).locale("pt-br").format('YYYY-MM-DD');
                  })
                }                
                if (ret.length > 0){
                  this.lista.horari_dias.push({
                    selecionado: 1,
                    feriado: 0,
                    horaridia_seq: i+1,
                    horaridia_data: ret[0]['horaridia_data'], 
                    horarios: ret[0]['horarios'],            
                    //horaridiadisp_hora: ret[0]['horaridiadisp_hora'], 
                    //horaridiadisp_duracao: ret[0]['horaridiadisp_duracao'],
                    horaridia_diasem: moment(data).locale("pt-br").format('d')
                  })
                  diasSel++
                }else{
                  this.lista.horari_dias.push({
                    selecionado: 0,
                    feriado: 0,
                    horaridia_seq: i+1, 
                    horaridia_data: data,
                    horarios: [],  
                    //horaridiadisp_hora: '00:00', 
                    //horaridiadisp_duracao: '00:00',
                    horaridia_diasem: moment(data).locale("pt-br").format('d')
                  })
                }
            }
            this.lista.turm_totalselecionados = diasSel
            this.getFeriados().then(() => this.fillFeriados())
      }        
    },    
    onSubmit (e) {
      this.$swal({
        title: 'Deseja realmente gravar o agendamento?',
        text: "Este agendamento poderá apenas ser cancelado posteriormente!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.onGravar()   
            }                     
        })
    },
    async onGravar (e) {
      
      var dados = this.lista
      await axios.post(`${this.source}`,dados)
          .then(response => {
              this.message = response.data.message
              this.status = response.status
              this.lista = response.data.data
              this.errors = {}
              this.setData()
              
              this.$swal('Sucesso!',
              'Registro incluído com sucesso!',
              'success');  
          this.mode = 'readonly'
          this.$router.back()                                          
          })
          .catch(response => {
              this.message = response.response.data.message
              if (response.response.data.error){
                  this.errors = response.response.data.error.errorInfo[2]              
              }else{
                  this.errors = response.response.data.errors
              }
              this.status = response.response.status
              this.setData()
              
          })                     
    },
    onCancelar (e) {
      this.$swal({
        title: 'Deseja realmente deletar o registro?',
        text: "Este evento não podera ser revertido!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.onDeletar()   
            }                     
        })
    },
    setTurma (data) {
      this.turma = data
    },
    onSelectCal (index){
      if (this.lista.horari_dias[index].selecionado == 0) {
        this.lista.horari_dias[index].selecionado = 1
        this.lista.turm_totalselecionados++

        //this.lista.horari_dias[index].horaridiadisp_duracao = this.lista.turn_duracao_aula
      } else {
        this.lista.horari_dias[index].selecionado = 0
        this.lista.turm_totalselecionados--
        //this.lista.horari_dias[index].horaridiadisp_hora = '00:00'
        //this.lista.horari_dias[index].horaridiadisp_duracao = '00:00'
      }
    }
  },
   computed: {
    ...mapState('Login',['infoLogado','Entidade', 'Exercicio', 'Escola']), 
  },
  mounted() {
    setTimeout(() => {
      
    }, 1000)
  },  
  created() {
    if (this.modo !== 'novo'){
      this.getData()
    }
    this.setData() 
    
  },
  watch: {
    'lista.horari_data_inicial': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null) {
            this.onGeraDias()
            this.fillDataDias()
        }

         //if (moment(newVal).isValid() && moment(this.lista.horari_data_final).isValid()){
        /*if (this.modo == 'novo'){
            this.getDias().then(() =>              
              this.fillDataDias())
          }
      }*/
    },
    'lista.horari_data_final': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null) {
            this.onGeraDias()
            this.fillDataDias()
        }
    },
    'lista.turm_codigo': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
            var ret = this.turma.filter(function(el) {
                return el.turm_codigo == newVal;
            })
            this.turmaSelecionada = ret[0]
            if (typeof this.turmaSelecionada !== 'undefined'){
              //this.lista.horari_data_inicial = this.turmaSelecionada.turm_data_criacao
              //this.lista.horari_data_final = this.turmaSelecionada.turm_data_fechamento
              this.lista.turm_numero_alunos = this.turmaSelecionada.turm_numero_alunos
              this.lista.turm_maximo_alunos = this.turmaSelecionada.turm_maximo_alunos 
              this.lista.turn_duracao_aula = this.turmaSelecionada.turnoturma.turn_duracao_aula 
            }
            if (this.modo == 'novo'){
                this.getDias()
              }    
        }
    },
      
     /*'lista.horari_dias': {
      handler: function (newVal, oldVal) {
        let somaHors = 0.00
        let somaMins = 0.00
        let somaSegs = 0.00
        for (var i = 0; i < newVal.length; i++){
          var vlrMin = Number(newVal[i]['horaridiadisp_duracao'].replace(':','.'))
          somaMins += Math.trunc(vlrMin)
          somaSegs += vlrMin - Math.trunc(vlrMin)
        }
        while (somaSegs >= 60){
          somaMins += 1
          somaSegs -= 60     
        }
        while (somaMins >= 60){
          somaHors += 1
          somaMins -= 60     
        }
        this.lista.turm_totalhoras = ("00" + somaHors).slice(-2)+':'+("00" + somaMins).slice(-2)+':'+("00" + somaSegs).slice(-2)
      },
      deep: true
    },*/
  }     
}
</script>