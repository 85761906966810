<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <div class="row">
        <div class="col-2">
          <mct-form-itens tipo="number" campo="even_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mct-form-itens tipo="search" tamanho="2" campo="even_turm_codigo" :valor="lista" titulo="Turma" :errors="errors" obrigatorio="true" origem="esc_turmas" origemChave="turm_codigo" origemRetorno='turm_completa' @setDataRetorno="setTurma" origemChaveComposta="true" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mct-form-itens tipo="select" campo="even_disp_codigo" :valor="lista" titulo="Disciplina" :errors="errors" obrigatorio="true" origemChave="profdis_disp_codigo" origemRetorno='disp_nome' origemRetornoSec="profdis_disp_codigo" :origemData="disciplinasLista" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mct-form-itens tipo="select" campo="even_alu_codigo" :valor="lista" titulo="Aluno" obrigatorio="false" :origemData="matriculasLista" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo' :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <mct-form-itens tipo="date" campo="even_data_inicio" :valor="lista" titulo="Data Início" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-2">
          <mct-form-itens tipo="text" campo="even_hora_inicio" :valor="lista" titulo="Hora Início" mascara="hora" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <mct-form-itens tipo="date" campo="even_data_termino" :valor="lista" titulo="Data Término" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-2">
          <mct-form-itens tipo="text" campo="even_hora_termino" :valor="lista" titulo="Hora Término" mascara="hora" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-3">
          <mct-form-itens tipo="select" campo="even_classificacao" :valor="lista" titulo="Classificação" 
          :origemData="[{value:1,text:'AVISO'}, {value:2,text:'COMUNICADO'}, {value:3,text:'IMPORTANTE'}]"
          obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mct-form-itens tipo="textarea" campo="even_texto" :valor="lista" titulo="Texto" linhas="4" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>

    </div>
  </mct-padrao>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import moment from 'moment'

export default {
  Name: 'EscEventos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
        turmaSelecionada: {}
      }
  },
  methods: {
    setTurma(data){
      this.turmaSelecionada = data
    },    
    async setData (lista, colunms, mode, errors) {
      this.lista = lista
      this.errors = errors
      if (this.modo == 'novo'){
        this.lista.even_classificacao = 1    
        this.lista.even_ent = this.Entidade    
        this.lista.even_esc = this.Escola    
        this.lista.even_ano = this.Exercicio    
        this.lista.audit_data_cadastro = this.moment(new Date()).format('YYYY-MM-DD')
        this.lista.audit_user_cadastro = this.infoLogado['usu_usuario'].toUpperCase()       
        this.lista.audit_local_cadastro = this.IP       
      } else if (this.modo == 'editar'){
        this.lista.audit_data_atualiza = this.moment(new Date()).format('YYYY-MM-DD')
        this.lista.audit_user_atualiza = this.infoLogado['usu_usuario'].toUpperCase()       
        this.lista.audit_local_atualiza = this.IP
      }
    },
  },
   computed: {
    ...mapState('Login',['Entidade','Escola','Exercicio','infoLogado']),
    disciplinasLista () {
      let ret = []
      if (this.turmaSelecionada && this.turmaSelecionada.disciplinas){
          ret = this.turmaSelecionada.disciplinas;
      }
      return ret
    },
    matriculasLista () {
      let ret = []
      if (this.turmaSelecionada && this.turmaSelecionada.matriculas){
          ret = this.turmaSelecionada.matriculas;
      }
      return ret
    }
  },    
}
</script>