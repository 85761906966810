<template>
    <div>        
        <div class="row mb-4">
            <div class="col-lg-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Turma</legend>
                    <div class="row">                                                        
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_ini" :valor="filtro" titulo="Inicial" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_ini" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_fin" :valor="filtro" titulo="Final" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_fin" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-sm-12 text-left">
                <button type="button" class="btn btn-secondary mr-2" aria-label="Left Align" @click="onAlunosSUS()">
                <span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Visualizar</button>     
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/http'
  
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios'
  
export default {
    components: {
        'MctFormItens': MctFormItens,
    },
    mixins: [MctPadraoRelatorios],
    data() {
        return {
            data: [],
            outputPdf: null,
            filtro: {
                turma_ini: null,
                turma_fin: null,
                agrupa_agendamento: null,
                situacao_agendamento: 9,
                agendamento: null,
                assinatura: 0,
                etap_codigo: null,
                aluno: null,
                modelo: 1,
                tipo: 2
            }            
        }
    },
    methods: {
        async getData() {
            var url = '/alunosturma';

            await axios.get(`/relatorios/siges${url}`,{params:  {
                turmaini: this.filtro.turma_ini,
                turmafin: this.filtro.turma_fin,
                agrupa_agendamento: this.filtro.agrupa_agendamento,
                situacao_agendamento: this.filtro.situacao_agendamento,
                agendamento: this.filtro.agendamento,
                etap_codigo: this.filtro.etap_codigo,
                aluno: this.filtro.aluno,
                tipo: this.filtro.tipo
            },
            paramsSerializer: function (params) {
                return jQuery.param(params)
            }
            })
            .then(response => {
                this.data = response.data.data                    
            })
            .catch(response => {
                console.log(response.message)
            })
            
        },

        async onAlunosSUS () {

            await this.getData();

            await this.onCreatePDF()
            /*CABEÇALHO*/
            this.title = 'FICHA ALUNOS';
            this.onHeaderPDF();
            this.pdf.setFont('courier') 
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)     
                    
            if(this.filtro.turma_ini){
                this.pdf.text(`TURMA ${this.filtro.turma_ini} ATÉ ${this.filtro.turma_fin}`,8,this.lin)
                this.onAddLine(this.jump)
            }

            //this.onAddLine(this.jump)

            //var grupo = 0
            var qtdAlunos = 0;

            this.pdf.setFillColor(192,192,192)
            this.pdf.rect(8,this.lin-3,195,(this.jump)+1,'FD')
            this.pdf.setFont('helvetica','bold')
            this.pdf.setFontSize(6)
            this.pdf.text('R.A.',16,this.lin,{align: 'right'})
            this.pdf.text('ALUNO',18,this.lin)
            this.pdf.text('DT.NASC.',78,this.lin)
            this.pdf.text('CARTÃO SUS',90,this.lin)
            this.pdf.text('ENDEREÇO COMPLETO',109,this.lin)
            this.onAddLine(this.jump)

            for (var index in this.data) {
                this.pdf.setFont('courier') 
                /*TURMA*/
                // if (grupo !== this.data[index].turm_codigo){
                //     grupo = this.data[index].turm_codigo
                //     this.pdf.setFillColor(192,192,192)
                //     this.pdf.rect(8,this.lin-3,195,this.jump+1,'FD')
                //     this.pdf.setFont('courier') 
                //     this.pdf.setFontSize(7.5)
                //     this.pdf.setFont('helvetica','bold') 
                //     this.pdf.text('TURMA:',10,this.lin,)
                //     this.pdf.text(this.data[index].turm_identificacao+' - '+this.data[index].turm_codigo,22,this.lin)
                //     this.pdf.text('TURNO:',90,this.lin)
                //     this.pdf.text(this.data[index].turn_descricao+' - '+this.data[index].turn_turno,102,this.lin)
                //     this.onAddLine(this.jump+1)

                //     this.pdf.setFillColor(192,192,192)
                //     this.pdf.rect(8,this.lin-3,195,(this.jump*2)+1,'FD')
                //     this.pdf.setFont('helvetica','bold')
                //     this.pdf.setFontSize(6)
                //     this.pdf.text('R.A.',16,this.lin,{align: 'right'})
                //     this.pdf.text('ALUNO',18,this.lin)
                //     this.pdf.text('DT.NASC.',78,this.lin)
                //     this.pdf.text('CARTÃO SUS',93,this.lin)
                //     this.pdf.text('ENDEREÇO COMPLETO',118,this.lin)
                //     this.onAddLine(this.jump)

                // }

                this.pdf.setFont('courier') 
                this.pdf.setFont('helvetica','bold') 
                this.pdf.setFontSize(6)
                this.pdf.text(String(this.data[index].matri_codigo),16,this.lin,{align: 'right'})
                this.pdf.setFont('helvetica','normal')
                this.pdf.text(this.data[index].pes_nome,18,this.lin)
                this.pdf.text(String(this.data[index].pes_nascimento !== null ? this.$options.filters.data(this.data[index].pes_nascimento) : ''),78,this.lin)
                this.pdf.text(String(this.data[index].pes_cartao_sus !== null ? this.data[index].pes_cartao_sus : ''),90,this.lin)
                this.pdf.text(String(this.data[index].pes_cep !== null ? this.data[index].pes_cep : '')+', '+String(this.data[index].pes_logradouro !== null ? this.data[index].pes_logradouro : '')+', '+String(this.data[index].pes_logra_numero !== null ? this.data[index].pes_logra_numero : 'S/N')+'-'+String(this.data[index].pes_bairro !== null ? this.data[index].pes_bairro : ''),109,this.lin)
                this.onAddLine(this.jump)
                qtdAlunos++;

                // if (parseInt(index)+1 == this.data.length || grupo !== this.data[parseInt(index)+1 < this.data.length ? parseInt(index)+1 : index].turm_codigo){
                if (parseInt(index)+1 == this.data.length){
                    this.onAddLine(1)
                    this.pdf.setFont('courier') 
                    this.pdf.setFontSize(6)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text(String(`TOTAL DE ALUNOS: ${qtdAlunos}`),10,this.lin)
                    this.pdf.line(8,this.lin-2,(this.widthPage+8),this.lin-2)
                    this.onAddLine(this.jump+1)
                    this.pdf.line(8,this.lin-this.jump,(this.widthPage+8),this.lin-this.jump)
                    this.onAddLine(this.jump)
                    qtdAlunos = 0;
                }
            }
                                               
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            const urlPDF = this.pdf.output('blob');
            this.outputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
            this.$emit('update:outputPdf', this.outputPdf);
        },
        
        retornoValorOuVAzio (value) {
            if ((value === null) || (!value)) {
                return ''
            } else {
                return value
            }
        }
    },

}
</script>