<template>
  <div class="dashboard">
    <header>
        <mct-form-itens tipo="select" campo="fnl_codigo" :valor="$data" :errors="{}" 
            origem="crm_funil" origemChave="fnl_codigo" origemRetorno='fnl_descricao' modo="novo" style="width: 300px; margin-right: 13px;" showBotoes=false></mct-form-itens>
        <div class="row m-0">
            <!--<button type="button" class="btn btn-success ml-2 mr-2 p-1" aria-label="Left Align"  @click.prevent.stop="onNovaOportunidade()">
                <span class="fa fa-plus fa-lg ml-1 mr-1" aria-hidden="true"></span> Nova Opotunidade</button>
            <button type="button" class="btn btn-info ml-2 mr-2 p-1" aria-label="Left Align"  @click.prevent.stop="onNovaMeta()">
                <span class="fa fa-dollar fa-lg ml-1 mr-1" aria-hidden="true"></span> Configurar Metas</button>
            -->
            <button type="button" class="btn ml-2 p-1 bg-light" aria-label="Left Align" title="Modo Lista" @click.prevent.stop="$router.push(`/crm/funil/${funil.fnl_codigo}`)">
                <span class="fa fa-list fa-lg" aria-hidden="true"></span></button>
            <button type="button" class="btn ml-1 p-1 bg-light" aria-label="Left Align" title="Modo Kaban" @click.prevent.stop="$router.push(`/crm/funil/${funil.fnl_codigo}`)">
                <span class="fa fa-th-large fa-lg" aria-hidden="true"></span></button>
            <button type="button" class="btn ml-1 p-1 bg-light" aria-label="Left Align" title="Atualizar dados" @click.prevent.stop="setFunil()">
                <span class="fa fa-refresh fa-lg" aria-hidden="true"></span></button>
        </div>
    </header>
    <div class="funil">
        <div class="funil-cards">
            <div class="funil-card bg-green">
                <span class="funil-card-label">{{taxaConversao()}}%</span>
                <span class="funil-card-label2">{{grupoGanhou && grupoGanhou.oportunidades ? grupoGanhou.oportunidades.length : 0}} Oportunidade(s)</span>
                <span class="funil-card-info">Taxa Conversão</span>
            </div>
            <div class="funil-card bg-blue">
                <span class="funil-card-label">{{ticketMedio() | moeda}}</span>
                <span class="funil-card-label2">{{this.funil && this.funil.grupos ? this.funil.grupos.reduce((soma,item) => soma + Number(item.oportunidades.length), 0) : 0}} Oportunidade(s)</span>
                <span class="funil-card-info">Ticket Médio</span>
            </div>
            <div class="funil-card bg-red">
                <span class="funil-card-label">{{this.funil && this.funil.metas ? this.funil.metas.reduce((soma,item) => soma + Number(item.mts_valor), 0) : 0 | moeda}}</span>
                <span class="funil-card-label2">{{this.funil && this.funil.metas ? this.funil.metas.length : 0}} Meta(s)</span>
                <span class="funil-card-label3">{{this.funil && this.funil.grupos ? this.funil.grupos.reduce((soma,item) => soma + Number(item.oportunidades.length), 0) : 0}} Oportunidade(s)</span>
                <span class="funil-card-info">Metas a Cumprir</span>
            </div>
            <div class="funil-card bg-green">
                <span class="funil-card-label">{{grupoGanhou && grupoGanhou.oportunidades ? grupoGanhou.oportunidades.reduce((soma,item) => soma + Number(item.oprt_vlr_estimado), 0) : 0 | moeda}}</span>
                <span class="funil-card-label2">{{this.funil && this.funil.metas ? this.funil.metas.length : 0}} Meta(s)</span>
                <span class="funil-card-label3">{{this.funil && this.funil.grupos ? this.funil.grupos.reduce((soma,item) => soma + Number(item.oportunidades.length), 0) : 0}} Oportunidade(s)</span>
                <span class="funil-card-info">Metas Cumpridas</span>
            </div>    
        </div>
        <div class="pb-2">
            <span class="badge badge-dark p-2 mr-2">% Oportunidades</span>
            <span class="badge badge-primary p-2 mr-2">Qtde Oportunidades</span>
            <span class="badge badge-success p-2">Previsão</span>
        </div>
        <div class="funil-grupos">
            <div v-for="(grupo,index) in funil.grupos" :key="index"
                class="funil-grupo"
                :style="`border-top-color:${grupo.fnlgrp_cor}; width: ${(100 + (100/(funil.grupos.length+1)) - ((grupo.fnlgrp_sequencia+1) * (100/(funil.grupos.length+1))))}%`"
            >
                <div>
                    <span class="funil-grupo-perc">{{ grupoPercentual(grupo)}} %</span>
                    <div style="width: 25%">
                        <span class="funil-grupo-label">{{grupo.fnlgrp_descricao}}</span>
                    </div>
                    <div class="funil-grupo-itens" style="width: 50%">
                        <span class="badge badge-primary p-2">{{grupo.oportunidades.length}}</span>
                    </div>
                    <div style="width: 25%; text-align: right;">
                        <span class="badge badge-success p-2">{{grupo.oportunidades.reduce((soma,item) => soma + Number(item.oprt_vlr_estimado), 0) | moeda}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="funil-resumo">
            <div>
                <span class="badge badge-success p-2">Ganhos</span>
                <span>{{grupoGanhou && grupoGanhou.oportunidades ? grupoGanhou.oportunidades.length : 0}}</span>
            </div>
            <div>
                <span class="badge badge-success p-2">Valor Ganho</span>
                <span>{{grupoGanhou && grupoGanhou.oportunidades ? grupoGanhou.oportunidades.reduce((soma,item) => soma + Number(item.oprt_vlr_estimado), 0) : 0 | moeda}}</span>
            </div>
            <div>
                <span class="badge badge-primary p-2">Taxa de Conversão</span>
                <span>{{taxaConversao()}}%</span>
            </div>
            <div>
                <span class="badge badge-danger p-2">Perdas</span>
                <span>{{grupoPerdeu && grupoPerdeu.oportunidades ? grupoPerdeu.oportunidades.length : 0}}</span>
            </div>
            <div>
                <span class="badge badge-danger p-2">Valor Perdido</span>
                <span>{{grupoPerdeu && grupoPerdeu.oportunidades ? grupoPerdeu.oportunidades.reduce((soma,item) => soma + Number(item.oprt_vlr_estimado), 0) : 0 | moeda}}</span>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from '@/http';

import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
    name: 'EscModCRM',
    components: {
        MctFormItens,
    },
    data () {
        return {
            fnl_codigo: null,
            funil: []
        }
    },
    methods: {
        async setFunil () {
            await axios.get(`/dashboard/crm/${this.fnl_codigo}`)
                .then(response => {
                    this.funil = response.data.data
                    if (!this.funil) {
                        this.$router.push('/crm/funil');
                    } else 
                    if (this.funil.fnl_tipo === 0){
                        this.$router.push(`/crm/funil/${this.funil.fnl_codigo}`);
                    }
                })
                .catch(response => {
                    //console.log(response)
                })
        },        
        grupoPercentual (grupo){
            const totalOportunidades = this.funil && this.funil.grupos ? this.funil.grupos.reduce((soma,item) => soma + Number(item.oportunidades.length), 0) : 0;
            const totalGrupo = grupo ? grupo.oportunidades.length : 0;
            const percentual =  totalOportunidades === 0 ? 0 : Number((totalGrupo/totalOportunidades)*100).toFixed(2);
            return percentual;
        },
        taxaConversao (){
            const totalOportunidades = this.funil && this.funil.grupos ? this.funil.grupos.reduce((soma,item) => soma + Number(item.oportunidades.length), 0) : 0;
            const totalGanho = this.grupoGanhou && this.grupoGanhou.oportunidades ? this.grupoGanhou.oportunidades.length : 0;
            const percentual =  totalOportunidades === 0 ? 0 : Number((totalGanho/totalOportunidades)*100).toFixed(2);
            return percentual;
        },
        ticketMedio (){
            const totalOportunidades = this.funil && this.funil.grupos ? this.funil.grupos.reduce((soma,item) => soma + Number(item.oportunidades.length), 0) : 0;
            const totalValor = this.funil && this.funil.grupos && this.funil.grupos.map !== undefined ? this.funil.grupos.map(ele => ele && ele.oportunidades && ele.oportunidades.reduce ? ele.oportunidades.reduce((soma,item) => soma + Number(item.oprt_vlr_estimado), 0) : 0) : [];
            const media =  totalValor && totalValor[0] ? Number(totalValor.reduce((soma,item) => soma + Number(item))/totalOportunidades).toFixed(2) : 0;
            return media;
        },

    },
    watch: {
        fnl_codigo  () {
            this.setFunil();
        }
    },
    computed: {
        grupoGanhou () {
            const grupo = this.funil && this.funil.grupos ? this.funil.grupos.filter(ele => ele.fnlgrp_tipo === 2) : [];
            return grupo && grupo[0] ? grupo[0] : [];
        },
        grupoPerdeu () {
            const grupo = this.funil && this.funil.grupos ? this.funil.grupos.filter(ele => ele.fnlgrp_tipo === 3) : [];
            return grupo && grupo[0] ? grupo[0] : [];
        },
    },
    async created () {
        await this.setFunil();
    }
}
</script>

<style lang="scss" scoped>

:root {
  --indexdiv: 1;
}


.dashboard header {
    padding: 20px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.funil {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    //overflow: hidden;
}

.funil-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 0;
}

.funil-card {
    position: relative;
    width: 24%;
    min-height: 150px;
    /*border: 1px solid #c1c1c1;*/
    border-radius: 4px;
    padding: 10px;
}

.funil-card-label {
    position: absolute;
    font-size: 2.2rem;
    font-weight: 700;
    color: var(--light);
    top: 5px;   
}

.funil-card-label2 {
    position: absolute;
    font-size: 1rem;
    font-weight: 400;
    color: var(--light);
    top: 50px;
}

.funil-card-label3 {
    position: absolute;
    font-size: 1rem;
    font-weight: 400;
    color: var(--light);
    top: 70px;
}


.funil-card-info {
    position: absolute;
    font-size: 1.6rem;
    color: var(--light);
    bottom: 5px;
}

.funil-grupos {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;    
    width: 100%;
    height: 100%;

}

.funil-grupo {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    border-top: 70px solid;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    
    //height: 70px;    
    /*width: 500px;*/
     /*animation: go-back 1s;*/
}

.funil-grupo .badge {
    font-size: 0.8rem;
}

.funil-grupo > div {
    position: relative;
    width: 100%;
    margin-top: -70px;
    /*height: 70px;*/
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /*text-align: center;*/
    z-index: inherit;
}

.funil-grupo-label {
    font-size: 1rem;
    font-weight: 500;
    color: var(--light);
    z-index: inherit;
}

.funil-grupo-perc {
    position: absolute;
    font-size: 0.8rem;
    font-weight: 500;
    background-color: var(--dark);
    color: var(--light);
    padding: 5px;
    border-radius: 10px;
    bottom: -15px;
    left: -15px;
    z-index: inherit;
}

.funil-grupo > div > span {
    /*font-size: 1rem;
    font-weight: 400;
    padding: 5px;*/
    text-align: center;
    z-index: inherit;
}

.funil-grupo-itens {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;    
    z-index: inherit;
}

/*.funil-grupo > div > div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.funil-grupo > div > div > span {
    font-size: 0.8rem;
    padding: 5px;
    text-align: center;
}*/

.funil-resumo {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.funil-resumo > div {
    width: 19%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.funil-resumo > div > span:first-child {
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
}

.funil-resumo > div > span:last-child {
    font-size: 2.2rem;
    color: var(--dark);
    padding: 0;
    margin: 0;
    line-height: 40px;
}

.trapezio {
    border-bottom: 70px solid #c1c1c1;
    border-left: 30px solid transparent;
    border-right: 15px solid transparent;
    height: 0;
    width: 120px;
}

.paralelograma {
    width: 150px;
    height: 70px;
    -webkit-transform: skew(-20deg);
    -moz-transform: skew(-20deg);
    -o-transform: skew(-20deg);
    background: #c1c1c1;
}

@for $i from 1 through 36 { 
    .funil-grupo:nth-child(#{$i}) {
        z-index: calc(36 - #{$i});
        -webkit-animation: all 2s; /* Chrome, Safari, Opera */ 
        animation: all 2s;
        animation-fill-mode: forwards;     
        --indexdiv: #{$i};
    }
}

@keyframes all {
    0%   {top: 0px; height: 0px; opacity: 0;}
    100% {top: calc(var(--indexdiv) * 70px); height: calc(var(--indexdiv) * 70px); opacity: 1;}
}


@keyframes fadein {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes fadeout {
  from {opacity: 1}
  to {opacity: 0}
}



</style>