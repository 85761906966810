<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#cadastro" role="tab">Caixa</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#financeiro" role="tab">Vinc. Finan./Contábil</a>
                </li>
            </ul>
            <!-- TABS -->
            <div class="tab-content">
                <div class="tab-pane active" id="cadastro" role="tabpanel">             
                    <div class="row">
                        <div class="col-sm-2">
                            <mct-form-itens coluna="0" tipo="number" campo="cxa_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                        </div>    
                        <div class="col-lg-2">
                            <mct-form-itens tipo="checkbox" campo="cxa_status" :valor="lista" :titulo="lista.cxa_status == 1 ? 'Cadastro Ativo.' : 'Cadastro Inativado.'" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <mct-form-itens tipo="text" campo="cxa_descricao" :valor="lista" titulo="Descrição" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <mct-form-itens tipo="date" campo="cxa_abertura" :valor="lista" titulo="Início Controle" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                        <div class="col-sm-3">
                            <mct-form-itens tipo="date" campo="cxa_encerramento" :valor="lista" titulo="Data Inativação" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                        </div>
                    </div>
                </div>
                <div class="tab-pane" id="financeiro" role="tabpanel">
                    <fieldset class="well">
                        <legend class="well-legend">Plano de Contas</legend> 
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-8 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plnc_codigo" :valor="lista" titulo="Plano de Contas" 
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>                        
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-8 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plnc_jur_codigo" :valor="lista" titulo="Plano de Contas (Juros)" 
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>                        
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-8 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plnc_mul_codigo" :valor="lista" titulo="Plano de Contas (Multa)" 
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>                        
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-8 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plnc_desc_codigo" :valor="lista" titulo="Plano de Contas (Desconto)" 
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>                        
                        <div class="row p-0 m-0 mb-2 mct-row-itens">
                            <div class="col-sm-8 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plnc_tar_codigo" :valor="lista" titulo="Plano de Contas (Tarifas)" 
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                        </div>                        
                    </fieldset>
                </div>                
            </div>
        </div>              
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'Caixa',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0
      }
  },
  methods: {
    setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            this.lista.cxa_status = 1 
            this.lista.cxa_ent = this.Entidade   
        }
    }
  },
  computed: {
        ...mapState('Login',['Entidade'])       
  }, 
  created() {
  
  }     
}
</script>