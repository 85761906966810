<template>
    <div>
        <div class="row m-0 mb-2 p-2 mct-barra-sub">
            <button type="button" class="btn btn-success" aria-label="Left Align" @click="$router.push({path: '/escextrato/banco'})">
                <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Conta BancÃ¡ria </button>
            <button type="button" class="btn btn-danger ml-1" aria-label="Left Align" @click="$router.push({path: '/escextrato/caixa'})">
                <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Caixa </button>
        </div>
        <div class="mct-form">
            <mct-topo-lista :titulo="titulo" :subtitulo="`Caixa`"></mct-topo-lista>        
            <div class="row">
                <div class="col-lg-12">
                    <mct-form-itens tipo="select" campo="conta" :valor="filtro" titulo="Caixa"  origem="esc_caixa" origemChave="cxa_codigo" origemRetorno='cxa_descricao' origemRetornoSec='cxa_codigo' @setDataLista="setContas" :errors="{}" modo="editar"></mct-form-itens>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-lg-12">
                            <mct-form-itens tipo="select" campo="ccusto" :valor="filtro" titulo="Centro de Custo"  origem="esc_centro_custo" origemChave="ccst_codigo" origemRetorno='ccst_descricao' origemRetornoSec='ccst_codigo' :errors="{}" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <mct-form-itens tipo="select" campo="tpquitacao" :valor="filtro" titulo="Tipo de Quitação"  origem="esc_tp_quitacao" origemChave="tqui_codigo" origemRetorno='tqui_descricao' origemRetornoSec='tqui_codigo' :errors="{}" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <mct-form-itens tipo="select" campo="tprecebimento" :valor="filtro" titulo="Tipo de Recebimento"  origem="esc_tp_recebimento" origemChave="trec_codigo" origemRetorno='trec_descricao' origemRetornoSec='trec_codigo' :errors="{}" modo="editar"></mct-form-itens>
                        </div>
                    </div>
                </div>    
                <div class="col-lg-4">
                    <fieldset class="well">
                        <legend class="well-legend">Data Lançamento</legend>
                        <div class="row">
                            <div class="col-lg-12 mb-2">
                                <mct-form-itens tipo="date" campo="datalanc_ini" :valor="filtro" titulo="Inicial" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <mct-form-itens tipo="date" campo="datalanc_fin" :valor="filtro" titulo="Final" showTitulo="false" :errors="{}" modo="editar"></mct-form-itens>
                            </div>
                        </div>
                    </fieldset>
                </div>
                
            </div>
            
            <div class="row mt-2 mb-2">
                <div class="col-sm-12 text-left">
                    <button type="button" class="btn btn-secondary" aria-label="Left Align" @click="getData()">
                    <span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Filtrar</button>
                    <button type="button" class="btn btn-dark" aria-label="Left Align" @click="onImprimir()">
                    <span class="fa fa-print fa-lg" aria-hidden="true"></span> Imprimir Extrato</button>     
                </div>
            </div>  
            <div class="mct-grid">
                <div class="mct-grid-scroll">         
                    <table class="table table-striped table-bordered table-sm table-hover">
                        <thead>                       
                            <tr>
                                <th @click="toggleOrder($event,'ext_data')" style="width: 80px;" class="text-right">
                                    <span>
                                        <span>EMISSÃO</span>
                                        <span class="dv-table-column" v-if="'ext_data' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>
                                <th @click="toggleOrder($event,'ext_historico')" class="text-left">
                                    <span>
                                        <span>HISTÓRICO</span>
                                        <span class="dv-table-column" v-if="'ext_historico' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'ext_debito')" style="width: 120px;" class="text-right">
                                    <span>
                                        <span>DÉBITO</span>
                                        <span class="dv-table-column" v-if="'ext_debito' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'ext_crediro')" style="width: 120px;" class="text-right">
                                    <span>
                                        <span>CRÉDITO</span>
                                        <span class="dv-table-column" v-if="'ext_crediro' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>                            
                                <th @click="toggleOrder($event,'ext_saldo')" style="width: 120px;" class="text-right">
                                    <span>
                                        <span>SALDO</span>
                                        <span class="dv-table-column" v-if="'ext_saldo' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>
                                <th @click="toggleOrder($event,'ext_tipo')" style="width: 50px;" class="text-center">
                                    <span>
                                        <span></span>
                                        <span class="dv-table-column" v-if="'ext_tipo' === query.column">
                                            <span v-if="query.direction === 'desc'">&darr;</span>
                                            <span v-else>&uarr;</span>
                                        </span>
                                    </span>
                                </th>
                            </tr>

                        </thead>
                        <tbody>
                        <tr v-for="(row,index) in $data.data" :key="index" :style="row.ext_debito > 0 && index & 1 ? 'background-color: #FEF2F4!important': row.ext_debito > 0 ? 'background-color: #FCDAE1!important' : ''">
                            <td class="text-right">{{$options.filters.data(row.ext_data)}}</td>
                            <td class="text-left">{{row.ext_historico}} <small class="text-muted">{{row.ext_conta_numero}}</small><small class="text-muted text-info" v-if="row.ext_tipo_conta !== null && row.ext_tipo_conta !== ''"> [{{row.ext_tipo_conta}}]</small> </td>
                            <td class="text-right text-danger font-weight-bold">{{row.ext_debito > 0 ? $options.filters.moeda(row.ext_debito) : ''}}</td>
                            <td class="text-right text-success font-weight-bold">{{row.ext_credito > 0 ? $options.filters.moeda(row.ext_credito) : ''}}</td>
                            <td class="text-right font-weight-bold" :class="row.ext_saldo > 0 ? 'text-success' : 'text-danger'">{{$options.filters.moeda(row.ext_saldo)}}</td>
                            <td class="text-center">
                                <span :class="row.ext_tipo === 2 ? 'badge-success' : 'badge-danger'" class="badge p-1 pl-2 pr-2 m-0">{{row.ext_tipo === 2 ? 'CR' : 'DB'}}</span>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>         
            </div>        
            <!--<mct-rodape-lista :data="$data" :query="query" @navigate="navigate" @getData="getData"></mct-rodape-lista> -->
        </div>
    </div> 
</template>

<script>
  import Vue from 'vue'
  import axios from '@/http'
  import moment from 'moment'

  import MctTopoLista from '@/components/comum/MctTopoLista.vue'
  import MctRodapeLista from '@/components/comum/MctRodapeLista.vue'
  import MctGridLista from '@/components/comum/MctGridLista.vue'
  import MctFiltroLista from '@/components/comum/MctFiltroLista.vue'
  import MctMixinsList from '@/components/funcoes/MctMixinsList'
  import MctFormItens from '@/components/comum/MctFormItens.vue'
  import MctFiltroCampo from '@/components/comum/MctFiltroCampo.vue'
  
  import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios'

  export default {
    name: 'EscExtratoBanco',
    mixins: [MctMixinsList, MctPadraoRelatorios],
    components: {
        'MctTopoLista': MctTopoLista,
        'MctRodapeLista': MctRodapeLista,
        'MctGridLista' : MctGridLista,
        'MctFiltroLista': MctFiltroLista,
        'MctFormItens': MctFormItens,
        'MctFiltroCampo': MctFiltroCampo,        
    },        
    props: ['source', 'titulo', 'showModal'],
    data() {
      return {
        filtro: {
            datalanc_ini: null,
            datalanc_fin: null,
            conta: null,
            ccusto: null,
            tpquitacao: null,
            tprecebimento: null,
        },
        contas: [],
        contaSelecionada: {}               
      }
    },
    watch: {
        source: function () {
          this.query.column = ''
          this.query.search_column = ''
          this.query.page = 1
          this.query.direction = 'asc'
          this.query.per_page = 10
          this.query.search_operator = 'equal'
          this.query.search_input = ''
          this.query.search_input2 = ''
          this.filters = {}
          this.getData()      
        }
    },    
    methods: {
        async getData() {
            if (typeof this.source !== "undefined" && typeof this.source !== "null" && this.source !== 'undefined/lista'){
            await axios.get(this.source,{params:  {
                    conta: this.filtro.conta,
                    ccusto: this.filtro.ccusto,
                    tpquitacao: this.filtro.tpquitacao,
                    tprecebimento: this.filtro.tprecebimento,
                    emiini: moment(this.filtro.datalanc_ini).isValid() ? moment(this.filtro.datalanc_ini).format('YYYY-MM-DD') : null,
                    emifin: moment(this.filtro.datalanc_fin).isValid() ? moment(this.filtro.datalanc_fin).format('YYYY-MM-DD') : null,

                    column: this.query.column,
                    direction: this.query.direction,
                    page: this.query.page,
                    per_page: this.query.per_page,
                    search_column: this.query.search_column,
                    search_operator: this.query.search_operator,
                    search_input: this.query.search_input
                },
                paramsSerializer: function (params) {
                    return jQuery.param(params)
                }
                })
                .then(response => {
                    this.data = response.data.data                    
                })
                .catch(response => {
                    console.log(response.message)
                })
            }
        },        
        navigate (page) {
                this.query.page = page
                this.getData()
        },
        toggleOrder(column) {
            if(column === this.query.column) {
            if(this.query.direction === 'desc') {
                this.query.direction = 'asc'
            } else {
                this.query.direction = 'desc'
            }
            } else {
            this.query.column = column
            this.query.direction = 'asc'
            }
            this.getData()
        },      
        setDescricao (data ){
            this.$emit('setDescricao', data)
        },
        toogleAvancado (data){
            this.showAvancado = data
        },
        setContas (data) {
            this.contas = data
        },        
        async onImprimir () {
            this.onCreatePDF()
            /*CABEÃ‡ALHO*/
            this.title = 'EXTRATO CAIXA';
            this.onHeaderPDF();            
            this.pdf.setFont('courier') 
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)   
                
            if(this.filtro.conta > 0){
                this.pdf.text(`CÓD.: ${this.contaSelecionada.cxa_codigo} DESCRIÇÃO: ${this.contaSelecionada.cxa_descricao}`,8,this.lin)
                this.onAddLine(this.jump)
            }
            if(moment(this.filtro.datalanc_ini).isValid()){
                this.pdf.text(`PERÍODO DE ${moment(this.filtro.datalanc_ini).format('DD/MM/YYYY')} A ${moment(this.filtro.datalanc_fin).format('DD/MM/YYYY')}`,8,this.lin)
                this.onAddLine(this.jump)
            }
            this.onAddLine(this.jump)
            this.pdf.setFillColor(192,192,192)
            this.pdf.rect(8,this.lin-3,195,this.jump+1,'FD')
            this.pdf.setFont('helvetica','bold') 
            this.pdf.text('EMISSÃO',25,this.lin,{align: 'right'})
            this.pdf.text('HISTÓRICO',26,this.lin)
            this.pdf.text('DÉBITO',150,this.lin,{align: 'right'})
            this.pdf.text('CRÉDITO',175,this.lin,{align: 'right'})
            this.pdf.text('SALDO',200,this.lin,{align: 'right'})
            this.onAddLine(this.jump)

            var somaDebitos = 0
            var somaCreditos = 0

            for (var index in this.data) {
                this.pdf.setFont('courier') 
                this.pdf.setFont('helvetica','normal') 
                this.pdf.setFontSize(7.5)   
                this.pdf.text(String(this.$options.filters.data(this.data[index].ext_data)) !== 'null' ? String(this.$options.filters.data(this.data[index].ext_data)) : '',25,this.lin,{align: 'right'})
                this.pdf.text(this.data[index].ext_historico.substring(0,65),26,this.lin)
                this.pdf.text(String(this.$options.filters.moeda(this.data[index].ext_debito)),150,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.moeda(this.data[index].ext_credito)),175,this.lin,{align: 'right'})
                this.pdf.text(String(this.$options.filters.moeda(this.data[index].ext_saldo)),200,this.lin,{align: 'right'})
                somaDebitos += Number(this.data[index].ext_debito)
                somaCreditos += Number(this.data[index].ext_credito)
                this.onAddRegLine()
                this.onAddLine(this.jump)
                if (this.qtdLin > this.itensPage || this.lin >= (94*this.jump)){
                    this.onFooterPDF()
                    this.page++   
                    this.pdf.addPage()
                    this.onHeaderPDF();  
                    this.lin = 39
                    this.qtdLin = 1 
                }
            } 
            /* FINAL RELATORIO */
            this.pdf.line(8,this.lin,203,this.lin)
            this.onAddLine(this.jump)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('TOTAL DE DÉBITOS(-) R$',175,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaDebitos)),200,this.lin,{align: 'right'})
            this.onAddLine(this.jump)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('TOTAL DE CRÉDITOS(+) R$',175,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(somaCreditos)),200,this.lin,{align: 'right'})
            this.onAddLine(this.jump)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('SALDO DA CONTA(=) R$',175,this.lin,{align: 'right'})
            this.pdf.text(String(this.$options.filters.moeda(this.data[this.data.length-1].ext_saldo)),200,this.lin,{align: 'right'})
            this.onAddLine(this.jump)
            this.pdf.line(8,this.lin,203,this.lin)
            this.onAddLine(this.jump)
            this.pdf.text(`QTD. REGISTROS: ${this.data.length}`,200,this.lin,{align: 'right'})

            this.onFooterPDF()

            const pages = this.pdf.internal.getNumberOfPages();
            this.pdf.setFont('times')
            this.pdf.setFont('helvetica','bold')
            this.pdf.setFontSize(7.5)
            for (let j = 1; j < pages + 1 ; j++) {
                this.pdf.setPage(j);
                this.pdf.text(`PÁGINA ${j} DE ${pages}`,200,8*this.jump,{align: 'right'})
            }

            const uuidv4 = this.$uuid.v4();
            this.pdf.save(`extrato-caixa-${uuidv4}.pdf`)
        }        
    },
    watch : {
        'filtro.conta': function (newVal, oldVal){ 
            if (typeof newVal != 'undefined' && newVal != null){        
                var ret = this.contas.filter(function(el) {
                    return el.cxa_codigo == newVal;
                })
                this.contaSelecionada = ret[0]
            }
        }           
    },
    computed: {

    }
  }
</script>