<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <div class="row">
        <div class="col-2">
          <mct-form-itens tipo="number" campo="afast_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
        </div>
        <div class="col-10">
          <mct-form-itens tipo="radio" campo="afast_tipo" titulo="Tipo Afastamento" :valor="lista" obrigatorio="false" :errors="errors" 
            :origemData="[{value:1,text:'Atestado'}, {value:0,text:'Outros'}]" 
            :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mct-form-itens tipo="select" campo="pes_codigo" :valor="lista" titulo="Funcionário/Servidor" obrigatorio="false" origem="esc_professores" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo' :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
          <div class="col-12">
              <mct-form-itens tipo="select" campo="turm_codigo" :valor="lista" titulo="Turma" obrigatorio="false" :errors="errors" origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_completa' :modo="modo"></mct-form-itens>
          </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mct-form-itens tipo="select" campo="pes_subst_codigo" :valor="lista" titulo="Funcionário/Servidor Substituto" obrigatorio="false" origem="esc_professores" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo' :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mct-form-itens tipo="textarea" campo="afast_causa_afastamento" :valor="lista" titulo="Causa Afastamento" linhas="4" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <mct-form-itens tipo="select" campo="afast_tipo_periodo" :valor="lista" titulo="Tipo Período" obrigatorio="true" 
          :origemData="[{value:1,text:'Temporário'}, {value:2,text:'Definitivo'}]" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-3">
          <mct-form-itens tipo="date" campo="afast_data_inicio" :valor="lista" titulo="Data Início" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-3" v-show="lista.afast_tipo_periodo == 1">
          <mct-form-itens tipo="number" campo="afast_dias_afastado" :valor="lista" titulo="Dias Afastado" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-3" v-show="lista.afast_tipo_periodo == 1">
          <mct-form-itens tipo="date" campo="afast_data_retorno" :valor="lista" titulo="Data Retorno" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row" v-show="lista.afast_tipo == 1">
        <div class="col-9">
          <mct-form-itens tipo="text" campo="afast_medico" :valor="lista" titulo="Médico" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-3">
          <mct-form-itens tipo="number" campo="afast_medico_crm" :valor="lista" titulo="CRM" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row" v-show="lista.afast_tipo == 1">
        <div class="col-3">
          <mct-form-itens tipo="text" campo="afast_cid" :valor="lista" titulo="CID" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-3">
          <mct-form-itens tipo="date" campo="afast_data_atestado" :valor="lista" titulo="Data Atestado" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mct-form-itens tipo="textarea" campo="afast_observacoes" :valor="lista" titulo="Observações" linhas="3" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>

    </div>
  </mct-padrao>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import moment from 'moment'

export default {
  Name: 'EscAfastamentos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
      }
  },
  methods: {
    async setData (lista, colunms, mode, errors) {
      this.lista = lista
      this.errors = errors
      if (this.modo == 'novo'){
        this.lista.afast_tipo = 1    
        this.lista.afast_ent = this.Entidade    
        this.lista.afast_esc = this.Escola    
        this.lista.afast_ano = this.Exercicio    
        this.lista.audit_data_cadastro = this.moment(new Date()).format('YYYY-MM-DD')
        this.lista.audit_user_cadastro = this.infoLogado['usu_usuario'].toUpperCase()       
        this.lista.audit_local_cadastro = this.IP       
        this.lista.afast_tipo_periodo = 1       
      } else if (this.modo == 'editar'){
        this.lista.audit_data_atualiza = this.moment(new Date()).format('YYYY-MM-DD')
        this.lista.audit_user_atualiza = this.infoLogado['usu_usuario'].toUpperCase()       
        this.lista.audit_local_atualiza = this.IP
      }
    },
  },
   computed: {
    ...mapState('Login',['Entidade','Escola','Exercicio','infoLogado']), 
  },  
  watch: {
    'lista.afast_dias_afastado': function (newVal, oldVal){ 
      if (newVal > 0) {
        let inicio = moment(this.lista.afast_data_inicio).format('YYYY-MM-DD');
        this.lista.afast_data_retorno = moment(inicio).add(newVal,'days').format('YYYY-MM-DD');
      }
    }  
  
  }     
}
</script>