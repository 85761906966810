<template>
<!-- RELATORIOS CONTAS A PAGAR - A PAGAR -->
    <div>
        
        <div class="row mb-4">
            <div class="col-lg-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Turma</legend>
                    <div class="row">                                                        
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_ini" :valor="filtro" titulo="Inicial" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_ini" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-3">
                                    <mct-form-itens tipo="number" campo="turma_fin" :valor="filtro" titulo="Final" :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                                <div class="col-9">
                                    <mct-form-itens tipo="select" campo="turma_fin" :valor="filtro" titulo=" " origemAPI="escturmas/combo2" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemRetornoSec='turm_codigo' :errors="{}" modo="editar"></mct-form-itens>
                                </div>
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
       
        <div class="row mb-4">
            <div class="col-lg-12">
                <fieldset class="well h-100">
                    <legend class="well-legend">Etapa</legend>
                    <div class="row">
                        <div class="col-12">
                            <mct-form-itens tipo="select" campo="etap_codigo" :valor="filtro" titulo="Etapa" origem="esc_etapas" origemChave="etap_codigo" origemRetorno='etap_descricao' :errors="{}" modo="editar" obrigatorio="true"></mct-form-itens>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
       

        <div class="row mb-2">
            <div class="col-sm-12 text-left">
                <button type="button" class="btn btn-secondary mr-2" aria-label="Left Align" @click="onDiarioClasse()">
                <span class="fa fa-refresh fa-lg" aria-hidden="true"></span> Visualizar</button>     
            </div>
        </div>
    </div>
</template>

<script>
import axios from '@/http'
import moment from 'moment'

import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctPadraoRelatorios from '@/components/funcoes/MctPadraoRelatorios'
  
export default {
    components: {
        'MctFormItens': MctFormItens,
    },
    mixins: [MctPadraoRelatorios],
    data() {
        return {
            data: [],
            outputPdf: null,
            filtro: {
                turma_ini: null,
                turma_fin: null,
                agrupa_agendamento: null,
                situacao_agendamento: 9,
                agendamento: null,
                assinatura: 0,
                etap_codigo: null,
                aluno: null,
                modelo: 1,
                tipo: 0
            }            
        }
    },
    methods: {
        async getData() {
            var url = '/diarioclasse';

            await axios.get(`/relatorios/siges${url}`,{params:  {
                turmaini: this.filtro.turma_ini,
                turmafin: this.filtro.turma_fin,
                agrupa_agendamento: this.filtro.agrupa_agendamento,
                situacao_agendamento: this.filtro.situacao_agendamento,
                agendamento: this.filtro.agendamento,
                etap_codigo: this.filtro.etap_codigo,
                aluno: this.filtro.aluno,
                tipo: this.filtro.tipo
            },
            paramsSerializer: function (params) {
                return jQuery.param(params)
            }
            })
            .then(response => {
                this.data = response.data.data                    
            })
            .catch(response => {
                console.log(response.message)
            })            
        },
        
        async onTopoDiarioClasse(index) {
            this.pdf.setFontSize(7.5)
            this.pdf.setFont('helvetica','normal')
            
            //this.pdf.setLineWidth(1);
            this.pdf.rect(8,this.lin-3,this.widthPage,5)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Bimestre:',10,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].etap_descricao),32,this.lin)        
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Início:',80,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(moment(this.data[index].ini_bimestre).locale("pt-br").format('DD/MM/YYYY')),90,this.lin)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Fim:',110,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(moment(this.data[index].fim_bimestre).locale("pt-br").format('DD/MM/YYYY')),122,this.lin)
            this.onAddLine(this.jump+2)

            this.pdf.rect(8,this.lin-3,this.widthPage,5)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Professor(a):',10,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].pes_nome),32,this.lin)        

            this.pdf.rect(108,this.lin-3,0,5)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Curso:',110,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(this.data[index].cur_nome,122,this.lin)
            this.onAddLine(this.jump+2)

            this.pdf.rect(8,this.lin-3,this.widthPage,5)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Disciplina:',10,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].disp_nome),32,this.lin)        
            
            this.pdf.rect(108,this.lin-3,0,5)
            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Módulo:',110,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(this.data[index].ser_descricao,122,this.lin)

            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Turma:',180,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].turm_codigo),192,this.lin)

            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Identificação:',202,this.lin)
            this.pdf.setFont('helvetica','normal')
            this.pdf.text(String(this.data[index].turm_identificacao),220,this.lin)

            this.pdf.setFont('helvetica','bold')
            this.pdf.text('Turno:',250,this.lin)
            this.pdf.setFont('helvetica','normal')
            await this.pdf.text(this.data[index].turn_descricao,262,this.lin)

        },

        async onRodapeConteudo () {
            this.pdf.rect(8,this.lin-1.5,this.widthPage,35);
            this.pdf.setFontSize(8)
            this.pdf.setFont('helvetica','bold')

            this.onAddLine(this.jump)
            this.pdf.text('AULAS PREVISTAS:________________________________________________________________',10,this.lin+2);
            this.pdf.text('OBS:__________________________________________________________________________',(this.widthPage/2)+16,this.lin+2);            
            this.onAddLine(this.jump+3)

            this.pdf.text('AULAS DADAS:____________________________________________________________________',10,this.lin+2);
            this.pdf.text('______________________________________________________________________________',(this.widthPage/2)+16,this.lin+2);            
            this.onAddLine(this.jump+3)

            this.pdf.text('______________________________________________________________________________',(this.widthPage/2)+16,this.lin+2);            
            this.onAddLine(this.jump+3)
            this.pdf.setFontSize(6)

            this.pdf.text('COORDENADOR PEDAGÓGICO__________________________________________________________________________________',10,this.lin+2);
            this.pdf.setFontSize(8)
            this.pdf.text('______________________________________________________________________________',(this.widthPage/2)+16,this.lin+2);            
            this.onAddLine(this.jump)
            
            this.pdf.setFontSize(4)
            this.pdf.text('ASSINATURA',84,this.lin+2,{align: 'center'});
            this.onAddLine(3)
            this.pdf.setFontSize(8)
            this.pdf.text('______________________________________________________________________________',(this.widthPage/2)+16,this.lin+2);            
        },        

        async onDiarioClasse () {

            await this.getData();

            await this.onCreatePDF('L')
            /*CABEÇALHO*/
            this.title = '';
            this.onHeaderPDF();
            this.pdf.setFont('helvetica','normal') 
            this.pdf.setFontSize(7.5)    
                    
            var grupo = '';

            for (var index in this.data) {
                if (grupo !== this.data[index].cur_codigo+'|'+this.data[index].turm_codigo+'|'+this.data[index].disp_codigo+'|'+this.data[index].etap_codigo){
                    if (grupo !== '')
                    this.onSaltarPagina2();
                    grupo = this.data[index].cur_codigo+'|'+this.data[index].turm_codigo+'|'+this.data[index].disp_codigo+'|'+this.data[index].etap_codigo
                    

                    var qtdLoop = Math.trunc(this.data[index].diasAula.length>45?this.data[index].diasAula.length/45:1);
                    if (this.data[index].diasAula.length/45 - qtdLoop > 0){
                        qtdLoop++;
                    }             

                    for (var index3 in this.data[index].matriculas) {
                        this.data[index].matriculas[index3].totalFaltas = 0;
                    }

                    for (let idxf = 1; idxf <= qtdLoop; idxf++){
                        if (idxf>1) this.onSaltarPagina2();
                        /** INICIO FREQUENCIAS */
                        await this.onTopoDiarioClasse(index);
    
                        this.onAddLine(this.jump+4);
                        this.pdf.rect(8,this.lin-3,this.widthPage,12);
                        this.pdf.text('Registro Aluno',10,this.lin+4);
                        this.pdf.rect(28,(this.lin-3),0,12);
                        this.pdf.text('Nº.',30,this.lin+4);
                        this.pdf.rect(35,(this.lin-3),0,12);
                        this.pdf.text('Aluno',37,this.lin+4);
                        this.pdf.rect(100,(this.lin-3),0,12);
    
                        for (let idx = 1; idx <= 45; idx++) {
                            this.pdf.rect((100+(3*idx)),(this.lin-3),0,12)                        
                        }
                        for (let idx = ((Number(idxf)-1)*(45)); idx < this.data[index].diasAula.length && idx < Number(idxf)*(45) ; idx++) {
                            this.pdf.setFontSize(6)
                            this.pdf.setFont('helvetica','normal')
                            this.pdf.text(String(this.$options.filters.data(this.data[index].diasAula[idx].horaridia_data)),(102.5+(3*((Number(idx)-((Number(idxf)-1)*(45)))))),this.lin+8,null,90)
                        }
                        this.pdf.text('Avaliações',255,this.lin+4,{align: 'center'});
                        this.pdf.rect(275,(this.lin-3),0,12)
                        this.pdf.text('Total',279,this.lin+2,{align: 'center'});
                        this.pdf.text('Faltas',279,this.lin+5,{align: 'center'});
                        this.onAddLine(this.jump+9)
    
                        for (var index2 in this.data[index].matriculas) {
                            this.pdf.setFontSize(6)
                            this.pdf.setFont('helvetica','normal')
                            this.onAlternarCor(index2);
                            this.pdf.rect(8,this.lin-3,this.widthPage,5,'FD')
                            this.pdf.text(String(this.data[index].matriculas[index2].matri_codigo),10,this.lin)
                            this.pdf.rect(28,(this.lin-3),0,5)
                            this.pdf.text(String(this.data[index].matriculas[index2].matri_numero),31.5,this.lin,{align: 'center'})
                            this.pdf.rect(35,(this.lin-3),0,5)
                            this.pdf.text(String(this.data[index].matriculas[index2].pes_nome),37,this.lin)
                            this.pdf.rect(100,(this.lin-3),0,5)
                            
                            for (let idx = 1; idx < 46; idx++) {
                                this.pdf.rect((100+(3*idx)),(this.lin-3),0,5)                        
                            }
                            /** ALUNOS PRESENCAS AQUI */
                            let transf = false;
                            for (let idx = ((Number(idxf)-1)*(45)); idx < this.data[index].diasAula.length && idx < Number(idxf)*(45) ; idx++) {
                            //for (let idx = 0; idx < this.data[index].diasAula.length; idx++) { 
                                if (this.data[index].matriculas[index2].matri_data_transf <= this.data[index].diasAula[idx].horaridia_data){
                                    if (!transf) {
                                        this.pdf.setFontSize(8)
                                        this.pdf.setFont('helvetica','bold')
                                        this.pdf.setFillColor(255,255,255);
                                        this.pdf.setDrawColor(255,255,255);
                                        this.pdf.rect((101+(3*((Number(idx)-((Number(idxf)-1)*(45)))))),(this.lin-2.7),40,5,'FD')
                                        if (this.data[index].matriculas[index2].matri_tipo_transf === 'E'){
                                            this.pdf.text(String('TRANSFERIDO DE ESCOLA'),(101+(3*((Number(idx)-((Number(idxf)-1)*(45)))))),(this.lin+0.5))
                                        } else {
                                            this.pdf.text(String('TRANSFERIDO DE TURMA'),(101+(3*((Number(idx)-((Number(idxf)-1)*(45)))))),(this.lin+0.5))
                                        }
                                        this.pdf.setDrawColor(0,0,0);
                                    }
                                    transf = true;
                                } else {
                                    const frequencias = this.data[index].matriculas[index2].frequencias.filter(ele => 
                                        ele.alufreq_data === this.data[index].diasAula[idx].horaridia_data &&
                                        ele.alufreq_aula === this.data[index].diasAula[idx].aula
                                        );
                                    if (frequencias && frequencias[0]){
                                        this.pdf.setFontSize(10)
                                        this.pdf.setFont('helvetica','bold')
                                        this.pdf.text(String('*'),(101+(3*((Number(idx)-((Number(idxf)-1)*(45)))))),(this.lin+1),null,5)
                                    } else {
                                        this.pdf.setFontSize(8)
                                        this.pdf.setFont('helvetica','bold')
                                        this.pdf.text(String('F'),(101+(3*((Number(idx)-((Number(idxf)-1)*(45)))))),(this.lin+1),null,5)
                                        this.data[index].matriculas[index2].totalFaltas++;
                                    }
                                }
                            }
                            /** FIM ALUNOS PRESENCAS */
                            /** ALUNOS NOTAS AQUI */
                            for (let idx = 0; idx < this.data[index].matriculas[index2].notas.length && idx < 5 && idxf === qtdLoop; idx++) {   
                                this.pdf.setFontSize(6)
                                this.pdf.setFont('helvetica','bold')
                                this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].notas[idx].notalu_nota,2)),(237+(7*Number(idx))),(this.lin),null,5)
                            
                            }
                            /** FIM ALUNOS NOTAs */
    
                            this.pdf.rect(275,(this.lin-3),0,5)
                            if (idxf === qtdLoop){
                                this.pdf.setFontSize(6)
                                this.pdf.setFont('helvetica','bold')
                                this.pdf.text(String(this.data[index].matriculas[index2].totalFaltas > 0 ? this.$options.filters.moeda(this.data[index].matriculas[index2].totalFaltas,0) : ''),279,this.lin,{align: 'center'},5)
                            }
                            this.onAddLine(this.jump+2)                        
                        }  
                        /** FIM FREQUENCIA */
                    }

                    /** INICIO CONTEUDO */ 
                    /** CONTEUDO MODELO ANTERIOR */   
                    // this.onSaltarPagina2();                
                    // await this.onTopoDiarioClasse(index);

                    // this.onAddLine(this.jump+2);
                    // this.pdf.rect(8,this.lin-1.5,this.widthPage,5);
                    // this.pdf.text('Conteúdo',this.widthPage/2,this.lin+2,{align: 'center'});
                    // this.onAddLine(this.jump+2);

                    // this.pdf.rect(8,this.lin-1.5, 16, 5);
                    // this.pdf.text('D/M',16,this.lin+2,{align: 'center'});                    
                    // this.pdf.rect(24,this.lin-1.5,(this.widthPage/2)-16,5);
                    // this.pdf.text('',32,this.lin+2);


                    // this.pdf.rect((this.widthPage/2)+8,this.lin-1.5, 16, 5);
                    // this.pdf.text('D/M',(this.widthPage/2)+16,this.lin+2,{align: 'center'});                                        
                    // this.pdf.rect((this.widthPage/2)+24,this.lin-1.5,(this.widthPage/2)-16,5);
                    // this.pdf.text('',(this.widthPage/2)+32,this.lin+2);

                    // this.onAddLine(this.jump+2);

                    // for (let index2 = 0; index2 < 19; index2++) {
                    //     this.onAlternarCor(index2);
                    //     /**LADO A */        
                    //     this.pdf.rect(8,this.lin-1.5, 16, 5, 'FD');

                    //     this.onAlternarCor(index2);
                    //     this.pdf.rect(24,this.lin-1.5,(this.widthPage/2)-16,5, 'FD');
                        
                    //     if (this.data[index].diasAula.length >= index2+1){
                    //         var dtStr = moment(this.data[index].diasAula[index2].horaridia_data).format('DD/MM');
                    //         this.pdf.text(String(dtStr),16,this.lin+2,{align: 'center'});                    
                    //         this.pdf.text(String(this.data[index].diasAula[index2].conteudo ? this.data[index].diasAula[index2].conteudo : '').substring(0,79),25,this.lin+2);
                    //     }

                    //     //this.pdf.text('',32,this.lin+2);
                    //     /**LADO B */

                    //     this.onAlternarCor(index2);
                    //     this.pdf.rect((this.widthPage/2)+8,this.lin-1.5, 16, 5, 'FD');

                    //     this.onAlternarCor(index2);
                    //     this.pdf.rect((this.widthPage/2)+24,this.lin-1.5,(this.widthPage/2)-16,5, 'FD');
                        
                    //     if (this.data[index].diasAula.length >= (index2+20)){
                    //         var dtStr = moment(this.data[index].diasAula[index2+19].horaridia_data).format('DD/MM');
                    //         this.pdf.text(String(dtStr),(this.widthPage/2)+16,this.lin+2,{align: 'center'});                                        
                    //         this.pdf.text(String(this.data[index].diasAula[index2+19].conteudo ? this.data[index].diasAula[index2+19].conteudo : '').substring(0,79),(this.widthPage/2)+25,this.lin+2);
                    //     }


                    //     this.onAddLine(this.jump+2);

                    // }  
                    // this.onAddLine(this.jump-1);
                    // this.onRodapeConteudo(); 

                    /** INICIO CONTEUDO */    
                    this.onSaltarPagina2();                
                    await this.onTopoDiarioClasse(index);

                    this.onAddLine(this.jump+2);
                    this.pdf.rect(8,this.lin-1.5,this.widthPage,5);
                    this.pdf.text('Conteúdo',this.widthPage/2,this.lin+2,{align: 'center'});
                    this.onAddLine(this.jump+2);

                    this.pdf.rect(8,this.lin-1.5, 16, 5);
                    this.pdf.text('D/M',16,this.lin+2,{align: 'center'});                    
                    this.pdf.rect(24,this.lin-1.5,(this.widthPage)-16,5);
                    this.pdf.text('CONTEÚDO',32,this.lin+2);


                    //this.pdf.rect((this.widthPage)+8,this.lin-1.5, 16, 5);

                    this.onAddLine(this.jump+2);

                    for (let index2 = 0; index2 < this.data[index].diasAula.length-1; index2++) {
                        var splitText = this.pdf.splitTextToSize(this.data[index].diasAula[index2].conteudo ? this.data[index].diasAula[index2].conteudo : '', 230);
                        var qtdLinhasTexto = splitText.length ? splitText.length : 1;

                        if (this.qtdLin > this.itensPage || this.lin+(3*qtdLinhasTexto) >= (this.factorJump*this.jump)){
                            this.onSaltarPagina2();
                        }

                        this.onAlternarCor(index2);
                        this.pdf.rect(8,this.lin-1.5, 16, qtdLinhasTexto === 1 ? 5 : 3*(qtdLinhasTexto)+1.5, 'FD');

                        this.onAlternarCor(index2);
                        this.pdf.rect(24,this.lin-1.5,(this.widthPage)-16,qtdLinhasTexto === 1 ? 5 : 3*(qtdLinhasTexto)+1.5, 'FD');
                        
                        var dtStr = moment(this.data[index].diasAula[index2].horaridia_data).format('DD/MM');
                        this.pdf.text(String(dtStr),16,this.lin+2,{align: 'center'});
                        this.pdf.text(25,this.lin+2,splitText,{align: 'left', maxWidth: 240});

                        //this.pdf.text(String(this.data[index].diasAula[index2].conteudo ? this.data[index].diasAula[index2].conteudo : '').substring(0,79),25,this.lin+2);
                        this.onAddLine(qtdLinhasTexto === 1 ? this.jump+2 : this.jump+(3*qtdLinhasTexto)-2);

                    }  
                    this.onAddLine(this.jump-1);
                    this.onRodapeConteudo();  

                    /** INICIO RESULTADO ANUAL */    
                    this.onSaltarPagina2();                
                    await this.onTopoDiarioClasse(index);

                    this.onAddLine(this.jump+2);
                    this.pdf.rect(8,this.lin-1.5,this.widthPage,5);
                    this.pdf.text('RESULTADO ANUAL',this.widthPage/2,this.lin+2,{align: 'center'});
                    this.onAddLine(this.jump+2);

                    /** LEGENDAS */
                    this.pdf.setFontSize(6)
                    this.pdf.setFont('helvetica','bold')

                    this.pdf.setFillColor(192,192,192)
                    this.onAlternarCor(index2);
                    this.pdf.rect(8,this.lin-1.5,16,10,'FD');
                    this.pdf.text('R.A.',9,this.lin+2);

                    this.pdf.setFillColor(192,192,192)
                    this.onAlternarCor(index2);
                    this.pdf.rect(24,this.lin-1.5,55,5,'FD');
                    this.pdf.text('FALTAS',55,this.lin+2,{align: 'center'});

                    this.pdf.setFillColor(192,192,192)
                    this.onAlternarCor(index2);
                    this.pdf.rect(79,this.lin-1.5,180,5,'FD');
                    this.pdf.text('NOTAS',180,this.lin+2,{align: 'center'});

                    this.pdf.setFillColor(192,192,192)
                    this.onAlternarCor(index2);
                    this.pdf.rect(259,this.lin-1.5,24,10,'FD');
                    this.pdf.text('RESULT. FINAL',265,this.lin+2);
                    this.onAddLine(this.jump+2);

                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(24,this.lin-1.5,11,5,'FD');
                    this.pdf.text('1º Bim.',30,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(35,this.lin-1.5,11,5,'FD');
                    this.pdf.text('2º Bim.',41,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(46,this.lin-1.5,11,5,'FD');
                    this.pdf.text('3º Bim.',52,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(57,this.lin-1.5,11,5,'FD');
                    this.pdf.text('4º Bim.',63,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(68,this.lin-1.5,11,5,'FD');
                    this.pdf.text('TOTAL',74,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(79,this.lin-1.5,15,5,'FD');
                    this.pdf.text('1º Bim.',86,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(94,this.lin-1.5,15,5,'FD');
                    this.pdf.text('2º Bim.',101,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(109,this.lin-1.5,15,5,'FD');
                    this.pdf.text('MD, 1º S.',116,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(124,this.lin-1.5,15,5,'FD');
                    this.pdf.text('Rec. 1º S.',131,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(139,this.lin-1.5,15,5,'FD');
                    this.pdf.text('MF 1º S.',146,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(154,this.lin-1.5,15,5,'FD');
                    this.pdf.text('3º Bim.',161,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(169,this.lin-1.5,15,5,'FD');
                    this.pdf.text('4º Bim.',176,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(184,this.lin-1.5,15,5,'FD');
                    this.pdf.text('MD, 2º S.',191,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(199,this.lin-1.5,15,5,'FD');
                    this.pdf.text('Rec. 2º S.',206,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(214,this.lin-1.5,15,5,'FD');
                    this.pdf.text('MF 2º S.',221,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(229,this.lin-1.5,15,5,'FD');
                    this.pdf.text('P. Final',236,this.lin+2,{align: 'center'});
                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(244,this.lin-1.5,15,5,'FD');
                    this.pdf.text('Md. Final',251,this.lin+2,{align: 'center'});
                    this.onAddLine(this.jump+2);

                    for (var index2 in this.data[index].matriculas) {
                        this.pdf.setFontSize(6)
                        this.pdf.setFont('helvetica','normal')

                        this.onAlternarCor(index2);

                        this.pdf.rect(8,this.lin-1.5,16,5,'FD');
                        this.pdf.text(String(this.data[index].matriculas[index2].matri_codigo),9,this.lin+2);

                        this.onAlternarCor(index2);
                        this.pdf.rect(24,this.lin-1.5,11,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.faltas_eta_1,0)),29.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(35,this.lin-1.5,11,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.faltas_eta_2,0)),40.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(46,this.lin-1.5,11,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.faltas_eta_3,0)),51.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(57,this.lin-1.5,11,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.faltas_eta_4,0)),62.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(68,this.lin-1.5,11,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(Number(this.data[index].matriculas[index2].boletim.faltas_eta_1)+Number(this.data[index].matriculas[index2].boletim.faltas_eta_2)+Number(this.data[index].matriculas[index2].boletim.faltas_eta_3)+Number(this.data[index].matriculas[index2].boletim.faltas_eta_4),0)),72.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(79,this.lin-1.5,15,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.media_eta_1,2)),86.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(94,this.lin-1.5,15,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.media_eta_2,2)),101.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(109,this.lin-1.5,15,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.media_sem_1,2)),116.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(124,this.lin-1.5,15,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.rec_sem_1,2)),131.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(139,this.lin-1.5,15,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.media_fin_sem_1,2)),146.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(154,this.lin-1.5,15,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.media_eta_3,2)),161.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(169,this.lin-1.5,15,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.media_eta_4,2)),176.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(184,this.lin-1.5,15,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.media_sem_2,2)),191.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(199,this.lin-1.5,15,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.rec_sem_2,2)),206.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(214,this.lin-1.5,15,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.media_fin_sem_2,2)),221.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(229,this.lin-1.5,15,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.prova_final,2)),236.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(244,this.lin-1.5,15,5,'FD');
                        this.pdf.text(String(this.$options.filters.moeda(this.data[index].matriculas[index2].boletim.media_final,2)),251.5,this.lin+2,{align: 'center'});
                        this.onAlternarCor(index2);
                        this.pdf.rect(259,this.lin-1.5,24,5,'FD');
                        this.pdf.text(String(this.data[index].matriculas[index2].boletim.situacao),271,this.lin+2,{align: 'center'});

                        this.onAddLine(this.jump+2);
                    }

                    /** INICIO OBSERVACOES */
                    this.onSaltarPagina2();
                    await this.onTopoDiarioClasse(index);

                    this.onAddLine(this.jump+2);
                    this.pdf.setFontSize(6)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.rect(8,this.lin-1.5,this.widthPage,5);
                    this.pdf.text('OBSERVAÇÕES',this.widthPage/2,this.lin+2,{align: 'center'});
                    this.onAddLine(this.jump+2);

                    for (let i = 1; i <= 4; i++) {
                        this.pdf.setFillColor(192,192,192)
                        this.pdf.rect(8,this.lin-1.5,this.widthPage,5,'FD');
                        this.pdf.text(i+'º BIMESTRE',this.widthPage/2,this.lin+2,{align: 'center'});
                        this.onAddLine(this.jump+2);
                        for (let j = 1; j <= 6; j++) {
                            this.pdf.rect(8,this.lin-1.5,this.widthPage,5);
                            this.pdf.text('',this.widthPage/2,this.lin+2,{align: 'center'});
                            if (!((i === 4) && (j === 6)))
                                this.onAddLine(this.jump+2);
                        }
                    }

                    /** INICIO ESPELHO DA BASE NACIONAL COMUM */
                    this.onSaltarPagina2();
                    await this.onTopoDiarioClasse(index);

                    this.onAddLine(this.jump+2);
                    this.pdf.setFontSize(6)
                    this.pdf.setFont('helvetica','bold')

                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(8,this.lin-1.5,16,10,'FD')
                    this.pdf.text('N.º',10,this.lin+4)


                    this.pdf.setFillColor(192,192,192)
                    this.pdf.rect(24,this.lin-1.5,this.widthPage-16,5,'FD');
                    this.pdf.text('ESPELHO DA BASE NACIONAL COMUM',(this.widthPage/2)-16,this.lin+2,{align: 'center'});

                    this.onAddLine(this.jump+2);

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(24,this.lin-1.5,20,5,'FD');
                    this.pdf.setFontSize(4.5)
                    this.pdf.text('EDUCAÇÃO ARTÍSTICA',34,this.lin+2,{align: 'center'});

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(44,this.lin-1.5,20,5,'FD');
                    this.pdf.setFontSize(4.5)
                    this.pdf.text('EDUCAÇÃO FÍSICA',54,this.lin+2,{align: 'center'});

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(64,this.lin-1.5,20,5,'FD');
                    this.pdf.setFontSize(4.5)
                    this.pdf.text('L.E.M.(INGLÊS)',74,this.lin+2,{align: 'center'});

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(84,this.lin-1.5,20,5,'FD');
                    this.pdf.setFontSize(4.5)
                    this.pdf.text('LINGUA PORTUGUESA',94,this.lin+2,{align: 'center'});

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(104,this.lin-1.5,20,5,'FD');
                    this.pdf.setFontSize(4.5)
                    this.pdf.text('CIÊNCIAS',114,this.lin+2,{align: 'center'});

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(124,this.lin-1.5,20,5,'FD');
                    this.pdf.setFontSize(4.5)
                    this.pdf.text('MATEMÁTICA',134,this.lin+2,{align: 'center'});

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(144,this.lin-1.5,20,5,'FD');
                    this.pdf.setFontSize(4.5)
                    this.pdf.text('ENSINO RELIGIOSO',154,this.lin+2,{align: 'center'});

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(164,this.lin-1.5,20,5,'FD');
                    this.pdf.setFontSize(4.5)
                    this.pdf.text('GEOGRAFIA',174,this.lin+2,{align: 'center'});

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(184,this.lin-1.5,20,5,'FD');
                    this.pdf.setFontSize(4.5)
                    this.pdf.text('HISTÓRIA',194,this.lin+2,{align: 'center'});

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(204,this.lin-1.5,20,5,'FD');

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(224,this.lin-1.5,20,5,'FD');

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(244,(this.lin-1.5),8,5,'FD');

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(252,(this.lin-1.5),31,5,'FD')

                    this.onAddLine(this.jump+2);

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(8,(this.lin-1.5),16,5,'FD');
                    this.pdf.text('',10,this.lin+2,{align: 'center'});

                    let idxff = 20;
                    for (let idx = 1; idx <= 11; idx++) {
                        idxff += 4;
                        this.pdf.setFillColor(192,192,192);
                        this.pdf.rect(idxff,(this.lin-1.5),4,5,'FD');
                        this.pdf.text('1B',idxff+2,this.lin+2,{align: 'center'});
                        idxff += 4;
                        this.pdf.setFillColor(192,192,192);
                        this.pdf.rect(idxff,(this.lin-1.5),4,5,'FD');
                        this.pdf.text('2B',idxff+2,this.lin+2,{align: 'center'});
                        idxff += 4;
                        this.pdf.setFillColor(192,192,192);
                        this.pdf.rect(idxff,(this.lin-1.5),4,5,'FD');
                        this.pdf.text('3B',idxff+2,this.lin+2,{align: 'center'});
                        idxff += 4;
                        this.pdf.setFillColor(192,192,192);
                        this.pdf.rect(idxff,(this.lin-1.5),4,5,'FD');
                        this.pdf.text('4B',idxff+2,this.lin+2,{align: 'center'});
                        idxff += 4;
                        this.pdf.setFillColor(192,192,192);
                        this.pdf.rect(idxff,(this.lin-1.5),4,5,'FD');
                        this.pdf.text('MF',idxff+2,this.lin+2,{align: 'center'});
                    }

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(244,(this.lin-1.5),8,5,'FD');
                    this.pdf.text('MF',248,this.lin+2,{align: 'center'});

                    this.pdf.setFillColor(192,192,192);
                    this.pdf.rect(252,(this.lin-1.5),31,5,'FD')
                    this.pdf.text('RESULTADO',268,this.lin+2,{align: 'center'});

                    this.onAddLine(this.jump+3.5);

                    for (var index2 in this.data[index].matriculas) {
                        this.pdf.setFontSize(6)
                        this.pdf.setFont('helvetica','normal')
                        this.onAlternarCor(index2);
                        this.pdf.rect(8,this.lin-3,16,5,'FD')
                        this.pdf.text(String(this.data[index].matriculas[index2].matri_codigo),10,this.lin)
                        let idxf = 0;
                        for (let idx = 1; idx <= 55; idx++) {
                            this.onAlternarCor(index2);
                            idxf = 20+(4*idx);
                            this.pdf.rect(idxf,(this.lin-3),4,5,'FD')
                        }
                        this.onAlternarCor(index2);
                        this.pdf.rect(idxf+4,(this.lin-3),8,5,'FD');
                        this.onAlternarCor(index2);
                        this.pdf.rect(idxf+12,(this.lin-3),31,5,'FD')
                        this.onAddLine(this.jump+2)
                    }  
                    this.onAddLine(this.jump+2)
                    this.onAddLine(this.jump+2)
                    this.pdf.setFontSize(6)
                    this.pdf.setFont('helvetica','bold')
                    this.pdf.text('____________________________________________________________________',this.widthPage+8,this.lin+2,{align: 'right'});
                    this.onAddLine(this.jump);
                    
                    this.pdf.text('Assinatura do Professor',this.widthPage-30,this.lin+2,{align: 'center'});
                    this.onAddLine(3);
                    this.pdf.text(String('Data: '+moment().format('DD/MM/YYYY')),this.widthPage-30,this.lin+2,{align: 'center'});
                }
            }
                                               
            this.onFooterPDF()
            this.onInsertNumberPagesPDF()

            const uuidv4 = this.$uuid.v4();
            const urlPDF = this.pdf.output('blob');
            this.outputPdf= await window.URL.createObjectURL(new Blob([urlPDF], { type: 'application/pdf:base64' }));
            this.$emit('update:outputPdf', this.outputPdf);
        },             
    }    
}
</script>