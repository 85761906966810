<template>
    <div class="container-fluid mct-calendar">
        <div class="row mb-2">
            <button class="btn btn-sm btn-primary mt-2" @click.prevent.stop="$emit('setHora')"><i class="fa fa-chevron-left" aria-hidden="true"></i> Voltar</button> 
        </div>
        <div class="row mct-calendar-horarios">
            <div class="col-2 mct-calendar-horarios-hora"></div>
            <div class="col mct-calendar-horarios-mins-t"><span>{{moment(linha.horaridia_data).locale('pt-br').format('L')}}</span></div>
        </div>
        <div class="row mct-calendar-horarios" v-for="(linha,index) in horarios" :key="index">
            <div :id="'ch_'+index" class="col-2 mct-calendar-horarios-hora"><a href="#" @click.prevent.stop="onSelDisp(linha.hora,index)">{{linha.hora}}</a></div>
            <div :id="'cm15_'+index" class="col mct-calendar-horarios-mins" v-show="(!showDisciplinas && linhaSelecionada === index) || linhaSelecionada !== index" @click.prevent.stop="onSelDisp(linha.hora,index)">
               
            </div>
            <div class="mct-calendar-horarios-txt" v-for="(disp,idx) in getAulasHora(linha.hora,index)" :key="'disp_'+idx">{{disp.disp_nome}}</div>
            <div class="mct-calendar-horarios-disp" v-show="showDisciplinas && linhaSelecionada === index" >
                <span class="mb-2">Selecione a Disciplina</span>
                <mct-form-itens coluna="0" tipo="select" campo="disp_codigo" :valor="linha" :errors="{}" 
                    :origemData="lista" origemChave="disp_codigo" origemRetorno='disp_nome'                     
                    modo="editar" showBotoes="false"></mct-form-itens>
                <button class="btn btn-sm btn-primary mt-2" @click.prevent.stop="onSelHora(linha)"><i class="fa fa-check" aria-hidden="true"></i> Selecionar</button>    
            </div>                    
        </div>                       
    </div>    
</template>

<script>
import axios from '@/http'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
    name: 'EscAgendamentosHorarios',
    props: ['data', 'linha', 'duracao', 'lista'],
    components: {
        MctFormItens
    },
    data () {
        return {
            intervalo: 15,
            horarios: [],
            aulasDia: [],
            linhaSelecionada: -1,
            showDisciplinas: false,
            disciplinas: []            
        }
    },
    methods: {
        onSelHora (linha) {
            var index = -1
            this.linha.selecionado = 1
            if (typeof this.linha.horarios.filter !== 'undefined'){
                var ret = this.linha.horarios.filter(function(el) {
                    return el.horaridiadisp_hora == linha.hora || el.horaridiadisp_hora+':00' == linha.hora
                })
            index = this.linha.horarios.indexOf(ret[0])            
            }
          
            if (index >= 0){
                this.linha.horarios[index].disp_codigo           = linha.disp_codigo
                this.linha.horarios[index].disp_nome             = linha.disp_nome
                this.linha.horarios[index].horaridiadisp_hora    = linha.hora
                this.linha.horarios[index].horaridiadisp_duracao = this.duracao
            } else {
                this.linha.horarios.push({  
                    disp_codigo           : linha.disp_codigo,
                    disp_nome             : linha.disp_nome,
                    horaridiadisp_hora    : linha.hora,
                    horaridiadisp_duracao : this.duracao
                })                
            }
            this.showDisciplinas = false
        },
        onSelDisp (hora, linha) {
            this.showDisciplinas = true
            this.linhaSelecionada = linha
        },
        geraHorario () {
            var seq = 0
            for (var i = 1; i <= 24; i++){
                this.horarios.push({
                    hora: ("00" + i).slice(-2)+':'+("00" + 0).slice(-2),
                    seq: seq
                })
                seq++
            }
        },       
        getAulasHora (hora,index) {
            if (typeof this.linha !== 'undefined' && typeof this.linha.horarios !== 'undefined' && typeof this.linha.horarios.filter !== 'undefined'){
                var ret = this.linha.horarios.filter(function(el) {
                    return el.horaridiadisp_hora == hora || el.horaridiadisp_hora == hora+':00'
                })
                $('#ch_'+index).width()
                return ret
            }else{
                return {}
            }
        }         
    },
    created() {
        this.showDisciplinas = false
        this.geraHorario()
    },
    
}
</script>

