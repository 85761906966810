<template>
    <div>
        <div class="row">
            <div class="col-12 text-center d-inline-flex justify-content-center">
                <div class="text-center mct-capture">
                    <video id="video" width="320" height="240" autoplay></video>
                </div>
                <!--<div class="text-center mct-capture">
                    <canvas id="canvas" width="320" height="240"></canvas>        
                </div>-->
                <div class="text-center mct-capture">
                    <img id="img" :src="imagem"/>
                </div>        
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="text-center">
                    <button id="snap" class="btn btn-sm btn-info" @click.prevent.stop="onCapture()"><i class="fa fa-camera" aria-hidden="true"></i> Capturar</button>
                    &nbsp;&nbsp;
                    <button id="ok" class="btn btn-sm btn-danger" @click.prevent.stop="onClose()"><i class="fa fa-check" aria-hidden="true"></i> Fechar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MctCapture',
  props: ['img'],
  data () {
    return {
        imagem: null
    }
  },
  methods: {
    onCapture (e) {
        var canvas =  document.createElement("canvas");
        canvas.height = 240;
        canvas.width = 320;
        //var canvas = document.getElementById('canvas');
        var context = canvas.getContext('2d');
        var video = document.getElementById('video');
        context.drawImage(video, 0, 0, 320, 240);

        //var canvas = document.getElementById('canvas');
        this.imagem = canvas.toDataURL("image/png");
        this.$emit('update:img', this.imagem);
        this.$emit('update:img2', this.imagem);
        
        //this.onConvertCanvasToImage();
    },
    onConvertCanvasToImage() {
        /*var canvas = document.getElementById('canvas');
	    //this.image = new Image();
	    //this.imagem.src = canvas.toDataURL("image/png");
	    this.imagem = canvas.toDataURL("image/png");
	    //return image;*/
    },
    onClose() {
        $('#MctModalCapture').modal('hide')
    }
  },
  computed: {

  },
  mounted () {
    var video = document.getElementById('video');

    // Get access to the camera!
    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // Not adding `{ audio: true }` since we only want video now
        navigator.mediaDevices.getUserMedia({ video: true }).then(function(stream) {
            //video.src = window.URL.createObjectURL(stream);
            video.srcObject = stream;
            video.play();
        });
    }/* Legacy code below: getUserMedia*/ 
    else if(navigator.getUserMedia) { // Standard
        navigator.getUserMedia({ video: true }, function(stream) {
            video.src = stream;
            video.play();
        }, errBack);
    } else if(navigator.webkitGetUserMedia) { // WebKit-prefixed
        navigator.webkitGetUserMedia({ video: true }, function(stream){
            video.src = window.webkitURL.createObjectURL(stream);
            video.play();
        }, errBack);
    } else if(navigator.mozGetUserMedia) { // Mozilla-prefixed
        navigator.mozGetUserMedia({ video: true }, function(stream){
            video.srcObject = stream;
            video.play();
        }, errBack);
    }
        
  }     
}
</script>

<style scoped>
    .mct-capture{
        background-color: #F0F3F5!important;
        border: 1px solid #C2CFD6;
        margin: 10px;
        padding: 5px;
        width: 335px;
        height: 255px;
    }
</style>


