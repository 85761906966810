<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="!Alterando ? 'editar' : 'readonly'" botoes="false">
    <div slot="formulario">
      <div class="view" style="padding-top: 5px; padding-bottom: 5px;">      
        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Turma</legend> 
          <div class="row">
            <div class="col-12">
              <mct-form-itens tipo="search" tamanho="2" campo="alufreq_turm_codigo" :valor="lista" titulo="Turma" :errors="errors" obrigatorio="true" origem="esc_turmas" origemChave="turm_codigo" origemRetorno='turm_completa' @setDataRetorno="setTurma" origemChaveComposta="true" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
          </div>
          <div class="row">
            <div class="col-4" v-if="infoEscola.esc_tipo !== 1">
              <mct-form-itens tipo="select" campo="alufreq_ser_codigo" :valor="lista" titulo="Módulo" :errors="errors" obrigatorio="true" 
                :origemFiltro="{cur_codigo: turmaSelecionada.cur_codigo}" origemChave="ser_codigo" origemRetorno='ser_descricao' origemRetornoSec="ser_codigo" origem="esc_serie" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
            <div class="col-5">
              <mct-form-itens tipo="select" campo="alufreq_prof_codigo" :valor="lista" titulo="Professor" :errors="errors" obrigatorio="true" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec="pes_codigo" :origemData="professorLista" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
            <div class="col-3">
              <mct-form-itens tipo="select" campo="alufreq_disp_codigo" :valor="lista" titulo="Disciplina" :errors="errors" obrigatorio="true" origemChave="profdis_disp_codigo" origemRetorno='disp_nome' origemRetornoSec="profdis_disp_codigo" :origemData="disciplinasLista" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
            <div class="col-3" v-if="infoEscola.esc_tipo === 1">
              <mct-form-itens tipo="select" campo="alufreq_etap_codigo" :valor="lista" titulo="Etapa" :errors="errors" obrigatorio="true" origem="esc_etapas" origemChave="etap_codigo" origemRetorno='etap_descricao' :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
          </div>
          <div class="row" v-if="infoEscola.esc_tipo !== 1">
            <div class="col-12">
              <mct-form-itens tipo="select" campo="alufreq_horari_codigo" :valor="lista" titulo="Agendamento" :errors="errors" obrigatorio="true" 
                :origemFiltro="{cur_codigo: turmaSelecionada.cur_codigo, ser_codigo: lista.alufreq_ser_codigo, turm_codigo: lista.alufreq_turm_codigo}" origemChave="horari_codigo" origemRetorno='horari_descricao' origemRetornoSec="horari_codigo" origem="esc_horarios" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
          </div>
        </fieldset>

        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Alunos/Aulas</legend> 
          <div class="row">
            <div class="col-4">
              <mct-form-itens tipo="datepicker" campo="alufreq_data" :valor="lista" titulo="Data" :errors="errors" obrigatorio="true" :modo="!Alterando ? 'editar' : 'readonly'" :legenda="moment(lista.alufreq_data).locale('pt-br').format('dddd')"
               :disabled-date="disabledBeforeTodayAndAfterAWeek"
              ></mct-form-itens>
            </div>
            <div class="col-2">
              <mct-form-itens tipo="number" campo="alufreq_qtde" :valor="lista" titulo="Quant. de Aulas" :errors="errors" obrigatorio="true" modo="readonly"></mct-form-itens>
            </div>
            <div class="col-4">
              <div class="btn-group" role="group" aria-label="Ações" style="margin-top: 20px;">
                <button type="button" class="btn btn-info text-white" aria-label="Left Align" :disabled="Alterando"  @click.prevent.stop="alterar()">
                <span class="fa fa-pencil text-white" aria-hidden="true"></span> Alterar </button>
                <button type="button" class="btn btn-success text-white" aria-label="Left Align" :disabled="!Alterando"  @click.prevent.stop="confirma()">
                <span class="fa fa-check fa-lg text-white" aria-hidden="true"></span> Gravar </button>
                <button type="button" class="btn btn-dark text-white" aria-label="Left Align" :disabled="!Alterando"  @click.prevent.stop="cancelar()">
                <span class="fa fa-ban fa-lg text-white" aria-hidden="true"></span> Cancelar </button>
              </div>
            </div>
          </div>
          <div class="mct-grid mt-2">
            <div class="mct-grid-scroll-sec">             
              <table class="animated fadeIn table table-striped table-hover mb-0">
                <thead>
                  <tr>
                    <th scope="col" style="width: 60px;">R.A.</th>
                    <th scope="col" style="width: 100px;">MATRÍCULA</th>
                    <th scope="col">NOME ALUNO</th>
                    <th v-for="(n,index1) in Number(lista.alufreq_qtde)" :key="index1" scope="col" style="width: 30px;" class="text-center">A. {{Number(n)}}</th>
                  </tr>
                </thead>
                <tbody>                                
                  <tr v-for="(aluno,index2) in lista.alunos" :key="index2">
                    <td>{{aluno.matri_codigo}}</td>
                    <td>{{aluno.matri_matricula}}</td>
                    <td>{{aluno.aluno.pes_nome}} <small class="text-muted">[{{aluno.aluno.pes_codigo}}]</small></td>
                    <td v-for="(n,index3) in Number(lista.alufreq_qtde)" :key="index3" class="text-center">
                      <mct-form-itens tipo="checkbox" :campo="'a'+n" :valor="aluno" :errors="errors" :modo="Alterando ? 'editar' : 'readonly'"></mct-form-itens>
                    </td>
                  </tr>
                  <tr  v-if="typeof lista.alunos === 'undefined' || lista.alunos.length === 0 || Object.keys(lista.alunos).length === 0">
                      <td colspan="12" class="text-info" style="text-align: center; font-size: 1.2rem;">SEM REGISTROS</td>
                  </tr>    
                </tbody>
              </table>
            </div>
          </div>
        </fieldset>
        <div style="padding-top: 17px;">
          <button type="button" class="btn btn-danger text-white" aria-label="Left Align" @click.prevent.stop="$router.back()">
              <span class="fa fa-times text-white" aria-hidden="true"></span> Fechar </button>
        </div>
      </div> 
    </div>              
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'
import moment from 'moment'
import * as funcoes from '@/components/funcoes/Funcoes.js'

import MctPadrao from '@/components/comum/MctFormPadraoSCB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'EscFrequenciaAluno',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {
          alufreq_cur_codigo: null,
          alufreq_ser_codigo: null,
          alufreq_turm_codigo: null,
          alufreq_prof_codigo: null,
          alufreq_disp_codigo: null,
          alufreq_etap_codigo: null,
          alufreq_horari_codigo: null,
          alufreq_data: null,
          alufreq_qtde: null,
          alunos: [],
        },
        errors:{},
        message: '',
        status: 0,
        turmaSelecionada: [],
        datasDisciplina: [],
        Alterando: false
      }
  },
  methods: {
    disabledBeforeTodayAndAfterAWeek(date) {
      if (this.datasDisciplina){
        const data = this.datasDisciplina.filter((ele) => ele.horaridia_data === moment(new Date(date)).format("YYYY-MM-DD"));
        if (data && data[0]){
          return null;
        }
        return date;
      }
      return null;
    },
    setTurma(data){
      this.turmaSelecionada = data
    }, 
    async getAulas () {
      if (typeof this.lista.alufreq_data !== 'undefined' && this.lista.alufreq_data !== null){
        await axios.get('eschorarios/aulasdiadisp?turm_codigo='+this.lista.alufreq_turm_codigo+'&disp_codigo='+this.lista.alufreq_disp_codigo+'&horari_codigo='+this.lista.alufreq_horari_codigo+'&dia='+moment(this.lista.alufreq_data).format("e")+'&data='+moment(this.lista.alufreq_data).format("YYYY-MM-DD"))
          .then(response => {
            this.lista.alufreq_qtde = response.data.data
          })
          .catch(response => {
            this.message = response.response.data.message
            this.errors = response.response.data.errors
            this.status = response.response.status
          })
      }        
    },    
    async getDatas () {
      let URL = '';
      if (this.infoEscola.esc_tipo === 1){
        if (typeof this.lista.alufreq_etap_codigo !== 'undefined' && this.lista.alufreq_etap_codigo !== null)
          URL = '&etap_codigo='+this.lista.alufreq_etap_codigo
      }else{
        if (typeof this.lista.alufreq_horari_codigo !== 'undefined' && this.lista.alufreq_horari_codigo !== null)
          URL = '&horari_codigo='+this.lista.alufreq_horari_codigo
      }
      if (URL !== ''){
        await axios.get('eschorarios/diasdisp?turm_codigo='+this.lista.alufreq_turm_codigo+'&disp_codigo='+this.lista.alufreq_disp_codigo+URL)
          .then(response => {
            this.datasDisciplina = response.data.data
          })
          .catch(response => {
            this.message = response.response.data.message
            this.errors = response.response.data.errors
            this.status = response.response.status
          })
      }        
    },    
    async alterar(){
      if(this.validaInputs()){
        await axios.get('escalunosfrequencia/frequencias?turm_codigo='+this.lista.alufreq_turm_codigo+'&prof_codigo='+this.lista.alufreq_prof_codigo+'&disp_codigo='+this.lista.alufreq_disp_codigo+
          '&horari_codigo='+this.lista.alufreq_horari_codigo+'&etap_codigo='+this.lista.alufreq_etap_codigo+'&data='+moment(this.lista.alufreq_data).format("YYYY-MM-DD")+'&qtdaulas='+this.lista.alufreq_qtde)
          .then(response => {
            this.Alterando = true
            this.lista.alunos = response.data.data
          })
          .catch(response => {
            this.message = response.response.data.message
            this.errors = response.response.data.errors
            this.status = response.response.status
          })
      }      
    },
    validaInputs () {
      this.errors = new Object()
      if (this.lista.alufreq_turm_codigo == null || this.lista.alufreq_turm_codigo == ''){
        this.errors.alufreq_turm_codigo = []
        this.errors.alufreq_turm_codigo.push("O campo TURMA não pode ser nulo ou vazio.") 
      }
      if (this.lista.alufreq_prof_codigo == null || this.lista.alufreq_prof_codigo == ''){
        this.errors.alufreq_prof_codigo = []
        this.errors.alufreq_prof_codigo.push("O campo PROFESSOR não pode ser nulo ou vazio.") 
      }
      if (this.lista.alufreq_disp_codigo == null || this.lista.alufreq_disp_codigo == ''){
        this.errors.alufreq_disp_codigo = []
        this.errors.alufreq_disp_codigo.push("O campo PROFESSOR não pode ser nulo ou vazio.") 
      }
      if ((this.lista.alufreq_etap_codigo == null || this.lista.alufreq_etap_codigo == '') && (this.infoEscola.esc_tipo === 1)){
        this.errors.alufreq_etap_codigo = []
        this.errors.alufreq_etap_codigo.push("O campo ETAPA não pode ser nulo ou vazio.") 
      }
      if ((this.lista.alufreq_horari_codigo == null || this.lista.alufreq_horari_codigo == '') && (this.infoEscola.esc_tipo !== 1)){
        this.errors.alufreq_horari_codigo = []
        this.errors.alufreq_horari_codigo.push("O campo AGENDAMENTO não pode ser nulo ou vazio.") 
      }

      if (this.lista.alufreq_data == null || this.lista.alufreq_data == ''){
        this.errors.alufreq_data = []
        this.errors.alufreq_data.push("O campo DATA não pode ser nulo ou vazio.") 
      }
      if (Object.keys(this.errors).length > 0) {
        this.message = "Houve um ou mais erros."
        this.status = 422
        return false
      }else{
        this.message = ""
        this.status = 0
        return true
      }        
    },
    confirma(){
      if(this.validaInputs()){
        this.$swal({
          title: 'Deseja realmente gravar os dados?',
          text: "Lembre-se que todos os dados anteriores serão apagados!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
          }).then((result) => {
            if (result.value) {
              this.gravar()
            }                     
        })
      }
    },
    async gravar(){
      
      var dados = this.lista
      await axios.post('escalunosfrequencia/salvar',dados)
        .then(response => {
            this.message = response.data.message
            this.status = response.status
            //this.lista = response.data.data
            this.errors = {}
            
            this.$swal('Sucesso!',
              'Registro incluído com sucesso!',
              'success');  
            this.Alterando = false
            this.lista.alunos = []
            this.lista.alufreq_data = null
            this.lista.alufreq_qtde = null 
        })
        .catch(response => {
            this.message = response.response.data.message
            if (response.response.data.error){
                this.errors = response.response.data.error.errorInfo[2]              
            }else{
                this.errors = response.response.data.errors
            }
            this.status = response.response.status
            
        })  
    },
    cancelar(){
      this.$swal({
        title: 'Deseja realmente cancelar a alteração?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
              this.Alterando = false
              this.lista.alunos = []
              this.lista.alufreq_data = null
              this.lista.alufreq_qtde = null
            }                     
        })
    }
  },
  computed: {
    ...mapState('Login',['infoLogado', 'infoEscola']), 
    professorLista (){
      let ret = []
      let ser_codigo = null;
      if (this.infoEscola.esc_tipo === 1){
        ser_codigo = this.turmaSelecionada.ser_codigo;
        this.lista.alufreq_ser_codigo = ser_codigo;
      }  else {
        ser_codigo = this.lista.alufreq_ser_codigo;
      }

      if (ser_codigo && this.turmaSelecionada && this.turmaSelecionada.disciplinas){
        ret = this.turmaSelecionada.disciplinas.map(function(item) { 
          return {pes_codigo : item["profdis_prof_codigo"], pes_nome : item["pes_nome"], ser_codigo: item['ser_codigo']}; 
        });
        ret = ret.filter(el => el.ser_codigo == ser_codigo)
        ret = funcoes.eliminarObjetosDuplicados(ret, 'pes_codigo')
      }
      return ret
    },
    disciplinasLista () {
      let ret = []
      let ser_codigo = null;
      if (this.infoEscola.esc_tipo === 1){
        ser_codigo = this.turmaSelecionada.ser_codigo;
        this.lista.alufreq_ser_codigo = ser_codigo;
      }  else {
        ser_codigo = this.lista.alufreq_ser_codigo;
      }
      if (this.lista.alufreq_prof_codigo && ser_codigo && this.turmaSelecionada && this.turmaSelecionada.disciplinas){
          ret = this.turmaSelecionada.disciplinas.filter(el => {
          return el.profdis_prof_codigo == this.lista.alufreq_prof_codigo && el.ser_codigo == ser_codigo
        })
      }
      return ret
    }

  },  
  created() {
  
  },
  watch: {
    'lista.alufreq_data' (){
        this.getAulas()
    },
    'lista.alufreq_horari_codigo' (){
        if (this.infoEscola.esc_tipo !== 1){                  
          this.getDatas();
          this.lista.alufreq_qtde = 0;
          this.lista.alufreq_data = null;
        }
    },
    'lista.alufreq_etap_codigo' (){
        if (this.infoEscola.esc_tipo === 1){                  
          this.getDatas();
          this.lista.alufreq_qtde = 0;
          this.lista.alufreq_data = null;
        }
    },
    'lista.alufreq_disp_codigo' (){
        if (this.infoEscola.esc_tipo === 1){                  
          this.getDatas();
          this.lista.alufreq_qtde = 0;
          this.lista.alufreq_data = null;
        }
    },
    'lista.alufreq_turm_codigo' (){
        this.lista.alufreq_cur_codigo = this.turmaSelecionada.cur_codigo
    },
  }
}
</script>