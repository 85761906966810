<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <div class="row">
        <div class="col-sm-2">
          <mct-form-itens coluna="0" tipo="number" campo="crit_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
        </div>    
      </div>
      <div class="row">
        <div class="col-lg-2">
          <mct-form-itens tipo="number" campo="crit_freq_minima" :valor="lista" titulo="Frequência Mínim." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
        </div>
        <div style="padding-top: 24px">%</div>
      </div>
      <div class="row">
        <div class="col-lg-2">
          <mct-form-itens tipo="number" campo="crit_media_final" :valor="lista" titulo="Média Final" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <mct-form-itens tipo="radio" campo="crit_tipo" titulo="Tipo Cálculo" :valor="lista" obrigatorio="false" :errors="errors" 
            :origemData="[{value:1,text:'Bimestral'}, {value:2,text:'Semestral'},{value:3,text:'Anual'}, {value:4,text:'Nota (Única)'}, {value:5,text:'Notas (Média)'}, {value:6,text:'% Frequência'}]" 
            :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <mct-form-itens tipo="text" campo="crit_calculo_bimestral" :valor="lista" titulo="Cálculo Bimestral" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
        </div>
        <div style="padding-top: 24px">= Nota Bimestre</div>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <mct-form-itens tipo="text" campo="crit_calculo_anual" :valor="lista" titulo="Cálculo Anual" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
        </div>
        <div style="padding-top: 24px">= Média Final</div>
      </div>
      <div class="row">
        <div class="col-lg-5">
          <mct-form-itens tipo="text" campo="crit_calculo_rec" :valor="lista" titulo="Cálc. Recuperação" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
        </div>
        <div style="padding-top: 24px">= Média Final</div>
      </div>
      <fieldset class="well">
        <legend class="well-legend">Mensagens</legend> 
        <div class="row">
          <div class="col-lg-5">
            <label style="font-size: 11px; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;">Ao Obter 4 ou mais notas abaixo da média</label>
            <mct-form-itens tipo="text" campo="crit_mensagem" :valor="lista" :errors="errors" :modo="modo"></mct-form-itens>    
          </div>
        </div>
      </fieldset>
    </div>              
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState, mapActions } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctLogradouro from '@/components/comum/MctLogradouro.vue'
import MctModal from '@/components/comum/MctModalSHB.vue'
import MctCapture from '@/components/comum/MctCapture.vue'

export default {
  Name: 'Cursos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctLogradouro,
      MctFormItens,
      MctModal,
      MctCapture, 
  },
  data () {
      return {
        lista: {'ser_codigo':''},
        errors:{},
        message: '',
        status: 0,
        showCapture: false
      }
  },
  methods: {
    setData (lista, errors, lastCode) {
        this.lista = lista
        this.errors = errors  
        if(this.modo =='novo'){
          this.lista.crit_ent = this.Entidade
          this.lista.crit_esc = this.Escola
          this.lista.crit_ano = this.Exercicio
        }
    },
  
  },
   computed: {
    ...mapState('Login',['infoLogado','Exercicio', 'Escola', 'Entidade']), 
  },  
  created() {
  
  }     
}
</script>