<template>
    <mct-padrao-s-c :titulo="titulo" :source="source" :modo="modo" :titAlerta="`ERRO: ${status}`" :msgAlerta="message" :tipoAlerta="tipoAlerta" @submit="submit()" @confirma="confirma()"
        :message="message" :errors="errors" :status="status">
        <div slot="formulario">
            <div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="select" campo="per_codigo" :valor="lista" titulo="Perfil" obrigatorio="false" origem="perfil" origemChave="per_codigo" origemRetorno='per_descricao' :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="select" campo="mod_codigo" :valor="lista" titulo="Módulo" obrigatorio="false" origem="modulos" origemChave="mod_codigo" origemRetorno='mod_descricao' :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-4">
                    <mct-form-itens coluna="0" tipo="select" campo="smod_codigo" :valor="lista" titulo="Sub Módulo" obrigatorio="false"
                    origem="modulos_sub" origemChave="smod_codigo" origemRetorno='smod_descricao' origemRetornoSec='smod_mod_codigo'
                    :origemFiltro="{smod_mod_codigo: lista.mod_codigo}"
                    :errors="errors" :modo="modo"></mct-form-itens>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="select" campo="menu_tipo" :valor="lista" titulo="Tipo" obrigatorio="true" :errors="errors" :modo="modo" :origemData="[{value: 0, text: 'MENU PRINCIPAL'}, {value: 1, text: 'MENU LATERAL'}]"></mct-form-itens>
                </div>
            </div>

            <br/>
            <mct-form-botoes @submit="submit()" :modo="modo"></mct-form-botoes>
            <div style="text-align: right;" class="mb-2">
                <button type="button" class="btn btn-primary btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarTodos(1)">
                    <span class="fa fa-check-square-o fa-lg" aria-hidden="true"></span> Marcar Tudo </button>
                <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarTodos(0)">
                    <span class="fa fa-times fa-lg" aria-hidden="true"></span> Desmarcar Tudo </button>
            </div>
            <table class="table table-striped table-bordered table-sm table-hover">
                <thead>
                    <tr>
                        <th style="display: none;">CÓDIGO</th>
                        <th style="width: 465px;">MENU</th>
                        <th>IMP.</th>
                        <th>SEL.</th>
                        <th>INS.</th>
                        <th>ATU.</th>
                        <th>DEL.</th>
                        <th>REF.</th>
                        <th style="width: 85px;"></th>
                    </tr>
                    <tr>
                        <th style="display: none;">CÓDIGO</th>
                        <th></th>
                        <th style="text-align: center;">
                            <button type="button" class="btn btn-primary btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarY(1,'priv_imprimi')">
                                <span class="fa fa-check-square-o" aria-hidden="true"></span></button>
                            <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarY(0,'priv_imprimi')">
                                <span class="fa fa-times" aria-hidden="true"></span></button>
                        </th>
                        <th style="text-align: center;">
                            <button type="button" class="btn btn-primary btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarY(1,'priv_seleciona')">
                                <span class="fa fa-check-square-o" aria-hidden="true"></span></button>
                            <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarY(0,'priv_seleciona')">
                                <span class="fa fa-times" aria-hidden="true"></span></button>
                        </th>
                        <th style="text-align: center;">
                            <button type="button" class="btn btn-primary btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarY(1,'priv_insere')">
                                <span class="fa fa-check-square-o" aria-hidden="true"></span></button>
                            <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarY(0,'priv_insere')">
                                <span class="fa fa-times" aria-hidden="true"></span></button>
                        </th>
                        <th style="text-align: center;">
                            <button type="button" class="btn btn-primary btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarY(1,'priv_atualiza')">
                                <span class="fa fa-check-square-o" aria-hidden="true"></span></button>
                            <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarY(0,'priv_atualiza')">
                                <span class="fa fa-times" aria-hidden="true"></span></button>
                        </th>
                        <th style="text-align: center;">
                            <button type="button" class="btn btn-primary btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarY(1,'priv_deleta')">
                                <span class="fa fa-check-square-o" aria-hidden="true"></span></button>
                            <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarY(0,'priv_deleta')">
                                <span class="fa fa-times" aria-hidden="true"></span></button>
                        </th>
                        <th style="text-align: center;">
                            <button type="button" class="btn btn-primary btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarY(1,'priv_referencia')">
                                <span class="fa fa-check-square-o" aria-hidden="true"></span></button>
                            <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarY(0,'priv_referencia')">
                                <span class="fa fa-times" aria-hidden="true"></span></button>
                        </th>
                        <th style="text-align: center;"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(linha,index) in menu" :key="index">
                        <td style="display: none;">{{ linha.menu_codigo }}</td>
                        <td><span :style="`width: ${linha.menu_nivel*20}px!important;display: inline-block;`"></span>
                            <i v-if="linha.menu_nivel > 0 && (linha.menu_pagina === '' || linha.menu_pagina === null)" aria-hidden="true" class="fa fa-level-up fa-rotate-90 mct-blue" ></i>
                            <i class="fa" aria-hidden="true" :class="linha.menu_pagina === '' || linha.menu_pagina === null ? 'fa-plus-square-o mct-green' : 'fa-level-up fa-rotate-90 mct-blue'"></i>
                             {{ linha.menu_descricao }}</td>
                        <td><mct-form-itens tipo="checkbox" campo="priv_imprimi" :valor="linha" obrigatorio="true" :errors="errors" :modo="modo" v-if="linha.menu_pagina !== '' && linha.menu_pagina !== null"></mct-form-itens></td>
                        <td><mct-form-itens tipo="checkbox" campo="priv_seleciona" :valor="linha" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens></td>
                        <td><mct-form-itens tipo="checkbox" campo="priv_insere" :valor="linha" obrigatorio="true" :errors="errors" :modo="modo" v-if="linha.menu_pagina !== '' && linha.menu_pagina !== null"></mct-form-itens></td>
                        <td><mct-form-itens tipo="checkbox" campo="priv_atualiza" :valor="linha" obrigatorio="true" :errors="errors" :modo="modo" v-if="linha.menu_pagina !== '' && linha.menu_pagina !== null"></mct-form-itens></td>
                        <td><mct-form-itens tipo="checkbox" campo="priv_deleta" :valor="linha" obrigatorio="true" :errors="errors" :modo="modo" v-if="linha.menu_pagina !== '' && linha.menu_pagina !== null"></mct-form-itens></td>
                        <td><mct-form-itens tipo="checkbox" campo="priv_referencia" :valor="linha" obrigatorio="true" :errors="errors" :modo="modo" v-if="linha.menu_pagina !== '' && linha.menu_pagina !== null"></mct-form-itens></td>
                        <td style="text-align: center;">
                            <button type="button" class="btn btn-primary btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarX(1,index)">
                                <span class="fa fa-check-square-o" aria-hidden="true"></span></button>
                            <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click.prevent.stop="onMarcarX(0,index)">
                                <span class="fa fa-times" aria-hidden="true"></span></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </mct-padrao-s-c>
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'

import MctPadraoSC from '../comum/MctFormPadraoSCB.vue'
import MctFormItens from '../comum/MctFormItens.vue'
import MctFormBotoes from '../comum/MctFormBotoes.vue'


export default {
  Name: 'Menu',
  props: ['source','titulo'],
  components: {
      MctPadraoSC,
      MctFormItens,
      MctFormBotoes
  },
  data () {
      return {
          lista: {},
          errors:{},
          message: '',
          status: 0,
          tipoAlerta: '',
          submodulo: {},
          menu: {},
          modo: 'editar'
      }
  },
  methods: {
    submit(){
        //alert('teste')
        var vm = this
        var dados = this.menu
        axios.post(`${this.source}`,dados)
        .then(response => {
            this.message = response.data.message
            this.status = response.status
            this.menu = response.data.data
            this.$swal('Sucesso!',
                'Registros alterados com sucesso!',
                'success');
            this.$router.back()
        })
        .catch(response => {
            this.message = response.response.data.message
            if (response.response.data.error){
            this.errors = response.response.data.error.errorInfo[2]
            }else{
            this.errors = response.response.data.errors
            }
            this.status = response.response.status
            this.$swal('Erro!',
                'Houve um erro ao tentar alterar a senha: </br> '+ response.response.data.message,
                'error');
        })
    },
    confirma (){
        alert('...')
    },
    getSubModulo (modulo) {
        var vm = this
        axios.get(`modulos_sub/combo/`+modulo)
        .then(function(response) {
            vm.$data.submodulo = response.data
        })
        .catch(function(response) {
            console.log(response)
        })
    },
    getMenu (modulo, submodulo,perfil,tipo) {
        var vm = this
        if (typeof submodulo === 'undefined'){
            submodulo = 0
        }
        axios.get(`privilegios/`+this.Entidade+`/`+modulo+`/`+submodulo+`/`+perfil+`/`+tipo)
        .then(function(response) {
            vm.$data.menu = response.data
        })
        .catch(function(response) {
            console.log(response)
        })
    },
    onMarcarTodos(marcar){
        for(var i = 0, len = this.menu.length; i < len; ++i){
            this.menu[i].priv_seleciona     = marcar
            if (this.menu[i].menu_pagina !== '' && this.menu[i].menu_pagina !== null){
                this.menu[i].priv_imprimi       = marcar
                this.menu[i].priv_insere        = marcar
                this.menu[i].priv_atualiza      = marcar
                this.menu[i].priv_deleta        = marcar
                this.menu[i].priv_referencia    = marcar
            }
        }
    },
    onMarcarY(marcar,coluna){
        for(var i = 0, len = this.menu.length; i < len; ++i){
            if (this.menu[i].menu_pagina === '' || this.menu[i].menu_pagina === null){
                if (coluna === 'priv_seleciona'){
                    this.menu[i][coluna] = marcar
                }
            }else{
                this.menu[i][coluna] = marcar
            }
        }
    },
    onMarcarX(marcar,key){
        this.menu[key].priv_seleciona     = marcar
        if (this.menu[key].menu_pagina !== '' && this.menu[key].menu_pagina !== null){
            this.menu[key].priv_imprimi       = marcar
            this.menu[key].priv_insere        = marcar
            this.menu[key].priv_atualiza      = marcar
            this.menu[key].priv_deleta        = marcar
            this.menu[key].priv_referencia    = marcar
        }
    }
  },
  computed: {
    ...mapState('Login',['infoLogado','Entidade']),

  },
  created() {
    //this.fetchIndexData()
  },
  watch: {
    'lista.mod_codigo' () {
        if (this.lista.mod_codigo){
            this.getSubModulo(this.lista.mod_codigo)
            this.getMenu(this.lista.mod_codigo,this.lista.smod_codigo,this.lista.per_codigo,this.lista.menu_tipo)
        }
    },
    'lista.smod_codigo' () {
        if (this.lista.smod_codigo){
            this.getMenu(this.lista.mod_codigo,this.lista.smod_codigo,this.lista.per_codigo,this.lista.menu_tipo)
        }
    },
    'lista.per_codigo' () {
        if (this.lista.per_codigo){
            this.getMenu(this.lista.mod_codigo,this.lista.smod_codigo,this.lista.per_codigo,this.lista.menu_tipo)
        }
    },
    'lista.menu_tipo' () {
        if (this.lista.menu_tipo !== null){
            this.getMenu(this.lista.mod_codigo,this.lista.smod_codigo,this.lista.per_codigo,this.lista.menu_tipo)
        }
    },
  }
}
</script>

<style scoped>
    .mct-green{
        color: #379A7B;
    }

    .mct-blue{
        color: #007BFF;
    }

    .mct-blue2{
        color: #007BFF;
        margin-left: 20px!important;
    }
</style>


