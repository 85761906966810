<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <div class="row">
                <div class="col-sm-2">
                    <mct-form-itens coluna="0" tipo="number" campo="modelc_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                </div>    
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <mct-form-itens tipo="text" campo="modelc_descricao" :valor="lista" titulo="Descrição" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                </div>
            </div>
            <div class="row">
                <div class="col-sm-2 overflow-auto" style="max-height: 600px">
                    <fieldset class="well">
                        <legend class="well-legend">Tags</legend>
                        <ul class="nav">
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[PAGE_BREAK]')">Quebra de Página</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_NOME]')">Nome Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_CPF]')">CPF Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_RG]')">RG Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_TELEFONES]')">Telefones Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_CONTATOS]')">Telefones de Contato Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_DATA_NASCIMENTO]')">Data Nascimento Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_CEP]')">Cep Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_ENDERECO]')">Endereço Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_BAIRRO]')">Bairro Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_CIDADE]')">Cidade Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_ESTADO]')">Estado Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_ENDERECO_COMPLETO]')">Endereço Completo Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ALUNO_EMAIL]')">Email Aluno</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_NOME]')">Nome Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_CPF]')">CPF Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_RG]')">RG Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_TELEFONES]')">Telefones Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_CONTATOS]')">Telefones de Contato Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_DATA_NASCIMENTO]')">Data Nascimento Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_CEP]')">Cep Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_ENDERECO]')">Endereço Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_BAIRRO]')">Bairro Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_CIDADE]')">Cidade Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_ESTADO]')">Estado Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_ENDERECO_COMPLETO]')">Endereço Completo Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[RESPFIN_EMAIL]')">Email Resp. Financeiro</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE]')">Nome Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_CNPJ]')">CNPJ Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_CEP]')">Cep Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_ENDERECO]')">Endereço Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_BAIRRO]')">Bairro Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_CIDADE]')">Cidade Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_ESTADO]')">Estado Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ENTIDADE_ENDERECO_COMPLETO]')">Endereço Completo Entidade</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ESCOLA_DIRETOR]')">Escola Diretor</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ESCOLA_DIRETOR_CPF]')">Escola Diretor CPF</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ESCOLA_DIRETOR_RG]')">Escola Diretor RG</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[ESCOLA_DIRETOR_ASSINATURA]')">Escola Diretor Assinatura</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[LISTA_CURSOS]')">Lista de Cursos</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_VALOR]')">Valor do Contrato</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_VALOR_EXTENSO]')">Valor por Extenso do Contrato</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_VALOR_MATRICULA]')">Valor da Matrícula do Contrato</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_VALOR_MATRICULA_EXTENSO]')">Valor por Extenso da Matrícula do Contrato</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_FORMA_PGTO_MATRICULA]')">Contrato Forma de PGTO da Matrícula</a></li>

                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_QTD_PARCELAS]')">Contrato Qtd. de Parcelas</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_QTD_PARCELAS_EXTENSO]')">Contrato Qtd. de Parcelas por Extenso</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_FORMA_PGTO_PARCELAS]')">Contrato Forma de PGTO das Parcelas</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_VALOR_PARCELAS]')">Contrato Valor da Parcela</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_VALOR_PARCELAS_EXTENSO]')">Contrato Valor por Extenso da Parcela</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_VALOR_TOTAL_PARCELAS]')">Contrato Valor Total das Parcelas</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_VALOR_TOTAL_PARCELAS_EXTENSO]')">Contrato Valor Total por Extenso das Parcelas</a></li>

                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_DIA_VENCIMENTO_PARCELAS]')">Contrato dia de Vencimento das Parcelas</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_DIA_VENCIMENTO_PARCELAS_EXTENSO]')">Contrato dia de Vencimento das Parcelas por Extenso</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_JUROS]')">Contrato Juros</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_MULTA]')">Contrato Multa</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_DESCONTO]')">Contrato Desconto</a></li>
                            <li class="nav-item"><a class="nav-link" href="#" @click.prevent.stop="placeholder('[CONTRATO_DESCONTOS_PONTUALIDADE]')">Contrato Descontos de Pontualidade</a></li>
                        </ul>
                    </fieldset> 
                </div>
                <div class="col-sm-10">
                    <fieldset class="well">
                        <legend class="well-legend">Texto</legend>
                        <quill-editor v-model="lista.modelc_texto"
                            ref="QuillEditor"
                            :options="editorOption"
                            >
                            <div id="toolbar" slot="toolbar">
                                <button class="ql-bold">Bold</button>
                                <button class="ql-italic">Italic</button>
                                <button class="ql-underline">Underline</button>
                                <button class="ql-strike">Strike</button>
                                <button class="ql-blockquote">Blockquote</button>
                                <button class="ql-code-block">CodeBlock</button>
                                <button class="ql-header" value="1"></button>
                                <button class="ql-header" value="2"></button>
                                <button class="ql-list" value="ordered"></button>
                                <button class="ql-list" value="bullet"></button>
                                <button class="ql-script" value="sub"></button>
                                <button class="ql-script" value="super"></button>
                                <button class="ql-indent" value="-1"></button>
                                <button class="ql-indent" value="+1"></button>

                                <button class="ql-direction" value="rtl"></button>
                                <select class="ql-size">
                                    <option value="small"></option>
                                    <option selected></option>
                                    <option value="large"></option>
                                    <option value="huge"></option>
                                </select>
                                <select class="ql-header">
                                    <option selected="selected"></option>
                                    <option value="1"></option>
                                    <option value="2"></option>
                                    <option value="3"></option>
                                    <option value="4"></option>
                                    <option value="5"></option>
                                    <option value="6"></option>
                                </select>
                                <select class="ql-font">
                                    <option selected="selected"></option>
                                    <option value="serif"></option>
                                    <option value="monospace"></option>
                                </select>
                                <select class="ql-color"></select>
                                <select class="ql-background"></select>
                                <select class="ql-align"></select>
                                
                                <!--<button class="ql-clear"></button>-->
                                <button class="ql-link"></button>
                                <button class="ql-image"></button>
                                <button class="ql-video"></button>
                                
                                <!--<button class="ql-clear"></button>-->
                                
                                <!--<select id="ql-customTags" class="ql-customTags">
                                    <option selected="selected" class="ql-customTags"></option>
                                    <option value="[ALUNO_NOME]" class="ql-customTags">NOME ALUNO</option>
                                    <option value="[ALUNO_ENDERECO_COMPLETO]" class="ql-customTags">ENDEREÇO COMPLETO ALUNO</option>
                                </select>-->
                                <button id="custom-button" @click.prevent.stop="onPrint"><i class="fa fa-print fa-sm"></i></button>
                            </div>
                        </quill-editor>
                    </fieldset>
                </div>
            </div>
        </div>        
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import { quillEditor } from 'vue-quill-editor';

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'ModelosContratos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      quillEditor
  },
  data () {
    return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
        editorOption: {
            preserveWhitespace: true,
            placeholder: 'Digite seu texto aqui.',
            modules: {
                toolbar: { 
                    container: '#toolbar',
                    /*[
                        [{ 'placeholder': ['[AlunoNome]', '[AlunoEnderecoCompleto]'] }],
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],
                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                        [{ 'script': 'sub' }, { 'script': 'super' }],
                        [{ 'indent': '-1' }, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],
                        [{ 'size': ['small', false, 'large', 'huge'] }],
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                        [{ 'font': [] }],
                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'align': [] }],
                        ['clean'],
                        ['link', 'image', 'video']
                    ],*/
                    /*handlers: {
                        "placeholder": this.placeholder
                    }*/
                }
                
            }
        },
        customToolbar: [
            [{ 'placeholder': ['[GuestName]', '[HotelName]'] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'script': 'sub' }, { 'script': 'super' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'font': [] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean'],
            ['link', 'image', 'video']
        ],
    }
  },
  methods: {
    setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            //this.lista.modelc_status = 1 
            this.lista.modelc_ent = this.Entidade   
            this.lista.modelc_esc = this.Escola   
        }
    },
    placeholder(args) {
        //console.log(args)
        const quill = this.$refs.QuillEditor.quill
        const value = args;//args[0];
        const cursorPosition = quill.getSelection().index
        quill.insertText(cursorPosition, value)
        quill.setSelection(cursorPosition + value.length)

    },
    onPrint(){
        //printJS({printable: 'print-texto', type: 'html', scanStyles: false, css: 'https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.core.min.css'})
        printJS({printable: '<style>.ql-editor p{ line-height: 0.9rem!important;}</style><div class="ql-editor">'+this.lista.modelc_texto+'</div>', type: 'raw-html', scanStyles: false, css: 'https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.7/quill.core.min.css'})
        //this.$htmlToPaper('quill-container');
    },
  },
  computed: {
    ...mapState('Login',['Entidade','Escola'])       
  }, 
  created() {
      /*

    const customTagsItems = Array.prototype.slice.call(document.querySelectorAll('.ql-customTags .ql-picker-item'));
    customTagsItems.forEach(item => item.textContent = item.dataset.value);
    document.querySelector('.ql-customTags .ql-picker-label').innerHTML = 'Insert customTags' + document.querySelector('.ql-customTags .ql-picker-label').innerHTML;
*/
    // We need to manually supply the HTML content of our custom dropdown list
    //const customTagsItems = Array.prototype.slice.call(document.querySelectorAll('.ql-placeholder .ql-picker-item'));
    //customTagsItems.forEach(item => item.textContent = item.dataset.value);
    //document.querySelector('.ql-placeholder .ql-picker-label').innerHTML = 'Insert placeholder' + document.querySelector('.ql-placeholder .ql-picker-label').innerHTML;
  
  }     
}
</script>

<style lang="css">
/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>

<style scoped>

    .ql-picker-item:before {
        content: attr(data-label);
    }

    .nav-link {
        display: block;
        background-color: #F4F4F4;
        padding: 4px;
        margin: 2px;
    }

</style>