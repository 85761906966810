<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <div class="row">
        <div class="col-sm-2">
          <mct-form-itens coluna="0" tipo="number" campo="turm_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
        </div> 
        <div class="col-sm-5">
            <mct-form-itens tipo="checkbox" campo="turm_situacao" :valor="lista" :titulo="lista.turm_situacao == 1 ? 'Cadastro Ativo.' : 'Cadastro Inativo.'" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#dados" role="tab">Dados Turma</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#horarios" role="tab">Horários de Aula</a>
        </li>
      </ul>

      <div class="tab-content">
        <!-- TAB DADOS TURMA -->            
        <div class="tab-pane active" id="dados" role="tabpanel">
          <fieldset class="well">
            <legend class="well-legend">Definição da Turma</legend> 
            <div class="row">
              <div class="col-lg-6">
                <mct-form-itens coluna="0" tipo="search" campo="cur_codigo" :valor="lista" titulo="Curso" :errors="errors" obrigatorio="true" 
                  origem="esc_cursos" origemChave="cur_codigo" origemRetorno='cur_nome' origemChaveComposta="true" :modo="modo" tamanho="1"></mct-form-itens>
              </div>
            </div>
            <div class="row" v-if="infoEscola.esc_tipo === 1">
              <div class="col-lg-6">
                <mct-form-itens coluna="0" tipo="select" campo="ser_codigo" :valor="lista" titulo="Ano/Série" :errors="errors" obrigatorio="false" 
                  origem="esc_serie" origemChave="ser_codigo" origemRetorno='ser_descricao' :origemFiltro="{cur_codigo: lista.cur_codigo}"
                  :modo="modo"></mct-form-itens>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <mct-form-itens coluna="0" tipo="select" campo="turn_codigo" :valor="lista" titulo="Turno" :errors="errors" obrigatorio="false" 
                  origem="esc_turno" origemChave="turn_codigo" origemRetorno='turn_descricao' origemRetornoSec='turn_turno' origemChaveComposta="true"  @setDataRetorno="setTurno" :modo="modo"></mct-form-itens>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <mct-form-itens coluna="0" tipo="select" campo="sal_codigo" :valor="lista" titulo="Sala" :errors="errors" obrigatorio="false" 
                  :origemFiltro="{sal_ativa: 1}" origem="esc_salas" origemChave="sal_codigo" origemRetorno='sal_descricao' origemRetornoSec='sal_codigo' origemChaveComposta="true" :modo="modo"></mct-form-itens>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6" v-if="infoEscola.esc_tipo === 1">
                <mct-form-itens coluna="0" tipo="select" campo="crit_codigo" :valor="lista" titulo="Critério de Avaliação" :errors="errors" obrigatorio="false" 
                  origem="esc_criterios" origemChave="crit_codigo" origemRetorno='crit_calculo_bimestral' origemRetornoSec='crit_codigo' origemChaveComposta="true" :modo="modo"></mct-form-itens>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <mct-form-itens coluna="0" tipo="text" campo="turm_identificacao" :valor="lista" titulo="Identificação" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
              <div class="col-lg-3">
                <mct-form-itens coluna="0" tipo="text" campo="turm_turma" :valor="lista" titulo="Turma" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
              <div class="col-lg-3">
                <mct-form-itens coluna="0" tipo="select" campo="turm_media_base" :valor="lista" titulo="Média Base" :origemData="[{value: 1,text:'TODOS BIMESTRES'},{value:4,text:'4º BIMESTRE'}]" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
            </div>
          </fieldset>
          <fieldset class="well">
            <legend class="well-legend">Situação Turma</legend> 
            <div class="row">
              <div class="col-lg-3" v-if="infoEscola.esc_tipo === 1">
                <mct-form-itens coluna="0" tipo="date" campo="turm_data_criacao" :valor="lista" titulo="Data da Criação" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
              <div class="col-lg-3" v-if="infoEscola.esc_tipo === 1">
                <mct-form-itens coluna="0" tipo="date" campo="turm_data_fechamento" :valor="lista" titulo="Data Fechamento" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
 
              <div class="col-lg-2">
                <mct-form-itens coluna="0" tipo="number" campo="turm_numero_alunos" :valor="lista" titulo="Número Alunos" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
              <div class="col-lg-2">
                <mct-form-itens coluna="0" tipo="number" campo="turm_maximo_alunos" :valor="lista" titulo="Máximo Alunos" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
            </div>
          </fieldset>
        </div>

        <!-- TAB HORÁRIOS DE AULA -->   
        <div class="tab-pane" id="horarios" role="tabpanel">
          <div class="row">
            <div class="col-lg-2">
              <mct-form-itens coluna="0" tipo="number" campo="turm_aulas_semanal" :valor="lista" titulo="Nº de Aulas Semanais" :errors="errors" :modo="modo"></mct-form-itens>    
            </div>
          </div>
          <div class="row">
            <div class="col-lg-10">
              <tbl-turmas-aulas  ref="escturmasaula" :modo="modo" :aulas="lista.aulas"></tbl-turmas-aulas>
            </div>
             <div class="col-lg-2">
              <fieldset class="well">
                <legend class="well-legend">Intervalo de Aula após:</legend>                    
                <div class="row">
                  <div class="col-lg-12">
                    <mct-form-itens coluna="0" tipo="radio" campo="turm_intervalo" :valor="lista" obrigatorio="false" :errors="errors" :origemData="this.intervalo" :modo="modo"></mct-form-itens>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </div> 
      </div>
    </div>              
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

import TblTurmasAulas from './TblTurmasAulas.vue'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctModal from '@/components/comum/MctModalSHB.vue'

export default {
  Name: 'Turmas',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      MctModal,
      TblTurmasAulas,
  },
  data () {
      return {
        lista:{'aulas':''},
        errors:{},
        aulas:{},
        aulas:[],
        intervalo:[],
        message: '',
        turnoatual:'',
        turnoSelecionado: {},
        status: 0,
      }
  },
  methods: {
    setData (lista, errors, lastCode) {
      this.lista = lista
      this.errors = errors
      if(this.modo =='novo'){
        this.lista.turm_ano = this.Exercicio
        this.lista.turm_ent = this.Entidade
        this.lista.turm_esc = this.Escola
        this.lista.turm_situacao = 1        
      }else{
        this.turnoatual = lista.turn_codigo
      }        
    },
    setTurno(data){ 
      this.turnoSelecionado = data;
    }      
  },

  watch: {
    'lista.turn_codigo': function (newValue, oldValue) {
      if (this.infoEscola.esc_tipo === 1) {
        if (this.modo=='novo'){
          if(this.turnoSelecionado && this.turnoSelecionado.turn_aulas_diaria && newValue != oldValue){ //Adiciona registros de aulas na grid
            this.aulas=[]
            for (let i = 0; i < this.turnoSelecionado.turn_aulas_diaria; i++) {
              this.aulas.push({'turmau_segunda': 0, 'turmau_terca': 0, 'turmau_quarta': 0, 'turmau_quinta': 0, 'turmau_sexta': 0, 'turmau_sabado': 0, 'turmau_aula':i+1}) //Descobrir origem do campo turmau_aula
            }
            this.lista.aulas = this.aulas
          }
        }else if((newValue != oldValue) || ((newValue == oldValue) && (this.lista.aulas.length == 0))){ //Adiciona registros de aulas na grid, se buscar nova turma
          this.aulas=[]
          if(this.turnoSelecionado.turn_aulas_diaria){ 
            for (let i = 0; i < this.turnoSelecionado.turn_aulas_diaria; i++) {
              this.aulas.push({'turmau_segunda': 0, 'turmau_terca': 0, 'turmau_quarta': 0, 'turmau_quinta': 0, 'turmau_sexta': 0, 'turmau_sabado': 0, 'turmau_aula':i+1}) //Descobrir origem do campo turmau_aula
            }
            this.lista.aulas = this.aulas
          }
        }
        
        //Setando Intervalo
        this.intervalo=[]
        if(this.turnoSelecionado.turn_aulas_diaria){
          for (let i = 1; i < this.turnoSelecionado.turn_aulas_diaria; i++) {
            this.intervalo.push({'value': i, 'text': i+'º Horário'})
          }
        }
      }
    }
  },  
  computed: {
    ...mapState('Login',['infoLogado','Exercicio', 'Escola', 'Entidade', 'infoEntidade', 'infoEscola']), 
  },  
  created() {
  
  }     
}

/*


if (this.infoEscola.esc_tipo === 1) {

        
        //let data = itens.filter((item,idx) => item.turn_codigo == this.turnoatual)[0];
        console.log(this.turnoSelecionado,newValue,oldValue);

        if (this.modo=='novo'){
          if(data.turn_aulas_diaria && data.turn_codigo != this.turnoatual){ //Adiciona registros de aulas na grid
            this.aulas=[]
            for (let i = 0; i < data.turn_aulas_diaria; i++) {
              this.aulas.push({'turmau_segunda': 0, 'turmau_terca': 0, 'turmau_quarta': 0, 'turmau_quinta': 0, 'turmau_sexta': 0, 'turmau_sabado': 0, 'turmau_aula':i+1}) //Descobrir origem do campo turmau_aula
            }
            this.lista.aulas = this.aulas
          }
        }else if((data.turn_codigo != this.turnoatual) || ((data.turn_codigo == this.turnoatual) && (this.lista.aulas.length == 0))){ //Adiciona registros de aulas na grid, se buscar nova turma
          this.aulas=[]
          if(data.turn_aulas_diaria){ 
            for (let i = 0; i < data.turn_aulas_diaria; i++) {
              this.aulas.push({'turmau_segunda': 0, 'turmau_terca': 0, 'turmau_quarta': 0, 'turmau_quinta': 0, 'turmau_sexta': 0, 'turmau_sabado': 0, 'turmau_aula':i+1}) //Descobrir origem do campo turmau_aula
            }
            this.lista.aulas = this.aulas
          }
        }
        
        //Setando Intervalo
        this.intervalo=[]
        if(data.turn_aulas_diaria){
          for (let i = 1; i < data.turn_aulas_diaria; i++) {
            this.intervalo.push({'value': i, 'text': i+'º Horário'})
          }
        }
      }


 */
</script>
