<template>
    <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
        <div slot="formulario">
            <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#cadastro" role="tab">Matrícula</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#conteudo" role="tab">Histórico</a>
                </li>
            </ul>
            <!-- TABS -->
            <div class="tab-content">
                <div class="tab-pane active" id="cadastro" role="tabpanel">                
                    <div class="row align-items-center">
                        <div class="col-lg-3">
                            <mct-form-itens tipo="number" campo="matri_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                        </div>
                        <div class="col-lg-3">
                            <mct-form-itens tipo="select" campo="matri_tipo" :valor="lista" titulo="Tipo" 
                                :origemData="[{value: 'A', text: 'ABERTA'},{value: 'F', text: 'FECHADA'}]" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-lg-3">
                            <mct-form-itens tipo="select" campo="matri_tipo_matricula" :valor="lista" titulo="Tipo Matrícula" 
                                :origemData="[{value: 'I', text: 'INGRESSANTE'},{value: 'C', text: 'CONTINUIDADE'},{value: 'T', text: 'TRANFERIDO DE ESCOLA'}]" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                        <div class="col-lg-3 text-right">
                            <h2 v-if="lista.matri_situacao === 'M'"><span class="badge badge-primary"><i class="fa fa-clock-o" aria-hidden="true"></i> matriculado</span></h2>
                            <h2 v-else-if="lista.matri_situacao === 'C'"><span class="badge badge-danger"><i class="fa fa-ban" aria-hidden="true"></i> cancelado</span></h2>
                            <h2 v-else-if="lista.matri_situacao === 'T'"><span class="badge badge-info"><i class="fa fa-hourglass-start" aria-hidden="true"></i> tranferido</span></h2>
                            <h2 v-else-if="lista.matri_situacao === 'F'"><span class="badge badge-success"><i class="fa fa-check" aria-hidden="true"></i> finalizado</span></h2>
                            <h2 v-else-if="lista.matri_situacao === 'D'"><span class="badge badge-secondary"><i class="fa fa-sign-out" aria-hidden="true"></i> desistente</span></h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3">
                            <mct-form-itens tipo="text" campo="matri_matricula" :valor="lista" titulo="Matrícula" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                        </div>
                        <div class="col-lg-3">
                            <mct-form-itens tipo="number" campo="matri_numero" :valor="lista" titulo="Número" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-10">
                            <mct-form-itens tipo="select" campo="matri_alu_codigo" :valor="lista" titulo="Aluno" 
                                origem="esc_pessoas" origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo'
                                obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-10">
                            <mct-form-itens tipo="select" campo="matri_turm_codigo" :valor="lista" titulo="Turma" 
                                origem="esc_turmas" origemChave="turm_codigo" origemRetorno='turm_completa' origemRetornoSec='turm_codigo'
                                obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                        </div>
                    </div>
                    <div class="row" v-if="infoEscola.esc_tipo === 3">
                        <fieldset class="well col-10">
                            <legend class="well-legend">Módulos / Agendamentos</legend> 
                            <div class="mct-grid">
                                <div class="mct-grid-scroll-sec">                        
                                    <table class="table table-sm table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="width: 150px;">Módulo</th>
                                                <th scope="col">Agendamento</th>
                                                <th scope="col" style="width: 60px;">CH</th>
                                                <th scope="col" style="width: 80px;">Data Ini.</th>
                                                <th scope="col" style="width: 80px;">Data Final</th>
                                                <th scope="col" style="width: 120px;">Situação</th>
                                            </tr>
                                        </thead>
                                        <tbody>                                
                                            <tr v-for="(serie,index) in lista.series" :key="index">
                                                <td>{{serie.agendamento.serie.ser_descricao}}<span class="text-muted">{{' [ '+serie.agendamento.serie.ser_codigo+ ' ]'}}</span></td>
                                                <td>{{serie.agendamento.horari_descricao}}<span class="text-muted">{{' [ '+serie.agendamento.horari_codigo+ ' ]'}}</span></td>
                                                <td>{{serie.agendamento.serie.ser_ch}}hs</td>
                                                <td>{{serie.agendamento.horari_data_inicial | data}}</td>
                                                <td>{{serie.agendamento.horari_data_final | data}}</td>
                                                <th scope="row">
                                                    <span class="mct-grid-span-01 d-block text-primary" v-if="serie.agendamento.horari_situacao === 1"><i class="fa fa-clock-o" aria-hidden="true"></i> em andamento</span>
                                                    <span class="mct-grid-span-01 d-block text-danger" v-else-if="serie.agendamento.horari_situacao === 0"><i class="fa fa-ban" aria-hidden="true"></i> cancelado</span>
                                                    <span class="mct-grid-span-01 d-block text-info" v-else-if="serie.agendamento.horari_situacao === 2"><i class="fa fa-hourglass-start" aria-hidden="true"></i> aguardando</span>
                                                    <span class="mct-grid-span-01 d-block text-success" v-else-if="serie.agendamento.horari_situacao === 3"><i class="fa fa-check" aria-hidden="true"></i> finalizado</span>
                                                </th>
                                            </tr>    
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </fieldset>                        
                    </div>
                    <div class="h-divider clear"></div>
                    <div class="col-12">
                        <button type="button" class="btn btn-secondary mr-2" aria-label="Left Align" @click="onAlterarSituacao('D')" :disabled="lista.matri_situacao !== 'M'">
                            <span class="fa fa-sign-out" aria-hidden="true"></span> Marcar como Desistente
                        </button> 
                        <button type="button" class="btn btn-danger mr-2" aria-label="Left Align" @click="onAlterarSituacao('C')" :disabled="lista.matri_situacao !== 'M'">
                            <span class="fa fa-ban" aria-hidden="true"></span> Marcar como Cancelado
                        </button>
                        <button type="button" class="btn btn-success" aria-label="Left Align" @click="onAlterarSituacao('F')" :disabled="lista.matri_situacao !== 'M'">
                            <span class="fa fa-check" aria-hidden="true"></span> Marcar como Finalizado
                        </button>
                    </div>
                </div>
                <div class="tab-pane" id="historico" role="tabpanel">
                    <fieldset class="well">
                        <legend class="well-legend">Histórico</legend> 
                        <!--<div class="mct-grid">
                            <div class="mct-grid-scroll-sec">                        
                                <table class="table table-sm table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th scope="col"></th>
                                            <th scope="col" style="width: 70px;">Grade</th>
                                            <th scope="col" style="width: 100%;">Descrição</th>
                                            <th scope="col" style="width: 100%;">CH</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                
                                        <tr v-for="(serie,index) in lista.series" :key="index">
                                            <th scope="row">
                                                <div class="is-icon">
                                                </div>
                                            </th>
                                            <td>{{serie.ser_serie}}</td>
                                            <td>{{serie.ser_descricao}}</td>
                                            <td>{{serie.ser_ch}}</td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </div>
                        </div>-->
                    </fieldset>
                </div>                
            </div>
        </div>              
    </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'
import moment from 'moment'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'Cursos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
      }
  },
  methods: {
    setData (lista, colunms, mode, errors) {
        this.lista = lista
        this.errors = errors
        if (this.modo == 'novo'){
            //this.lista.cur_situacao = 1    
        }
    },
    async onAlterarSituacao(situacao){
        this.$swal({
            title: `Deseja realmente marcar a matrícula como ${situacao === 'D' ? 'desistente' : situacao === 'C' ? 'cancelada' : 'finalizada'}?`,
            text: "Este evento não podera ser revertido!",
            icon: 'warning',
            html: situacao === 'D' || situacao === 'C' ?
                `<label class="swal2-label">Digite o motivo para ${situacao === 'D' ? 'a desistência' : situacao === 'C' ? 'o canceladamento' : ''}.</label>` +
                '<input type="text" id="swal-input1" class="swal2-input">' : '',
            focusConfirm: false,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                if (situacao === 'D' || situacao === 'C'){
                    const motivo = document.getElementById('swal-input1').value
                    if (motivo === '')
                    {
                        this.$swal.showValidationMessage(
                            'Digite o motivo para o cancelamento.'
                        )
                    } else {
                        return [
                            true,
                            document.getElementById('swal-input1').value,
                        ]
                    }
                } else {
                    return [true]
                }
            }
        }).then(async (result) => { 
            if (result.dismiss && result.dismiss === 'cancel') return; 
            if (result.value || result.value[0]) {
                let motivo = null;
                if (situacao === 'D' || situacao === 'C'){
                    motivo = result.value[1]
                }
                await axios.put(`/escmatriculaaluno/situacao?matri_codigo=${this.lista.matri_codigo}&matri_ent=${this.lista.matri_ent}&matri_esc=${this.lista.matri_esc}&matri_ano=${this.lista.matri_ano}`,
                    {
                        matri_situacao: situacao,
                        audit_data_cancelado: this.moment(new Date()).format('YYYY-MM-DD'),
                        audit_usu_cancelado: this.infoLogado['usu_usuario'].toUpperCase(),
                        audit_local_cancelado: this.IP,
                        audit_motivo_cancelado: motivo,
                    })
                    .then(response => {
                        this.message = response.data.message
                        this.status = response.status
                        this.errors = {}
                        
                        this.$swal('Sucesso!',
                        'Situação da matrícula alterada com sucesso!',
                        'success');
                        this.$router.back()
                    })
                    .catch(response => {
                        this.message = response.response.data.message
                        if (response.response.data.error){
                            this.errors = response.response.data.error.errorInfo[2]              
                        }else{
                            this.errors = response.response.data.errors
                        }
                        this.status = response.response.status                        
                    }) 
            }
        })        
    }    
  },
  computed: {
        ...mapState('Login',['infoLogado','IP','Entidade','Exercicio','Escola', 'infoEscola'])
  }, 
  created() {
  
  }     
}
</script>