<template>
    <div class="container-fluid mct-form">
        <mct-topo-lista :titulo="titulo" :subtitulo="'Manutenção de '+ titulo + ' - ' + modo.toUpperCase()"></mct-topo-lista>    
        <mct-alertas :mensagem="message" :errors="errors" :status="status" :modo="modo" v-show="status !== 0"></mct-alertas>
        <div class="container-fluid position-relative">
            <mct-form>
                <div class="row">
                    <div class="col-sm-1">
                        <mct-form-itens coluna="0" tipo="number" campo="cntr_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
                    </div>    
                    <div class="col-lg-2">
                        <mct-form-itens tipo="date" campo="cntr_data" :valor="lista" titulo="Data Cadastro" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                    </div>
                    <div class="col-lg-3">
                        <mct-form-itens tipo="select" campo="trec_codigo" :valor="lista" titulo="Tipo" obrigatorio="true" origem="esc_tp_recebimento" 
                            origemChave="trec_codigo" origemRetorno='trec_descricao' origemRetornosEC='trec_codigo' :errors="errors" :modo="modo"></mct-form-itens>
                    </div>
                    <div class="col-lg-2">
                        <mct-form-itens tipo="checkbox" campo="cntr_status" :valor="lista" :titulo="lista.cntr_status == 1 ? 'Cadastro Ativo.' : 'Cadastro Inativado.'" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="row">
                            <div class="col-sm-12">
                                <mct-form-itens v-if="lista.trec_codigo !== 6" tipo="search" campo="pes_codigo" :valor="lista" titulo="Aluno/Pessoa" obrigatorio="true" tamanho="2" origem="esc_pessoas" 
                                    origemChave="pes_codigo" origemRetorno='pes_nome' :errors="errors" :modo="modo"
                                    ></mct-form-itens>    
                                <mct-form-itens v-else tipo="search" campo="pes_codigo" :valor="lista" titulo="Professor" obrigatorio="true" tamanho="2" origem="esc_professores" 
                                    origemChave="pes_codigo" origemRetorno='pes_nome' :errors="errors" :modo="modo"
                                    ></mct-form-itens>    
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <fieldset class="well">
                                    <legend class="well-legend">Tabela de Valores (Cursos)</legend> 
                                    <button 
                                        v-if="modo === 'novo' || modo === 'editar'"
                                        type="button" class="btn btn-success btn-sm mb-2 text-white" aria-label="Left Align" @click="onIncluirCurso($event)">
                                        <span class="fa fa-plus text-white" aria-hidden="true"></span> Incluir Curso </button> 

                                    <b-modal id="curso-sel" title="Incluir/ Editar Curso">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <mct-form-itens tipo="search" campo="ctab_codigo" titulo="Curso / Tab. Valores" :valor="cursoTabela" obrigatorio="true" tamanho="2" 
                                                    origem="esc_cursos_tabela" origemChave="ctab_codigo" origemRetorno='ctab_descricao' origemChaveComposta="true" @setDataRetorno="setTabela" :errors="errors" :modo="modo"></mct-form-itens>
                                            </div>  
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <mct-form-itens tipo="select" campo="cntrc_tp_matricula" titulo="Tipo Matrícula" :valor="cursoTabela" :origemData="[{value: 0, text: 'NÃO MATRICULAR'},{value: 1, text: 'EXISTENTE'},{value: 2, text: 'NOVA'}]" 
                                                    obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <mct-form-itens v-show="cursoTabela.cntrc_tp_matricula == 1" titulo="Selecione a Matrícula" tipo="select" campo="matri_codigo" :valor="cursoTabela" obrigatorio="true" origem="esc_matricula_aluno"
                                                    :origemFiltro="{matri_alu_codigo: lista.pes_codigo}" origemChave="matri_codigo" origemRetorno='matri_matricula' :errors="errors" :modo="modo"></mct-form-itens>
                                                <mct-form-itens v-show="cursoTabela.cntrc_tp_matricula == 2" titulo="Selecione a Turma" tipo="select" campo="turm_codigo" :valor="cursoTabela" obrigatorio="true"
                                                    origem="esc_turmas" :origemFiltro="{cur_codigo: cursoTabela.tabvalores.curso.cur_codigo}" origemChave="turm_codigo" origemRetorno='turm_completa' :errors="errors" :modo="modo"></mct-form-itens>
                                            </div>
                                        </div>

                                        <fieldset class="well" v-show="cursoTabela.cntrc_tp_matricula == 2">
                                            <legend class="well-legend">Selecione os Agendamentos dos Módulos da Turma</legend>                    
                                            <div class="mct-grid">
                                                <div class="mct-grid-scroll-sec">  
                                                    <table class="table table-sm table-striped">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" style="width: 34px;" class="text-center" v-if="modo == 'editar' || modo == 'novo'"></th>
                                                                <th scope="col" style="width: 50px;" class="text-center">Grade</th>
                                                                <th scope="col" class="text-left">Módulo</th>
                                                                <th scope="col" style="width: 80px;" class="text-right">Data Ini.</th>
                                                                <th scope="col" style="width: 80px;" class="text-right">Data Fin.</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-if="(!cursoTabela.horarios || cursoTabela.horarios.length === 0) && (!horarios2 || horarios2.length === 0)" class="bg-white">
                                                                <td colspan="6" class="text-center"><span class="text-info">SEM REGISTRO(S)</span></td>
                                                            </tr>
                                                            <tr v-for="(horario,index) in cursoTabela.horarios" :key="index" :style="moment(lista.cntr_data) > moment(horario2.horario.horari_data_inicial) ? 'background-color: #EAF6FB;' : ''">
                                                                <th scope="row" v-if="modo == 'editar' || modo == 'novo'">
                                                                    <mct-form-itens titulo="" tipo="checkbox" campo="selecionado" :valor="horario" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                                                                </th> 
                                                                <td class="text-center">{{horario.horario.serie.ser_serie}}</td>
                                                                <td class="text-left"><span class="d-block">{{horario.horario.serie.ser_descricao}}</span><span class="badge badge-primary" v-if="moment(lista.cntr_data) > moment(horario.horario.horari_data_inicial)"><i class="fa fa-clock-o" aria-hidden="true"></i> em andamento</span></td>
                                                                <td class="text-right">{{horario.horario.horari_data_inicial | data}}</td>
                                                                <td class="text-right">{{horario.horario.horari_data_final | data}}</td>
                                                            </tr>
                                                            <tr v-for="(horario2,index2) in horarios2" :key="'hr2'+index2" :style="moment(lista.cntr_data) > moment(horario2.horario.horari_data_inicial) ? 'background-color: #EAF6FB;' : ''">
                                                                <th scope="row" v-if="modo == 'editar' || modo == 'novo'">
                                                                    <label class="custom-control custom-checkbox">
                                                                        <input  class="custom-control-input" type="checkbox" name="ckSelecionado" v-model="horario2.selecionado"
                                                                        v-bind:true-value="1"
                                                                        v-bind:false-value="0"
                                                                        :disabled="modo == 'deletar' || modo == 'visualizar' || modo == 'readonly'"
                                                                        @change="onChangeSelecionado(index2)">
                                                                        <span class="custom-control-indicator"></span>
                                                                        <span class="custom-control-description align-middle"></span>
                                                                    </label>
                                                                    <!--<mct-form-itens titulo="" tipo="checkbox" campo="selecionado" :valor="horario2" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>-->
                                                                </th> 
                                                                <td class="text-center">{{horario2.horario.serie.ser_serie}}</td>
                                                                <td class="text-left"><span class="d-block">{{horario2.horario.serie.ser_descricao}}</span><span class="badge badge-primary" v-if="moment(lista.cntr_data) > moment(horario2.horario.horari_data_inicial)"><i class="fa fa-clock-o" aria-hidden="true"></i> em andamento</span></td>
                                                                <td class="text-right">{{horario2.horario.horari_data_inicial | data}}</td>
                                                                <td class="text-right">{{horario2.horario.horari_data_final | data}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>                        
                                        </fieldset>
                                        <template v-slot:modal-footer>
                                            <button type="button" class="btn btn-danger btn-sm text-white" aria-label="Left Align" @click="$bvModal.hide('curso-sel')">
                                                <span class="fa fa-trash-o fa-sm text-white" aria-hidden="true"></span> Fechar </button>
                                            <button type="button" class="btn btn-success btn-sm text-white" aria-label="Left Align" @click="onSalvarCurso($event)">
                                                <span class="fa fa-check fa-sm text-white" aria-hidden="true"></span> Salvar </button>
                                        </template>
                                    </b-modal>    

                                    <div class="mct-grid">
                                        <div class="mct-grid-scroll-sec">                        
                                            <table class="table table-sm table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col" style="width: 34px;" v-if="modo == 'editar' || modo == 'novo'"></th>
                                                        <th scope="col" style="width: 50px;" class="text-center">Cód.</th>
                                                        <th scope="col" class="text-left">Descrição</th>
                                                        <!--<th scope="col" style="width: 100px;" class="text-left">Tipo Matrícula</th>
                                                        <th scope="col" style="width: 80px;" class="text-left">Matrícula/Turma</th>-->
                                                        <th scope="col" style="width: 80px;" class="text-right">Vlr. à Vista</th>
                                                        <th scope="col" style="width: 80px;" class="text-right">Vlr. Parcelado</th>
                                                        <th scope="col" style="width: 80px;" class="text-right">Vlr. Matrícula</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="!lista.cursos || lista.cursos.length === 0" class="bg-white">
                                                        <td colspan="8" class="text-center"><span class="text-info">SEM REGISTRO(S)</span></td>
                                                    </tr>
                                                    <tr v-for="(curso,index) in lista.cursos" :key="index">
                                                        <th scope="row" v-if="modo == 'editar' || modo == 'novo'">
                                                            <div class="is-icon">
                                                                <i class="fa fa-pencil text-white mct-fa bg-primary" aria-hidden="true" title="Editar Registro" @click="onEditCurso(index)"></i>
                                                                <i class="fa fa fa-times text-white bg-danger mct-fa" aria-hidden="true" title="Excluir Parcela" @click="onExcCurso(index)"></i>
                                                            </div>
                                                        </th>                                
                                                        <td class="text-center">
                                                            {{curso.ctab_codigo}}
                                                        </td>
                                                        <td class="text-left" style="white-space: pre-wrap; word-wrap:break-word;">{{curso.tabvalores.ctab_descricao}}</td>
                                                        <!--<td class="text-left">
                                                            {{curso.cntrc_tp_matricula === 0 ? 'NÃO MATRICULAR' : curso.cntrc_tp_matricula === 1 ? 'EXISTENTE' : curso.cntrc_tp_matricula === 2 ? 'NOVA' : ''}}
                                                        </td>
                                                        <td class="text-left">
                                                            {{curso.matri_codigo}} / {{curso.turm_codigo}}
                                                        </td>-->
                                                        <td class="text-right">
                                                            {{curso.tabvalores.ctab_vlr_avista | moeda}}
                                                        </td>
                                                        <td class="text-right">
                                                            {{curso.tabvalores.ctab_vlr_parcelado | moeda}}
                                                        </td>
                                                        <td class="text-right">
                                                            {{curso.tabvalores.ctab_vlr_matricula | moeda}}
                                                        </td>
                                                    </tr>    
                                                </tbody>
                                                <tfoot>
                                                    <tr class="table-info">
                                                        <th :colspan="modo === 'novo' || modo === 'editar' ? 3 : 2" class="text-right">Total:</th>
                                                        <th class="text-right">{{ somaColuna(lista.cursos, 'ctab_vlr_avista') | moeda }}</th>
                                                        <th class="text-right">{{ somaColuna(lista.cursos, 'ctab_vlr_parcelado') | moeda }}</th>
                                                        <th class="text-right">{{ somaColuna(lista.cursos, 'ctab_vlr_matricula') | moeda }}</th>
                                                    </tr>
                                                </tfoot>  
                                            </table>
                                        </div>
                                    </div>                        
                                </fieldset>
                            </div>
                        </div> 
                        <div class="row">
                            <div class="col-sm-12">
                                <fieldset class="well">
                                    <legend class="well-legend">Valores e Parcelamento da Mensalidade</legend>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <mct-form-itens tipo="currency" campo="cntr_vlr_avista" :valor="lista" titulo="Vlr. Curso à Vista" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                        <div class="col-sm-4">
                                            <mct-form-itens tipo="currency" campo="cntr_vlr_parcelado" :valor="lista" titulo="Vlr. Curso Parcelado" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                        <div class="col-sm-4">
                                            <mct-form-itens tipo="currency" campo="cntr_vlr_matricula" :valor="lista" titulo="Valor Matrícula" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <mct-form-itens tipo="select" campo="cbnc_codigo" :valor="lista" titulo="Conta Bancária" obrigatorio="true" 
                                                :origemFiltro="{cbnc_status: 1, cbnc_boleto: 1}"  origem="esc_conta_bancaria" origemChave="cbnc_codigo" origemRetorno='cbnc_descricao' origemRetornoSec='cbnc_numero' @setDataLista="setContaBancaria" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <mct-form-itens tipo="select" campo="cpg_codigo" :valor="lista" titulo="Condição de Pagamento" obrigatorio="true" 
                                                origem="esc_condpag" origemChave="cpg_codigo" origemRetorno='cpg_nome' @setDataLista="setCondPgto" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                        <div class="col-sm-4">
                                            <mct-form-itens tipo="number" campo="cntr_parcelas" :valor="lista" titulo="Qtd. Parcelas" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <mct-form-itens tipo="date" campo="cntr_venc_matricula" :valor="lista" titulo="Venc. 1º Parc. / Matrícula" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                        <div class="col-sm-4">
                                            <mct-form-itens tipo="number" campo="cntr_parcelas_matri" :valor="lista" titulo="Qtd. Parc. Matrí." obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                        <div class="col-sm-4">
                                            <mct-form-itens tipo="number" campo="cntr_dia_vencimento" :valor="lista" titulo="Dia Venc. Parcela" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <mct-form-itens tipo="currency" campo="cntr_vlr_parcela" :valor="lista" titulo="Vlr. Parcela" obrigatorio="false" :errors="errors" modo="readonly"></mct-form-itens>    
                                        </div>
                                        <div class="col-sm-4">
                                            <mct-form-itens tipo="currency" campo="cntr_vlr_contrato" :valor="lista" titulo="Vlr. Contrato" obrigatorio="false" :errors="errors" modo="readonly"></mct-form-itens>    
                                        </div>
                                        <div class="col-sm-2">
                                            <mct-form-itens tipo="number" campo="cntr_multa" :valor="lista" titulo="Multa (%)" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                        <div class="col-sm-2">
                                            <mct-form-itens tipo="number" campo="cntr_juros" :valor="lista" titulo="Juros (% dia)" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                    </div>

                                </fieldset>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <fieldset class="well">
                                    <legend class="well-legend">Modelo do Contrato</legend>                                
                                    <div class="row">
                                        <div class="col-sm-8">
                                            <mct-form-itens tipo="select" campo="modelc_codigo" :valor="lista" titulo="Modelo do Contrato" obrigatorio="true" 
                                                origem="esc_modelos_contratos" origemChave="modelc_codigo" origemRetorno='modelc_descricao' :errors="errors" :modo="modo"></mct-form-itens>
                                        </div>
                                        <div class="col-sm-4">
                                            <button type="button" class="btn btn-danger text-white mr-1" style="margin-top: 19px;" aria-label="Left Align" @click.prevent.stop="verContrato()">
                                                <span class="fa fa-file-pdf-o fa-lg text-white" aria-hidden="true"></span> Ver Contrato </button>
                                        </div>
                                    </div>
                                </fieldset>
                                <b-modal size="lg" id="texto-sel" title="Contrato">
                                    <div ref="contratoTexto" id="contratoTexto" v-html="lista.cntr_texto" style="max-height:400px;overflow-y: scroll;" class="ql-editor"></div>   
                                    <template v-slot:modal-footer>
                                        <button type="button" class="btn btn-dark btn-sm text-white" aria-label="Left Align" @click="onImprimirContrato()">
                                            <span class="fa fa-print fa-sm text-white" aria-hidden="true"></span> Imprimir </button>
                                        <button type="button" class="btn btn-danger btn-sm text-white" aria-label="Left Align" @click="$bvModal.hide('texto-sel')">
                                            <span class="fa fa-times fa-sm text-white" aria-hidden="true"></span> Fechar </button>
                                    </template>
                                </b-modal>
                            </div>
                        </div>
                    </div>    

                    <div class="col-sm-6"> 
                        <div class="row">
                            <div class="col-sm-12">
                                <fieldset class="well">
                                    <legend class="well-legend">Convênio / Desconto</legend>                    
                                    <div class="row">
                                        <div class="col-sm-2">
                                            <mct-form-itens tipo="checkbox" campo="cntr_bolsista" :valor="lista" titulo="Bolsista" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                        <div class="col-sm-4">
                                            <mct-form-itens tipo="select" campo="cntr_tp_bolsa" :valor="lista" titulo="Tipo de Bolsa" :origemData="[{value: 1, text: 'CONVÊNIO'}, {value: 2, text: 'DESCONTO'}]" obrigatorio="false" :errors="errors" :modo="lista.cntr_bolsista === 1 ? modo : 'readonly'"></mct-form-itens>    
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <mct-form-itens tipo="search" campo="cnv_codigo" :valor="lista" titulo="Cód. Convênio" obrigatorio="false" tamanho="2" 
                                                origem="esc_convenios" origemChave="cnv_codigo" origemRetorno='cnv_nome' :errors="errors" :modo="lista.cntr_bolsista === 1 && lista.cntr_tp_bolsa === 1 ? modo : 'readonly'"></mct-form-itens>    
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <mct-form-itens tipo="number" campo="cntr_desconto" :valor="lista" titulo="Desconto" obrigatorio="false" :errors="errors" :modo="lista.cntr_bolsista === 1 && lista.cntr_tp_bolsa === 2 ? modo : 'readonly'"></mct-form-itens>    
                                        </div>
                                        <div class="col-sm-6">
                                            <mct-form-itens tipo="select" campo="cntr_tp_desconto" :valor="lista" titulo="Tipo de Desconto" 
                                                :origemData="[{value: 1, text: 'PERCENTUAL'}, {value: 2, text: 'VALOR'}, {value: 3, text: 'PERCENTUAL POR PARCELA'}, {value: 4, text: 'VALOR POR PARCELA'}]" obrigatorio="false" :errors="errors" :modo="lista.cntr_bolsista === 1  && lista.cntr_tp_bolsa === 2  ? modo : 'readonly'"></mct-form-itens>    
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <fieldset class="well">
                            <legend class="well-legend">Descontos de Pontualidade</legend>                    
                            <div 
                                v-if="modo === 'novo' || modo === 'editar'"
                                class="row p-0 m-0 mb-2 mct-row-itens">
                                <div class="col-sm-4 pl-0 pr-2">
                                    <mct-form-itens tipo="text" campo="cntrdesc_dia" :valor="desconto" titulo="Até o Dia" :errors="errors" :modo="modo"></mct-form-itens>    
                                </div>
                                <div class="col-sm-6 pl-0 pr-2">
                                    <mct-form-itens tipo="text" campo="cntrdesc_desconto" :valor="desconto" titulo="Desconto (%)" :errors="errors" :modo="modo"></mct-form-itens>    
                                </div>
                                <div class="is-icon mt-3">
                                    <i class="fa fa-check text-white mct-fa bg-success" aria-hidden="true" @click="onAdcDesconto()"></i>
                                </div>
                            </div>
                            <div class="mct-grid">
                                <div class="mct-grid-scroll-sec">                        
                                    <table class="table table-sm table-striped">
                                        <thead>
                                            <tr>
                                                <th v-if="modo === 'novo' || modo === 'editar'" scope="col"></th>
                                                <th scope="col" class="text-center" style="width: 70px;">Até o Dia</th>
                                                <th scope="col" class="text-right" style="width: 100%;">Desconto (%)</th>
                                            </tr>
                                        </thead>
                                        <tbody>                                
                                            <tr v-for="(desconto,index) in lista.descontos" :key="index">
                                                <th v-if="modo === 'novo' || modo === 'editar'" scope="row">
                                                    <div class="is-icon">
                                                        <i class="fa fa-pencil text-white mct-fa bg-primary" aria-hidden="true" @click="onEditDesconto(index)"></i>
                                                        <i class="fa fa-times text-white bg-danger mct-fa" aria-hidden="true" @click="onExcDesconto(index)"></i>
                                                    </div>
                                                </th>
                                                <td class="text-center">{{desconto.cntrdesc_dia}}</td>
                                                <td class="text-right">{{desconto.cntrdesc_desconto}}</td>
                                            </tr>    
                                        </tbody>
                                    </table>
                                </div>
                            </div>                        
                        </fieldset>                                                           
                        <fieldset class="well">
                            <legend class="well-legend">Vencimento Mensalidades</legend>                    
                            <div class="mct-grid">
                                <div class="mct-grid-scroll-sec">                        
                                    <table class="table table-sm table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="width: 34px;" v-if="modo == 'editar' || modo == 'novo'"></th>
                                                <th scope="col" style="width: 50px;" class="text-center">Par.</th>
                                                <th scope="col" style="width: 80px;" class="text-left">Tipo</th>
                                                <th scope="col" style="width: 80px;" class="text-right">Número</th>
                                                <th scope="col" style="width: 100px;" class="text-right">Vrl. Bruto</th>
                                                <th scope="col" style="width: 100px;" class="text-right">Desconto</th>
                                                <th scope="col" style="width: 100px;" class="text-right">Vlr. Líquido</th>
                                                <th scope="col" class="text-right">Dt. Venc.</th>
                                                <th scope="col" class="text-right">Tp. Doc.</th>
                                                <th scope="col" style="width: 80px;" class="text-center" v-if="modo !== 'deletar' && modo !== 'novo'"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-if="!lista.parcelas || lista.parcelas.length === 0" class="bg-white">
                                                <td colspan="8" class="text-center"><span class="text-info">SEM REGISTRO(S)</span></td>
                                            </tr>
                                            <tr v-for="(parcela,index) in lista.parcelas" :key="index"
                                                :class="parcela.cntrp_status === 0 ? 'mct-cancelado-tr' : ''">
                                                <th scope="row" v-if="(modo == 'editar' || modo == 'novo') && (parcela.cntrp_status === 1)">
                                                    <div class="is-icon">
                                                        <i class="fa fa-pencil text-white mct-fa bg-primary" aria-hidden="true" title="Editar Registro" @click="onEditParcela(index)"></i>
                                                    </div>
                                                </th> 
                                                <th scope="row" v-else-if="parcela.cntrp_status === 0">
                                                    <span class="mct-cancelado">CANCELADO PELO USUÁRIO <strong>{{parcela.audit_usu_cancelado.toUpperCase()}}</strong> EM <strong>{{moment(parcela.audit_data_cancelado).locale('pt-br').format('L')}}.</strong></span>
                                                </th> 
                                                <td class="text-center">{{parcela.cntrp_parcela}}</td>
                                                <td class="text-left">{{parcela.cntrp_tipo === 0 ? 'MATRÍCULA' : 'MENSALIDADE'}}</td>
                                                <td class="text-right">{{parcela.cntrp_numero}}</td>
                                                <td class="text-right">{{parcela.cntrp_valor | moeda}}</td>
                                                <td class="text-right">{{parcela.cntrp_desconto | moeda}}</td>
                                                <td class="text-right">{{parcela.cntrp_liquido | moeda}}</td>
                                                <td class="text-right">{{parcela.cntrp_vencimento | data}}</td>
                                                <td class="text-left">{{getTpQuitacao(parcela.tpag_codigo)}}</td>
                                                <td class="text-left" v-if="modo !== 'deletar' && modo !== 'novo'">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <label class="custom-control custom-checkbox p-0 m-0 mr-2">
                                                            <input  class="custom-control-input" type="checkbox" name="ckSelecionado" v-model="parcela.imprimir"
                                                            v-bind:true-value="1"
                                                            v-bind:false-value="0"
                                                            :disabled="modo == 'deletar' || modo == 'novo'"
                                                            @change="onChangeImpressao(index)">
                                                            <span class="custom-control-indicator"></span>
                                                            <span class="custom-control-description align-middle"></span>
                                                        </label>
                                                        <i class="fa fa-download text-white mct-fa bg-dark" aria-hidden="true" title="Fazer Download do Boleto" @click.prevent.stop="onBoleto('D',index)"></i>
                                                        <i class="fa fa-print text-white mct-fa bg-info" aria-hidden="true" title="Imprimir Boleto" @click.prevent.stop="onBoleto('P',index)"></i>
                                                    </div>
                                                </td>                                            
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr class="table-info">
                                                <td :colspan="modo === 'novo' || modo === 'editar' ? 6 : 5" class="text-right font-weight-bold">Total das Parcelas:</td>
                                                <td class="text-right font-weight-bold">{{lista.cntr_vlr_contrato | moeda}}</td>
                                                <td colspan="2" class="text-right"></td>
                                                <td class="text-center" v-if="modo !== 'deletar' && modo !== 'novo'">
                                                    <!--<a href="#" class="text-white mct-fa bg-info" @click.prevent.stop="onBoleto('P',null)"><i class="fa fa-print"></i> Todos</a>-->
                                                    <div class="d-flex justify-content-end align-items-center">
                                                        <i class="fa fa-download text-white mct-fa bg-dark mr-1" aria-hidden="true" title="Fazer Download dos Boletos Selecionados" @click.prevent.stop="onBoleto('D',-1)"></i>
                                                        <i class="fa fa-print text-white mct-fa bg-info" aria-hidden="true" title="Imprimir Boletos Selecionados" @click.prevent.stop="onBoleto('P',-1)"></i>
                                                    </div>                                                
                                                </td>
                                            </tr>
                                            <tr class="table-info">
                                                <td :colspan="modo === 'novo' || modo === 'editar' ? 10 : 9" class="text-right font-weight-bold" v-if="modo !== 'deletar' && modo !== 'novo'">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <a v-show="modo === 'editar'" href="#" class="text-white mct-fa bg-primary mr-1" title="Atualizar Parcelas" @click.prevent.stop="onAtualizaParcelas()"><i class="fa fa-refresh"></i> Atualizar Parcelas</a>
                                                        </div>
                                                        <div class="d-flex justify-content-end align-items-center">
                                                            <a href="#" class="text-white mct-fa bg-dark mr-1" title="Fazer Download dos Boletos Selecionados" @click.prevent.stop="onBoleto('D',null)"><i class="fa fa-download"></i> Baixar Todos</a>
                                                            <a href="#" class="text-white mct-fa bg-info" title="Imprimir Boletos Selecionados" @click.prevent.stop="onBoleto('P',null)"><i class="fa fa-print"></i> Imprimir Todos</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                            <b-modal id="parc-sel" title="Editar Mensalidades">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <mct-form-itens tipo="text" campo="cntrp_parcela" titulo="Parcela" :valor="parcelaSelecionada != null ? lista.parcelas[parcelaSelecionada] : {}" obrigatorio="true" :errors="errors" modo="readonly"></mct-form-itens>
                                    </div>  
                                    <div class="col-sm-4">
                                        <mct-form-itens tipo="select" campo="cntrp_tipo" titulo="Tipo" :valor="parcelaSelecionada != null ? lista.parcelas[parcelaSelecionada] : {}" :origemData="[{value: 0, text: 'MATRÍCULA'},{value: 1, text: 'MENSALIDADE'}]" 
                                            obrigatorio="true" :errors="errors" modo="readonly"></mct-form-itens>
                                    </div>
                                    <div class="col-sm-4">
                                        <mct-form-itens tipo="text" campo="cntrp_numero" titulo="Número" :valor="parcelaSelecionada != null ? lista.parcelas[parcelaSelecionada] : {}" obrigatorio="true" :errors="errors" modo="readonly"></mct-form-itens>
                                    </div>  
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <mct-form-itens tipo="currency" campo="cntrp_valor" titulo="Valor" :valor="parcelaSelecionada != null ? lista.parcelas[parcelaSelecionada] : {}" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                                    </div>
                                    <div class="col-sm-4">
                                        <mct-form-itens tipo="currency" campo="cntrp_desconto" titulo="Desconto" :valor="parcelaSelecionada != null ? lista.parcelas[parcelaSelecionada] : {}" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                                    </div>
                                    <div class="col-sm-4">
                                        <mct-form-itens tipo="date" campo="cntrp_vencimento" titulo="Data" :valor="parcelaSelecionada != null ? lista.parcelas[parcelaSelecionada] : {}" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <mct-form-itens tipo="select" campo="tpag_codigo" titulo="Tp. Documento" :valor="parcelaSelecionada != null ? lista.parcelas[parcelaSelecionada] : {}" 
                                        :origemData="tpQuitacao" origemChave="tqui_codigo" origemRetorno='tqui_descricao' obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                                    </div>
                                </div>
                                <template v-slot:modal-footer>
                                    <button type="button" class="btn btn-danger btn-sm text-white" aria-label="Left Align" @click="$bvModal.hide('parc-sel')">
                                        <span class="fa fa-trash-o fa-sm text-white" aria-hidden="true"></span> Fechar </button>
                                    <button type="button" class="btn btn-success btn-sm text-white" aria-label="Left Align" @click="onSalvarParcela($event)">
                                        <span class="fa fa-check fa-sm text-white" aria-hidden="true"></span> OK </button>
                                </template>
                            </b-modal>
                        </fieldset>
                        <div class="row">
                            <div class="col-sm-12">
                                <fieldset class="well">
                                    <legend class="well-legend">Observações</legend>                    
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <mct-form-itens tipo="textarea" campo="cntr_observacoes" :valor="lista" titulo="" obrigatorio="false" linhas="3" :errors="errors" :modo="modo"></mct-form-itens>    
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12">
                                <fieldset class="well">
                                    <legend class="well-legend">Mídia</legend>
                                    <span class="text-danger">Selecione uma ou mais Mídias</span>                    
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <!--<mct-form-itens tipo="textarea" campo="cntr_crm_midia" :valor="lista" titulo="" obrigatorio="false" linhas="3" :errors="errors" :modo="modo"></mct-form-itens>    -->
                                            <multiselect v-model="midias" :options="listaMidias" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" 
                                                placeholder="Selecione as Mídias" selectedLabel="Selecionar opção" deselectLabel="Pressione Enter para remover" selectLabel="Pressione enter para selecionar"
                                                label="midia_nome" track-by="midia_nome">
                                            </multiselect>
                                            <!--<pre class="language-json"><code>{{ midias  }}</code></pre>-->
                                        </div>
                                    </div>
                                </fieldset>
                            </div>
                        </div>                    
                    </div>
                </div>
            </mct-form>
            <b-modal 
                id="parc-sel-cancelar" 
                title="Selecione as parcelas a serem canceladas" 
                cancel-title="Cancelar"
                ok-title="Gravar"
                @ok="onCancelar">
                <div class="mct-grid">
                    <div class="mct-grid-scroll-sec">                        
                        <table class="table table-sm table-striped">
                            <thead>
                                <tr>
                                    <th scope="col" style="width: 30px;" class="text-center"></th>
                                    <th scope="col" style="width: 50px;" class="text-center">Par.</th>
                                    <th scope="col" style="width: 80px;" class="text-left">Tipo</th>
                                    <th scope="col" style="width: 80px;" class="text-right">Número</th>
                                    <th scope="col" style="width: 100px;" class="text-right">Vrl. Bruto</th>
                                    <th scope="col" style="width: 100px;" class="text-right">Desconto</th>
                                    <th scope="col" style="width: 100px;" class="text-right">Vlr. Líquido</th>
                                    <th scope="col" class="text-right">Dt. Venc.</th>
                                    <th scope="col" class="text-right">Tp. Doc.</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="!lista.parcelas || lista.parcelas.length === 0" class="bg-white">
                                    <td colspan="8" class="text-center"><span class="text-info">SEM REGISTRO(S)</span></td>
                                </tr>
                                <tr v-for="(parcela,index) in lista.parcelas" :key="index">
                                    <td class="text-left">
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label class="custom-control custom-checkbox p-0 m-0 mr-2"
                                                v-show="parcela.receberparcelas && parcela.receberparcelas.quitacao && parcela.receberparcelas.quitacao.quita_valor === null"
                                                >
                                                <input class="custom-control-input" type="checkbox" name="ckCancelado" v-model="parcela.cntrp_status"
                                                v-bind:true-value="0"
                                                v-bind:false-value="1"
                                                >
                                                <span class="custom-control-indicator"></span>
                                                <span class="custom-control-description align-middle"></span>
                                            </label>
                                        </div>
                                    </td>
                                    <td class="text-center">{{parcela.cntrp_parcela}}</td>
                                    <td class="text-left">{{parcela.cntrp_tipo === 0 ? 'MATRÍCULA' : 'MENSALIDADE'}}</td>
                                    <td class="text-right">{{parcela.cntrp_numero}}</td>
                                    <td class="text-right">{{parcela.cntrp_valor | moeda}}</td>
                                    <td class="text-right">{{parcela.cntrp_desconto | moeda}}</td>
                                    <td class="text-right">{{parcela.cntrp_liquido | moeda}}</td>
                                    <td class="text-right">{{parcela.cntrp_vencimento | data}}</td>
                                    <td class="text-left">{{getTpQuitacao(parcela.tpag_codigo)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </b-modal>
        </div>
        <mct-form-botoes @submit="submit()" :modo="modo"></mct-form-botoes>
    </div>     
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'

import moment from 'moment'
import jsPDF from 'jspdf'
import { uuid } from 'vue-uuid';
//import { VueEditor } from "vue2-editor/dist/vue2-editor.core.js";
import Multiselect from 'vue-multiselect'

import MctTopoLista from '@/components/comum/MctTopoLista.vue'
import MctForm from '@/components/comum/MctForm.vue'
import MctFormBotoes from '@/components/comum/MctFormBotoes.vue'
import MctAlertas from '@/components/comum/MctAlertas.vue' 
import MctPopUps from '@/components/comum/MctPopUps.vue'
import MctModal from '@/components/comum/MctModal.vue'
import MctDataViewer from '@/components/comum/MctDataViewer.vue'
import MctMixinsForm from '@/components/funcoes/MctMixinsForm'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'EscContratos',
  mixins: [MctMixinsForm],  
  props: ['source','titulo','modo'],
  components: {
    MctForm,
    MctTopoLista,
    MctFormBotoes,
    MctAlertas,
    MctPopUps,
    MctModal,
    MctDataViewer,
    MctFormItens,
    Multiselect
  },
  data () {
      return {
        tpQuitacao: [],
        vctoParcelas:'',
        condPgto: [], 
        condPgtoSelecionado: {},
        contaBancaria: [],
        contaBancariaSelecionada: {},
        parcelaSelecionada: null,
        cursoSelecionado: null,
        cursoTabela: {
            ctab_codigo: null,
            tabvalores: {
                curso: {
                    cur_codigo: null, 
                    cur_nome: null 
                    },
                ctab_descricao: null,
                ctab_vlr_avista: null,
                ctab_vlr_parcelado: null,
                ctab_vlr_matricula: null
                },
            cntrc_tp_matricula: 0,
            matri_codigo: null,
            turm_codigo: null,
            horarios: []
        },
        horarios: [],
        listaMidias: [],
        desconto: {
            cntrdesc_codigo: null,
            cntrdesc_dia: '',
            cntrdesc_desconto: ''
        },        
      }
  },
  methods: {
    onImprimirContrato () {
        printJS({printable: '<style>.ql-editor p{ line-height: 1.2rem!important;}</style><div class="ql-editor" style="overflow: hidden;">'+this.lista.cntr_texto+'</div>', type: 'raw-html', scanStyles: false, css: 'https://cdnjs.cloudflare.com/ajax/libs/quill/1.3.6/quill.core.min.css'})
    },
    /* QUITACOES */
    getTpQuitacao(index){
        const ret = this.tpQuitacao.filter(el => el.tqui_codigo === index);
        //console.log(ret,index,ret[0].tqui_descricao);
        return ret && ret[0] ? ret[0].tqui_descricao : null;
    },
    async setTpQuitacao () {
        await axios.get('esctpquitacao/combo')
            .then(response => {
                this.tpQuitacao = response.data.data 
            })
            .catch(response => {
                //console.log(response)
            })
    },     
    async onBoleto (tipo,index){
        let url = '';
        let method = 'GET';
        let selecionadosList = [];
        if (index === null){
            url = `/boletos/contrato/${this.lista.cntr_codigo}`;
        }else if (index === -1){
            const selecionados = this.lista.parcelas.filter(item => item.imprimir === 1);
            selecionadosList = selecionados.map(item => item.cntrp_codigo);
            url = `/boletos/contrato`;
            method = 'POST';
        }else{
            url = `/boletos/contrato/${this.lista.cntr_codigo}/${this.lista.parcelas[index].cntrp_codigo}`;
        }
        await axios({
                url,
                method,
                responseType: 'blob', // important
                data: {
                    contrato: this.lista.cntr_codigo,
                    parcelas: selecionadosList
                }
            }).then((response) => {
                const urlPDF = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                if (tipo === 'D'){
                    const link = document.createElement('a');
                    link.href = urlPDF;
                    link.setAttribute('download', `${uuid.v1()}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                } else {
                    printJS({printable: urlPDF, type: 'pdf'})
                }
            }).catch(response => {
                this.$swal('Erro!',
                    'Houve um erro interno no servidor ou nenhum Boleto foi selecionado.',
                    'error');
            }) 
    },    
    onChangeSelecionado (index){
        //alert(item)
        //const lin = this.horarios2[item]
        //console.log(lin)
        //this.cursoTabela.horarios.push(lin)
        //this.horarios2.splice(item,1)
    },
    onChangeImpressao (index){

        ///this.lista.parcelas[item].imprimir = this.lista.parcelas[item].imprimir === 1 ? 0 : 1;
        //alert(item)
        //const lin = this.horarios2[item]
        //console.log(lin)
        //this.cursoTabela.horarios.push(lin)
        //this.horarios2.splice(item,1)
    },

    async onValida() {
        let msgs = ''
        let soma = 0
        if (this.lista.trec_codigo == null || this.lista.trec_codigo == ''){
            msgs += 'O campo Tipo tem um valor inválido; </br>';
        }
        if (this.lista.cntr_data == null || this.lista.cntr_data == 0){
            msgs += 'Digite uma Data de Cadastro válida; </br>';
        }
        if (this.lista.pes_codigo == null || this.lista.pes_codigo == ''){
            msgs += 'Selecione um Aluno/Pessoa; </br>';
        }
        if (typeof this.lista.cursos === 'undefined' || this.lista.cursos.length === 0){
            msgs += 'Selecione ao menos uma Tab. Valor (Curso); </br>';
        }
        if ((this.lista.cbnc_codigo == null || this.lista.cbnc_codigo == '') &&
            !(this.lista.cntr_bolsista === 1) && (this.lista.cntr_tp_desconto === 1) && (this.lista.cntr_desconto === 100)){
            msgs += 'Selecione uma Conta Bancária para emissão dos Boletos; </br>';
        }
        if (this.lista.cpg_codigo == null || this.lista.cpg_codigo == ''){
            msgs += 'Selecione uma Condição de Pagamento; </br>';
        }        
        if ((this.lista.cntr_parcelas == null || this.lista.cntr_parcelas == 0) && 
            !(this.lista.cntr_bolsista === 1) && (this.lista.cntr_tp_desconto === 1) && (this.lista.cntr_desconto === 100)){
            msgs += 'O campo Qtd. de Parcelas não pode ser nulo ou zero; </br>';
        }
        if ((this.lista.cntr_dia_vencimento == null || this.lista.cntr_dia_vencimento == 0) &&
            !(this.lista.cntr_bolsista === 1) && (this.lista.cntr_tp_desconto === 1) && (this.lista.cntr_desconto === 100)){
            msgs += 'O campo Dia de Vencimento das Parcelas não pode ser nulo ou zero; </br>';
        }
        if ((this.lista.cntr_vlr_contrato == null || this.lista.cntr_vlr_contrato == 0) &&
            !(this.lista.cntr_bolsista === 1) && (this.lista.cntr_tp_desconto === 1) && (this.lista.cntr_desconto === 100)){
            msgs += 'O campo Valor do Contrato não pode ser nulo ou zero; </br>';
        }
        if (this.lista.parcelas !== null && typeof this.lista.parcelas !== 'undefined'){
            soma = 0
            for (var index in this.lista.parcelas) {
                soma += Number(this.lista.parcelas[index].cntrp_liquido) 
            }            
            if (Number(soma).toFixed(2) !== Number(this.lista.cntr_vlr_contrato).toFixed(2)){
                msgs += 'O Total das parcelas R$ '+Number(soma).toFixed(2)+' não pode ser diferente do valor do Contrato R$ '+Number(this.lista.cntr_vlr_contrato).toFixed(2)+'; </br>';
            }
        }        
        
        if (msgs !== ''){
            this.isValidated = await false
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Incluir / Alterar o Registro',
                html/*text*/: msgs,
                /*footer: '<a href>Why do I have this issue?</a>'*/
                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
            })        
        }else{
            this.isValidated = await true
        }
    },      
    setData () {
        if (this.modo == 'novo'){
            this.lista.turm_codigo = null
            this.lista.cntr_status = 1 
            this.lista.cntr_ent = this.Entidade
            this.lista.cntr_esc = this.Escola
            this.lista.cntr_ano = this.Exercicio
            this.lista.cntr_data = new Date()
            this.lista.audit_data_cadastro = this.moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
            this.lista.audit_usu_cadastro = this.infoLogado['usu_usuario']         
            this.lista.audit_local_cadastro = this.IP
        }else if (this.modo == 'editar'){
            this.lista.audit_data_atualiza = this.moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
            this.lista.audit_usu_atualiza = this.infoLogado['usu_usuario']            
            this.lista.audit_local_atualiza = this.IP
        }else if (this.modo == 'cancelar'){
            //this.lista.cntr_status = 0; 
            this.lista.audit_data_cancelado = this.moment(new Date()).format('YYYY-MM-DD hh:mm:ss')
            this.lista.audit_usu_cancelado = this.infoLogado['usu_usuario']            
            this.lista.audit_local_cancelado = this.IP
        }
        this.lista.cursos.forEach((el,idx) => {
            el.horarios.forEach((el2,idx2) => {
                this.lista.cursos[idx].horarios[idx2].selecionado = 1
            })
        })
    },
    async onBeforeDeletar(){
        if ((this.infoLogado.perfil.per_siges_exc_contrato === 0) ){
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Excluir',
                text: 'O CONTRATO não pode ser excluído.',
                footer: `<small class="text-danger"><strong>O Usuário ${this.infoLogado.usu_usuario.toUpperCase()} não possui permissão para a exclusão do "CONTRATO", contate o Usuário Administrador para mais informações.</strong></small>`
            })
            return await false;
        }
        return await true;
    },
    async onBeforeCancelar(){
        if ((this.infoLogado.perfil.per_siges_canc_contrato === 0) ){
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Cancelar',
                text: 'O CONTRATO não pode ser cancelado.',
                footer: `<small class="text-danger"><strong>O Usuário ${this.infoLogado.usu_usuario.toUpperCase()} não possui permissão para o cancelamento do "CONTRATO", contate o Usuário Administrador para mais informações.</strong></small>`
            })
            return await false;
        }

        if ((this.lista.cntr_status === 0) ){
            this.$swal({
                icon: 'warning',
                title: 'Contrato já esta cancelado.',
                text: 'O CONTRATO já esta com seu status alterado para cancelado.',
                footer: ''
            })
            return await false;
        }

        const temQuitacao = await this.lista.parcelas.some((item) => {
            return (item.receberparcelas.quitacao.quita_valor !== null && item.receberparcelas.quitacao.quita_valor > 0);
        });
        if (temQuitacao){
            await this.$swal({
                icon: 'error',
                title: 'Seleção de parcela(s).',
                text: 'Existe(m) parcela(s) do contas a receber quitada(s) para esse contrato, somente as parcelas em aberto poderão ser canceladas. \n \r Selecione as parcelas em abertos que serão canceladas.',
                footer: ''
            })
            this.$bvModal.show('parc-sel-cancelar');
            return await false;            
        } 
        /* */
        return await true;
    },    
    somaColuna(data,coluna) {
        if (typeof data !== 'undefined'){
            return data.reduce((soma, item) => soma + Number(item.tabvalores[coluna]), 0);
        } else {
            return 0.00
        }
    },
    setCondPgto (data) {
        this.condPgto = data
    },
    setContaBancaria (data) {
        this.contaBancaria = data
    },
    setTabela (data) {
        if (this.modo == 'novo' || this.modo == 'editar'){
            this.cursoTabela.tabvalores.curso.cur_codigo    = data.curso.cur_codigo
            this.cursoTabela.tabvalores.curso.cur_nome      = data.curso.cur_nome
            this.cursoTabela.tabvalores.ctab_descricao      = data.ctab_descricao
            this.cursoTabela.tabvalores.ctab_vlr_avista     = data.ctab_vlr_avista
            this.cursoTabela.tabvalores.ctab_vlr_parcelado  = data.ctab_vlr_parcelado
            this.cursoTabela.tabvalores.ctab_vlr_matricula  = data.ctab_vlr_matricula
        }
    },  
    /* CURSOS / TAB. VALORES */
    onIncluirCurso (e) {
        this.cursoSelecionado = null
        this.cursoTabela = {
            ctab_codigo: null,
            tabvalores: {
                curso: {
                    cur_codigo: null, 
                    cur_nome: null 
                    },
                ctab_descricao: null,
                ctab_vlr_avista: null,
                ctab_vlr_parcelado: null,
                ctab_vlr_matricula: null
                },
            //cur_codigo: null,
            //cur_nome: null,
            cntrc_tp_matricula: 0,
            matri_codigo: null,
            turm_codigo: null,
            horarios: []
        }
        this.$bvModal.show('curso-sel')
    },
    onSalvarCurso () {

        //Object.assign(this.cursoTabela.horarios, this.horarios2)
        this.horarios2.forEach((el,idx) => {
            if (el.selecionado == 1){
                this.cursoTabela.horarios.push(el)
            }
        })

        if (this.cursoSelecionado === null){
            this.lista.cursos.push(
                this.cursoTabela
            )
        } else {
            this.lista.cursos[this.cursoSelecionado] = {...this.cursoTabela}
        }
        this.lista.cntr_vlr_avista              = this.somaColuna(this.lista.cursos, 'ctab_vlr_avista')
        this.lista.cntr_vlr_parcelado           = this.somaColuna(this.lista.cursos, 'ctab_vlr_parcelado')
        this.lista.cntr_vlr_matricula           = this.somaColuna(this.lista.cursos, 'ctab_vlr_matricula')  

        this.cursoSelecionado = null
        this.cursoTabela =  {
                ctab_codigo: null,
                tabvalores: {
                    curso: {
                        cur_codigo: null, 
                        cur_nome: null 
                    },
                    ctab_descricao: null,
                    ctab_vlr_avista: null,
                    ctab_vlr_parcelado: null,
                    ctab_vlr_matricula: null
                },
                //cur_codigo: null,
                //cur_nome: null,
                cntrc_tp_matricula: 0,
                matri_codigo: null,
                turm_codigo: null,
                horarios: []
            }            
        this.$bvModal.hide('curso-sel')
    },
    onEditCurso (index) {
        this.cursoSelecionado = index
        this.cursoTabela = {...this.lista.cursos[index]}
        this.$bvModal.show('curso-sel')
    },
    onExcCurso (index) {
        this.$swal({
            title: 'Deseja realmente remover o item da lista?',
            text: "Este evento não podera ser revertido!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.lista.cursos.splice(index,1)
                this.onParcelas()
            }
        }) 
    },
    onEditParcela(index){
        this.parcelaSelecionada = index
        this.$watch(`lista.parcelas.${index}.cntrp_vencimento`, function (newVal, oldVal){
            if (newVal !== oldVal && typeof oldVal !== 'undefined'){
                this.onAlterarVencParcelas(index,newVal) 
                //console.log(index,newVal, oldVal)
            }
        })

        this.$bvModal.show('parc-sel')
    },
    onSalvarParcela(e){
        this.lista.parcelas[this.parcelaSelecionada].cntrp_liquido = Number(this.lista.parcelas[this.parcelaSelecionada].cntrp_valor) - Number(this.lista.parcelas[this.parcelaSelecionada].cntrp_desconto)
        this.parcelaSelecionada = null
        var totalContrato = 0.00
        for (var i = 0; i < this.lista.parcelas.length; i++) {            
            totalContrato += Number(this.lista.parcelas[i].cntrp_liquido)
        }            
        this.lista.cntr_vlr_contrato = totalContrato
        this.$bvModal.hide('parc-sel')
    },
    onParcelas () {
        if (this.modo == 'novo'){
            this.onGerarParcelas();
        }
    },
    onAtualizaParcelas () {
        if (this.modo !== 'novo'){
            let pagas = this.lista.parcelas.map(item => {
                if (item.receberparcelas && item.receberparcelas.quitacao.quita_data !== null)
                return item;
            }).filter(item => item !== undefined);
            console.log(pagas);
            if (pagas && !pagas[0]){
                this.onGerarParcelas();
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Erro(s) ao Atualizar as Parcelas',
                    text: 'Parcelas não atualizadas, existem quitações.',
                    footer: `<small class="text-danger"><strong>As parcelas não podem ser atualizadas pois possuem quitações, exclua as quitações no contas a receber e tente novamente.</strong></small>`
                })                
            }
        }
    },
    onGerarParcelas () {
        var totalContrato = 0
        if (Number(this.lista.cntr_parcelas) === 1){
            totalContrato = Number(this.lista.cntr_vlr_avista)/* + Number(this.lista.cntr_vlr_matricula)*/
        } else {
            totalContrato = Number(this.lista.cntr_vlr_parcelado)/* + Number(this.lista.cntr_vlr_matricula)*/
        }

        var totalParcelas = Number(this.lista.cntr_parcelas)/* + Number(this.lista.cntr_parcelas_matri)*/
        var descParcela = 0
        if (this.lista.cntr_bolsista === 1 && this.lista.cntr_tp_bolsa === 2){
            if (this.lista.cntr_tp_desconto === 1){
                /* % */
                totalContrato = Number(Number(totalContrato) - (Number(totalContrato)*(Number(this.lista.cntr_desconto)/100))).toFixed(2)
            }else
            if (this.lista.cntr_tp_desconto === 2){
                /* $ */
                totalContrato = Number(Number(totalContrato) - Number(this.lista.cntr_desconto)).toFixed(2)
            }
        }

        var valorParcela = 0
        var restoParcelas = 0
        var totalParcelado = 0
        if (totalParcelas > 0){
            valorParcela = Number(totalContrato / totalParcelas).toFixed(2); 
        }else if (totalParcelas === 0){
            valorParcela = 0;
        }

        totalParcelado = Number(valorParcela) * Number(totalParcelas)
        restoParcelas = Number(Number(totalParcelado) - Number(totalContrato)).toFixed(2)
        /*var valorParcelaOri = valorParcela;
        if (this.lista.cntr_bolsista === 1 && this.lista.cntr_tp_bolsa === 2){
            if (this.lista.cntr_tp_desconto === 3){
                // % PARC.
                valorParcela = Number(Number(valorParcela) - (Number(valorParcela)*(Number(this.lista.cntr_desconto)/100))).toFixed(2)
                restoParcelas = 0
            }else
            if (this.lista.cntr_tp_desconto === 4){
                // $ PARC.
                valorParcela = Number(Number(valorParcela) - Number(this.lista.cntr_desconto)).toFixed(2)
                restoParcelas = 0
            }
        }*/            
        this.lista.cntr_vlr_parcela = valorParcela
        this.lista.cntr_vlr_contrato = totalContrato + Number(this.lista.cntr_vlr_matricula)
        this.lista.parcelas = []
        var diaVencimento = this.lista.cntr_dia_vencimento
        var mesAtual = typeof this.lista.cntr_venc_matricula !== 'undefined' && this.lista.cntr_venc_matricula !== null & this.lista.cntr_venc_matricula !== '' ? moment(this.lista.cntr_venc_matricula).locale('pt-br').get('month') : moment().locale('pt-br').get('month')
        var anoAtual = typeof this.lista.cntr_venc_matricula !== 'undefined' && this.lista.cntr_venc_matricula !== null & this.lista.cntr_venc_matricula !== '' ? moment(this.lista.cntr_venc_matricula).locale('pt-br').get('year') : moment().locale('pt-br').get('year')
        var dataVencimento = typeof this.lista.cntr_venc_matricula !== 'undefined' && this.lista.cntr_venc_matricula !== null & this.lista.cntr_venc_matricula !== '' ? moment(this.lista.cntr_venc_matricula).locale('pt-br').format('YYYY-MM-DD') : moment().set({'date': diaVencimento, 'month': mesAtual, 'year': anoAtual}).locale('pt-br').format('YYYY-MM-DD') /*moment().locale('pt-br').format('YYYY-MM-DD')*/;            
        dataVencimento = moment(dataVencimento).day() === 0 ? moment(dataVencimento).day(1) : moment(dataVencimento).day() === 6 ? moment(dataVencimento).day(8) : moment(dataVencimento);
        var data = typeof this.lista.cntr_venc_matricula !== 'undefined' && this.lista.cntr_venc_matricula !== null & this.lista.cntr_venc_matricula !== '' ? moment(this.lista.cntr_venc_matricula) : moment().locale('pt-br');
        var parcAtual = 0

        /* MATRÍCULAS */
        var valorParcelaMatri = Number(this.lista.cntr_vlr_matricula) / Number(this.lista.cntr_parcelas_matri)
        var totalParceladoMatri = Number(valorParcelaMatri) * Number(this.lista.cntr_parcelas_matri)
        var restoParcelasMatri = Number(Number(totalParceladoMatri) - Number(this.lista.cntr_vlr_matricula)).toFixed(2)

        for (var i = 0; i < Number(this.lista.cntr_parcelas_matri); i++) {
            if (i > 0){
                dataVencimento = moment().set({'date': diaVencimento, 'month': mesAtual, 'year': anoAtual}).locale('pt-br').format('YYYY-MM-DD')
                dataVencimento = moment(dataVencimento).day() === 0 ? moment(dataVencimento).day(1) : moment(dataVencimento).day() === 6 ? moment(dataVencimento).day(8) : moment(dataVencimento);
                }
            parcAtual++
            mesAtual++
            if (mesAtual > 12){
                mesAtual = 1
                anoAtual++
            }
            this.lista.parcelas.push(
                {
                    cntrp_codigo: null,
                    cntrp_numero: /*this.lista.cntr_ent +'/'+*/ parcAtual +'-'+ (Number(this.lista.cntr_parcelas_matri)+Number(this.lista.cntr_parcelas)),
                    cntrp_parcela: parcAtual,
                    cntrp_vencimento: dataVencimento,
                    cntrp_tipo: 0,
                    cntrp_valor: parcAtual === Number(this.lista.cntr_parcelas_matri) ? valorParcelaMatri - restoParcelasMatri : valorParcelaMatri,
                    cntrp_liquido: parcAtual === Number(this.lista.cntr_parcelas_matri) ? valorParcelaMatri - restoParcelasMatri : valorParcelaMatri,
                    cntrp_desconto: 0.00,
                    tpag_codigo: 20,
                    cntrp_status: 1
                    //cntrp_valor: valorParcela
                })            
        }

        /* PARCELAS */
        for (var i = 0; i < Number(this.lista.cntr_parcelas); i++) {
            if (parcAtual > 0){
                dataVencimento = moment().set({'date': diaVencimento, 'month': mesAtual, 'year': anoAtual}).locale('pt-br').format('YYYY-MM-DD')
                dataVencimento = moment(dataVencimento).day() === 0 ? moment(dataVencimento).day(1) : moment(dataVencimento).day() === 6 ? moment(dataVencimento).day(8) : moment(dataVencimento);
            }
            parcAtual++
            mesAtual++
            if (mesAtual > 12){
                mesAtual = 1
                anoAtual++
            }
            this.lista.parcelas.push(
                {
                    cntrp_codigo: null,
                    cntrp_numero: /*this.lista.cntr_ent +'/'+*/ parcAtual +'-'+ (Number(this.lista.cntr_parcelas_matri)+Number(this.lista.cntr_parcelas)),
                    cntrp_parcela: parcAtual,
                    cntrp_vencimento: dataVencimento,
                    cntrp_tipo: 1,
                    cntrp_valor: parcAtual === (Number(this.lista.cntr_parcelas_matri)+Number(this.lista.cntr_parcelas)) ? valorParcela - restoParcelas : valorParcela,
                    cntrp_liquido: parcAtual === (Number(this.lista.cntr_parcelas_matri)+Number(this.lista.cntr_parcelas)) ? valorParcela - restoParcelas : valorParcela,
                    cntrp_desconto: 0.00,
                    tpag_codigo: 20,
                    cntrp_status: 1
                })            
        }
        /* SOMA CONTRATO E DESCONTO NA PARCELA */
        totalContrato = 0.00
        for (var i = 0; i < this.lista.parcelas.length; i++) {
            if (this.lista.parcelas[i].cntrp_tipo == 1){
                if (this.lista.cntr_bolsista === 1 && this.lista.cntr_tp_bolsa === 2){
                    if (this.lista.cntr_tp_desconto === 3){
                        /* % PARC.*/
                        this.lista.parcelas[i].cntrp_liquido = Number(Number(this.lista.parcelas[i].cntrp_valor) - (Number(this.lista.parcelas[i].cntrp_valor)*(Number(this.lista.cntr_desconto)/100))).toFixed(2)
                        this.lista.parcelas[i].cntrp_desconto = Number(Number(this.lista.parcelas[i].cntrp_valor) - (Number(this.lista.parcelas[i].cntrp_liquido))).toFixed(2)
                    }else
                    if (this.lista.cntr_tp_desconto === 4){
                        /* $ PARC.*/
                        this.lista.parcelas[i].cntrp_liquido = Number(Number(this.lista.parcelas[i].cntrp_valor) - Number(this.lista.cntr_desconto)).toFixed(2)
                        this.lista.parcelas[i].cntrp_desconto = Number(Number(this.lista.parcelas[i].cntrp_valor) - (Number(this.lista.parcelas[i].cntrp_liquido))).toFixed(2)
                    }
                }
            }
            totalContrato += Number(this.lista.parcelas[i].cntrp_liquido)
        }            
        this.lista.cntr_vlr_contrato = totalContrato
        
    },
    onAlterarVencParcelas(index, data){
        var diaVencimento = typeof data !== 'undefined' && data !== null & data !== '' ? moment(data).locale('pt-br').get('date') : this.lista.cntr_dia_vencimento
        var mesAtual = typeof data !== 'undefined' && data !== null & data !== '' ? moment(data).locale('pt-br').get('month') : moment().locale('pt-br').get('month')
        var anoAtual = typeof data !== 'undefined' && data !== null & data !== '' ? moment(data).locale('pt-br').get('year') : moment().locale('pt-br').get('year')
        var dataVencimento = typeof data !== 'undefined' && data !== null & data !== '' ? moment(data).locale('pt-br').format('YYYY-MM-DD') : moment().set({'date': diaVencimento, 'month': mesAtual, 'year': anoAtual}).locale('pt-br').format('YYYY-MM-DD');            
        var data = typeof data !== 'undefined' && data !== null & data !== '' ? moment(data) : moment().locale('pt-br');
        var parcAtual = 0

        /* MATRÍCULAS */
        for (var i = (Number(index)+1); i < this.lista.parcelas.length; i++) {
            mesAtual++
            if (mesAtual > 12){
                mesAtual = 1
                anoAtual++
            }
            var dataNova = moment().set({'date': diaVencimento, 'month': mesAtual, 'year': anoAtual}).locale('pt-br').format('YYYY-MM-DD')
            //console.log(i,dataNova)
            this.lista.parcelas[i].cntrp_vencimento = dataNova
        }
    },
    /* TEXTO CONTRATO */
    async verContrato () {
        /*let URL = `/escmodeloscontratos/editar/${this.lista.modelc_codigo}`
        let texto = ''
        await axios.get(`${URL}`)
            .then(response => {
                texto = response.data.data.modelc_texto                
            })
            .catch(response => {

            })
        // ALTERANDO DADOS DO CONTRATO //
        texto = texto.replace(/&lt;%ALUNO_NOME%&gt;/g,'MARCELO DE PAULA E SILVA')
        texto = texto.replace('&lt;%ALUNO_CPF%&gt;','000.138.391.44')
        texto = texto.replace('&lt;%ALUNO_TELEFONES%&gt;','(66)99667-3434')
        
            

        this.lista.cntr_texto = texto*/
        this.$bvModal.show('texto-sel')
    },
    async getMidias () {
        let URL = `/escmidias/combo`
        await axios.get(`${URL}`)
            .then(response => {
                this.listaMidias = response.data.data                
            })
            .catch(response => {

            })
    },
    onAdcDesconto () {
        this.lista.descontos.push(
        {
            cntrdesc_codigo: this.desconto.cntrdesc_codigo,
            cntrdesc_dia: this.desconto.cntrdesc_dia,
            cntrdesc_desconto: this.desconto.cntrdesc_desconto,
        })
        this.desconto.cntrdesc_codigo = null
        this.desconto.cntrdesc_dia = ''
        this.desconto.cntrdesc_desconto = ''
    },
    onEditDesconto (index) {
        this.desconto.cntrdesc_codigo = this.lista.descontos[index].cntrdesc_codigo
        this.desconto.cntrdesc_dia = this.lista.descontos[index].cntrdesc_dia
        this.desconto.cntrdesc_desconto = this.lista.descontos[index].cntrdesc_desconto
        this.lista.descontos.splice(index,1)
    },
    onExcDesconto (index) {
        this.$swal({
            title: 'Deseja realmente remover o item da lista?',
            text: "Este evento não podera ser revertido!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
        }).then((result) => {
            this.lista.descontos.splice(index,1)                       
        })        
    },
  },
  computed: {
    ...mapState('Login',['infoLogado','Entidade','Exercicio','Escola','IP']),
    horarios2 () {
        let res = []
        this.horarios.forEach((lin) => {
            const ret =  this.cursoTabela.horarios.filter(el => {
                return el.horari_codigo == lin.horari_codigo
            })
            if (ret.length === 0){
                res.push({
                    cntr_ano: this.lista.cntr_ano,
                    cntr_codigo: this.lista.cntr_codigo,
                    cntr_ent: this.lista.cntr_ent,
                    cntr_esc: this.lista.cntr_esc,
                    cntrc_codigo: this.cursoTabela.cntrc_codigo,
                    cntrch_codigo: null,
                    horari_codigo: lin.horari_codigo,
                    horario: lin,
                    selecionado: 0
                })
            }
        })
        return res
    },
    midias: {
        get: function () {
            if (typeof this.lista.cntr_crm_midia !== 'undefined' && this.lista.cntr_crm_midia !== null && this.lista.cntr_crm_midia !== ''){
                let req = this.lista.cntr_crm_midia.split(',')
                let resultado = [];
                req.forEach(el =>{
                    resultado.push(...this.listaMidias.filter(el2 => el2.midia_nome === el))
                })
                return resultado
            }
        },
        set: function (newValue) {
            const res = newValue.map(item => item.midia_nome)
            this.lista.cntr_crm_midia = res.toString()
        }
    }

  }, 
  created() {
    this.setTpQuitacao()
    this.getMidias()
  },
  watch: {
    'lista.cntr_bolsista': function (newVal, oldVal){
        if (typeof newVal != 'undefined' && newVal != null && newVal === 0 && (this.modo === 'editar' || this.modo === 'novo')){
            this.lista.cntr_tp_bolsa = null
            this.lista.cnv_codigo = null
            this.lista.cntr_desconto = null
            this.lista.cntr_tp_desconto = null
        }
    },
    'lista.cntr_tp_bolsa': function (newVal, oldVal){
        if (typeof newVal != 'undefined' && newVal != null && (this.modo === 'editar' || this.modo === 'novo')){
            if (newVal === 2 ){
                this.lista.cnv_codigo = null
            }else if (newVal === 1 ){
                this.lista.cntr_desconto = null
                this.lista.cntr_tp_desconto = null
            }
        }
    },
    'lista.cntr_dia_vencimento': function(newVal, oldVal){
        if(newVal < 1  || newVal > 31){
            this.$swal({
                title: 'Dia de Vencimento Inválido',
                text: "Informe um valor entre 1 - 31.",
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'OK'
            })
            this.lista.cntr_dia_vencimento = null
        }
    },
    'lista.cpg_codigo': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
            var ret = this.condPgto.filter(function(el) {
                return el.cpg_codigo == newVal;
            })
            this.condPgtoSelecionado = ret[0]
            if (this.condPgtoSelecionado){
                this.lista.ctab_parcelas = this.condPgtoSelecionado.cpg_nparcelas
            }
        }
    },
    'lista.cbnc_codigo': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
            var ret = this.contaBancaria.filter(function(el) {
                return el.cbnc_codigo == newVal;
            })
            this.contaBancariaSelecionado = ret[0]
            if (this.contaBancariaSelecionado){
                this.lista.cntr_multa = this.contaBancariaSelecionado.cbnc_bol_multa
                this.lista.cntr_juros = this.contaBancariaSelecionado.cbnc_bol_juros
            }
        }
    },    
    'lista.cntr_parcelas': function (newVal, oldVal){
        this.onParcelas()
    },
    'lista.cntr_parcelas_matri': function (newVal, oldVal){ 
        this.onParcelas()
    },
    'lista.cntr_vlr_avista': function (newVal, oldVal){ 
        this.onParcelas()
    },
    'lista.cntr_vlr_parcelado': function (newVal, oldVal){ 
        this.onParcelas()
    },
    'lista.cntr_vlr_matricula': function (newVal, oldVal){ 
        this.onParcelas()
    },
    'lista.cntr_dia_vencimento': function (newVal, oldVal){ 
        this.onParcelas()
    },
    'lista.cntr_tp_bolsa': function (newVal, oldVal){ 
        this.onParcelas()
    },
    'lista.cntr_bolsista': function (newVal, oldVal){ 
        this.onParcelas()
    },
    'lista.cntr_tp_desconto': function (newVal, oldVal){ 
        this.onParcelas()
    },
    'lista.cntr_desconto': function (newVal, oldVal){ 
        this.onParcelas()
    },
    'cursoTabela.turm_codigo': async function (newVal, oldVal){
        if (newVal){
            let URL = '/escagendamento/combo?horari_situacao=1&turm_codigo=' + newVal
            await axios.get(`${URL}`)
            .then(response => {
                this.horarios = response.data.data                
            })
            .catch(response => {
                //this.message = response.response.data.message
                //this.errors = response.response.data.errors
                //this.status = response.response.status
            })                       
        }
    }    
  }     
}
</script>

<style scoped>

    @media print {
        body * {
            visibility: hidden;
        }
        #contratoTexto, #contratoTexto * {
            visibility: visible;
        }
        #contratoTexto {
            position: fixed;
            left: 0;
            top: 0;
        }
    }
</style>