<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <div class="row">
        <div class="col-sm-2">
          <mct-form-itens coluna="0" tipo="number" campo="ser_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
        </div>    
      </div>
      <div class="row">
        <div class="col-lg-6">
          <mct-form-itens coluna="0" tipo="search" campo="cur_codigo" :valor="lista" titulo="Curso" obrigatorio="true" :errors="errors" origem="esc_cursos" origemChave="cur_codigo" origemRetorno='cur_nome' origemChaveComposta="true" @setDataRetorno="setCurso" :modo="modo" tamanho="2"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <mct-form-itens coluna="0" tipo="text" campo="ser_descricao" :valor="lista" titulo="Descrição" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="text" campo="ser_serie" :valor="lista" titulo="Ano / Série" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
        </div>
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="select" campo="nvl_codigo" :valor="lista" titulo="Nível" :errors="errors" origem="esc_nivel" origemChave="nvl_codigo" origemRetorno='nvl_descricao' :modo="modo" ></mct-form-itens>
        </div>
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="select" campo="snvl_codigo" :valor="lista" titulo="Sub-Nível" :errors="errors" origem="esc_sub_nivel" origemChave="snvl_codigo" origemRetorno='snvl_descricao' :modo="modo" ></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <fieldset class="well">
            <legend class="well-legend">Próximo Ano</legend> 
            <div class="row">
              <div class="col-lg-12">
                <mct-form-itens coluna="0" tipo="search" campo="pser_codigo" :valor="lista" titulo="Código" :errors="errors" origem="esc_serie" origemChave="ser_codigo" origemRetorno='ser_descricao' origemChaveComposta="true" @setDataRetorno="setSerie" :modo="modo" tamanho="2"></mct-form-itens>
              </div>
            </div>
          </fieldset>
          </div>
      </div>
    </div>              
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctModal from '@/components/comum/MctModalSHB.vue'

export default {
  Name: 'Series',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      MctModal,
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
      }
  },
  methods: {
    setData (lista, errors, lastCode) {
        this.lista = lista
        this.errors = errors        
    },
    setCurso(data){
      this.lista.cur_codigo = data.cur_codigo
      this.lista.ser_ent = data.cur_ent
      this.lista.ser_esc = data.cur_esc
      this.lista.ser_ano = data.cur_ano
    },
    setSerie(data){
      this.lista.pser_codigo = data.ser_codigo
      this.lista.ser_ent = data.ser_ent
      this.lista.ser_esc = data.ser_esc
      this.lista.ser_ano = data.ser_ano
    },
  
  },
   computed: {
    ...mapState('Login',['infoLogado']), 
  },  
  created() {
  
  }     
}
</script>