<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="!Alterando ? 'editar' : 'readonly'" botoes="false">
    <div slot="formulario">
      <div class="view" style="padding-top: 5px; padding-bottom: 5px;">      
        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Professor</legend> 
          <div class="row">
            <div class="col-12">
              <mct-form-itens tipo="select" campo="contd_prof_codigo" :valor="lista" titulo="Professor" obrigatorio="true" :errors="errors" :origemData="professores" origemRetornoSec="value" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
          </div>  
        </fieldset>
        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Turma</legend> 
          <div class="row">
            <div class="col-4">
              <mct-form-itens tipo="select" campo="contd_turm_codigo" :valor="lista" titulo="Turma" obrigatorio="true" :errors="errors" :origemData="turmas" origemRetornoSec="value" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
            <div class="col-4" v-if="infoEscola.esc_tipo !== 1">
              <mct-form-itens tipo="select" campo="contd_ser_codigo" :valor="lista" titulo="Módulo" :errors="errors" obrigatorio="true" 
                :origemFiltro="{cur_codigo: turmaSelecionada.cur_codigo}" origemChave="ser_codigo" origemRetorno='ser_descricao' origemRetornoSec="ser_codigo" origem="esc_serie" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
            <div class="col-4">
              <mct-form-itens tipo="select" campo="contd_disp_codigo" :valor="lista" titulo="Disciplina" :errors="errors" obrigatorio="true" :origemData="disciplinas" origemRetornoSec="value" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
            <div class="col-4" v-if="infoEscola.esc_tipo === 1">
              <mct-form-itens tipo="select" campo="contd_etap_codigo" :valor="lista" titulo="Etapa" :errors="errors" obrigatorio="true" origem="esc_etapas" origemChave="etap_codigo" origemRetorno='etap_descricao'  :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
          </div>
          <div class="row" v-if="infoEscola.esc_tipo !== 1">
            <div class="col-12">
              <mct-form-itens tipo="select" campo="contd_horari_codigo" :valor="lista" titulo="Agendamento" :errors="errors" obrigatorio="true" 
                :origemFiltro="{cur_codigo: turmaSelecionada.cur_codigo, ser_codigo: lista.contd_ser_codigo, turm_codigo: lista.contd_turm_codigo}" origemChave="horari_codigo" origemRetorno='horari_descricao' origemRetornoSec="horari_codigo" origem="esc_horarios" :modo="!Alterando ? 'editar' : 'readonly'"></mct-form-itens>
            </div>
          </div>
        </fieldset>
        <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;">
          <legend class="well-legend">Data/Conteúdo/Material de Apoio</legend> 
          <div class="row">
            <div class="col-4">
              <mct-form-itens tipo="datepicker" campo="contd_data" :valor="lista" titulo="Data" :errors="errors" obrigatorio="true" :modo="!Alterando ? 'editar' : 'readonly'" :legenda="moment(lista.contd_data).locale('pt-br').format('dddd')"
              :disabled-date="disabledBeforeTodayAndAfterAWeek"
              ></mct-form-itens>
            </div>
            <div class="col-4">
              <mct-form-itens tipo="datepicker" campo="contd_data2" :valor="lista" titulo="Data" :errors="errors" obrigatorio="true" :modo="!Alterando ? 'editar' : 'readonly'" :legenda="moment(lista.contd_data2).locale('pt-br').format('dddd')"
              :disabled-date="disabledBeforeTodayAndAfterAWeek"
              ></mct-form-itens>
            </div>
            <div class="col-4">
              <div class="btn-group" role="group" aria-label="Ações" style="margin-top: 20px;">
                <button type="button" class="btn btn-info text-white" aria-label="Left Align" @click.prevent.stop="visualizar()">
                <span class="fa fa-search text-white" aria-hidden="true"></span> Visualizar </button>
              </div>
            </div>
          </div>
          <div style="padding-top: 10px;"> 
            <template v-for="(item,idx) in lista.conteudos"> 
              <div :key="idx">
                <div class="row">
                  <div class="col-12 bg-info text-white mt-2">
                    <div><strong>Data: </strong>{{ item.contd_data | data }}</div>
                    <div><strong>Aula: </strong>{{ item.contd_aula }}</div>
                  </div>       
                </div>
                <div class="row">
                  <div class="col-12">
                    <mct-form-itens tipo="text" campo="contd_descricao" :valor="item" titulo="Descrição da Aula:" :errors="errors" :modo="Alterando ? 'editar' : 'readonly'"></mct-form-itens>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <mct-form-itens tipo="textarea" campo="contd_conteudo" :valor="item" titulo="Conteúdo da Aula:" :errors="errors" :modo="Alterando ? 'editar' : 'readonly'" linhas="5"></mct-form-itens>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <mct-form-itens tipo="textarea" campo="contd_observacoes" :valor="item" titulo="Obsevações da Aula:" :errors="errors" :modo="Alterando ? 'editar' : 'readonly'" linhas="3"></mct-form-itens>
                  </div>
                </div>
              </div> 
              <fieldset class="well" style="margin-top: 5px;margin-bottom: 0px;padding-bottom: 5px;padding-top: 0px;" :key="'m'+idx">
                <legend class="well-legend">Material de Apoio</legend> 
                
                <div class="row row-striped p-0 py-1 m-0" v-for="(itemm,idxm) in item.material" :key="idxm">
                  <div class="col-3">{{itemm.contdm_tipo === 1 ? 'MATERIAL EM VÍDEO' : 
                                      itemm.contdm_tipo === 2 ? 'MATERIAL EM ÁUDIO' :
                                      itemm.contdm_tipo === 3 ? 'MATERIAL EM PDF' : 
                                      itemm.contdm_tipo === 4 ? 'IMAGENS' :
                                      itemm.contdm_tipo === 5 ? 'QUESTIONÁRIO' : ''}}</div>
                  <div class="col-3">{{itemm.contdm_descricao}}</div>
                  <div class="col-2">{{itemm.contdm_data_encerramento | data}}</div>
                  <div class="col-2">{{itemm.contdm_hora_encerramento}}</div>
                </div>
              </fieldset>
            </template>            
          </div>
        </fieldset>
        <div style="padding-top: 17px;">
          <button type="button" class="btn btn-danger text-white" aria-label="Left Align" @click.prevent.stop="$router.back()">
              <span class="fa fa-times text-white" aria-hidden="true"></span> Fechar </button>
        </div>
      </div> 
    </div>              
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'
import moment from 'moment'
import {isEmpty, minBy, orderBy, size, filter, some} from "lodash"

import MctPadrao from '@/components/comum/MctFormPadraoSCB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'EscConteudoProfessorData',
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {
          contd_cur_codigo: null,
          contd_ser_codigo: null,
          contd_turm_codigo: null,
          contd_prof_codigo: null,
          contd_disp_codigo: null,
          contd_etap_codigo: null,
          contd_data: null,
          contd_data2: null,
          conteudos: []
        },
        errors:{},
        message: '',
        status: 0,
        turmas:{},
        professores:[],
        disciplinas:[],
        arraySql:[],
        datasDisciplina: [],
        Alterando: false,
        material: {},
        materialSelecionado: -1,        
        files: [],
        conteudoSelecionado: -1
      }
  },
  methods: {
    disabledBeforeTodayAndAfterAWeek(date) {
      if (this.datasDisciplina){
        const data = this.datasDisciplina.filter((ele) => ele.horaridia_data === moment(new Date(date)).format("YYYY-MM-DD"));
        if (data && data[0]){
          return null;
        }
        return date;
      }
      return null;
    },
    async setProfessores(){      
      await axios.get('escprofessordisciplina/professoresturmas')
        .then(response => {
          this.arraySql = response.data
          })
          
      this.professores = this.arraySql.professores
      if (this.infoLogado.pes_codigo > 0){
        this.professores = this.professores.filter(item => item.value === this.infoLogado.pes_codigo);
        this.lista.contd_prof_codigo = this.infoLogado.pes_codigo;
        this.setTurma();
      }
    },
    setTurma(){
      this.turmas = _.filter(this.arraySql.turmas,{'pes_codigo':this.lista.contd_prof_codigo})
      this.turmas = _.uniqWith(this.turmas, _.isEqual);
    },
    async setDisciplinas(){
      this.disciplinas = []
      this.disciplinas = _.filter(this.arraySql.disciplinas,{'turm_codigo':this.lista.contd_turm_codigo,'professor':this.lista.contd_prof_codigo})
      this.disciplinas = _.uniqWith(this.disciplinas, _.isEqual);
    },        
    async getDatas () {
      let URL = '';
      if (this.infoEscola.esc_tipo === 1){
        if (typeof this.lista.contd_etap_codigo !== 'undefined' && this.lista.contd_etap_codigo !== null)
          URL = '&etap_codigo='+this.lista.contd_etap_codigo
      }else{
        if (typeof this.lista.contd_horari_codigo !== 'undefined' && this.lista.contd_horari_codigo !== null)
          URL = '&horari_codigo='+this.lista.contd_horari_codigo
      }
      if (URL !== ''){
        await axios.get('eschorarios/diasdisp?turm_codigo='+this.lista.contd_turm_codigo+'&disp_codigo='+this.lista.contd_disp_codigo+URL)
          .then(response => {
            this.datasDisciplina = response.data.data
          })
          .catch(response => {
            this.message = response.response.data.message
            this.errors = response.response.data.errors
            this.status = response.response.status
          })
      }        
    },       
    async visualizar(){
      if(this.validaInputs()){
        if(this.lista.contd_aula <= 0 || this.lista.contd_aula > this.lista.contd_qtde )
          {
            this.$swal({
              title:'O campo "CONTEÚDO DA AULA Nº" não pode tem o valor maior/menor que a "QUANTIDADE DE AULAS" ou igual a zero.',
              text: 'Preencha o campo com um valor válido e tente novamente.',
              icon: 'warning'});
            return
          }
          let URL = '';
          if (this.infoEscola.esc_tipo === 1) {
            URL = 'contd_turm_codigo='+this.lista.contd_turm_codigo+'&contd_prof_codigo='+this.lista.contd_prof_codigo+'&contd_disp_codigo='+
            this.lista.contd_disp_codigo+'&contd_etap_codigo='+this.lista.contd_etap_codigo+'&contd_data='+moment(this.lista.contd_data).format("YYYY-MM-DD")+'&contd_data2='+moment(this.lista.contd_data2).format("YYYY-MM-DD");
          }else{
            URL = 'contd_turm_codigo='+this.lista.contd_turm_codigo+'&contd_prof_codigo='+this.lista.contd_prof_codigo+'&contd_disp_codigo='+
            this.lista.contd_disp_codigo+'&contd_data='+moment(this.lista.contd_data).format("YYYY-MM-DD")+'&contd_data2='+moment(this.lista.contd_data2).format("YYYY-MM-DD");
          }
          
          await axios.get(`escconteudo/pordata?${URL}`)
            .then(response => {
              //this.Alterando = true
              if (response.data.data != null){
                this.lista.conteudos = response.data.data
              }
            })
            .catch(response => {
              this.message = response.response.data.message
              this.errors = response.response.data.errors
              this.status = response.response.status
            })
      }          
    },
    validaInputs () {
      this.errors = new Object()
      if (this.lista.contd_turm_codigo == null || this.lista.contd_turm_codigo == ''){
        this.errors.contd_turm_codigo = []
        this.errors.contd_turm_codigo.push("O campo TURMA não pode ser nulo ou vazio.") 
      }
      if (this.lista.contd_prof_codigo == null || this.lista.contd_prof_codigo == ''){
        this.errors.contd_prof_codigo = []
        this.errors.contd_prof_codigo.push("O campo PROFESSOR não pode ser nulo ou vazio.") 
      }
      if (this.lista.contd_disp_codigo == null || this.lista.contd_disp_codigo == ''){
        this.errors.contd_disp_codigo = []
        this.errors.contd_disp_codigo.push("O campo DISCIPLINA não pode ser nulo ou vazio.") 
      }
      if ((this.lista.contd_etap_codigo == null || this.lista.contd_etap_codigo == '') && (this.infoEscola.esc_tipo === 1)){
        this.errors.contd_etap_codigo = []
        this.errors.contd_etap_codigo.push("O campo ETAPA não pode ser nulo ou vazio.") 
      }
      if (this.lista.contd_data == null || this.lista.contd_data == ''){
        this.errors.contd_data = []
        this.errors.contd_data.push("O campo DATA INICIAL não pode ser nulo ou vazio.") 
      }
      if (this.lista.contd_data2 == null || this.lista.contd_data2 == ''){
        this.errors.contd_data2 = []
        this.errors.contd_data2.push("O campo DATA FINAL não pode ser nulo ou vazio.") 
      }
      if (Object.keys(this.errors).length > 0) {
        this.message = "Houve um ou mais erros."
        this.status = 422
        return false
      }else{
        this.message = ""
        this.status = 0
        return true
      }        
    },
    confirma(){
      if(this.validaInputs()){
        this.$swal({
          title: 'Deseja realmente gravar os dados?',
          text: "Lembre-se que todos os dados anteriores serão apagados!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim'
          }).then((result) => {
            if (result.value) {
              this.gravar()
            }                     
        })
      }
    },    
  },
  computed: {
    ...mapState('Login',['infoLogado', 'infoEscola'])
  },  
  created() {
    this.setProfessores()  
  },
  watch: {    
    'lista.contd_prof_codigo' (){
      this.turmas = [] 
      this.disciplinas = [] 
      this.lista.contd_turm_codigo = ''
      this.lista.contd_disp_codigo = ''
      this.setTurma()
    },
    'lista.contd_turm_codigo' (){
      if(this.lista.contd_turm_codigo!=null){
        this.setDisciplinas()
      }
    },
    'lista.contd_horari_codigo' (){
        if (this.infoEscola.esc_tipo !== 1){                  
          this.getDatas();
          this.lista.contd_data = null;
          this.lista.contd_data2 = null;
        }
    },
    'lista.contd_etap_codigo' (){
        if (this.infoEscola.esc_tipo === 1){                  
          this.getDatas();
          this.lista.contd_data = null;
          this.lista.contd_data2 = null;
        }
    },
    'lista.contd_disp_codigo' (){
        if (this.infoEscola.esc_tipo === 1){                  
          this.getDatas();
          this.lista.contd_data = null;
          this.lista.contd_data2 = null;
        }
    },
  }
}
</script>

<style scoped>
  input[type="file"]{
      opacity: 0;
      width: 100%;
      height: 150px;
      position: absolute;
      cursor: pointer;
  }
  .filezone {
      outline: 2px dashed grey;
      outline-offset: -10px;
      background: #ccc;
      color: dimgray;
      padding: 10px 10px;
      min-height: 150px;
      position: relative;
      cursor: pointer;
  }
  .filezone:hover {
      background: #c0c0c0;
  }

  .filezone p {
      font-size: 1.2em;
      text-align: center;
      padding: 50px 50px 50px 50px;
  }

  div.file-listing img{
      max-width: 90%;
  }

  div.file-listing{
      margin: auto;
      padding: 10px;
      border-bottom: 1px solid #ddd;
  }

  div.file-listing img{
      height: 50px;
  }

  .row-striped:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

</style>