<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <div class="row">
        <div class="col-sm-2">
          <mct-form-itens coluna="0" tipo="number" campo="turm_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
        </div> 
        <div class="col-sm-5">
            <mct-form-itens tipo="checkbox" campo="turm_situacao" :valor="lista" :titulo="lista.turm_situacao == 1 ? 'Cadastro Ativo.' : 'Cadastro Inativo.'" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#dados" role="tab">Dados Agendamento</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#horarios" role="tab">Horários de Aula</a>
        </li>
      </ul>

      <div class="tab-content">
        <!-- TAB DADOS TURMA -->            
        <div class="tab-pane active" id="dados" role="tabpanel">
          <fieldset class="well">
            <legend class="well-legend">Definição do Agendamento</legend> 
            <div class="row">
              <div class="col-lg-6">
                <mct-form-itens coluna="0" tipo="search" campo="cur_codigo" :valor="lista" titulo="Curso" :errors="errors" obrigatorio="true" origem="esc_cursos" origemChave="cur_codigo" origemRetorno='cur_nome' origemChaveComposta="true" :modo="modo" tamanho="1"></mct-form-itens>
              </div>
            </div>
            <!--<div class="row">
              <div class="col-lg-6">
                <mct-form-itens coluna="0" tipo="select" campo="ser_codigo" :valor="lista" titulo="Módulo" :errors="errors" obrigatorio="false" 
                  origem="esc_serie" origemChave="ser_codigo" origemRetorno='ser_descricao' :origemFiltro="{cur_codigo: lista.cur_codigo}"
                  :modo="modo"></mct-form-itens>
              </div>
            </div>-->
            <div class="row">
              <div class="col-lg-6">
                <mct-form-itens coluna="0" tipo="search" campo="turn_codigo" :valor="lista" titulo="Turno" :errors="errors" obrigatorio="false" origem="esc_turno" origemChave="turn_codigo" origemRetorno='turn_descricao' origemChaveComposta="true" :modo="modo" tamanho="1"></mct-form-itens>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <mct-form-itens coluna="0" tipo="search" campo="sal_codigo" :valor="lista" titulo="Sala" :errors="errors" obrigatorio="false" origem="esc_salas" origemChave="sal_codigo" origemRetorno='sal_descricao' origemChaveComposta="true" :modo="modo" tamanho="1"></mct-form-itens>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-3">
                <mct-form-itens coluna="0" tipo="text" campo="turm_identificacao" :valor="lista" titulo="Identificação" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
              <div class="col-lg-3">
                <mct-form-itens coluna="0" tipo="number" campo="turm_maximo_alunos" :valor="lista" titulo="Máximo Alunos" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
            </div>
          </fieldset>
        </div>

        <!-- TAB HORÁRIOS DE AULA -->   
        <div class="tab-pane" id="horarios" role="tabpanel">
          <!--<fieldset class="well">
            <legend class="well-legend">Período das Aulas</legend> 
            <div class="row">
              <div class="col-lg-3">
                <mct-form-itens coluna="0" tipo="date" campo="turm_data_criacao" :valor="lista" titulo="Data Inicial" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
              <div class="col-lg-3">
                <mct-form-itens coluna="0" tipo="date" campo="turm_data_fechamento" :valor="lista" titulo="Data Final" :errors="errors" :modo="modo"></mct-form-itens>    
              </div>
             </div>
          </fieldset>
          <fieldset class="well">
            <legend class="well-legend">Selecione o(s) dia(s) da(s) aulas</legend>
            <div style="max-width: 1000px; margin: 0 auto;">
              <ul class="mct-calendar d-flex flex-wrap justify-content-center">
                <li v-for="(dia,index) in diasEmBranco" :key="'bb'+index">

                </li>
                <li v-for="(dia,index) in diasCalendario" :key="index" :class="dia.selecionado == 0 ? 'unselect' : 'select'">            
                  <div class="mct-calendar-left float-left" :class="typeof dia.feriado != 'undefined' && dia.feriado === 1 ? 'mct-calendar-fer' : dia.horaridia_diasem == 0 ? 'mct-calendar-dom' : ''">
                    <a href="#" @click.prevent.stop="onSelectCal(index)" title="Clique para Selecionar ou Deselecionar o Dia">
                      <span class="mct-calendar-mes">{{moment(dia.horaridia_data).locale("pt-br").format('MMM').toUpperCase()}}</span>
                      <span class="mct-calendar-dia">{{moment(dia.horaridia_data).locale("pt-br").format('DD')}}</span>
                      <span class="mct-calendar-diasem">{{moment(dia.horaridia_data).locale("pt-br").format('ddd').toUpperCase()}}</span>
                    </a>
                  </div>
                    <a href="#" tabindex="-1" title="Ver Horários Livres" @click.prevent.stop="verHorarios(dia,index)">
                  <div class="mct-calendar-right float-right">
                      <div class="form-group m-0 p-0">
                        <label class="m-0 p-0" for="cal-hora">Horários Sel.:</label>  
                        <input class="form-control m-0 p-0" type="text" name="cal-hora" v-mask="'##:##'" v-model="dia.horaridiadisp_hora" :disabled="dia.selecionado == 0" :title="dia.selecionado == 1 ? 'Clique para Alterar a Hora' : ''">
                      </div>
                  </div>
                    </a>
                </li>
              </ul>
            </div> 
          </fieldset>-->            
        </div> 
      </div>
    </div>              
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctModal from '@/components/comum/MctModalSHB.vue'

export default {
  Name: 'AgendamentosT',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens,
      MctModal
  },
  data () {
      return {
        lista: {},
        errors: {},
        aulas: {},
        message: '',
        status: 0,
      }
  },
  methods: {
    setData (lista, errors, lastCode) {
        this.lista = lista
        this.errors = errors
        if(this.modo =='novo'){
            this.lista.turm_ano = this.Exercicio
            this.lista.turm_ent = this.Entidade
            this.lista.turm_esc = this.Escola
            this.lista.turm_situacao = 1        
        }else{

        }        
    }
  },
   computed: {
    ...mapState('Login',['infoLogado','Exercicio', 'Escola', 'Entidade']),
    diasEmBranco () {
      let diasem = 0
      if (typeof this.diasCalendario !== 'undefined' && typeof this.diasCalendario !== 'undefined' && typeof this.diasCalendario[0] !== 'undefined'){
        diasem = this.diasCalendario[0].horaridia_diasem
      }
      let ret = []
      while (diasem > 0){
        ret.push({diasem: diasem})    
        diasem--
      }
      return ret
    },
    diasCalendario () {      
      let ret = []
      let data = this.lista.turm_data_criacao
      while (moment(data) <= moment(this.lista.turm_data_fechamento)){
        ret.push(
          {
            horaridia_data: data,
            horaridia_diasem: moment(data).locale("pt-br").format('d'),
            selecionado: 0,
            feriado: 0
          }
        )    
        data = moment(data).add(1, 'days')
      }
      return ret
    }, 
  },  
  created() {
  
  }     
}
</script>