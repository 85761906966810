<template>
  <mct-padrao :titulo="titulo" :modo="modo" botoes="false">
    <div slot="formulario">
      <div class="row">
        <div class="col-lg-12">
          <mct-form-itens coluna="0" tipo="select" campo="cur_codigo" :valor="lista" titulo="Curso" obrigatorio="true" :errors="errors" 
            origem="esc_cursos" origemChave="cur_codigo" origemRetorno='cur_nome' origemChaveComposta="true" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <mct-form-itens coluna="0" tipo="select" campo="cmod_codigo" :valor="lista" titulo="Módulo" obrigatorio="true" :errors="errors" 
            origem="esc_cursos_modulos" origemChave="cmod_codigo" origemRetorno='cmod_descricao' origemChaveComposta="true" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-lg-6">
          <mct-form-itens coluna="0" tipo="select" campo="turn_codigo" :valor="lista" titulo="Turno" obrigatorio="true" :errors="errors" 
            origem="esc_turno" origemChave="turn_codigo" origemRetorno='turn_descricao' origemChaveComposta="true" :modo="modo"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="select" campo="turm_existente" :valor="lista" titulo="Seleção de Turma" obrigatorio="true" :errors="errors" 
            :origemData="[{value: 0, text: 'NOVA'},{value: 1, text: 'EXISTENTE'}]" :modo="modo"></mct-form-itens>
        </div>
        <div class="col-lg-2" v-show="lista.turm_existente == 1">
          <mct-form-itens coluna="0" tipo="select" campo="turm_codigo" :valor="lista" titulo="Turma" obrigatorio="true" :errors="errors" 
            origem="esc_turmas" origemChave="turm_codigo" origemRetorno='turm_identificacao' origemChaveComposta="true" :modo="modo" @setDataLista="setTurma"></mct-form-itens>
        </div>
        <div class="col-lg-2" v-show="lista.turm_existente == 0">
          <mct-form-itens coluna="0" tipo="number" campo="turm_sequencia" :valor="lista" titulo="Turma Identificação" obrigatorio="true" :errors="errors" :modo="lista.turm_existente == 0 ? modo : 'readonly'"></mct-form-itens>    
        </div>
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="date" campo="turm_data_criacao" :valor="lista" titulo="Data Início" :errors="errors" :modo="lista.turm_existente == 0 ? modo : 'readonly'" ></mct-form-itens>
        </div>
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="date" campo="turm_data_fechamento" :valor="lista" titulo="Data Fim" :errors="errors" :modo="lista.turm_existente == 0 ? modo : 'readonly'" ></mct-form-itens>
        </div>
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="number" campo="turm_numero_alunos" :valor="lista" titulo="Nº de Alunos" :errors="errors" :modo="lista.turm_existente == 0 ? modo : 'readonly'" ></mct-form-itens>
        </div>
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="number" campo="turm_maximo_alunos" :valor="lista" titulo="Nº Máximo de Alunos" :errors="errors" :modo="lista.turm_existente == 0 ? modo : 'readonly'" ></mct-form-itens>
        </div>
      </div>
      <div class="row">
          <div class="col-lg-6">
              <mct-form-itens coluna="0" tipo="textarea" campo="turm_observacao" :valor="lista" titulo="Observações" obrigatorio="true" :errors="errors" :modo="lista.turm_existente == 0 ? modo : 'readonly'"></mct-form-itens>    
          </div>
      </div>

      <fieldset class="well">
        <legend class="well-legend">Seleção de Dias (Etapas)</legend> 
        <div class="d-flex flex-wrap justify-content-center mct-calendar-status">
            <div>
                <span>Qtd. Dias:</span>
                <h1 class="mct-bg-red">{{lista.turm_totaldias}}</h1>
            </div>
            <div>
                <span>Qtd. Dias Selecionados:</span>
                <h1 class="mct-bg-green">{{lista.turm_totalselecionados}}</h1>
            </div>
            <div>
                <span>Carga Horária(hs):</span>
                <h1 class="mct-bg-green">{{lista.turm_totalhoras}}</h1>
            </div>
        </div>

        <ul class="mct-calendar d-flex flex-wrap justify-content-center">
          <li v-for="(dia,index) in onGeraDiasBranco()" :key="index">

          </li>
          <li v-for="(dia,index) in lista.turm_dias" :key="index" :class="dia.selecionado == 0 ? 'unselect' : 'select'">            
            <div class="mct-calendar-left float-left" :class="dia.turm_dias_diasem == 0 ? 'mct-calendar-dom' : ''">
              <a href="#" @click.prevent.stop="onSelectCal(index)" title="Clique para Selecionar ou Deselecionar o Dia">
                <span class="mct-calendar-mes">{{moment(dia.turm_dias_data).locale("pt-br").format('MMM').toUpperCase()}}</span>
                <span class="mct-calendar-dia">{{moment(dia.turm_dias_data).locale("pt-br").format('DD')}}</span>
                <span class="mct-calendar-diasem">{{moment(dia.turm_dias_data).locale("pt-br").format('ddd').toUpperCase()}}</span>
              </a>
            </div>
            <div class="mct-calendar-right float-right">
              <div class="form-group m-0 p-0">
                <label class="m-0 p-0" for="cal-hora">Hora(h):</label>  
                <input class="form-control m-0 p-0" type="text" name="cal-hora" v-mask="'##:##'" v-model="dia.turm_dias_horario" :disabled="dia.selecionado == 0" :title="dia.selecionado == 1 ? 'Clique para Alterar a Hora' : ''">
              </div>
              <!--<div class="form-group m-0 p-0">
                <label class="m-0 p-0" for="cal-hora">Duração(min):</label>  
                <input class="form-control m-0 p-0" type="text" name="cal-hora" v-mask="['###:##', '##:##']" v-model="dia.turm_dias_duracao" :disabled="dia.selecionado == 0" :title="dia.selecionado == 1 ? 'Clique para Alterar a Duração' : ''">
              </div>-->
              <a href="#" tabindex="-1" title="Ver Horários Livres"><span>Horários Livres</span></a>
              <!--<mct-form-itens tipo="text" campo="turm_dias_horario" :valor="dia" obrigatorio="false" :errors="errors" :modo="modo" mascara="##:##"></mct-form-itens>-->
            </div>
          </li>
        </ul>

        <!--<div class="mct-grid">
            <div class="mct-grid-scroll-sec">                        
                <table class="table table-sm table-striped table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 50px;">Seq.</th>
                            <th scope="col" style="width: 50px;">Sel.</th>                            
                            <th scope="col" style="width: 100px;" class="text-right">Dia</th>
                            <th scope="col" style="width: 100px;" class="text-right">Hora (hh:mm)</th>
                            <th scope="col" style="width: 100px;" class="text-right">Duração (min)</th>
                        </tr>
                    </thead>
                    <tbody>                                
                        <tr v-for="(dia,index) in lista.turm_dias" :key="index">
                            <td>{{dia.turm_dias_seq}}</td>
                            <td>
                              <mct-form-itens tipo="checkbox" campo="selecionado" :valor="dia" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>  
                            </td>

                            <td class="text-right">{{dia.turm_dias_data | data}}</td>
                            <td v-if="(modo != 'novo' && modo != 'editar') || dia.selecionado == 0">{{dia.turm_dias_horario}}</td>
                            <td v-else><mct-form-itens tipo="text" campo="turm_dias_horario" :valor="dia" obrigatorio="false" :errors="errors" :modo="modo" mascara="##:##"></mct-form-itens></td>                            
                            <td v-if="(modo != 'novo' && modo != 'editar') || dia.selecionado == 0">{{dia.turm_dias_duracao}}</td>
                            <td v-else><mct-form-itens tipo="text" campo="turm_dias_duracao" :valor="dia" obrigatorio="false" :errors="errors" :modo="modo" :mascara="['###:##', '##:##']"></mct-form-itens></td>                            
                        </tr>    
                    </tbody>
                </table>
            </div>
        </div>-->
      </fieldset>

      <div style="margin-bottom: 5px;">
          <div class="h-divider clear"></div>
          <button type="button" class="btn btn-success" aria-label="Left Align"  @click.prevent.stop="onSubmit($event)" v-if="modo !== 'deletar' && modo !== 'visualizar' && modo !== 'readonly'">
                  <span class="fa fa-check fa-lg" aria-hidden="true"></span> Gravar Agendamento </button>
          <button type="button" class="btn btn-danger" aria-label="Left Align" @click.prevent.stop="onCancelar($event)" v-if="modo === 'deletar'">
                  <span class="fa fa-trash-o fa-lg" aria-hidden="true"></span> Cancelar Agendamento </button>
          <button type="button" class="btn btn-dark float-right" aria-label="Left Align" @click.prevent.stop="$router.back()">
                  <span class="fa fa-times fa-lg" aria-hidden="true"></span> Fechar </button>
      </div>                 
    </div> 
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import MctPadrao from '@/components/comum/MctFormPadraoSCB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'

export default {
  Name: 'Agendamentos',  
  props: ['source','titulo','modo'],
  components: {
      MctPadrao,
      MctFormItens
  },
  data () {
      return {
        lista: {
            ent_codigo: null,            
            esc_codigo: null,
            ano_codigo: null,
            cur_codigo: null,
            cmod_codigo: null,
            turm_existente: 0,
            turm_codigo: null,
            turm_sequencia: null,            
            turm_data_criacao: null,
            turm_data_fechamento: null,
            turm_numero_alunos: null,
            turm_maximo_alunos: null,
            turn_codigo: null,
            turn_duracao_aula: null,
            crit_codigo: null,
            turm_totaldias: 0,
            turm_totalselecionados: 0,
            turm_totalhoras: 0.00,
            turm_dias: [],
            turm_status: 0,
            turm_observacao: ''
        },
        errors:{},
        message: '',
        status: 0,
        turma: [],
        turmaSelecionada: {}
      }
  },
  methods: {
    onGeraDiasBranco () {
      let diasem = 0
      if (typeof this.lista !== 'undefined' && typeof this.lista.turm_dias !== 'undefined' && typeof this.lista.turm_dias[0] !== 'undefined'){
        diasem = this.lista.turm_dias[0].turm_dias_diasem
      }
      let ret = []
      while (diasem > 0){
        ret.push({diasem: diasem})    
        diasem--
      }
      return ret
    }, 
    setData () {
      this.lista.ent_codigo = this.Entidade            
      this.lista.esc_codigo = this.Escola
      this.lista.ano_codigo = this.Exercicio                  
    },
    onGeraDias () {
      if (typeof this.lista.turm_data_criacao != 'undefined' && this.lista.turm_data_criacao != null 
          && typeof this.lista.turm_data_fechamento != 'undefined' && this.lista.turm_data_fechamento != null){            
            let dias = moment(this.lista.turm_data_fechamento).diff(this.lista.turm_data_criacao,'days')
            dias++
            this.lista.turm_totaldias = dias
            this.turm_totalselecionados = 0
            this.lista.turm_dias = []
            let dataBase = this.lista.turm_data_criacao
            for (var i = 0; i < dias; i++){
                
                var data = moment(dataBase).add(i, 'days')
                this.lista.turm_dias.push({
                  selecionado: 0,
                  turm_dias_seq: i+1, 
                  turm_dias_data: data, 
                  turm_dias_horario: '00:00', 
                  turm_dias_duracao: '00:00',
                  turm_dias_diasem: moment(data).locale("pt-br").format('d')
                })

            }           
        }        
    },
    onSubmit (e) {
      this.$swal({
        title: 'Deseja realmente gravar o agendamento?',
        text: "Este agendamento poderá apenas ser cancelado posteriormente!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.onGravar()   
            }                     
        })
    },
    async onGravar (e) {
      
      var dados = this.lista
      await axios.post(`${this.source}`,dados)
          .then(response => {
              this.message = response.data.message
              this.status = response.status
              this.lista = response.data.data
              this.errors = {}
              this.setData()
              
              this.$swal('Sucesso!',
              'Registro incluído com sucesso!',
              'success');  
          this.mode = 'readonly'                                          
          })
          .catch(response => {
              this.message = response.response.data.message
              if (response.response.data.error){
                  this.errors = response.response.data.error.errorInfo[2]              
              }else{
                  this.errors = response.response.data.errors
              }
              this.status = response.response.status
              this.setData()
              
          })                     
    },
    onCancelar (e) {
      this.$swal({
        title: 'Deseja realmente deletar o registro?',
        text: "Este evento não podera ser revertido!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.onDeletar()   
            }                     
        })
    },
    setTurma (data) {
      this.turma = data
    },
    onSelectCal (index){
      if (this.lista.turm_dias[index].selecionado == 0) {
        this.lista.turm_dias[index].selecionado = 1
        this.lista.turm_totalselecionados++
      } else {
        this.lista.turm_dias[index].selecionado = 0
        this.lista.turm_totalselecionados--
        this.lista.turm_dias[index].turm_dias_horario = '00:00'
        this.lista.turm_dias[index].turm_dias_duracao = '00:00'
      }
    }
  },
   computed: {
    ...mapState('Login',['infoLogado','Entidade', 'Exercicio', 'Escola']), 
  },  
  created() {
    this.setData()  
  },
  watch: {
    'lista.turm_data_criacao': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null) {
            this.onGeraDias()
        }
    },
    'lista.turm_data_fechamento': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null) {
            this.onGeraDias()
        }
    },
    'lista.turm_codigo': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
            var ret = this.turma.filter(function(el) {
                return el.turm_codigo == newVal;
            })

            this.turmaSelecionada = ret[0]
            this.lista.turm_data_criacao = this.turmaSelecionada.turm_data_criacao
            this.lista.turm_data_fechamento = this.turmaSelecionada.turm_data_fechamento
            this.lista.turm_numero_alunos = this.turmaSelecionada.turm_numero_alunos
            this.lista.turm_maximo_alunos = this.turmaSelecionada.turm_maximo_alunos           
        }
    },
    /*'lista.turm_dias.turm_dias_duracao': function (newVal, oldVal){
      if (newVal != oldVal){
        this.lista.turm_totalhoras -= Number(oldVal.replace(':','.')) 
        this.lista.turm_totalhoras -= Number(newVal.replace(':','.')) 
      }        
    },*/
    
     'lista.turm_dias': {
      handler: function (newVal, oldVal) {
        let somaHors = 0.00
        let somaMins = 0.00
        let somaSegs = 0.00
        for (var i = 0; i < newVal.length; i++){
          var vlrMin = Number(newVal[i]['turm_dias_duracao'].replace(':','.'))
          somaMins += Math.trunc(vlrMin)
          somaSegs += vlrMin - Math.trunc(vlrMin)
        }
        while (somaSegs >= 60){
          somaMins += 1
          somaSegs -= 60     
        }
        while (somaMins >= 60){
          somaHors += 1
          somaMins -= 60     
        }
        this.lista.turm_totalhoras = ("00" + somaHors).slice(-2)+':'+("00" + somaMins).slice(-2)+':'+("00" + somaSegs).slice(-2)
      },
      deep: true
    },
  }     
}
</script>