<template>
  <mct-padrao :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <div class="row">
        <div class="col-lg-2">
          <mct-form-itens coluna="0" tipo="text" campo="curr_codigo" :valor="lista" titulo="Código" :errors="errors" modo="readonly"></mct-form-itens>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <fieldset class="well">
            <legend class="well-legend">Curso / Ano / Turno</legend> 
            <div class="row">
              <div class="col-lg-10">
                <mct-form-itens coluna="0" tamanho="1" tipo="search" campo="cur_codigo" :valor="lista" titulo="Curso" :errors="errors" obrigatorio="true" origem="esc_cursos" origemChave="cur_codigo" origemRetorno='cur_nome' origemChaveComposta="true" @setDataRetorno="setCurso" :modo="modo"></mct-form-itens>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-10">
                <mct-form-itens coluna="0" tipo="select" campo="ser_codigo" :valor="lista" :titulo="infoEscola.esc_tipo === 1 ? 'Ano/Série' : 'Ano/Módulo'" :errors="errors" obrigatorio="false" origem="esc_serie" origemChave="ser_codigo" origemRetorno='ser_descricao' origemRetornoSec='ser_serie' 
                  :origemFiltro="{cur_codigo: lista.cur_codigo}" origemChaveComposta="true" :modo="modo"></mct-form-itens>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-7">
                <mct-form-itens coluna="0" tipo="select" campo="turn_codigo" :valor="lista" titulo="Turno" :errors="errors" obrigatorio="false" 
                  origem="esc_turno" origemChave="turn_codigo" origemRetorno='turn_descricao' origemChaveComposta="true" @setDataLista="setTurno" :modo="modo" ></mct-form-itens>
              </div>
              <div class="col-lg-4">
                <mct-form-itens coluna="0" tipo="checkbox" campo="curr_agrupa_porarea" :valor="lista" subtitulo="Agrupar por Área de Conhecimento" titulo=" " :errors="errors" :modo="modo" ></mct-form-itens>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <fieldset class="well">
            <button type="button" class="btn btn-success btn-sm mb-2" aria-label="Left Align" @click.prevent.stop="incluirDisciplina()">
                <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Incluir Novo </button>                     
            <div class="mct-grid">
                <div class="mct-grid-scroll-sec">         
                    <table class="table table-striped table-bordered table-sm table-hover" @setDisciplinas="setDisciplinas">
                      <thead>
                        <tr>
                          <th>Ações</th>
                          <th>
                            <span>DISCIPLINA</span>
                          </th>
                          <th>
                            <span>NOME DISCIPLINA</span>
                          </th>
                          <th>
                            <span>QTDE AULAS</span>
                          </th>
                          <th>
                            <span>AULAS EXTRAS</span>
                          </th>
                          <th>
                            <span>DURAÇÃO AULA</span>
                          </th>
                          <th>
                            <span>TOTAL AULAS</span>
                          </th>                                
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(disciplina,index) in lista.disciplinas" :key="index" :class="[{editing: disciplina == linhaEditada},{'is-excluido': disciplina.status == 'E'}]" v-cloak v-on:dblclick="editDisciplina(disciplina,index)"  v-on:click="setChSemanal()"> 
                          <td>
                            <div class="btn-group">
                              <i class="fa fa-pencil text-white mct-fa bg-primary mr-1" aria-hidden="true" @click="editDisciplina(disciplina,index)"></i>
                              <i class="fa fa-times text-white mct-fa bg-danger mr-1" aria-hidden="true" @click="excluirDisciplina(index)"></i>
                            </div>
                          </td>
                          <td> 
                            <div class="view" readonly style="width: 80px;">
                              {{disciplina.currdis_disp_codigo}}
                            </div>
                            <div class="edit" style="width: 80px;">
                              <mct-form-itens tipo="search-input" campo="currdis_disp_codigo" :valor="lista.disciplinas[index]"  origem="esc_disciplina" origemChave="disp_codigo" origemRetorno='disp_nome'  @setDataRetorno="setDisciplina"  :errors="errors" :modo="modo"></mct-form-itens>
                            </div>
                          </td>
                          <td>
                            <div class="view" style="width: 300px;">
                              {{disciplina.disp_nome}}
                            </div>
                            <div class="edit" style="width: 300px;">
                              {{disciplina.disp_nome}}
                            </div>
                          </td>
                          <td>
                            <div class="view" style="width:80px;">
                              {{disciplina.currdis_aulas_qtd}}
                            </div>
                            <div class="edit" style="width:80px;">
                              <mct-form-itens tipo="number" campo="currdis_aulas_qtd" :valor="disciplina" :errors="errors" obrigatorio="true" :modo="modo"></mct-form-itens>
                            </div>
                          </td>
                          <td>
                            <div class="view" style="width: 80px;">
                              {{disciplina.currdis_aulas_extras}}
                            </div>
                            <div class="edit" style="width: 80px;">
                              <mct-form-itens tipo="number" campo="currdis_aulas_extras" :valor="disciplina" :errors="errors" obrigatorio="true" :modo="modo"></mct-form-itens>
                            </div>
                          </td>
                          <td>
                            <div class="view" style="width: 80px;">
                              {{disciplina.currdis_aulas_duracao}}
                            </div>
                            <div class="edit" style="width: 80px;">
                              <mct-form-itens tipo="number" campo="currdis_aulas_duracao" :valor="disciplina" :errors="errors" modo="readonly"></mct-form-itens>
                            </div>
                          </td>
                          <td>
                            <div class="view" style="width: 80px;">
                              {{disciplina.currdis_aulas_total = Number(disciplina.currdis_aulas_qtd) + Number(disciplina.currdis_aulas_extras)  }}
                            </div>
                            <div class="edit" style="width: 80px;">
                              <mct-form-itens tipo="number" campo="currdis_aulas_total" :valor="disciplina" :errors="errors" modo="readonly"></mct-form-itens>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
            </div>
          </fieldset> 
        </div>
      </div>
      <div class="row d-flex flex-row justify-content-center">
        <div class="col-lg-2">
          <mct-form-itens tipo="number" campo="curr_dias_letivos" :valor="lista" titulo="Dias Letivos" :errors="errors" modo="readonly"></mct-form-itens>    
        </div>
        <div class="col-lg-2">
          <mct-form-itens tipo="number" campo="curr_semanas_letivas" :valor="lista" titulo="Semanas Letivas" :errors="errors" modo="readonly"></mct-form-itens>    
        </div>
        <div class="col-lg-2">
          <mct-form-itens tipo="number" campo="curr_ch_semanal" :valor="lista" titulo="CH Semanal (hrs,mins)" :errors="errors" modo="readonly"></mct-form-itens>    
        </div>
        <div class="col-lg-2">
          <mct-form-itens tipo="number" campo="curr_ch_anual" :valor="lista" titulo="CH Anual (hrs,mins)" :errors="errors" modo="readonly"></mct-form-itens>    
        </div>
      </div>
    </div>              
  </mct-padrao>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState, mapActions } from 'vuex'

import MctPadrao from '@/components/comum/MctFormPadrao.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctModal from '@/components/comum/MctModalSHB.vue'

export default {
  Name: 'Curriculo',  
  props: ['source','titulo','modo'],
  components: {
    MctPadrao,
    MctFormItens,
    MctModal,
  },
  data () {
    return {
      lista: {},
      curso: {},
      turno: {},
      ch_semanal: 0,
      ch_semanal_mins: 0,
      semanas_letivas: 0,
      errors: {},
      disciplinas: {},
      message: '',
      status: 0,
      linhaEditada: null,
      duracao_aula: 0,
      turnos: []
    }
  },
  methods: {
    setData (lista, errors, lastCode) {
      this.lista = lista
      this.errors = errors        
      if(this.modo =='novo'){
        this.lista.curr_ano = this.Exercicio
        this.lista.curr_ent = this.Entidade
        this.lista.curr_esc = this.Escola
        if(this.lista.disciplinas !== null)
          {
            this.lista.disciplinas=[]
          }
      }
        this.setDiasLetivos()
        this.setChSemanal()
        this.setChAnual()
    },
    setDiasLetivos(){
      let dias_letivos
      axios.get('/escanoletivo'+'/editar?ano_codigo='+this.Exercicio+'&ano_esc='+this.Escola+'&ano_ent='+this.Entidade+'&mct_entidade='+this.Entidade+'&mct_ano_letivo='+this.Exercicio+'&mct_escola='+this.Escola)
      .then(response => {
          this.lista.curr_dias_letivos = response.data.data['ano_dias_letivos'];
          dias_letivos = response.data.data['ano_dias_letivos'];
          this.setSemanasLetivas(dias_letivos)
          this.setChAnual()
      })
      .catch(response => {
        if (response.response.status == 404) {
          this.retornoDescricao = 'REGISTRO NÃO LOCALIZADO'
        }
      })
    },
    setSemanasLetivas(dias_letivos){
      this.lista.curr_semanas_letivas = parseInt(dias_letivos)/5
      this.semanas_letivas = parseInt(dias_letivos)/5
    },
    setDisciplinas (data) {
      if (this.modo == 'novo' || this.modo == 'editar'){
        this.disciplinas = data 
      }
    },
    setCurso (data) {
      this.curso = data
    },
    setTurno(data){
      this.turnos = data
    },
    async incluirDisciplina() {
      await this.setDuracaoAula()

      if(this.lista.disciplinas && this.lista.turn_codigo){
          if (typeof this.lista.disciplinas === 'undefined'){
            this.lista.disciplinas.push( {currdis_disp_codigo: '', curr_codigo: '', currdis_aulas_qtd: 0, currdis_aulas_extras: 0, currdis_aulas_duracao: this.duracao_aula, currdis_aulas_total: 0, status: 'N'} ) 
            this.linhaEditada = this.lista.disciplinas[0]
            this.linhaEditada['id'] = 0 
          }else
          if (this.lista.disciplinas[0] && typeof this.lista.disciplinas[0].curr_codigo === 'undefined'){
            this.lista.disciplinas[0] = {currdis_disp_codigo: '', curr_codigo: '', currdis_aulas_qtd: 0, currdis_aulas_extras: 0, currdis_aulas_duracao: this.duracao_aula, currdis_aulas_total: 0, status: 'N'}
            this.linhaEditada = this.lista.disciplinas[0]
            this.linhaEditada['id'] = 0             
          }else{
            this.lista.disciplinas.push( {currdis_disp_codigo: '', curr_codigo: '', currdis_aulas_qtd: 0, currdis_aulas_extras: 0, currdis_aulas_duracao: this.duracao_aula, currdis_aulas_total: 0, status: 'N'} ) 
            this.linhaEditada = this.lista.disciplinas[this.lista.disciplinas.length-1]
            this.linhaEditada['id'] = this.lista.disciplinas.length-1 
          }  
      } else if(this.lista.turn_codigo == null){
        this.$swal({
        title: 'É necessário selecionar um Turno!',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
        })
        //this.$set(this.lista,'disciplinas',[])
      }
        
    },
    excluirDisciplina(linha) {
      this.linhaEditada = null   
      this.lista.disciplinas[linha].status = 'E'
      //todos.splice(index, 1) 
       this.setChSemanal()
    },
    editDisciplina(linha,id) {
      if (this.linhaEditada === linha){
        this.linhaEditada = null    
      }else{
        this.linhaEditada = linha
        this.linhaEditada['id'] = id 
      }
    },
    setDisciplina(data,linhaEditada)
    {
      this.disciplina=data
      let index = this.linhaEditada ? this.linhaEditada.id : ''
      if (typeof data != 'undefined' && this.linhaEditada != null){
        if (data == 404 || data == '404'){
          this.lista.disciplinas[index].disp_codigo = ''
          this.lista.disciplinas[index].disp_nome = ''
        }else{                
          this.lista.disciplinas[index].disp_codigo = data.disp_codigo
          this.lista.disciplinas[index].disp_nome = data.disp_nome
        }
      }
    },
    setChSemanal(){
      this.ch_semanal = 0
      this.ch_semanal_mins = 0
      if(this.lista.disciplinas){
        this.lista.disciplinas.forEach(element => {
          if(element['status']){
            if(element['status'] !== 'E'){
              this.ch_semanal += parseInt(element['currdis_aulas_total'])
              this.ch_semanal_mins += parseInt(element['currdis_aulas_total'])*Number(element['currdis_aulas_duracao'])
            }
          }else{
            this.ch_semanal += parseInt(element['currdis_aulas_total'])
            this.ch_semanal_mins += parseInt(element['currdis_aulas_total'])*Number(element['currdis_aulas_duracao'])
          }
        
      });
      this.setChAnual()
      }
       
    },
    setChAnual(){
      this.lista.curr_ch_semanal = Number(Number(this.ch_semanal_mins)/60.00).toFixed(2)
      this.lista.curr_ch_anual = Number(Number(this.lista.curr_ch_semanal) * Number(this.lista.curr_semanas_letivas)).toFixed(2)
    },
    setDuracaoAula () {
      let ret = this.turnos.filter(el => {
          return el.turn_codigo == this.lista.turn_codigo;
      })
      console.log(ret, this.lista.turn_codigo,this.turnos)
      if (typeof ret[0] !== 'undefined'){
          this.duracao_aula = parseInt(ret[0].turn_duracao_aula)
      }else{
          this.duracao_aula = 0
      }
    }  
  },
   computed: {
    ...mapState('Login',['infoLogado','Exercicio', 'Escola', 'Entidade','infoEscola']), 
  },  
  created() {
  
  },
  watch:{
    'lista.disciplinas'(){
      if(this.lista.disciplinas){
        this.setChSemanal()
      }      
    },
    /*'lista.turn_codigo': function (newVal, oldVal ) {
      let ret = this.turnos.filter(el => {
          return el.turn_codigo == newVal;
      })
      console.log(ret, newVal,this.turnos)
      if (typeof ret[0] !== 'undefined'){
          this.duracao_aula = parseInt(ret[0].turn_duracao_aula)
      }else{
          this.duracao_aula = 0
      }
      
    }*/
  } 
}
</script>

<style scoped>
    [v-cloak] {
        display: none;
    }
    .edit {
        display: none;
    }
    .editing .edit {
        display: block
    }
    .editing .view {
        display: none;
    }  
      
    .is-icon{
        max-width: 65px;
    }

    .is-excluido{
        color: #C82333;
        background-color: #e39199!important; 
        text-decoration: line-through;
    }    
</style>