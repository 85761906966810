<template>
    <div class="mct-form container">
        <mct-topo-lista :titulo="titulo" :subtitulo="'Manutenção de '+ titulo + ' - ' + modo.toUpperCase()"></mct-topo-lista>    
        <mct-alertas :mensagem="message" :errors="errors" :status="status" :modo="modo" v-show="status !== 0"></mct-alertas>
        <div class="container-fluid position-relative">
            <i class="fa fa-info mct-infos-btn" aria-hidden="true" @click="onShowInfos()" title="Mostrar ou Ocultar Informações"></i>
            <mct-form>            
                <div class="row">
                    <div class="col-2">
                        <mct-form-itens coluna="0" tipo="number" campo="crec_codigo" :valor="lista" titulo="Código" obrigatorio="true" chave="true" :errors="errors" modo="readonly"></mct-form-itens>                      
                    </div>    
                    <div class="col-3">
                        <mct-form-itens coluna="0" tipo="text" campo="crec_numero" :valor="lista" titulo="Número" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                    </div>    
                    <div class="col-3">
                        <mct-form-itens tipo="date" campo="crec_emissao" :valor="lista" titulo="Emissão" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>
                    </div>
                    <div class="col-4">
                        <mct-form-itens tipo="select" campo="cpg_codigo" :valor="lista" titulo="Cond. Pgto." obrigatorio="true" origem="esc_condpag" 
                            origemChave="cpg_codigo" origemRetorno='cpg_nome' :errors="errors" :modo="modo" @setDataLista="setCondPgto"></mct-form-itens>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2">
                        <mct-form-itens coluna="0" tipo="currency" campo="crec_valor" :valor="lista" titulo="Valor" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>                      
                    </div>    
                    <div class="col-2">
                        <mct-form-itens coluna="0" tipo="currency" campo="crec_valor_quitado" :valor="lista" titulo="Valor Quitado" obrigatorio="false" :errors="errors" modo="readonly"></mct-form-itens>                      
                    </div>    
                    <div class="col-4">
                        <mct-form-itens tipo="select" campo="trec_codigo" :valor="lista" titulo="Tipo de Recebimento" obrigatorio="true" origem="esc_tp_recebimento" 
                            origemChave="trec_codigo" origemRetorno='trec_descricao' :errors="errors" :modo="modo"></mct-form-itens>
                    </div>
                    <div class="col-2">
                        <mct-form-itens coluna="0" tipo="number" campo="crec_per_multa" :valor="lista" titulo="Multa (%)" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>                      
                    </div>    
                    <div class="col-2">
                        <mct-form-itens coluna="0" tipo="number" campo="crec_per_juros" :valor="lista" titulo="Juros (% ao dia)" obrigatorio="false" :errors="errors" :modo="modo"></mct-form-itens>                      
                    </div>    
                </div>
                <div class="row">
                    <div class="col-12">
                        <mct-form-itens tipo="select" campo="pes_codigo" :valor="lista" titulo="Pessoa" obrigatorio="true" origem="esc_pessoas" 
                            origemChave="pes_codigo" origemRetorno='pes_nome' origemRetornoSec='pes_codigo' :errors="errors" :modo="modo"></mct-form-itens>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <mct-form-itens tipo="textarea" campo="crec_observacao" :valor="lista" titulo="Observações" obrigatorio="false" linhas="3" :errors="errors" :modo="modo"></mct-form-itens>    
                    </div>
                </div>


                <ul class="nav nav-tabs mt-2" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#parcela" role="tab">Parcelas</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#contabil" role="tab">Contábil</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#centrocustos" role="tab">Centro de Custos</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#quitacoes" role="tab">Histórico de Quitações</a>
                    </li>
                </ul>
                <!-- TABS -->
                <div class="tab-content">
                    <div class="tab-pane active" id="parcela" role="tabpanel">                
                        <div class="mb-2" style="width: 150px;" v-if="modo === 'novo'">
                            <mct-form-itens tipo="number" campo="qtdParcelas" :valor="$data" titulo="Alterar Qtd. Parcelas" obrigatorio="true" :errors="errors" :modo="modo" tamanho="12"></mct-form-itens>     
                        </div>               
                        <div class="mct-grid">
                            <div class="mct-grid-scroll-sec">                        
                                <table class="table table-sm table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width: 24px;" v-if="modo == 'editar' || modo == 'novo'">
                                                <div class="btn-group" role="group" aria-label="Ações">
                                                    <button type="button" class="btn btn-primary btn-sm" @click.prevent.stop="onMarcarTodos(1)" title="Selecionar Tudo"><i class="fa fa-check-square-o text-white"></i></button>
                                                    <button type="button" class="btn btn-danger btn-sm" @click.prevent.stop="onMarcarTodos(0)" title="Deselecionar Tudo"><i class="fa fa-square-o text-white"></i></button>
                                                </div>
                                            </th>
                                            <th scope="col" style="width: 34px;" v-if="modo == 'editar' || modo == 'novo'">
                                                <div class="btn-group" role="group" aria-label="Ações">
                                                    <button type="button" class="btn btn-danger btn-sm" @click.prevent.stop="onExcParcelasSel()" title="Excluir Parcela(s) Selecionada(s)"><i class="fa fa-times text-white"></i></button>
                                                    <button type="button" class="btn btn-danger btn-sm" @click.prevent.stop="onCancParcelasSel()" title="Cancelar Parcela(s) Selecionada(s)"><i class="fa fa-ban text-white"></i></button>
                                                </div>
                                            </th>
                                            <th scope="col" style="width: 60px;" class="text-center">Parcela</th>
                                            <th scope="col" style="width: 80px;">Número</th>
                                            <th scope="col" style="width: 80px" class="text-right">Vencimento</th>
                                            <th scope="col" style="width: 100px;" class="text-right">Valor</th>
                                            <th scope="col" class="text-left">Tp. Doc.</th>
                                            <th scope="col" style="width: 80px;" class="text-right">Mul+Jur Rec.</th>
                                            <th scope="col" style="width: 80px;" class="text-right">Desc. Conc.</th>
                                            <th scope="col" style="width: 100px;" class="text-right">Valor Pago</th>
                                            <th scope="col" style="width: 80px;" class="text-right">Dt. Últ. Pgto</th>
                                            <th scope="col" style="width: 60px;" class="text-right">Dias Atraso</th>
                                            <th scope="col" style="width: 60px;" class="text-right">Multa</th>
                                            <th scope="col" style="width: 60px;" class="text-right">Juros</th>
                                            <th scope="col" style="width: 100px;" class="text-right">Saldo a Pagar</th>                                        
                                        </tr>
                                    </thead>
                                    <tbody>                                
                                        <tr v-for="(parcela,index) in lista.parcelas" :key="index" 
                                            :style="parcela.crecp_valor_pago > 0 ? 'border-left: 8px solid #379A7B!important;': 'border-left: 8px solid #F86C6B!important;'"
                                            :class="parcela.crecp_cancelado === 1 ? 'mct-cancelado-tr' : ''">
                                            <th scope="row" v-if="modo == 'editar' || modo == 'novo'">
                                                <div style="max-width: 100px!important;">                                        
                                                    <mct-form-itens tipo="checkbox" campo="crecp_selecionado" :valor="parcela" obrigatorio="true" :errors="errors" :modo="modo" tamanho="12"></mct-form-itens>    
                                                </div>
                                            </th>
                                            <th scope="row" v-if="modo == 'editar' || modo == 'novo'">
                                                <div class="btn-group" role="group" aria-label="Ações" v-if="parcela.crecp_cancelado === 0">
                                                    <button type="button" class="btn btn-primary btn-sm" @click.prevent.stop="onEditParcela(index)" title="Editar Parcela"><i class="fa fa-pencil text-white"></i></button>
                                                    <button type="button" class="btn btn-danger btn-sm" @click.prevent.stop="onExcParcela(index)" title="Excluir Parcela"><i class="fa fa-times text-white"></i></button>
                                                    <button type="button" class="btn btn-danger btn-sm" @click.prevent.stop="onCancParcela(index)" title="Cancelar Parcela"><i class="fa fa-ban text-white"></i></button>
                                                </div>
                                                <span v-else-if="parcela.crecp_cancelado === 1" class="mct-cancelado">CANCELADO PELO USUÁRIO <strong>{{parcela.crecp_user_cancelado.toUpperCase()}}</strong> EM <strong>{{moment(parcela.crecp_data_cancelado).locale('pt-br').format('L')}}.</strong></span>
                                            </th>
                                            <td class="text-center">{{parcela.crecp_parcela}}</td>
                                            <td>{{parcela.crecp_numero}}</td>
                                            <td class="text-right">{{ parcela.crecp_vencimento | data }}</td>
                                            <td class="text-right">{{parcela.crecp_valor | moeda}}</td>
                                            <td class="text-left">{{getTpQuitacao(parcela.tpag_codigo)}}</td>
                                            <td class="text-right">{{ parcela && parcela.quitacao && parcela.quitacao.quita_vlr_multjurpag ? parcela.quitacao.quita_vlr_multjurpag : 0 | moeda }}</td>
                                            <td class="text-right">{{ parcela && parcela.quitacao && parcela.quitacao.quita_vlr_desconto ? parcela.quitacao.quita_vlr_desconto : 0 | moeda }}</td>
                                            <td class="text-right">{{ parcela && parcela.quitacao && parcela.quitacao.quita_valor ? parcela.quitacao.quita_valor : 0 | moeda }}</td>
                                            <td class="text-right">{{ parcela && parcela.quitacao && parcela.quitacao.quita_data !== null ? moment(parcela.quitacao.quita_data).locale("pt-br").format('L') : '' }}</td>
                                            <td class="text-right">{{parcela.crecp_diasatraso}}</td>
                                            <td class="text-right">{{parcela.crecp_vlrmulta | moeda}}</td>
                                            <td class="text-right">{{parcela.crecp_vlrjuros | moeda}}</td>
                                            <td class="text-right">{{parcela.crecp_sldpagar | moeda}}</td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div class="tab-pane" id="contabil" role="tabpanel">
                        <div class="row p-0 m-0 mb-2 mct-row-itens" v-if="modo == 'editar' || modo == 'novo'">
                            <div class="col-3 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plncd_codigo" :valor="plncontas" titulo="Débito" 
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" @setDataLista="setPlncd" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-3 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="plncc_codigo" :valor="plncontas" titulo="Crédito" 
                                    origem="esc_plncontas" origemChave="plnc_codigo" origemRetorno='plnc_numero' origemRetornoSec='plnc_descricao' 
                                    :origemFiltro="{plnc_status: 1, plnc_tipo_conta: 'A'}" @setDataLista="setPlncc" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-2 pl-0 pr-2">
                                <mct-form-itens tipo="currency" campo="crecplnc_valor" :valor="plncontas" titulo="Valor" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-3 pl-0 pr-2">
                                <mct-form-itens tipo="text" campo="crecplnc_complemento" :valor="plncontas" titulo="Complemento" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="is-icon mt-3">
                                <i class="fa fa-check text-white mct-fa bg-success" aria-hidden="true" @click="onAdcPlnContas()"></i>
                            </div>
                        </div>
                        <div class="mct-grid">
                            <div class="mct-grid-scroll-sec">                        
                                <table class="table table-sm table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width: 50px;" v-if="modo == 'editar' || modo == 'novo'"></th>
                                            <th scope="col" style="width: 250px;">Débito</th>
                                            <th scope="col" style="width: 250px;">Crédito</th>
                                            <th scope="col" style="width: 100px;" class="text-right">Valor</th>
                                            <th scope="col">Complemento</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                
                                        <tr v-for="(plnconta,index) in lista.plncontas" :key="index">
                                            <th scope="row" v-if="modo == 'editar' || modo == 'novo'">
                                                <div class="btn-group" role="group" aria-label="Ações">
                                                    <button type="button" class="btn btn-primary btn-sm" @click.prevent.stop="onEditPlnContas(index)" title="Editar Plano de Contas"><i class="fa fa-pencil text-white"></i></button>
                                                    <button type="button" class="btn btn-danger btn-sm" @click.prevent.stop="onExcPlnContas(index)" title="Excluir Plano de Contas"><i class="fa fa-times text-white"></i></button>
                                                </div>
                                            </th>
                                            <td><strong class="text-danger">{{plnconta.plncdcontas.plnc_numero}}</strong> {{plnconta.plncdcontas.plnc_descricao}}</td>
                                            <td><strong class="text-success">{{plnconta.plncccontas.plnc_numero}}</strong> {{plnconta.plncccontas.plnc_descricao}}</td>
                                            <td class="text-right">{{plnconta.crecplnc_valor | moeda}}</td>
                                            <td>{{plnconta.crecplnc_complemento}}</td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>                
                    <div class="tab-pane" id="centrocustos" role="tabpanel">
                        <div class="row p-0 m-0 mb-2 mct-row-itens" v-if="modo == 'editar' || modo == 'novo'">
                            <div class="col-3 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="ccst_codigo" :valor="ccustos" titulo="Centro de Custo" 
                                    origem="esc_centro_custo" origemChave="ccst_codigo" origemRetorno='ccst_descricao' 
                                    :origemFiltro="{ccst_status: 1}" @setDataLista="setCentroCusto" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-2 pl-0 pr-2">
                                <mct-form-itens tipo="currency" campo="crecc_valor" :valor="ccustos" titulo="Valor" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="is-icon mt-3">
                                <i class="fa fa-check text-white mct-fa bg-success" aria-hidden="true" @click="onAdcCentroCusto()"></i>
                            </div>
                        </div>
                        <div class="mct-grid">
                            <div class="mct-grid-scroll-sec">                        
                                <table class="table table-sm table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" style="width: 50px;" v-if="modo == 'editar' || modo == 'novo'"></th>
                                            <th scope="col">Centro de Custos</th>
                                            <th scope="col" style="width: 100px;" class="text-right">Valor</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                
                                        <tr v-for="(ccusto,index) in lista.ccustos" :key="index">
                                            <th scope="row" v-if="modo == 'editar' || modo == 'novo'">
                                                <div class="btn-group" role="group" aria-label="Ações">
                                                    <button type="button" class="btn btn-primary btn-sm" @click.prevent.stop="onEditCentroCusto(index)" title="Editar Centro de Custos"><i class="fa fa-pencil text-white"></i></button>
                                                    <button type="button" class="btn btn-danger btn-sm" @click.prevent.stop="onExcCentroCusto(index)" title="Excluir Centro de Custos"><i class="fa fa-times text-white"></i></button>
                                                </div>
                                            </th>
                                            <td>{{ccusto.ccusto.ccst_descricao}}</td>
                                            <td class="text-right">{{ccusto.crecc_valor | moeda}}</td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </div>
                        </div>                    
                    </div>
                    <div class="tab-pane" id="quitacoes" role="tabpanel">
                        <div class="row p-0 m-0 mb-2 mct-row-itens" v-if="modo == 'editar' || modo == 'novo'">
                            <div class="col-2 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="crecp_codigo" :valor="quitacoes" titulo="Parcela" 
                                    :origemData="lista.parcelas.filter((item) => item.crecp_cancelado === 0)" origemChave="crecp_codigo" origemRetorno='crecp_numero' 
                                    obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-2 pl-0 pr-2">
                                <mct-form-itens tipo="currency" campo="crecp_valor" :valor="quitacoes" titulo="Valor Parc." obrigatorio="true" :errors="errors" modo="readonly"></mct-form-itens>    
                            </div>
                            <div class="col-2 pl-0 pr-2">
                                <mct-form-itens tipo="date" campo="cbnclanc_data" :valor="quitacoes" titulo="Data Quitação" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-1 pl-0 pr-2">
                                <mct-form-itens tipo="currency" campo="cbnclanc_vlr_multa" :valor="quitacoes" titulo="Valor Multa" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-1 pl-0 pr-2">
                                <mct-form-itens tipo="currency" campo="cbnclanc_vlr_juros" :valor="quitacoes" titulo="Valor Juros" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-1 pl-0 pr-2">
                                <mct-form-itens tipo="currency" campo="cbnclanc_vlr_desconto" :valor="quitacoes" titulo="Valor Desc." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-1 pl-0 pr-2">
                                <mct-form-itens tipo="currency" campo="cbnclanc_vlr_tarifas" :valor="quitacoes" titulo="Valor Acrés." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-2 pl-0 pr-2">
                                <mct-form-itens tipo="currency" campo="cbnclanc_valor" :valor="quitacoes" titulo="Valor Pago" obrigatorio="true" :errors="errors" modo="readonly"></mct-form-itens>    
                            </div>
                            <div class="col-3 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="tqui_codigo" :valor="quitacoes" titulo="Tipo Quitação" 
                                    origem="esc_tp_quitacao" origemChave="tqui_codigo" origemRetorno='tqui_descricao' 
                                    :origemFiltro="{tqui_status: 1}" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-2 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="ccst_codigo" :valor="quitacoes" titulo="Centro Custo" 
                                    :origemData="lista.ccustos" origemChave="ccst_codigo" origemRetorno='ccst_codigo' 
                                    obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-2 pl-0 pr-2">
                                <mct-form-itens tipo="select" campo="tp_lancamento" :valor="quitacoes" titulo="Tipo Lançamento" :origemData="[{value: 'CAIXA', text: 'CAIXA'}, {value: 'BANCO', text: 'BANCO'}]" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-3 pl-0 pr-2" v-if="quitacoes.tp_lancamento == 'BANCO'">
                                <mct-form-itens tipo="select" campo="cbnc_codigo" :valor="quitacoes" titulo="Conta Bancária" 
                                    origem="esc_conta_bancaria" origemChave="cbnc_codigo" origemRetorno='cbnc_descricao' 
                                    :origemFiltro="{cbnc_status: 1, cbnc_ent: this.Entidade}" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>
                            <div class="col-3 pl-0 pr-2" v-else>
                                <mct-form-itens tipo="select" campo="cbnc_codigo" :valor="quitacoes" titulo="Conta Caixa" 
                                    origem="esc_caixa" origemChave="cxa_codigo" origemRetorno='cxa_descricao' 
                                    :origemFiltro="{cxa_status: 1, cxa_ent: this.Entidade}" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                            </div>

                            <div class="mt-3">
                                <button type="button" class="btn bg-success text-white" aria-label="Left Align" @click.prevent.stop="onAdcQuitacao()">
                                    <span class="fa fa-check text-white" aria-hidden="true"></span> Incluir Quitação </button>
                            </div>

                            <div class="row col-12 p-0 m-0 mt-2 mct-row-itens" v-if="tpQuitacaoSelecionada.tqui_descricao === 'CHEQUE'">
                                <div class="col-2 pl-0 pr-2">
                                    <mct-form-itens tipo="text" campo="docp_ch_numero" :valor="quitacoes" titulo="Número Ch." obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                                </div>                    
                                <div class="col-2 pl-0 pr-2">
                                    <mct-form-itens tipo="date" campo="docp_ch_vencimento" :valor="quitacoes" titulo="Vcto Cheque" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                                </div>     
                                <div class="col-3 pl-0 pr-2">
                                    <mct-form-itens tipo="select" campo="docp_ch_banco" :valor="quitacoes" titulo="Banco" 
                                        origem="banco" origemChave="bnc_codigo" origemRetorno='bnc_descricao' obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>  
                                </div>                    
                                <div class="col-1 pl-0 pr-2">
                                    <mct-form-itens tipo="text" campo="docp_ch_agencia" :valor="quitacoes" titulo="Agência" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                                </div>                    
                                <div class="col-2 pl-0 pr-2">
                                    <mct-form-itens tipo="text" campo="docp_ch_conta" :valor="quitacoes" titulo="Conta Corrente" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                                </div>                    
                        
                                <div class="col-2 pl-0 pr-2">
                                    <mct-form-itens tipo="currency" campo="docp_ch_valor" :valor="quitacoes" titulo="Valor Cheque" obrigatorio="true" :errors="errors" :modo="modo"></mct-form-itens>    
                                </div>
                            </div>                        
                        </div>                    

                        <div class="mct-grid">
                            <div class="mct-grid-scroll-sec">                        
                                <table class="table table-sm table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" v-if="modo == 'editar' || modo == 'novo'" style="width: 30px;"></th>
                                            <th scope="col" style="width: 60px;" class="text-center">Parcela</th>
                                            <th scope="col" style="width: 80px;" class="text-right">Número</th>
                                            <th scope="col" style="width: 100px;" class="text-right">Valor</th>
                                            <th scope="col" style="width: 100px;" class="text-right">Tarifas</th>
                                            <th scope="col" style="width: 80px;" class="text-right">Multa</th>
                                            <th scope="col" style="width: 80px;" class="text-right">Juros</th>
                                            <th scope="col" style="width: 80px;" class="text-right">Desconto</th>
                                            <th scope="col" style="width: 100px;" class="text-right">Valor Pago</th>
                                            <th scope="col" style="width: 80px;" class="text-right">Dt. Pgto</th>
                                            <th scope="col" class="text-left">Tp. Quitação</th>
                                            <th scope="col" style="width: 80px;" class="text-left">Situação</th>
                                            <th scope="col" style="width: 60px;" class="text-left">Tp. Lançamento</th>
                                        </tr>
                                    </thead>
                                    <tbody>                                
                                        <tr v-for="(quitacao,index) in lista.quitacoes" :key="index" :style="quitacao.cbnclanc_tipo == 2 ? 'border-left: 8px solid #379A7B!important;': 'border-left: 8px solid #F86C6B!important;'">
                                            <th scope="row" v-if="modo == 'editar' || modo == 'novo'">
                                                <div class="btn-group" role="group" aria-label="Ações" v-if="(quitacao.cbnclanc_codigo === null && quitacao.cbnclanc_cancelado === 0) || (quitacao.cbnclanc_codigo !== null && quitacao.cbnclanc_cancelado === 0 && infoLogado.perfil.per_siges_exc_parc_cnts_receber === 1)">
                                                    <button type="button" class="btn btn-danger btn-sm" @click.prevent.stop="onExcQuitacao(index)" title="Excluir Quitacao"><i class="fa fa-times text-white"></i></button>
                                                </div>
                                                <div class="btn-group" role="group" aria-label="Ações" v-if="quitacao.cbnclanc_codigo !== null && quitacao.cbnclanc_cancelado === 0 && infoLogado.perfil.per_siges_est_parc_cnts_receber === 1">
                                                    <button type="button" class="btn btn-danger btn-sm" @click.prevent.stop="onEstornaQuitacao(quitacao.tp_lancamento,quitacao.cbnclanc_codigo)" title="Cancelar/ Estonar Quitacao"><i class="fa fa-ban text-white"></i></button>
                                                </div>
                                                <span v-else-if="quitacao.cbnclanc_cancelado === 1" class="mct-cancelado">CANCELADO PELO USUÁRIO <strong>{{quitacao.cbnclanc_user_cancelado.toUpperCase()}}</strong> EM <strong>{{moment(quitacao.cbnclanc_data_cancelado).locale('pt-br').format('L')}}.</strong></span>
                                            </th>
                                            <td class="text-center">{{quitacao.crecp_parcela}}</td>
                                            <td class="text-right">{{quitacao.crecp_numero}}</td>
                                            <td class="text-right">{{quitacao.crecp_valor | moeda}}</td>
                                            <td class="text-right">{{quitacao.cbnclanc_vlr_tarifas | moeda}}</td>
                                            <td class="text-right">{{quitacao.cbnclanc_vlr_multa | moeda}}</td>
                                            <td class="text-right">{{quitacao.cbnclanc_vlr_juros | moeda}}</td>
                                            <td class="text-right">{{quitacao.cbnclanc_vlr_desconto | moeda}}</td>
                                            <td class="text-right">{{quitacao.cbnclanc_valor | moeda}}</td>
                                            <td class="text-right">{{quitacao.cbnclanc_data | data}}</td>
                                            <td class="text-left">{{quitacao.tqui_descricao}}</td>
                                            <td class="text-left"><span class="badge badge-danger" v-if="quitacao.cbnclanc_estorno > 0"><i class="fa fa-ban" aria-hidden="true"></i> estornado</span></td>
                                            <td class="text-left">
                                                <i class="fa fa-money text-success" aria-hidden="true" v-if="quitacao.tp_lancamento == 'CAIXA'"></i>
                                                <i class="fa fa-university text-info" aria-hidden="true" v-if="quitacao.tp_lancamento == 'BANCO'"></i> {{quitacao.tp_lancamento}}</td>
                                        </tr>    
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <b-modal id="parc-sel" title="Editar Parcela" ok-only cancel-title="Cancelar" ok-title="Salvar">
                    <div class="row">
                        <div class="col-4">
                            <mct-form-itens tipo="text" campo="crecp_numero" titulo="Número" :valor="parcelaSelecionada != null ? lista.parcelas[parcelaSelecionada] : {}" obrigatorio="true" :errors="errors" :modo="modo" tamanho="12"></mct-form-itens>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <mct-form-itens tipo="date" campo="crecp_vencimento" titulo="Vencimento" :valor="parcelaSelecionada != null ? lista.parcelas[parcelaSelecionada] : {}" obrigatorio="true" :errors="errors" :modo="modo" tamanho="12"></mct-form-itens>
                        </div>  
                        <div class="col-6">
                            <mct-form-itens tipo="currency" campo="crecp_valor" titulo="Valor" :valor="parcelaSelecionada != null ? lista.parcelas[parcelaSelecionada] : {}" obrigatorio="true" :errors="errors" :modo="modo" tamanho="12"></mct-form-itens>
                        </div>  
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <mct-form-itens tipo="select" campo="tpag_codigo" titulo="Tipo Documento" :valor="parcelaSelecionada != null ? lista.parcelas[parcelaSelecionada] : {}" obrigatorio="true" :errors="errors" origem="esc_tp_quitacao" origemChave="tqui_codigo" origemRetorno='tqui_descricao' :modo="modo" showBotoes="false"></mct-form-itens>
                        </div>
                    </div>
                </b-modal>
                <portal-target name="mct-infos" class="mct-infos">
                        
                </portal-target>
                <mct-infos :data="lista"/>            
            </mct-form>
        </div>
        <mct-form-botoes @submit="submit()" :modo="modo"></mct-form-botoes>
    </div>    
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState } from 'vuex'
import moment from 'moment'

import MctTopoLista from '@/components/comum/MctTopoLista.vue'
import MctForm from '@/components/comum/MctForm.vue'
import MctFormBotoes from '@/components/comum/MctFormBotoes.vue'
import MctAlertas from '@/components/comum/MctAlertas.vue' 
import MctMixinsForm from '@/components/funcoes/MctMixinsForm'

import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctInfos from "@/components/comum/MctInfos.vue"

export default {
  Name: 'EscReceber',
  mixins: [MctMixinsForm],
  props: ['source','titulo','modo'],
  components: {
        MctForm,
        MctTopoLista,
        MctFormBotoes,
        MctAlertas,
        MctFormItens,
        MctInfos
  },
  data () {
      return {
        condPgto: [],
        condPgtoSelecionado: {},
        qtdParcelas: 1,
        plncc: [],
        plncd: [],
        plnccSelecionado: {},
        plncdSelecionado: {},
        centrocusto: [],
        centrocustoSelecionado: {},
        plncontas: {
            crecplnc_codigo: null,
            plncc_codigo: null,
            plncd_codigo: null,
            crecplnc_valor: 0.00,
            crecplnc_complemento: null,
            plncccontas: {},
            plncdcontas: {}

        },
        ccustos: {
            crecc_codigo: null,
            ccst_codigo: null,
            crecc_valor: 0.00,
            ccusto: {}
        },
        quitacoes: {
            crecp_codigo: null,
            crecp_parcela: null,
            crecp_numero: null,
            crecp_valor: 0.00,
            crec_codigo: null,
            cbnclanc_codigo: null,
            cbnclanc_tipo: 2,/*1-DEB, 2-CRED */
            cbnclanc_data: new Date(),
            cbnclanc_data_credito: new Date(),
            cbnc_codigo: null,
            cbnc_ent: null,
            cbnclanc_vlr_multa: 0.00,
            cbnclanc_vlr_juros: 0.00,
            cbnclanc_vlr_desconto: 0.00,
            cbnclanc_vlr_tarifas: 0.00,
            cbnclanc_valor: 0.00,
            ccst_codigo: null,
            cbnclanc_estorno: 0,
            tp_lancamento: null,
            tqui_codigo: null,
            tqui_descricao: null,
            docp_ch_numero: null,
            docp_ch_vencimento: null,
            docp_ch_banco: null,
            docp_ch_agencia: null,
            docp_ch_conta: null,
            docp_ch_valor: null,
            pes_codigo: null,
            cbnclanc_cancelado: 0
        },
        tpQuitacao: [],
        tpQuitacaoSelecionada: {},    
        totalParcelasContabil:0,
        totalParcelasCCusto:0,
        parcelaSelecionada: null
      }
  },
  methods: {
    async onValida() {
        let msgs = ''
        let soma = 0
        if (this.lista.crec_numero == null || this.lista.crec_numero == ''){
            msgs += 'O campo Número tem um valor inválido; </br>';
        }
        if (this.lista.crec_emissao == null || this.lista.crec_emissao == 0){
            msgs += 'Digite uma Data de Emissão válida; </br>';
        }
        if (this.lista.cpg_codigo == null || this.lista.cpg_codigo == ''){
            msgs += 'Selecione uma Condição de Pagamento; </br>';
        }
        if (this.lista.crec_valor == null || this.lista.crec_valor == 0){
            msgs += 'O campo Valor não pode ser nulo ou zero; </br>';
        }
        if (this.lista.pes_codigo == null || this.lista.pes_codigo == ''){
            msgs += 'Selecione uma Pessoa; </br>';
        }
        if (this.lista.ccustos == 'undefined' || this.lista.ccustos.length == 0){
            msgs += 'É obrigatório a inserção de ao menos um registro no centro de custos; </br>';
        }
        if (this.lista.plncontas == 'undefined' || this.lista.plncontas.length == 0){
            msgs += 'É obrigatório a inserção de ao menos um registro no plano de contas; </br>';
        }
        if (this.lista.ccustos !== null && this.lista.ccustos !== 'undefined'){
            let soma = 0
            for (var index in this.lista.ccustos) {
                soma += Number(this.lista.ccustos[index].crecc_valor) 
            }            
            if (Number(soma).toFixed(2) !== Number(this.lista.crec_valor).toFixed(2)){
                msgs += 'O Total dos lançamentos no Centro de Custos R$ '+Number(soma).toFixed(2)+', não pode ser diferente do valor do Contas a Pagar R$ '+Number(this.lista.crec_valor).toFixed(2)+'; </br>';
            }
        }
        if (this.lista.plncontas !== null && this.lista.plncontas !== 'undefined'){
            soma = 0
            for (var index in this.lista.plncontas) {
                soma += Number(this.lista.plncontas[index].crecplnc_valor) 
            }            
            if (Number(soma).toFixed(2) !== Number(this.lista.crec_valor).toFixed(2)){
                msgs += 'O Total dos lançamentos no Plano de Contas R$ '+Number(soma).toFixed(2)+', não pode ser diferente do valor do Contas a Pagar R$ '+Number(this.lista.crec_valor).toFixed(2)+'; </br>';
            }
        }        
        if (this.lista.parcelas !== null && typeof this.lista.parcelas !== 'undefined'){
            soma = 0
            for (var index in this.lista.parcelas) {
                if (this.lista.parcelas[index].crecp_cancelado !== 1) {
                    soma += Number(this.lista.parcelas[index].crecp_valor)
                }
            }            
            if (Number(soma).toFixed(2) !== Number(this.lista.crec_valor).toFixed(2)){
                msgs += 'O Total das parcelas R$ '+Number(soma).toFixed(2)+' não pode ser diferente do valor do Contas a Receber R$ '+Number(this.lista.crec_valor).toFixed(2)+'; </br>';
            }
        }        
        
        if (msgs !== ''){
            this.isValidated = await false
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Incluir / Alterar o Registro',
                html/*text*/: msgs,
                /*footer: '<a href>Why do I have this issue?</a>'*/
                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
            })        
        }else{
            this.isValidated = await true
        }
    },
    setData () {
        if (this.modo == 'novo'){
            this.lista.crec_cancelado = 0 
            this.lista.crec_emissao = new Date()
            this.lista.audit_data_cadastro = this.moment(new Date()).format('YYYY-MM-DD')
            this.lista.audit_user_cadastro = this.infoLogado['usu_usuario'].toUpperCase()       
            this.lista.audit_local_cadastro = this.IP       
        } else if (this.modo == 'editar'){
            this.lista.audit_data_atualiza = this.moment(new Date()).format('YYYY-MM-DD')
            this.lista.audit_user_atualiza = this.infoLogado['usu_usuario'].toUpperCase()       
            this.lista.audit_local_atualiza = this.IP
        }        
    },
    async onBeforeDeletar(){
        if ((this.infoLogado.perfil.per_siges_exc_cnts_receber === 0) && (this.lista.quitacoes.length > 0) && (this.lista.cntr_codigo === null)){
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Excluir',
                text: 'O CONTAS A RECEBER não pode ser excluído poís o mesmo contém QUITAÇÕES. Exclua as QUITAÇÕES e tente novamente.',
                footer: `<small class="text-danger"><strong>O Usuário ${this.infoLogado.usu_usuario.toUpperCase()} não possui permissão para a exclusão de "CONTAS A RECEBER" com "PARCELAS QUITADAS(RECEBIDAS)", contate o Usuário Administrador para mais informações.</strong></small>`
            })
            return await false;
        } else if ((this.infoLogado.perfil.per_siges_exc_cnts_receber === 1) && (this.lista.quitacoes.length > 0)  && (this.lista.cntr_codigo === null)){
            return await this.$swal({
                title: 'Existem Parcelas Quitadas, deseja continuar com esta ação?',
                text: "As Quitações serão exluídas juntamente com as parcelas e o Contas a Receber, lembre-se este evento não podera ser revertido!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim'
                }).then((result) => {
                    return result.value;   
                })
        }
        return await true;
    },
    setCondPgto (data) {
        this.condPgto = data
    },
    getTpQuitacao(index){
        const ret = this.tpQuitacao.filter(el => el.tqui_codigo === index);
        //console.log(ret,index,ret[0].tqui_descricao);
        return ret && ret[0] ? ret[0].tqui_descricao : null;
    },
    /* PLANO DE CONTAS */
    setPlncc (data) {
        this.plncc = data
    },
    setPlncd (data) {
        this.plncd = data
    },
    onAdcPlnContas () {
        let msgs = ''
        if (this.plncontas.plncc_codigo == null || this.plncontas.plncc_codigo == ''){
            msgs += 'Selecione uma Conta para o Crédito; </br>';
        }
        if (this.plncontas.plncd_codigo == null || this.plncontas.plncd_codigo == ''){
            msgs += 'Selecione uma Conta para o Débito; </br>';
        }
        if (this.plncontas.crecplnc_valor == null || this.plncontas.crecplnc_valor == 0){
            msgs += 'Digite um Valor válido; </br>';
        }
        /*if (this.plncontas.crecplnc_valor !== null && this.plncontas.crecplnc_valor !== 0){
            let soma = Number(this.plncontas.crecplnc_valor)
            for (var index in this.lista.plncontas) {
                soma += Number(this.lista.plncontas[index].crecplnc_valor) 
            }            
            if (Number(soma).toFixed(2) > Number(this.lista.crec_valor).toFixed(2)){
                msgs += 'O Total dos itens não pode ser maior que o valor do Contas a Receber; </br>';
            }
        }*/
        if (this.plncontas.plncc_codigo !== null && this.plncontas.plncc_codigo !== '' && this.plncontas.plncd_codigo !== null && this.plncontas.plncd_codigo !== ''){
            let existe = 0
            for (var index in this.lista.plncontas) {
                if (this.lista.plncontas[index].plncc_codigo == this.plncontas.plncc_codigo && this.lista.plncontas[index].plncd_codigo == this.plncontas.plncd_codigo){
                    existe = 1
                }
            }            
            if (existe == 1){
                msgs += 'Não é permitido duplicidade de Contas; </br>';
            }
        }

        if (msgs !== ''){
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Incluir o Item',
                html: msgs,
                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
            })        
        }else{
            this.lista.plncontas.push(
            {
                crecplnc_codigo: this.plncontas.crecplnc_codigo,
                plncc_codigo: this.plncontas.plncc_codigo,
                plncd_codigo: this.plncontas.plncd_codigo,
                crecplnc_valor: this.plncontas.crecplnc_valor,
                crecplnc_complemento: this.plncontas.crecplnc_complemento,
                plncccontas: this.plnccSelecionado,
                plncdcontas: this.plncdSelecionado,
            })

            this.plncontas.crecplnc_codigo = null
            this.plncontas.plncc_codigo = null
            this.plncontas.plncd_codigo = null
            this.plncontas.crecplnc_valor = 0.00
            this.plncontas.crecplnc_complemento = null
            this.plncontas.plncccontas = {}
            this.plncontas.plncdcontas = {}

            this.onTotalContabil()
        }        
    },
    onEditPlnContas (index) {
        this.plncontas.crecplnc_codigo = this.lista.plncontas[index].crecplnc_codigo
        this.plncontas.plncc_codigo = this.lista.plncontas[index].plncc_codigo
        this.plncontas.plncd_codigo = this.lista.plncontas[index].plncd_codigo
        this.plncontas.crecplnc_valor = this.lista.plncontas[index].crecplnc_valor
        this.plncontas.crecplnc_complemento = this.lista.plncontas[index].crecplnc_complemento
        this.plncontas.plncccontas = this.lista.plncontas[index].plncccontas
        this.plncontas.plncdcontas = this.lista.plncontas[index].plncdcontas

        this.lista.plncontas.splice(index,1)
        this.onTotalContabil()
    },
    onExcPlnContas (index) {
        this.$swal({
            title: 'Deseja realmente remover o item da lista?',
            text: "Este evento não podera ser revertido!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.lista.plncontas.splice(index,1) 
                this.onTotalContabil()                      
            }
        })           
    },
    /* CENTRO DE CUSTOS */
    setCentroCusto (data) {
        this.centrocusto = data
    },
    onAdcCentroCusto () {
        let msgs = ''
        if (this.ccustos.ccst_codigo == null || this.ccustos.ccst_codigo == ''){
            msgs += 'Selecione um Centro de Custo; </br>';
        }
        if (this.ccustos.crecc_valor == null || this.ccustos.crecc_valor == 0){
            msgs += 'Digite um Valor válido; </br>';
        }
        /*if (this.ccustos.crecc_valor !== null && this.ccustos.crecc_valor !== 0){
            let soma = Number(this.ccustos.crecc_valor)
            for (var index in this.lista.ccustos) {
                soma += Number(this.lista.ccustos[index].crecc_valor) 
            }            
            if (Number(soma).toFixed(2) > Number(this.lista.crec_valor).toFixed(2)){
                msgs += 'O Total dos itens não pode ser maior que o valor do Contas a Receber; </br>';
            }
        }*/
        if (this.ccustos.ccst_codigo !== null && this.ccustos.ccst_codigo !== ''){
            let existe = 0
            for (var index in this.lista.ccustos) {
                if (this.lista.ccustos[index].ccst_codigo == this.ccustos.ccst_codigo){
                    existe = 1
                }
            }            
            if (existe == 1){
                msgs += 'Não é permitido duplicidade de centro de custo; </br>';
            }
        }

        if (msgs !== ''){
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Incluir o Item',
                html: msgs,
                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
            })        
        }else{
            this.lista.ccustos.push(
            {
                crecc_codigo: this.ccustos.crecc_codigo,
                ccst_codigo: this.ccustos.ccst_codigo,
                crecc_valor: this.ccustos.crecc_valor,
                ccusto: this.centrocustoSelecionado,
            })

            this.ccustos.crecc_codigo = null
            this.ccustos.ccst_codigo = null
            this.ccustos.crecc_valor = 0.00
            this.ccustos.ccusto = {}
        }        
        this.onTotalCCusto()  
    },
    onEditCentroCusto (index) {
        this.ccustos.crecc_codigo = this.lista.ccustos[index].crecc_codigo
        this.ccustos.ccst_codigo = this.lista.ccustos[index].ccst_codigo
        this.ccustos.crecc_valor = this.lista.ccustos[index].crecc_valor
        this.ccustos.ccusto = this.lista.ccustos[index].ccusto

        this.lista.ccustos.splice(index,1)
        this.onTotalCCusto()  
    },
    onExcCentroCusto (index) {
        this.$swal({
            title: 'Deseja realmente remover o item da lista?',
            text: "Este evento não podera ser revertido!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
        }).then((result) => {
            if (result.value) {
                this.lista.ccustos.splice(index,1)    
                this.onTotalCCusto()                     
            }
        })           
    },    

    /* PARCELAS */
    onMarcarTodos(marcar){
        for(var i = 0, len = this.lista.parcelas.length; i < len; ++i){
           this.lista.parcelas[i].crecp_selecionado = this.lista.parcelas[i].crecp_cancelado != 1 ? marcar : 0
        }
    },    
    async onEditParcela (index) {
        const res =  this.lista.parcelas[index].crecp_codigo ? await this.onVerQuitacao(this.lista.parcelas[index].crecp_codigo,'C') : false  
        if (!res){            
            this.parcelaSelecionada = index
            this.$bvModal.show('parc-sel')
        } else {
            this.$swal({
                icon: 'error',
                title: 'A Parcela não pode ser alterada/excluída.',
                text: 'A Parcela não pode ser alterada/excluída, pois a mesma contém lançamentos financeiros vinculados.',
                footer: '<small class="text-danger"><strong>Exclua os lançamentos vinculados e tente novamente.</strong></small>'
            }) 
        }
    },
    async onExcParcela (index) {
        const res = this.lista.parcelas[index].crecp_codigo ? await this.onVerQuitacao(this.lista.parcelas[index].crecp_codigo,'E') : false
        if (res){            
            this.$swal({
                icon: 'error',
                title: 'A Parcela não pode ser alterada/excluída.',
                text: 'A Parcela não pode ser alterada/excluída, pois a mesma contém lançamentos financeiros vinculados.',
                footer: '<small class="text-danger"><strong>Exclua os lançamentos vinculados e tente novamente.</strong></small>'
            })
        } else {
            this.$swal({
                title: 'Deseja realmente remover o item da lista?',
                text: "Este evento não podera ser revertido!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim'
            }).then((result) => {
                if (result.value) {
                    this.lista.parcelas.splice(index,1)      
                }
            })
        }        
    },
    async onExcParcelasSel () {
        let res
        await this.$swal({
            title: 'Deseja realmente remover o(s) item(s) selecionado(s) da lista?',
            text: "Este evento não podera ser revertido!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim'
        }).then((result) => {
            res = result
        }) 
                    
        if (res.value) {
            var i = this.lista.parcelas.length
            while (i--){
                const quitado =  this.lista.parcelas[i].crecp_codigo ? await this.onVerQuitacao(this.lista.parcelas[i].crecp_codigo,'E') : false
                if (this.lista.parcelas[i].crecp_selecionado === 1 && !quitado) {
                    this.lista.parcelas.splice(i,1)                        
                }
            }                      
        }

    },
    async onCancParcela (index) {
        const res =  this.lista.parcelas[index].crecp_codigo ? await this.onVerQuitacao(this.lista.parcelas[index].crecp_codigo,'C') : false  
        if (res){            
            this.$swal({
                icon: 'error',
                title: 'A Parcela não pode ser cancelada.',
                text: 'A Parcela não pode ser cancelada, pois a mesma contém lançamentos financeiros vinculados.',
                footer: '<small class="text-danger"><strong>Estorne os lançamentos vinculados e tente novamente.</strong></small>'
            })
        } else {
            this.$swal({
                title: 'Deseja realmente cancelar a parcela?',
                text: "Cancelar parcela!",
                html:
                    '<label class="swal2-label">Digite o motivo para o cancelamento.</label>' +
                    '<input type="text" id="swal-input1" class="swal2-input">' +
                    '<label class="swal2-label">Digite a data do cancelamento.</label>' +
                    `<input type="date" id="swal-input2" class="swal2-input" value="${this.moment(new Date()).format('YYYY-MM-DD')}">`,
                focusConfirm: false,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    const motivo = document.getElementById('swal-input1').value
                    const data = document.getElementById('swal-input2').value
                    if (motivo === '')
                    {
                        this.$swal.showValidationMessage(
                            'Digite o motivo para o cancelamento.'
                        )
                    } else
                    if (!moment(data).isValid())
                    {
                        this.$swal.showValidationMessage(
                            'Digite a data do cancelamento.'
                        )
                    } else {
                        return [
                            document.getElementById('swal-input1').value,
                            document.getElementById('swal-input2').value
                        ]
                    }
                }
            }).then((result) => {
                const motivo = result.value[0]
                const data = result.value[1]
                if (result.value[0]) {

                    this.lista.parcelas[index].crecp_cancelado = 1
                    this.lista.parcelas[index].crecp_moti_cancelado = result.value[0]
                    this.lista.parcelas[index].crecp_user_cancelado = this.infoLogado.usu_usuario
                    this.lista.parcelas[index].crecp_data_cancelado = result.value[1]
                    this.lista.parcelas[index].crecp_hora_cancelado = this.moment().format("YYYY-MM-DD HH:mm:ss")
                                            
                }                     
            })
        }
    },
    async onCancParcelasSel () {
        let res
        await this.$swal({
            title: 'Deseja realmente cancelar a(s) parcela(s) selecionada(s)?',
            text: "Cancelar parcela!",
            html:
                '<label class="swal2-label">Digite o motivo para o cancelamento.</label>' +
                '<input type="text" id="swal-input1" class="swal2-input">' +
                '<label class="swal2-label">Digite a data do cancelamento.</label>' +
                `<input type="date" id="swal-input2" class="swal2-input" value="${this.moment(new Date()).format('YYYY-MM-DD')}">`,
            focusConfirm: false,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                const motivo = document.getElementById('swal-input1').value
                const data = document.getElementById('swal-input2').value
                if (motivo === '')
                {
                    this.$swal.showValidationMessage(
                        'Digite o motivo para o cancelamento.'
                    )
                } else
                if (!moment(data).isValid())
                {
                    this.$swal.showValidationMessage(
                        'Digite a data do cancelamento.'
                    )
                } else {
                    return [
                        document.getElementById('swal-input1').value,
                        document.getElementById('swal-input2').value
                    ]
                }
            }
        }).then((result) => {
            res = result                      
        })

        const motivo = res.value[0]
        const data = res.value[1]            
        if (res.value[0]) {
            for(var i = 0, len = this.lista.parcelas.length; i < len; ++i){
                const quitado =  this.lista.parcelas[i].crecp_codigo ? await this.onVerQuitacao(this.lista.parcelas[i].crecp_codigo,'C') : false
                if (this.lista.parcelas[i].crecp_selecionado === 1 && !quitado) {
                    this.lista.parcelas[i].crecp_cancelado = 1
                    this.lista.parcelas[i].crecp_moti_cancelado = res.value[0]
                    this.lista.parcelas[i].crecp_user_cancelado = this.infoLogado.usu_usuario
                    this.lista.parcelas[i].crecp_data_cancelado = res.value[1]
                    this.lista.parcelas[i].crecp_hora_cancelado = this.moment().format("YYYY-MM-DD HH:mm:ss")
                }
            }
        }
    },
    onParcelas () {
        var dataAtual = this.lista.crec_emissao
        var dataVencimento = dataAtual
        var valorParcela = this.lista.crec_valor/this.qtdParcelas
        var valorParcela = Number(valorParcela).toFixed(2)
        this.lista.parcelas = []
        for (var i = 0; i < this.qtdParcelas; i++) {
            if (i == 0){
                dataVencimento = moment(dataVencimento).add(this.condPgtoSelecionado.cpg_dias1, 'days')
            } else {
                dataVencimento = moment(dataVencimento).add(this.condPgtoSelecionado.cpg_dias2, 'days')
            }
            if (i+1 == this.qtdParcelas){
                var totalParcelas = valorParcela * this.qtdParcelas
                totalParcelas = Number(totalParcelas).toFixed(2)
                totalParcelas = Number(this.lista.crec_valor - totalParcelas).toFixed(2)
                valorParcela = Number(valorParcela) + Number(totalParcelas)
            }
            this.lista.parcelas.push(
            {
                crecp_codigo: null,
                crecp_parcela: i+1,
                crecp_numero: this.lista.crec_numero +'/'+ (i+1),
                crecp_valor: Number(valorParcela).toFixed(2),
                crecp_vencimento: dataVencimento,
                tpag_codigo: 1,
                crecp_multjurrec: 0.00,
                crecp_descconc: 0.00,
                crecp_valor_pago: 0.00,
                crecp_ultimopgto: null,
                crecp_diasatraso: 0,
                crecp_vlrmulta: 0.00,
                crecp_vlrjuros: 0.00,
                crecp_sldpagar: Number(valorParcela).toFixed(2),
                crecp_cancelado: 0
            })                
        }
    },
    
    /* QUITACOES */
    async setTpQuitacao () {
        //this.tpQuitacao = data
        await axios.get('esctpquitacao/combo')
            .then(response => {
                this.tpQuitacao = response.data.data 
            })
            .catch(response => {
                //console.log(response)
            })
    },
    onAdcQuitacao () {
        let msgs = ''
        /* FILTRA PARCELA */
        let ret = this.lista.parcelas.filter(el => {
            return el.crecp_codigo == this.quitacoes.crecp_codigo;
        })
        
        if (this.quitacoes.crecp_codigo == null || this.quitacoes.crecp_codigo == ''){
            msgs += 'Selecione uma Parcela; </br>';
        }
        if (this.quitacoes.cbnclanc_data == null || this.quitacoes.cbnclanc_data == 0){
            msgs += 'Digite uma Data válida; </br>';
        }
        if (this.quitacoes.tqui_codigo == null || this.quitacoes.tqui_codigo == 0){
            msgs += 'Selecione um Tipo de Quitação; </br>';
        }
        if (this.quitacoes.ccst_codigo == null || this.quitacoes.ccst_codigo == 0){
            msgs += 'Selecione um Centro de Custo; </br>';
        }
        if (this.quitacoes.cbnclanc_tipo == null || this.quitacoes.cbnclanc_tipo == 0){
            msgs += 'Selecione um Tipo de Lançamento; </br>';
        }
        if (this.quitacoes.cbnc_codigo == null || this.quitacoes.cbnc_codigo == 0){
            msgs += 'Selecione uma Conta Caixa / Bancária; </br>';
        }
        if (this.quitacoes.cbnclanc_valor == null || this.quitacoes.cbnclanc_valor == 0){
            msgs += 'Digite um Valor Pago válido; </br>';
        }        

        if (this.tpQuitacaoSelecionada.tqui_descricao === 'CHEQUE'){
            if (this.quitacoes.docp_ch_numero == null || this.quitacoes.docp_ch_numero == ''){
                msgs += 'Digite o Número do Cheque; </br>';
            }            
            if (this.quitacoes.docp_ch_vencimento == null || this.quitacoes.docp_ch_vencimento == 0){
                msgs += 'Digite a Data de Vencimento do Cheque; </br>';
            }            
            if (this.quitacoes.docp_ch_banco == null || this.quitacoes.docp_ch_banco == 0){
                msgs += 'Selecione o Banco do Cheque; </br>';
            }            
            if (this.quitacoes.docp_ch_agencia == null || this.quitacoes.docp_ch_agencia == ''){
                msgs += 'Digite a Agência do Cheque; </br>';
            }            
            if (this.quitacoes.docp_ch_conta == null || this.quitacoes.docp_ch_conta == ''){
                msgs += 'Digite a Conta Corrente do Cheque; </br>';
            }            
            if (this.quitacoes.docp_ch_valor == null || this.quitacoes.docp_ch_valor == 0){
                msgs += 'Digite um Valor válido para o Cheque; </br>';
            }            
        }
        if (msgs !== ''){
            this.$swal({
                icon: 'error',
                title: 'Erro(s) ao Incluir o Item',
                html: msgs,
                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
            })        
        }else{
            this.lista.quitacoes.push(
            {
                crecp_codigo: this.quitacoes.crecp_codigo,
                crecp_parcela: ret[0].crecp_parcela,
                crecp_numero: ret[0].crecp_numero,
                crecp_valor: ret[0].crecp_valor,
                pes_codigo: this.lista.pes_codigo,
                crec_codigo: this.quitacoes.crec_codigo,
                cbnclanc_codigo: this.quitacoes.cbnclanc_codigo,
                cbnclanc_tipo: this.quitacoes.cbnclanc_tipo,
                cbnclanc_data: this.quitacoes.cbnclanc_data,
                cbnclanc_data_credito: this.quitacoes.cbnclanc_data_credito,
                cbnc_codigo: this.quitacoes.cbnc_codigo,
                cbnc_ent: this.Entidade,
                cbnclanc_vlr_multa: this.quitacoes.cbnclanc_vlr_multa,
                cbnclanc_vlr_juros: this.quitacoes.cbnclanc_vlr_juros,
                cbnclanc_vlr_desconto: this.quitacoes.cbnclanc_vlr_desconto,
                cbnclanc_vlr_tarifas: this.quitacoes.cbnclanc_vlr_tarifas,
                cbnclanc_valor: this.quitacoes.cbnclanc_valor,
                cpagp_codigo: this.quitacoes.cpagp_codigo,
                ccst_codigo: this.quitacoes.ccst_codigo,
                cbnclanc_estorno: this.quitacoes.cbnclanc_estorno,
                tp_lancamento: this.quitacoes.tp_lancamento,
                tqui_codigo: this.quitacoes.tqui_codigo,
                tqui_descricao: this.tpQuitacaoSelecionada.tqui_descricao,
                docp_ch_numero: this.quitacoes.docp_ch_numero,
                docp_ch_vencimento: this.quitacoes.docp_ch_vencimento,
                docp_ch_banco: this.quitacoes.docp_ch_banco,
                docp_ch_agencia: this.quitacoes.docp_ch_agencia,
                docp_ch_conta: this.quitacoes.docp_ch_conta,
                docp_ch_valor: this.quitacoes.docp_ch_valor,
                cbnclanc_cancelado: this.quitacoes.cbnclanc_cancelado
            })

            this.quitacoes.crecp_codigo = null
            this.quitacoes.crecp_parcela = null
            this.quitacoes.crecp_numero = null
            this.quitacoes.crecp_valor = null,
            this.quitacoes.pes_codigo = null
            this.quitacoes.crec_codigo = null
            this.quitacoes.cbnclanc_codigo = null
            this.quitacoes.cbnclanc_tipo = 2
            this.quitacoes.cbnclanc_data = new Date()
            this.quitacoes.cbnclanc_data_credito = new Date()
            this.quitacoes.cbnc_codigo = null
            this.quitacoes.cbnc_ent = this.Entidade
            this.quitacoes.cbnclanc_vlr_multa = null
            this.quitacoes.cbnclanc_vlr_juros = null
            this.quitacoes.cbnclanc_vlr_desconto = null
            this.quitacoes.cbnclanc_vlr_tarifas = null
            this.quitacoes.cbnclanc_valor = null
            this.quitacoes.ccst_codigo = null
            this.quitacoes.cbnclanc_estorno = null
            this.quitacoes.tp_lancamento = null
            this.quitacoes.tqui_codigo = null
            this.quitacoes.tqui_descricao= null
            this.quitacoes.docp_ch_numero = null
            this.quitacoes.docp_ch_vencimento = null
            this.quitacoes.docp_ch_banco = null
            this.quitacoes.docp_ch_agencia = null
            this.quitacoes.docp_ch_conta = null
            this.quitacoes.docp_ch_valor = null
            this.quitacoes.cbnclanc_cancelado = 0
        }
    },
    onExcQuitacao (index) {
        if (this.lista.quitacoes[index].cbnclanc_codigo === null || this.lista.quitacoes[index].cbnclanc_codigo === 0){
            this.$swal({
            title: 'Deseja realmente remover a quitação?',
            text: "Remover quitação!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim'
            }).then((result) => {
                if (result.value) {
                    this.lista.quitacoes.splice(index,1);   
                }                     
            })
        } else {
            this.$swal({
                title: 'Deseja realmente excluir a quitação?',
                text: "Este evento não podera ser revertido!",
                html:
                    '<label class="swal2-label">Digite o motivo para a exclusão.</label>' +
                    '<input type="text" id="swal-input1" class="swal2-input">',
                focusConfirm: false,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                cancelButtonText: 'Não',
                confirmButtonText: 'Sim',
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    const motivo = document.getElementById('swal-input1').value

                    if (motivo === '')
                    {
                        this.$swal.showValidationMessage(
                            'Digite o motivo para a exclusão.'
                        )
                    } else {
                        return [
                            document.getElementById('swal-input1').value
                        ]
                    }
                }

            }).then((result) => {
                const motivo = result.value[0]
                if (result.value[0]) {
                    
                    let LancCod = this.lista.quitacoes[index].cbnclanc_codigo;
                    let LancTipo = this.lista.quitacoes[index].tp_lancamento;
                    
                    let URL = ''    
                    URL = '/escfinanceiro/parcreceber/quitacao/excluir'
                    var dados = {codigo: LancCod, tipo: LancTipo, motivo: result.value[0]}
                    axios.delete(`${URL}`,{data: dados})
                        .then(response => {
                            this.message = response.data.message
                            this.status = response.status
                            this.errors = {}
                            this.getData()
                            
                            this.$swal('Sucesso!',
                            'Registro excluído com sucesso!',
                            'success');
                        })
                        .catch(response => {
                            this.message = response.response.data.message;
                            this.errors = response.response.data.message;
                            /*if (response.response.data.error){
                                this.errors = response.response.data.error.errorInfo[2]              
                            }else{
                                this.errors = response.response.data.errors
                            }*/
                            this.status = response.response.status
                            this.$swal({
                                icon: 'error',
                                title: 'Erro(s) ao Excluir o Registro',
                                text: this.message,
                                footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                            })                              
                        })                
                }                     
            })
        }
    },

    onCancQuitacao (tipo,index) {
        this.$swal({
            title: 'Deseja realmente cancelar a quitação?',
            text: "Cancelar quitação!",
            html:
                '<label class="swal2-label">Digite o motivo para o cancelamento.</label>' +
                '<input type="text" id="swal-input1" class="swal2-input">' +
                '<label class="swal2-label">Digite a data do cancelamento.</label>' +
                `<input type="date" id="swal-input2" class="swal2-input" value="${this.moment(new Date()).format('YYYY-MM-DD')}">`,
            focusConfirm: false,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                const motivo = document.getElementById('swal-input1').value
                const data = document.getElementById('swal-input2').value

                if (motivo === '')
                {
                    this.$swal.showValidationMessage(
                        'Digite o motivo para o cancelamento.'
                    )
                } else
                if (!moment(data).isValid())
                {
                    this.$swal.showValidationMessage(
                        'Digite a data do cancelamento.'
                    )
                } else {
                    return [
                        document.getElementById('swal-input1').value,
                        document.getElementById('swal-input2').value
                    ]
                }
            }

        }).then((result) => {
            const motivo = result.value[0]
            const data = result.value[1]
            if (result.value[0]) {
                
                let URL = ''
                if (tipo === 'BANCO'){
                    URL = '/escfinanceiro/lancbanco/cancelar'
                } else {
                    URL = '/escfinanceiro/lanccaixa/cancelar'
                }
                var dados = {codigo: index, motivo: result.value[0], data: result.value[1]}
                axios.put(`${URL}`,dados)
                    .then(response => {
                        this.message = response.data.message
                        this.status = response.status
                        this.errors = {}
                        this.getData()
                        
                        this.$swal('Sucesso!',
                        'Registro cancelado com sucesso!',
                        'success');
                    })
                    .catch(response => {
                        this.message = response.response.data.message
                        if (response.response.data.error){
                            this.errors = response.response.data.error.errorInfo[2]              
                        }else{
                            this.errors = response.response.data.errors
                        }
                        this.status = response.response.status
                        
                    })                
            }                     
        })
    },
    onEstornaQuitacao (tipo,index) {
        this.$swal({
            title: 'Deseja realmente estonar a quitação?',
            text: "Estornar quitação!",
            html:
                '<label class="swal2-label">Digite o motivo para o estorno.</label>' +
                '<input type="text" id="swal-input1" class="swal2-input">' +
                '<label class="swal2-label">Digite a data do estorno.</label>' +
                `<input type="date" id="swal-input2" class="swal2-input" value="${this.moment(new Date()).format('YYYY-MM-DD')}">`,
            focusConfirm: false,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                const motivo = document.getElementById('swal-input1').value
                const data = document.getElementById('swal-input2').value

                if (motivo === '')
                {
                    this.$swal.showValidationMessage(
                        'Digite o motivo para o estorno.'
                    )
                } else
                if (!moment(data).isValid())
                {
                    this.$swal.showValidationMessage(
                        'Digite a data do estorno.'
                    )
                } else {
                    return [
                        document.getElementById('swal-input1').value,
                        document.getElementById('swal-input2').value
                    ]
                }
            }

        }).then((result) => {

            const motivo = result.value[0]
            const data = result.value[1]
            if (result.value[0]) {
                
                let URL = ''
                if (tipo === 'BANCO'){
                    URL = '/escfinanceiro/lancbanco/estornar'
                } else {
                    URL = '/escfinanceiro/lanccaixa/estornar'
                }
                var dados = {codigo: index, motivo: result.value[0], data: result.value[1]}
                axios.put(`${URL}`,dados)
                    .then(response => {
                        this.message = response.data.message
                        this.status = response.status
                        this.errors = {}
                        this.getData()
                        
                        this.$swal('Sucesso!',
                        'Registro estornado com sucesso!',
                        'success');
                    })
                    .catch(response => {
                        this.message = response.response.data.message;
                        this.errors = response.response.data.message;
                        this.status = response.response.status
                        this.$swal({
                            icon: 'error',
                            title: 'Erro(s) ao tentar Estornar a Quitação',
                            text: this.message,
                            footer: '<small class="text-danger"><strong>Corrija os erros relacionados acima e tente novamente.</strong></small>'
                        })
                    })                
            }                     
        })
    },
    async onVerQuitacao (index, tipo) {
        const URL = '/escfinanceiro/parcreceber/quitacao/'+index
        const response = await axios.get(`${URL}`)

        if ((response.data.data.qtd_lanc > 0 && tipo === 'E') || (Number(response.data.data.quita_valor) > 0 && tipo === 'C')) {
            return true
        } else {
            return false
        }
    },
    onTotalContabil(){
        this.totalParcelasContabil = 0
        for (var index in this.lista.plncontas) {
            this.totalParcelasContabil += Number(this.lista.plncontas[index].crecplnc_valor) 
        }
        this.plncontas.crecplnc_valor = this.lista.crec_valor - this.totalParcelasContabil > 0 ? this.lista.crec_valor - this.totalParcelasContabil : 0
    },
    onTotalCCusto(){
        this.totalParcelasCCusto = 0
        for (var index in this.lista.ccustos) {
            this.totalParcelasCCusto += Number(this.lista.ccustos[index].crecc_valor) 
        }
        this.ccustos.crecc_valor = this.lista.crec_valor - this.totalParcelasCCusto > 0 ? this.lista.crec_valor - this.totalParcelasCCusto : 0
    }
  },
  watch: {
    'ccustos.ccst_codigo': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
            var ret = this.centrocusto.filter(function(el) {
                return el.ccst_codigo == newVal;
            })
            this.centrocustoSelecionado = ret[0]
        }
    },
    'plncontas.plncc_codigo': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
            var ret = this.plncc.filter(function(el) {
                return el.plnc_codigo == newVal;
            })
            this.plnccSelecionado = ret[0]
        }
    },
    'plncontas.plncd_codigo': function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
            var ret = this.plncd.filter(function(el) {
                return el.plnc_codigo == newVal;
            })
            this.plncdSelecionado = ret[0]
        }
    },
    'lista.cpg_codigo': function (newVal, oldVal){ 
        if (typeof newVal !== 'undefined' && newVal != null && typeof this.condPgto.filter !== 'undefined'){        
            var ret = this.condPgto.filter(function(el) {
                return el.cpg_codigo == newVal;
            })

            this.condPgtoSelecionado = ret[0]
            if (typeof this.condPgtoSelecionado !== 'undefined' && typeof this.condPgtoSelecionado.cpg_nparcelas !== 'undefined'){
                this.qtdParcelas = Number(this.condPgtoSelecionado.cpg_nparcelas)
            }
            if ((newVal !== oldVal) && (this.modo == 'novo')) {
                this.onParcelas()    
            } 
        }
    },
    qtdParcelas: function (newVal, oldVal){ 
        if (typeof newVal != 'undefined' && newVal != null){        
            if ((newVal !== oldVal) && (this.modo == 'novo')) {
                this.onParcelas()    
            } 
        }
    },
    'lista.crec_valor': function (newVal, oldVal){         
        if (typeof newVal != 'undefined' && newVal != null){        
            if ((newVal !== oldVal) && (this.modo == 'novo')) {
                this.onParcelas()    
            }
            this.onTotalContabil()
            this.onTotalCCusto()
        } 
    },    
    'lista.crec_emissao': function (newVal, oldVal){         
        if (typeof newVal != 'undefined' && newVal != null){        
            if ((newVal !== oldVal) && (this.modo == 'novo')) {
                this.onParcelas()    
            } 
        }
    },
    'lista.crec_numero': function (newVal, oldVal){         
        if (typeof newVal != 'undefined' && newVal != null){        
            if ((newVal !== oldVal) && (this.modo == 'novo')) {
                this.onParcelas()    
            }
        } 
    },
    'quitacoes.crecp_codigo' : function (newVal, oldVal) {
        let ret = this.lista.parcelas.filter(el => {
            return el.crecp_codigo == this.quitacoes.crecp_codigo;
        })
        this.quitacoes.crecp_valor = ret[0] && ret[0].crecp_valor ? ret[0].crecp_valor : 0
    },
    'quitacoes.tqui_codigo' : function (newVal, oldVal) {
        let ret = this.tpQuitacao.filter(el => {
            return el.tqui_codigo == newVal;
        })
        if (typeof ret[0] !== 'undefined'){
            this.tpQuitacaoSelecionada = ret[0]
        }else{
            this.tpQuitacaoSelecionada = {}
        }
    },
    'quitacoes.crecp_valor' : function (newVal, oldVal) {
        this.quitacoes.cbnclanc_valor =  Number(this.quitacoes.crecp_valor) + Number(this.quitacoes.cbnclanc_vlr_multa) + Number(this.quitacoes.cbnclanc_vlr_juros) + Number(this.quitacoes.cbnclanc_vlr_tarifas) - Number(this.quitacoes.cbnclanc_vlr_desconto)
    },
    'quitacoes.cbnclanc_vlr_multa' : function (newVal, oldVal) {
        this.quitacoes.cbnclanc_valor =  Number(this.quitacoes.crecp_valor) + Number(this.quitacoes.cbnclanc_vlr_multa) + Number(this.quitacoes.cbnclanc_vlr_juros) + Number(this.quitacoes.cbnclanc_vlr_tarifas) - Number(this.quitacoes.cbnclanc_vlr_desconto)
    },
    'quitacoes.cbnclanc_vlr_juros' : function (newVal, oldVal) {
        this.quitacoes.cbnclanc_valor =  Number(this.quitacoes.crecp_valor) + Number(this.quitacoes.cbnclanc_vlr_multa) + Number(this.quitacoes.cbnclanc_vlr_juros) + Number(this.quitacoes.cbnclanc_vlr_tarifas) - Number(this.quitacoes.cbnclanc_vlr_desconto)
    },
    'quitacoes.cbnclanc_vlr_tarifas' : function (newVal, oldVal) {
        this.quitacoes.cbnclanc_valor =  Number(this.quitacoes.crecp_valor) + Number(this.quitacoes.cbnclanc_vlr_multa) + Number(this.quitacoes.cbnclanc_vlr_juros) + Number(this.quitacoes.cbnclanc_vlr_tarifas) - Number(this.quitacoes.cbnclanc_vlr_desconto)
    },
    'quitacoes.cbnclanc_vlr_desconto' : function (newVal, oldVal) {
        this.quitacoes.cbnclanc_valor =  Number(this.quitacoes.crecp_valor) + Number(this.quitacoes.cbnclanc_vlr_multa) + Number(this.quitacoes.cbnclanc_vlr_juros) + Number(this.quitacoes.cbnclanc_vlr_tarifas) - Number(this.quitacoes.cbnclanc_vlr_desconto)
    },        
  },
  computed: {
        ...mapState('Login',['infoLogado','IP','Entidade','Exercicio','Escola'])
  }, 
  created() {
      this.setTpQuitacao();
  }     
}
</script>