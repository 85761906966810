<template>
  <div class="mct-form">
    <mct-topo-lista :titulo="titulo" :subtitulo="`Listagem de ${titulo}`" v-show="!showModal"></mct-topo-lista>    

    <div class="container-fluid position-relative">
      <div class="row mct-form-filter mb-2">
        <div class="col-4">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text">Situação</label>
            </div>            
            <select class="form-control" v-model="situacao">
              <option value="0">TODOS</option>
              <option value="M">MATRICULADO</option>
              <option value="C">CANCELADO</option>
              <option value="D">DESISTENTE</option>
              <option value="T">TRANSFERIDO</option>
              <option value="F">FINALIZADO</option>
            </select>              
          </div>
        </div>
        <div class="col-4">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text">Aluno</label>
            </div>            
            <input type="text" 
              class="form-control"
              placeholder="Pesquisa"
              v-model="pessoa">
          </div>
        </div>
        <div class="col-4">
          <div class="input-group">
            <div class="input-group-prepend">
              <label class="input-group-text">Curso</label>
            </div>            
            <input type="text" 
              class="form-control"
              placeholder="Pesquisa"
              v-model="curso">
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-2 btn-group" role="group" aria-label="Ações">
          <button type="button" class="btn btn-secondary text-white" aria-label="Left Align" @click="getData($event)">
              <span class="fa fa-search text-white" aria-hidden="true"></span> Buscar 
          </button> 
          <button type="button" class="btn btn-danger text-white" aria-label="Left Align" @click="limpaEdits()">
              <span class="fa fa-eraser text-white" aria-hidden="true"></span> Limpar 
          </button>
        </div>
      </div>
      <slot>
          <div class="mct-grid">
              <div class="mct-grid-scroll">         
                    <table class="animated fadeIn table table-striped table-hover mb-0">
                      <thead>
                          <tr>
                              <th :style="showModal ? 'width: 35px!important;' : 'width: 45px!important;'"></th>
                              <th style="width: 50px;" class="text-right">R.A.</th>
                              <th style="width: 100px;" class="text-right">MATRÍCULA</th>
                              <th style="width: 50px;" class="text-right">NÚMERO</th>
                              <th>ALUNO / CURSO</th>
                              <th style="width: 80px;" class="text-right">DATA MATRI.</th>
                          </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(row,index) in data.data" :key="index"  @click.prevent.stop="setDescricao($event,row)">
                          <td>
                            <div class="btn-group pt-2">
                              <a class="fa fa-pencil text-white mct-fa bg-primary mr-1 btn btn-sm" style="max-height: 22px; padding-top: 1px;" role="button" :class="row.horari_situacao === 0 || row.horari_situacao === 3 ? 'disabled' : ''" :aria-disabled="row.horari_situacao === 0 || row.horari_situacao === 3" aria-hidden="true" title="Editar Agendamento" v-show="!showModal" @click="redirect($event,`${$route.fullPath.replace('/lista','')}/${row.matri_codigo}/${row.matri_ent}/${row.matri_esc}/${row.matri_ano}/editar`)"></a>
                              <i class="fa fa-search text-white bg-secondary mct-fa mr-1" aria-hidden="true" title="Visualizar Agendamento" v-show="!showModal" @click="redirect($event,`${$route.fullPath.replace('/lista','')}/${row.matri_codigo}/${row.matri_ent}/${row.matri_esc}/${row.matri_ano}/visualizar`)"></i>
                              <i class="fa fa-hand-pointer-o text-white bg-info mct-fa mr-1" aria-hidden="true" v-show="showModal" @click.prevent.stop="setDescricao($event,row)"></i>
                            </div>
                          </td>
                          <td><span class="mct-grid-span-02 d-block text-right pt-2">{{row.matri_codigo}}</span></td>
                          <td><span class="mct-grid-span-02 d-block text-right pt-2">{{row.matri_matricula}}</span></td>
                          <td><span class="mct-grid-span-02 d-block text-right pt-2">{{row.matri_numero}}</span></td>
                          <td>
                            <div class="row">
                              <div class="col-8">
                                <h1 class="mct-grid-h1" style="white-space: pre-wrap; word-wrap:break-word;">{{row.pes_nome}}<span class="text-muted">{{' [ '+row.pes_codigo+ ' ]'}}</span> </h1>
                                <h1 class="mct-grid-h1 text-muted font-italic" style="white-space: pre-wrap; word-wrap:break-word;">{{row.cur_nome }}<span class="text-muted">{{' [ '+row.cur_codigo+ ' ]'}}</span></h1>
                                <div class="row">
                                  <div class="col-3"><span class="mct-grid-span-00 mct-text-dark-light d-block">ANO/SÉRIE</span></div>
                                </div>
                                <div class="row">
                                  <div class="col-3"><span class="mct-grid-span-01 d-block text-primary">{{row.ser_descricao}}<span class="text-muted">{{' [ '+row.ser_codigo+ ' ]'}}</span></span></div>
                                </div>
                                <span class="badge badge-primary" v-if="row.matri_situacao === 'M'"><i class="fa fa-clock-o" aria-hidden="true"></i> matriculado</span>
                                <span class="badge badge-danger" v-else-if="row.matri_situacao === 'C'"><i class="fa fa-ban" aria-hidden="true"></i> cancelado</span>
                                <span class="badge badge-info" v-else-if="row.matri_situacao === 'T'"><i class="fa fa-hourglass-start" aria-hidden="true"></i> tranferido</span>
                                <span class="badge badge-success" v-else-if="row.matri_situacao === 'F'"><i class="fa fa-check" aria-hidden="true"></i> finalizado</span>
                                <span class="badge badge-secondary" v-else-if="row.matri_situacao === 'D'"><i class="fa fa-sign-out" aria-hidden="true"></i> desistente</span>
                              </div>
                              <div class="col-2">
                                <span class="mct-grid-span-00 mct-text-dark-light d-block text-center">TURMA</span>
                                <span class="mct-grid-span-02 d-block text-center">{{row.turm_identificacao}}</span>
                              </div>
                              <div class="col-2 text-center">
                                <span class="mct-grid-span-00 mct-text-dark-light d-block text-center">TURNO</span>
                                <span class="mct-grid-span-02 fa-stack text-center">
                                  <i class="fa fa-sun-o text-warning" :class="row.turn_tipo == 0 ? 'fa-daytime' : ''" v-if="row.turn_tipo == 0 || row.turn_tipo == 1 || row.turn_tipo == 2"></i>
                                  <i class="fa fa-moon-o text-primary" v-else-if="row.turn_tipo == 0 || row.turn_tipo == 3"></i> 
                                  <i class="fa fa-clock-o text-info" v-else-if="row.turn_tipo == 4"></i> 
                                  {{row.turn_abreviatura}}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td class="text-right"><span class="mct-grid-span-02 d-block text-right pt-2">{{row.matri_data | data}}</span></td>
                      </tr>
                      </tbody>
                  </table>
              </div>
          </div>
      </slot>
      <mct-rodape-lista :data="data" :query="query" @navigate="navigate" @getData="getData"></mct-rodape-lista> 
    </div>       
  </div>
</template>
<script>
  import axios from '@/http'

  import MctTopoLista from '@/components/comum/MctTopoLista.vue'
  import MctRodapeLista from '@/components/comum/MctRodapeLista.vue'
  import MctMixinsList from '@/components/funcoes/MctMixinsList'

  export default {
    name: 'EscMatriculaLista2',
    mixins: [MctMixinsList],
    components: {
        'MctTopoLista': MctTopoLista,
        'MctRodapeLista': MctRodapeLista
    },        
    props: ['source', 'titulo', 'showModal'],
    data() {
      return {
        id: '',
        showAvancado: false,
        pessoa: '', 
        curso: '',
        situacao: 0
      }
    },
    watch: {
        source: function () {
          this.query.column = ''
          this.query.search_column = ''
          this.query.page = 1
          this.query.direction = 'asc'
          this.query.per_page = 10
          this.query.search_operator = 'equal'
          this.query.search_input = ''
          this.query.search_input2 = ''
          this.filters = {}
          this.getData()      
        }
    },    
    methods: {
        async getData() {
          if (typeof this.source !== "undefined" && typeof this.source !== "null" && this.source !== 'undefined/lista'){
          axios.get(this.source,{params:  {
                  column: this.query.column,
                  direction: this.query.direction,
                  page: this.query.page,
                  per_page: this.query.per_page,
                  search_column: this.query.search_column,
                  search_operator: this.query.search_operator,
                  search_input: this.query.search_input,
                  situacao: this.situacao,
                  pessoa: this.pessoa,
                  curso: this.curso,
                  //filters: this.filtros
              },
              paramsSerializer: function (params) {
                return jQuery.param(params)
              }
              })
              .then(response => {
                this.data = response.data.data
                this.columns = response.data.columns
                if (typeof response.data.filters != 'undefined'){
                  this.filters = response.data.filters
                }else{
                  this.filters = response.data.columns
                }
                this.id = response.data.primary_key
                this.$emit('setData', this.data, this.columns, this.query)
              })
              .catch(response => {
                console.log(response.message)
              })
          }
      },
      navigate (page) {
            this.query.page = page
            this.getData()
      },
      toggleOrder(column) {
        if(column === this.query.column) {
          if(this.query.direction === 'desc') {
            this.query.direction = 'asc'
          } else {
            this.query.direction = 'desc'
          }
        } else {
          this.query.column = column
          this.query.direction = 'asc'
        }
        this.getData()
      },      
      setDescricao (data ){
          this.$emit('setDescricao', data)
      },
      toogleAvancado (data){
        this.showAvancado = data
      },
      redirect (ev, link) {
          ev.preventDefault();
          
          this.$router.push(link)
      },
      limpaEdits() {
        this.curso = '';
        this.pessoa = '';
        this.situacao = 0;
        this.getData();  
      }  
    },
  }
</script>

<style scoped>
  .mct-grid-h1{
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 0.8rem; 
  }
  .mct-grid-span-01{
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.6rem; 
  }
  .mct-grid-span-00{
    font-size: 0.6rem;
    font-weight: 400;
  }
  .mct-grid-span-02{
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.8rem;
  }
  .fa-daytime{
    margin-right: -10px;
  }

</style>
