<template>
    <div>    
        <div class="card-titulo d-flex justify-content-between align-items-center">
            <button type="button" class="btn btn-sm" aria-label="Left Align"  @click.prevent.stop="$router.go(-1)">
                <span class="fa fa-chevron-left fa-lg" aria-hidden="true"></span> Voltar </button>
            <h1>HORÁRIO</h1>
            <i></i>
        </div>

        <div class="cards card-horario">
            <div>
                <h2>{{matricula.turma.turm_identificacao}}</h2>
                <h1>{{matricula.turma.curso.cur_nome}}</h1>
            </div>
            <div class="d-flex flex-row justify-content-between">
                <div class="d-flex flex-row">
                    <div class="d-flex flex-column">
                        <small>Matrícula</small>
                        <span>{{matricula.matri_matricula}}</span>
                    </div>
                    <div class="d-flex flex-column ml-3 mr-3">
                        <small>Início</small>
                        <span>{{serie.agendamento.horari_data_inicial | data}}</span>
                    </div>
                    <div class="d-flex flex-column">
                        <small>Fim</small>
                        <span>{{serie.agendamento.horari_data_final | data}}</span>
                    </div>
                </div>
                <h1>
                    <span class="badge badge-primary" v-if="serie.agendamento.horari_situacao === 1"><i class="fa fa-clock-o" aria-hidden="true"></i> em andamento</span>
                    <span class="badge badge-danger" v-else-if="serie.agendamento.horari_situacao === 0"><i class="fa fa-ban" aria-hidden="true"></i> cancelado</span>
                    <span class="badge badge-info" v-else-if="serie.agendamento.horari_situacao === 2"><i class="fa fa-hourglass-start" aria-hidden="true"></i> aguardando</span>
                    <span class="badge badge-success p-1" v-else-if="serie.agendamento.horari_situacao === 3"><i class="fa fa-check" aria-hidden="true"></i> finalizado</span>                            
                </h1>
            </div>
        </div>
        <div class="card-horario">
            <div class="cards mt-2 d-flex flex-row p-0" v-for="(horario,idxh) in serie.horario.horariosdia" :key="idxh">
                <div class="card-horario-dia d-flex flex-column justify-content-center align-items-center">
                    <span>{{horario.horaridia_data | data}}</span>
                </div>
                <div class="card-horario-aula d-flex flex-column" v-for="(dia,idxhd) in horario.horariosdiadisp" :key="idxhd">
                    <h2>{{dia.aula.turnhor_aula_c}}</h2>
                    <h1>{{dia.aula.turnhor_horario}}</h1>
                    <span>{{dia.disp_nome}}</span>  
                    <small>{{dia.disciplina.pes_nome}}</small>                  
                </div>
            </div>

        </div>

        
        
    </div>
</template>

<script>
import axios from '@/http'
import { mapState } from 'vuex'
import MctFormItens from '../../comum/MctFormItens.vue'

export default {
    Name: 'PortalNotasFaltas',
    components: {
        MctFormItens,
    },
    data () {
        return {
            porData: [],
            porDisciplina: []
        }
    },
    methods: {
        async getFrequencias () {
            await axios.get('/frequencia',
            {
                params: {
                    entidade: this.$route.params.entidade,
                    escola: this.$route.params.escola,
                    anoletivo: this.$route.params.ano_letivo,
                    turma: this.$route.params.turma,
                    horario: this.$route.params.horario
                }
            })
            .then(response => {
                this.porData = response.data.data;
                this.porDisciplina = response.data.disciplina;
            
            })
        }

    },
    mounted () {
        this.getFrequencias();    
    },
    computed: {
        ...mapState('Portal',['matriculas']),
        matricula () {
            return this.matriculas.filter(item => item.turma.turm_codigo == this.$route.params.turma)[0];
        },
        serie () {
            const matricula = this.matriculas.filter(item => item.turma.turm_codigo == this.$route.params.turma); 
            return matricula[0].series.filter(item => item.matri_horari_codigo == this.$route.params.horario)[0];
        },

    },

}
</script>

<style scoped>

    .card-avaliacao > div {
        padding: 10px;
        width: 15%;
        background-color: #05512C;
        border-color: 1px solid #E7E7E7;
        border-radius: 4px;

        text-align: center;
        color: #FFFFFF;
    }

    .card-avaliacao > div > h1 {
        font-size: 2em;
        font-weight: 600;
    }

    .card-avaliacao > div > small {
        font-size: 0.7em;
        font-weight: 400;
        padding-top: 10px;
        padding-bottom: 2px;
        margin: 0;
        line-height: 0;

    }

    .card-avaliacao > div > span {
        font-size: 0.9em;
        font-weight: 400;
    }

    .card-horario {
        margin-top: 10px;
    }

    .card-horario h1 {
        color: #333333;
        font-size: 1em;
    }

    .card-horario h2 {
        color: #05512C;
        font-size: 1.2em;
        font-weight: bold;
    }

    .card-horario-dia {
        min-width: 125px;
        padding: 20px;
        background-color: #F9A21E;
    }

    .card-horario-dia > span {
        font-size: 1.2em;
        font-weight: 600;
        color: #05512C;
    }

    .card-horario-aula {
        padding: 10px 20px;
    }

    .card-horario-aula > span {
        font-size: 1em;
        font-weight: 600;
        color: #333333;
    }

</style>

