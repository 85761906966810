<template>
    <div class="mct-form container">
        <mct-topo-lista :titulo="titulo" subtitulo=""></mct-topo-lista>
        <div class="container-fluid">
            <mct-form>
                <slot name="formulario"></slot>    
            </mct-form>
        </div>
        <mct-form-botoes v-if="botoes != 'false' && botoes != false" @submit="submit()" :modo="modo"></mct-form-botoes>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import MctTopoLista from './MctTopoLista.vue'
import MctForm from './MctForm.vue'
import MctFormBotoes from './MctFormBotoes.vue'

export default {
    Name: 'FormPadraoSCB', 
    components: {
        MctForm,
        MctTopoLista,
        MctFormBotoes,
    },
    props: ['titulo','modo','botoes'],
    data () {
        return{
            //isLoading: true
        }
    },
    methods: {
        submit(){
            this.$emit('submit')
        },
        confirma (){
            this.$emit('confirma')
        }                
  },
  mounted() {
    /*setTimeout(() => {
      this.isLoading = false
    }, 1000)*/
  } 
}
</script>


