<template>
    <div class="mct-form container">
        <mct-topo-lista :titulo="titulo" :subtitulo="'Manutenção de '+ titulo + ' - ' + modo.toUpperCase()"></mct-topo-lista>
        <mct-alertas :mensagem="message" :errors="errors" :status="status" :modo="modo" v-show="status !== 0"></mct-alertas>
        <div class="container-fluid position-relative">
            <i class="fa fa-info mct-infos-btn" aria-hidden="true" @click="onShowInfos()" title="Mostrar ou Ocultar Informações"></i>
            <mct-form>
                <slot name="formulario"></slot>  
                <portal-target name="mct-infos" class="mct-infos">
                        
                </portal-target>
                <mct-infos :data="lista"/>

            </mct-form>
        </div>       
        <mct-form-botoes @submit="submit" :modo="modoComp">            
            <slot name="botoes"></slot>
        </mct-form-botoes>        
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

import MctTopoLista from './MctTopoLista.vue'
import MctForm from './MctForm.vue'
import MctFormBotoes from './MctFormBotoes.vue'
import MctAlertas from './MctAlertas.vue'
import MctMixinsForm from '../funcoes/MctMixinsForm'
import MctInfos from "@/components/comum/MctInfos.vue"

export default {
    Name: 'MctFormPadrao', 
    mixins: [MctMixinsForm], 
    components: {
        MctForm,
        MctTopoLista,
        MctFormBotoes,
        MctAlertas,
        MctInfos,
        },
    props: ['source','titulo','modo','listaAdicional'],
    data () {
        return{
            
        }
    },
    methods: {
        onShowInfos(){
            this.$store.dispatch('Configs/CONFIG_INFOS');
        }
    }  
}
</script>


