<template>
  <div class="app d-flex">
    <div class="portal-top d-flex flex-column justify-content-center align-items-center">
      <!--<img src="https://www.cistmt.com.br/wa_images/logo_(1).png" alt="Logo">-->
      <img src="@/assets/images/logo_siges.svg" alt="Logo">
      <h1 class="portal-logo-text">SIGES</h1>       
      <h1 class="portal-slogan-text">SISTEMA DE GESTÃO ESCOLAR</h1>       

    </div>
    <div class="container flex-grow-1">
      <router-view/>
    </div>
    <div class="portal-footer">
      <!--footer-->
      <div>
        <a href="https://www.mercatosa.com.br">Mercato</a>
        <span class="ml-1">&copy; {{moment().year()}}</span>
      </div>
      <div class="ml-auto">
        <span class="mr-1">Desenvolvido por</span>
        <a href="https://www.mercatosa.com.br">Mercato SA</a>
      </div>      
    </div>
  </div>
</template>

<script>

export default {
  name: 'LayoutLoginPortal',
  components: {
  },
  data () {
    return {
      nav: []
    }
  },
  methods: {
    
  },
  created () {
    
  },
  computed: {
    
  },
}
</script>

<style lang="scss">  
  @import '../../assets/scss/styleportal';
</style>