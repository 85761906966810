<template>
  <mct-padrao-s-b :titulo="titulo" :source="source" :modo="modo" @setData="setData">
    <div slot="formulario">
      <div class="row">
        <div class="col-lg-6">
          <mct-form-itens coluna="0" tipo="search" campo="turn_codigo" :valor="lista" titulo="Turno" obrigatorio="true" :errors="errors" origem="esc_turno" origemChave="turn_codigo" origemRetorno='turn_descricao' origemChaveComposta="true" @setDataRetorno="setTurno" :modo="modo" tamanho="2"></mct-form-itens>
        </div>
      </div>
     
      <div class="row">
        <div class="col-lg-6">
          <fieldset class="well">
            <table class="table table-striped table-bordered table-sm table-hover">
              <thead>
                <tr>
                  <th class="mct-acoes">Ação</th>
                  <th>
                    <span>AULA</span>
                  </th>
                  <th>
                    <span>INÍCIO</span>
                  </th>
                  <th>
                    <span>FIM</span>
                  </th>                           
                </tr>
              </thead>
              <tbody>
                <tr v-for="(aula,index) in aulasturno" :key="index" :class="[{editing: aula == linhaEditada}]" v-cloak v-on:dblclick="editAulaTurno(aula,index)"> 
                  <td class="is-icon">
                    <a href="#" class="btn btn-primary btn-sm" aria-label="Editar" @click="editAulaTurno(aula,index)"><i class="fa fa-pencil" aria-hidden="true"></i></a>
                  </td>
                  <td> 
                    <div class="view" readonly style="width: 80px;">
                      {{aula.turnhor_aula}}
                    </div>
                    <div class="edit" style="width: 80px;">
                      {{aula.turnhor_aula}}
                    </div>
                  </td>
                  <td>
                    <div class="view" style="width: 120px;">
                      {{aula.turnhor_hora_ini}}
                    </div>
                    <div class="edit" style="width: 120px;">
                      <mct-form-itens tipo="text" mascara="hora" campo="turnhor_hora_ini" :valor="aula" :errors="errors" obrigatorio="true" :modo="modo"></mct-form-itens>
                    </div>
                  </td>
                  <td>
                    <div class="view" style="width:120px;">
                      {{aula.turnhor_hora_fin}}
                    </div>
                    <div class="edit" style="width:120px;">
                      <mct-form-itens tipo="text" mascara="hora" campo="turnhor_hora_fin" :valor="aula" :errors="errors" obrigatorio="true" :modo="modo"></mct-form-itens>
                    </div>
                  </td>
                </tr>
                <tr v-if="typeof lista !== 'undefined' && typeof aulasturno !== 'undefined' && aulasturno.length == 0">
                  <td colspan="4" class="text-info" style="text-align: center; font-size: 1rem; line-height: 1.2rem;">
                    O FILTRO NÃO RETORNOU REGISTROS DE AULAS, VERIFIQUE SE NO TURNO O VALOR DO CAMPO "AULAS DIÁRIA" ESTÁ MAIOR QUE 0 (ZERO).
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
        </div>
      </div>
      <div style="margin-bottom: 5px;">
        <div class="h-divider clear"></div>
         <button type="button" class="btn btn-success" aria-label="Left Align"  @click.prevent.stop="gravar()" v-if="modo !== 'deletar' && modo !== 'visualizar' && modo !== 'readonly'">
          <span class="fa fa-check fa-lg" aria-hidden="true"></span> Gravar </button>
        <button type="button" class="btn btn-danger" aria-label="Left Align" @click.prevent.stop="excluir()" v-if="modo === 'deletar'">
          <span class="fa fa-trash-o fa-lg" aria-hidden="true"></span> Excluir </button>
        <button type="button" class="btn btn-dark" aria-label="Left Align" @click.prevent.stop="$router.back()">
          <span class="fa fa-chevron-left fa-lg" aria-hidden="true"></span> Voltar </button>
    </div> 
    </div>              
  </mct-padrao-s-b>      
</template>

<script>
import Vue from 'vue'
import axios from '@/http'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'

import MctPadraoSB from '@/components/comum/MctFormPadraoSB.vue'
import MctFormItens from '@/components/comum/MctFormItens.vue'
import MctModal from '@/components/comum/MctModalSHB.vue'

export default {
  Name: 'TurnoHorario',  
  props: ['source','titulo','modo'],
  components: {
      MctPadraoSB,
      MctFormItens,
      MctModal,
  },
  data () {
      return {
        lista: {},
        errors:{},
        message: '',
        status: 0,
        aulasturno:[],
        linhaEditada: null,
        horarios:[],
      }
  },
  methods: {
    setData (lista, errors, lastCode) {
        this.lista = lista
        this.errors = errors        
    },
    async setTurno(data){
      this.lista.cur_codigo = data.cur_codigo
      this.lista.turn_ent = data.turn_ent
      this.lista.turn_esc = data.turn_esc
      this.lista.turn_ano = data.turn_ano
      await axios.get('escturnohorario/combo?turn_codigo='+this.lista.turn_codigo+'&turn_ent='+this.lista.turn_ent+'&turn_esc='+this.lista.turn_esc+'&turn_ano='+this.lista.turn_ano)
        .then(response => {
          this.horarios = response.data.data
        })
        .catch(response => {
          this.message = response.response.data.message
          this.errors = response.response.data.errors
          this.status = response.response.status
        })
      
      //if(this.horarios.length > 0){
      //  this.aulasturno = this.horarios
      //}else{
        this.setAulas(data)
      //}
      
    },
    setAulas(data){
      //this.aulasturno = []
      this.aulasturno = this.horarios
      for (var i = this.aulasturno.length; i < Number(data.turn_aulas_diaria); i++) {
        this.aulasturno.push({turn_codigo: data.turn_codigo, turn_ent: data.turn_ent, turn_esc: data.turn_esc, turn_ano: data.turn_ano, turnhor_hora_ini:'', turnhor_hora_fin:'', turnhor_aula: i+1})
      }
    },
    editAulaTurno(linha,id) {
      if (this.linhaEditada === linha){
        this.linhaEditada = null    
      }else{
        this.linhaEditada = linha
        this.linhaEditada['id'] = id 
      }
    },
    async gravar(ev){
      this.lista.aulasturno = this.aulasturno
      
      var dados = this.lista
      await axios.post(`${this.source}`,dados)
          .then(response => {
              this.message = response.data.message
              this.status = response.status
              this.lista = response.data.data
              this.errors = {}
              this.setData()
              
              this.$swal('Sucesso!',
              'Registro incluído com sucesso!',
              'success');  
          this.mode = 'readonly'
          this.$router.back()                                          
          })
          .catch(response => {
              this.message = response.response.data.message
              if (response.response.data.error){
                  this.errors = response.response.data.error.errorInfo[2]              
              }else{
                  this.errors = response.response.data.errors
              }
              this.status = response.response.status
              this.setData()
              
          })   
    },
    excluir(ev){

    }
  
  },
   computed: {
    ...mapState('Login',['infoLogado']), 
  },  
  created() {
  
  }     
}
</script>

<style scoped>
    [v-cloak] {
        display: none;
    }
    .edit {
        display: none;
    }
    .editing .edit {
        display: block
    }
    .editing .view {
        display: none;
    }  
      
    .is-icon{
        max-width: 65px;
    }

    .is-excluido{
        color: #C82333;
        background-color: #e39199!important; 
        text-decoration: line-through;
    }    
</style>